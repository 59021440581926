import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { SmallDraggableIcon } from '@/components/Icons/SmallDraggableIcon';
import { PrivateImage } from '@/components/PrivateImage/PrivateImage';
import { Typography } from '@/components/Typography/Typography';
import { AdministrationFunctionTypeTag } from '@/components/views/administration/AdministrationFunctionContainer/AdministrationFunctionTypeTag/AdministrationFunctionTypeTag';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { administrationPageSelectors } from '@/core/redux/slices/administrationPage/selectors';
import { administrationPageActions } from '@/core/redux/slices/administrationPage/slice';
import { FunctionTypes, IFunctionItem } from '@/types/functions';

import styles from './styles.module.scss';

export const AdministrationFunctionCard: React.FC<IFunctionItem> = ({
  id,
  functionType,
  name,
  iconUrl,
  functionID,
}) => {
  const { attributes, listeners, setNodeRef, isDragging, transform, transition } = useSortable({
    id,
  });
  const activeTabId = useAppSelector(administrationPageSelectors.activeTabId);

  const dispatch = useAppDispatch();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleOnClick = () => {
    if (activeTabId) {
      const payload = {
        tabId: activeTabId,
        functionItem: {
          id: functionID,
          functionType: functionType,
          iconUrl,
          name,
        },
      };
      dispatch(administrationPageActions.setTabFunction(payload));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleOnClick();
    }
  };

  return (
    <motion.div
      ref={setNodeRef}
      style={style}
      animate={{
        zIndex: isDragging ? 1 : 0,
      }}
      className={clsx(styles.container, 'w-[300px] relative flex flex-col justify-between', {
        [styles.isDragging]: isDragging,
      })}
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role='button'
    >
      <div
        className={clsx(styles.dragIcon, 'absolute end-1 top-1', {
          [styles.dragIconDragging]: isDragging,
        })}
        {...attributes}
        {...listeners}
      >
        <SmallDraggableIcon />
      </div>
      <div className='flex justify-between h-[28px]'>
        <PrivateImage id={iconUrl} alt='function_logo' className={clsx(styles.icon, 'w-6 h-6')} />
        {functionType !== FunctionTypes.none && (
          <AdministrationFunctionTypeTag functionType={functionType} id={id} className='shrink-0' />
        )}
      </div>
      <Typography type='16-R' className={styles.name}>
        {name}
      </Typography>
    </motion.div>
  );
};
