import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.efficacyAssessmentModals;

const efficacyAssessmentModal = createSelector(
  [selector],
  (state) => state.efficacyAssessmentModal
);

const recordingEffectivenessModal = createSelector(
  [selector],
  (state) => state.recordingEffectivenessModal
);

export const efficacyAssessmentModalSelectors = {
  efficacyAssessmentModal,
  recordingEffectivenessModal,
};
