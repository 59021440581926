export enum Parameter {
  TableEvenBackground = 'W_TableEvenBackgroundColour',
  TableOddBackground = 'W_TableOddBackgroundColour',
  SelfAssessmentEvenBackgroundColor = 'W_SEFullListEvenItemBackgroundColour',
  SelfAssessmentOddBackgroundColor = 'W_SEFullListOddItemBackgroundColour',
  InternalDifferentiationItemEvenBackground = 'W_InternalDifferentiationEvenItemBackgroundColor',
  InternalDifferentiationItemOddBackground = 'W_InternalDifferentiationOddItemBackgroundColor',
  VocationalTrainingAreaEvenItemBackgroundColor = 'W_VocationalTrainingAreaEvenItemBackgroundColor',
  VocationalTrainingAreaOddItemBackgroundColor = 'W_VocationalTrainingAreaOddItemBackgroundColor',
  AdditionalPersonRequirementConfirmCreatingText = 'ask4saveHBbeantragtam',
  AdditionalPersonRequirementResponsibleLeaderRequired = 'Verantwortlicher4HBGIsPflicht',
  PerformanceProfileNoAllocatedCatalogs = 'PerformanceProfileNoAllocatedCatalogs',
  // Started status tag
  StartedStatusTagBgColor = 'StartedStatusTagBgColor',
  StartedStatusTagTextColor = 'StartedStatusTagTextColor',
  // Completed status tag
  CompleteStatusTagBgColor = 'CompleteStatusTagBgColor',
  CompleteStatusTagTextColor = 'CompleteStatusTagTextColor',
  // Ready for closing status tag
  ReadyForClosingStatusTagBgColor = 'ReadyForClosingStatusTagBgColor',
  ReadyForClosingStatusTagTextColor = 'ReadyForClosingStatusTagTextColor',
  // Closed status tag
  ClosedStatusTagBgColor = 'ClosedStatusTagBgColor',
  ClosedStatusTagTextColor = 'ClosedStatusTagTextColor',
  IsMnTerminWirksamkeitPflicht = 'IsMnTerminWirksamkeitPflicht',
  //Notifications
  GlobalNotificationDelay = 'GlobalNotificationDelay',
  VocationalTrainingAreaFileNotFoundNotificationDelay = 'VocationalTrainingAreaFileNotFoundNotificationDelay',
  StammdatenPersonUpdateDataNotificationDelay = 'StammdatenPersonUpdateDataNotificationDelay',
  TableCopyPopupNotificationDelay = 'TableCopyPopupNotificationDelay',
  QualificationDocumentationBlockedActionNotificationDelay = 'QualificationDocumentationBlockedActionNotificationDelay',
  MedikamentenverabreichungUpdateDataNotificationDelay = 'MedikamentenverabreichungUpdateDataNotificationDelay',
  ForderzyklusErrorNotificationDelay = 'ForderzyklusErrorNotificationDelay',
  // Functions shortcuts
  AlwaysShowPersonShortcutFunctions = 'AlwaysShowPersonShortcutFunctions',
  // Competence analysis
  allowChangeBinnDiffInEP = 'allowChangeBinnDiffInEP',
}
