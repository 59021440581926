import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import fp from 'lodash/fp';

import {
  IPersonsChecksState,
  IPersonState,
  usePersonPageStoreSelector,
} from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';

export function usePersonListForm() {
  const [personsChecksState, setPersonsChecksState] = usePersonPageStoreSelector(
    (store) => store.personsChecksState
  );

  const [filteredPersonsList] = usePersonPageStoreSelector((store) => store.filteredPersons);

  const form = useForm({ defaultValues: personsChecksState });
  const watcher = useWatch(form);

  useEffect(() => {
    if (!fp.isEqual(watcher, personsChecksState)) {
      const watcherPersons = watcher.persons;

      const contextPersons = personsChecksState?.persons;
      const watcherCheckAll = watcher.persons_check_all;

      const contextCheckAll = personsChecksState?.persons_check_all;

      if (watcherPersons) {
        let newPersons;
        if (!fp.isEqual(watcherPersons, contextPersons)) {
          newPersons = fp.cloneDeep(watcherPersons);
        } else if (!fp.isEqual(watcherCheckAll, contextCheckAll)) {
          newPersons = Object.keys(watcherPersons).reduce(
            (newFormAccumulator: IPersonState, personKey) => {
              const foundPerson = filteredPersonsList?.find(
                (person) => `person_id=${person.id}` === personKey
              );

              if (foundPerson) {
                return {
                  ...newFormAccumulator,
                  [personKey]: watcherCheckAll,
                };
              }

              return newFormAccumulator;
            },
            {}
          );
        } else {
          newPersons = fp.cloneDeep(watcherPersons);
        }

        const newForm: IPersonsChecksState = {
          persons_check_all: watcherCheckAll,
          persons: newPersons,
        };

        if (!fp.isEqual(personsChecksState, newForm)) {
          form.reset(newForm);
          setPersonsChecksState({ personsChecksState: newForm });
        }
      }
    }
  }, [form, personsChecksState, setPersonsChecksState, watcher]);

  return form;
}
