import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useImperativeHandle,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import fp from 'lodash/fp';

import { Accordion } from '@/components/Accordion/Accordion';
import { CompetenceAnalysis } from '@/components/forms/StatusReport/CompetenceAnalysis/CompetenceAnalysis';
import { CourseOfMeasures } from '@/components/forms/StatusReport/CourseOfMeasures/CourseOfMeasures';
import { EducationCoursePrognosis } from '@/components/forms/StatusReport/EducationCoursePrognosis/EducationCoursePrognosis';
import { statusReportResolver } from '@/components/forms/StatusReport/helpers/statusReportResolver';
import { ParticipantsExpectations } from '@/components/forms/StatusReport/ParticipantsExpectations/ParticipantsExpectations';
import { QualificationObjectives } from '@/components/forms/StatusReport/QualificationObjectives/QualificationObjectives';
import { useSelectedPersonForm } from '@/components/functions/IntegrationPlan/hooks/useSelectedPersonForm';
import { IntegrationPlanFormActions } from '@/components/functions/IntegrationPlan/IntegrationPlan';
import { IIntegrationPlanFormProps } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsContainer/IntegrationPlanFormsContainer';
import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';
import { useDateFormSwitch } from '@/core/hooks/useDateFormSwitch';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { statusReportSelectors } from '@/core/redux/slices/functions/integrationPlan/statusReport/selectors';
import {
  IStatusReport,
  statusReportActions,
} from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { colorTypes } from '@/styles/types';

export const StatusReport: ForwardRefExoticComponent<
  Omit<IIntegrationPlanFormProps, 'ref'> & RefAttributes<IntegrationPlanFormActions>
> = forwardRef((props, ref) => {
  const { integrationPlanID, personID } = props;

  const { t: statusReportTranslations } = useTranslation('statusReport');

  const initialValues = useAppSelector(statusReportSelectors.statusReport);

  const form = useForm<IStatusReport>({
    resolver: yupResolver(statusReportResolver(statusReportTranslations)),
  });

  useSelectedPersonForm({ form, integrationPlanID, initialValues });

  useDateFormSwitch(form, 'reportingPeriodFromTimestamp', 'reportingPeriodToTimestamp');

  const dispatch = useAppDispatch();

  const onBeforeExitCheck = () => {
    const currentValues = form.watch();

    if (fp.isEqual(JSON.stringify(currentValues), JSON.stringify(initialValues))) {
      return true;
    }

    if (!personID || integrationPlanID === null) {
      return true;
    }

    dispatch(
      statusReportActions.updateStatusReport({
        personID: personID,
        integrationPlanID: integrationPlanID,
        statusReport: currentValues,
      })
    );

    dispatch(statusReportActions.setStatusReport(null));

    return true;
  };

  useImperativeHandle(ref, () => ({
    onBeforeExitCheck: onBeforeExitCheck,
  }));

  useEffect(() => {
    if (!integrationPlanID || !personID) {
      return;
    }

    dispatch(
      statusReportActions.fetchStatusReport({
        integrationPlanID: integrationPlanID,
        personID: personID,
      })
    );
  }, [integrationPlanID, personID]);

  useEffect(() => {
    form.trigger().then();
  }, [form.watch()]);

  return (
    <FormProvider {...form}>
      <form className={'flex flex-col gap-1'} onSubmit={form.handleSubmit(() => {})}>
        <Accordion
          title={
            <Typography type={'H3'}>
              {statusReportTranslations('masterData.title.label')}
            </Typography>
          }
          isExpanded={true}
          headerClassName={`hover:bg-${colorTypes.HoverLight} py-4`}
        >
          <div className={'flex flex-row items-center gap-4 mt-2 w-2/3'}>
            <Typography>
              {statusReportTranslations('masterData.fields.documentNumber.label')}
            </Typography>
            <Input control={form.control.register('documentNumber')} readOnly={true} />
          </div>
        </Accordion>
        <CourseOfMeasures />
        <CompetenceAnalysis />
        <ParticipantsExpectations />
        <QualificationObjectives
          qualificationsDocuments={initialValues?.qualificationObjectivesDocumentations}
        />
        <EducationCoursePrognosis />
      </form>
    </FormProvider>
  );
});
