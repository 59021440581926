import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.efficacyAssessment;

const efficacyAssessment = createSelector([selector], (state) => state.efficacyAssessment);
const selectedMeasure = createSelector([selector], (state) => state.selectedMeasure);
const appointments = createSelector([selector], (state) => state.appointments);
const measureAppointments = createSelector([selector], (state) => state.measureAppointments);



export const efficacyAssessmentSelectors = {
  efficacyAssessment,
  appointments,
  measureAppointments,
  selectedMeasure
};
