import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { IQuestionnaireForm } from '@/components/functions/PerformanceProfile/Questionnaire/hooks/useQuestionnaireQuestionsListForm';
import { QuestionnaireReviewsHistory } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionItem/QuationnaireReviewsHistory/QuestionnaireReviewsHistory';
import { QuestionItemDescription } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionItem/QuestionItemDescription/QuestionItemDescription';
import { QuestionItemReviewSelect } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionItem/QuestionItemReviewSelect/QuestionItemReviewSelect';
import { statusIcons } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionsGroup/QuestionnaireQuestionsGroup';
import { Typography } from '@/components/Typography/Typography';
import { QuestionnaireQuestionsGroupStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireQustionsGroupStatusEnum';
import {
  IQuestionnaireQuestionReview,
  IQuestionnaireQuestionReviewHistoryItem,
} from '@/core/redux/slices/functions/performanceProfile/slice';

interface IQuestionnaireQuestionItem {
  id: number;
  parentID: number;
  parentIndex: number;
  index: number;
  name: string;
  reviewsList: IQuestionnaireQuestionReview[];
  reviewsHistory: IQuestionnaireQuestionReviewHistoryItem[];
  review?: number | null;
  description?: string | null;
}

export const QuestionnaireQuestionItem: React.FC<IQuestionnaireQuestionItem> = ({
  parentIndex,
  index,
  name,
  id,
  parentID,
  reviewsList,
  review,
  reviewsHistory,
}) => {
  const [questionStatus, setQuestionStatus] = useState<QuestionnaireQuestionsGroupStatus>(
    QuestionnaireQuestionsGroupStatus.Uncompleted
  );

  const formContext = useFormContext<IQuestionnaireForm>();
  const currentValues = formContext.watch();

  useEffect(() => {
    if (currentValues[parentID]) {
      if (currentValues[parentID].questions[id].review !== null) {
        setQuestionStatus(QuestionnaireQuestionsGroupStatus.Completed);
        return;
      }
      setQuestionStatus(QuestionnaireQuestionsGroupStatus.Uncompleted);
    }
  }, [currentValues]);

  return (
    <div className={`w-full py-2 grid grid-cols-12 items-start px-[30px]`}>
      <div className={'col-span-8 grid grid-cols-8 items-center'}>
        <div className={'col-span-7 flex flex-row justify-between gap-2 items-center px-[34px]'}>
          <div className={'flex flex-row gap-2 items-center'}>
            {statusIcons[questionStatus]}
            <Typography>{`${parentIndex}.${index} ${name}`}</Typography>
          </div>
          {reviewsHistory.length > 0 && (
            <QuestionnaireReviewsHistory reviewsHistory={reviewsHistory} />
          )}
        </div>
        <div className={'col-span-1 px-2'}>
          <QuestionItemReviewSelect
            id={id}
            parentID={parentID}
            reviewsList={reviewsList}
            review={review}
          />
        </div>
      </div>
      <div className={'col-span-4'}>
        <QuestionItemDescription parentID={parentID} id={id} />
      </div>
    </div>
  );
};
