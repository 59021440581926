import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Typography } from '@/components/Typography/Typography';

import { MedicationAdministration } from './MedicationAdministration/MedicationAdministration';

import styles from './styles.module.scss';

export const MedicationAdministrationPage: React.FC = () => {
  const { t: medicationAdministrationTranslations } = useTranslation('medicationAdministration');

  return (
    <div className={'px-[40px] w-full flex flex-col'}>
      <div className='py-5'>
        <Typography type='H1'>{medicationAdministrationTranslations('title.label')}</Typography>
      </div>

      <div className={clsx(styles.container, 'overflow-hidden w-full')}>
        <MedicationAdministration />
      </div>
    </div>
  );
};
