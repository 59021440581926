import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import clsx from 'clsx';

import { useQuestionnaireQuestionsListForm } from '@/components/functions/PerformanceProfile/Questionnaire/hooks/useQuestionnaireQuestionsListForm';
import {
  IQuestionnaireStoreContext,
  QuestionnaireStoreProvider,
} from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireContext/QuestionnaireContext';
import { QuestionnaireFooter } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireFooter/QuestionnaireFooter';
import { QuestionnaireHeader } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireHeader/QuestionnaireHeader';
import { QuestionnaireQuestionsList } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionsList/QuestionnaireQuestionsList';
import { QuestionnaireStatusContainer } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireStatus/QuestionnaireStatusContainer';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { QuestionnaireStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireStatusEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IQuestionnaire {
  competenceAnalysisID?: number;
}

export const Questionnaire: React.FC<IQuestionnaire> = ({ competenceAnalysisID }) => {
  const dispatch = useAppDispatch();
  const { selectedPersonID } = useSelectedPerson();

  // state
  const questionnaire = useAppSelector(performanceProfileSelectors.questionnaire);
  const questionnaireStatus = useAppSelector(performanceProfileSelectors.questionnaireStatus);
  // loading
  const loadingStatus = useAppSelector(performanceProfileSelectors.questionnaireLock);
  const completeLoadingStatus = useAppSelector(
    performanceProfileSelectors.completeQuestionnaireLock
  );

  const { form } = useQuestionnaireQuestionsListForm(
    questionnaire?.competencies ?? []
  );

  useEffect(() => {
    if (!selectedPersonID || !competenceAnalysisID) {
      return;
    }

    dispatch(performanceProfileActions.setSelectedDetailID(competenceAnalysisID));
  }, []);

  useEffect(() => {
    if (!selectedPersonID || !competenceAnalysisID) {
      return;
    }

    dispatch(
      performanceProfileActions.fetchQuestionnaire({
        personID: selectedPersonID,
        competenceAnalysisID: competenceAnalysisID,
      })
    );
  }, [selectedPersonID, competenceAnalysisID]);

  useEffect(() => {
    return () => {
      dispatch(performanceProfileActions.setQuestionnaire(null));
      dispatch(performanceProfileActions.setSelectedDetailID(null));
    };
  }, []);

  const initialContextState: IQuestionnaireStoreContext = {
    isFormEditable: questionnaireStatus !== QuestionnaireStatus.ReadyForClosing,
    collapseTrigger: false,
    expandedGroup: null,
  };

  return (
    <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
      <QuestionnaireStoreProvider state={initialContextState}>
        <div className={'flex flex-col w-full h-full'}>
          <div
            className={clsx(
              'flex-grow flex flex-col gap-2 overflow-hidden',
              completeLoadingStatus === LoadingStatus.LOADING && 'opacity-60 pointer-events-none'
            )}
          >
            <QuestionnaireStatusContainer status={questionnaireStatus} />
            <QuestionnaireHeader />
            <FormProvider {...form}>
              {questionnaire && questionnaire.competencies.length > 0 && (
                <QuestionnaireQuestionsList questionsGroups={questionnaire.competencies} />
              )}
            </FormProvider>
          </div>
        </div>
      </QuestionnaireStoreProvider>
    </Skeleton>
  );
};
