import React from 'react';

import { QualificationObjectivesContent } from '@/components/Modals/Modals/functions/integrationPlanModals/QualificationObjectivesModal/QualificationObjectivesContent/QualificationObjectivesContent';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';

export const QualificationObjectivesModal: React.FC = () => {
  const { isRender: isOpen, payload } = useAppSelector(
    integrationPlanModalsSelectors.qualificationObjectivesModal
  );

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(integrationPlanModalsActions.closeQualificationObjectivesModal());
  };

  if (isOpen) {
    return (
      <QualificationObjectivesContent
        onExit={handleExit}
        integrationPlanID={payload?.integrationPlanID}
        personID={payload?.personID}
      />
    );
  }

  return null;
};
