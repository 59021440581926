import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { useIntegrationPlanLock } from '@/components/functions/IntegrationPlan/hooks/useIntegrationPlanLock';
import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { ICompetenceAnalysis } from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/competenceAnalysisSlice';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';

interface IHeader {
  personID?: number | null;
  importDate?: string | null;
}

export const Header: React.FC<IHeader> = ({ personID, importDate }) => {
  const { t: competenceAnalysisTranslations } = useTranslation('competenceAnalysis', {
    keyPrefix: 'header',
  });

  const isIntegrationPlanLocked = useIntegrationPlanLock();

  const dispatch = useAppDispatch();

  const handleOpenModal = (event: React.MouseEvent) => {
    if (personID) {
      event.preventDefault();
      dispatch(integrationPlanModalsActions.openCompetenceAnalysisImportModal({ personID }));
    }
  };

  const { control, watch, setValue } = useFormContext<ICompetenceAnalysis>();

  const isOtherTestprocedureV1 = watch('isOtherTestProcedureV1');
  const isOtherTestprocedureV2 = watch('isOtherTestProcedureV2');

  useEffect(() => {
    if (!isOtherTestprocedureV1) {
      setValue('designationOtherTestProcedureV1', null);
    }

    if (!isOtherTestprocedureV2) {
      setValue('designationOtherTestProcedureV2', null);
    }
  }, [isOtherTestprocedureV1, isOtherTestprocedureV2]);

  return (
    <div className={'flex flex-col w-full'}>
      <Typography type={'H3'}>{competenceAnalysisTranslations('title.label')}</Typography>
      <div className={'flex-grow flex flex-row w-full  gap-x-4'}>
        <div className={'mt-4 flex flex-row flex-grow'}>
          <div className={'flex flex-col w-full gap-3'}>
            <div
              className={
                'flex-grow w-full grid grid-cols-[max-content,max-content,max-content] items-center gap-x-4 gap-y-2'
              }
            >
              <CheckboxItem
                label={competenceAnalysisTranslations('fields.melba.label')}
                fieldName={'melba'}
                className={'justify-start'}
              />
              <CheckboxItem
                label={competenceAnalysisTranslations('fields.pac.label')}
                fieldName={'pac'}
                className={'justify-start'}
              />
              <CheckboxItem
                label={competenceAnalysisTranslations('fields.ida.label')}
                fieldName={'ida'}
                className={'justify-start'}
              />
              <CheckboxItem
                label={competenceAnalysisTranslations('fields.melbaSl.label')}
                fieldName={'melbaSl'}
                className={'justify-start'}
              />
              <CheckboxItem
                label={competenceAnalysisTranslations('fields.detmolderLearningPathModel.label')}
                fieldName={'detmolderLearningPathModel'}
                className={'justify-start'}
              />
              <CheckboxItem
                label={competenceAnalysisTranslations('fields.hamet.label')}
                fieldName={'hamet'}
                className={'justify-start'}
              />
              <CheckboxItem
                label={competenceAnalysisTranslations('fields.mast.label')}
                fieldName={'mast'}
                className={'justify-start'}
              />
              <CheckboxItem
                label={competenceAnalysisTranslations('fields.isOwnTestProcedure.label')}
                fieldName={'isOwnTestProcedure'}
                className={'justify-start'}
              />
              <div>
                <CheckboxItem
                  label={competenceAnalysisTranslations('fields.imba.label')}
                  fieldName={'imba'}
                  className={'justify-start'}
                />
              </div>
            </div>
            <div className={'grid grid-cols-[max-content,auto] items-center gap-3 w-full'}>
              <CheckboxItem
                label={competenceAnalysisTranslations('fields.isOtherTestProcedureV1.label')}
                fieldName={'isOtherTestProcedureV1'}
                className={'justify-between'}
              />
              <div className={'flex flex-row gap-3 items-center w-full'}>
                <Typography>
                  {competenceAnalysisTranslations('fields.designationOtherTestProcedureV1.label')}
                </Typography>
                <Input
                  control={control.register('designationOtherTestProcedureV1')}
                  readOnly={!watch('isOtherTestProcedureV1')}
                  containerClassName={'w-full'}
                />
              </div>
              <CheckboxItem
                label={competenceAnalysisTranslations('fields.isOtherTestProcedureV2.label')}
                fieldName={'isOtherTestProcedureV2'}
                className={'justify-start'}
              />
              <div className={'flex flex-row gap-3 items-center'}>
                <Typography>
                  {competenceAnalysisTranslations('fields.designationOtherTestProcedureV2.label')}
                </Typography>
                <Input
                  control={control.register('designationOtherTestProcedureV2')}
                  readOnly={!watch('isOtherTestProcedureV2')}
                  containerClassName={'w-full'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={'flex flex-col gap-2 mt-4'}>
          {importDate && (
            <Typography>
              {`${competenceAnalysisTranslations('reviewsFrom.label')} ${importDate}`}{' '}
            </Typography>
          )}
          <Button
            onClick={handleOpenModal}
            className={'flex items-center justify-center'}
            disabled={isIntegrationPlanLocked}
          >
            {competenceAnalysisTranslations('buttons.readReviews.label')}
          </Button>
          <Typography>{competenceAnalysisTranslations('reportID.label')}</Typography>
          <Input control={control.register('lfdNr')} />
        </div>
      </div>
    </div>
  );
};
