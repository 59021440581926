import { useEffect, useMemo } from 'react';
import fp from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { medicationAdministrationActions } from '@/core/redux/slices/medicationAdministration/medicationAdministrationSlice';
import { medicationAdministrationSelectors } from '@/core/redux/slices/medicationAdministration/selectors';

export default function useFetchedAdministrationDepartments() {
  const dispatch = useAppDispatch();
  const administrationDepartments = useAppSelector(medicationAdministrationSelectors.departments);

  useEffect(() => {
    if (fp.isEmpty(administrationDepartments)) {
      dispatch(medicationAdministrationActions.fetchDepartments());
    }
  }, [dispatch, administrationDepartments]);

  return { administrationDepartments };
}
