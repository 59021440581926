import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { actionCourseActions } from '@/core/redux/slices/functions/integrationPlan/actionCourse/actionCourseSlice';
import { competenceAnalysisActions } from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/competenceAnalysisSlice';
import { generalDataActions } from '@/core/redux/slices/functions/integrationPlan/generalData/generalDataSlice';
import {
  ICheckIntegrationPlanUpdatePayload,
  integrationPlanActions,
} from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import { participantExpectationActions } from '@/core/redux/slices/functions/integrationPlan/participantExpectation/participantExpectationSlice';
import { qualificationDocumentationActions } from '@/core/redux/slices/functions/integrationPlan/qualificationDocumentation/qualificationDocumentationSlice';
import { statusReportActions } from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { summaryFAActions } from '@/core/redux/slices/functions/integrationPlan/summaryFA/summaryFASlice';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import i18n from '@/i18n';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type IntegrationPlanIDResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan',
  'get',
  '200'
>;

function* fetchIntegrationPlanID(
  action: PayloadAction<number>
): Generator<any, void, IntegrationPlanIDResponse> {
  yield put(integrationPlanActions.setIntegrationPlanLock(LoadingStatus.LOADING));
  try {
    const response = yield call(restCall, '/integration_plan', 'get', {
      query: {
        person_id: action.payload,
      },
      ...authAdd(),
    });

    yield put(integrationPlanActions.setIntegrationPlanID(response.integration_plan.id));
    yield put(integrationPlanActions.setIntegrationPlanLock(LoadingStatus.LOADED));
  } catch (error) {
    yield put(integrationPlanActions.setIntegrationPlanLock(LoadingStatus.ERROR));
    console.log('Error on integration plan ID fetching');
  }
}

function* setIntegrationPlanToInitial() {
  yield put(generalDataActions.setGeneralData(null));
  yield put(generalDataActions.setUpdatedData(null));
  yield put(actionCourseActions.setActionCourse(null));
  yield put(competenceAnalysisActions.setCompetenceAnalysis(null));
  yield put(participantExpectationActions.setParticipantExpectation(null));
  yield put(qualificationDocumentationActions.setQualificationDocumentation(null));
  yield put(summaryFAActions.setSummaryFA(null));
  yield put(statusReportActions.setStatusReport(null));
}

function* checkIntegrationPlanUpdate(action: PayloadAction<ICheckIntegrationPlanUpdatePayload>) {
  const { integrationPlanID, updateResponse } = action.payload;

  const isUpdateResponseValid = typeof updateResponse === 'number' && !isNaN(updateResponse);

  if (!isUpdateResponseValid) {
    return;
  }

  if (updateResponse === integrationPlanID) {
    return;
  }

  yield put(integrationPlanActions.setIntegrationPlanToInitial());
  yield put(integrationPlanActions.setIntegrationPlanVersionDate(null));
  yield put(
    notificationsActions.showNotification({
      notification: {
        title: i18n.t(
          'integrationPlanNotifications:warnings.integrationPlanChangedToInProgress.title.label'
        ),
        description: i18n.t(
          'integrationPlanNotifications:warnings.integrationPlanChangedToInProgress.description.label'
        ),
      },
    })
  );

  yield put(integrationPlanActions.setIntegrationPlanID(updateResponse));
}

export const integrationPlanSagas = [
  takeLatest(integrationPlanActions.fetchIntegrationPlanID, fetchIntegrationPlanID),
  takeLatest(integrationPlanActions.setIntegrationPlanToInitial, setIntegrationPlanToInitial),
  takeLatest(integrationPlanActions.checkIntegrationPlanUpdate, checkIntegrationPlanUpdate),
];
