import React from 'react';
import { ClassNameValue, twMerge } from 'tailwind-merge';

import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import { Typography } from '@/components/Typography/Typography';

export interface IBreadcrumbItem {
  id: number | undefined;
  label: string;
}

interface IMeasureBreadcrumbs {
  items: IBreadcrumbItem[];
  onItemClick?: (id: number | undefined) => void;
  className?: ClassNameValue;
}

export const MeasureBreadcrumbs: React.FC<IMeasureBreadcrumbs> = ({
  items,
  onItemClick,
  className,
}) => {
  return (
    <nav
      className={twMerge('flex flex-wrap gap-2 items-center', className)}
      aria-label='Breadcrumb'
    >
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;

        return (
          <React.Fragment key={item.id}>
            <div className='flex gap-2 items-center'>
              <Typography
                onClick={() => !isLastItem && onItemClick && onItemClick(item.id)}
                type='14-M'
                className={twMerge(!isLastItem && 'cursor-pointer', isLastItem && 'text-disabled')}
              >
                {item.label}
              </Typography>
            </div>
            {!isLastItem && <ArrowRightIcon />}
          </React.Fragment>
        );
      })}
    </nav>
  );
};
