import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { integrationPlanSelectors } from '@/core/redux/slices/functions/integrationPlan/selectors';

export const IntegrationPlanVersionDate: React.FC = () => {
  const { t: integrationPlanTranslations } = useTranslation('integrationPlan');

  const versionDate = useAppSelector(integrationPlanSelectors.integrationPlanVersionDate);

  const formattedVersionDate =
    versionDate === null
      ? integrationPlanTranslations('fields.inProgress.label')
      : moment(versionDate).format('DD.MM.YYYY, HH:mm');

  return (
    <Typography type={'14-R'} color={'Secondary'} className={'mr-auto w-full'}>
      {`${integrationPlanTranslations('fields.versionDate.label')} ${formattedVersionDate}`}
    </Typography>
  );
};
