import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { workingTimeSelectors } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/selectors';
import {
  IWorkingTime,
  workingTimeActions,
} from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const WorkingTimeContainerFooter: React.FC = () => {
  const { t: workingTimeTranslations } = useTranslation('workingTime', { keyPrefix: 'buttons' });

  const {
    watch,
    reset,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useFormContext<IWorkingTime>();
  const currentValues = watch();

  const updateWorkingTimeLock = useAppSelector(workingTimeSelectors.updateWorkingTimeLock);

  const isLoading = updateWorkingTimeLock === LoadingStatus.LOADING;
  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  const handleReset = () => {
    reset();
  };

  const onSubmit = (data: IWorkingTime) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      workingTimeActions.updateWorkingTime({
        personID: selectedPersonID,
        workingTime: data,
      })
    );
  };

  useEffect(() => {
    if (updateWorkingTimeLock === LoadingStatus.LOADED) {
      reset(currentValues);
    }
  }, [updateWorkingTimeLock]);

  return (
    <div className={'flex flex-row gap-3 self-end mt-auto'}>
      <Button
        disabled={!isDirty || isLoading || !isValid}
        type={'submit'}
        onClick={handleSubmit(onSubmit)}
      >
        {isLoading ? <SpinnerIcon className={'w-[16px]'} /> : workingTimeTranslations('save.label')}
      </Button>
      <Button buttonVariant={'Secondary'} disabled={isLoading} onClick={handleReset}>
        {workingTimeTranslations('cancel.label')}
      </Button>
    </div>
  );
};
