import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { LeftSidebarMenuItemTooltip } from '@/components/LeftSidebar/LeftSidebarMenuItemTooltip';
import { useHover } from '@/core/hooks/useHover';

interface IMenuItem {
  name: string;
  icon: React.ReactNode;
  link: string;
  pattern: string;
  isExpanded: boolean;
}

export const MenuItem: React.FC<IMenuItem> = ({ name, icon, link, pattern, isExpanded }) => {
  const hoverRef = useRef<HTMLButtonElement>(null);
  const isHovered = useHover(hoverRef);

  const location = useLocation();
  const navigate = useNavigate();

  const tooltipID = `left-sidebar-tooltip-${name}`;

  const handleClick = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <>
      <Button
        buttonVariant={'Sidebar'}
        buttonStyle={'WithIcon'}
        className={clsx(
          location.pathname === pattern && 'text-blue bg-hover',
          'justify-start gap-3'
        )}
        onClick={handleClick}
        ref={hoverRef}
        id={tooltipID}
      >
        <div className={isHovered || location.pathname === pattern ? 'text-blue' : 'text-disabled'}>
          {icon}
        </div>
        {isExpanded && name}
      </Button>
      {!isExpanded && <LeftSidebarMenuItemTooltip id={tooltipID} tooltipMessage={name} />}
    </>
  );
};
