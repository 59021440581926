import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@/components/Input/Input';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { actionCourseDateRangeTypes } from '@/core/enums/actionCourseDateRangeEnum';
import { IActionCourse } from '@/core/redux/slices/functions/integrationPlan/actionCourse/actionCourseSlice';

export const DateBlock: React.FC = () => {
  const { t: actionCourseTranslations } = useTranslation('actionCourse');

  const { control, watch } = useFormContext<IActionCourse>();
  const selectedType = watch('dateBlock');

  const options: IRadioButtonGroupItem[] = [
    {
      value: actionCourseDateRangeTypes.isEndeEV,
      element: <Typography>{actionCourseTranslations('fields.isEndeEV.label')}</Typography>,
    },
    {
      value: actionCourseDateRangeTypes.isEndeBBB1,
      element: <Typography>{actionCourseTranslations('fields.isEndeBBB1.label')}</Typography>,
    },
    {
      value: actionCourseDateRangeTypes.isIndividualAppointment,
      element: (
        <div className={'flex flex-row gap-2 items-center'}>
          <Typography>
            {actionCourseTranslations('fields.isIndividualAppointment.label')}
          </Typography>
          <div className={'flex flex-row gap-2 items-center ms-[50px]'}>
            <Typography>
              {actionCourseTranslations('fields.individualAppointmentReason.label')}
            </Typography>
            <Input
              control={control.register('individualAppointmentReason')}
              readOnly={selectedType !== actionCourseDateRangeTypes.isIndividualAppointment}
            />
          </div>
        </div>
      ),
    },
    {
      value: actionCourseDateRangeTypes.isEndeBBB2,
      element: <Typography>{actionCourseTranslations('fields.isEndeBBB2.label')}</Typography>,
    },
  ];

  return (
    <RadioButtonGroup
      options={options}
      name={'dateBlock'}
      className={'flex flex-col gap-2'}
      itemClassName={'flex flex-row items-center gap-2'}
    />
  );
};
