import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { PersonWidgetIcon } from '@/components/Icons/PersonWidgetIcon';
import { Typography } from '@/components/Typography/Typography';
import styles from '@/components/views/person/PersonPage/PersonView/PersonList/styles.module.scss';

export const ErrorPlaceholder: React.FC = () => {
  const { t: favoritePersonsWidgetTranslations } = useTranslation('favoritePersonWidget');

  return (
    <div className={clsx(styles.placeHolder, 'flex flex-col items-center text-center')}>
      <PersonWidgetIcon />
      <Typography>
        {favoritePersonsWidgetTranslations('placeholders.error.label', { 1: <br /> })}
      </Typography>
    </div>
  );
};
