import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isSameDay, parse } from 'date-fns';

import { Button } from '@/components/Button/Button';
import { ActionCourse } from '@/components/forms/ActionCourse/ActionCourse';
import { CompetenceAnalysis } from '@/components/forms/CompetenceAnalysis/CompetenceAnalysis';
import { GeneralData } from '@/components/forms/GeneralData/GeneralData';
import { ParticipantExpectation } from '@/components/forms/ParticipantExpectation/ParticipantExpectation';
import { QualificationDocumentation } from '@/components/forms/QualificationDocumentation/QualificationDocumentation';
import { StatusReport } from '@/components/forms/StatusReport/StatusReport';
import { SummaryFA } from '@/components/forms/SummaryFA/SummaryFA';
import { useIntegrationPlanLock } from '@/components/functions/IntegrationPlan/hooks/useIntegrationPlanLock';
import {
  IntegrationPlanFormsContainer,
  IntegrationPlanTabs,
} from '@/components/functions/IntegrationPlan/IntegrationPlanFormsContainer/IntegrationPlanFormsContainer';
import { IntegrationPlanFormsNavigation } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsNavigation/IntegrationPlanFormsNavigation';
import { IntegrationPlanVersionDate } from '@/components/functions/IntegrationPlan/IntegrationPlanVersionDate/IntegrationPlanVersionDate';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import { PrintIcon } from '@/components/Icons/PrintIcon';
import {
  IPermissions,
  PermissionsProvider,
} from '@/components/PermissionsContext/PermissionsContext';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionFooter } from '@/components/wrapper/FunctionWrapper/FunctionFooter/FunctionFooter';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { FRONTEND_DATE_FORMAT } from '@/core/constants/dateFormat';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { actionCourseActions } from '@/core/redux/slices/functions/integrationPlan/actionCourse/actionCourseSlice';
import { actionCourseSelectors } from '@/core/redux/slices/functions/integrationPlan/actionCourse/selectors';
import { integrationPlanActions } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import { integrationPlanSelectors } from '@/core/redux/slices/functions/integrationPlan/selectors';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { colorTypes } from '@/styles/types';

export interface IIntegrationPlanForm {
  title: string;
  form: React.ReactNode;
  type: IntegrationPlanTabs;
  shortCut: string;
}

export interface IntegrationPlanFormActions {
  onBeforeExitCheck: () => boolean;
}

export const IntegrationPlan: React.FC = () => {
  const { t: integrationPlanTranslations } = useTranslation('integrationPlan');

  const [activeTab, setActiveTab] = useState<IntegrationPlanTabs>(IntegrationPlanTabs.GeneralData);
  const formRef = useRef<IntegrationPlanFormActions>(null);

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const integrationPlanID = useAppSelector(integrationPlanSelectors.integrationPlanID);
  const actionCourse = useAppSelector(actionCourseSelectors.actionCourse);

  const isIntegrationPlanLocked = useIntegrationPlanLock();

  const integrationPlanPermissions: IPermissions = {
    all: {
      isReadOnly: isIntegrationPlanLocked,
      isVisible: true,
    },
  };

  const handleTabSwitch = (newTab: IntegrationPlanTabs) => {
    if (newTab === activeTab) {
      return;
    }

    if (formRef.current) {
      formRef.current.onBeforeExitCheck() ? setActiveTab(newTab) : undefined;
    }
  };

  useEffect(() => {
    if (selectedPerson) {
      dispatch(integrationPlanActions.fetchIntegrationPlanID(selectedPerson.id));
    }
  }, [selectedPerson]);

  useEffect(() => {
    if (selectedPerson && integrationPlanID) {
      dispatch(
        actionCourseActions.fetchActionCourse({
          integrationPlanID,
          personID: selectedPerson.id,
        })
      );
    }
  }, [selectedPerson, integrationPlanID]);

  const dispatch = useAppDispatch();

  const handleCompleteIntegrationPlan = () => {
    if (!integrationPlanID || !selectedPerson?.id) {
      return;
    }

    if (actionCourse?.createdTimestamp) {
      const createdDate = parse(actionCourse?.createdTimestamp, FRONTEND_DATE_FORMAT, new Date());
      const today = new Date();

      if (createdDate && !isSameDay(createdDate, today)) {
        dispatch(
          integrationPlanModalsActions.openActionCourseCreatedDateModal({
            integrationPlanID: integrationPlanID,
            personID: selectedPerson.id,
            createdTimestamp: createdDate.toISOString(),
          })
        );
        return;
      }
    }

    dispatch(integrationPlanModalsActions.openCreateIntegrationPlanDocumentsModal());
  };

  const openVersionHistoryModal = () => {
    if (selectedPerson) {
      dispatch(
        integrationPlanModalsActions.openIntegrationPlanVersionHistoryModal({
          personID: selectedPerson?.id,
        })
      );
    }
  };

  const integrationPlanForms: Record<IntegrationPlanTabs, IIntegrationPlanForm> = useMemo(
    () => ({
      [IntegrationPlanTabs.GeneralData]: {
        title: integrationPlanTranslations('forms.generalData.label'),
        form: (
          <GeneralData
            integrationPlanID={integrationPlanID}
            personID={selectedPerson?.id}
            ref={formRef}
          />
        ),
        type: IntegrationPlanTabs.GeneralData,
        shortCut: integrationPlanTranslations('forms.generalData.shortCut.label'),
      },
      [IntegrationPlanTabs.ActionCourse]: {
        title: integrationPlanTranslations('forms.actionCourse.label'),
        form: (
          <ActionCourse
            integrationPlanID={integrationPlanID}
            personID={selectedPerson?.id}
            ref={formRef}
          />
        ),
        type: IntegrationPlanTabs.ActionCourse,
        shortCut: integrationPlanTranslations('forms.actionCourse.shortCut.label'),
      },
      [IntegrationPlanTabs.CompetenceAnalysis]: {
        title: integrationPlanTranslations('forms.competenceAnalysis.label'),
        form: (
          <CompetenceAnalysis
            integrationPlanID={integrationPlanID}
            personID={selectedPerson?.id}
            ref={formRef}
          />
        ),
        type: IntegrationPlanTabs.CompetenceAnalysis,
        shortCut: integrationPlanTranslations('forms.competenceAnalysis.shortCut.label'),
      },
      [IntegrationPlanTabs.ParticipantExpectation]: {
        title: integrationPlanTranslations('forms.participantExpectations.label'),
        form: (
          <ParticipantExpectation
            integrationPlanID={integrationPlanID}
            personID={selectedPerson?.id}
            ref={formRef}
          />
        ),
        type: IntegrationPlanTabs.ParticipantExpectation,
        shortCut: integrationPlanTranslations('forms.participantExpectations.shortCut.label'),
      },
      [IntegrationPlanTabs.QualificationDocumentation]: {
        title: integrationPlanTranslations('forms.qualificationDocumentation.label'),
        form: (
          <QualificationDocumentation
            integrationPlanID={integrationPlanID}
            personID={selectedPerson?.id}
            ref={formRef}
          />
        ),
        type: IntegrationPlanTabs.QualificationDocumentation,
        shortCut: integrationPlanTranslations('forms.qualificationDocumentation.shortCut.label'),
      },
      [IntegrationPlanTabs.SummaryFA]: {
        title: integrationPlanTranslations('forms.summaryFA.label'),
        form: (
          <SummaryFA
            integrationPlanID={integrationPlanID}
            personID={selectedPerson?.id}
            ref={formRef}
          />
        ),
        type: IntegrationPlanTabs.SummaryFA,
        shortCut: integrationPlanTranslations('forms.summaryFA.shortCut.label'),
      },
      [IntegrationPlanTabs.StatusReport]: {
        title: integrationPlanTranslations('forms.statusReport.label'),
        form: (
          <StatusReport
            integrationPlanID={integrationPlanID}
            personID={selectedPerson?.id}
            ref={formRef}
          />
        ),
        type: IntegrationPlanTabs.StatusReport,
        shortCut: integrationPlanTranslations('forms.statusReport.shortCut.label'),
      },
    }),
    [integrationPlanID, selectedPerson, integrationPlanTranslations]
  );

  return (
    <FunctionWrapper>
      <FunctionContent withFooter>
        <IntegrationPlanFormsNavigation
          activeTab={activeTab}
          handeTabChange={handleTabSwitch}
          integrationPlanForms={integrationPlanForms}
        />
        <div
          className={`mx-5 flex flex-row items-center justify-end border-y border-y-${colorTypes.Stroke}  `}
        >
          <IntegrationPlanVersionDate />
          <Button
            buttonVariant={'TextBlue'}
            buttonStyle={'WithIcon'}
            onClick={openVersionHistoryModal}
            className={'shrink-0'}
          >
            {integrationPlanTranslations('buttons.versionHistory.label')}
            <ArrowRightIcon />
          </Button>
        </div>
        <PermissionsProvider permissions={integrationPlanPermissions}>
          <IntegrationPlanFormsContainer
            integrationPlanForms={integrationPlanForms}
            activeTab={activeTab}
          />
        </PermissionsProvider>
      </FunctionContent>
      <FunctionFooter>
        <Button
          onClick={handleCompleteIntegrationPlan}
          className={'flex gap-3'}
          disabled={isIntegrationPlanLocked}
        >
          {integrationPlanTranslations('buttons.printPlan.label')}
          <PrintIcon />
        </Button>
      </FunctionFooter>
    </FunctionWrapper>
  );
};
