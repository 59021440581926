import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import type oas from '@/services/rest/base/openapi';
import { Camelize } from '@/types/camelize';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  costCentersActions,
  IAvailableUserDepartmentsFetchPayload,
  IUserDepartmentAddPayload,
  IUserDepartmentDeletePayload,
  IUsersByDepartmentFetchPayload,
} from './costCentersSlice';

// types

type UserDepartmentsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/department/user/available',
  'get',
  '200'
>;
type AddUserDepartmentRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/department/user',
  'post'
>;
type DeleteUserDepartmentRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/department/user',
  'delete'
>;
type UsersByDepartmentResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/user/department',
  'get',
  '200'
>;

// sagas

function* fetchAvailableUserDepartments(
  action: PayloadAction<IAvailableUserDepartmentsFetchPayload>
): Generator<any, void, UserDepartmentsResponse> {
  const { userID, isOnlyAdded } = action.payload;

  yield put(costCentersActions.setAvailableUserDepartmentsLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/department/user/available', 'get', {
      query: {
        user_id: userID,
        only_added: isOnlyAdded,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<UserDepartmentsResponse> = toCamelCase(response);
    const userDepartments = camelizeResponse.departments;

    yield put(costCentersActions.setAvailableUserDepartments(userDepartments));
    yield put(costCentersActions.setAvailableUserDepartmentsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on available user departments fetching');
    yield put(costCentersActions.setAvailableUserDepartmentsLock(LoadingStatus.ERROR));
  }
}

function* addUserDepartment(
  action: PayloadAction<IUserDepartmentAddPayload>
): Generator<any, void, any> {
  const { userID, departmentID } = action.payload;

  yield put(costCentersActions.setUpdateUserDepartmentsLock(LoadingStatus.LOADING));

  try {
    const request: AddUserDepartmentRequest = {
      json: {
        user_id: userID,
        department_id: departmentID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/department/user',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    yield put(costCentersActions.setUpdateUserDepartmentsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on add user department', error);
    yield put(costCentersActions.setUpdateUserDepartmentsLock(LoadingStatus.ERROR));
  }
}

function* deleteUserDepartment(
  action: PayloadAction<IUserDepartmentDeletePayload>
): Generator<any, void, any> {
  const { userID, departmentID } = action.payload;

  yield put(costCentersActions.setUpdateUserDepartmentsLock(LoadingStatus.LOADING));

  try {
    const request: DeleteUserDepartmentRequest = {
      json: {
        user_id: userID,
        department_id: departmentID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/department/user',
      'delete',
      {
        ...request,
      },
      null,
      true
    );
    yield put(costCentersActions.setUpdateUserDepartmentsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on delete user department', error);
    yield put(costCentersActions.setUpdateUserDepartmentsLock(LoadingStatus.ERROR));
  }
}

function* fetchDepartmentUsers(
  action: PayloadAction<IUsersByDepartmentFetchPayload>
): Generator<any, void, UsersByDepartmentResponse> {
  const { departmentID, isAdded } = action.payload;

  yield put(costCentersActions.setDepartmentUsersLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/user/department', 'get', {
      query: {
        department_id: departmentID,
        is_added: isAdded,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<UsersByDepartmentResponse> = toCamelCase(response);
    const departmentUsers = camelizeResponse.users;

    yield put(costCentersActions.setDepartmentUsers(departmentUsers));
    yield put(costCentersActions.setDepartmentUsersLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on fetch department users');
    yield put(costCentersActions.setDepartmentUsersLock(LoadingStatus.ERROR));
  }
}

export const costCentersSagas = [
  takeLatest(costCentersActions.fetchAvailableUserDepartments, fetchAvailableUserDepartments),
  takeLatest(costCentersActions.addUserDepartment, addUserDepartment),
  takeLatest(costCentersActions.deleteUserDepartment, deleteUserDepartment),
  takeLatest(costCentersActions.fetchDepartmentUsers, fetchDepartmentUsers),
];
