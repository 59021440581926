import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { ExternalSelectForm } from '@/components/Select/form/ExternalSelectForm';
import { TextArea } from '@/components/TextArea/TextArea';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { evaluateGoalsActions } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/evaluateGoalsSlice';
import { evaluateGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/selectors';

export interface IGoalCheckFormFields {
  checkedAtDate: string;
  achievementLevel: string;
  note?: string | null;
  nextAction: string;
  nextCheckDate?: string;
}

interface IGoalCheckForm {
  className?: string;
  smartGoalID?: number;
}

export const GoalCheckForm: React.FC<IGoalCheckForm> = ({ className, smartGoalID }) => {
  const { t: smartGoalModalTranslations } = useTranslation('displayGoalCheckModal');

  const form = useFormContext<IGoalCheckFormFields>();
  const { control, watch, setValue } = form;

  const dispatch = useAppDispatch();
  const nextExpectedDate = useAppSelector(evaluateGoalsSelectors.nextExpectedCheckDate);

  const nextActionValue = watch('nextAction');
  const checkedAtDateValue = watch('checkedAtDate');

  const achievementLevelOptions: IRadioButtonGroupItem[] = [
    {
      value: '1',
      element: 'ja',
    },
    {
      value: '2',
      element: 'teilweise',
    },
    {
      value: '3',
      element: 'nein',
    },
  ];

  useEffect(() => {
    if (!checkedAtDateValue || !smartGoalID) {
      return;
    }

    dispatch(
      evaluateGoalsActions.fetchNextExpectedDate({
        smartGoalID: smartGoalID,
        currentCheckDate: checkedAtDateValue,
      })
    );
  }, [checkedAtDateValue]);

  useEffect(() => {
    if (!nextExpectedDate || nextActionValue !== '1') {
      return;
    }

    setValue('nextCheckDate', nextExpectedDate);
  }, [nextExpectedDate, nextActionValue]);

  return (
    <form className={twMerge('flex flex-col gap-4', className)}>
      <DatePicker
        control={control.register('checkedAtDate')}
        label={smartGoalModalTranslations('fields.checkedAtDate.label')}
      />

      <div>
        {smartGoalModalTranslations('fields.achievementLevel.label')} *
        <RadioButtonGroup options={achievementLevelOptions} name={'achievementLevel'} />
      </div>

      <TextArea
        control={control.register('note')}
        label={smartGoalModalTranslations('fields.note.label')}
      />
      <ExternalSelectForm
        control={control.register('nextAction')}
        tableName={DropdownItemsByFetch.SMART_GOAL_CHECK_ACTION}
        isFlexible
        label={smartGoalModalTranslations('fields.nextAction.label')}
      />
      {nextActionValue === '1' && (
        <DatePicker
          control={control.register('nextCheckDate')}
          label={smartGoalModalTranslations('fields.nextCheckDate.label') + ' *'}
        />
      )}
    </form>
  );
};
