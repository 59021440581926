import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAddressModalPayload {
  personID: number;
  addressID: number;
}

export interface IModal {
  isRender: boolean;
  payload: IAddressModalPayload | null;
}

export interface IPersonGeneralDataModalsState {
  displayAddressModal: IModal;
  deleteAddressModal: IModal;
}

const initialState: IPersonGeneralDataModalsState = {
  displayAddressModal: {
    isRender: false,
    payload: null,
  },
  deleteAddressModal: {
    isRender: false,
    payload: null,
  },
};

export const personGeneralDataModalsSlice = createSlice({
  name: 'personGeneralDataModals',
  initialState,
  reducers: {
    //Display address modal
    openDisplayAddressModal: (state, action: PayloadAction<IAddressModalPayload>) => ({
      ...state,
      displayAddressModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeDisplayAddressModal: (state) => ({
      ...state,
      displayAddressModal: {
        isRender: false,
        payload: null,
      },
    }),
    //Delete address modal
    openDeleteAddressModal: (state, action: PayloadAction<IAddressModalPayload>) => ({
      ...state,
      deleteAddressModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeDeleteAddressModal: (state) => ({
      ...state,
      deleteAddressModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const personGeneralDataModalsReducers = personGeneralDataModalsSlice.reducer;
export const personGeneralDataModalsActions = personGeneralDataModalsSlice.actions;
