import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { UserIcon } from '@/components/Icons/UserIcon';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { costCentersActions } from '@/core/redux/slices/functions/specialistGeneralData/costCenters/costCentersSlice';
import { costCentersSelectors } from '@/core/redux/slices/functions/specialistGeneralData/costCenters/selectors';
import { specialistGeneralDataModalsActions } from '@/core/redux/slices/modalsSlice/functions/specialistGeneralData/specialistGeneralDataModalsSlice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

interface ICostCenters {
  userID: number;
}

export const CostCenters: React.FC<ICostCenters> = ({ userID }) => {
  const { t: costCentersTranslations } = useTranslation('costCenters');

  const dispatch = useAppDispatch();

  const availableUserDepartments = useAppSelector(costCentersSelectors.availableUserDepartments);
  const updateUserDepartmentsLock = useAppSelector(costCentersSelectors.updateUserDepartmentsLock);

  const [isOnlyAdded, setIsOnlyAdded] = useState(false);

  const handleAddDepartmentToUser = (departmentID: number, userID: number) => {
    dispatch(
      costCentersActions.addUserDepartment({
        userID: userID,
        departmentID: departmentID,
      })
    );
  };

  const handleRemoveDepartmentFromUser = (departmentID: number, userID: number) => {
    dispatch(
      costCentersActions.deleteUserDepartment({
        userID: userID,
        departmentID: departmentID,
      })
    );
  };

  const handleOpenAssignedSpecialistsModal = (departmentID: number) => {
    dispatch(
      specialistGeneralDataModalsActions.openAssignedSpecialistsModal({
        departmentID: departmentID,
      })
    );
  };

  const columns: ITableColumn[] = [
    {
      header: costCentersTranslations('table.columns.name.label'),
      accessor: 'name',
      className: 'w-3/4',
    },
    {
      header: costCentersTranslations('table.columns.isAdded.label'),
      accessor: 'isAdded',
      template: (value: boolean, row) => (
        <CheckboxUI
          checked={value}
          onChange={(value) => {
            value
              ? handleAddDepartmentToUser(row.id, userID)
              : handleRemoveDepartmentFromUser(row.id, userID);
          }}
        />
      ),
    },
    {
      header: '',
      accessor: '',
      isSortable: false,
      className: 'py-2',
      template: (_value, row) => (
        <>
          <Button
            buttonVariant={'Icon'}
            buttonStyle={'Icon'}
            className={`text-${colorTypes.Grey}  p-2 hover:bg-stroke rounded-md cursor-pointer`}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenAssignedSpecialistsModal(row.id);
            }}
          >
            <UserIcon />
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      costCentersActions.fetchAvailableUserDepartments({
        userID: userID,
        isOnlyAdded: isOnlyAdded,
      })
    );
  }, [userID, isOnlyAdded]);

  useEffect(() => {
    if (updateUserDepartmentsLock === LoadingStatus.LOADED) {
      dispatch(
        costCentersActions.fetchAvailableUserDepartments({
          userID: userID,
          isOnlyAdded: isOnlyAdded,
        })
      );
    }
  }, [updateUserDepartmentsLock]);

  return (
    <div className='w-full flex flex-col gap-4 h-full overflow-auto p-5'>
      <div className={`border-b border-b-${colorTypes.StrokeLight} pb-2.5 mb-5`}>
        <Typography type='H3'>{costCentersTranslations('title.label')}</Typography>
      </div>
      <div className='flex flex-col gap-4'>
        <label className='w-fit flex gap-2 cursor-pointer'>
          <CheckboxUI
            className='pointer-events-none'
            checked={isOnlyAdded}
            onChange={(value: boolean) => {
              setIsOnlyAdded(value);
            }}
          />
          <Typography>{costCentersTranslations('filters.isOnlyAdded.label')}</Typography>
        </label>
        <Table
          data={availableUserDepartments}
          columns={columns}
          selectable={false}
          className='max-h-[500px]'
        />
      </div>
    </div>
  );
};
