import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { Typography } from '@/components/Typography/Typography';
import { DateRangeType } from '@/core/enums/dateRangeTypeEnum';
import { useDateFormSwitch } from '@/core/hooks/useDateFormSwitch';
import { useAppDispatch } from '@/core/redux/hooks';
import { dashboardActions, IDateRange } from '@/core/redux/slices/dashboard/dashboardSlice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

const FROM_DATE_FIELD_NAME = 'from_date';
const TO_DATE_FIELD_NAME = 'to_date';

export const PeriodDatePicker: React.FC = () => {
  const { t: activitiesWidgetTranslations } = useTranslation('activitiesWidget', {
    keyPrefix: 'activitiesDatePickerContainer',
  });

  const dispatch = useAppDispatch();

  const form = useForm();

  useDateFormSwitch(form, FROM_DATE_FIELD_NAME, TO_DATE_FIELD_NAME);

  useEffect(() => {
    const fromDateValue = form.watch(FROM_DATE_FIELD_NAME);
    const toDateValue = form.watch(TO_DATE_FIELD_NAME);

    if (fp.isEmpty(fromDateValue) || fp.isEmpty(toDateValue)) {
      return;
    }

    if (fromDateValue > toDateValue) {
      dispatch(dashboardActions.setActivitiesLock(LoadingStatus.ERROR));
      return;
    }

    const dateRange: IDateRange = {
      fromDate: fromDateValue,
      toDate: toDateValue,
    };

    dispatch(
      dashboardActions.fetchActivities({
        dateRangeType: DateRangeType.CHOSEN_PERIOD,
        dateRange: dateRange,
      })
    );
  }, [form.watch()]);

  const isFormDirty = form.formState.isDirty;

  const handleFormReset = () => {
    form.reset({
      [FROM_DATE_FIELD_NAME]: null,
      [TO_DATE_FIELD_NAME]: null,
    });
    dispatch(dashboardActions.fetchActivities(null));
  };

  return (
    <FormProvider {...form}>
      <div className={'flex flex-row items-center justify-start flex-wrap mt-3 gap-1'}>
        <Typography color={'Primary'} type={'16-R'}>
          {activitiesWidgetTranslations('choosePeriod.label')}
        </Typography>
        <DatePicker control={form.control.register(FROM_DATE_FIELD_NAME)} />
        <div className={'h-[1px] w-[20px] border rounded-full'} />
        <DatePicker control={form.control.register(TO_DATE_FIELD_NAME)} />
        <div className={`cursor-pointer text-${colorTypes.Grey}`}>
          {isFormDirty && <CrossIcon onClick={handleFormReset} />}
        </div>
      </div>
    </FormProvider>
  );
};
