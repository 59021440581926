import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import { Typography } from '@/components/Typography/Typography';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { developmentReportSelectors } from '@/core/redux/slices/functions/developmentReport/selectors';
import { developmentReportVersionHistoryModalActions } from '@/core/redux/slices/modalsSlice/functions/developmentReport/developmentReportVersionHistory/slice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import { colorTypes } from '@/styles/types';

export const DevelopmentReportVersionNavigation: React.FC = () => {
  const { t: developmentReportTranslations } = useTranslation('developmentReport', {
    keyPrefix: 'developmentReportVersion',
  });

  const currentDevelopmentReport = useAppSelector(developmentReportSelectors.developmentReport);

  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      developmentReportVersionHistoryModalActions.openDevelopmentReportVersionHistory({
        personID: selectedPersonID,
      })
    );
  };

  return (
    <div
      className={`flex flex-row items-center justify-between p-2 border-b border-b-${colorTypes.Stroke}`}
    >
      <Typography color={'Secondary'} type={'14-M'}>{`${developmentReportTranslations(
        'date.label'
      )} ${
        currentDevelopmentReport?.printedTimestamp
          ? toClientDateInput(currentDevelopmentReport.printedTimestamp)
          : developmentReportTranslations('inProgress.label')
      }`}</Typography>
      <Button
        onClick={handleOpenModal}
        buttonStyle={'Text'}
        buttonVariant={'TextBlue'}
        className={'flex flex-row items-center'}
      >
        {developmentReportTranslations('buttons.openModal.label')}
        <ArrowRightIcon />
      </Button>
    </div>
  );
};
