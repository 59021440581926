import { TFunction } from 'i18next';
import { ObjectSchema } from 'yup';

import { IAdministrationCheckpointForm } from '@/components/administartionFunctions/checklist/AdministrationCheckpointList/hooks/useAdministrationCheckpointForm';
import { yup } from '@/core/utils/commonUtils';

export const administrationCheckpointFormValidator = (
  translations: TFunction<'administrationChecklist', undefined>
): ObjectSchema<IAdministrationCheckpointForm> =>
  yup.object().shape({
    name: yup.string().required(translations('checkpointList.fields.name.errors.required.label')),
    days: yup
      .number()
      .required(translations('checkpointList.fields.days.errors.required.label'))
      .integer(translations('checkpointList.fields.days.errors.integer.label')),
    correspondingDocumentID: yup.number().nullable(),
    infoDocumentName: yup.mixed().nullable(),
    validFrom: yup
      .string()
      .required(translations('checkpointList.fields.validFrom.errors.required.label')),
    disabilityTypeID: yup.number().nullable(),
  });
