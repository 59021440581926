import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.medicationAdministrationModals;

const confirmMarkAdministrationModal = createSelector(
  [selector],
  (state) => state.confirmMarkAdministrationModal
);

const confirmUnmarkAdministrationModal = createSelector(
  [selector],
  (state) => state.confirmUnmarkAdministrationModal
);

const medicationAdministrationHistoryModal = createSelector(
  [selector],
  (state) => state.medicationAdministrationHistoryModal
);


export const medicationAdministrationModalsSelectors = {
  confirmMarkAdministrationModal,
  confirmUnmarkAdministrationModal,
  medicationAdministrationHistoryModal
};
