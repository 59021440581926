import React from 'react';

import { IIcon } from '@/types/icon';

export const PinnedIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M5.34549 11.6669L8.49341 14.8173L6.27648 17.0361C6.02873 17.284 6.02873 17.686 6.27648 17.934C6.52424 18.182 6.92594 18.182 7.17369 17.934L9.39062 15.7153L12.5255 18.8527C12.6428 18.9715 12.7842 19.0638 12.9402 19.1232C13.0972 19.1831 13.2653 19.2084 13.4329 19.1973C13.6006 19.1862 13.7638 19.139 13.9116 19.059C14.059 18.9791 14.1876 18.8684 14.2884 18.7344C14.818 18.0339 15.8016 16.4265 15.2047 14.6982L19.0539 10.8459C19.1632 10.7376 19.25 10.6088 19.3094 10.4668C19.3692 10.3239 19.4 10.1705 19.4 10.0155C19.4 9.86059 19.3692 9.70719 19.3094 9.56426C19.25 9.42226 19.1629 9.29319 19.0536 9.1849L15.0182 5.14621C14.91 5.03683 14.7813 4.94992 14.6394 4.89047C14.4966 4.83062 14.3433 4.7998 14.1885 4.7998C14.0337 4.7998 13.8804 4.83062 13.7376 4.89047C13.5957 4.94993 13.4667 5.03712 13.3585 5.14652L9.49489 9.01324C9.25199 8.94151 8.91664 8.86898 8.51514 8.85104C7.67077 8.81331 6.55725 9.01964 5.43308 9.92879C5.30554 10.0321 5.20118 10.1611 5.12682 10.3075C5.05245 10.4539 5.00974 10.6143 5.00148 10.7783C4.99322 10.9423 5.01959 11.1062 5.07887 11.2593C5.13797 11.4119 5.22854 11.5505 5.34456 11.6659L5.34549 11.6669Z'
      fill='currentColor'
    />
  </svg>
);

export const UnPinnedIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.34549 11.6669L8.49341 14.8173L6.27648 17.0361C6.02873 17.284 6.02873 17.686 6.27648 17.934C6.52424 18.182 6.92594 18.182 7.17369 17.934L9.39062 15.7153L12.5255 18.8527C12.6428 18.9715 12.7842 19.0638 12.9402 19.1232C13.0972 19.1831 13.2653 19.2084 13.4329 19.1973C13.6006 19.1862 13.7638 19.139 13.9116 19.059C14.059 18.9791 14.1876 18.8684 14.2884 18.7344C14.818 18.0339 15.8016 16.4265 15.2047 14.6982L19.0539 10.8459C19.1632 10.7376 19.25 10.6088 19.3094 10.4668C19.3692 10.3239 19.4 10.1705 19.4 10.0155C19.4 9.86059 19.3692 9.70719 19.3094 9.56426C19.25 9.42226 19.1629 9.29319 19.0536 9.18491L15.0182 5.14621C14.91 5.03683 14.7813 4.94992 14.6394 4.89047C14.4966 4.83062 14.3433 4.7998 14.1885 4.7998C14.0337 4.7998 13.8804 4.83062 13.7376 4.89047C13.5957 4.94993 13.4667 5.03712 13.3585 5.14652L9.49489 9.01324C9.25199 8.94151 8.91664 8.86898 8.51514 8.85104C7.67077 8.81331 6.55725 9.01964 5.43308 9.92879C5.30554 10.0321 5.20118 10.1611 5.12682 10.3075C5.05245 10.4539 5.00974 10.6143 5.00148 10.7783C4.99322 10.9423 5.01959 11.1062 5.07887 11.2593C5.13797 11.4119 5.22854 11.5505 5.34456 11.6659L5.34549 11.6669ZM13.3444 17.8764L6.31985 10.8461C7.14119 10.2159 7.90515 10.0949 8.45853 10.1197C8.75078 10.1327 8.99056 10.1871 9.15372 10.2366C9.23499 10.2612 9.29615 10.2844 9.33399 10.2998C9.35288 10.3076 9.36583 10.3133 9.37243 10.3163L9.37743 10.3187C9.62086 10.4384 9.91369 10.39 10.1058 10.1977L14.1885 6.11172L18.0892 10.0155L14.0064 14.1016C13.813 14.2951 13.7654 14.5909 13.8881 14.8355C14.181 15.4192 14.1653 16.0202 14.0066 16.569C13.8609 17.0731 13.5956 17.5289 13.3444 17.8764Z'
      fill='currentColor'
    />
  </svg>
);
