import React, { useEffect } from 'react';

import { Typography } from '@/components/Typography/Typography';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsSelectors } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/selectors';
import { activityPlanningAppointmentDetailsActions } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';
import { twMerge } from '@/core/utils/tailwindUtil';

export interface ISerialAppointmentList {
  className?: string;
}

export const SerialAppointmentList: React.FC<ISerialAppointmentList> = ({ className }) => {
  const serialAppointmentList = useAppSelector(
    activityPlaningAppointmentDetailsSelectors.serialAppointmentList
  );
  const { selectedPersonID } = useSelectedPerson();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    return () => {
      dispatch(activityPlanningAppointmentDetailsActions.setSerialAppointmentList(null));
    };
  }, [selectedPersonID]);

  return (
    <div className={twMerge('h-full overflow-y-auto flex flex-col gap-2', className)}>
      {serialAppointmentList &&
        serialAppointmentList.map((appointment, index) => (
          <Typography key={index}>{appointment.appointmentDate}</Typography>
        ))}
    </div>
  );
};
