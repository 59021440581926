import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.personGeneralDataModals;

const displayAddressModal = createSelector([selector], (state) => state.displayAddressModal);
const deleteAddressModal = createSelector([selector], (state) => state.deleteAddressModal);

export const personGeneralDataModalsSelectors = {
  displayAddressModal,
  deleteAddressModal,
};
