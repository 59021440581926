import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectForm } from '@/components/Select/form/SelectForm';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';
import { testingResultModalsActions } from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';

export const TestProcedureSelect: React.FC = () => {
  const { t: createAppliedTestProcedureModalTranslations } = useTranslation(
    'createAppliedTestProcedureModal'
  );

  const testProcedureList = useAppSelector(testingResultModalsSelectors.testProcedureList);

  const formContext = useFormContext();

  const selectItems: ISelectItem[] = testProcedureList.map((testPerformer) => ({
    id: testPerformer.id.toString(),
    component: <Typography>{testPerformer.value}</Typography>,
    showCheckIcon: false,
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      testingResultModalsActions.fetchTestProceduresList(DropdownItemsByFetch.TEST_PROCEDURE)
    );
  }, []);

  return (
    <SelectForm
      control={formContext.control.register('procedure.id')}
      className={'w-full'}
      values={selectItems}
      label={createAppliedTestProcedureModalTranslations('fields.testingProcedure.label')}
    />
  );
};
