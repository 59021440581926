import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { FunctionContainer } from '@/components/views/functions/FunctionContainer/FunctionContainer';
import { SelectedPersonsList } from '@/components/views/functions/SelectedPersonsList/SelectedPersonsList';
import { FUNCTIONS_URL, PERSONS_URL } from '@/core/constants/urls';
import { useAppDispatch } from '@/core/redux/hooks';
import { personPageActions } from '@/core/redux/slices/personPage/personPageSlice';
import { LoadingStatus } from '@/types/loadingStatus';

import { useFunctionPageSearchParams } from './hooks/useFunctionPageParams';

export const FunctionsPage: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.breadcrumbs' });

  const breadcrumbItems = [
    { text: t('personsSelection'), link: PERSONS_URL },
    { text: t('functionsSelection'), link: FUNCTIONS_URL },
  ];

  useFunctionPageSearchParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(personPageActions.setPersonsLock(LoadingStatus.NEVER));
    };
  }, []);

  return (
    <div className={'px-[40px] w-full flex flex-col overflow-y-hidden'}>
      <div className='pt-[18px] pb-[18px]'>
        <Breadcrumbs items={breadcrumbItems} />
      </div>
      <div className='flex flex-row'>
        <SelectedPersonsList />
        <FunctionContainer />
      </div>
    </div>
  );
};
