import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAppSelector } from '@/core/redux/hooks';
import { diagnosticsSpecialFeaturesSelectors } from '@/core/redux/slices/functions/medicalInformation/specialFeatures/selectors';
import { IDiagnosticsSpecialFeatures } from '@/core/redux/slices/functions/medicalInformation/specialFeatures/slice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';

export function useDiagnosticsSpecialFeatures() {
  const form = useForm<IDiagnosticsSpecialFeatures>();

  const diagnosticsSpecialFeatures = useAppSelector(
    diagnosticsSpecialFeaturesSelectors.diagnosticsSpecialFeatures
  );

  useEffect(() => {
    if (!diagnosticsSpecialFeatures) {
      return;
    }

    form.reset({
      ...diagnosticsSpecialFeatures,
      lastSeizureDate: toClientDateInput(diagnosticsSpecialFeatures.lastSeizureDate),
    });
  }, [diagnosticsSpecialFeatures]);

  return form;
}
