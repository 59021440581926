import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.integrationPlan.generalData;

const generalData = createSelector([selector], (state) => state.generalData);
const generalDataUpdatedData = createSelector([selector], (state) => state.updatedData);
const generalDataLock = createSelector([selector], (state) => state.generalDataLock);
const updateGeneralDataLock = createSelector([selector], (state) => state.updateGeneralDataLock);

export const generalDataSelectors = {
  generalData,
  generalDataUpdatedData,
  generalDataLock,
  updateGeneralDataLock,
};
