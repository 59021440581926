import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.fundingCycle.smartGoals;

const smartGoals = createSelector([selector], (state) => state.smartGoals);
const smartGoalDetails = createSelector([selector], (state) => state.smartGoalDetails);
const measures = createSelector([selector], (state) => state.measures);
const measuresAvailable = createSelector([selector], (state) => state.measuresAvailable);
const smartGoalCompetencies = createSelector([selector], (state) => state.smartGoalCompetencies);

const selectedSmartGoal = createSelector([selector], (state) => state.selectedSmartGoal);
const selectedMeasures = createSelector([selector], (state) => state.selectedMeasures);

const smartGoalsLock = createSelector([selector], (state) => state.smartGoalsLock);
const measuresLock = createSelector([selector], (state) => state.measuresLock);
const smartGoalDetailsLock = createSelector([selector], (state) => state.smartGoalDetailsLock);
const createSmartGoalLock = createSelector([selector], (state) => state.createSmartGoalLock);
const updateSmartGoalLock = createSelector([selector], (state) => state.updateSmartGoalLock);
const updateMeasureLock = createSelector([selector], (state) => state.updateMeasureLock);

export const smartGoalsSelectors = {
  smartGoals,
  selectedSmartGoal,
  smartGoalDetails,
  smartGoalsLock,
  measuresLock,
  smartGoalDetailsLock,
  createSmartGoalLock,
  updateSmartGoalLock,
  measures,
  measuresAvailable,
  selectedMeasures,
  smartGoalCompetencies,
  updateMeasureLock
};
