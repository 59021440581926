import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageActions } from '@/core/redux/slices/functionPage/functionPageSlice';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { personPageSelectors } from '@/core/redux/slices/personPage/personPageSelectors';
import { personPageActions } from '@/core/redux/slices/personPage/personPageSlice';

import {
  FetchContext,
  usePersonPageStoreSelector,
} from '../../person/PersonPage/Context/PersonPageStoreContext';
import { useSelectedPersons } from '../../person/PersonPage/PersonView/PersonList/hooks/useSelectedPersons';

export const useFunctionPageSearchParams = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [personsChecksState, setPersonsChecksState] = usePersonPageStoreSelector(
    (store) => store.personsChecksState
  );

  // Get specific query parameters
  const paramsPersonID = Number(searchParams.get('personID'));
  const paramsActiveTabID = searchParams.get('activeTabID');

  // Get store data
  const storeSelectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const storeActiveTabId = useAppSelector(functionPageSelectors.activeTabId);

  const persons = useAppSelector(personPageSelectors.persons);

  const selectedPersons = useSelectedPersons();

  const selectedPersonsIds = useMemo(
    () => (selectedPersons ? selectedPersons.map((item) => item.id) : []),
    [selectedPersons]
  );

  useEffect(() => {
    if (paramsPersonID) {
      //Fetch persons data
      dispatch(
        personPageActions.fetchPersons({
          limit: 2000,
          context: FetchContext.default,
          persons: [...selectedPersonsIds, paramsPersonID],
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!persons) {
      return;
    }

    // Find the selected person based on the query parameter
    const selectedPerson = Object.values(persons).find((person) => person.id === paramsPersonID);

    if (!selectedPerson) {
      return;
    }

    if (selectedPerson.id !== storeSelectedPerson?.id) {
      // Update persons checks state
      setPersonsChecksState({
        personsChecksState: {
          persons_check_all: false,
          persons: {
            ...personsChecksState?.persons,
            [`person_id=${selectedPerson.id}`]: true,
          },
        },
      });

      // // Set the selected person
      dispatch(functionPageActions.setSelectedPerson(selectedPerson));
    }
  }, [persons, paramsPersonID]);

  useEffect(() => {
    if (paramsActiveTabID !== storeActiveTabId) {
      // Set the user's active tab
      dispatch(
        functionPageActions.setUserActiveTab({
          userId: paramsPersonID,
          activeTab: paramsActiveTabID,
        })
      );
    }
  }, [paramsActiveTabID]);
};
