import React from 'react';

import { Typography } from '@/components/Typography/Typography';

interface IQuestionnaireItemMarkSelectItem {
  review: number;
  description?: string | null;
}

export const QuestionnaireItemReviewSelectItem: React.FC<IQuestionnaireItemMarkSelectItem> = ({
  review,
  description,
}) => {
  return (
    <div className={'flex flex-row items-start gap-3 max-w-[410px] overflow-x-hidden'}>
      <Typography type={'16-M'}>{review}</Typography>
      {description && <Typography type={'14-R'}>{description}</Typography>}
    </div>
  );
};
