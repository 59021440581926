import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.logbook;

const logbook = createSelector([selector], (state) => state.logbookPerson);
const logbookLock = createSelector([selector], (state) => state.logbookPersonLock);

const logbookSummary = createSelector([selector], (state) => state.logbookSummary);
const logbookSummaryLock = createSelector([selector], (state) => state.logbookSummaryLock);

const categories =  createSelector([selector], (state) => state.categories);
const categoriesLock =  createSelector([selector], (state) => state.categoriesLock);

const createLogbookItemLock = createSelector([selector], (state) => state.createLogbookItemLock);
const updateLogbookItemLock = createSelector([selector], (state) => state.updateLogbookItemLock);

export const logbookSelectors = {
  logbook,
  logbookLock,

  logbookSummary,
  logbookSummaryLock,

  categories,
  categoriesLock,

  createLogbookItemLock,
  updateLogbookItemLock,
};
