import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { Typography } from '@/components/Typography/Typography';
import { DateRangeType } from '@/core/enums/dateRangeTypeEnum';
import { useAppDispatch } from '@/core/redux/hooks';
import { dashboardActions, IDateRange } from '@/core/redux/slices/dashboard/dashboardSlice';
import { colorTypes } from '@/styles/types';

const CHOSEN_DAY_FIELD_NAME = 'chosen_day';

export const SingleDatePicker: React.FC = () => {
  const { t: activitiesWidgetTranslations } = useTranslation('activitiesWidget', {
    keyPrefix: 'activitiesDatePickerContainer',
  });

  const dispatch = useAppDispatch();

  const form = useForm();
  const isDirty = form.formState.isDirty;

  useEffect(() => {
    const formValue = form.watch(CHOSEN_DAY_FIELD_NAME);

    if (fp.isEmpty(formValue)) {
      return;
    }

    const dateRange: IDateRange = {
      fromDate: formValue,
      toDate: formValue,
    };

    dispatch(
      dashboardActions.fetchActivities({
        dateRangeType: DateRangeType.CHOSEN_DAY,
        dateRange: dateRange,
      })
    );
  }, [form.watch()]);

  const handleFormReset = () => {
    form.reset({ [CHOSEN_DAY_FIELD_NAME]: null });
    dispatch(dashboardActions.fetchActivities(null));
  };

  return (
    <FormProvider {...form}>
      <div className={'flex flex-row items-center justify-start flex-wrap mt-3 gap-1'}>
        <Typography color={'Primary'} type={'16-R'}>
          {activitiesWidgetTranslations('chooseDay.label')}
        </Typography>
        <DatePicker control={form.control.register(CHOSEN_DAY_FIELD_NAME)} />
        <div className={`cursor-pointer text-${colorTypes.Grey}`}>
          {isDirty && <CrossIcon onClick={handleFormReset} />}
        </div>
      </div>
    </FormProvider>
  );
};
