import { extendTailwindMerge } from 'tailwind-merge';

export const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      spacing: ['default'],
      colors: [
        'primary',
        'secondary',
        'error',
        'blue',
        'blue-hover',
        'blue-pressed',
        'dark',
        'disabled',
        'white',
        'tooltip-bg',
        'hover',
        'hover-light',
        'pressed',
        'grey',
        'fields',
        'border',
        'border-light',
        'activity-border',
        'scroll',
        'stroke',
        'orange',
        'tiffany',
        'self-assessment-base',
        'self-assessment-base-border',
        'self-assessment-active',
        'self-assessment-active-border',
        'self-assessment-hover',
        'self-assessment-hover-border',
        'success',
        'overlay',
      ],
      borderRadius: ['default'],
    },
  },
});
