import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets/typings';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  activityPlanningAppointmentsMeasuresListActions,
  IActivityPlanningMeasureAppointment,
  IMeasuresAppointmentsListFetchPayload,
} from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/slice';
import { parseTime, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type MeasuresAppointmentsListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment',
  'get',
  '200'
>;

type MeasuresAppointmentsListRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment',
  'get'
>;

function* fetchMeasuresAppointmentsList(
  action: PayloadAction<IMeasuresAppointmentsListFetchPayload>
): Generator<any, void, MeasuresAppointmentsListResponse> {
  const { personID, measureID } = action.payload;

  try {
    yield put(
      activityPlanningAppointmentsMeasuresListActions.setMeasuresAppointmentsListLock(
        LoadingStatus.LOADING
      )
    );

    const request: MeasuresAppointmentsListRequest = {
      query: {
        person_id: personID,
        measure_id: measureID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/activity_planning/measure/appointment', 'get', request);

    const uniqueMeasuresList: IActivityPlanningMeasureAppointment[] = [];
    const seenIds: Set<number> = new Set();

    response.appointments.forEach((appointment) => {
      if (!seenIds.has(appointment.id)) {
        seenIds.add(appointment.id);
        uniqueMeasuresList.push({
          freePlaces: appointment.free_places,
          appointmentStartDate: toClientDateInput(appointment.appointment_start_date),
          appointmentEndDate: toClientDateInput(appointment.appointment_end_date),
          appointmentEndTime: parseTime(appointment.appointment_end_time),
          appointmentStartTime: parseTime(appointment.appointment_start_time),
          isCompleted: appointment.is_completed,
          implementationAid: appointment.implementation_aids,
          appointmentID: appointment.appointment_id,
          id: appointment.id,
          day: appointment.day,
          hours: appointment.hours?.toFixed(2),
          location: appointment.location,
          responsibleUser: appointment.responsible_user,
        });
      }
    });

    yield put(
      activityPlanningAppointmentsMeasuresListActions.setMeasuresAppointmentsList(
        uniqueMeasuresList
      )
    );

    yield put(
      activityPlanningAppointmentsMeasuresListActions.setMeasuresAppointmentsListLock(
        LoadingStatus.LOADED
      )
    );
  } catch (error) {
    console.log('Error on activity planing measures list fetch', error);
    yield put(
      activityPlanningAppointmentsMeasuresListActions.setMeasuresAppointmentsListLock(
        LoadingStatus.ERROR
      )
    );
  }
}

export const activityPlaningMeasuresAppointmentsListSagas = [
  takeLatest(
    activityPlanningAppointmentsMeasuresListActions.fetchMeasuresAppointmentsList,
    fetchMeasuresAppointmentsList
  ),
];
