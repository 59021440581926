import React from 'react';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { Accordion } from '@/components/Accordion/Accordion';
import { QualificationsDocumentationItem } from '@/components/forms/StatusReport/QualificationObjectives/QualificationsDocumentationItem/QualificationsDocumentationItem';
import { TableHeader } from '@/components/forms/StatusReport/QualificationObjectives/TableHeader/TableHeader';
import { Typography } from '@/components/Typography/Typography';
import { IQualificationObjectivesDocumentationsItem } from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { colorTypes } from '@/styles/types';

export interface IQualificationObjectives {
  qualificationsDocuments?: Record<string, IQualificationObjectivesDocumentationsItem>;
}

export const QualificationObjectives: React.FC<IQualificationObjectives> = ({
  qualificationsDocuments,
}) => {
  const { t: qualificationObjectivesTranslations } = useTranslation('statusReport', {
    keyPrefix: 'qualificationObjectives',
  });

  const documentsKeys = Object.keys(qualificationsDocuments ?? {});

  const generateDocumentName = (document: IQualificationObjectivesDocumentationsItem) => {
    if (fp.isEmpty(document.qualificationObjectiveExtension)) {
      return document.qualificationObjectiveName;
    }

    return document.qualificationObjectiveName + ` - ${document.qualificationObjectiveExtension}`;
  };

  return (
    <Accordion
      title={
        <Typography type={'H3'}>{qualificationObjectivesTranslations('title.label')}</Typography>
      }
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'flex flex-col mt-4'}>
        <TableHeader />
        <div className={'flex flex-col gap-3'}>
          {qualificationsDocuments &&
            documentsKeys.map((documentKey) => (
              <QualificationsDocumentationItem
                documentName={generateDocumentName(qualificationsDocuments[documentKey])}
                documentID={documentKey}
                key={documentKey}
              />
            ))}
        </div>
      </div>
    </Accordion>
  );
};
