import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICompleteAppointmentFormGroup } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/hooks/useCompleteAppointmentModalForm';
import { LoadingStatus } from '@/types/loadingStatus';
import { ILocation } from '@/types/location';

export interface IModal {
  isRender: boolean;
}

export interface IAppointmentCompleteModalFetchPayload {
  measureID: number;
  appointmentID: number;
}

export interface IAppointmentCompleteModalPayload extends IAppointmentCompleteModalFetchPayload {
  startDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
}

export interface IAppointmentFinishPayload {
  formValues: ICompleteAppointmentFormGroup;
  personID: number;
  measureID: number;
}

export interface ICompleteAppointmentItem {
  id: number;
  text: string;
  explanation: string;
  type: string;
  fromTimestamp: string | null;
  toTimestamp: string | null;
  hours: number | null;
  isParticipated: boolean;
  isUnderDirection: boolean;
  isAlmostIndependent: boolean;
  isIndependent: boolean;
  note: string | null;
}

export interface ICompleteAppointmentChildGroup {
  id: number;
  name: string;
  childGroups: ICompleteAppointmentChildGroup[] | null;
  items: ICompleteAppointmentItem[] | null;
}

export interface IAppointmentQuestionnaire {
  id: number;
  name: string;
  childGroups: ICompleteAppointmentChildGroup[] | null;
  items: ICompleteAppointmentItem[] | null;
}

export interface IAppointmentCompleteModal extends IModal {
  payload: IAppointmentCompleteModalPayload | null;
  fetchPayload: IAppointmentCompleteModalFetchPayload | null;
  finishAppointmentPayload: IAppointmentFinishPayload | null;
  appointmentCompleteQuestionnaire: IAppointmentQuestionnaire | null;
  appointmentCompleteQuestionnaireLock: LoadingStatus;
}

export interface IAppointmentDetailsModalPayload {
  measureID: number;
  appointmentID?: number | null;
  personLocation: ILocation;
  selectedMeasureName?: string | null;
  appointmentDetailsContext: 'create' | 'edit';
}

export interface IAppointmentDetailsModal extends IModal {
  payload: IAppointmentDetailsModalPayload | null;
}

export interface IActivityPlaningAppointmentDetailsModalsState {
  completeAppointmentModal: IAppointmentCompleteModal;
  appointmentDetailsModal: IAppointmentDetailsModal;
}

const initialState: IActivityPlaningAppointmentDetailsModalsState = {
  completeAppointmentModal: {
    isRender: false,
    payload: null,
    fetchPayload: null,
    finishAppointmentPayload: null,
    appointmentCompleteQuestionnaire: null,
    appointmentCompleteQuestionnaireLock: LoadingStatus.NEVER,
  },
  appointmentDetailsModal: {
    isRender: false,
    payload: null,
  },
};

const activityPlaningAppointmentDetailsModalsSlice = createSlice({
  name: 'activityPlaningAppointmentDetailsModals',
  initialState,
  reducers: {
    openCompleteAppointmentModal: (
      state,
      action: PayloadAction<IAppointmentCompleteModalPayload>
    ) => ({
      ...state,
      completeAppointmentModal: {
        ...state.completeAppointmentModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeCompleteAppointmentModal: (state) => ({
      ...state,
      completeAppointmentModal: {
        isRender: false,
        payload: null,
        fetchPayload: null,
        finishAppointmentPayload: null,
        appointmentCompleteQuestionnaire: null,
        appointmentCompleteQuestionnaireLock: LoadingStatus.NEVER,
      },
    }),
    fetchAppointmentCompleteQuestionnaire: (
      state,
      action: PayloadAction<IAppointmentCompleteModalFetchPayload>
    ) => ({
      ...state,
      completeAppointmentModal: {
        ...state.completeAppointmentModal,
        fetchPayload: action.payload,
      },
    }),
    setAppointmentCompleteQuestionnaire: (
      state,
      action: PayloadAction<IAppointmentQuestionnaire | null>
    ) => ({
      ...state,
      completeAppointmentModal: {
        ...state.completeAppointmentModal,
        appointmentCompleteQuestionnaire: action.payload,
      },
    }),
    setAppointmentCompleteQuestionnaireLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      completeAppointmentModal: {
        ...state.completeAppointmentModal,
        appointmentCompleteQuestionnaireLock: action.payload,
      },
    }),
    finishAppointment: (state, action: PayloadAction<IAppointmentFinishPayload>) => ({
      ...state,
      completeAppointmentModal: {
        ...state.completeAppointmentModal,
        finishAppointmentPayload: action.payload,
      },
    }),
    //Appointment details modal
    openAppointmentDetailsModal: (
      state,
      action: PayloadAction<IAppointmentDetailsModalPayload>
    ) => ({
      ...state,
      appointmentDetailsModal: {
        ...state.appointmentDetailsModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAppointmentDetailsModal: (state) => ({
      ...state,
      appointmentDetailsModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const activityPlaningAppointmentDetailsModalsReducer =
  activityPlaningAppointmentDetailsModalsSlice.reducer;
export const activityPlaningAppointmentDetailsModalsActions =
  activityPlaningAppointmentDetailsModalsSlice.actions;
