import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { SerialAppointmentButton } from '@/components/functions/ActivityPlanning/components/SerialAppointmentButton/SerialAppointmentButton';
import { AppointmentDetailsFooter } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/AppointmentDetailsFooter/AppointmentDetailsFooter';
import { AppointmentDetailsLocationSelect } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/AppointmentDetailsLocationSelect/AppointmentDetailsLocationSelect';
import { AppointmentDetailsTypeSelect } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/AppointmentDetailsTypeSelect/AppointmentDetailsTypeSelect';
import { appointmentDetailsSchemaBuilder } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/hooks/appointmentDetailsValidator';
import { useAppointmentDetailsForm } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/hooks/useAppointmentDetailsForm';
import { ImplementationAidsSelect } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/ImplementationAidsSelect/ImplementationAidsSelect';
import { fillTime } from '@/components/Input/helpers/fillTime';
import { timeMask } from '@/components/Input/helpers/timeMask';
import { Input } from '@/components/Input/Input';
import { SchemaProvider } from '@/components/SchemaContext/SchemaContext';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { useDateFormSwitch } from '@/core/hooks/useDateFormSwitch';
import { useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsSelectors } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

export const AppointmentDetails: React.FC = () => {
  const { t: appointmentDetailTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentDetails',
  });

  const loadingStatus = useAppSelector(
    activityPlaningAppointmentDetailsSelectors.appointmentDetailLock
  );

  const {
    form,
    appointmentDetails,
    appointmentDetailsContext,
    setAppointmentDetailsContext,
    createAppointment,
    handleSubmit,
    isStartEndSameDate,
  } = useAppointmentDetailsForm();

  useDateFormSwitch(form, 'startDate', 'endDate');

  return (
    <div className={'border flex flex-col gap-2 p-2'}>
      <Typography type={'16-Sb'}>{appointmentDetailTranslations('title.label')}</Typography>
      <FormProvider {...form}>
        <SchemaProvider schema={appointmentDetailsSchemaBuilder(appointmentDetailTranslations)}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            {(appointmentDetails || appointmentDetailsContext === 'create') && (
              <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
                <div className={'flex flex-col gap-2'}>
                  <div className={'flex flex-col gap-2'}>
                    <div className={'flex flex-row gap-2'}>
                      <Typography>
                        {appointmentDetailTranslations('fields.appointmentOnlyPlanned.label')}
                      </Typography>
                      <Checkbox name={'appointmentOnlyPlanned'} />
                    </div>
                    <div className={'grid grid-cols-[max-content,_max-content] items-center gap-5'}>
                      <DatePicker
                        control={form.register('startDate')}
                        label={appointmentDetailTranslations('fields.startDate.label')}
                      />
                      {isStartEndSameDate ? (
                        <Input
                          control={form.register('startTime')}
                          handleChange={timeMask}
                          handleChangeOnBlur={fillTime}
                          label={appointmentDetailTranslations('fields.startTime.label')}
                        />
                      ) : (
                        <div />
                      )}
                      <DatePicker
                        control={form.register('endDate')}
                        label={appointmentDetailTranslations('fields.endDate.label')}
                      />
                      {isStartEndSameDate ? (
                        <Input
                          control={form.register('endTime')}
                          handleChange={timeMask}
                          handleChangeOnBlur={fillTime}
                          label={appointmentDetailTranslations('fields.endTime.label')}
                        />
                      ) : (
                        <Input
                          control={form.register('duration')}
                          type={'number'}
                          label={appointmentDetailTranslations('fields.hours.label')}
                        />
                      )}
                    </div>
                    <SerialAppointmentButton form={form} context={'fullVersion'} />
                    <div className={'flex flex-col gap-2'}>
                      <Input
                        control={form.register('measureTitle')}
                        label={appointmentDetailTranslations('fields.measureTitle.label')}
                      />
                      <AppointmentDetailsLocationSelect appointmentDetails={appointmentDetails} />
                      <ImplementationAidsSelect appointmentDetails={appointmentDetails} />
                      <AppointmentDetailsTypeSelect />
                      <Input
                        control={form.register('places')}
                        label={appointmentDetailTranslations('fields.places.label')}
                      />
                      <Input
                        control={form.register('responsible')}
                        readOnly={true}
                        label={appointmentDetailTranslations('fields.responsible.label')}
                      />
                      <TextArea
                        control={form.register('appointmentInfo')}
                        label={appointmentDetailTranslations('fields.appointmentInfo.label')}
                      />
                    </div>
                  </div>
                </div>
              </Skeleton>
            )}
            <AppointmentDetailsFooter
              appointmentDetails={appointmentDetails}
              appointmentContext={appointmentDetailsContext}
              setAppointmentContext={setAppointmentDetailsContext}
              handleCreateAppointment={createAppointment}
              form={form}
            />
          </form>
        </SchemaProvider>
      </FormProvider>
    </div>
  );
};
