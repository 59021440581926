import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.widgetsModals;
const personWidgetModal = createSelector([selector], (state) => state.personWidgetModal.isRender);
const widgetControlModal = createSelector([selector], (state) => state.widgetControlModal.isRender);
const activityPersonsListModal = createSelector(
  [selector],
  (state) => state.activityPersonsListModal
);
export const widgetModalsSelectors = {
  personWidgetModal,
  widgetControlModal,
  activityPersonsListModal,
};
