import React from 'react';
import { useTranslation } from 'react-i18next';

import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { ISerialAppointmentForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/hooks/useSerialAppointmentForm';
import { Typography } from '@/components/Typography/Typography';

export interface ISerialAppointmentWeeklyForm {
  weeksInterval?: number | null;
  onMonday: boolean;
  onTuesday: boolean;
  onWednesday: boolean;
  onThursday: boolean;
  onFriday: boolean;
  onSaturday: boolean;
  onSunday: boolean;
}

export const SerialAppointmentWeeklyForm: React.FC = () => {
  const { t: serialAppointmentTranslations } = useTranslation('serialAppointmentModal', {
    keyPrefix: 'cycle.forms.weekly',
  });

  const serialAppointmentWeeklyForm: (
    | FormItem<ISerialAppointmentForm>
    | FormGroup<ISerialAppointmentForm>
  )[] = [
    {
      fieldsContainerClassName: 'flex flex-col gap-3',
      fields: [
        {
          type: 'custom',
          name: 'weekly.undefined',
          className: 'w-fit',
          customComponent: (
            <Typography>{serialAppointmentTranslations('fields.eachAll.label')}</Typography>
          ),
        },
        {
          type: 'input',
          name: 'weekly.weeksInterval',
          className: 'flex flex-row gap-3 items-center',
          isReversed: true,
          label: serialAppointmentTranslations('fields.weeksOn.label'),
        },
      ],
    },
    {
      fieldsContainerClassName: 'grid grid-cols-4 gap-2',
      fields: [
        {
          type: 'checkbox',
          name: 'weekly.onMonday',
          className: 'flex flex-row gap-3',
          isReversed: true,
          label: serialAppointmentTranslations('fields.weekdays.Montag.label'),
        },
        {
          type: 'checkbox',
          name: 'weekly.onTuesday',
          className: 'flex flex-row gap-3',
          isReversed: true,
          label: serialAppointmentTranslations('fields.weekdays.Dienstag.label'),
        },
        {
          type: 'checkbox',
          name: 'weekly.onWednesday',
          className: 'flex flex-row gap-3',
          isReversed: true,
          label: serialAppointmentTranslations('fields.weekdays.Mittwoch.label'),
        },
        {
          type: 'checkbox',
          name: 'weekly.onThursday',
          className: 'flex flex-row gap-3',
          isReversed: true,
          label: serialAppointmentTranslations('fields.weekdays.Donnerstag.label'),
        },
        {
          type: 'checkbox',
          name: 'weekly.onFriday',
          className: 'flex flex-row gap-3',
          isReversed: true,
          label: serialAppointmentTranslations('fields.weekdays.Freitag.label'),
        },
        {
          type: 'checkbox',
          name: 'weekly.onSaturday',
          className: 'flex flex-row gap-3',
          isReversed: true,
          label: serialAppointmentTranslations('fields.weekdays.Samstag.label'),
        },
        {
          type: 'checkbox',
          name: 'weekly.onSunday',
          className: 'flex flex-row gap-3',
          isReversed: true,
          label: serialAppointmentTranslations('fields.weekdays.Sonntag.label'),
        },
      ],
    },
  ];

  return <FormRender list={serialAppointmentWeeklyForm} />;
};
