import { useEffect, useMemo } from 'react';
import fp from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { specialistGeneralDataSelectors } from '@/core/redux/slices/functions/specialistGeneralData/generalData/selectors';
import { specialistGeneralDataActions } from '@/core/redux/slices/functions/specialistGeneralData/generalData/specialistGeneralDataSlice';

export default function useFetchedAdministers(date: string | null | undefined) {
  const dispatch = useAppDispatch();
  const administers = useAppSelector(specialistGeneralDataSelectors.administers);

  useEffect(() => {
    if (date === undefined) {
      return;
    }

    dispatch(
      specialistGeneralDataActions.fetchAdministers({
        date: date,
      })
    );
  }, [date]);

  return { administers };
}
