import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/selectors';
import {
  IAppointmentQuestionnaire,
  ICompleteAppointmentChildGroup,
  ICompleteAppointmentItem,
} from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';

export interface ICompleteAppointmentFormGroupItem {
  id: number;
  isParticipated: boolean;
  isUnderDirection: boolean;
  isAlmostIndependent: boolean;
  isIndependent: boolean;
  comment?: string | null;
}

export interface ICompleteAppointmentFormGroup {
  id: number;
  items?: ICompleteAppointmentFormGroupItem[];
  childGroup?: ICompleteAppointmentFormGroup[];
}

const mapFormItem = (items: ICompleteAppointmentItem[]): ICompleteAppointmentFormGroupItem[] => {
  return items.map((item) => ({
    id: item.id,
    isAlmostIndependent: item.isAlmostIndependent,
    isIndependent: item.isIndependent,
    isParticipated: item.isParticipated,
    isUnderDirection: item.isUnderDirection,
    comment: item.note,
  }));
};

const mapFormGroup = (group: ICompleteAppointmentChildGroup[]): ICompleteAppointmentFormGroup[] => {
  return group.map<ICompleteAppointmentFormGroup>((_group) => ({
    items: _group.items ? mapFormItem(_group.items) : undefined,
    id: _group.id,
    childGroup: _group.childGroups ? mapFormGroup(_group.childGroups) : undefined,
  }));
};

const mapForm = (questionnaire: IAppointmentQuestionnaire): ICompleteAppointmentFormGroup => {
  return {
    id: questionnaire.id,
    items: questionnaire.items ? mapFormItem(questionnaire.items) : undefined,
    childGroup: questionnaire.childGroups ? mapFormGroup(questionnaire.childGroups) : undefined,
  };
};

export function useCompleteAppointmentModalForm() {
  const { appointmentCompleteQuestionnaire } = useAppSelector(
    activityPlaningAppointmentDetailsModalsSelectors.completeAppointmentModal
  );

  const form = useForm<ICompleteAppointmentFormGroup>();

  useEffect(() => {
    if (!appointmentCompleteQuestionnaire || !appointmentCompleteQuestionnaire.childGroups) {
      return;
    }

    const newFormValues = mapForm(appointmentCompleteQuestionnaire);

    form.reset(newFormValues);
  }, [appointmentCompleteQuestionnaire]);

  return { form };
}
