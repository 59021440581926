import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Input } from '@/components/Input/Input';
import { FavoritePersonsGroupsList } from '@/components/widgets/PersonWidget/FavoritePersonsGroupsList/FavoritePersonsGroupsList';
import { useAppDispatch } from '@/core/redux/hooks';
import { dashboardActions } from '@/core/redux/slices/dashboard/dashboardSlice';
import { colorTypes } from '@/styles/types';

import styles from './styles.module.scss';

const searchInput = 'search-person-widget';

export const PersonWidget: React.FC = () => {
  const { t: favoritePersonsWidgetTranslations } = useTranslation('favoritePersonWidget');

  const form = useForm();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      dashboardActions.fetchFavoritePersons({
        search: form.watch(searchInput),
      })
    );
  }, [form.watch(searchInput)]);

  return (
    <div className={clsx(styles.container, 'flex flex-col w-full')}>
      <FormProvider {...form}>
        <Input
          inputVariant='WidgetSearch'
          placeholder={favoritePersonsWidgetTranslations('fields.search.label')}
          control={form.control.register(searchInput)}
        />
      </FormProvider>
      <div
        className={`flex flex-col flex-grow overflow-y-auto  border-t-2 border-${colorTypes.Fields} mt-4 max-h-[calc(100vh_-_450px)] overflow-y-auto`}
      >
        <FavoritePersonsGroupsList />
      </div>
    </div>
  );
};
