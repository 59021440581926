import fp from 'lodash/fp';

import { DevelopmentReportVisionAndGoalsTypes } from '@/core/enums/functions/developmentReport/developmentReportVisionAndGoalsTypesEnum';
import { yup } from '@/core/utils/commonUtils';

export const visionAndGoalsValidator = yup.object().shape({
  visionAndGoals: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          lfdNr: yup.number().required(),
          viewType: yup
            .mixed<DevelopmentReportVisionAndGoalsTypes>()
            .oneOf(Object.values(DevelopmentReportVisionAndGoalsTypes))
            .required(),
          ownView: yup
            .string()
            .nullable()
            .when('representativeView', {
              is: (representativeView: string | null) => !fp.isEmpty(representativeView),
              then: () =>
                yup.string().required('ownView is required when representativeView has a value'),
              otherwise: () => yup.string().nullable(),
            })
            .test('max-length', 'max-length', (value) => !value || value.length <= 300)
            .test('max-lines', 'max-lines', (value) => !value || value.split('\n').length <= 4),
          representativeView: yup
            .string()
            .nullable()
            .test('max-length', 'max-length', (value) => !value || value.length <= 300)
            .test('max-lines', 'max-lines', (value) => !value || value.split('\n').length <= 4),
        })
        .test(
          'either-view-must-be-set',
          'At least one of ownView or representativeView must contain a value if viewType is OwnExclusion or DeputyExclusion',
          (value) => {
            const { ownView, representativeView, viewType } = value;
            const viewsExist = !!ownView || !!representativeView;
            return (
              (viewType !== DevelopmentReportVisionAndGoalsTypes.OwnExclusion &&
                viewType !== DevelopmentReportVisionAndGoalsTypes.DeputyExclusion) ||
              viewsExist
            );
          }
        )
    )
    .required(),
});
