import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.personGeneralData.addresses;

const addressData = createSelector([selector], (state) => state.addressData);
const addressDataLock = createSelector([selector], (state) => state.addressDataLock);

const addressInfo = createSelector([selector], (state) => state.addressInfo);
const addressTypes = createSelector([selector], (state) => state.addressTypes);
const addressInfoLock = createSelector([selector], (state) => state.addressInfoLock);

//action locks
const updateAddressLock = createSelector([selector], (state) => state.updateAddressLock);
const createAddressLock = createSelector([selector], (state) => state.createAddressLock);
const deleteAddressLock = createSelector([selector], (state) => state.deleteAddressLock);


export const addressesSelectors = {
  addressData,
  addressDataLock,
  addressInfo,
  addressTypes,
  addressInfoLock,

  updateAddressLock,
  createAddressLock,
  deleteAddressLock
};
