import React from 'react';

import { IIcon } from '@/types/icon';

export const SortingDescendIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M3 14.0073C3 14.5556 3.44445 15 3.9927 15H9.24817C9.79643 15 10.2409 14.5556 10.2409 14.0073C10.2409 13.459 9.79643 13.0146 9.24817 13.0146H3.9927C3.44445 13.0146 3 13.459 3 14.0073Z'
      fill='#3575EF'
    />
    <path
      d='M3 9.33577C3 9.88402 3.44445 10.3285 3.9927 10.3285H13.3358C13.884 10.3285 14.3285 9.88402 14.3285 9.33577C14.3285 8.78751 13.884 8.34307 13.3358 8.34307H3.9927C3.44445 8.34307 3 8.78751 3 9.33577Z'
      fill='#3575EF'
    />
    <path
      d='M3.9927 5.65693C3.44445 5.65693 3 5.21249 3 4.66423C3 4.11598 3.44445 3.67153 3.9927 3.67153H18.0073C18.5556 3.67153 19 4.11598 19 4.66423C19 5.21249 18.5556 5.65693 18.0073 5.65693H3.9927Z'
      fill='#3575EF'
    />
  </svg>
);
