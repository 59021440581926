import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';

export interface IQuestionItem {
  title: string;
  fieldName: string;
}

export const QuestionItem: React.FC<IQuestionItem> = ({ title, fieldName }) => {
  const { control } = useFormContext();

  return (
    <div className={'grid grid-cols-5 gap-4'}>
      <Typography>{title}</Typography>
      <TextArea
        control={control.register(`${fieldName}`)}
        containerClassName={'col-span-2 h-full'}
      />
      <TextArea
        control={control.register(`${fieldName}Method`)}
        containerClassName={'col-span-2'}
      />
    </div>
  );
};
