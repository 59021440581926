import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export interface IFunctionFooter {
  className?: string;
}

export const FunctionFooter: React.FC<PropsWithChildren<IFunctionFooter>> = ({
  children,
  className,
}) => {
  return (
    <div
      className={twMerge(
        'shadow-[0px_2px_10px_0px_rgba(110,123,148,0.20)] w-full py-2.5 px-5 flex gap-4',
        className
      )}
    >
      {children}
    </div>
  );
};
