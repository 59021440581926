import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Dialog, IDialog } from '@/components/Dialog/Dialog';
import { DialogContent } from '@/components/Dialog/DialogContent/DialogContent';
import { Typography } from '@/components/Typography/Typography';

export const ApprovedToBeforeApprovedFromDateDialog: React.FC<IDialog> = ({ onConfirm }) => {
  const { t: additionalPersonRequirementsModalTranslations } = useTranslation(
    'additionalPersonRequirementModal',
    { keyPrefix: 'dialogs.approvedToBeforeApprovedFromDate' }
  );

  const handleSave = () => {
    onConfirm?.();
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <div className={'flex flex-col gap-5'}>
          <Typography type={'H3'}>
            {additionalPersonRequirementsModalTranslations('title.label')}
          </Typography>
          <Typography>{additionalPersonRequirementsModalTranslations('message.label')}</Typography>
          <div className={'flex flex-row gap-2 justify-center'}>
            <Button onClick={handleSave}>
              {additionalPersonRequirementsModalTranslations('buttons.ok.label')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
