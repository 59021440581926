import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ISerialAppointmentDurationForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentDurationForm';
import { ISerialAppointmentDailyForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentPeriodForms/serialAppointmentDailyForm';
import { ISerialAppointmentMonthlyForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentPeriodForms/serialAppointmentMonthlyForm';
import { ISerialAppointmentWeeklyForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentPeriodForms/serialAppointmentWeeklyForm';
import { ISerialAppointmentYearlyForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentPeriodForms/serialAppointmentYearlyForm';
import { useAppSelector } from '@/core/redux/hooks';
import { serialAppointmentModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningSerialAppointment/selectors';

export interface ISerialAppointmentForm {
  serialTypeID?: number | null;
  daily: ISerialAppointmentDailyForm;
  weekly: ISerialAppointmentWeeklyForm;
  monthly: ISerialAppointmentMonthlyForm;
  yearly: ISerialAppointmentYearlyForm;
  duration: ISerialAppointmentDurationForm;
}

const defaultValues: ISerialAppointmentForm = {
  serialTypeID: 1,
  duration: {
    endDate: null,
    appointmentsNumber: null,
    isFixedAppointmentsCount: false,
  },
  monthly: {
    daysInterval: null,
    weekDayID: null,
    weekNumber: null,
    monthsInterval: null,
    isRegularDayOfMonth: false,
  },
  weekly: {
    onSunday: false,
    onSaturday: false,
    onFriday: false,
    onThursday: false,
    onWednesday: false,
    onTuesday: false,
    onMonday: false,
    weeksInterval: null,
  },
  daily: {
    daysInterval: null,
    isWorkingDaysOnly: false,
  },
  yearly: {
    isRegularMonthOfYear: false,
    monthID: null,
    specificMonthDay: null,
    weekDayID: null,
    weekNumber: null,
    yearsInterval: null,
  },
};

export function useSerialAppointmentForm() {
  const form = useForm<ISerialAppointmentForm>({
    defaultValues: defaultValues,
  });

  const { serialAppointmentDetails } = useAppSelector(
    serialAppointmentModalsSelectors.serialAppointmentModal
  );

  useEffect(() => {
    if (!serialAppointmentDetails) {
      return;
    }

    form.reset(serialAppointmentDetails);
  }, [serialAppointmentDetails]);

  return { form };
}
