import { ObjectSchema } from 'yup';

import { IDevelopmentReportPersonDetailsForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/PersonDetails/helper/personDetailsForm';
import { DevelopmentReportDisabilityTypes } from '@/core/enums/functions/developmentReport/developmentReportDisabilityTypesEnum';
import { DevelopmentReportLegalSupportTypes } from '@/core/enums/functions/developmentReport/developmentReportLegalSupportTypesEnum';
import { yup } from '@/core/utils/commonUtils';

export function developmentReportPersonDetailsValidatorBuilder(
  translations: any
): ObjectSchema<IDevelopmentReportPersonDetailsForm> {
  return yup.object().shape({
    wfbmName: yup.string().nullable().optional(),
    wfbmOperatingUnit: yup.string().nullable().optional(),
    wfbmContactPerson: yup.string().nullable().optional(),
    hbg: yup
      .mixed<DevelopmentReportDisabilityTypes>()
      .oneOf(Object.values(DevelopmentReportDisabilityTypes))
      .required(),
    legalGuardianFlag: yup
      .mixed<DevelopmentReportLegalSupportTypes>()
      .oneOf(Object.values(DevelopmentReportLegalSupportTypes))
      .required(),
    legalGuardianNameAddress: yup
      .string()
      .nullable()
      .when('legalGuardianFlag', {
        is: (legalGuardianFlag: DevelopmentReportLegalSupportTypes) =>
          legalGuardianFlag === DevelopmentReportLegalSupportTypes.Yes,
        then: () =>
          yup
            .string()
            .required('Legal guardian name and address is required when the flag is set to Yes.'),
        otherwise: () => yup.string().optional(),
      }),
    isMobilityOepnv: yup.boolean().required(),
    isMobilityTransportService: yup.boolean().required(),
    isMobilityOnFoot: yup.boolean().required(),
    isMobilitySelfDrive: yup.boolean().required(),
    workingHoursPerWeek: yup.number().nullable().optional(),
    workingDaysPerWeek: yup.number().nullable().optional(),
    workingHoursPerDay: yup.number().nullable().optional(),
    diagnosis: yup.string().nullable().optional(),
    internships: yup.string().nullable().optional(),
    communicationNote: yup.string().nullable().optional(),
    goalsWishes: yup.string().nullable().optional(),
    defaultLegalGuardianNameAddress: yup.string().nullable().optional(),
  });
}

export const developmentReportPersonDetailsValidator =
  developmentReportPersonDetailsValidatorBuilder('s');
