export const TableFolderIcon: React.FC = () => {
  return (
    <svg width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.8 3.79688C2.58783 3.79688 2.38434 3.88116 2.23431 4.03119C2.08429 4.18122 2 4.3847 2 4.59688V17.1969C2 17.409 2.08429 17.6125 2.23431 17.7626C2.38434 17.9126 2.58783 17.9969 2.8 17.9969H13.6C13.8122 17.9969 14.0157 17.9126 14.1657 17.7626C14.3157 17.6125 14.4 17.409 14.4 17.1969V4.59688C14.4 4.3847 14.3157 4.18122 14.1657 4.03119C14.0157 3.88116 13.8122 3.79688 13.6 3.79688H11.8C11.2477 3.79688 10.8 3.34916 10.8 2.79688C10.8 2.24459 11.2477 1.79688 11.8 1.79688H13.6C14.3426 1.79688 15.0548 2.09187 15.5799 2.61698C16.105 3.14208 16.4 3.85427 16.4 4.59688V17.1969C16.4 17.9395 16.105 18.6517 15.5799 19.1768C15.0548 19.7019 14.3426 19.9969 13.6 19.9969H2.8C2.05739 19.9969 1.3452 19.7019 0.820101 19.1768C0.294999 18.6517 0 17.9395 0 17.1969V4.59688C0 3.85427 0.294999 3.14208 0.820101 2.61698C1.3452 2.09187 2.05739 1.79688 2.8 1.79688H4.6C5.15228 1.79688 5.6 2.24459 5.6 2.79688C5.6 3.34916 5.15228 3.79688 4.6 3.79688H2.8Z'
        fill='#838FAF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.60156 2V3.6H10.8016V2H5.60156ZM3.60156 1.9C3.60156 0.850659 4.45222 0 5.50156 0H10.9016C11.9509 0 12.8016 0.850659 12.8016 1.9V3.7C12.8016 4.74934 11.9509 5.6 10.9016 5.6H5.50156C4.45222 5.6 3.60156 4.74934 3.60156 3.7V1.9Z'
        fill='#838FAF'
      />
    </svg>
  );
};
