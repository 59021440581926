import { useState } from 'react';
import { eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns';

import { getDaysArray } from '@/core/utils/dateTimeUtil';

export const useDatePickerCalendar = (selectedDate?: Date | null) => {
  const date = selectedDate ?? new Date();

  const [selectedMonth, setSelectedMonth] = useState(date.getMonth());
  const [selectedYear, setSelectedYear] = useState(date.getFullYear());

  const startDate = new Date(selectedYear, selectedMonth, 1);
  const endDate = new Date(selectedYear, selectedMonth + 1, 0);

  const days = eachDayOfInterval({ start: startDate, end: endDate });

  const firstDayOfWeek = startOfWeek(startDate, { weekStartsOn: 1 });
  const lastDayOfWeek = endOfWeek(endDate, { weekStartsOn: 1 });

  // Fill weekdays before the start of the month
  const previousMonthEndDate = new Date(selectedYear, selectedMonth, 0);
  const previousMonthDays = getDaysArray(firstDayOfWeek, previousMonthEndDate);

  // Fill weekdays after the end of the month
  const nextMonthStartDate = new Date(selectedYear, selectedMonth + 1, 1);
  const nextMonthDays = getDaysArray(nextMonthStartDate, lastDayOfWeek);

  const allDays = [...previousMonthDays, ...days, ...nextMonthDays];

  // Fill additional days after the end of the month
  const nextMonthEndDate = new Date(selectedYear, selectedMonth + 2, 0);
  const nextMonthAdditionalDays = getDaysArray(nextMonthStartDate, nextMonthEndDate);

  // Ensure the calendar always fills 6 rows
  const daysToAdd = 42 - allDays.length; // Total number of days needed to fill 6 rows
  const additionalDays = nextMonthAdditionalDays.slice(
    nextMonthDays.length,
    daysToAdd + nextMonthDays.length
  ); // Additional days from next month

  const updatedAllDays = [...allDays, ...additionalDays]; // Updated array of all days

  return {
    startDate,
    endDate,
    allDays: updatedAllDays,
    setSelectedMonth,
    setSelectedYear,
    selectedYear,
    selectedMonth,
  };
};
