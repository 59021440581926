import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActivitiesLoadedEmptyIcon } from '@/components/Icons/ActivitiesLoadedEmptyIcon';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const EmptyLoadedPlaceholder: React.FC = () => {
  const { t: activitiesWidgetPlaceholderTranslations } = useTranslation('activitiesWidget', {
    keyPrefix: 'activitiesContentTable.placeholders.emptyLoadedPlaceholder',
  });
  return (
    <div className={'h-[400px] flex flex-col items-center justify-start w-full gap-2'}>
      <div className={`text-${colorTypes.Border} mt-9`}>
        <ActivitiesLoadedEmptyIcon />
      </div>
      <Typography>{activitiesWidgetPlaceholderTranslations('mainText.label')}</Typography>
    </div>
  );
};
