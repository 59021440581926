import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAdditionalPersonRequirementField } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/AdditionalPersonRequirementModalContent';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';

export const PersonRequirementAssistanceSelect: React.FC<IAdditionalPersonRequirementField> = ({
  form,
}) => {
  const { t: additionalPersonRequirementsModalTranslations } = useTranslation(
    'additionalPersonRequirementModal'
  );

  const { availableAssistanceTypes } = useAppSelector(
    organizationalUnitsModalsSelectors.additionalPersonRequirementModal
  );

  const { setValue, watch } = form;
  const selectedAssistanceID = watch('assistanceID');

  const assistanceSelectOptions: ISelectItem[] = availableAssistanceTypes.map<ISelectItem>(
    (assistanceType) => ({
      id: String(assistanceType.id),
      component: <Typography>{assistanceType.name}</Typography>,
    })
  );

  const handleAssistanceTypeSelect = (id: string) => {
    setValue('assistanceID', Number(id), { shouldDirty: true });
  };

  return (
    <div className={'flex flex-col gap-2'}>
      <Typography>
        {additionalPersonRequirementsModalTranslations('fields.needAssistance.label')}
      </Typography>
      <Select
        values={assistanceSelectOptions}
        initialValueID={String(selectedAssistanceID)}
        onChange={handleAssistanceTypeSelect}
        isFlexible={true}
      />
    </div>
  );
};
