import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { Input } from '@/components/Input/Input';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { medicationAdministrationActions } from '@/core/redux/slices/medicationAdministration/medicationAdministrationSlice';
import { medicationAdministrationSelectors } from '@/core/redux/slices/medicationAdministration/selectors';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import { LoadingStatus } from '@/types/loadingStatus';

import useFetchedAdministers from '../hooks/useFetchedAdministers';
import useFetchedAdministrationDepartments from '../hooks/useFetchedAdministrationDepartments';

interface IMedicationHeader {
  isAdministered: boolean;
}

interface IFilterForm {
  date?: string | null;
  search?: string;
  department?: number;
  administer?: number;
}

export const MedicationHeader: React.FC<IMedicationHeader> = ({ isAdministered }) => {
  const form = useForm<IFilterForm>({
    defaultValues: {
      date: toClientDateInput(new Date().toDateString()),
      search: undefined,
      department: undefined,
      administer: undefined,
    },
  });

  const dispatch = useAppDispatch();

  const isOnlyOnDemand = useAppSelector(medicationAdministrationSelectors.isOnlyOnDemand);

  const markUnadministratedLock = useAppSelector(
    medicationAdministrationSelectors.markUnadministratedLock
  );

  const markAdministratedLock = useAppSelector(
    medicationAdministrationSelectors.markAdministratedLock
  );

  const selectedDate = form.watch('date');
  const { administers } = useFetchedAdministers(selectedDate);
  const { administrationDepartments } = useFetchedAdministrationDepartments();

  const { t: medicationAdministrationTranslations } = useTranslation('medicationAdministration');

  // format select options

  const departmentSelectOptions: ISelectItem[] = useMemo(
    () =>
      administrationDepartments?.map(
        (item): ISelectItem => ({
          id: String(item.id),
          component: item.name,
        })
      ),
    [administrationDepartments]
  );

  const administerSelectOptions: ISelectItem[] = useMemo(
    () =>
      administers?.map(
        (item): ISelectItem => ({
          id: String(item.id),
          component: item.administer,
        })
      ),
    [administers]
  );

  const fetchMedications = (isAdministered: boolean, value: IFilterForm) => {
    const { department, date, search, administer } = value;

    if (isAdministered) {
      dispatch(
        medicationAdministrationActions.fetchAdministratedMedications({
          departmentID: department,
          date: date,
          isOnlyOnDemand: isOnlyOnDemand,
          administerID: administer,
          search: search,
        })
      );
    } else {
      dispatch(
        medicationAdministrationActions.fetchUnadministratedMedications({
          departmentID: department,
          isOnlyOnDemand: isOnlyOnDemand,
          date: date,
          search: search,
        })
      );
    }
  };

  // fetch medications om isAdministered status change

  useEffect(() => {
    const value = form.getValues();
    fetchMedications(isAdministered, value);
  }, [isAdministered, form.watch(), isOnlyOnDemand]);

  // fetch medications after mark update

  useEffect(() => {
    if (markUnadministratedLock === LoadingStatus.LOADED) {
      const value = form.getValues();
      fetchMedications(true, value);
      dispatch(medicationAdministrationActions.setMarkUnadministratedLock(LoadingStatus.NEVER));
    }
  }, [markUnadministratedLock]);

  useEffect(() => {
    if (markAdministratedLock === LoadingStatus.LOADED) {
      const value = form.getValues();
      fetchMedications(false, value);
      dispatch(medicationAdministrationActions.setMarkUnadministratedLock(LoadingStatus.NEVER));
    }
  }, [markAdministratedLock]);

  return (
    <FormProvider {...form}>
      <div className='flex gap-4 items-center'>
        <div>
          <DatePicker
            control={form.control.register('date')}
            isNavigate
            isFutureSelectionLocked={true}
          />
        </div>
        <div className='flex items-center gap-4'>
          <Input
            inputVariant='WidgetSearch'
            placeholder={medicationAdministrationTranslations('filter.search.label')}
            control={form.control.register('search')}
          />
        </div>
        <div className='flex items-center gap-4'>
          <SelectForm
            values={departmentSelectOptions}
            control={form.control.register('department')}
            className='w-full max-w-[415px]'
            placeholder={medicationAdministrationTranslations('filter.department.label')}
            isFlexible
            isResetable
          />
        </div>
        {isAdministered && (
          <div className='flex items-center gap-4'>
            <SelectForm
              values={administerSelectOptions}
              control={form.control.register('administer')}
              className='w-full'
              placeholder={medicationAdministrationTranslations('filter.administer.label')}
              isFlexible
              isResetable
            />
          </div>
        )}
      </div>
    </FormProvider>
  );
};
