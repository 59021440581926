import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IGeneralData {
  name?: string | null;
  surname?: string | null;
  profession?: string | null;
  gender?: number;
  email?: string | null;
  id: number;
  salutation?: number;
  birthDate?: string | null;
  professionSinceTimestamp?: string | null;
  inactiveTimestamp?: string | null;
  roles: { [key: number]: boolean };
  targetCatalogTypes: { [key: number]: boolean };
  isSocialServiceWorker: boolean;
  isEducationalSupporter: boolean;
  isGroupManager: boolean;
  isInternshipSupervisor: boolean;
  telephoneNumber?: string | null;
  cellPhoneNumber?: string | null;
  isSpecialist?: boolean | null;
  isJobcoach?: boolean | null;
  isManager?: boolean | null;
}

export interface IAdminister {
  id: number;
  administer: string;
}

export interface IAdministerFetchPayload {
  date: string | null;
}

export interface IGeneralDataFetchPayload {
  userID: number;
}

export interface IGeneralDataUpdatePayload {
  generalData: IGeneralData;
  userID: number;
}

export interface ISpecialistGeneralDataState {
  generalData: IGeneralData | null;
  generalDataLock: LoadingStatus;
  administers: IAdminister[];
  administersLock: LoadingStatus;
  updateGeneralDataLock: LoadingStatus;
}

const initialState: ISpecialistGeneralDataState = {
  generalData: null,
  generalDataLock: LoadingStatus.NEVER,
  administers: [],
  administersLock: LoadingStatus.NEVER,
  updateGeneralDataLock: LoadingStatus.NEVER,
};

export const specialistGeneralDataSlice = createSlice({
  name: 'specialistGeneralData',
  initialState,
  reducers: {
    //general data
    fetchGeneralData: (state, action: PayloadAction<IGeneralDataFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setGeneralDataLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      generalDataLock: action.payload,
    }),
    setGeneralData: (state, action: PayloadAction<IGeneralData | null>) => ({
      ...state,
      generalData: action.payload ?? null,
    }),
    // update
    updateGeneralData: (state, action: PayloadAction<IGeneralDataUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateGeneralDataLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateGeneralDataLock: action.payload,
    }),
    //administer
    fetchAdministers: (state, action: PayloadAction<IAdministerFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAdministers: (state, action: PayloadAction<IAdminister[]>) => ({
      ...state,
      administers: action.payload,
    }),
    setAdministersLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      administersLock: action.payload,
    }),
  },
});

export const specialistGeneralDataReducers = specialistGeneralDataSlice.reducer;
export const specialistGeneralDataActions = specialistGeneralDataSlice.actions;
