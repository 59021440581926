import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { Input } from '@/components/Input/Input';
import { useFecthedMeasureAppointments } from '@/components/Modals/Modals/efficacyAssessment/RecordingEffectivenessModal/hooks/useFetchedMeasureAppointments';
import { IRecordEffectivenessFormValues } from '@/components/Modals/Modals/efficacyAssessment/RecordingEffectivenessModal/RecordingEffectivenessModal';
import { ExternalSelectForm } from '@/components/Select/form/ExternalSelectForm';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { TextArea } from '@/components/TextArea/TextArea';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useSelectedPersonLocation } from '@/core/hooks/useSelectedPersonLocation';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsActions } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';
import { efficacyAssessmentModalSelectors } from '@/core/redux/slices/modalsSlice/efficacyAssessment/selectors';
import { twMerge } from '@/core/utils/tailwindUtil';

import { AppointmentsTable } from './AppointmentsTable/AppointmentsTable';
import { TypesCheckboxList } from './TypesCheckboxList/TypesCheckboxList';

export interface IRecordingEffictivenessForm {
  onSubmit: (data: IRecordEffectivenessFormValues) => void;
  className?: string;
}

export const RecordingEffictivenessForm: React.FC<IRecordingEffictivenessForm> = ({
  onSubmit,
  className,
}) => {
  const form = useFormContext<IRecordEffectivenessFormValues>();
  const dispatch = useAppDispatch()

  const { t: recordingEffectivenessModalTranslations } = useTranslation(
    'recordingEffectivenessModal',
    {
      keyPrefix: 'fields',
    }
  );

  const { selectedPersonLocation } = useSelectedPersonLocation();

  const { payload } = useAppSelector(efficacyAssessmentModalSelectors.recordingEffectivenessModal);

  const { selectedPersonID } = useSelectedPerson();

  const fromDate = form.watch('fromTimestamp');
  const toDate = form.watch('toTimestamp');
  const selectedMeasureAppointmentID = form.watch('appointmentID');

  const { measureAppointments, options: measureAppointmentOptions } = useFecthedMeasureAppointments(
    {
      personID: selectedPersonID,
      measureID: payload?.measureID,
    }
  );

  const selectedMeasureAppointment = useMemo(
    () => measureAppointments.find((item) => item.id === Number(selectedMeasureAppointmentID)),
    [measureAppointments, selectedMeasureAppointmentID]
  );

  useEffect(() => {
    if (!selectedMeasureAppointment) {
      return;
    }

    const { hours, fromTimestamp, toTimestamp } = selectedMeasureAppointment;

    hours && form.setValue('hours', hours);
    fromTimestamp && form.setValue('fromTimestamp', fromTimestamp);
    toTimestamp && form.setValue('toTimestamp', toTimestamp);
  }, [selectedMeasureAppointment]);

  const dropdownItems = useAppSelector(dropdownItemsSelectors.dropdownItems);
  const measureLocations = dropdownItems?.[DropdownItemsByFetch.MEASURE_LOCATION];


  useEffect(() => {
    dispatch(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.MEASURE_LOCATION,
      })
    );
  }, []);

  useEffect(() => {
    if (!selectedPersonLocation) {
      return;
    }

    form.setValue('location', selectedPersonLocation.name);
  }, [selectedPersonLocation]);

  const locationOptions: ISelectItem[] = useMemo(() => {
    if (!measureLocations) {
      return [];
    }

    const measureOptions: ISelectItem[] = measureLocations.map((item) => ({
      id: item.value || '',
      component: item.value,
    }));

    if (!selectedPersonLocation) {
      return measureOptions;
    }

    const selectedLocationOption = {
      id: selectedPersonLocation.name,
      component: selectedPersonLocation.name,
    };

    return [selectedLocationOption, ...measureOptions];
  }, [measureLocations, selectedPersonLocation]);

  if (dropdownItems && dropdownItems[DropdownItemsByFetch.MEASURE_LOCATION]) {
    dropdownItems[DropdownItemsByFetch.MEASURE_LOCATION].forEach((dropdownItem) => {
      const newItem: ISelectItem = {
        id: dropdownItem.value || '',
        component: dropdownItem.value,
      };
    });
  }
  

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className={twMerge('flex flex-col gap-4', className)}
    >
      <div className='flex flex-col gap-3'>
        <label></label>
        <div className='flex flex-col gap-4'>
          <TypesCheckboxList
            options={[
              {
                value: 'isParticipated',
                label: recordingEffectivenessModalTranslations('isParticipated.label'),
              },
              {
                value: 'isUnderDirection',
                label: recordingEffectivenessModalTranslations('isUnderDirection.label'),
              },
              {
                value: 'isAlmostIndependent',
                label: recordingEffectivenessModalTranslations('isAlmostIndependent.label'),
              },
              {
                value: 'isIndependent',
                label: recordingEffectivenessModalTranslations('isIndependent.label'),
              },
            ]}
            defaultValues={form.getValues('types')}
          />
        </div>
      </div>
      <div className='flex flex-col gap-3'>
        <label htmlFor='appointmentID'>
          {recordingEffectivenessModalTranslations('appointmentID.label')}
        </label>
        <SelectForm
          values={measureAppointmentOptions}
          control={form.control.register('appointmentID')}
          placeholder={recordingEffectivenessModalTranslations('appointmentID.placeholder')}
          isFlexible
        />
      </div>
      <div className='flex flex-col gap-3'>
        <label htmlFor='location'>
          {recordingEffectivenessModalTranslations('location.label')}:
        </label>
        <SelectForm
          values={locationOptions}
          control={form.control.register('location')}
          placeholder={recordingEffectivenessModalTranslations('location.placeholder')}
          isFlexible
        />
      </div>
      <div className='flex gap-4'>
        <div className='flex flex-col gap-3'>
          <label htmlFor='from_timestamp'>
            {recordingEffectivenessModalTranslations('fromTimestamp.label')}
          </label>
          <DatePicker control={form.control.register('fromTimestamp')} />
        </div>
        <div className='flex flex-col gap-3'>
          <label htmlFor='to_timestamp'>
            {recordingEffectivenessModalTranslations('toTimestamp.label')}
          </label>
          <DatePicker control={form.control.register('toTimestamp')} />
        </div>
      </div>

      {selectedPersonID && fromDate && toDate && (
        <AppointmentsTable personID={selectedPersonID} fromDate={fromDate} toDate={toDate} />
      )}

      <div className='flex flex-col gap-3'>
        <label htmlFor='hours'>{recordingEffectivenessModalTranslations('hours.label')}</label>
        <Input control={form.control.register('hours')} />
      </div>
      <div className='flex flex-col gap-3'>
        <label htmlFor='performerID'>
          {recordingEffectivenessModalTranslations('performerID.label')}
        </label>
        <ExternalSelectForm
          control={form.control.register('performerID')}
          placeholder={recordingEffectivenessModalTranslations('performerID.placeholder')}
          tableName='TEST_PROCEDURE_PERFORMER'
          isFlexible
        />
      </div>

      <div className='flex flex-col gap-3'>
        <label htmlFor='note'>{recordingEffectivenessModalTranslations('note.label')}</label>
        <TextArea control={form.control.register('note')} />
      </div>
    </form>
  );
};

