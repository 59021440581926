import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.medicationAdministration;

const unadministratedMedications = createSelector(
  [selector],
  (state) => state.unadministratedMedications
);
const unadministratedMedicationsLock = createSelector(
  [selector],
  (state) => state.unadministratedMedicationsLock
);

const administratedMedications = createSelector(
  [selector],
  (state) => state.administratedMedications
);
const administratedMedicationsLock = createSelector(
  [selector],
  (state) => state.administratedMedicationsLock
);

const markAdministratedLock = createSelector([selector], (state) => state.markAdministratedLock);
const markUnadministratedLock = createSelector(
  [selector],
  (state) => state.markUnadministratedLock
);

const departments = createSelector([selector], (state) => state.departments);
const isOnlyOnDemand = createSelector([selector], (state) => state.isOnlyOnDemand);

export const medicationAdministrationSelectors = {
  unadministratedMedications,
  unadministratedMedicationsLock,
  administratedMedications,
  administratedMedicationsLock,
  departments,
  markAdministratedLock,
  markUnadministratedLock,
  isOnlyOnDemand
};
