import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { ArrowIcon } from '@/components/Icons/ArrowIcon';
import { Activity } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/ActivityItem/Activity/Activity';
import { ActivityPersonsContainer } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/ActivityItem/ActivityPersonsContainer/ActivityPersonsContainer';
import { SubActivityItem } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/SubActivityItem/SubActivityItem';
import { colorTypes } from '@/styles/types';
import { IActivityItem } from '@/types/activitiesWidget';

export const ActivityItem: React.FC<IActivityItem> = ({ activity }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div
      className={`flex flex-col justify-start items-center border-t border-${colorTypes.ActivityBorder} w-full first:border-0`}
    >
      <div
        className={`flex flex-row w-full items-start gap-3 hover:bg-${colorTypes.HoverLight} py-3`}
      >
        {activity.sub_activities ? (
          <motion.div
            className={`cursor-pointer text-${colorTypes.Blue}`}
            animate={{ rotate: isExpanded ? 180 : 0 }}
            onClick={handleExpand}
          >
            <ArrowIcon />
          </motion.div>
        ) : (
          <div className={'w-[20px]'} />
        )}
        <Activity {...activity} />
        {activity.persons && (
          <ActivityPersonsContainer personsList={activity.persons} activityID={activity.id} />
        )}
      </div>
      <motion.div
        className={'flex flex-col w-full overflow-y-hidden'}
        animate={isExpanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
      >
        {activity.sub_activities?.map((subActivity, index) => (
          <SubActivityItem {...subActivity} index={index} key={subActivity.id} />
        ))}
      </motion.div>
    </div>
  );
};
