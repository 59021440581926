import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from '@/app/tms/App';
import { store } from '@/core/redux/store';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
