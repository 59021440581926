import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { IFormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import FormRender from '@/components/FormRender/FormRender';
import { DevelopmentReportActionsBar } from '@/components/functions/DevelopmentReport/DevelopmentReportActionsBar/DevelopmentReportActionsBar';
import {
  personDetailsDefaultValues,
  personDetailsForm,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/PersonDetails/helper/personDetailsForm';
import { useDevelopmentReport } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReport';
import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { DevelopmentReportDisabilityTypes } from '@/core/enums/functions/developmentReport/developmentReportDisabilityTypesEnum';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { DevelopmentReportLegalSupportTypes } from '@/core/enums/functions/developmentReport/developmentReportLegalSupportTypesEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { developmentReportPersonDetailsSelectors } from '@/core/redux/slices/functions/developmentReport/developmentReportPersonDetailsSlice/selectors';
import { developmentReportPersonDetailsActions } from '@/core/redux/slices/functions/developmentReport/developmentReportPersonDetailsSlice/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const DevelopmentReportPersonDetails: React.FC = () => {
  const { t: developmentReportPersonDetailsTranslations } = useTranslation(
    'developmentReportPersonDetails'
  );

  const personDetails = useAppSelector(
    developmentReportPersonDetailsSelectors.developmentReportPersonDetails
  );
  const personDetailsLoadingStatus = useAppSelector(
    developmentReportPersonDetailsSelectors.developmentReportPersonDetailsLock
  );
  const importedPersonDetailsLoadingStatus = useAppSelector(
    developmentReportPersonDetailsSelectors.importedDevelopmentReportPersonDetailsLock
  );
  const importedPersonDetails = useAppSelector(
    developmentReportPersonDetailsSelectors.importedDevelopmentReportPersonDetails
  );
  const { developmentReportID } = useDevelopmentReport();
  const { selectedPersonID } = useSelectedPerson();

  const {
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useFormContext<IDevelopmentReportForm>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!developmentReportID) {
      return;
    }

    dispatch(
      developmentReportPersonDetailsActions.fetchDevelopmentReportPersonDetails({
        developmentReportID: developmentReportID,
      })
    );

    return () => {
      dispatch(developmentReportPersonDetailsActions.setDevelopmentReportPersonDetails(null));

      const currentValues = fp.cloneDeep(watch());
      reset({
        ...currentValues,
        [DevelopmentReportForms.PersonDetails]: personDetailsDefaultValues,
      });
    };
  }, [developmentReportID]);

  useEffect(() => {
    if (!importedPersonDetails) {
      return;
    }

    setValue(DevelopmentReportForms.PersonDetails, importedPersonDetails);
    dispatch(developmentReportPersonDetailsActions.setImportedDevelopmentReportPersonDetails(null));
  }, [importedPersonDetails]);

  const handleImport = () => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      developmentReportPersonDetailsActions.fetchImportedDevelopmentReportPersonDetails({
        personID: selectedPersonID,
      })
    );
  };

  const handleLegalGuardianChange = (value: DevelopmentReportLegalSupportTypes) => {
    setValue(
      `PersonDetails.legalGuardianNameAddress`,
      value === DevelopmentReportLegalSupportTypes.No
        ? (null as never)
        : (personDetails?.defaultLegalGuardianNameAddress as never)
    );
  };

  const hbgRadioButtonGroupOptions: Omit<IFormRadioButtonGroup, 'control'> = {
    options: [
      {
        value: DevelopmentReportDisabilityTypes.HBG1,
        component: (
          <Typography>
            {developmentReportPersonDetailsTranslations('groups.disabilityType.fields.hbg1.label')}
          </Typography>
        ),
      },
      {
        value: DevelopmentReportDisabilityTypes.HBG2,
        component: (
          <Typography>
            {developmentReportPersonDetailsTranslations('groups.disabilityType.fields.hbg2.label')}
          </Typography>
        ),
      },
      {
        value: DevelopmentReportDisabilityTypes.HBG3,
        component: (
          <Typography>
            {developmentReportPersonDetailsTranslations('groups.disabilityType.fields.hbg3.label')}
          </Typography>
        ),
      },
    ],
    containerClassName: 'flex-row gap-3',
  };
  const legalGuardianRadioButtonGroupOptions: Omit<IFormRadioButtonGroup, 'control'> = {
    options: [
      {
        value: DevelopmentReportLegalSupportTypes.Yes,
        component: (
          <Typography>
            {developmentReportPersonDetailsTranslations('groups.legalSupport.fields.yes.label')}
          </Typography>
        ),
        onChange: handleLegalGuardianChange,
      },
      {
        value: DevelopmentReportLegalSupportTypes.No,
        component: (
          <Typography>
            {developmentReportPersonDetailsTranslations('groups.legalSupport.fields.no.label')}
          </Typography>
        ),
        onChange: handleLegalGuardianChange,
      },
    ],
    containerClassName: 'flex-row gap-3',
  };

  return (
    <Skeleton trigger={personDetailsLoadingStatus === LoadingStatus.LOADING}>
      <FormRender<IDevelopmentReportForm>
        list={personDetailsForm({
          hbgRadioButtonGroupOptions,
          legalGuardianRadioButtonGroupOptions,
        })}
        translationContext={developmentReportPersonDetailsTranslations}
        className={'py-4 flex flex-col gap-2'}
      />
      <DevelopmentReportActionsBar
        importOptions={{
          handleImport,
          loadingStatus: importedPersonDetailsLoadingStatus,
        }}
        errorsOptions={{ errors: errors, formKey: DevelopmentReportForms.PersonDetails }}
        resetOptions={{
          onReset: reset,
          isDirty: isDirty,
        }}
      />
    </Skeleton>
  );
};
