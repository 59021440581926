import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IActionCourse {
  dateBlock?: string | null;
  individualAppointmentReason?: string | null;
  observationFromTimestamp?: string | null;
  observationToTimestamp?: string | null;
  integrationPlanComment?: string | null;
  nextCheckTimestamp?: string | null;
  qualificationGoal?: string | null;
  specialQuestions?: string | null;
  createdTimestamp?: string | null;
  bbbComment?: string | null;
  apgNote?: string | null;
  notes?: string | null;
}

export interface IOrganizationUnitsItem {
  organizationUnitID: number;
  fromTimestamp: string;
  toTimestamp: string;
}

export interface IActionCourseFetchPayload {
  integrationPlanID: number;
  personID: number;
}

export interface IActionCourseUpdatePayload {
  integrationPlanID: number;
  personID: number;
  actionCourse: IActionCourse;
}

export interface IActionCourseState {
  actionCourse: IActionCourse | null;
  organizationUnits: IOrganizationUnitsItem[] | null;
  actionCourseLock: LoadingStatus;
  updateActionCourseLock: LoadingStatus;
}

const initialState: IActionCourseState = {
  actionCourse: null,
  organizationUnits: null,
  actionCourseLock: LoadingStatus.NEVER,
  updateActionCourseLock: LoadingStatus.NEVER,
};

export const actionCourseSlice = createSlice({
  name: 'actionCourse',
  initialState,
  reducers: {
    fetchActionCourse: (state, action: PayloadAction<IActionCourseFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateActionCourse: (state, action: PayloadAction<IActionCourseUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateWithFetchActionCourse: (state, action: PayloadAction<IActionCourseUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setActionCourseLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      actionCourseLock: action.payload,
    }),
    setUpdateActionCourseLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateActionCourseLock: action.payload,
    }),
    setActionCourse: (state, action: PayloadAction<IActionCourse | null>) => ({
      ...state,
      actionCourse: action.payload ?? null,
    }),
    setOrganizationUnits: (state, action: PayloadAction<IOrganizationUnitsItem[]>) => ({
      ...state,
      organizationUnits: action.payload,
    }),
  },
});

export const actionCourseReducers = actionCourseSlice.reducer;

export const actionCourseActions = actionCourseSlice.actions;
