import React from 'react';

import { Button } from '@/components/Button/Button';
import { CheckedIcon } from '@/components/Icons/CheckedIcon';
import { IPersonDropDownItem } from '@/types/person';

export const PersonDropDownItem: React.FC<IPersonDropDownItem> = ({
  onClick,
  text,
  selected = false,
}) => {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <Button
      onClick={handleClick}
      buttonVariant={'UserSelect'}
      buttonStyle={'WithIcon'}
      className={'w-full flex justify-between'}
    >
      {text}
      {selected ? <CheckedIcon /> : <div className={'w-[24px]'} />}
    </Button>
  );
};
