import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { dropdownItemsActions } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import {
  diagnosticsSpecialFeaturesActions,
  IDiagnosticsSpecialFeaturesFetchPayload,
  IDiagnosticsSpecialFeaturesUpdatePayload,
} from '@/core/redux/slices/functions/medicalInformation/specialFeatures/slice';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDate } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type DiagnosticsSpecialFeaturesResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/special_features',
  'get',
  '200'
>;
type DiagnosticsSpecialFeaturesFetchRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/special_features',
  'get'
>;

type DiagnosticsSpecialFeaturesUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/special_features',
  'post'
>;

function* fetchDiagnosticsSpecialFeatures(
  action: PayloadAction<IDiagnosticsSpecialFeaturesFetchPayload>
): Generator<any, void, DiagnosticsSpecialFeaturesResponse> {
  const { personID } = action.payload;

  try {
    yield put(
      diagnosticsSpecialFeaturesActions.setDiagnosticsSpecialFeaturesLock(LoadingStatus.LOADING)
    );

    const request: DiagnosticsSpecialFeaturesFetchRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/special_features', 'get', request);

    const mappedResponse = toCamelCase(response.special_features);

    yield put(diagnosticsSpecialFeaturesActions.setDiagnosticsSpecialFeatures(mappedResponse));

    yield put(
      dropdownItemsActions.fetchDropdownItems({ dropdownTable: DropdownItemsByFetch.CARE_LEVEL })
    );

    yield put(
      diagnosticsSpecialFeaturesActions.setDiagnosticsSpecialFeaturesLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on diagnostics disability characteristics fetch', error);
    yield put(
      diagnosticsSpecialFeaturesActions.setDiagnosticsSpecialFeaturesLock(LoadingStatus.ERROR)
    );
  }
}

function* updateDiagnosticsSpecialFeatures(
  action: PayloadAction<IDiagnosticsSpecialFeaturesUpdatePayload>
): Generator<any, void, any> {
  const { personID, formValues } = action.payload;

  try {
    yield put(
      diagnosticsSpecialFeaturesActions.setUpdateDiagnosticsSpecialFeaturesLock(
        LoadingStatus.LOADING
      )
    );

    const request: DiagnosticsSpecialFeaturesUpdateRequest = {
      json: {
        person_id: personID,
        allergic_note: formValues?.isAllergic ? formValues?.allergicNote : null,
        asthmatic_note: formValues?.isAsthmatic ? formValues?.asthmaticNote : null,
        diabetic_note: formValues?.isDiabetic ? formValues?.diabeticNote : null,
        has_allergy_passport: formValues?.isAllergic
          ? formValues?.hasAllergyPassport || false
          : false,
        heart_disease_note: formValues?.isHeartDisease ? formValues?.heartDiseaseNote : null,
        is_allergic: formValues?.isAllergic,
        is_asthmatic: formValues?.isAsthmatic,
        is_diabetic: formValues?.isDiabetic,
        is_emergency_medication: formValues?.isAsthmatic
          ? formValues?.isEmergencyMedication
          : false,
        is_heart_disease: formValues?.isHeartDisease,
        is_insulin_required: formValues?.isDiabetic ? formValues?.isInsulinRequired : false,
        is_other: formValues?.isOther,
        is_seizure_disorder: formValues?.isSeizureDisorder,
        is_tablets_required: formValues?.isDiabetic ? formValues?.isTabletsRequired : false,
        last_seizure_date: formValues?.isSeizureDisorder
          ? formValues?.lastSeizureDate
            ? toBackendDate(formValues?.lastSeizureDate)
            : null
          : null,
        other_note: formValues?.isOther ? formValues?.otherNote : null,
        seizure_disorder_form: formValues?.isSeizureDisorder
          ? formValues?.seizureDisorderForm
          : null,
        seizure_disorder_note: formValues?.isSeizureDisorder
          ? formValues?.seizureDisorderNote
          : null,
      },
      ...authAdd(),
    };

    yield call(restCall, '/special_features', 'post', request);

    yield put(
      diagnosticsSpecialFeaturesActions.setUpdateDiagnosticsSpecialFeaturesLock(
        LoadingStatus.LOADED
      )
    );

    yield put(
      diagnosticsSpecialFeaturesActions.fetchDiagnosticsSpecialFeatures({
        personID: personID,
      })
    );
  } catch (error) {
    console.log('Error on diagnostics disability characteristics update', error);
    yield put(
      diagnosticsSpecialFeaturesActions.setUpdateDiagnosticsSpecialFeaturesLock(LoadingStatus.ERROR)
    );
  }
}

export const diagnosticsSpecialFeaturesSagas = [
  takeLatest(
    diagnosticsSpecialFeaturesActions.fetchDiagnosticsSpecialFeatures,
    fetchDiagnosticsSpecialFeatures
  ),
  takeLatest(
    diagnosticsSpecialFeaturesActions.updateDiagnosticsSpecialFeatures,
    updateDiagnosticsSpecialFeatures
  ),
];
