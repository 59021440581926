import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { FunctionTypeCard } from '@/components/Modals/Modals/functionsPage/InitialFunctionTypeChooseModal/InitialFunctionTypeContent/FunctionTypeCard/FunctionTypeCard';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageActions } from '@/core/redux/slices/functionPage/functionPageSlice';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { commonModalsActions } from '@/core/redux/slices/modalsSlice/common/commonModalsSlice';
import { useGetFunctionTypesQuery } from '@/services/graphql/base/graphql';
import { FunctionTypes, IFunctionItem } from '@/types/functions';

import styles from './styles.module.scss';

const rememberChoice = 'remember';

export const InitialFunctionTypeContent: React.FC<{ id: string | null }> = ({ id }) => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui' });
  const [selectedType, setSelectedType] = useState<FunctionTypes>(FunctionTypes.none);

  const dispatch = useAppDispatch();

  const [functionTypesMap, setFunctionTypesMap] = useState<Map<string, string>>();

  const functionTypesResponse = useGetFunctionTypesQuery();

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const activeTabId = useAppSelector(functionPageSelectors.activeTabId);
  const functions = useAppSelector(functionPageSelectors.functions);

  const fm = useForm();

  const isRemembered = fm.watch(rememberChoice);

  const handleTypeChange = (type: FunctionTypes) => {
    setSelectedType(type);
  };

  const handleToFunction = () => {
    if (selectedPerson && activeTabId && id) {
      if (isRemembered) {
        dispatch(
          functionPageActions.updateFunctionType({
            id,
            functionType: functionTypesMap?.get(selectedType),
          })
        );
      }
      const functionItem = functions.find((func: IFunctionItem) => func.id === id);

      if (functionItem) {
        const { iconUrl, functionID, name } = functionItem;
        const payload = {
          userId: selectedPerson.id,
          tabId: activeTabId,
          functionItem: {
            id: functionID,
            functionType: selectedType,
            iconUrl,
            name,
          },
        };
        dispatch(functionPageActions.setTabFunction(payload));
      }
      dispatch(commonModalsActions.closeChooseFunctionTypeModal());
    }
  };

  useEffect(() => {
    const functionTypeDict = new Map<string, string>();
    functionTypesResponse.data?.function_types.forEach((item) =>
      functionTypeDict.set(item.type, item.id)
    );
    setFunctionTypesMap(functionTypeDict);
  }, [functionTypesResponse]);

  return (
    <div
      className={clsx(styles.container, 'flex flex-col items-center justify-center relative p-7')}
    >
      <Typography type='H2' color='Primary' className='self-start'>
        {t('title.selectAction')}
      </Typography>
      <div className='mt-5 flex flex-row gap-5'>
        <FunctionTypeCard
          type={FunctionTypes.wizard}
          handleTypeChange={handleTypeChange}
          text={t('label.guidedProcess')}
          badgeColor='#E57D14'
          selected={selectedType === FunctionTypes.wizard}
        />
        <FunctionTypeCard
          type={FunctionTypes.full}
          handleTypeChange={handleTypeChange}
          text={t('label.inputTemplate')}
          badgeColor='#1AAEC0'
          selected={selectedType === FunctionTypes.full}
        />
      </div>
      <div className='flex flex-row items-start w-full mt-5'>
        <FormProvider {...fm}>
          <Checkbox name={rememberChoice} id={rememberChoice} />
          <Typography type='16-R' color='Primary' className='ms-2'>
            <label htmlFor={rememberChoice} className='cursor-pointer'>
              {t('label.rememberSelection')}
            </label>
          </Typography>
        </FormProvider>
      </div>
      <Button
        className='w-full mt-7 flex justify-center'
        disabled={selectedType === FunctionTypes.none}
        onClick={handleToFunction}
      >
        {t('button.chooseFunction')}
      </Button>
    </div>
  );
};
