import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IParticipantExpectation {
  creationReason?: string | null;
  individualAppointmentReason?: string | null;
  inm1?: string | null;
  inm1Method?: string | null;
  inm2?: string | null;
  inm2Method?: string | null;
  inm3?: string | null;
  inm3Method?: string | null;
  inm4?: string | null;
  inm4Method?: string | null;
  wem1?: string | null;
  wem1Method?: string | null;
  wem2?: string | null;
  wem2Method?: string | null;
  wem3?: string | null;
  wem3Method?: string | null;
  notes?: string | null;
}

export interface IParticipantExpectationFetchPayload {
  integrationPlanID: number;
}

export interface IParticipantExpectationUpdatePayload {
  integrationPlanID: number;
  personID: number;
  participantExpectation: IParticipantExpectation;
}

export interface IParticipantExpectationState {
  participantExpectation: IParticipantExpectation | null;
  participantExpectationLock: LoadingStatus;
  updateParticipantExpectationLock: LoadingStatus;
}

const initialState: IParticipantExpectationState = {
  participantExpectation: null,
  participantExpectationLock: LoadingStatus.NEVER,
  updateParticipantExpectationLock: LoadingStatus.NEVER,
};

export const participantExpectationSlice = createSlice({
  name: 'participantExpectation',
  initialState,
  reducers: {
    fetchParticipantExpectation: (
      state,
      action: PayloadAction<IParticipantExpectationFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    updateParticipantExpectation: (
      state,
      action: PayloadAction<IParticipantExpectationUpdatePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setParticipantExpectationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      participantExpectationLock: action.payload,
    }),
    setUpdateParticipantExpectationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateParticipantExpectationLock: action.payload,
    }),
    setParticipantExpectation: (state, action: PayloadAction<IParticipantExpectation | null>) => ({
      ...state,
      participantExpectation: action.payload,
    }),
  },
});

export const participantExpectationReducers = participantExpectationSlice.reducer;

export const participantExpectationActions = participantExpectationSlice.actions;
