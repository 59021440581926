import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPerson } from '@/types/activitiesWidget';

export interface IModal {
  isRender: boolean;
}

export interface IActivityPersonsList {
  personList: IPerson[] | null;
}

export interface IActivityPersonsListModal extends IModal {
  payload: IActivityPersonsList;
}

export interface IWidgetsModalsState {
  widgetControlModal: IModal;
  personWidgetModal: IModal;
  activityPersonsListModal: IActivityPersonsListModal;
}

const initialState: IWidgetsModalsState = {
  personWidgetModal: {
    isRender: false,
  },
  widgetControlModal: {
    isRender: false,
  },
  activityPersonsListModal: {
    isRender: false,
    payload: {
      personList: null,
    },
  },
};

export const widgetsModalsSlice = createSlice({
  name: 'widgetModals',
  initialState,
  reducers: {
    // Person widget modal
    openPersonWidgetModal: (state) => {
      return {
        ...state,
        personWidgetModal: {
          isRender: true,
        },
      };
    },
    closePersonWidgetModal: (state) => {
      return {
        ...state,
        personWidgetModal: {
          isRender: false,
        },
      };
    },
    // Widget control modal
    openWidgetControlModal: (state) => {
      return {
        ...state,
        widgetControlModal: {
          isRender: true,
        },
      };
    },
    closeWidgetControlModal: (state) => {
      return {
        ...state,
        widgetControlModal: {
          isRender: false,
        },
      };
    },
    //Activity person list modal
    openActivityPersonsListModal: (state, action: PayloadAction<IActivityPersonsList>) => {
      return {
        ...state,
        activityPersonsListModal: {
          isRender: true,
          payload: action.payload,
        },
      };
    },
    closeActivityPersonsListModal: (state) => {
      return {
        ...state,
        activityPersonsListModal: {
          isRender: false,
          payload: {
            personList: null,
          },
        },
      };
    },
  },
});

export const widgetsModalsReducers = widgetsModalsSlice.reducer;
export const widgetsModalsActions = widgetsModalsSlice.actions;
