import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  generalDataActions,
  IGeneralData,
} from '@/core/redux/slices/functions/integrationPlan/generalData/generalDataSlice';
import { generalDataSelectors } from '@/core/redux/slices/functions/integrationPlan/generalData/selectors';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';

export const GeneralDataUpdatedDataModal: React.FC = () => {
  const { t: updatedDataModalTranslations } = useTranslation('generalDataUpdatedDataModal');

  const { isRender: isOpened, payload } = useAppSelector(
    integrationPlanModalsSelectors.generalDataUpdatedDataModal
  );

  const generalData = useAppSelector(generalDataSelectors.generalData);

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(integrationPlanModalsActions.closeGeneralDataUpdatedDataModal());
  };

  if (isOpened) {
    const handleUpdateGeneralData = () => {
      if (payload && generalData) {
        const newGeneralData: IGeneralData = {
          ...generalData,
          ...payload.updatedData,
        };

        dispatch(
          generalDataActions.updateWithFetchGeneralData({
            integrationPlanID: payload.integrationPlanID,
            personID: payload.personID,
            generalData: newGeneralData,
          })
        );

        handleExit();
      }
    };

    return (
      <Modal onExit={handleExit}>
        <div className={'flex flex-col min-w-[500px] max-w-[740px] min-h-[200px] gap-3'}>
          <Typography type={'H3'}>{updatedDataModalTranslations('title.label')}</Typography>
          <div className={'flex flex-col gap-3 flex-grow'}>
            {payload?.updatedData &&
              Object.keys(payload?.updatedData).map((updatedDataKey) => (
                <Typography key={updatedDataKey}>
                  {updatedDataKey === 'disabilityType'
                    ? updatedDataModalTranslations('fields.disabilityType.label')
                    : updatedDataKey}
                </Typography>
              ))}
          </div>
          <div className={'flex flex-row self-end gap-3'}>
            <Button buttonVariant={'Secondary'} onClick={handleExit}>
              {updatedDataModalTranslations('button.cancel.label')}
            </Button>
            <Button onClick={handleUpdateGeneralData}>
              {updatedDataModalTranslations('button.save.label')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  return null;
};
