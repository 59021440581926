import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateSelfAssessmentForPersonMutationVariables,
  GetSelfAssessmentAnswersByPersonQueryVariables,
  GetSelfAssessmentAnswersQueryVariables,
  GetSelfAssessmentPersonQuestionnaireQueryVariables,
  GetSelfAssessmentQuestionnairesByPersonQueryVariables,
  UpdateSelfAssessmentForPersonMutationVariables,
} from '@/services/graphql/base/graphql';

export interface ISelfAssessmentQuestionnaire {
  sebID: string;
  name: string;
  questions: ISelfAssessmentQuestion[];
}

export interface ISelfAssessmentQuestion {
  sefID: string;
  name: string;
}

export interface ISelfAssessmentAnswer {
  seaID: string;
  m2sID: string;
  sefID: string;
  answer: number;
  note: string;
  lastChange: string;
}

export interface ISelfAssessmentPersonQuestionnaire {
  m2sID: string;
  personID: string;
  sebID: string;
  createdByID: string;
  createdDate: string;
  statusName: string;
  answeredByPerson: boolean;
}

export interface ISelfAssessmentPersonQuestionnaireWithDetails
  extends ISelfAssessmentPersonQuestionnaire {
  questionnaireName: string;
  numQuestions: number;
  numAnswered: number;
  lastChange: string;
}

export interface ISelfAssessmentPersonQuestionnaireWithData
  extends ISelfAssessmentPersonQuestionnaire {
  questionnaire: ISelfAssessmentQuestionnaire | null;
  answers: ISelfAssessmentAnswer[];
  lastChange: string;
}

export interface IFetchPersonAnswersPayload {
  variables: GetSelfAssessmentAnswersByPersonQueryVariables;
}

export interface IFetchPersonQuestionnaireAnswersPayload {
  variables: GetSelfAssessmentAnswersQueryVariables;
}

export interface IFetchPersonQuestionnairePayload {
  variables: GetSelfAssessmentPersonQuestionnaireQueryVariables;
  availableQuestionnaires: ISelfAssessmentQuestionnaire[];
  personQuestionnaireAnswers: ISelfAssessmentAnswer[];
}

export interface IFetchPersonQuestionnairesPayload {
  variables: GetSelfAssessmentQuestionnairesByPersonQueryVariables;
  availableQuestionnaires: ISelfAssessmentQuestionnaire[];
  personAnswers: ISelfAssessmentAnswer[];
}

export interface ICreateSelfAssessmentForPersonPayload {
  variables: CreateSelfAssessmentForPersonMutationVariables;
}

export interface IUpdateSelfAssessmentForPersonPayload {
  variables: UpdateSelfAssessmentForPersonMutationVariables;
}

export interface ISimpleSelfAssesmentViewDetails {
  selectedPersonQuestionnaire: Record<number, string>;
  selectedPersonSimpleVersion: Record<number, boolean>;
}

export interface ISimpleSelfAssesmentQuestionnaireState {
  availableQuestionnaires: ISelfAssessmentQuestionnaire[];
  personQuestionnaires: ISelfAssessmentPersonQuestionnaireWithDetails[];
  personAnswers: ISelfAssessmentAnswer[];
  personQuestionnaire: ISelfAssessmentPersonQuestionnaireWithData | null;
  personQuestionnaireAnswers: ISelfAssessmentAnswer[];
  updatedPersonQuestionnaireID: string | null;
  viewDetails: ISimpleSelfAssesmentViewDetails;
}

const initialState: ISimpleSelfAssesmentQuestionnaireState = {
  availableQuestionnaires: [],
  personQuestionnaires: [],
  personAnswers: [],
  personQuestionnaire: null,
  personQuestionnaireAnswers: [],
  updatedPersonQuestionnaireID: null,
  viewDetails: {
    selectedPersonQuestionnaire: {},
    selectedPersonSimpleVersion: {},
  },
};

export const simpleSelfAssesmentSlice = createSlice({
  name: 'simpleSelfAssesment',
  initialState,
  reducers: {
    fetchQuestionnaires: (state, action: PayloadAction) => ({
      ...state,
      payload: action.payload,
    }),
    setQuestionnaires: (state, action: PayloadAction<ISelfAssessmentQuestionnaire[]>) => ({
      ...state,
      availableQuestionnaires: action.payload,
    }),
    fetchPersonQuestionnaire: (state, action: PayloadAction<IFetchPersonQuestionnairePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setPersonQuestionnaire: (
      state,
      action: PayloadAction<ISelfAssessmentPersonQuestionnaireWithData>
    ) => ({
      ...state,
      personQuestionnaire: action.payload,
    }),
    fetchPersonQuestionnaires: (
      state,
      action: PayloadAction<IFetchPersonQuestionnairesPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setPersonQuestionnaires: (
      state,
      action: PayloadAction<ISelfAssessmentPersonQuestionnaireWithDetails[]>
    ) => ({
      ...state,
      personQuestionnaires: action.payload,
    }),
    fetchPersonAnswers: (state, action: PayloadAction<IFetchPersonAnswersPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setPersonAnswers: (state, action: PayloadAction<ISelfAssessmentAnswer[]>) => ({
      ...state,
      personAnswers: action.payload,
    }),
    fetchPersonQuestionnaireAnswers: (
      state,
      action: PayloadAction<IFetchPersonQuestionnaireAnswersPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setPersonQuestionnaireAnswers: (state, action: PayloadAction<ISelfAssessmentAnswer[]>) => ({
      ...state,
      personQuestionnaireAnswers: action.payload,
    }),
    createSelfAssessmentForPerson: (
      state,
      action: PayloadAction<ICreateSelfAssessmentForPersonPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    updateSelfAssessmentForPerson: (
      state,
      action: PayloadAction<IUpdateSelfAssessmentForPersonPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdatedPersonQuestionnaireID: (state, action: PayloadAction<string | null>) => ({
      ...state,
      updatedPersonQuestionnaireID: action.payload,
    }),
    setSelectedPersonQuestionnaire: (
      state,
      action: PayloadAction<{ personID: number; questionnaireID: string }>
    ) => ({
      ...state,
      viewDetails: {
        ...state.viewDetails,
        selectedPersonQuestionnaire: {
          ...state.viewDetails.selectedPersonQuestionnaire,
          [action.payload.personID]: action.payload.questionnaireID,
        },
      },
    }),
    setSelectedPersonIsSimpleVersion: (
      state,
      action: PayloadAction<{ personID: number; isSimpleVersion: boolean }>
    ) => ({
      ...state,
      viewDetails: {
        ...state.viewDetails,
        selectedPersonSimpleVersion: {
          ...state.viewDetails.selectedPersonSimpleVersion,
          [action.payload.personID]: action.payload.isSimpleVersion,
        },
      },
    }),
  },
});

export const simpleSelfAssesmentReducer = simpleSelfAssesmentSlice.reducer;
export const simpleSelfAssesmentActions = simpleSelfAssesmentSlice.actions;
