import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.functions.medicalInformation.disabilityCharacteristics;

const disabilityCharacteristics = createSelector(
  [selector],
  (state) => state.disabilityCharacteristics
);
const disabilityCharacteristicsLock = createSelector(
  [selector],
  (state) => state.disabilityCharacteristicsLock
);
const updateLock = createSelector([selector], (state) => state.updateLock);

export const disabilityCharacteristicsSelectors = {
  disabilityCharacteristics,
  disabilityCharacteristicsLock,
  updateLock,
};
