import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.activityPlanning.measuresAppointmentsList;

const measuresAppointmentsList = createSelector(
  [selector],
  (state) => state.measuresAppointmentsList
);
const measuresAppointmentsListLock = createSelector(
  [selector],
  (state) => state.measuresAppointmentsListLock
);

export const activityPlaningMeasuresAppointmentsListSelectors = {
  measuresAppointmentsList,
  measuresAppointmentsListLock,
};
