import React from 'react';

import { IQuestionaryImage } from '@/types/questionaryImage';

export const RightImage: React.FC<IQuestionaryImage> = ({ className }) => (
  <img
    alt=''
    src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAUCAYAAAC07qxWAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB9sHBAciEtgrLOYAAAFxSURBVCjPbZI9jtRAFIS/slagQZuREkDkPghcwS135l2RIC5ChsSIkNBWT4jWEkexEw6wI2KyKYJue1ZoX+K/8lf1Xj9SiB+o1YeoFDqeqwb4kkK8A5iWbJCeFRreYX/r2/gRYFyyU4jFoY1XoeAP0ivwMbWFPC6ZFCLTmp9aW5gL6AXiuMUYl0xqr3mVQvwNvAVkQPAX/HlcTj8AUogCuwED4HK5GB9sPSUbpAZUtALhBrhIHDDHFLpdXImmzEQI1cwcQMfUxnuAm/L9OjpjFbqKWHxNIb4u1rhyC3X7s7zl1vC+Ybfc7rylbqrFg8ynGzBYFWys/QnQDNyPa35sarulcW2jBGDGHsY1P/Ztt+G9JS08mG2GaT2d+3o6Te3ae1J5xh6mNZ/7tkMS03qiqc029WRm0DCup3PZIDEueSe+pGzhL2CYlnxOIWLMtJYtKkJzQfzE3I1LPm87OC0nUog7kT5031OIbwBSG3fC//UPpo6py7DVTDoAAAAASUVORK5CYII='
    className={className}
  />
);
