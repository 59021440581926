import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isSameDay, parse } from 'date-fns';

import { Button } from '@/components/Button/Button';
import { IAppointmentDetailsForm } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/hooks/useAppointmentDetailsForm';
import { FRONTEND_DATE_FORMAT } from '@/core/constants/dateFormat';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch } from '@/core/redux/hooks';
import { serialAppointmentModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningSerialAppointment/slice';

interface ISerialAppointmentButton {
  form: UseFormReturn<IAppointmentDetailsForm, any, undefined>;
  context: 'fullVersion' | 'modalVersion';
}

const isValidTime = (time?: string | null) => {
  if (!time) {
    return false;
  }

  const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return timeRegex.test(time);
};

export const SerialAppointmentButton: React.FC<ISerialAppointmentButton> = ({ form, context }) => {
  const { t: appointmentDetailTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentDetails',
  });

  const [startDate, startTime, endDate, endTime] = form.watch([
    'startDate',
    'startTime',
    'endDate',
    'endTime',
  ]);

  const dispatch = useAppDispatch();
  const { selectedPersonID } = useSelectedPerson();

  const parsedStartDate = startDate ? parse(startDate, FRONTEND_DATE_FORMAT, new Date()) : null;
  const parsedEndDate = endDate ? parse(endDate, FRONTEND_DATE_FORMAT, new Date()) : null;

  const handleOpenSerialModal = () => {
    if (!startDate || !startTime || !endTime || !selectedPersonID) {
      return;
    }

    dispatch(
      serialAppointmentModalsActions.openSerialAppointmentModal({
        context: context,
        personID: selectedPersonID,
        firstAppointmentDate: startDate,
        appointmentStartTime: startTime,
        appointmentEndTime: endTime,
      })
    );
  };

  return (
    parsedStartDate &&
    parsedEndDate &&
    isSameDay(parsedStartDate, parsedEndDate) && (
      <Button
        onClick={handleOpenSerialModal}
        disabled={!isValidTime(startTime) || !isValidTime(endTime)}
      >
        {appointmentDetailTranslations('buttons.appointmentSeries.label')}
      </Button>
    )
  );
};
