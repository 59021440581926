import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import LoginRoute from '@/pages/auth/LoginRoute';
import PasswordRequestRoute from '@/pages/auth/PasswordRequestRoute';
import PasswordResetRoute from '@/pages/auth/PasswordResetRoute';

const AuthRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route index element={<LoginRoute />} />
      <Route path={'password_request'} element={<PasswordRequestRoute />} />
      <Route path={'password_reset'} element={<PasswordResetRoute />} />
      <Route path={'*'} element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default AuthRootRoute;
