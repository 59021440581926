import { forwardRef, HTMLProps } from 'react';
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react';

import { useDialogContext } from '@/components/Dialog/DialogContext/DialogContext';
import { colorTypes } from '@/styles/types';

export const DialogContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  function DialogContent(props, propRef) {
    const { context: floatingContext, ...context } = useDialogContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!floatingContext.open) return null;

    return (
      <FloatingPortal>
        <FloatingOverlay
          onClick={(e) => e.stopPropagation()}
          className={`fixed inset-0 bg-${colorTypes.Overlay} bg-opacity-50 grid place-items-center z-50`}
          lockScroll
        >
          <FloatingFocusManager context={floatingContext}>
            <div
              ref={ref}
              aria-labelledby={context.labelId}
              aria-describedby={context.descriptionId}
              {...context.getFloatingProps(props)}
              className={`p-6 bg-${colorTypes.White} rounded-default max-w-[40%] shadow-default`}
            >
              {props.children}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      </FloatingPortal>
    );
  }
);
