import { twMerge } from 'tailwind-merge';

import { Typography } from '@/components/Typography/Typography';

import styles from './styles.module.scss';

interface ILogbookSummaryItem {
  id: number;
  createdBy: string;
  createdTimestamp: string;
  note: string;
}

export const LogbookSummaryItem: React.FC<ILogbookSummaryItem> = ({
  id,
  createdBy,
  createdTimestamp,
  note,
}) => {
  return (
    <div key={id} className={twMerge('relative pl-8 group', styles.timeline)}>
      <div className={styles.timeline__vertical}>
        <div className={styles.timelapse__head}>
          <div className={styles.timeline__icon}></div>
        </div>
        <div className={styles.timeline__divider}></div>
      </div>
      <div className={twMerge('flex flex-col sm:flex-row items-start mb-2.5')}>
        <Typography type='16-Sb'>
          {createdTimestamp}, {createdBy}
        </Typography>
      </div>
      <div className='pb-5'>
        <Typography as='p' type='16-R'>
          {note}
        </Typography>
      </div>
    </div>
  );
};
