import React from 'react';

import { IIcon } from '@/types/icon';

export const RadioButtonUncheckedIcon: React.FC<IIcon> = ({ className, onClick }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
    onClick={onClick}
  >
    <path d='M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0z' />
  </svg>
);
