import { useEffect } from 'react';
import fp from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { generalDataSelectors } from '@/core/redux/slices/functions/integrationPlan/generalData/selectors';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';

export function useUpdatedGeneralData(integrationPlanID: number | null, personID?: number | null) {
  const updatedData = useAppSelector(generalDataSelectors.generalDataUpdatedData);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!integrationPlanID || !personID) {
      return;
    }

    if (!fp.isEmpty(updatedData)) {
      dispatch(
        integrationPlanModalsActions.openGeneralDataUpdatedDataModal({
          integrationPlanID: integrationPlanID,
          personID: personID,
          updatedData: updatedData,
        })
      );
    }
  }, [updatedData, integrationPlanID, personID]);
}
