import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMedication } from '@/core/redux/slices/medicationAdministration/medicationAdministrationSlice';

export interface IConfirmMarkPayload {
  medicationID: number;
  isGiven: boolean;
  isOnlyOnDemand: boolean;
}

export interface IConfirmUnmarkPayload {
  medicationID: number;
}

export interface IMedicationAdministrationHistoryPayload {
  medication: IMedication;
}

export interface IMarkModal {
  isRender: boolean;
  payload: IConfirmMarkPayload | null;
}

export interface IUnmarkModal {
  isRender: boolean;
  payload: IConfirmUnmarkPayload | null;
}

export interface IMedicationAdministrationModalState {
  confirmMarkAdministrationModal: IMarkModal;
  confirmUnmarkAdministrationModal: IUnmarkModal;
  medicationAdministrationHistoryModal: any;
}

const initialState: IMedicationAdministrationModalState = {
  confirmMarkAdministrationModal: {
    isRender: false,
    payload: null,
  },
  confirmUnmarkAdministrationModal: {
    isRender: false,
    payload: null,
  },
  medicationAdministrationHistoryModal: {
    isRender: false,
    payload: null,
  },
};

export const medicationAdministrationModalsSlice = createSlice({
  name: 'medicationAdministrationModals',
  initialState,
  reducers: {
    //mark modal
    openConfirmMarknModal: (state, action: PayloadAction<IConfirmMarkPayload>) => ({
      ...state,
      confirmMarkAdministrationModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeConfirmMarknModal: (state) => ({
      ...state,
      confirmMarkAdministrationModal: {
        isRender: false,
        payload: null,
      },
    }),
    //unmark modal
    openConfirmUnmarknModal: (state, action: PayloadAction<IConfirmUnmarkPayload>) => ({
      ...state,
      confirmUnmarkAdministrationModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeConfirmUnmarknModal: (state) => ({
      ...state,
      confirmUnmarkAdministrationModal: {
        isRender: false,
        payload: null,
      },
    }),
    // history modal
    openHistorynModal: (state, action: PayloadAction<IMedicationAdministrationHistoryPayload>) => ({
      ...state,
      medicationAdministrationHistoryModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeHistorynModal: (state) => ({
      ...state,
      medicationAdministrationHistoryModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const medicationAdministrationModalsReducer = medicationAdministrationModalsSlice.reducer;
export const medicationAdministrationModalsActions = medicationAdministrationModalsSlice.actions;
