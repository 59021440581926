import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { QualificationObjectivesItem } from '@/components/forms/QualificationDocumentation/QualificationObjectives/QualificationObjectivesItem/QualificationObjectivesItem';
import { TableHeader } from '@/components/forms/QualificationDocumentation/QualificationObjectives/TableHeader/TableHeader';
import { useIntegrationPlanLock } from '@/components/functions/IntegrationPlan/hooks/useIntegrationPlanLock';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { IQualificationDocumentationItem } from '@/core/redux/slices/functions/integrationPlan/qualificationDocumentation/qualificationDocumentationSlice';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';

interface IQualificationObjectives {
  qualificationsDocumentations?: Record<string, IQualificationDocumentationItem>;
  integrationPlanID?: number | null;
  personID?: number | null;
}

export const QualificationObjectives: React.FC<IQualificationObjectives> = ({
  qualificationsDocumentations,
  integrationPlanID,
  personID,
}) => {
  const { t: qualificationObjectivesTranslations } = useTranslation('qualificationDocumentation', {
    keyPrefix: 'qualificationObjectives',
  });

  const isLocked = useIntegrationPlanLock();

  const dispatch = useAppDispatch();

  let qualificationsKeys: string[] = [];

  if (qualificationsDocumentations) {
    qualificationsKeys = Object.keys(qualificationsDocumentations);
  }
  const handleOpenModal = (event: React.MouseEvent) => {
    event.preventDefault();
    if (integrationPlanID && personID) {
      dispatch(
        integrationPlanModalsActions.openQualificationObjectivesModal({
          integrationPlanID,
          personID,
        })
      );
    }
  };

  return (
    <div className={'flex flex-col gap-4'}>
      <Typography type={'H3'}>{qualificationObjectivesTranslations('title.label')}</Typography>
      <div>
        <TableHeader />
        <div className={'flex flex-col'}>
          {qualificationsDocumentations &&
            qualificationsKeys.map((qualificationKey) => (
              <QualificationObjectivesItem
                objectiveName={
                  qualificationsDocumentations[qualificationKey].qualificationObjectiveName
                }
                objectiveID={qualificationKey}
                key={qualificationKey}
              />
            ))}
        </div>
      </div>
      <div className={'flex flex-row gap-3 self-end'}>
        <Button type={'button'} onClick={handleOpenModal} disabled={isLocked}>
          {qualificationObjectivesTranslations('buttons.manageQualificationGoal.label')}
        </Button>
      </div>
    </div>
  );
};
