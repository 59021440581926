import { Typography } from '@/components/Typography/Typography';
import { generateName } from '@/core/utils/commonUtils';
import { IPerson } from '@/types/activitiesWidget';

export const PersonItem: React.FC<IPerson> = (person) => {
  return (
    <Typography type={'16-R'} color={'Black'}>
      {generateName(person)}
    </Typography>
  );
};
