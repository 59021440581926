import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { ISerialAppointmentForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/hooks/useSerialAppointmentForm';
import { Typography } from '@/components/Typography/Typography';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { SerialAppointmentWeekNumberTypes } from '@/core/enums/functions/activityPlanning/serialAppointmentWeekNumberTypesEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';
import { serialAppointmentModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningSerialAppointment/selectors';

export interface ISerialAppointmentMonthlyForm {
  monthsInterval?: number | null;
  daysInterval?: number | null;
  weekNumber?: SerialAppointmentWeekNumberTypes | null;
  weekDayID?: number | null;
  isRegularDayOfMonth: boolean;
}

export const SerialAppointmentMonthlyForm: React.FC = () => {
  const { t: serialAppointmentTranslations } = useTranslation('serialAppointmentModal', {
    keyPrefix: 'cycle.forms.monthly',
  });

  const { weekNumbers } = useAppSelector(serialAppointmentModalsSelectors.serialAppointmentModal);
  const dropDowns = useAppSelector(dropdownItemsSelectors.dropdownItems);

  const { watch } = useFormContext<ISerialAppointmentForm>();

  const isRegularDayOfMonth = watch('monthly.isRegularDayOfMonth');

  const radioButtonOptions: IFormRadioButtonGroupItem[] = [
    {
      id: 'monthly.isRegularDayOfMonth.false',
      value: false,
      component: (
        <label htmlFor={'monthly.isRegularDayOfMonth.false'}>
          <Typography>{serialAppointmentTranslations('fields.on.label')}</Typography>
        </label>
      ),
    },
    {
      id: 'monthly.isRegularDayOfMonth.true',
      value: true,
      component: (
        <label htmlFor={'monthly.isRegularDayOfMonth.true'}>
          <Typography>{serialAppointmentTranslations('fields.on.label')}</Typography>
        </label>
      ),
    },
  ];

  const weekNumberSelectOptions = weekNumbers.map((weekNumber) => ({
    id: weekNumber.value,
    name: weekNumber.value,
  }));

  const daySelectOptions = dropDowns?.[DropdownItemsByFetch.WEEK_DAY]
    ? dropDowns[DropdownItemsByFetch.WEEK_DAY].map((item) => ({
        id: item.id,
        name: item.value,
      }))
    : [];

  const serialAppointmentMonthlyForm: (
    | FormItem<ISerialAppointmentForm>
    | FormGroup<ISerialAppointmentForm>
  )[] = [
    {
      type: 'radio-button-group',
      name: 'monthly.isRegularDayOfMonth',
      radioButtonGroupOptions: {
        options: radioButtonOptions,
        containerClassName: 'flex flex-row gap-3',
      },
    },
    {
      condition: isRegularDayOfMonth,
      fieldsContainerClassName: 'flex flex-row gap-3',
      fields: [
        {
          name: 'monthly.daysInterval',
          type: 'input',
          label: serialAppointmentTranslations('fields.everyday.label'),
        },
        {
          name: 'monthly.monthsInterval',
          type: 'input',
          label: serialAppointmentTranslations('fields.monthly.label'),
        },
      ],
    },
    {
      condition: !isRegularDayOfMonth,
      fieldsContainerClassName: 'grid grid-cols-2 gap-3',
      fields: [
        {
          name: 'monthly.weekNumber',
          type: 'select',
          options: weekNumberSelectOptions,
        },
        {
          name: 'monthly.weekDayID',
          type: 'select',
          options: daySelectOptions,
        },
        {
          name: 'monthly.undefined',
          type: 'custom',
          customComponent: (
            <Typography>{serialAppointmentTranslations('fields.everyone.label')}</Typography>
          ),
        },
        {
          name: 'monthly.monthsInterval',
          type: 'input',
          label: serialAppointmentTranslations('fields.month.label'),
        },
      ],
    },
  ];

  return <FormRender list={serialAppointmentMonthlyForm} />;
};
