import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { DevelopmentReportActionsBar } from '@/components/functions/DevelopmentReport/DevelopmentReportActionsBar/DevelopmentReportActionsBar';
import {
  goalsAndActivityDefaultValues,
  IDevelopmentReportGoalAndActivity,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/GoalsAndActivity/helper/goalsAndActivityForm';
import { useDevelopmentReport } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReport';
import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { developmentReportGoalsAndActivitySelectors } from '@/core/redux/slices/functions/developmentReport/developmentReportGoalsAndActivitySlice/selectors';
import { developmentReportGoalsAndActivityActions } from '@/core/redux/slices/functions/developmentReport/developmentReportGoalsAndActivitySlice/slice';
import { LoadingStatus } from '@/types/loadingStatus';

function mapGoalAndActivityToFormItem(
  goalAndActivity: IDevelopmentReportGoalAndActivity,
  index: number
): FormItem<IDevelopmentReportForm> | FormGroup<IDevelopmentReportForm> {
  return {
    fieldsContainerClassName: 'grid grid-cols-2 gap-5',
    fields: [
      {
        fieldsContainerClassName: 'flex flex-col gap-2',
        fields: [
          {
            name: `${DevelopmentReportForms.GoalsAndActivityPlaning}.goalsAndActivities.${index}.goal`,
            type: 'input',
            label: 'objective.label',
            labelParams: { objectiveNum: goalAndActivity.lfdNr },
          },
          {
            name: `${DevelopmentReportForms.GoalsAndActivityPlaning}.goalsAndActivities.${index}.requiredActions`,
            type: 'textarea',
            label: 'taskDetails.label',
          },
        ],
      },
      {
        fieldsContainerClassName: 'flex flex-col gap-2',
        fields: [
          {
            name: `${DevelopmentReportForms.GoalsAndActivityPlaning}.goalsAndActivities.${index}.until`,
            type: 'input',
            label: 'deadline.label',
          },
          {
            name: `${DevelopmentReportForms.GoalsAndActivityPlaning}.goalsAndActivities.${index}.executor`,
            type: 'textarea',
            label: 'responsible.label',
          },
          {
            name: `${DevelopmentReportForms.GoalsAndActivityPlaning}.goalsAndActivities.${index}.executionPlace`,
            type: 'textarea',
            label: 'location.label',
          },
        ],
      },
    ],
  };
}

export const DevelopmentReportGoalsAndActivity: React.FC = () => {
  const { t: reviewSupportTranslations } = useTranslation('developmentReportGoalsAndActivity');
  const [formRenderState, setFormRenderState] = useState<
    (FormItem<IDevelopmentReportForm> | FormGroup<IDevelopmentReportForm>)[]
  >([]);

  const { developmentReportID } = useDevelopmentReport();
  const { selectedPersonID } = useSelectedPerson();

  const {
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useFormContext<IDevelopmentReportForm>();
  const dispatch = useAppDispatch();

  const developmentReportGoalsAndActivity = useAppSelector(
    developmentReportGoalsAndActivitySelectors.developmentReportGoalsAndActivity
  );
  const developmentReportGoalsAndActivityLock = useAppSelector(
    developmentReportGoalsAndActivitySelectors.developmentReportGoalsAndActivityLock
  );
  const importedDevelopmentReportGoalsAndActivity = useAppSelector(
    developmentReportGoalsAndActivitySelectors.importedDevelopmentReportGoalsAndActivity
  );
  const importedDevelopmentReportGoalsAndActivityLock = useAppSelector(
    developmentReportGoalsAndActivitySelectors.importedDevelopmentReportGoalsAndActivityLock
  );

  useEffect(() => {
    if (!developmentReportID) {
      return;
    }

    dispatch(
      developmentReportGoalsAndActivityActions.fetchDevelopmentReportGoalsAndActivity({
        developmentReportID: developmentReportID,
      })
    );

    return () => {
      dispatch(
        developmentReportGoalsAndActivityActions.setDevelopmentReportGoalsAndActivity({
          goalsAndActivities: [],
        })
      );

      const currentValues = fp.cloneDeep(watch());
      reset({
        ...currentValues,
        [DevelopmentReportForms.GoalsAndActivityPlaning]: goalsAndActivityDefaultValues,
      });
    };
  }, [developmentReportID]);

  useEffect(() => {
    if (!developmentReportGoalsAndActivity) {
      return;
    }

    const newFormState = developmentReportGoalsAndActivity.goalsAndActivities.map(
      mapGoalAndActivityToFormItem
    );
    setFormRenderState(newFormState);
  }, [developmentReportGoalsAndActivity]);

  useEffect(() => {
    if (!importedDevelopmentReportGoalsAndActivity) {
      return;
    }

    setValue(
      DevelopmentReportForms.GoalsAndActivityPlaning,
      importedDevelopmentReportGoalsAndActivity
    );

    const newFormState = importedDevelopmentReportGoalsAndActivity.goalsAndActivities.map(
      mapGoalAndActivityToFormItem
    );
    setFormRenderState(newFormState);

    dispatch(
      developmentReportGoalsAndActivityActions.setImportedDevelopmentReportGoalsAndActivity(null)
    );
  }, [importedDevelopmentReportGoalsAndActivity]);

  const handleImport = () => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      developmentReportGoalsAndActivityActions.fetchImportedDevelopmentReportGoalsAndActivity({
        personID: selectedPersonID,
      })
    );
  };

  return (
    <Skeleton trigger={developmentReportGoalsAndActivityLock === LoadingStatus.LOADING}>
      <FormRender<IDevelopmentReportForm>
        list={formRenderState}
        translationContext={reviewSupportTranslations}
        className={'py-4 w-full flex flex-col gap-2'}
      />
      <DevelopmentReportActionsBar
        importOptions={{
          handleImport,
          loadingStatus: importedDevelopmentReportGoalsAndActivityLock,
        }}
        errorsOptions={{
          errors: errors,
          formKey: DevelopmentReportForms.GoalsAndActivityPlaning,
        }}
        resetOptions={{
          onReset: reset,
          isDirty: isDirty,
        }}
      />
    </Skeleton>
  );
};
