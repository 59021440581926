import React, { useState } from 'react';

import { useWorkingTime } from '@/components/WorkingTimePlanner/hooks/useWorkingTime';
import { WorkingTimePlannerContainer } from '@/components/WorkingTimePlanner/WorkingTimePlannerContainer/WorkingTimePlannerContainer';
import { WorkingTimePlannerHeader } from '@/components/WorkingTimePlanner/WorkingTimePlannerHeader/WorkingTimePlannerHeader';
import {
  IWorkingTime,
  IWorkingTimeDayItem,
} from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IWorkingTimePlanner {
  workingTimeDaysList: IWorkingTimeDayItem[] | null;
  context?: 'function' | 'modal';
  className?: string;
}

export const WorkingTimePlanner: React.FC<IWorkingTimePlanner> = ({
  className,
  context = 'function',
}) => {
  const [currentFormState, setCurrentFormState] = useState<IWorkingTime | null>(null);

  useWorkingTime(currentFormState, setCurrentFormState);

  return (
    <div
      className={twMerge(
        `flex flex-col gap-5 border border-${colorTypes.Border} rounded-default p-4`,
        className
      )}
    >
      <WorkingTimePlannerHeader context={context} />
      <WorkingTimePlannerContainer context={context} />
    </div>
  );
};
