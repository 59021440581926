import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ISerialAppointmentForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/hooks/useSerialAppointmentForm';
import { SerialAppointmentDailyForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentPeriodForms/serialAppointmentDailyForm';
import { SerialAppointmentMonthlyForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentPeriodForms/serialAppointmentMonthlyForm';
import { SerialAppointmentWeeklyForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentPeriodForms/serialAppointmentWeeklyForm';
import { SerialAppointmentYearlyForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentPeriodForms/serialAppointmentYearlyForm';

export const SerialAppointmentPeriodForms: React.FC = () => {
  const { watch } = useFormContext<ISerialAppointmentForm>();

  const selectedPeriod = watch('serialTypeID');

  const serialAppointmentForms: Record<number, React.ReactNode> = {
    1: <SerialAppointmentDailyForm />,
    2: <SerialAppointmentWeeklyForm />,
    3: <SerialAppointmentMonthlyForm />,
    4: <SerialAppointmentYearlyForm />,
  };

  return <div>{serialAppointmentForms[selectedPeriod || 1]}</div>;
};
