import { useEffect } from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { isAfter, parse } from 'date-fns';

import { FRONTEND_DATE_FORMAT } from '@/core/constants/dateFormat';

export function useDateFormSwitch<T extends FieldValues>(
  form: UseFormReturn<T>,
  fromTimestampFieldName: Path<T>,
  toTimestampFieldName: Path<T>
) {
  const { watch, setValue } = form;

  const fromTimeString = watch(fromTimestampFieldName);
  const toTimeString = watch(toTimestampFieldName);

  useEffect(() => {
    if (!fromTimeString || !toTimeString) {
      return;
    }

    const fromDate = parse(fromTimeString, FRONTEND_DATE_FORMAT, new Date());
    const toDate = parse(toTimeString, FRONTEND_DATE_FORMAT, new Date());

    if (isAfter(fromDate, toDate)) {
      setValue(fromTimestampFieldName, toTimeString as any);
      setValue(toTimestampFieldName, fromTimeString as any);
    }
  }, [fromTimeString, toTimeString]);
}
