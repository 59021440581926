import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDraggable } from '@dnd-kit/core';

import { AdministrationLocationManagementLocationItemOverlay } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementLocationList/AdministrationLocationManagementLocationItemOverlay';
import { IAdministrationLocationManagementForm } from '@/components/administartionFunctions/locationManagement/hooks/useAdministrationLocationManagementForm';
import { Typography } from '@/components/Typography/Typography';
import { IAdministrationLocation } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

export interface IAdministrationLocationManagementLocationItem {
  layer: number;
  location: IAdministrationLocation;
  className?: string;
  openConfirmDeleteDialog?: () => void;
  openBlockDeleteDialog?: () => void;
  openRenameDialog?: () => void;
}

export const AdministrationLocationManagementLocationItem: React.FC<IAdministrationLocationManagementLocationItem> =
  ({
    location,
    layer,
    className,
    openBlockDeleteDialog,
    openConfirmDeleteDialog,
    openRenameDialog,
  }) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const { watch, setValue } = useFormContext<IAdministrationLocationManagementForm>();

    const selectedLocation = watch('selectedLocation');

    const isSelected = selectedLocation?.id === location.id;

    const handleSelect = () => {
      setValue('selectedLocation', isSelected ? null : location);
      setValue('departments', []);
    };

    const { setNodeRef, isDragging, over } = useDraggable({ id: location.id });

    return (
      <div
        className={twMerge(
          `relative flex flex-col 
         border-b border-b-${colorTypes.StrokeLight} bg-${colorTypes.White} 
         gap-1 pr-[30px] py-[10px]`,
          isSelected && `bg-${colorTypes.Blue}`,
          isDragging && 'opacity-50',
          className
        )}
        style={{ paddingLeft: `${30 + layer * 34}px` }}
        onClick={handleSelect}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={setNodeRef}
      >
        <Typography type={'16-R'} color={isSelected ? 'White' : 'Primary'}>
          {location.name}
        </Typography>
        <Typography
          type={'14-R'}
          color={isSelected ? 'White' : 'Secondary'}
        >{`${location.departmentsCount} Abteilungen`}</Typography>
        <AdministrationLocationManagementLocationItemOverlay
          isActive={isHovered}
          isSelected={isSelected}
          location={location}
          setIsHovered={setIsHovered}
          openBlockDeleteDialog={openBlockDeleteDialog}
          openConfirmDeleteDialog={openConfirmDeleteDialog}
          openRenameDialog={openRenameDialog}
        />
      </div>
    );
  };
