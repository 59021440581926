import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IDevelopmentReportVersionHistoryModalPayload {
  personID: number;
}

export interface IDevelopmentReportVersionHistoryModalFetchPayload {
  personID: number;
}

export interface IDevelopmentReportVersionHistoryItem {
  id: number;
  printedTimestamp?: string | null;
}

export interface IDevelopmentReportVersionHistoryModalState {
  isOpened: boolean;
  payload: IDevelopmentReportVersionHistoryModalPayload | null;
  fetchPayload: IDevelopmentReportVersionHistoryModalFetchPayload | null;
  versions: IDevelopmentReportVersionHistoryItem[];
  versionsLock: LoadingStatus;
}

const initialState: IDevelopmentReportVersionHistoryModalState = {
  isOpened: false,
  payload: null,
  fetchPayload: null,
  versions: [],
  versionsLock: LoadingStatus.NEVER,
};

export const developmentReportVersionHistoryModalSlice = createSlice({
  name: 'developmentReportVersionHistoryModal',
  initialState,
  reducers: {
    openDevelopmentReportVersionHistory: (
      state,
      action: PayloadAction<IDevelopmentReportVersionHistoryModalPayload>
    ) => ({
      ...state,
      isOpened: true,
      payload: action.payload,
    }),
    closeDevelopmentReportVersionHistory: (state) => ({
      ...state,
      isOpened: false,
      fetchPayload: null,
      payload: null,
      versions: [],
      versionsLock: LoadingStatus.NEVER,
    }),
    fetchDevelopmentReportVersionHistory: (
      state,
      action: PayloadAction<IDevelopmentReportVersionHistoryModalFetchPayload>
    ) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setDevelopmentReportVersionHistory: (
      state,
      action: PayloadAction<IDevelopmentReportVersionHistoryItem[]>
    ) => ({
      ...state,
      versions: action.payload,
    }),
    setDevelopmentReportVersionHistoryLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      versionsLock: action.payload,
    }),
  },
});

export const developmentReportVersionHistoryModalReducer =
  developmentReportVersionHistoryModalSlice.reducer;
export const developmentReportVersionHistoryModalActions =
  developmentReportVersionHistoryModalSlice.actions;
