import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Typography } from '@/components/Typography/Typography';
import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { Person } from '@/components/views/person/PersonPage/PersonView/Person/Person';
import { ErrorPlaceholder } from '@/components/views/person/PersonPage/PersonView/PersonList/ErrorPlaceholder/ErrorPlaceholder';
import { useAnySelectedPersons } from '@/components/views/person/PersonPage/PersonView/PersonList/hooks/useAnySelectedPersons';
import { useFetchedPersons } from '@/components/views/person/PersonPage/PersonView/PersonList/hooks/useFetchedPersons';
import { Placeholder } from '@/components/views/person/PersonPage/PersonView/PersonList/Placeholder/Placeholder';
import { FUNCTIONS_URL } from '@/core/constants/urls';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { commonModalsActions } from '@/core/redux/slices/modalsSlice/common/commonModalsSlice';
import { commonModalsSelectors } from '@/core/redux/slices/modalsSlice/common/selector';
import { personPageSelectors } from '@/core/redux/slices/personPage/personPageSelectors';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import styles from './styles.module.scss';

export const PersonList: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui' });

  const status = useAppSelector(personPageSelectors.personsStatus);
  const modalContext = useAppSelector(commonModalsSelectors.addPersonModal);

  const [, setFilteredPerson] = usePersonPageStoreSelector((store) => store.filteredPersons);

  const dispatch = useAppDispatch();

  const { selectedPersonList, nonSelectedPersonList, personList } = useFetchedPersons();
  const isAnySelected = useAnySelectedPersons();

  useEffect(() => {
    if (!personList) {
      return;
    }

    setFilteredPerson({ filteredPersons: personList });
  }, [personList]);

  const navigate = useNavigate();

  const renderSelectedPersonList = useCallback(() => {
    if (!personList) {
      return null;
    }
    return selectedPersonList.map((person) => (
      <Person
        key={person.id}
        name={person.name}
        surname={person.surname}
        id={person.id}
        last_modified={person.last_modified}
        functions={person.functions}
      />
    ));
  }, [selectedPersonList]);

  const renderNonSelectedPersonList = useCallback(() => {
    if (!personList) {
      return null;
    }
    return nonSelectedPersonList.map((person) => (
      <Person
        key={person.id}
        name={person.name}
        surname={person.surname}
        id={person.id}
        last_modified={person.last_modified}
        functions={person.functions}
      />
    ));
  }, [nonSelectedPersonList]);

  const handleToFunctionClick = () => {
    if (modalContext) {
      dispatch(commonModalsActions.closeAddPersonModal());
    }
    navigate(FUNCTIONS_URL);
  };

  return (
    <>
      {status === LoadingStatus.LOADING ? (
        <div className={clsx(styles.placeHolder, 'flex flex-col items-center justify-center')}>
          <SpinnerIcon className={`text-${colorTypes.Blue}`} />
          <Typography type='16-R' color='Secondary'>
            {t('label.personListLoading')}
          </Typography>
        </div>
      ) : status === LoadingStatus.LOADED && personList ? (
        <>
          <div
            className={twMerge(
              'shrink-0  overflow-y-auto',
              nonSelectedPersonList.length === 0 ? '' : 'max-h-[calc(100vh_-_600px)]'
            )}
          >
            {renderSelectedPersonList()}
          </div>
          <div className={'flex-grow overflow-y-auto'}>{renderNonSelectedPersonList()}</div>
          <div className={styles.buttonContainer}>
            <Button
              className={clsx(styles.functionButton, 'justify-center')}
              disabled={!isAnySelected}
              onClick={handleToFunctionClick}
            >
              {isAnySelected ? t('button.chooseFunction') : t('button.choosePerson')}
            </Button>
          </div>
        </>
      ) : status === LoadingStatus.LOADED_EMPTY && personList && personList.length === 0 ? (
        <Placeholder />
      ) : status === LoadingStatus.NEVER ? (
        <Placeholder />
      ) : status === LoadingStatus.ERROR ? (
        <ErrorPlaceholder />
      ) : (
        <Placeholder />
      )}
      <div />
    </>
  );
};
