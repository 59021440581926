import { PayloadAction } from '@reduxjs/toolkit';
import { type NormalizeOAS, type OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  developmentReportVersionHistoryModalActions,
  IDevelopmentReportVersionHistoryItem,
  IDevelopmentReportVersionHistoryModalFetchPayload,
} from '@/core/redux/slices/modalsSlice/functions/developmentReport/developmentReportVersionHistory/slice';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type FetchDevelopmentReportVersionHistoryRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/history',
  'get'
>;


type DevelopmentReportVersionHistoryResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/development_report/history',
  'get',
  '200'
>;

function* fetchDevelopmentReportVersionHistory(
  action: PayloadAction<IDevelopmentReportVersionHistoryModalFetchPayload>
): Generator<any, void, DevelopmentReportVersionHistoryResponse > {
  const { personID } = action.payload;

  try {
    yield put(
      developmentReportVersionHistoryModalActions.setDevelopmentReportVersionHistoryLock(
        LoadingStatus.LOADING
      )
    );

    const request: FetchDevelopmentReportVersionHistoryRequest = {
      query: {
        person_id: personID
      },
        ...authAdd()
    }

    const response = yield call(restCall, '/development_report/history', 'get',request)

    const mappedResponse = response.history.map<IDevelopmentReportVersionHistoryItem>((item) => ({
      id: item.id,
      printedTimestamp: item.printed_timestamp
    }))

    yield put(developmentReportVersionHistoryModalActions.setDevelopmentReportVersionHistory(mappedResponse.reverse()));
    yield put(
      developmentReportVersionHistoryModalActions.setDevelopmentReportVersionHistoryLock(
        LoadingStatus.LOADED
      )
    );
  } catch (error) {
    console.log('Error on development report version history fetching', error);
    yield put(
      developmentReportVersionHistoryModalActions.setDevelopmentReportVersionHistoryLock(
        LoadingStatus.ERROR
      )
    );
  }
}

export const developmentReportVersionHistorySagas = [
  takeLatest(
    developmentReportVersionHistoryModalActions.fetchDevelopmentReportVersionHistory,
    fetchDevelopmentReportVersionHistory
  ),
];
