import React, { LegacyRef, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { isArray } from 'lodash';
import RcTree, { TreeNode } from 'rc-tree';
import { DataNode, Key } from 'rc-tree/lib/interface';
import Tree, { CheckInfo } from 'rc-tree/lib/Tree';
import { twMerge } from 'tailwind-merge';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { ArrowIcon } from '@/components/Icons/ArrowIcon';
import { Typography } from '@/components/Typography/Typography';
import { IDepartment } from '@/types/department';

import { usePersonPageStoreSelector } from '../../../Context/PersonPageStoreContext';

import { getAllIds } from './utils';

import './motion.scss';
import styles from './styles.module.scss';

export type ICheckedState = { checked: Key[]; halfChecked: Key[] };

interface IDepartmentsTreeSelect {
  departments: IDepartment[];
  defaultSelected: ICheckedState | undefined;
  onSelectedChange: (selected: ICheckedState) => void;
}

export const DepartmentsTreeSelect: React.FC<IDepartmentsTreeSelect> = ({
  departments,
  defaultSelected,
  onSelectedChange,
}) => {
  const [isExpandedAll] = usePersonPageStoreSelector((store) => store.allDepartmentsListExpanded);
  const [isAllSelected, setIsAllSelected] = usePersonPageStoreSelector(
    (store) => store.allDepartmentsSelected
  );
  const [isSetAllDepartments] = usePersonPageStoreSelector((store) => store.setAllDepartments);

  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [halfCheckedKeys, setHalfCheckedKeys] = useState<Key[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  const departmentsKeys = useMemo(() => getAllIds(departments), [departments]);

  const treeRef = useRef<Tree<DataNode> | null>(null);

  const onExpand = (key: Key) => {
    setExpandedKeys((prev) => {
      const newExpandedKeys = [...prev];
      if (newExpandedKeys.includes(key)) {
        newExpandedKeys.splice(newExpandedKeys.indexOf(key), 1);
      } else {
        newExpandedKeys.push(key);
      }
      return newExpandedKeys;
    });
  };

  const handleCheck = (
    checked: Key[] | { checked: Key[]; halfChecked: Key[] },
    info: CheckInfo<DataNode>
  ) => {
    if (!isArray(checked)) {
      return;
    }

    setCheckedKeys(checked);
    setHalfCheckedKeys(info.halfCheckedKeys || []);

    onSelectedChange({
      checked: checked,
      halfChecked: info.halfCheckedKeys || [],
    });

    if (checked.length === departmentsKeys.length) {
      setIsAllSelected({
        allDepartmentsSelected: true,
      });
    } else {
      setIsAllSelected({
        allDepartmentsSelected: false,
      });
    }
  };

  useEffect(() => {
    if (!defaultSelected || !defaultSelected.checked) {
      return;
    }
    
    setCheckedKeys(defaultSelected.checked);
    setHalfCheckedKeys(defaultSelected.halfChecked);

    if (defaultSelected.checked.length === departmentsKeys.length) {
      setIsAllSelected({
        allDepartmentsSelected: true,
      });
    }
  }, [defaultSelected]);

  useEffect(() => {
    if (isExpandedAll) {
      setExpandedKeys(departmentsKeys);
    } else {
      setExpandedKeys([]);
    }
  }, [isExpandedAll]);

  useEffect(() => {
    if (isSetAllDepartments === undefined) {
      return;
    }

    if (isAllSelected) {
      setCheckedKeys(departmentsKeys);

      setHalfCheckedKeys([]);

      onSelectedChange({
        checked: departmentsKeys,
        halfChecked: [],
      });
    } else {
      setCheckedKeys([]);
      onSelectedChange({
        checked: [],
        halfChecked: [],
      });
      setHalfCheckedKeys([]);
    }
  }, [isSetAllDepartments]);

  const rcMotion = {
    motionName: 'node-motion',
    motionAppear: true,
    onAppearStart: () => ({ height: 0 }),
    onAppearActive: (node: HTMLElement) => ({ height: node.scrollHeight }),
    onLeaveStart: (node: HTMLElement) => ({ height: node.offsetHeight }),
    onLeaveActive: () => ({ height: 0 }),
  };

  const renderTreeNodes = (data: IDepartment[], level = 1): React.ReactNode => {
    return data.map((item) => {
      const isExpandable = item.departments || item.childGroups;

      return (
        <TreeNode
          title={(props) => {
            const isChecked = checkedKeys.includes(props.key);
            const isIntermediate = halfCheckedKeys.includes(props.key);
            const isExpanded = expandedKeys.includes(props.key);

            return (
              <div
                className={twMerge(
                  'flex flex-row items-center py-2 overflow-hidden cursor-pointer',
                  styles.container,
                  (isChecked || isIntermediate) && styles.checked
                )}
                style={{ paddingLeft: level * 32 + 'px' }}
              >
                <CheckboxUI
                  name={String(props.key)}
                  checked={isChecked}
                  intermediate={isIntermediate}
                  className='mr-2'
                  isPreventClick
                />
                {isExpandable ? (
                  <Typography type={level === 1 ? 'H3' : '16-Sb'} color='Primary'>
                    {item.name}
                  </Typography>
                ) : (
                  <Typography type='16-R' color='Primary'>
                    {item.name}
                  </Typography>
                )}

                <div className='flex-grow h-5' onClick={(e) => e.stopPropagation()}>
                  {isExpandable && (
                    <motion.div
                      key={'arrow-' + item.id}
                      className={twMerge(styles.arrowButton, 'ms-2')}
                      onClick={() => {
                        onExpand(props.key);
                      }}
                      style={
                        isExpanded ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }
                      }
                    >
                      <ArrowIcon />
                    </motion.div>
                  )}
                </div>
              </div>
            );
          }}
          key={isExpandable ? item.id : `location_${item.id}`}
        >
          <>
            {item.departments && renderTreeNodes(item.departments, level + 1)}
            {item.childGroups && renderTreeNodes(item.childGroups, level + 1)}
          </>
        </TreeNode>
      );
    });
  };

  return (
    <>
      {departments && (
        <RcTree
          ref={treeRef}
          selectable={false}
          checkable={true}
          showIcon={false}
          expandedKeys={expandedKeys}
          checkedKeys={checkedKeys}
          onCheck={handleCheck}
          motion={rcMotion}
          prefixCls='tree'
        >
          {renderTreeNodes(departments)}
        </RcTree>
      )}
    </>
  );
};
