import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import {
  IAppliedTestProcedure,
  IInternalDifferentiation,
} from '@/core/redux/slices/functions/testingResult/testingResultSlice';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IModal {
  isRender: boolean;
}

export interface IDeleteAppliedTestProcedureModal extends IModal {
  procedureID: number | null;
  deleteAppliedTestProcedureLock: LoadingStatus;
}

export interface IAppliedTestProcedureUpdatePayload {
  appliedTestProcedure: Omit<IAppliedTestProcedure, 'id'>;
  procedureID: number;
}

export interface IUpdateAppliedTestProcedureModal extends IModal {
  procedureID: number | null;
  updateAppliedTestProcedureLock: LoadingStatus;
  payload: IAppliedTestProcedureUpdatePayload | null;
}

export interface IAppliedTestProcedureCreatePayload {
  personID: number;
  appliedTestProcedure: Omit<IAppliedTestProcedure, 'id'>;
}

export interface ICreateAppliedTestProcedureModal extends IModal {
  payload: IAppliedTestProcedureCreatePayload | null;
  createAppliedTestProcedureLock: LoadingStatus;
}

export interface IAppliedTestProcedureTestPerformers {
  testPerformerList: Array<{ id: number; value?: string | null }>;
  testPerformerFetchPayload: DropdownItemsByFetch | null;
  testPerformerListLock: LoadingStatus;
}

export interface IAppliedTestProcedureTestProcedures {
  testProcedureList: Array<{ id: number; value?: string | null }>;
  testProcedureFetchPayload: DropdownItemsByFetch | null;
  testProcedureListLock: LoadingStatus;
}

export interface IImportInternalDifferentiationModalPayload {
  personID: number | null;
}

export interface IImportInternalDifferentiationModal extends IModal {
  payload: IImportInternalDifferentiationModalPayload | null;
  importInternalDifferentiation: IInternalDifferentiation | null;
  importInternalDifferentiationLock: LoadingStatus;
}

export interface ITestingResultModalState {
  deleteAppliedTestProcedureModal: IDeleteAppliedTestProcedureModal;
  updateAppliedTestProcedureModal: IUpdateAppliedTestProcedureModal;
  createAppliedTestProcedureModal: ICreateAppliedTestProcedureModal;
  importInternalDifferentiationModal: IImportInternalDifferentiationModal;
  testPerformers: IAppliedTestProcedureTestPerformers;
  testProcedures: IAppliedTestProcedureTestProcedures;
}

const initialState: ITestingResultModalState = {
  deleteAppliedTestProcedureModal: {
    isRender: false,
    procedureID: null,
    deleteAppliedTestProcedureLock: LoadingStatus.NEVER,
  },
  updateAppliedTestProcedureModal: {
    isRender: false,
    payload: null,
    procedureID: null,
    updateAppliedTestProcedureLock: LoadingStatus.NEVER,
  },
  createAppliedTestProcedureModal: {
    isRender: false,
    payload: null,
    createAppliedTestProcedureLock: LoadingStatus.NEVER,
  },
  testProcedures: {
    testProcedureList: [],
    testProcedureFetchPayload: null,
    testProcedureListLock: LoadingStatus.NEVER,
  },
  testPerformers: {
    testPerformerList: [],
    testPerformerFetchPayload: null,
    testPerformerListLock: LoadingStatus.NEVER,
  },
  importInternalDifferentiationModal: {
    isRender: false,
    payload: null,
    importInternalDifferentiation: null,
    importInternalDifferentiationLock: LoadingStatus.NEVER,
  },
};

const testingResultModalSlice = createSlice({
  name: 'testingResultModal',
  initialState,
  reducers: {
    //Delete applied test procedure
    openDeleteAppliedTestProcedureModal: (state, action: PayloadAction<number>) => ({
      ...state,
      deleteAppliedTestProcedureModal: {
        isRender: true,
        procedureID: action.payload,
        deleteAppliedTestProcedureLock: LoadingStatus.NEVER,
      },
    }),
    closeDeleteAppliedTestProcedureModal: (state) => ({
      ...state,
      deleteAppliedTestProcedureModal: {
        isRender: false,
        procedureID: null,
        deleteAppliedTestProcedureLock: LoadingStatus.NEVER,
      },
    }),
    deleteAppliedTestProcedure: (state, action: PayloadAction<number>) => ({
      ...state,
      deleteAppliedTestProcedureModal: {
        ...state.deleteAppliedTestProcedureModal,
        payload: action.payload,
      },
    }),
    setDeleteAppliedTestProcedureLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      deleteAppliedTestProcedureModal: {
        ...state.deleteAppliedTestProcedureModal,
        deleteAppliedTestProcedureLock: action.payload,
      },
    }),

    //Update applied test procedure
    openUpdateAppliedTestProcedureModal: (state, action: PayloadAction<number>) => ({
      ...state,
      updateAppliedTestProcedureModal: {
        isRender: true,
        payload: null,
        updateAppliedTestProcedureLock: LoadingStatus.NEVER,
        procedureID: action.payload,
      },
    }),
    closeUpdateAppliedTestProcedureModal: (state) => ({
      ...state,
      updateAppliedTestProcedureModal: {
        isRender: false,
        payload: null,
        updateAppliedTestProcedureLock: LoadingStatus.NEVER,
        procedureID: null,
      },
    }),
    updateAppliedTestProcedure: (
      state,
      action: PayloadAction<IAppliedTestProcedureUpdatePayload>
    ) => ({
      ...state,
      updateAppliedTestProcedureModal: {
        ...state.updateAppliedTestProcedureModal,
        payload: action.payload,
      },
    }),
    setUpdatingAppliedTestProcedure: (
      state,
      action: PayloadAction<IAppliedTestProcedureCreatePayload>
    ) => ({
      ...state,
      updateAppliedTestProcedureModal: {
        ...state.updateAppliedTestProcedureModal,
        appliedTestProcedure: action.payload,
      },
    }),
    setUpdatingAppliedTestProcedureLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateAppliedTestProcedureModal: {
        ...state.updateAppliedTestProcedureModal,
        updateAppliedTestProcedureLock: action.payload,
      },
    }),

    //Create applied test procedure
    openCreateAppliedTestProcedureResultModal: (state) => ({
      ...state,
      createAppliedTestProcedureModal: {
        isRender: true,
        payload: null,
        createAppliedTestProcedureLock: LoadingStatus.NEVER,
      },
    }),
    closeCreateAppliedTestProcedureResultModal: (state) => ({
      ...state,
      createAppliedTestProcedureModal: {
        isRender: false,
        payload: null,
        createAppliedTestProcedureLock: LoadingStatus.NEVER,
      },
    }),
    createAppliedTestProcedure: (
      state,
      action: PayloadAction<IAppliedTestProcedureCreatePayload>
    ) => ({
      ...state,
      createAppliedTestProcedureModal: {
        ...state.createAppliedTestProcedureModal,
        payload: action.payload,
      },
    }),
    setCreateAppliedTestProcedureLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      createAppliedTestProcedureModal: {
        ...state.createAppliedTestProcedureModal,
        createAppliedTestProcedureLock: action.payload,
      },
    }),

    //Import Internal Differentiation
    openImportInternalDifferentiationModal: (
      state,
      action: PayloadAction<IImportInternalDifferentiationModalPayload>
    ) => ({
      ...state,
      importInternalDifferentiationModal: {
        isRender: true,
        payload: action.payload,
        importInternalDifferentiation: null,
        importInternalDifferentiationLock: LoadingStatus.NEVER,
      },
    }),
    closeImportInternalDifferentiationModal: (state) => ({
      ...state,
      importInternalDifferentiationModal: {
        isRender: false,
        payload: null,
        importInternalDifferentiation: null,
        importInternalDifferentiationLock: LoadingStatus.NEVER,
      },
    }),
    fetchImportInternalDifferentiation: (
      state,
      action: PayloadAction<IImportInternalDifferentiationModalPayload>
    ) => ({
      ...state,
      importInternalDifferentiationModal: {
        ...state.importInternalDifferentiationModal,
        payload: action.payload,
      },
    }),
    setImportInternalDifferentiation: (state, action: PayloadAction<IInternalDifferentiation>) => ({
      ...state,
      importInternalDifferentiationModal: {
        ...state.importInternalDifferentiationModal,
        importInternalDifferentiation: action.payload,
      },
    }),
    setImportInternalDifferentiationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      importInternalDifferentiationModal: {
        ...state.importInternalDifferentiationModal,
        importInternalDifferentiationLock: action.payload,
      },
    }),

    //TestPerformerList
    fetchTestPerformerList: (state, acton: PayloadAction<DropdownItemsByFetch>) => ({
      ...state,
      testPerformers: {
        ...state.testPerformers,
        testPerformerFetchPayload: acton.payload,
      },
    }),
    setTestPerformerList: (
      state,
      acton: PayloadAction<Array<{ id: number; value?: string | null }>>
    ) => ({
      ...state,
      testPerformers: {
        ...state.testPerformers,
        testPerformerList: acton.payload,
      },
    }),
    setTestPerformerListLock: (state, acton: PayloadAction<LoadingStatus>) => ({
      ...state,
      testPerformers: {
        ...state.testPerformers,
        testPerformerListLock: acton.payload,
      },
    }),

    //TestProceduresList
    fetchTestProceduresList: (state, acton: PayloadAction<DropdownItemsByFetch>) => ({
      ...state,
      testProcedures: {
        ...state.testProcedures,
        testProcedureFetchPayload: acton.payload,
      },
    }),
    setTestProceduresList: (
      state,
      acton: PayloadAction<Array<{ id: number; value?: string | null }>>
    ) => ({
      ...state,
      testProcedures: {
        ...state.testProcedures,
        testProcedureList: acton.payload,
      },
    }),
    setTestProceduresListLock: (state, acton: PayloadAction<LoadingStatus>) => ({
      ...state,
      testProcedures: {
        ...state.testProcedures,
        testProcedureListLock: acton.payload,
      },
    }),
  },
});

export const testingResultModalsReducer = testingResultModalSlice.reducer;
export const testingResultModalsActions = testingResultModalSlice.actions;
