import { AdditionalPersonRequirementAssessmentPrognosisModalContent } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementAssessmentPrognosisModal/AdditionalPersonRequirementAssessmentPrognosisModalContent';
import { useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';

export const AdditionalPersonRequirementAssessmentPrognosisModal: React.FC = () => {
  const { isRender } = useAppSelector(
    organizationalUnitsModalsSelectors.additionalPersonRequirementAssessmentPrognosisModal
  );

  return isRender ? <AdditionalPersonRequirementAssessmentPrognosisModalContent /> : null;
};
