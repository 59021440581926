import React from 'react';

import { CheckedIcon } from '@/components/Icons/CheckedIcon';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

export interface ISelectItem {
  id: string;
  component: React.ReactNode;
  componentContainerClassName?: string;
  selected?: boolean;
  onClick?: (id: string) => void;
  showCheckIcon?: boolean;
}

export const SelectItem: React.FC<ISelectItem> = ({
  id,
  component,
  componentContainerClassName,
  onClick,
  selected = false,
  showCheckIcon = true,
}) => {
  const handleClick = () => {
    onClick?.(id);
  };

  return (
    <div
      className={twMerge(
        `flex flex-row items-center justify-between hover:bg-${colorTypes.Fields} p-2 rounded-default`,
        componentContainerClassName
      )}
      onClick={handleClick}
      role={'button'}
      data-id={id}
    >
      {component}
      {showCheckIcon && (
        <CheckedIcon className={`text-${selected ? colorTypes.Blue : 'transparent'}`} />
      )}
    </div>
  );
};
