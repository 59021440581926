import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { IMobilityForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/EditMobilityModal/hooks/useMobilityForm';

export const JobTicketForm: React.FC = () => {
  const { t: mobilityFormTranslations } = useTranslation('mobility', {
    keyPrefix: 'forms.jobTicket',
  });

  const formContext = useFormContext<IMobilityForm>();

  return (
    <div>
      <DatePicker
        control={formContext.control.register('jobTicket.validDate')}
        label={mobilityFormTranslations('validDate.label')}
      />
    </div>
  );
};
