import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../types';

const selector = (state: IRootState) => state.dashboard;
const widgets = createSelector([selector], (state) => state.widgets);

const favoritePersonsGroups = createSelector(
  [selector],
  (state) => state.favoritePersons.favoritePersonsGroups
);
const favoritePersonsLock = createSelector(
  [selector],
  (state) => state.favoritePersons.favoritePersonsGroupsLock
);
const navigatedFavoritePersons = createSelector(
  [selector],
  (state) => state.favoritePersons.navigatedFavoritePersons
);

const activitiesList = createSelector([selector], (state) => state.activities.activitiesList);
const activitiesStatus = createSelector([selector], (state) => state.activities.status);
const activitiesDateRange = createSelector([selector], (state) => state.activities.chosenDateRange);
const activitiesDatePickerVariant = createSelector(
  [selector],
  (state) => state.activities.datePickerVariant
);
const activitiesTotalCount = createSelector([selector], (state) => state.activities.totalCount);
const selectedActivityPersons = createSelector(
  [selector],
  (state) => state.activities.selectedActivityPersons
);

export const dashboardSelectors = {
  widgets,

  favoritePersonsGroups,
  favoritePersonsLock,
  navigatedFavoritePersons,

  activitiesList,
  activitiesStatus,
  activitiesDateRange,
  activitiesDatePickerVariant,
  activitiesTotalCount,
  selectedActivityPersons,
};
