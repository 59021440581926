import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { EditTextIcon } from '@/components/Icons/EditTextIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { MobilityFormsContext } from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import {
  IMobilityDay,
  mobilityModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/mobility/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IWorkingDayButton {
  id: number;
  context: MobilityFormsContext;
  name?: string | null;
  mobilityDay?: IMobilityDay | null;
  className?: string;
  personID?: number | null;
}

export const WorkingDayButton: React.FC<IWorkingDayButton> = ({
  name,
  mobilityDay,
  context,
  id,
  className,
  personID,
}) => {
  const { t: mobilityTranslations } = useTranslation('mobility', { keyPrefix: 'placeholders' });
  const placeholder = mobilityTranslations(
    context === 'arrival' ? 'arrival.label' : 'departure.label'
  );

  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(
      mobilityModalsActions.openEditMobilityModal({
        context: context,
        mobilityID: id,
        mobilityDay: mobilityDay,
      })
    );
  };

  const handleDeleteMobility = () => {
    if (!personID) {
      return;
    }

    dispatch(
      mobilityModalsActions.deleteMobility({
        mobilityID: id,
        context: context,
        personID: personID,
      })
    );
  };

  return (
    <div
      className={twMerge(
        `flex gap-2 justify-between items-center px-[12px] py-[6px] rounded-default bg-${colorTypes.Fields}`,
        className
      )}
    >
      <Typography color={name ? 'Primary' : 'Disabled'} className={'ms-2'}>
        {name || placeholder}
      </Typography>
      <div className={'flex'}>
        {name && (
          <Button
            buttonStyle={'Icon'}
            buttonVariant={'Icon'}
            className={`p-default rounded-default hover:bg-${colorTypes.Stroke} text-${colorTypes.Grey}`}
            onClick={handleDeleteMobility}
          >
            <CrossIcon className={`w-[24px] h-[24px]`} />
          </Button>
        )}
        <Button
          buttonStyle={'Icon'}
          buttonVariant={'Icon'}
          className={`p-default rounded-default hover:bg-${colorTypes.Stroke} text-${colorTypes.Grey}`}
          onClick={handleOpenModal}
        >
          <EditTextIcon className={`w-[24px] h-[24px]`} />
        </Button>
      </div>
    </div>
  );
};
