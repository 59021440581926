import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MeasureCompleteValidationStatusEnum } from '@/core/enums/functions/vocationalTrainingArea/measureCompleteValidationStatusEnum';
import { VocationalTrainingAreaType } from '@/core/enums/functions/vocationalTrainingArea/vocationalTrainingAreaTypesEnum';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IVocationalTrainingAreaItem {
  id: number;
  name: string;
  nearestActivity?: string | null;
  lastActivity?: string | null;
  type?: VocationalTrainingAreaType | null;
  hasDocuments: boolean;
  measureToPersonID: number;
}

export interface IVocationalTrainingAreaGroup {
  id: number;
  name: string;
  childGroup?: IVocationalTrainingAreaGroup[] | null;
  items?: IVocationalTrainingAreaItem[] | null;
}

export interface IVocationalTrainingAreaFetchPayload {
  personID: number;
  searchValue?: string | null;
  selectedType?: VocationalTrainingAreaType | null;
  isFinished?: boolean | null;
}

export interface IMeasureItem {
  id: number;
  name?: string | null;
}

export interface ICompleteMeasureState {
  validMeasures: IMeasureItem[];
  withoutAppointments: IMeasureItem[];
  hasOpenAppointments: IMeasureItem[];
}

export interface IVocationalTrainingAreaState {
  vocationalTrainingAreaList: IVocationalTrainingAreaItem[] | null;
  vocationalTrainingAreaListLock: LoadingStatus;
  vocationalTrainingAreaGroups: IVocationalTrainingAreaGroup[] | null;
  vocationalTrainingAreaGroupsLock: LoadingStatus;
  payload: IVocationalTrainingAreaFetchPayload | null;
  selectedVocationalTrainingAreaItems: IVocationalTrainingAreaItem[];
  completeMeasuresPayload: number[] | null;
  completeMeasuresErrors: Record<MeasureCompleteValidationStatusEnum, boolean>;
  completeMeasureState: ICompleteMeasureState | null;
}

const initialState: IVocationalTrainingAreaState = {
  selectedVocationalTrainingAreaItems: [],
  vocationalTrainingAreaList: null,
  vocationalTrainingAreaListLock: LoadingStatus.NEVER,
  vocationalTrainingAreaGroups: null,
  vocationalTrainingAreaGroupsLock: LoadingStatus.NEVER,
  payload: null,
  completeMeasuresPayload: null,
  completeMeasuresErrors: {
    [MeasureCompleteValidationStatusEnum.SINGLE_MEASURE_COMPLETED]: false,
    [MeasureCompleteValidationStatusEnum.SINGLE_MEASURE_HAS_OPEN_APPOINTMENTS]: false,
    [MeasureCompleteValidationStatusEnum.SINGLE_MEASURE_WITHOUT_APPOINTMENTS]: false,
    [MeasureCompleteValidationStatusEnum.MULTIPLE_MEASURES_WARNING]: false,
    [MeasureCompleteValidationStatusEnum.MULTIPLE_HAS_OPEN_APPOINTMENTS]: false,
    [MeasureCompleteValidationStatusEnum.MULTIPLE_MEASURE_WITHOUT_APPOINTMENTS]: false,
    [MeasureCompleteValidationStatusEnum.OK]: false,
  },
  completeMeasureState: null,
};

export const vocationalTrainingAreaSlice = createSlice({
  name: 'vocationalTrainingArea',
  initialState,
  reducers: {
    fetchVocationalTrainingAreaGroups: (
      state,
      action: PayloadAction<IVocationalTrainingAreaFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setVocationalTrainingAreaGroupsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      vocationalTrainingAreaGroupsLock: action.payload,
    }),
    setVocationalTrainingAreaGroups: (
      state,
      action: PayloadAction<IVocationalTrainingAreaGroup[] | null>
    ) => ({
      ...state,
      vocationalTrainingAreaGroups: action.payload,
    }),
    fetchVocationalTrainingAreaList: (
      state,
      action: PayloadAction<IVocationalTrainingAreaFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setVocationalTrainingAreaListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      vocationalTrainingAreaListLock: action.payload,
    }),
    setVocationalTrainingAreaList: (
      state,
      action: PayloadAction<IVocationalTrainingAreaItem[] | null>
    ) => ({
      ...state,
      vocationalTrainingAreaList: action.payload,
    }),
    setSelectedVocationalTrainingAreaItem: (
      state,
      action: PayloadAction<IVocationalTrainingAreaItem>
    ) => {
      const tempArray = [...state.selectedVocationalTrainingAreaItems];
      const foundIndex = tempArray.findIndex((item) => item.id === action.payload.id);

      if (foundIndex < 0) {
        tempArray.push(action.payload);
      } else {
        tempArray.splice(foundIndex, 1);
      }

      return {
        ...state,
        selectedVocationalTrainingAreaItems: tempArray,
      };
    },
    resetSelectedVocationalTrainingAreaItems: (state) => ({
      ...state,
      selectedVocationalTrainingAreaItems: [],
    }),
    completeMeasures: (state, action: PayloadAction<number[]>) => ({
      ...state,
      completeMeasuresPayload: action.payload,
    }),
    setCompleteMeasuresErrors: (
      state,
      action: PayloadAction<Partial<Record<MeasureCompleteValidationStatusEnum, boolean>>>
    ) => ({
      ...state,
      completeMeasuresErrors: {
        ...state.completeMeasuresErrors,
        ...action.payload,
      },
    }),
    setCompleteMeasureState: (state, action: PayloadAction<ICompleteMeasureState | null>) => ({
      ...state,
      completeMeasureState: action.payload,
    }),
    setValidAppointments: (state, action: PayloadAction<IMeasureItem[]>) => {
      if (state.completeMeasureState) {
        state.completeMeasureState.validMeasures = action.payload;
      }
    },
  },
});

export const vocationalTrainingAreaReducer = vocationalTrainingAreaSlice.reducer;
export const vocationalTrainingAreaActions = vocationalTrainingAreaSlice.actions;
