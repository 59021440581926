import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.testingResultModals;

const deleteAppliedTestingResultModal = createSelector(
  [selector],
  (state) => state.deleteAppliedTestProcedureModal
);
const updateAppliedTestingResultModal = createSelector(
  [selector],
  (state) => state.updateAppliedTestProcedureModal
);
const createAppliedTestingResultModal = createSelector(
  [selector],
  (state) => state.createAppliedTestProcedureModal
);
const importInternalDifferentiationModal = createSelector(
  [selector],
  (state) => state.importInternalDifferentiationModal
);

const testPerformerList = createSelector(
  [selector],
  (state) => state.testPerformers.testPerformerList
);
const testPerformerListLock = createSelector(
  [selector],
  (state) => state.testPerformers.testPerformerListLock
);
const testProcedureList = createSelector(
  [selector],
  (state) => state.testProcedures.testProcedureList
);
const testProcedureListLock = createSelector(
  [selector],
  (state) => state.testProcedures.testProcedureListLock
);

export const testingResultModalsSelectors = {
  deleteAppliedTestingResultModal,
  updateAppliedTestingResultModal,
  createAppliedTestingResultModal,
  importInternalDifferentiationModal,
  testPerformerList,
  testPerformerListLock,
  testProcedureList,
  testProcedureListLock,
};
