import React, { useCallback } from 'react';

import { PeriodDatePicker } from '@/components/widgets/ActivitiesWidget/ActivitiesDatePicker/PeriodDatePicker/PeriodDatePicker';
import { SingleDatePicker } from '@/components/widgets/ActivitiesWidget/ActivitiesDatePicker/SingleDatePicker/SingleDatePicker';
import { DatePickerVariant } from '@/core/enums/datePickerVariantsEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { dashboardSelectors } from '@/core/redux/slices/dashboard/selectors';

export const ActivitiesDatePicker: React.FC = () => {
  const datePickerVariant = useAppSelector(dashboardSelectors.activitiesDatePickerVariant);

  const renderDatePicker = useCallback(() => {
    switch (datePickerVariant) {
      case DatePickerVariant.SINGLE:
        return <SingleDatePicker />;
      case DatePickerVariant.RANGE:
        return <PeriodDatePicker />;
      default:
        return null;
    }
  }, [datePickerVariant]);

  return renderDatePicker();
};
