import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import fp from 'lodash/fp';

import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { mobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/mobility/selectors';
import {
  IJobTicket,
  IMobilityFormType,
  ISelfDrive,
  ITransportTypeService,
  mobilityModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/mobility/slice';

export interface IMobilityForm {
  mobilityType: IMobilityFormType;
  carService?: ITransportTypeService | null;
  selfDriver?: ISelfDrive | null;
  jobTicket?: IJobTicket | null;
  appliedDays?: number[] | null;
}

export function useMobilityForm() {
  const { mobilityDetails, payload } = useAppSelector(mobilityModalsSelectors.editMobilityModal);

  const form = useForm<IMobilityForm>();
  const selectedMobility = form.watch('mobilityType');
  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!mobilityDetails) {
      return;
    }

    const newFormState: IMobilityForm = {
      appliedDays: payload?.mobilityDay?.id ? [payload.mobilityDay.id] : [],
      carService: mobilityDetails.transportService,
      selfDriver: mobilityDetails.selfDrive,
      jobTicket: mobilityDetails.jobTicket,
      mobilityType: mobilityDetails.mobilityType,
    };

    form.reset(newFormState);
  }, [mobilityDetails]);

  const handleSubmit = () => {
    if (!payload || !selectedPersonID) {
      return;
    }

    const currentValues = fp.cloneDeep(form.watch());

    dispatch(
      mobilityModalsActions.updateMobility({
        context: payload.context,
        formValues: currentValues,
        personID: selectedPersonID,
      })
    );
  };

  return { form, selectedMobility, handleSubmit };
}
