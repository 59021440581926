import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { useDialog } from '@/core/hooks/useDialog';
import { useAppDispatch } from '@/core/redux/hooks';
import {
  administrationLocationManagementActions,
  IAdministrationDepartment,
} from '@/core/redux/slices/administrationFunctions/locationManagement/slice';

export function useConfirmDeleteAdministrationDepartmentDialog(
  departments: IAdministrationDepartment[]
) {
  const { t: administrationLocationManagementTranslations } = useTranslation(
    'administrationLocationManagement',
    { keyPrefix: 'departments.dialogs.deleteDepartment.confirm' }
  );

  const dispatch = useAppDispatch();

  const handleDeleteDepartment = () => {
    dispatch(
      administrationLocationManagementActions.deleteDepartment({
        departments: departments,
      })
    );

    closeDialog();
  };

  const { renderDialogContainer, openDialog, closeDialog } = useDialog({
    title:
      departments.length > 1 ? (
        <Typography type={'H2'}>
          {administrationLocationManagementTranslations('multipleDeleteTitle.label', {
            departmentCount: departments.length,
          })}
        </Typography>
      ) : (
        <Typography type={'H2'}>
          {administrationLocationManagementTranslations('title.label')}
        </Typography>
      ),
    confirmButton: (
      <Button onClick={handleDeleteDepartment} className={'w-full'}>
        {administrationLocationManagementTranslations('buttons.confirm.label')}
      </Button>
    ),
    cancelButton: (
      <Button onClick={() => closeDialog()} buttonVariant={'Secondary'} className={'w-full'}>
        {administrationLocationManagementTranslations('buttons.cancel.label')}
      </Button>
    ),
    className: 'w-[500px] flex flex-col gap-4 items-center text-center',
    buttonsContainerClassName: 'w-full flex-row-reverse mt-3',
  });

  return { renderDialogContainer, openDialog };
}
