import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PersonInfoItem } from '@/components/Modals/Modals/functionsPage/PersonInfoModal/PersonInfoModalContent/PersonInfoItem/PersonInfoItem';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionsPageModalsActions } from '@/core/redux/slices/modalsSlice/functionsPage/functionsPageModalsSlice';
import { functionsPageModalsSelectors } from '@/core/redux/slices/modalsSlice/functionsPage/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

export const PersonInfoModalContent: React.FC = () => {
  const { t: personInfoModalTranslations } = useTranslation('personInfoModal');

  const {
    personInfo,
    personInfoLock: loadingStatus,
    payload: person,
  } = useAppSelector(functionsPageModalsSelectors.personInfoModal);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!person) {
      return;
    }

    dispatch(functionsPageModalsActions.fetchPersonInfo(person));
  }, []);

  return (
    <div className={'flex flex-col gap-4 p-8'}>
      <div className={'grid grid-cols-2 gap-3'}>
        <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
          <PersonInfoItem
            label={personInfoModalTranslations('fields.name.label')}
            value={personInfo?.name}
          />
          <PersonInfoItem
            label={personInfoModalTranslations('fields.surname.label')}
            value={personInfo?.surname}
          />
          <PersonInfoItem
            label={personInfoModalTranslations('fields.birthDate.label')}
            value={personInfo?.birthDate}
          />
          <PersonInfoItem
            label={personInfoModalTranslations('fields.educationMentor.label')}
            value={personInfo?.educationMentor}
          />
          <PersonInfoItem
            label={personInfoModalTranslations('fields.inactiveSince.label')}
            value={personInfo?.inactiveSince}
          />
          <PersonInfoItem
            label={personInfoModalTranslations('fields.socialServiceWorker.label')}
            value={personInfo?.socialServiceWorker}
          />
          <PersonInfoItem
            label={personInfoModalTranslations('fields.groupManager.label')}
            value={personInfo?.groupManager}
          />
        </Skeleton>
      </div>
    </div>
  );
};
