import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDiagnosticsModalsPayload {
  personID: number;
  selectedPage: number;
}

export interface IModal {
  isRender: boolean;
  payload: IDiagnosticsModalsPayload | null;
}

export interface IDiagnosticsModalsState {
  addICDCodesModal: IModal;
  addICFItemsModal: IModal;
}

const initialState: IDiagnosticsModalsState = {
  addICDCodesModal: {
    isRender: false,
    payload: null,
  },
  addICFItemsModal: {
    isRender: false,
    payload: null,
  },
};

export const diagnosticsModalsSlice = createSlice({
  name: 'diagnosticsModals',
  initialState,
  reducers: {
    //Add ICD Codes Modal
    openAddICDCodesModal: (state, action: PayloadAction<IDiagnosticsModalsPayload>) => ({
      ...state,
      addICDCodesModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAddICDCodesModal: (state) => ({
      ...state,
      addICDCodesModal: {
        isRender: false,
        payload: null,
      },
    }),
    //Add ICF Items Modal
    openAddICFItemsModal: (state, action: PayloadAction<IDiagnosticsModalsPayload>) => ({
      ...state,
      addICFItemsModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAddICFItemsModal: (state) => ({
      ...state,
      addICFItemsModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const diagnosticsModalsReducers = diagnosticsModalsSlice.reducer;
export const diagnosticsModalsActions = diagnosticsModalsSlice.actions;
