import { PayloadAction } from '@reduxjs/toolkit';
import { type NormalizeOAS, type OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import {
  developmentReportGoalsAndActivityActions,
} from '@/core/redux/slices/functions/developmentReport/developmentReportGoalsAndActivitySlice/slice';
import {
  developmentReportPersonDetailsActions,
} from '@/core/redux/slices/functions/developmentReport/developmentReportPersonDetailsSlice/slice';
import {
  developmentReportReviewSupportActions,
} from '@/core/redux/slices/functions/developmentReport/developmentReportReviewSupportSlice/slice';
import {
  developmentReportVisionAndGoalsActions,
} from '@/core/redux/slices/functions/developmentReport/developmentReportVisionAndGoalsSlice/slice';
import {
  developmentReportActions,
  IDevelopmentReportIDFetchPayload,
  IUpdateDevelopmentReportFormPayload,
} from '@/core/redux/slices/functions/developmentReport/slice';
import type oas from '@/services/rest/base/openapi';

type FetchDevelopmentReportRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report',
  'get'
>;


type DevelopmentReportResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/development_report',
  'get',
  '200'
>;

function* fetchDevelopmentReport(action: PayloadAction<IDevelopmentReportIDFetchPayload>): Generator<any, void, DevelopmentReportResponse> {
  const { personID } = action.payload;

  try {
    const request: FetchDevelopmentReportRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/development_report', 'get', request);

    yield put(developmentReportActions.setDevelopmentReport({
      id: response.development_report.id,
      printedTimestamp: response.development_report.printed_timestamp,
    }));
  } catch (error) {
    console.log('Error on development report id fetching', error);
  }
}

function* updateDevelopmentReportForm(action: PayloadAction<IUpdateDevelopmentReportFormPayload | null>): Generator<any, void, DevelopmentReportResponse> {
  if (!action.payload) {
    return;

  }

  const { personID, developmentReportID, type, form } = action.payload;

  const credentials = {
    developmentReportID: developmentReportID,
    personID: personID,
  }

  try {

    switch (type) {
      case DevelopmentReportForms.PersonDetails: {
        yield put(developmentReportPersonDetailsActions.updateDevelopmentReportPersonDetails({
         ...credentials,
          formValues: form[DevelopmentReportForms.PersonDetails],
        }));
        break;
      }
      case DevelopmentReportForms.VisionAndGoals: {
        yield put(developmentReportVisionAndGoalsActions.updateDevelopmentReportVisionAndGoals({
        ...credentials,
          formValues: form[DevelopmentReportForms.VisionAndGoals]
        }))
        break;
      }
      case DevelopmentReportForms.Review: {
        yield put(developmentReportReviewSupportActions.updateDevelopmentReportReviewSupport({
         ...credentials,
          formValues: form[DevelopmentReportForms.Review]
        }))
        break;
      }
      case DevelopmentReportForms.GoalsAndActivityPlaning: {
        yield put(developmentReportGoalsAndActivityActions.updateDevelopmentReportGoalsAndActivity({
         ...credentials,
          formValues: form[DevelopmentReportForms.GoalsAndActivityPlaning]
        }))
        break;
      }
    }

  } catch (error) {
    console.log('Error on development report form updating', error);
  }
}


export const developmentReportSagas = [
  takeLatest(developmentReportActions.fetchDevelopmentReport, fetchDevelopmentReport),
  takeLatest(developmentReportActions.updateDevelopmentReportForm, updateDevelopmentReportForm),
];
