import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { AdministrationLocationManagementMoveGroup } from '@/components/Modals/Modals/administrationModals/locationManagement/AdministrationLocationMoveModal/AdministrationLocationManagementMoveGroup';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { administrationLocationManagementSelectors } from '@/core/redux/slices/administrationFunctions/locationManagement/selectors';
import { administrationLocationManagementActions } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { administrationLocationManagementModalsSelectors } from '@/core/redux/slices/modalsSlice/administrationFunctions/locationManagement/selectors';
import { administrationLocationManagementModalsActions } from '@/core/redux/slices/modalsSlice/administrationFunctions/locationManagement/slice';
import { colorTypes } from '@/styles/types';

export const AdministrationLocationMoveModal: React.FC = () => {
  const { isRender } = useAppSelector(
    administrationLocationManagementModalsSelectors.moveLocationModal
  );

  return isRender ? <AdministrationLocationMoveModalContent /> : null;
};

const AdministrationLocationMoveModalContent: React.FC = () => {
  const { t: moveLocationModalTranslations } = useTranslation(
    'administrationLocationManagementMoveLocationModal'
  );

  const { payload } = useAppSelector(
    administrationLocationManagementModalsSelectors.moveLocationModal
  );
  const locations = useAppSelector(administrationLocationManagementSelectors.locations);

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(administrationLocationManagementModalsActions.closeAdministrationLocationMoveModal());
  };

  const form = useForm({
    defaultValues: {
      targetID: payload?.location.id,
    },
  });

  const handleUpdate = () => {
    const targetID = form.watch('targetID');

    if (!payload || !targetID) {
      return;
    }

    dispatch(
      administrationLocationManagementActions.updateLocationParent({
        locationID: payload?.location.id,
        targetID: targetID,
      })
    );

    handleExit();
  };

  const footer = (
    <div className={'flex flex-row w-full items-center gap-[10px] p-[30px] pt-[10px]'}>
      <Button buttonVariant={'Secondary'} onClick={handleExit} className={'w-full'}>
        {moveLocationModalTranslations('buttons.cancel.label')}
      </Button>
      <Button onClick={handleUpdate} className={'w-full'}>
        {moveLocationModalTranslations('buttons.save.label')}
      </Button>
    </div>
  );

  return (
    <Modal onExit={handleExit} className={'w-[600px] px-0'} footer={footer}>
      <div className={`h-full flex flex-col max-h-[60vh] overflow-hidden`}>
        <Typography
          type={'H2'}
          className={`px-[30px] pb-5 border-b border-b-${colorTypes.StrokeLight}`}
        >
          {payload?.location.name}
        </Typography>
        <FormProvider {...form}>
          <div className={'flex-1 h-full overflow-y-auto'}>
            {locations &&
              locations.map((location) => (
                <AdministrationLocationManagementMoveGroup
                  location={location}
                  layer={0}
                  control={form.control.register('targetID')}
                  key={location.id}
                />
              ))}
          </div>
        </FormProvider>
      </div>
    </Modal>
  );
};
