import React from 'react';
import { useTranslation } from 'react-i18next';

import { ISelfAssessmentPersonQuestionnaireWithData } from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';
import { toClientDateTimeInput } from '@/core/utils/dateTimeUtil';

interface IDefaultQuestionnaireDetails {
  personQuestionnaire: ISelfAssessmentPersonQuestionnaireWithData | null;
}

export const DefaultQuestionnaireDetails: React.FC<IDefaultQuestionnaireDetails> = ({
  personQuestionnaire,
}) => {
  const { t: selfAssesmentTranslations } = useTranslation('selfAssesment');

  const questionsCount = personQuestionnaire?.questionnaire?.questions?.length ?? 0;
  const missingCount =
    (personQuestionnaire?.questionnaire?.questions?.length ?? 0) -
    (personQuestionnaire?.answers.length ?? 0);

  return (
    <address>
      <span>{selfAssesmentTranslations('fields.status.label')}&nbsp;</span>
      <span>{personQuestionnaire?.statusName}</span>
      <br />
      <span>{selfAssesmentTranslations('fields.numQuestions.label')}&nbsp;</span>
      <span>
        {selfAssesmentTranslations('fields.unrated.label', { questionsCount, missingCount })}
      </span>
      <br />
      <span>{selfAssesmentTranslations('fields.lastChange.label')}&nbsp;</span>
      <span>{toClientDateTimeInput(personQuestionnaire?.lastChange)}</span>
      <br />
      <span>{selfAssesmentTranslations('fields.dateCreated.label')}&nbsp;</span>
      <span>{toClientDateTimeInput(personQuestionnaire?.createdDate)}</span>
    </address>
  );
};
