import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { IAdditionalPersonRequirementField } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/AdditionalPersonRequirementModalContent';

interface IPersonRequirementFooter extends IAdditionalPersonRequirementField {
  handleExit: () => void;
  handleSave: () => void;
}

export const PersonRequirementFooter: React.FC<IPersonRequirementFooter> = ({
  handleExit,
  handleSave,
  form,
}) => {
  const { t: additionalPersonRequirementsModalTranslations } = useTranslation(
    'additionalPersonRequirementModal'
  );

  const {
    getValues,
    reset,
    formState: { isDirty },
  } = form;

  const selected = getValues('assistanceID');

  const handleCancel = () => {
    reset();
  };

  return (
    <div className={'flex flex-row gap-3 justify-start'}>
      {isDirty ? (
        <>
          <Button onClick={handleSave} disabled={!selected}>
            {additionalPersonRequirementsModalTranslations('buttons.save.label')}
          </Button>
          <Button buttonVariant={'Secondary'} onClick={handleCancel}>
            {additionalPersonRequirementsModalTranslations('buttons.cancel.label')}
          </Button>
        </>
      ) : (
        <Button buttonVariant={'Secondary'} onClick={handleExit}>
          {additionalPersonRequirementsModalTranslations('buttons.close.label')}
        </Button>
      )}
    </div>
  );
};
