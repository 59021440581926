export const applyTimeStampMask = (previousValue: string, newValue: string) => {
  const numericValue = newValue.replace(/\D/g, '');
  const digits = numericValue.split('');

  if (newValue.length > 5) {
    return previousValue;
  }

  if (digits.length >= 2) {
    const hours = parseInt(digits.slice(0, 2).join(''), 10);

    if (hours > 23) {
      digits[0] = '2';
      digits[1] = '3';
    }
  }

  if (digits.length > 2) {
    const minutes = parseInt(digits.slice(2, 4).join(''), 10);

    if (minutes > 59) {
      digits[2] = '5';
      digits[3] = '9';
    }
  }

  let timeString = digits.reduce((acc, digit, index) => {
    if (index === 2) acc += ':';
    return acc + digit;
  }, '');

  if (digits.length === 2) {
    timeString += ':';
  }

  return timeString;
};
