import { FieldError, FieldErrors } from 'react-hook-form';

export function getFirstFormError<T extends Record<string, any>>(
  errors: FieldErrors<T>
): string | undefined {
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      const error = errors[key as keyof FieldErrors<T>];
      if (isFieldError(error)) {
        return error.message;
      }
      if (typeof error === 'object' && error) {
        const nestedError = getFirstFormError(error as FieldErrors<any>);
        if (nestedError) {
          return nestedError;
        }
      }
    }
  }
  return undefined;
}

function isFieldError(error: any): error is FieldError {
  return error && typeof error.message === 'string';
}

export const extractErrorsByKey = (
  errors?: FieldErrors,
  formKey?: string,
  uniqueOnly: boolean = false
): string[] | null => {
  if (!errors || !formKey) {
    return null;
  }

  const result: string[] = [];

  const targetObject = errors[formKey];

  const collectMessages = (obj: any) => {
    if (!obj || typeof obj !== 'object') {
      return;
    }

    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        if (item.message) {
          result.push(item.message);
        }
        collectMessages(item);
      });
    } else {
      for (const key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
          collectMessages(obj[key]);
        }
      }
    }
  };

  if (targetObject) {
    collectMessages(targetObject);
  }

  const uniqueMessages = uniqueOnly ? Array.from(new Set(result)) : result;

  return uniqueMessages.length > 0 ? uniqueMessages : null;
};
