import React from 'react';

import { Button } from '@/components/Button/Button';
import { IIntegrationPlanForm } from '@/components/functions/IntegrationPlan/IntegrationPlan';
import { IntegrationPlanTabs } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsContainer/IntegrationPlanFormsContainer';
import { IntegrationPlanFormNavigationTooltip } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsNavigation/IntegrationPlanFormNavigationTooltip';

interface IIntegrationPlanFormsNavigation {
  activeTab: IntegrationPlanTabs;
  handeTabChange: (newTab: IntegrationPlanTabs) => void;
  integrationPlanForms: Record<IntegrationPlanTabs, IIntegrationPlanForm>;
}

export const IntegrationPlanFormsNavigation: React.FC<IIntegrationPlanFormsNavigation> = ({
  activeTab,
  integrationPlanForms,
  handeTabChange,
}) => {
  return (
    <div className={'flex flex-row gap-2 px-5'}>
      {Object.values(integrationPlanForms).map((form) => {
        const isActive = activeTab === form.type;
        const tooltipID = `integration-plan-tooltip-${form.type}`;
        const formTitle = `${form.shortCut}. ${form.title}`;

        return (
          <div key={form.type}>
            <Button
              buttonVariant={isActive ? 'Default' : 'Secondary'}
              className={'rounded-b-none px-4 pt-2 pb-[7px] justify-start text-sm overflow-hidden '}
              onClick={() => handeTabChange(form.type)}
              id={tooltipID}
            >
              <span className={'w-full whitespace-nowrap truncate text-ellipsis'}>{formTitle}</span>
            </Button>
            <IntegrationPlanFormNavigationTooltip id={tooltipID} tooltipMessage={formTitle} />
          </div>
        );
      })}
    </div>
  );
};
