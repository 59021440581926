import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IMeasure {
  id: number;
  name: string;
  layer: number;
}

export interface IEfficacyAssessment {
  id: number;
  name: string;
  childGroups: IEfficacyAssessment[];
  items: IMeasure[];
}

export interface IEfficacyAssessmentFetchPayload {
  personID: number;
  measureID?: number;
}

export interface IAppointment {
  hours?: number | null;
  toTimestamp?: string | null;
  id: number;
  fromTimestamp: string;
}

export interface IMeasureAppointment {
  toTimestamp?: string | null;
  hours?: number | null;
  id: number;
  fromTimestamp: string;
}

export interface IAppointmentsFetchPayload {
  personID: number;
  fromDate: string;
  toDate: string;
}

export interface IMeasureAppointmentsFetchPayload {
  personID: number;
  measureID: number;
}

export interface IEfficacyAssessmentCreatePayload {
  appointmentID?: number;
  personID: number;
  measureID: number;
  level: number;
  measureTitle: string;
  isParticipated: boolean;
  isUnderDirection: boolean;
  isAlmostIndependent: boolean;
  isIndependent: boolean;
  fromTimestamp: string;
  toTimestamp: string;
  hours: number;
  location: string;
  implementationAidsIDs: number[]
  note?: string;
}

export interface IEfficacyAssessmentState {
  efficacyAssessment: IEfficacyAssessment | null;
  efficacyAssessmentLock: LoadingStatus;
  appointments: IAppointment[];
  measureAppointments: IMeasureAppointment[];
  selectedMeasure: IMeasure | null;
}

const initialState: IEfficacyAssessmentState = {
  selectedMeasure: null,
  efficacyAssessment: null,
  efficacyAssessmentLock: LoadingStatus.NEVER,
  appointments: [],
  measureAppointments: [],
};

export const efficacyAssessmentSlice = createSlice({
  name: 'efficacyAssessment',
  initialState,
  reducers: {
    // fetch
    fetchEfficacyAssessment: (state, action: PayloadAction<IEfficacyAssessmentFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setEfficacyAssessment: (state, action: PayloadAction<IEfficacyAssessment | null>) => ({
      ...state,
      efficacyAssessment: action.payload ?? null,
    }),
    setEfficacyAssessmentaLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      efficacyAssessmentLock: action.payload,
    }),
    //measure
    setSelectedMeasure: (state, action: PayloadAction<IMeasure | null>) => ({
      ...state,
      selectedMeasure: action.payload ?? null,
    }),
    // appointments
    fetchAppointments: (state, action: PayloadAction<IAppointmentsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAppointments: (state, action: PayloadAction<IAppointment[]>) => ({
      ...state,
      appointments: action.payload,
    }),
    // measure appointments
    fetchMeasureAppointments: (state, action: PayloadAction<IMeasureAppointmentsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setMeasureAppointments: (state, action: PayloadAction<IMeasureAppointment[]>) => ({
      ...state,
      measureAppointments: action.payload,
    }),
    // create
    createEfficacyAssessment: (state, action: PayloadAction<IEfficacyAssessmentCreatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const efficacyAssessmentReducer = efficacyAssessmentSlice.reducer;
export const efficacyAssessmentActions = efficacyAssessmentSlice.actions;
