import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../types';

const selector = (state: IRootState) => state.auth;

const accessToken = createSelector([selector], (state) => state.accessToken);
const profileEntity = createSelector([selector], (state) => state.profile.entity);

export const authSelectors = { accessToken, profileEntity };
