import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Typography } from '../Typography/Typography';

export interface ITag {
  background?: string;
  label: string | ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const Tag: React.FC<ITag> = ({ background, label, className, style }) => {
  return (
    <div
      className={twMerge(`px-3 py-1.5 rounded-3xl w-fit bg-${background}`, className)}
      style={style}
    >
      {typeof label === 'string' ? (
        <Typography color={'White'} type={'14-M'}>
          {label}
        </Typography>
      ) : (
        <>{label}</>
      )}
    </div>
  );
};
