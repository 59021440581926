import React from 'react';

import { IIcon } from '@/types/icon';

export const SearchIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M11.0833 18.4167C15.1334 18.4167 18.4167 15.1334 18.4167 11.0833C18.4167 7.03324 15.1334 3.75 11.0833 3.75C7.03324 3.75 3.75 7.03324 3.75 11.0833C3.75 15.1334 7.03324 18.4167 11.0833 18.4167Z'
      stroke='#8290AB'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.2502 20.2492L16.2627 16.2617'
      stroke='#8290AB'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
