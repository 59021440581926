import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IPersonData {
  name?: string | null;
  surname?: string | null;
  sozVersNr?: string | null;
  birthDate?: string | null;
  birthPlace?: string | null;
  kidsNumber?: number | null;
  costBearer?: string | null;
  inactiveTimestamp?: string | null;
  salutation?: number;
  gender?: number;
  maritalStatus?: number;
  nationality?: number;
  confession?: number;
  supervisor?: number;
  foreignLegalStatus?: number;
  serviceProvider?: number;
  healthInsurance?: number;
  housingForm?: number;
  educationalSupporter?: number;
  socialServiceWorker?: number;
  groupManager?: number;
  jobcoach?: number;
  manager?: number;
  specialist?: number;
  isPhotoConsent: boolean;
  isAdmissionReserved: boolean;
}

export interface IServiceProvider {
  id: number;
  name?: string | null;
  address?: string | null;
  plz?: string | null;
  location?: string | null;
}

export interface IPersonDataFetchPayload {
  personID: number;
}

export interface IPersonDataUpdatePayload {
  personData: IPersonData;
  personID: number;
}

export interface IPersonDataState {
  personData: IPersonData | null;
  serviceProviders: IServiceProvider[];
  personDataLock: LoadingStatus;
  updatePersonDataLock: LoadingStatus;
}

const initialState: IPersonDataState = {
  personData: null,
  serviceProviders: [],
  personDataLock: LoadingStatus.NEVER,
  //update
  updatePersonDataLock: LoadingStatus.NEVER,
};

export const personDataSlice = createSlice({
  name: 'personData',
  initialState,
  reducers: {
    //fetch
    fetchPersonData: (state, action: PayloadAction<IPersonDataFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setPersonData: (state, action: PayloadAction<IPersonData | null>) => ({
      ...state,
      personData: action.payload ?? null,
    }),
    setPersonDataLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      personDataLock: action.payload,
    }),

    //Service providers select
    fetchServiceProviders: () => {},
    setServiceProviders: (state, action: PayloadAction<IServiceProvider[]>) => ({
      ...state,
      serviceProviders: action.payload,
    }),

    //update
    updatePersonData: (state, action: PayloadAction<IPersonDataUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdatePersonDataLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updatePersonDataLock: action.payload,
    }),
  },
});

export const personDataReducers = personDataSlice.reducer;
export const personDataActions = personDataSlice.actions;
