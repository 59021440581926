import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';

import { CalendarSmallIcon } from '@/components/Icons/CalendarSmallIcon';
import { PointSmallIcon } from '@/components/Icons/PointSmaillIcon';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';
import { IActivityDate } from '@/types/activitiesWidget';

export const ActivityDate: React.FC<IActivityDate> = ({ date, isExpired }) => {
  const { i18n, t: activitiesWidgetTranslations } = useTranslation('activitiesWidget', {
    keyPrefix: 'activitiesContentTable.activityColumn.activity.activityDate',
  });
  const formattedDate = moment(date).locale(i18n.language).format('D MMM, hh:mm A');

  return (
    <div className={'flex flex-row items-end gap-3 flex-wrap'}>
      <div
        className={clsx(
          'flex flex-row items-center gap-1 mt-1',
          isExpired ? `text-${colorTypes.Error}` : `text-${colorTypes.Secondary}`
        )}
      >
        <CalendarSmallIcon />
        <Typography type={'14-R'} color={isExpired ? 'Error' : 'Disabled'}>
          {formattedDate}
        </Typography>
      </div>
      {isExpired && (
        <div className={`flex flex-row items-center gap-1 text-${colorTypes.Error}`}>
          <PointSmallIcon />
          <Typography type={'14-R'} color={'Error'}>
            {activitiesWidgetTranslations('isExpired.label')}
          </Typography>
        </div>
      )}
    </div>
  );
};
