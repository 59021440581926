import React from 'react';

import { IIcon } from '@/types/icon';

export const HistoryIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g clipPath='url(#clip0_3320_57544)'>
      <path
        d='M10.0003 0.687507C8.60856 0.685852 7.23427 0.99721 5.97914 1.59854C4.92902 2.10164 3.98482 2.79694 3.19438 3.64648V2.48594C3.19438 2.33676 3.13511 2.19369 3.02962 2.0882C2.92413 1.98271 2.78106 1.92344 2.63188 1.92344C2.48269 1.92344 2.33962 1.98271 2.23413 2.0882C2.12864 2.19369 2.06938 2.33676 2.06938 2.48594V5.24376C2.06938 5.39294 2.12864 5.53601 2.23413 5.6415C2.33962 5.74699 2.48269 5.80626 2.63188 5.80626H5.29625C5.44544 5.80626 5.58851 5.74699 5.694 5.6415C5.79949 5.53601 5.85875 5.39294 5.85875 5.24376C5.85875 5.09457 5.79949 4.9515 5.694 4.84601L5.51722 5.02279L5.694 4.84601C5.58851 4.74052 5.44543 4.68126 5.29625 4.68126H3.77535C4.92074 3.34072 6.47202 2.40199 8.20106 2.01257C10.0974 1.58549 12.0838 1.847 13.8049 2.75032C15.5261 3.65365 16.8697 5.13985 17.5954 6.94309C18.3212 8.74632 18.3817 10.7489 17.7661 12.5927C17.1506 14.4365 15.8992 16.0011 14.2357 17.0067C12.5722 18.0123 10.6053 18.3933 8.68664 18.0815C6.76801 17.7697 5.0229 16.7854 3.76349 15.3048C2.50407 13.8242 1.8125 11.9438 1.8125 10C1.8125 9.85082 1.75324 9.70775 1.64775 9.60226C1.54226 9.49677 1.39918 9.43751 1.25 9.43751C1.10082 9.43751 0.957742 9.49677 0.852252 9.60226C0.746763 9.70775 0.6875 9.85082 0.6875 10C0.6875 11.8418 1.23367 13.6423 2.25694 15.1738C3.28021 16.7052 4.73462 17.8988 6.43626 18.6036C8.1379 19.3085 10.0103 19.4929 11.8168 19.1336C13.6232 18.7742 15.2826 17.8873 16.5849 16.5849C17.8873 15.2826 18.7742 13.6232 19.1336 11.8168C19.4929 10.0103 19.3085 8.1379 18.6036 6.43627C17.8988 4.73463 16.7052 3.28022 15.1737 2.25695C13.6424 1.23373 11.842 0.687565 10.0003 0.687507ZM10.0003 0.687507L10 0.937506V0.687506C10.0001 0.687506 10.0002 0.687507 10.0003 0.687507Z'
        fill='#838FAF'
        stroke='currentColor'
        strokeWidth='0.5'
      />
      <path
        d='M14.7652 10.0226L14.5885 10.1993L14.7652 10.0226C14.6598 9.91708 14.5167 9.85781 14.3675 9.85781H10.4531V5.21094C10.4531 5.06175 10.3939 4.91868 10.2884 4.81319C10.1829 4.7077 10.0398 4.64844 9.89062 4.64844C9.74144 4.64844 9.59837 4.7077 9.49288 4.81319C9.38739 4.91868 9.32812 5.06175 9.32812 5.21094V10.4203C9.32812 10.5695 9.38739 10.7126 9.49288 10.8181C9.59837 10.9236 9.74144 10.9828 9.89062 10.9828H14.3675C14.5167 10.9828 14.6598 10.9236 14.7652 10.8181C14.8707 10.7126 14.93 10.5695 14.93 10.4203C14.93 10.2711 14.8707 10.1281 14.7652 10.0226Z'
        fill='#838FAF'
        stroke='currentColor'
        strokeWidth='0.5'
      />
    </g>
    <defs>
      <clipPath id='clip0_3320_57544'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
