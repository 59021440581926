import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { ReviewItem } from '@/components/forms/CompetenceAnalysis/ReviewItem/ReviewItem';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const SuitabilityAndInclination: React.FC = () => {
  const { t: suitabilityTranslations } = useTranslation('competenceAnalysis', {
    keyPrefix: 'suitabilityAndInclination',
  });

  return (
    <Accordion
      title={<Typography type={'H3'}>{suitabilityTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'flex flex-col gap-2 mt-4'}>
        <ReviewItem
          title={suitabilityTranslations('fields.grossFineMotorSkillsWithoutTools.label')}
          fieldName={'grossFineMotorSkillsWithoutTools'}
        />
        <ReviewItem
          title={suitabilityTranslations('fields.grossFineMotorSkillsWithTools.label')}
          fieldName={'grossFineMotorSkillsWithTools'}
        />
        <ReviewItem
          title={suitabilityTranslations('fields.physicalCapacity.label')}
          fieldName={'physicalCapacity'}
        />
        <ReviewItem
          title={suitabilityTranslations('fields.spacialOrientation.label')}
          fieldName={'spacialOrientation'}
        />
        <ReviewItem
          title={suitabilityTranslations('fields.timeOrientation.label')}
          fieldName={'timeOrientation'}
        />
        <ReviewItem
          title={suitabilityTranslations('fields.mobilityTraffic.label')}
          fieldName={'mobilityTraffic'}
        />
      </div>
    </Accordion>
  );
};
