import React from 'react';
import clsx from 'clsx';

import { Typography } from '@/components/Typography/Typography';
import { generateName } from '@/core/utils/commonUtils';
import { colorTypes } from '@/styles/types';
import { IPersonsListTooltip } from '@/types/activitiesWidget';

export const PersonsListTooltip: React.FC<IPersonsListTooltip> = ({ personsList }) => {
  const trimmedArray = personsList.slice(0, 10);
  const remainingPersonsCount = personsList.length - 10;

  return (
    <div
      className={clsx(
        `flex flex-col items-center justify-start p-[10px] bg-${colorTypes.TooltipBG} w-max shadow-default rounded-default`
      )}
    >
      {trimmedArray &&
        trimmedArray.map((person) => (
          <Typography type={'14-R'} color={'White'} className={'w-full'} key={person.id}>
            {generateName(person)}
          </Typography>
        ))}
      {remainingPersonsCount > 0 && <Typography color={'White'}>...</Typography>}
    </div>
  );
};
