import React from 'react';

import { IIcon } from '@/types/icon';

export const EyeIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 18.25C7.87847 18.25 4.32713 15.6818 2.80612 11.9983C4.27536 8.40628 7.88228 5.75 12 5.75C16.1177 5.75 19.7246 8.40623 21.1938 11.9982C19.6729 15.6818 16.1215 18.25 12 18.25ZM22.7022 11.7367C22.7701 11.9176 22.7635 12.1082 22.6978 12.2749C21.0309 16.6405 16.8776 19.75 12 19.75C7.11939 19.75 2.96399 16.6368 1.2991 12.267C1.23029 12.0864 1.23588 11.8957 1.30073 11.7287C2.90586 7.45831 7.12107 4.25 12 4.25C16.882 4.25 21.0994 7.46229 22.7022 11.7367ZM8.75 12C8.75 10.2051 10.2051 8.75 12 8.75C13.7949 8.75 15.25 10.2051 15.25 12C15.25 13.7949 13.7949 15.25 12 15.25C10.2051 15.25 8.75 13.7949 8.75 12ZM12 7.25C9.37665 7.25 7.25 9.37665 7.25 12C7.25 14.6234 9.37665 16.75 12 16.75C14.6234 16.75 16.75 14.6234 16.75 12C16.75 9.37665 14.6234 7.25 12 7.25Z'
      fill='currentColor'
    />
  </svg>
);
