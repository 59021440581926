import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { useActivityPlaningSelector } from '@/components/functions/ActivityPlanning/FullFunction/ActivityPlanningContext/ActivityPlanningContext';
import { appointmentDetailsSchemaBuilder } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/hooks/appointmentDetailsValidator';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsSelectors } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/selectors';
import {
  activityPlanningAppointmentDetailsActions,
  IAppointmentDetails,
  IAppointmentMeasureAid,
} from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';

export interface IAppointmentDetailsForm {
  appointmentOnlyPlanned?: boolean | null;
  startDate?: string | null;
  endDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  measureTitle?: string | null;
  locationID?: number | null;
  locationName?: string | null;
  appointmentTypeID?: number | null;
  implementationAids?: Record<string, boolean> | null;
  appointmentInfo?: string | null;
  places?: number | null;
  responsible?: string | null;
  duration?: number | null;
}

const mapSelectedImplementationAids = (
  aids?: IAppointmentMeasureAid[] | null
): Record<string, boolean> => {
  if (!aids) {
    return {};
  }

  return aids.reduce<Record<string, boolean>>(
    (accum, aid) => ({
      ...accum,
      [aid.id]: true,
    }),
    {}
  );
};

const mapAppointmentDetailsToForm = (
  appointmentDetails: IAppointmentDetails
): IAppointmentDetailsForm => {
  return {
    appointmentInfo: appointmentDetails.appointmentInfo,
    appointmentTypeID: appointmentDetails.appointmentType?.id,
    endDate: appointmentDetails.appointmentEndDate,
    endTime: appointmentDetails.appointmentEndTime,
    places: appointmentDetails.places,
    measureTitle: appointmentDetails.measureTitle,
    responsible: appointmentDetails.responsibleUser,
    startDate: appointmentDetails.appointmentStartDate,
    startTime: appointmentDetails.appointmentStartTime,
    appointmentOnlyPlanned: false, // todo
    locationID: -1,
    locationName: appointmentDetails.location,
    implementationAids: mapSelectedImplementationAids(appointmentDetails.implementationAids),
    duration: Number(appointmentDetails.hours),
  };
};

export function useAppointmentDetailsForm() {
  const { t: appointmentDetailTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentDetails',
  });
  const [appointmentDetailsContext, setAppointmentDetailsContext] = useState<'create' | 'edit'>(
    'edit'
  );

  const { selectedPersonID } = useSelectedPerson();
  const [selectedAppointmentID] = useActivityPlaningSelector(
    (store) => store.selectedAppointmentID
  );
  const [selectedMeasureID] = useActivityPlaningSelector((store) => store.selectedMeasureID);
  const [selectedMeasureName] = useActivityPlaningSelector((store) => store.selectedMeasureName);
  const [personLocation] = useActivityPlaningSelector((store) => store.personLocation);
  const [, setIsActionBlocked] = useActivityPlaningSelector((store) => store.isActionsBlocked);

  const dispatch = useAppDispatch();

  const appointmentDetails = useAppSelector(
    activityPlaningAppointmentDetailsSelectors.appointmentDetails
  );

  const form = useForm<IAppointmentDetailsForm>({
    resolver: yupResolver(appointmentDetailsSchemaBuilder(appointmentDetailTranslations)),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const isStartEndSameDate = form.getValues('startDate') === form.getValues('endDate');

  useEffect(() => {
    if (!selectedPersonID || !selectedAppointmentID || !selectedMeasureID) {
      dispatch(activityPlanningAppointmentDetailsActions.setAppointmentDetails(null));
      return;
    }

    dispatch(
      activityPlanningAppointmentDetailsActions.fetchAppointmentDetails({
        appointmentID: selectedAppointmentID,
        personID: selectedPersonID,
        measureID: selectedMeasureID,
      })
    );
  }, [selectedPersonID, selectedAppointmentID, selectedMeasureID]);

  useEffect(() => {
    if (!selectedMeasureID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentDetailsActions.fetchAppointmentDetailsFields({
        measureID: selectedMeasureID,
      })
    );

    return () => {
      dispatch(activityPlanningAppointmentDetailsActions.setAppointmentDetails(null));
    };
  }, [selectedMeasureID]);

  useEffect(() => {
    if (!appointmentDetails || appointmentDetailsContext === 'create') {
      return;
    }

    const formValues = mapAppointmentDetailsToForm(appointmentDetails);
    form.reset(formValues);
  }, [appointmentDetails, appointmentDetailsContext, selectedAppointmentID]);

  const createAppointment = () => {
    setAppointmentDetailsContext('create');
    setIsActionBlocked({ isActionsBlocked: true });

    const newFormValues: IAppointmentDetailsForm = {
      implementationAids: {},
      startTime: null,
      startDate: null,
      responsible: null,
      places: null,
      endTime: null,
      endDate: null,
      appointmentTypeID: null,
      appointmentOnlyPlanned: null,
      appointmentInfo: null,
      duration: null,
      locationID: personLocation ? personLocation.id : undefined,
      measureTitle: selectedMeasureName ? selectedMeasureName : undefined,
    };

    form.reset(newFormValues);
  };

  useEffect(() => {
    if (!appointmentDetails || appointmentDetailsContext === 'create') {
      return;
    }

    const formValues = mapAppointmentDetailsToForm(appointmentDetails);

    form.setValue('duration', formValues.duration, { shouldDirty: false });
    form.setValue('startTime', formValues.startTime, { shouldDirty: false });
    form.setValue('endTime', formValues.endTime, { shouldDirty: false });
  }, [isStartEndSameDate]);

  form.clearErrors();

  const handleSubmit = (data: IAppointmentDetailsForm) => {
    if (!selectedPersonID || !selectedMeasureID) {
      return;
    }

    if (appointmentDetailsContext === 'create') {
      dispatch(
        activityPlanningAppointmentDetailsActions.createAppointment({
          formValues: data,
          personID: selectedPersonID,
          measureID: selectedMeasureID,
        })
      );

      setAppointmentDetailsContext('edit');
      setIsActionBlocked({ isActionsBlocked: false });
      return;
    }

    if (!selectedAppointmentID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentDetailsActions.updateAppointment({
        formValues: data,
        personID: selectedPersonID,
        measureID: selectedMeasureID,
        appointmentID: selectedAppointmentID,
      })
    );

    form.reset(data);

    return;
  };

  return {
    form,
    appointmentDetails,
    createAppointment,
    appointmentDetailsContext,
    setAppointmentDetailsContext,
    handleSubmit,
    isStartEndSameDate,
  };
}
