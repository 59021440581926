import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import FormRender from '@/components/FormRender/FormRender';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionFooter } from '@/components/wrapper/FunctionWrapper/FunctionFooter/FunctionFooter';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';

import { firstColumn, secondColumn } from './helpers/formTemplates';

export const LegalGuardian: React.FC = () => {
  const { t: legalGuardianTranslations } = useTranslation('legalGuardian');

  const form = useForm<any>();

  const checkboxValues = ['Option 1', 'Option 2', 'Option 3'];
  const selectAllChecked = form.watch('all');

  useEffect(() => {
    checkboxValues.forEach((option) => {
      form.setValue(`checkboxes.${option}`, selectAllChecked);
    });
  }, [selectAllChecked]);

  const onSubmit = (data: any) => console.log(data);

  return (
    <FunctionWrapper>
      <FunctionContent className={'p-5'} withFooter={true}>
        <Typography type='H3'>{legalGuardianTranslations('title.label')}</Typography>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className='flex flex-col items-start gap-4 w-full'
          >
            <div className='flex gap-6 grow'>
              <FormRender list={firstColumn} translationContext={legalGuardianTranslations} />
              <FormRender list={secondColumn} translationContext={legalGuardianTranslations} />
            </div>
            <div className='w-full'>
              <CheckboxItem
                label={legalGuardianTranslations('fields.selectAll.label')}
                fieldName={`all`}
                className='mb-2'
              />
              <div className='grid grid-cols-2 gap-4'>
                {checkboxValues.map((option) => (
                  <div key={option}>
                    <CheckboxItem label={option} fieldName={`checkboxes.${option}`} />
                    <div className='min-h-[82px]'>
                      {form.watch(`checkboxes.${option}`) && (
                        <TextArea control={form.control.register(`textareas.${option}`)} />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </FormProvider>
      </FunctionContent>
      <FunctionFooter>
        <Button onClick={() => form.handleSubmit(onSubmit)()}>
          {legalGuardianTranslations('buttons.submit')}
        </Button>
      </FunctionFooter>
    </FunctionWrapper>
  );
};
