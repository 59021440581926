import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { initializeApollo } from '@/core/clients/apollo';
import { mixinWithErrorHandler, mixinWithLoading } from '@/core/redux/sagas';
import {
  functionPageActions,
  IUpdateFunctionOrderNumberPayload,
  IUpdateFunctionType,
  IUpdatePinnedPayload,
} from '@/core/redux/slices/functionPage/functionPageSlice';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { integrationPlanActions } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import { augmentSaga } from '@/core/redux/utils';
import { getFunctionType } from '@/core/utils/functionsUtils';
import {
  GetFunctionByIdDocument,
  GetFunctionByIdQuery,
  GetFunctionByIdQueryHookResult,
  GetSettingsDocument,
  GetSettingsQuery,
  GetSettingsQueryHookResult,
  UpdateFunctionOrderNumberDocument,
  UpdateFunctionOrderNumberMutation,
  UpdateFunctionTypeDocument,
  UpdateFunctionTypeMutation,
  UpdatePinnedDocument,
} from '@/services/graphql/base/graphql';
import { FunctionTypes, IFunctionItem } from '@/types/functions';
import { LoadingStatus } from '@/types/loadingStatus';

function updateFunctionOrder(functions: IFunctionItem[], targetID: string): IFunctionItem[] {
  return functions
    .map((func) => {
      if (func.id === targetID) {
        return { ...func, orderNumber: -1 };
      }
      return func;
    })
    .sort((a, b) => a.orderNumber - b.orderNumber)
    .map((func, index) => ({ ...func, orderNumber: index + 1 }));
}

function* fetchFunctions(): Generator<any, void, GetSettingsQueryHookResult> {
  const client = initializeApollo();

  yield put(functionPageActions.setFunctionsLock(LoadingStatus.LOADING));

  try {
    const response = yield client.query<GetSettingsQuery>({
      query: GetSettingsDocument,
    });

    const filteredResponse = response.data?.settings?.filter(
      (element) => element.functions && element.functions.length > 0
    );

    const functions: IFunctionItem[] = [];

    if (filteredResponse && filteredResponse[0].functions) {
      filteredResponse[0].functions.forEach((item) => {
        if (item) {
          functions.push({
            id: item.id,
            functionID: item.functions_id ? item.functions_id.id : '',
            isPinned: item.is_pinned || false,
            name: item.functions_id ? item.functions_id.name : '',
            iconUrl: item.functions_id?.icon?.filename_disk || '',
            functionType: item.function_type_id
              ? getFunctionType(item.function_type_id.type)
              : FunctionTypes.none,
            orderNumber: item.order_number ?? 0,
          });
        }
      });
    }

    yield put(functionPageActions.setFunctions(functions));
    yield put(functionPageActions.setFunctionsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on functions list fetching', error);
    yield put(functionPageActions.setFunctionsLock(LoadingStatus.LOADED));
  }
}

function* updatePinned(action: PayloadAction<IUpdatePinnedPayload>): Generator<any, void, any> {
  const { payload } = action;

  try {
    yield put(functionPageActions.setPinFunctionLock(LoadingStatus.LOADING));
    const client = initializeApollo();

    const functions = yield select(functionPageSelectors.functions);
    const sortedFunctions = updateFunctionOrder(functions, payload.id);

    yield call([client, client.mutate], {
      mutation: UpdatePinnedDocument,
      variables: {
        id: payload.id,
        is_pinned: payload.is_pinned,
      },
    });
    yield put(functionPageActions.updateFunctionOrderNumber({ functions: sortedFunctions }));

    yield put(functionPageActions.setFunctions(sortedFunctions));
    yield put(functionPageActions.setPinFunctionLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on function pin updating', error);
    yield put(functionPageActions.setPinFunctionLock(LoadingStatus.ERROR));
  }
}

function* updateFunctionType(
  action: PayloadAction<IUpdateFunctionType>
): Generator<any, void, any> {
  const { payload } = action;

  const client = initializeApollo();

  yield client.mutate<UpdateFunctionTypeMutation>({
    mutation: UpdateFunctionTypeDocument,
    variables: {
      id: payload.id,
      function_type: payload.functionType === 'none' ? null : { id: payload.functionType },
    },
  });

  yield call(fetchFunctions);
}

function* updateFunctionOrderNumber(
  action: PayloadAction<IUpdateFunctionOrderNumberPayload>
): Generator<any, void, any> {
  const { payload } = action;

  const client = initializeApollo();

  for (const [index, func] of Object.entries(payload.functions || [])) {
    yield client.mutate<UpdateFunctionOrderNumberMutation>({
      mutation: UpdateFunctionOrderNumberDocument,
      variables: {
        id: func.id,
        order_number: index,
      },
    });
  }
}

function* setSelectedPerson() {
  yield put(integrationPlanActions.setIntegrationPlanID(null));
  yield put(integrationPlanActions.setIntegrationPlanToInitial());
}

function* addShortcutTab(
  action: PayloadAction<any>
): Generator<any, any, GetFunctionByIdQueryHookResult> {
  const { payload } = action;

  const { personID, tabID, functionID } = payload;

  yield put(
    functionPageActions.setAddShortcutTabLock({
      status: LoadingStatus.LOADING,
      data: null,
    })
  );

  const client = initializeApollo();

  const response = yield client.query<GetFunctionByIdQuery>({
    query: GetFunctionByIdDocument,
    variables: {
      functionID: functionID,
    },
  });

  const selectedShortcutFunction = response.data?.settings?.[0].functions?.[0]?.functions_id;

  if (!selectedShortcutFunction) {
    return;
  }

  yield put(
    functionPageActions.addTab({
      userId: personID,
      tabId: tabID,
      tabData: {
        title: selectedShortcutFunction.name,
        functionItem: {
          name: selectedShortcutFunction.name,
          id: String(selectedShortcutFunction.id),
          iconUrl: selectedShortcutFunction.icon?.filename_disk || '',
        },
      },
    })
  );

  yield put(
    functionPageActions.setAddShortcutTabLock({
      status: LoadingStatus.LOADED,
      data: {
        personID: personID,
        tabID: tabID,
      },
    })
  );

  return tabID;
}

export const functionPageSagas = [
  takeLatest(
    functionPageActions.fetchFunctions,
    augmentSaga(
      fetchFunctions,
      mixinWithErrorHandler('', functionPageActions.setFunctionsLock),
      mixinWithLoading(functionPageActions.setFunctionsLock)
    )
  ),
  takeLatest(functionPageActions.updatePinned, updatePinned),
  takeEvery(functionPageActions.updateFunctionType, updateFunctionType),
  takeEvery(functionPageActions.updateFunctionOrderNumber, updateFunctionOrderNumber),
  takeLatest(functionPageActions.setSelectedPerson, setSelectedPerson),
  takeLatest(functionPageActions.addShortcutTab, addShortcutTab),
]