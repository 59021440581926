import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAdministrationChecklistSelector } from '@/components/administartionFunctions/checklist/AdministrationChecklistContext/AdministrationChecklistContext';
import { Typography } from '@/components/Typography/Typography';

export const AdministrationChecklistSelectChecklistPlaceholder: React.FC = () => {
  const { t: administrationChecklistTranslations } = useTranslation('administrationChecklist');

  const [selectedChecklist] = useAdministrationChecklistSelector(
    (state) => state.selectedChecklist
  );
  const [selectedChecklistGroup] = useAdministrationChecklistSelector(
    (state) => state.selectedChecklistGroup
  );

  return !selectedChecklist && selectedChecklistGroup ? (
    <div className={'flex-grow h-full flex flex-col items-center justify-center'}>
      <Typography>
        {administrationChecklistTranslations('placeholder.selectChecklist.description.label')}
      </Typography>
    </div>
  ) : null;
};
