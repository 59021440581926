import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { ArrowIcon } from '@/components/Icons/ArrowIcon';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

export interface IAccordionHeader {
  isExpanded?: boolean;
  setIsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  title?: React.ReactNode;
}

interface IAccordion extends Omit<IAccordionHeader, 'isExpanded | setIsExpanded'> {
  children: React.ReactNode;
  customHeader?: React.FC<IAccordionHeader>;
  isExpanded?: boolean;
  onClick?: () => void;
  onAnimationComplete?: () => void;
  initialIsExpanded?: boolean;
  setIsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  headerClassName?: string;
}

export const Accordion: React.FC<IAccordion> = ({
  title,
  children,
  setIsExpanded,
  className,
  headerClassName,
  onClick,
  onAnimationComplete,
  customHeader: CustomHeader,
  isExpanded = false,
  initialIsExpanded = false,
}) => {
  const [isExpandedInternal, setIsExpandedInternal] = useState<boolean>(initialIsExpanded);

  const expanded = isExpanded || isExpandedInternal;
  const setExpanded = setIsExpanded || setIsExpandedInternal;

  const handleExpand = (event: React.MouseEvent) => {
    event.preventDefault();

    onClick?.();
    setExpanded(!expanded);
  };

  return (
    <div className={twMerge('flex flex-col', className)}>
      {CustomHeader ? (
        <CustomHeader title={title} isExpanded={expanded} setIsExpanded={setExpanded} />
      ) : (
        <Button
          buttonStyle={'Text'}
          buttonVariant={'Transparent'}
          onClick={handleExpand}
          className={twMerge('flex flex-row justify-between items-center', headerClassName)}
        >
          {title}
          <motion.div
            className={`cursor-pointer text-${colorTypes.Blue}`}
            animate={{ rotate: isExpandedInternal ? 180 : 0 }}
          >
            <ArrowIcon />
          </motion.div>
        </Button>
      )}
      <motion.div
        className={twMerge('flex flex-col w-full overflow-y-hidden')}
        initial={{ height: 0, opacity: 0 }}
        animate={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
        onAnimationComplete={onAnimationComplete}
      >
        {children}
      </motion.div>
    </div>
  );
};
