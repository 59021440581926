import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { smartGoalsActions } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { smartGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/smartGoalsModalSlice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IMeasuresAvailableView {
  smartGoalID: number;
  competenceID: number;
}

export const MeasuresAvailableView: React.FC<IMeasuresAvailableView> = ({
  smartGoalID,
  competenceID,
}) => {
  const dispatch = useAppDispatch();
  const { t: addMeasuresModalTranslations } = useTranslation('addMeasuresModal');

  const measuresAvailable = useAppSelector(smartGoalsSelectors.measuresAvailable);
  const updateMeasureLock = useAppSelector(smartGoalsSelectors.updateMeasureLock);

  useEffect(() => {
    dispatch(
      smartGoalsActions.fetchAvailableMeasures({
        smartGoalID: smartGoalID,
        competenceID: competenceID,
      })
    );
  }, []);

  const handleChangeMeasure = (id: number, isAdded: boolean) => {
    if (isAdded) {
      dispatch(
        smartGoalsModalActions.openConfirmUnpinActivityModal({
          smartGoalID: smartGoalID,
          measureID: id,
        })
      );
    } else {
      dispatch(
        smartGoalsActions.addMeasure({
          measureID: id,
          smartGoalID: smartGoalID,
        })
      );
    }
  };

  useEffect(() => {
    if (updateMeasureLock === LoadingStatus.LOADED) {
      dispatch(
        smartGoalsActions.fetchAvailableMeasures({
          smartGoalID: smartGoalID,
          competenceID: competenceID,
        })
      );
    }
  }, [updateMeasureLock]);

  useEffect(() => {
    return () => {
      dispatch(smartGoalsActions.setAvailableMeasures([]));
    };
  }, []);

  return (
    <div>
      {measuresAvailable.map(({ id, isAdded, hasAppointments, name }) => {
        const isDisabled = hasAppointments && isAdded;
        return (
          <div key={id}>
            <div
              className={twMerge(
                'flex gap-4 items-center py-3 border-stroke-light border-b px-6',
                !isDisabled && 'cursor-pointer'
              )}
              onClick={() => {
                if (isDisabled) {
                  return;
                }

                handleChangeMeasure(id, isAdded);
              }}
            >
              <div
                data-tooltip-id={String(id)}
                data-tooltip-content={addMeasuresModalTranslations('measures.tooltip.label')}
              >
                <CheckboxUI checked={isAdded} readOnly disabled={isDisabled} />
              </div>

              <Typography type='16-R'>{name}</Typography>
            </div>
            {isDisabled && <Tooltip id={String(id)} style={{ zIndex: 100000 }} />}
          </div>
        );
      })}
    </div>
  );
};
