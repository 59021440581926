import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.modals.functions.activityPlaningModals.serialAppointmentModals;

const serialAppointmentModal = createSelector([selector], (state) => state.serialAppointmentModal);

export const serialAppointmentModalsSelectors = {
  serialAppointmentModal,
};
