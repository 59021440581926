import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import {
  IDisabilityCharacteristicsForm,
  useDisabilityCharacteristicsForm,
} from '@/components/functions/MedicalInformation/DisabilityCharacteristics/helpers/useDisabilityCharacteristicsForm';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';
import { disabilityCharacteristicsSelectors } from '@/core/redux/slices/functions/medicalInformation/disabilityCharacteristics/selectors';
import { disabilityCharacteristicsActions } from '@/core/redux/slices/functions/medicalInformation/disabilityCharacteristics/slice';
import { getFirstFormError } from '@/core/utils/formUtils';
import { LoadingStatus } from '@/types/loadingStatus';

export const DisabilityCharacteristics: React.FC = () => {
  const { t: disabilityCharacteristicsTranslations } = useTranslation('disabilityCharacteristics');

  const disabilityCharacteristics = useAppSelector(
    disabilityCharacteristicsSelectors.disabilityCharacteristics
  );
  const disabilityCharacteristicsLock = useAppSelector(
    disabilityCharacteristicsSelectors.disabilityCharacteristicsLock
  );
  const updateLock = useAppSelector(disabilityCharacteristicsSelectors.updateLock);

  const dropDowns = useAppSelector(dropdownItemsSelectors.dropdownItems);

  const { selectedPersonID } = useSelectedPerson();
  const dispatch = useAppDispatch();

  const { form, defaultValues } = useDisabilityCharacteristicsForm();

  const firstError = getFirstFormError(form.formState.errors);

  const identityCardAuthoritiesOptions = dropDowns?.[DropdownItemsByFetch.IDENTITY_CARD_AUTHORITIES]
    ? dropDowns[DropdownItemsByFetch.IDENTITY_CARD_AUTHORITIES].map((option) => ({
        id: String(option.id),
        name: option.value,
      }))
    : [];

  const isDisabled = form.watch('isDisabledPass');

  const disabilityCharacteristicsForm: (
    | FormItem<IDisabilityCharacteristicsForm>
    | FormGroup<IDisabilityCharacteristicsForm>
  )[] = [
    {
      className: 'flex-row-reverse justify-end',
      type: 'checkbox',
      name: 'isDisabledPass',
      label: disabilityCharacteristicsTranslations('fields.isDisabledPass.label'),
    },
    {
      condition: isDisabled,
      className: 'w-full',
      fieldsContainerClassName: 'grid grid-cols-2 gap-3',
      fields: [
        {
          fieldsContainerClassName: 'grid grid-cols-2 gap-3',
          fields: [
            {
              className: 'flex-row-reverse justify-end',
              type: 'checkbox',
              name: 'isUnlimited',
              label: disabilityCharacteristicsTranslations('fields.isUnlimited.label'),
              checkBoxOptions: {
                onClick: (value: boolean) => {
                  if (value) {
                    form.setValue('validDate', null, { shouldDirty: true });
                  }

                  form.setValue('isUnlimited', value, { shouldDirty: true });
                },
              },
            },
            {
              type: 'datepicker',
              name: 'validDate',
              label: disabilityCharacteristicsTranslations('fields.validDate.label'),
              datePickerOptions: {
                onChange: (date: Date) => {
                  if (date) {
                    form.setValue('isUnlimited', false, { shouldDirty: true });
                  }
                },
              },
            },
          ],
        },
        {
          fieldsContainerClassName: 'flex flex-col gap-3',
          fields: [
            {
              type: 'select',
              options: identityCardAuthoritiesOptions,
              name: 'identityCardAuthoritiesID',
              label: disabilityCharacteristicsTranslations('fields.identityCardAuthorities.label'),
            },
            {
              type: 'textarea',
              options: [],
              name: 'referenceNumber',
              label: disabilityCharacteristicsTranslations('fields.referenceNumber.label'),
            },
          ],
        },
      ],
    },
    {
      condition: Boolean(firstError),
      type: 'custom',
      name: undefined,
      customComponent: <Typography color={'Error'}>{firstError}</Typography>,
    },
    {
      fieldsContainerClassName: 'grid grid-cols-5 gap-3 items-center',
      fields: disabilityCharacteristics
        ? disabilityCharacteristics.characteristics.map<
            FormItem<IDisabilityCharacteristicsForm> | FormGroup<IDisabilityCharacteristicsForm>
          >((characteristic) => ({
            className: 'flex flex-row gap-3',
            isReversed: true,
            name: `characteristics.${String(characteristic.id)}`,
            type: 'checkbox',
            label: characteristic.name || ``,
          }))
        : [],
    },
  ];

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      disabilityCharacteristicsActions.fetchDisabilityCharacteristics({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID]);

  const onSubmit = (data: IDisabilityCharacteristicsForm) => {
    if (!selectedPersonID) {
      return;
    }

    const characteristicsKeys = Object.keys(data.characteristics);

    const blankValues: IDisabilityCharacteristicsForm = {
      ...defaultValues,
      characteristics: characteristicsKeys.reduce(
        (accum, key) => ({
          ...accum,
          [key]: false,
        }),
        {}
      ),
    };

    const formValues = data.isDisabledPass ? data : blankValues;

    form.reset(formValues, { keepDirty: false });

    dispatch(
      disabilityCharacteristicsActions.updateDisabilityCharacteristics({
        personID: selectedPersonID,
        formValues: formValues,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(disabilityCharacteristicsActions.setDisabilityCharacteristics(null));
      form.reset(defaultValues);
    };
  }, []);

  return (
    <div className={'flex flex-col h-full gap-3'}>
      <Typography type={'H3'}>{disabilityCharacteristicsTranslations('title.label')}</Typography>
      <Skeleton trigger={disabilityCharacteristicsLock === LoadingStatus.LOADING}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={'flex flex-col h-full gap-3'}>
            <div className={'flex-1'}>
              <FormRender list={disabilityCharacteristicsForm} />
            </div>
            {form.formState.isDirty && (
              <div className={'flex flex-row gap-3 justify-start'}>
                <Button type={'submit'}>
                  {updateLock === LoadingStatus.LOADING ? (
                    <SpinnerIcon className={'w-[24px] h-[24px]'} />
                  ) : (
                    disabilityCharacteristicsTranslations('buttons.save.label')
                  )}
                </Button>
                <Button buttonVariant={'Secondary'} onClick={() => form.reset()}>
                  {disabilityCharacteristicsTranslations('buttons.cancel.label')}
                </Button>
              </div>
            )}
          </form>
        </FormProvider>
      </Skeleton>
    </div>
  );
};
