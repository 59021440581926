import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface IFunctionContent {
  withFooter?: boolean;
  className?: string;
}

export const FunctionContent: React.FC<PropsWithChildren<IFunctionContent>> = ({
  children,
  className,
  withFooter = false,
}) => {
  return (
    <div className='flex-grow h-full'>
      <div
        className={twMerge(
          `overflow-y-auto`,
          withFooter ? 'h-[calc(100vh_-_365px)]' : 'h-[calc(100vh_-_300px)]',
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};
