import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { CircleFilledIcon } from '@/components/Icons/CircleFilledIcon';
import { CircleOutlineIcon } from '@/components/Icons/CircleOutlineIcon';
import {
  CompleteMeasureGroup,
  DEFAULT_LAYER_PADDING,
} from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureList/CompleteMeasureGroup';
import { ICompleteMeasureFormRootGroup } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/hooks/useCompleteMeasureModalForm';
import { Typography } from '@/components/Typography/Typography';
import { IRootMeasureGroup } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface ICompleteMeasureRootGroup extends IRootMeasureGroup {
  formPath: string;
  layer: number;
}

export const CompleteMeasureRootGroup: React.FC<ICompleteMeasureRootGroup> = ({
  formPath,
  layer,
  name,
  surname,
  groups,
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const { getValues } = useFormContext();
  const currentValues: ICompleteMeasureFormRootGroup = getValues(formPath);

  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };
  return (
    <div className={'col-span-12 grid grid-cols-12'}>
      <Button
        buttonVariant={'Transparent'}
        buttonStyle={'Text'}
        onClick={handleExpand}
        className={`col-span-12 hover:bg-${colorTypes.Hover}`}
      >
        <Typography
          style={{ paddingLeft: DEFAULT_LAYER_PADDING * layer }}
          type={'16-M'}
          className={'flex flex-row items-center gap-2 py-1'}
        >
          {expanded ? (
            <CircleFilledIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
          ) : (
            <CircleOutlineIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
          )}
          {name} {surname}
        </Typography>
      </Button>
      <motion.div
        className={twMerge(`col-span-12 grid grid-cols-12 w-full overflow-hidden`)}
        initial={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
        animate={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
      >
        <div className={'col-span-12 grid grid-cols-12'}>
          {groups &&
            groups.map((group) => {
              const foundGroupIndex = currentValues?.groups
                ? currentValues.groups.findIndex((_group) => _group.id === group.id)
                : -1;

              const nestedFormPath = `${formPath}.groups.${foundGroupIndex}`;

              return (
                <CompleteMeasureGroup
                  appointments={group.appointments}
                  id={group.id}
                  name={group.name}
                  layer={1}
                  items={group.items}
                  key={group.id}
                  formPath={nestedFormPath}
                />
              );
            })}
        </div>
      </motion.div>
    </div>
  );
};
