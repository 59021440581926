import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Input } from '@/components/Input/Input';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';

export interface IBlockItem {
  title: string;
  fieldName: string;
}

export const ReviewItem: React.FC<IBlockItem> = ({ title, fieldName }) => {
  const { control, setValue } = useFormContext();

  const ratingFieldName = `${fieldName}Rating`;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case '0': {
        setValue(ratingFieldName, '0');
        break;
      }
      case '1': {
        setValue(ratingFieldName, '1');
        break;
      }
      case '2': {
        setValue(ratingFieldName, '2');
        break;
      }
      case '3': {
        setValue(ratingFieldName, '3');
        break;
      }
      case '4': {
        setValue(ratingFieldName, '4');
        break;
      }
      case 'Backspace': {
        setValue(ratingFieldName, '');
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={`grid grid-cols-[repeat(20,_minmax(0,_1fr))] gap-x-4 items-start`}>
      <Typography className={'col-span-6'}>{title}</Typography>
      <Input
        control={control.register(ratingFieldName)}
        className={'text-center h-full'}
        containerClassName={'h-full col-span-2'}
        wrapperClassName={'h-full'}
        resetButton={false}
        preventDefault={true}
        onKeyDown={handleKeyDown}
      />
      <TextArea
        control={control.register(`${fieldName}Comment`)}
        containerClassName={'col-span-12'}
        resetButton={true}
      />
    </div>
  );
};
