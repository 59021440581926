import { useEffect } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

interface SelectedPersonFormParams<T extends FieldValues> {
  form: UseFormReturn<T, any, undefined>;
  integrationPlanID: number | null;
  initialValues: T | null;
}

export function useSelectedPersonForm<T extends FieldValues>({
  form,
  integrationPlanID,
  initialValues,
}: SelectedPersonFormParams<T>) {
  useEffect(() => {
    if (!integrationPlanID) {
      form.reset();
      return;
    }

    if (!initialValues) {
      return;
    }

    form.reset(initialValues);
  }, [initialValues, integrationPlanID]);
}
