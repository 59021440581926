import React from 'react';

import { Accordion } from '@/components/Accordion/Accordion';
import { FavoritePerson } from '@/components/widgets/PersonWidget/FavoritePerson/FavoritePerson';
import { IFavoritePersonsGroup } from '@/core/redux/slices/dashboard/dashboardSlice';
import { colorTypes, textTypes } from '@/styles/types';

export const FavoritePersonsGroup: React.FC<IFavoritePersonsGroup> = ({ persons, id, name }) => {
  return (
    <Accordion
      title={name}
      headerClassName={`p-2 border-b border-${colorTypes.Orange} hover:border-${colorTypes.Blue} ${textTypes['16-Sb']} mb-2`}
      initialIsExpanded={true}
    >
      {persons.map((person) => (
        <FavoritePerson {...person} key={person.id} />
      ))}
    </Accordion>
  );
};
