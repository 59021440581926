import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Typography } from '@/components/Typography/Typography';

import { ArrowRightIcon } from '../Icons/ArrowRightIcon';

interface BreadcrumbsProps {
  items: { text: string; link: string }[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  const location = useLocation();
  return (
    <nav aria-label='breadcrumb'>
      <ol className='flex flex-row'>
        {items.map((item) => {
          const isActive = location.pathname === item.link;
          return (
            <li
              key={item.link}
              className={`flex flex-row items-center ${
                isActive ? 'cursor-default' : 'cursor-pointer'
              }`}
            >
              {isActive ? (
                <Typography color='Secondary' type={'14-M'}>
                  {item.text}
                </Typography>
              ) : (
                <>
                  <NavLink to={item.link}>
                    <Typography color='Primary' type={'14-M'}>
                      {item.text}
                    </Typography>
                  </NavLink>
                  <div className='mr-[8px] ml-[8px]'>
                    <ArrowRightIcon />
                  </div>
                </>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
