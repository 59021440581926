import React from 'react';

import { IIcon } from '@/types/icon';

export const DownloadIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path d='M12 15V3m0 12l-4-4m4 4l4-4' />
    <path d='M2 17l.621 2.485A2 2 0 0 0 4.561 21H19.439a2 2 0 0 0 1.94-1.515L22 17' />
  </svg>
);
