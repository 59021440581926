import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';
import { applyTimeStampMask } from '@/components/WorkingTimePlanner/WorkingTimePlannerContainer/WorkingTimePlannerContainerDaysList/WorkingTimePlannerContainerDay/helpers/applyTimeStampMask';
import { IWorkingTimeDayItem } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';

interface IWorkingTimePlannerContainerDay {
  day: IWorkingTimeDayItem;
}

export const WorkingTimePlannerContainerDay: React.FC<IWorkingTimePlannerContainerDay> = ({
  day,
}) => {
  const { t: workingTimePlannerTranslations } = useTranslation('workingTimePlanner', {
    keyPrefix: 'daysList.days',
  });

  const formContext = useFormContext();

  const getDayName = useCallback(
    (day: IWorkingTimeDayItem) => {
      switch (day.name) {
        case 'Montag':
          return workingTimePlannerTranslations('monday.label');
        case 'Dienstag':
          return workingTimePlannerTranslations('tuesday.label');
        case 'Mittwoch':
          return workingTimePlannerTranslations('wednesday.label');
        case 'Donnerstag':
          return workingTimePlannerTranslations('thursday.label');
        case 'Freitag':
          return workingTimePlannerTranslations('friday.label');
        case 'Samstag':
          return workingTimePlannerTranslations('saturday.label');
        case 'Sonntag':
          return workingTimePlannerTranslations('sunday.label');
        default:
          day.name;
      }
    },
    [day, workingTimePlannerTranslations]
  );

  const basePath = `workingWeek.workingDays.${day.id}`;
  const fromTimeStampFieldName = `${basePath}.fromDateStamp`;
  const toTimeStampFieldName = `${basePath}.toDateStamp`;
  const pauseDurationFieldName = `${basePath}.pauseDuration`;

  const fillEmptyDigits = (fieldName: string) => {
    const value: string | null | undefined = formContext.getValues(fieldName);

    if (!value) {
      return;
    }

    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length > 0 && numericValue.length < 4) {
      const diff = 4 - numericValue.length;
      const zeros = '0'.repeat(diff);
      formContext.setValue(fieldName, applyTimeStampMask(value, numericValue + zeros), {
        shouldValidate: true,
      });
    }

    setTimeout(() => {
      formContext.trigger();
    }, 100);
  };

  const parseNumbers = (previousValue: string, newValue: string) => {
    return newValue.replace(/\D/g, '');
  };

  const handleResetDay = () => {
    const defaultDay = {
      fromDateStamp: null,
      toDateStamp: null,
      pauseDuration: null,
    };

    formContext.setValue(basePath, defaultDay, { shouldDirty: true });
    formContext.trigger();
  };

  return (
    <>
      <Typography className={'col-span-5 justify-self-start'}>{getDayName(day)}</Typography>
      <Input
        control={formContext.register(fromTimeStampFieldName)}
        inputVariant={'Outline'}
        onBlur={() => fillEmptyDigits(fromTimeStampFieldName)}
        handleChange={applyTimeStampMask}
        className={'text-center'}
        containerClassName={'col-span-2'}
      />
      <Input
        control={formContext.register(toTimeStampFieldName)}
        inputVariant={'Outline'}
        onBlur={() => fillEmptyDigits(toTimeStampFieldName)}
        handleChange={applyTimeStampMask}
        className={'text-center'}
        containerClassName={'col-span-2'}
      />
      <Input
        control={formContext.register(pauseDurationFieldName)}
        inputVariant={'Outline'}
        className={'text-center'}
        containerClassName={'col-span-2'}
        type={'number'}
        handleChange={parseNumbers}
      />
      <Button buttonStyle={'Icon'} buttonVariant={'Icon'} onClick={handleResetDay}>
        <CrossIcon />
      </Button>
    </>
  );
};
