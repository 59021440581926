import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.medicationModals;

const displayMedicationModal = createSelector([selector], (state) => state.displayMedicationModal);
const stopAdministratingModal = createSelector(
  [selector],
  (state) => state.stopAdministratingModal
);

export const medicationModalsSelectors = {
  displayMedicationModal,
  stopAdministratingModal,
};
