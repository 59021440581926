import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateSelfAssessmentAnswerMutationVariables,
  UpdateSelfAssessmentAnswerMutationVariables,
} from '@/services/graphql/base/graphql';

export interface IModal {
  isRender: boolean;
}

export interface IStartQuestionnairePayload {
  m2sID: string;
}

export interface ISimpleQuestionnaireModalState {
  simpleQuestionnaireModal: IModal;
}

export interface IDefaultQuestionnaireModalState {
  defaultQuestionnaireModal: IModal;
}

export interface ICreateSelfAssessmentAnswerPayload {
  variables: CreateSelfAssessmentAnswerMutationVariables;
  m2sID: string;
}

export interface IUpdateSelfAssessmentAnswerPayload {
  variables: UpdateSelfAssessmentAnswerMutationVariables;
}

export interface ISelfAssesmentModalsState {
  m2sID: string | null;
  simpleQuestionnaireModalState: ISimpleQuestionnaireModalState;
  defaultQuestionnaireModalState: IDefaultQuestionnaireModalState;
}

const initialState: ISelfAssesmentModalsState = {
  m2sID: null,
  simpleQuestionnaireModalState: {
    simpleQuestionnaireModal: {
      isRender: false,
    },
  },
  defaultQuestionnaireModalState: {
    defaultQuestionnaireModal: {
      isRender: false,
    },
  },
};

export const selfAssesmentModalsSlice = createSlice({
  name: 'selfAssesmentModals',
  initialState,
  reducers: {
    // Sagas
    createSelfAssessmentAnswer: (
      state,
      action: PayloadAction<ICreateSelfAssessmentAnswerPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    updateSelfAssessmentAnswer: (
      state,
      action: PayloadAction<IUpdateSelfAssessmentAnswerPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    // DefaultQuestionnaireModal
    openDefaultQuestionnaireModal: (state, action: PayloadAction<IStartQuestionnairePayload>) => ({
      ...state,
      m2sID: action.payload.m2sID,
      defaultQuestionnaireModalState: {
        ...state.defaultQuestionnaireModalState,
        defaultQuestionnaireModal: {
          isRender: true,
        },
      },
    }),
    closeDefaultQuestionnaireModal: (state) => ({
      ...state,
      defaultQuestionnaireModalState: {
        ...state.defaultQuestionnaireModalState,
        defaultQuestionnaireModal: {
          isRender: false,
        },
      },
    }),
    // SimpleQuestionnaireModal
    openSimpleQuestionnaireModal: (state, action: PayloadAction<IStartQuestionnairePayload>) => ({
      ...state,
      m2sID: action.payload.m2sID,
      simpleQuestionnaireModalState: {
        ...state.simpleQuestionnaireModalState,
        simpleQuestionnaireModal: {
          isRender: true,
        },
      },
    }),
    closeSimpleQuestionnaireModal: (state) => ({
      ...state,
      simpleQuestionnaireModalState: {
        ...state.simpleQuestionnaireModalState,
        simpleQuestionnaireModal: {
          isRender: false,
        },
      },
    }),
  },
});

export const selfAssesmentModalsReducers = selfAssesmentModalsSlice.reducer;
export const selfAssesmentModalsActions = selfAssesmentModalsSlice.actions;
