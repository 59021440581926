import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { PersonItem } from '@/components/Modals/Modals/widgets/ActivityPersonsListModal/PersonItem/PersonItem';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { Typography } from '@/components/Typography/Typography';
import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { FUNCTIONS_URL } from '@/core/constants/urls';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dashboardActions } from '@/core/redux/slices/dashboard/dashboardSlice';
import { widgetModalsSelectors } from '@/core/redux/slices/modalsSlice/widgets/selector';
import { widgetsModalsActions } from '@/core/redux/slices/modalsSlice/widgets/widgetsModalsSlice';
import { IPerson } from '@/types/person';

import styles from './styles.module.scss';

export const ActivityPersonsListModal: React.FC = () => {
  const { t: activityPersonsListModalTranslations } = useTranslation('activityPersonsListModal');

  const [personsChecksState, setPersonsChecksState] = usePersonPageStoreSelector(
    (store) => store.personsChecksState?.persons
  );

  const { isRender: isOpened, payload } = useAppSelector(
    widgetModalsSelectors.activityPersonsListModal
  );

  const navigate = useNavigate();

  const form = useForm();
  const modalPersonsChecksState = form.watch();

  const dispatch = useAppDispatch();
  const handleExit = () => {
    dispatch(widgetsModalsActions.closeActivityPersonsListModal());
  };

  const handleToFunctions = () => {
    if (!payload.personList) {
      return;
    }

    const selectedActivityPersons = payload.personList.reduce(
      (accum, person): Record<string, IPerson> => ({
        ...accum,
        [person.id]: {
          ...person,
        },
      }),
      {}
    );

    dispatch(dashboardActions.setSelectedActivityPersons(selectedActivityPersons));

    setPersonsChecksState({
      personsChecksState: {
        persons_check_all: false,
        persons: {
          ...personsChecksState,
          ...modalPersonsChecksState,
        },
      },
    });

    navigate(FUNCTIONS_URL, { replace: true });

    handleExit();
  };

  if (isOpened) {
    return (
      <Modal onExit={handleExit} className={'p-0'}>
        <div className={'flex flex-col max-w-[740px] gap-3'}>
          <Typography type={'H2'} color={'Primary'} className={'mx-[30px] mt-[30px]'}>
            {activityPersonsListModalTranslations('title.label')}
          </Typography>
          <FormProvider {...form}>
            <div
              className={clsx(
                'flex flex-col max-h-[600px] overflow-y-auto ps-[30px] gap-[20px]',
                styles.scrollbar
              )}
            >
              {payload.personList?.map((person) => (
                <PersonItem {...person} key={person.id} />
              ))}
            </div>
          </FormProvider>
          <div className={'flex flex-row gap-3 px-[30px] pb-[30px]'}>
            <Button
              className={'w-full flex items-center justify-center'}
              buttonVariant={'Secondary'}
              onClick={handleExit}
            >
              {activityPersonsListModalTranslations('buttons.cancel.label')}
            </Button>
            <Button
              className={'w-full flex items-center justify-center'}
              onClick={handleToFunctions}
            >
              {activityPersonsListModalTranslations('buttons.add.label')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  return null;
};
