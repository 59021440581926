import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import {
  mapWorkingDaysResponse,
  mapWorkingTimeDaysRequest,
} from '@/core/redux/slices/functions/workingTimeMobility/workingTime/sagas';
import {
  ICreateWorkingTimePredefinedVariantPayload,
  IMobilityFromType,
  IMobilityTransportType,
  IMobilityTransportTypesUpdatePayload,
  IUpdateWorkingTimePredefinedVariantPayload,
  IWorkingTimePredefinedVariant,
  workingTimeMobilityModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/workingTimeMobilitModalsSlice';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type WorkingTimePredefinedVariantsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/working_hours/predefined',
  'get',
  '200'
>;

type UpdateWorkingTimePredefinedVariantRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/working_hours/predefined',
  'put'
>;

type CreateWorkingTimePredefinedVariantRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/working_hours/predefined',
  'post'
>;

const mapWorkingTimePredefinedVariantsResponse = (
  response: WorkingTimePredefinedVariantsResponse
): IWorkingTimePredefinedVariant[] => {
  const { working_hours } = response;
  return working_hours.map((workingHour) => ({
    id: workingHour.id,
    name: workingHour.name,
    workingWeek: {
      sumGross: workingHour.working_week.sum_brutto
        ? Number(workingHour.working_week.sum_brutto.toFixed(2))
        : null,
      sumNet: workingHour.working_week.sum_netto
        ? Number(workingHour.working_week.sum_netto.toFixed(2))
        : null,
      sumPauseDuration: workingHour.working_week.sum_pauses_durations
        ? Number(workingHour.working_week.sum_pauses_durations.toFixed(2))
        : null,
      workingDaysCount: workingHour.working_week.working_days_count,
      workingDays: mapWorkingDaysResponse(workingHour.working_week.days),
    },
  }));
};

function* fetchMobilityTransportTypes(
  action: PayloadAction<number | null>
): Generator<any, void, any> {
  const id = action.payload;

  try {
    yield put(workingTimeMobilityModalsActions.setTransportTypesLock(LoadingStatus.LOADING));
    yield delay(2000);

    const transportTypes: IMobilityTransportType[] = [
      {
        transportFormType: { id: 1, formType: MobilityFormTypes.SelfDriver },
        transportTypeName: 'Test 1',
        id: 1,
      },
      {
        transportFormType: { id: 2, formType: MobilityFormTypes.CarService },
        transportTypeName: 'Test 2',
        id: 2,
      },
      {
        transportFormType: { id: 1, formType: MobilityFormTypes.SelfDriver },
        transportTypeName: 'Test 3',
        id: 3,
      },
    ];

    const transportFormTypes: IMobilityFromType[] = [
      { id: 1, formType: MobilityFormTypes.SelfDriver },
      { id: 2, formType: MobilityFormTypes.CarService },
    ];

    yield put(workingTimeMobilityModalsActions.setTransportTypes(transportTypes));
    yield put(workingTimeMobilityModalsActions.setTransportFormTypes(transportFormTypes));

    yield put(workingTimeMobilityModalsActions.setTransportTypesLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on mobility transport types loading');
    yield put(workingTimeMobilityModalsActions.setTransportTypesLock(LoadingStatus.ERROR));
  }
}

function* updateTransportTypes(
  action: PayloadAction<IMobilityTransportTypesUpdatePayload>
): Generator<any, void, any> {
  const { id, transportTypeFormID, transportTypeName } = action.payload;

  if (!id) {
    console.log('create', transportTypeFormID, transportTypeName);
    return;
  }

  console.log('update', id, transportTypeFormID, transportTypeName);
}

function* fetchWorkingTimePredefinedVariants(): Generator<
  any,
  void,
  WorkingTimePredefinedVariantsResponse
> {
  try {
    yield put(
      workingTimeMobilityModalsActions.setWorkingTimePredefinedVariantsLock(LoadingStatus.LOADING)
    );

    const response = yield call(restCall, '/working_hours/predefined', 'get', {
      ...authAdd(),
    });

    const workingTimePredefinedVariants = mapWorkingTimePredefinedVariantsResponse(response);

    yield put(workingTimeMobilityModalsActions.setWorkingTimeDaysList(response.working_days));
    yield put(
      workingTimeMobilityModalsActions.setWorkingTimePredefinedVariants(
        workingTimePredefinedVariants
      )
    );

    yield put(
      workingTimeMobilityModalsActions.setWorkingTimePredefinedVariantsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on fetching working time predefined variants', error);
    yield put(
      workingTimeMobilityModalsActions.setWorkingTimePredefinedVariantsLock(LoadingStatus.ERROR)
    );
  }
}

function* updateWorkingTimePredefinedVariant(
  action: PayloadAction<IUpdateWorkingTimePredefinedVariantPayload>
) {
  const { id, name, days } = action.payload;

  try {
    yield put(
      workingTimeMobilityModalsActions.setUpdateWorkingTimePredefinedVariantsLock(
        LoadingStatus.LOADING
      )
    );

    const request: UpdateWorkingTimePredefinedVariantRequest = {
      json: {
        id: id,
        name: name,
        days: mapWorkingTimeDaysRequest(days),
      },
      ...authAdd(),
    };

    yield call(restCall, '/working_hours/predefined', 'put', request);

    yield put(
      workingTimeMobilityModalsActions.setUpdateWorkingTimePredefinedVariantsLock(
        LoadingStatus.LOADED
      )
    );
    yield call(fetchWorkingTimePredefinedVariants);
  } catch (error) {
    console.log('Error on update working time predefined variant', error);
    yield put(
      workingTimeMobilityModalsActions.setUpdateWorkingTimePredefinedVariantsLock(
        LoadingStatus.ERROR
      )
    );
  }
}

function* createWorkingTimePredefinedVariant(
  action: PayloadAction<ICreateWorkingTimePredefinedVariantPayload>
) {
  const { name, days } = action.payload;

  try {
    yield put(
      workingTimeMobilityModalsActions.setUpdateWorkingTimePredefinedVariantsLock(
        LoadingStatus.LOADING
      )
    );

    const request: CreateWorkingTimePredefinedVariantRequest = {
      json: {
        name: name,
        days: mapWorkingTimeDaysRequest(days),
      },
      ...authAdd(),
    };

    yield call(restCall, '/working_hours/predefined', 'post', request);

    yield put(
      workingTimeMobilityModalsActions.setUpdateWorkingTimePredefinedVariantsLock(
        LoadingStatus.LOADED
      )
    );

    yield call(fetchWorkingTimePredefinedVariants);
  } catch (error) {
    console.log('Error on update working time predefined variant', error);
    yield put(
      workingTimeMobilityModalsActions.setUpdateWorkingTimePredefinedVariantsLock(
        LoadingStatus.LOADING
      )
    );
  }
}

export const workingTimeMobilityModalsSagas = [
  takeLatest(workingTimeMobilityModalsActions.fetchTransportTypes, fetchMobilityTransportTypes),
  takeLatest(workingTimeMobilityModalsActions.updateTransportTypes, updateTransportTypes),
  takeLatest(
    workingTimeMobilityModalsActions.fetchWorkingTimePredefinedVariants,
    fetchWorkingTimePredefinedVariants
  ),
  takeLatest(
    workingTimeMobilityModalsActions.createWorkingTimePredefinedVariants,
    createWorkingTimePredefinedVariant
  ),
  takeLatest(
    workingTimeMobilityModalsActions.updateWorkingTimePredefinedVariants,
    updateWorkingTimePredefinedVariant
  ),
];
