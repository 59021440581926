import React from 'react';

import { AdditionalPersonRequirementTimeListModalContent } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/AdditionalPersonRequirementTimeListModalContent';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

export const AdditionalPersonRequirementTimeListModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    organizationalUnitsModalsSelectors.additionalPersonRequirementTimeListModal
  );

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(organizationalUnitsModalsActions.closeAdditionalPersonRequirementTimeListModal());
  };

  return isOpened ? <AdditionalPersonRequirementTimeListModalContent onExit={handleExit} /> : null;
};
