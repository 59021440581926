import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IGeneralData {
  disabilityType?: string | null;
  healthImpact?: string | null;
  schoolEducation?: string | null;
  professionalMeasuresInformationBeforeWfbM?: string | null;
  publicTransportUsageNote?: string | null;
  other?: string | null;
}

export interface IGeneralDataFetchPayload {
  integrationPlanID: number;
  personID: number;
}

export interface IGeneralDataUpdatePayload {
  integrationPlanID: number;
  personID: number;
  generalData: IGeneralData;
}

export interface IGeneralDataState {
  generalData: IGeneralData | null;
  updatedData: Record<string, string> | null;
  generalDataLock: LoadingStatus;
  updateGeneralDataLock: LoadingStatus;
}

const initialState: IGeneralDataState = {
  generalData: null,
  updatedData: null,
  generalDataLock: LoadingStatus.NEVER,
  updateGeneralDataLock: LoadingStatus.NEVER,
};

export const generalDataSlice = createSlice({
  name: 'generalData',
  initialState,
  reducers: {
    fetchGeneralData: (state, action: PayloadAction<IGeneralDataFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateGeneralData: (state, action: PayloadAction<IGeneralDataUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateWithFetchGeneralData: (state, action: PayloadAction<IGeneralDataUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setGeneralDataLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      generalDataLock: action.payload,
    }),
    setUpdateGeneralDataLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateGeneralDataLock: action.payload,
    }),
    setGeneralData: (state, action: PayloadAction<IGeneralData | null>) => ({
      ...state,
      generalData: action.payload ?? null,
    }),
    setUpdatedData: (state, action: PayloadAction<Record<string, string> | null>) => ({
      ...state,
      updatedData: action.payload,
    }),
  },
});

export const generalDataReducers = generalDataSlice.reducer;

export const generalDataActions = generalDataSlice.actions;
