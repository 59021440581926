import React, { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ICreateIntegrationPlanDocumentsForm } from '@/components/Modals/Modals/functions/integrationPlanModals/CreateIntegrationPlanDocumentsModal/CreateIntegrationPlanDocumentsModal';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { IIntegrationPlanPredefinedVariant } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';

interface IPredefinedPrintVariantsSelect {
  form: UseFormReturn<ICreateIntegrationPlanDocumentsForm, any, undefined>;
  predefinedPrintVariants: IIntegrationPlanPredefinedVariant[];
}

export const PredefinedPrintVariantsSelect: React.FC<IPredefinedPrintVariantsSelect> = ({
  form,
  predefinedPrintVariants,
}) => {
  const { t: predefinedPrintVariantsTranslations } = useTranslation(
    'createIntegrationPlanDocumentsModal',
    { keyPrefix: 'predefinedVariants' }
  );

  const options = useMemo(
    () =>
      predefinedPrintVariants.reduce<ISelectItem[]>((accum, predefinedPrintVariant) => {
        return [
          ...accum,
          {
            id: predefinedPrintVariant.id.toString(),
            component: <Typography>{predefinedPrintVariant.name}</Typography>,
          },
        ];
      }, []),
    [predefinedPrintVariants]
  );

  const handleSelect = (id: string) => {
    const predefinedVariant = predefinedPrintVariants.find(
      (predefinedPrintVariant) => predefinedPrintVariant.id === parseInt(id)
    );

    if (predefinedVariant) {
      const formState = form.watch().forms;
      const formKeys = Object.keys(formState);

      const newFormState = formKeys.reduce<Record<string, boolean>>((accum, formKey) => {
        return {
          ...accum,
          [formKey]: predefinedVariant.variantIds.includes(parseInt(formKey)),
        };
      }, {});

      form.setValue('forms', newFormState);
    }
  };

  return (
    <div className={'flex flex-col gap-2'}>
      <Typography>{predefinedPrintVariantsTranslations('title.label')}</Typography>
      <Select values={options} onChange={handleSelect} />
    </div>
  );
};
