import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDevelopmentReportPersonDetailsForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/PersonDetails/helper/personDetailsForm';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IDevelopmentReportPersonDetailsFetchPayload {
  developmentReportID: number;
}

export interface IImportedDevelopmentReportPersonDetailsFetchPayload {
  personID: number;
}

export interface IDevelopmentReportPersonDetailsUpdatePayload {
  developmentReportID: number;
  personID: number;
  formValues: IDevelopmentReportPersonDetailsForm;
}

export interface IDevelopmentReportPersonDetailsState {
  developmentReportPersonDetails: IDevelopmentReportPersonDetailsForm | null;
  developmentReportPersonDetailsLock: LoadingStatus;
  importedDevelopmentReportPersonDetails: IDevelopmentReportPersonDetailsForm | null;
  importedDevelopmentReportPersonDetailsLock: LoadingStatus;
  fetchPayload: IDevelopmentReportPersonDetailsFetchPayload | null;
  importPayload: IImportedDevelopmentReportPersonDetailsFetchPayload | null;
  updatePayload: IDevelopmentReportPersonDetailsUpdatePayload | null;
}

const initialState: IDevelopmentReportPersonDetailsState = {
  developmentReportPersonDetails: null,
  developmentReportPersonDetailsLock: LoadingStatus.NEVER,
  importedDevelopmentReportPersonDetails: null,
  importedDevelopmentReportPersonDetailsLock: LoadingStatus.NEVER,
  fetchPayload: null,
  importPayload: null,
  updatePayload: null,
};

const developmentReportPersonDetailsSlice = createSlice({
  name: 'developmentReportPersonDetails',
  initialState,
  reducers: {
    fetchDevelopmentReportPersonDetails: (
      state,
      action: PayloadAction<IDevelopmentReportPersonDetailsFetchPayload>
    ) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setDevelopmentReportPersonDetails: (
      state,
      action: PayloadAction<IDevelopmentReportPersonDetailsForm | null>
    ) => ({
      ...state,
      developmentReportPersonDetails: action.payload,
    }),
    setDevelopmentReportPersonDetailsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      developmentReportPersonDetailsLock: action.payload,
    }),
    updateDevelopmentReportPersonDetails: (
      state,
      action: PayloadAction<IDevelopmentReportPersonDetailsUpdatePayload>
    ) => ({
      ...state,
      updatePayload: action.payload,
    }),
    fetchImportedDevelopmentReportPersonDetails: (
      state,
      action: PayloadAction<IImportedDevelopmentReportPersonDetailsFetchPayload>
    ) => ({
      ...state,
      importPayload: action.payload,
    }),
    setImportedDevelopmentReportPersonDetails: (
      state,
      action: PayloadAction<IDevelopmentReportPersonDetailsForm | null>
    ) => ({
      ...state,
      importedDevelopmentReportPersonDetails: action.payload,
    }),
    setImportedDevelopmentReportPersonDetailsLock: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => ({
      ...state,
      importedDevelopmentReportPersonDetailsLock: action.payload,
    }),
  },
});

export const developmentReportPersonDetailsReducer = developmentReportPersonDetailsSlice.reducer;
export const developmentReportPersonDetailsActions = developmentReportPersonDetailsSlice.actions;
