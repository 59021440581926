import { useTranslation } from 'react-i18next';

import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { SPECIALISTS_URL } from '@/core/constants/urls';

import { SelectedSpecialistsList } from './SelectedSpecialistsList/SelectedSpecialistsList';
import { SpecialistFunctionContainer } from './SpecialistFunctionContainer/SpecialistFunctionContainer';

export const SpecialistsPage: React.FC = () => {
  const { t: specialistsTranslations } = useTranslation('specialists');

  const breadcrumbItems = [
    { text: specialistsTranslations('breadcurms.specialists.label'), link: SPECIALISTS_URL },
  ];

  return (
    <div className={'px-[40px] w-full flex flex-col overflow-y-hidden'}>
      <div className='pt-[18px] pb-[18px]'>
        <Breadcrumbs items={breadcrumbItems} />
      </div>
      <div className='flex flex-row'>
        <SelectedSpecialistsList />
        <SpecialistFunctionContainer />
      </div>
    </div>
  );
};
