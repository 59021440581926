import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  IMobilityFetchPayload,
  IMobilityItem,
  mobilityActions,
} from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type FetchMobilityResponse = OASOutput<NormalizeOAS<typeof oas>, '/mobility', 'get', '200'>;
type FetchMobilityRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/mobility', 'get'>;

function* fetchMobility(
  action: PayloadAction<IMobilityFetchPayload>
): Generator<any, void, FetchMobilityResponse> {
  const { personID } = action.payload;
  try {
    yield put(mobilityActions.setMobilityLock(LoadingStatus.LOADING));

    const request: FetchMobilityRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/mobility', 'get', request);

    const mobilityItems = response.items.map<IMobilityItem>((mobilityItem) => ({
      arrival: mobilityItem.arrival_type,
      departure: mobilityItem.departure_type,
      id: mobilityItem.id,
      isWorkingDay: mobilityItem.is_working_day,
      name: mobilityItem.name,
    }));

    yield put(mobilityActions.setMobility(mobilityItems));
    yield put(mobilityActions.setMobilityLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on mobility fetching', error);
    yield put(mobilityActions.setMobilityLock(LoadingStatus.ERROR));
  }
}

export const mobilitySagas = [takeLatest(mobilityActions.fetchMobility, fetchMobility)];
