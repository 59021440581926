import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  actionCourseActions,
  IActionCourse,
} from '@/core/redux/slices/functions/integrationPlan/actionCourse/actionCourseSlice';
import { actionCourseSelectors } from '@/core/redux/slices/functions/integrationPlan/actionCourse/selectors';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';
import { currentClientDate, toClientDateInput } from '@/core/utils/dateTimeUtil';

export const ActionCourseCreatedDateModal: React.FC = () => {
  const { t: createdDateModalTranslations } = useTranslation('actionCourseCreatedDateModal');

  const { isRender: isOpened, payload } = useAppSelector(
    integrationPlanModalsSelectors.actionCourseCreatedDateModal
  );
  const actionCourse = useAppSelector(actionCourseSelectors.actionCourse);

  const dispatch = useAppDispatch();

  if (isOpened) {
    const handleExit = () => {
      dispatch(integrationPlanModalsActions.closeActionCourseCreatedDateModal());
    };

    const date = toClientDateInput(payload?.createdTimestamp) ?? '';
    const currentDate = currentClientDate();

    const handleDateUpdate = () => {
      if (actionCourse && payload) {
        const newActionCourse: IActionCourse = {
          ...actionCourse,
          createdTimestamp: currentDate,
        };

        dispatch(
          actionCourseActions.updateWithFetchActionCourse({
            integrationPlanID: payload.integrationPlanID,
            personID: payload.personID,
            actionCourse: newActionCourse,
          })
        );
      }

      openCreateIntegrationPlanDocumentsModal();

      handleExit();
    };

    const openCreateIntegrationPlanDocumentsModal = () => {
      if (payload) {
        dispatch(integrationPlanModalsActions.openCreateIntegrationPlanDocumentsModal());
      }
    };

    const handleWithOutDateUpdate = () => {
      openCreateIntegrationPlanDocumentsModal();

      handleExit();
    };

    return (
      <Modal onExit={handleExit}>
        <div className={'flex flex-col max-w-[740px] gap-3'}>
          <Typography type={'H3'}>{createdDateModalTranslations('title.label')}</Typography>
          <Typography type={'16-M'}>
            {createdDateModalTranslations('description.label', { date })}
          </Typography>
          <Typography className={'mt-4'}>
            {createdDateModalTranslations('options.yes.label', { currentDate })}
          </Typography>
          <Typography>{createdDateModalTranslations('options.no.label')}</Typography>
          <Typography>{createdDateModalTranslations('options.cancel.label')}</Typography>
          <div className={'flex flex-row justify-center gap-2 mt-4'}>
            <Button onClick={handleExit} buttonVariant={'Secondary'}>
              {createdDateModalTranslations('buttons.cancel.label')}
            </Button>
            <Button onClick={handleWithOutDateUpdate}>
              {createdDateModalTranslations('buttons.no.label')}
            </Button>
            <Button onClick={handleDateUpdate}>
              {createdDateModalTranslations('buttons.yes.label')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  return null;
};
