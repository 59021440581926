import { useMemo } from 'react';

import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { useAppSelector } from '@/core/redux/hooks';
import { dashboardSelectors } from '@/core/redux/slices/dashboard/selectors';
import { personPageSelectors } from '@/core/redux/slices/personPage/personPageSelectors';
import { IPerson } from '@/types/person';

export function useSelectedPersons() {
  const [personsChecksState] = usePersonPageStoreSelector((store) => store.personsChecksState);
  const persons = useAppSelector(personPageSelectors.persons);
  const activityPersons = useAppSelector(dashboardSelectors.selectedActivityPersons);
  const favoritePersons = useAppSelector(dashboardSelectors.navigatedFavoritePersons);

  const mergedPersons = {
    ...persons,
    ...activityPersons,
    ...favoritePersons,
  };

  function getSelectedPersons(
    persons: { [id: string]: IPerson },
    selectedIds: { [id: string]: boolean }
  ): IPerson[] {
    const selectedPersons: IPerson[] = [];
    for (const personId in persons) {
      if (selectedIds[personId]) {
        const person: IPerson = {
          name: persons[personId].name,
          surname: persons[personId].surname,
          last_modified: persons[personId].last_modified,
          id: parseInt(personId),
        };
        selectedPersons.push(person);
      }
    }
    return selectedPersons;
  }

  return useMemo(() => {
    if (mergedPersons && personsChecksState && personsChecksState.persons) {
      const selectedIds: { [key: string]: boolean } = Object.keys(
        personsChecksState.persons
      ).reduce((accum, personKey) => {
        if (!personsChecksState?.persons?.[personKey]) {
          return accum;
        }
        return {
          ...accum,
          [personKey.split('=')[1]]: personsChecksState?.persons?.[personKey],
        };
      }, {});
      return getSelectedPersons(mergedPersons, selectedIds);
    }
    return null;
  }, [activityPersons, favoritePersons, personsChecksState]);
}
