import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.diagnosticsModals;

//Add ICD Codes Modal
const addICDCodesModal = createSelector([selector], (state) => state.addICDCodesModal);
const addICFItemsModal = createSelector([selector], (state) => state.addICFItemsModal);

export const diagnosticsModalsSelectors = {
  addICDCodesModal,
  addICFItemsModal,
};
