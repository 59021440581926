import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDisabilityCharacteristicsForm } from '@/components/functions/MedicalInformation/DisabilityCharacteristics/helpers/useDisabilityCharacteristicsForm';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IDisabilityCharacteristicsFetchPayload {
  personID: number;
}

export interface IDisabilityCharacteristicsUpdatePayload {
  personID: number;
  formValues: IDisabilityCharacteristicsForm;
}

export interface IDisabilityCharacteristic {
  id: number;
  name?: string | null;
  isSelected: boolean;
}

export interface IDisabilityCharacteristicValues {
  characteristics: IDisabilityCharacteristic[];
  isDisabledPass: boolean;
  identityCardAuthoritiesID?: number | null;
  validDate?: string | null;
  referenceNumber?: string | null;
  isUnlimited: boolean;
}

export interface IDisabilityCharacteristicsState {
  disabilityCharacteristics: IDisabilityCharacteristicValues | null;

  disabilityCharacteristicsLock: LoadingStatus;
  fetchPayload: IDisabilityCharacteristicsFetchPayload | null;
  updatePayload: IDisabilityCharacteristicsUpdatePayload | null;
  updateLock: LoadingStatus;
}

const initialState: IDisabilityCharacteristicsState = {
  disabilityCharacteristics: null,
  disabilityCharacteristicsLock: LoadingStatus.NEVER,
  fetchPayload: null,
  updatePayload: null,
  updateLock: LoadingStatus.NEVER,
};

export const disabilityCharacteristicsSlice = createSlice({
  name: 'disabilityCharacteristics',
  initialState,
  reducers: {
    fetchDisabilityCharacteristics: (
      state,
      action: PayloadAction<IDisabilityCharacteristicsFetchPayload>
    ) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setDisabilityCharacteristics: (
      state,
      action: PayloadAction<IDisabilityCharacteristicValues | null>
    ) => ({
      ...state,
      disabilityCharacteristics: action.payload,
    }),
    setDisabilityCharacteristicsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      disabilityCharacteristicsLock: action.payload,
    }),
    updateDisabilityCharacteristics: (
      state,
      action: PayloadAction<IDisabilityCharacteristicsUpdatePayload>
    ) => ({
      ...state,
      updatePayload: action.payload,
    }),
    setUpdateDisabilityCharacteristicsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateLock: action.payload,
    }),
  },
});

export const disabilityCharacteristicsReducer = disabilityCharacteristicsSlice.reducer;
export const disabilityCharacteristicsActions = disabilityCharacteristicsSlice.actions;
