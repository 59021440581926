import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { IWorkingTimePredefinedVariantsForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/WorkingTimePredefinedVariantsModalContent';
import { WorkingTimeType } from '@/core/enums/functions/workingTimeMobility/employmentTypesEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { workingTimeActions } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { workingTimeMobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/selectors';
import { workingTimeMobilityModalsActions } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/workingTimeMobilitModalsSlice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

interface IPredefinedVariantsFooter {
  className?: string;
}

export const PredefinedVariantsFooter: React.FC<IPredefinedVariantsFooter> = ({ className }) => {
  const { t: workingTimePredefinedVariantsModalTranslations } = useTranslation(
    'workingTimePredefinedVariantsModal',
    { keyPrefix: 'buttons' }
  );

  const { updateWorkingTimePredefinedVariantsLock } = useAppSelector(
    workingTimeMobilityModalsSelectors.workingTypePredefinedVariantsModal
  );
  const isLoading = updateWorkingTimePredefinedVariantsLock === LoadingStatus.LOADING;

  const dispatch = useAppDispatch();

  const {
    reset,
    formState: { isDirty, errors },
    watch,
  } = useFormContext<IWorkingTimePredefinedVariantsForm>();

  const handleReset = () => {
    reset();
  };

  const predefinedName = watch('predefinedName');
  const isPredefinedNameValid = predefinedName && predefinedName.length > 0;

  const selectedID = watch('selectedID');

  const currentValues = watch();

  const handleCreate = () => {
    if (!predefinedName || !currentValues.workingWeek.workingDays) {
      return;
    }

    dispatch(
      workingTimeMobilityModalsActions.createWorkingTimePredefinedVariants({
        name: predefinedName,
        days: currentValues.workingWeek.workingDays,
      })
    );
  };

  const handleUpdate = () => {
    if (!predefinedName || !currentValues.workingWeek.workingDays || !selectedID) {
      return;
    }

    dispatch(
      workingTimeMobilityModalsActions.updateWorkingTimePredefinedVariants({
        id: selectedID,
        name: predefinedName,
        days: currentValues.workingWeek.workingDays,
      })
    );
  };

  const handleApply = () => {
    if (!currentValues || !currentValues.selectedID) {
      return;
    }

    const currentDate = new Date();

    dispatch(
      workingTimeActions.setUpdatedWorkingTime({
        workingWeek: currentValues.workingWeek,
        workingTimeType: WorkingTimeType.Default,
        appliesFromTimeStamp:
          toClientDateInput(currentDate.toISOString()) ?? currentDate.toISOString(),
      })
    );

    dispatch(workingTimeMobilityModalsActions.closeWorkingTimePredefinedVariantsModal());
  };

  const isPlannerInValid = Object.keys(errors).includes('workingWeek');

  return (
    <div className={twMerge('flex flex-row justify-between gap-3', className)}>
      <div className={'flex flex-row gap-3'}>
        {isDirty ? (
          isLoading ? (
            <SpinnerIcon className={`text-${colorTypes.Blue} h-[24px] w-[24px]`} />
          ) : (
            <>
              <Button
                disabled={!isPredefinedNameValid || isLoading || isPlannerInValid}
                onClick={handleCreate}
              >
                {workingTimePredefinedVariantsModalTranslations('saveAsNew.label')}
              </Button>
              <Button
                disabled={!isPredefinedNameValid || isLoading || !selectedID || isPlannerInValid}
                onClick={handleUpdate}
              >
                {workingTimePredefinedVariantsModalTranslations('update.label')}
              </Button>
            </>
          )
        ) : null}
      </div>
      <Button onClick={isDirty ? handleReset : handleApply} disabled={!selectedID}>
        {isDirty
          ? workingTimePredefinedVariantsModalTranslations('cancel.label')
          : workingTimePredefinedVariantsModalTranslations('apply.label')}
      </Button>
    </div>
  );
};
