import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IVocationalTrainingAreaForm } from '@/components/functions/VocationalTrainingArea/VocationalTrainingArea';
import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';

export const VocationalTrainingAreaSearchByName: React.FC = () => {
  const { t: vocationalTrainingAreaTranslations } = useTranslation('vocationalTrainingArea', {
    keyPrefix: 'filters.filterMnUh',
  });

  const formContext = useFormContext<IVocationalTrainingAreaForm>();

  return (
    <div className={'flex flex-row gap-3 items-center'}>
      <Typography>{vocationalTrainingAreaTranslations('label')}</Typography>
      <Input control={formContext.register('searchByName')} />
    </div>
  );
};
