import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

export interface ISkeleton {
  trigger: boolean;
  isTitle?: boolean;
  isSingle?: boolean;
  className?: string;
  rows?: number;
  children?: React.ReactNode;
}

export const Skeleton: React.FC<ISkeleton> = ({
  trigger,
  isTitle = true,
  isSingle = false,
  className,
  rows = 4,
  children,
}) => {
  return (
    <>
      {trigger ? (
        <div className={clsx(styles.skeleton, className)}>
          <div className={clsx(styles.row, { [styles.title]: isTitle })} />
          {!isSingle &&
            Array.from({ length: rows }, (_, i) => <div key={i} className={styles.row} />)}
        </div>
      ) : (
        children
      )}
    </>
  );
};
