import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { PersonPlusIcon } from '@/components/Icons/PersonPlusIcon';
import { RollUpIcon } from '@/components/Icons/RollUpIcon';
import { UserIcon } from '@/components/Icons/UserIcon';
import { Typography } from '@/components/Typography/Typography';
import { SelectedPersonItem } from '@/components/views/functions/SelectedPersonsList/SelectedPersonItem';
import { SelectedPersonListTooltip } from '@/components/views/functions/SelectedPersonsList/SelectedPersonListTooltip';
import { useSelectedPersons } from '@/components/views/person/PersonPage/PersonView/PersonList/hooks/useSelectedPersons';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageActions } from '@/core/redux/slices/functionPage/functionPageSlice';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { commonModalsActions } from '@/core/redux/slices/modalsSlice/common/commonModalsSlice';
import { generateName } from '@/core/utils/commonUtils';
import { twMerge } from '@/core/utils/tailwindUtil';
import { IPerson } from '@/types/person';

const ADD_PERSON_BUTTON_ID = 'add-person-button';

export const SelectedPersonsList: React.FC = () => {
  const { t: selectedPersonsListTranslations } = useTranslation('tms', { keyPrefix: 'ui' });
  const dispatch = useAppDispatch();
  const selectedPersons = useSelectedPersons() || [];
  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isExpanded, setIsExpanded] = useState(true);

  const handleSelectPerson = (item: IPerson) => {
    if (selectedPerson?.id === item.id) {
      return;
    }

    dispatch(functionPageActions.setSelectedPerson(item));

    if (item.id) {
      searchParams.set('personID', String(item.id));
      setSearchParams(searchParams);
    }
  };

  const handleAddPerson = () => {
    dispatch(commonModalsActions.openAddPersonModal());
  };

  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (!selectedPersons) {
      return;
    }

    if (selectedPersons.length === 0) {
      dispatch(functionPageActions.setSelectedPerson(null));
      return;
    }

    const isSelectedPersonInList = selectedPersons.find(
      (person) => person.id === selectedPerson?.id
    );

    if (isSelectedPersonInList) {
      return;
    }

    dispatch(functionPageActions.setSelectedPerson(selectedPersons[0]));
  }, [selectedPersons]);

  const sortedSelectedPersons = [...selectedPersons].sort((a, b) => {
    const componentA = generateName(a).toLowerCase();
    const componentB = generateName(b).toLowerCase();

    return componentA.localeCompare(componentB);
  });

  return (
    <motion.div
      className={twMerge(
        'flex flex-col justify-between pt-[30px] bg-white rounded-t-[30px] h-[calc(100vh_-_138px)]'
      )}
      style={{ minWidth: isExpanded ? '85px' : '280px' }}
      animate={{
        minWidth: isExpanded ? '280px' : '85px',
        maxWidth: isExpanded ? '20%' : '5%',
      }}
      transition={{ duration: 0.2 }}
    >
      <div className='flex flex-col flex-grow'>
        <div
          className={twMerge(
            'flex flex-row gap-3 justify-between items-center',
            isExpanded ? 'px-[20px]' : 'px-3 pb-[15px]'
          )}
        >
          {isExpanded ? (
            <Typography type='H2'>{selectedPersonsListTranslations('title.persons')}</Typography>
          ) : (
            <UserIcon className={'shrink-0 w-[24px] h-[24px]'} />
          )}
          <Button buttonVariant={'Icon'} buttonStyle={'Icon'} onClick={handleExpand}>
            <RollUpIcon isOpen={isExpanded} />
          </Button>
        </div>
        <div className={'flex flex-col grow max-h-[calc(100vh_-_210px)] overflow-y-auto'}>
          <div className={twMerge('flex flex-col grow relative mt-[20px]', !isExpanded && 'gap-2')}>
            {sortedSelectedPersons &&
              sortedSelectedPersons.map((item: IPerson) => (
                <SelectedPersonItem
                  person={item}
                  isActive={selectedPerson?.id === item.id}
                  handleSelect={handleSelectPerson}
                  isListExpanded={isExpanded}
                  key={item.id}
                />
              ))}
          </div>
          <div className={'flex sticky bottom-0 p-[10px] bg-white'}>
            <Button
              onClick={handleAddPerson}
              buttonStyle={'WithIcon'}
              buttonVariant={'AddUser'}
              className={twMerge(
                'min-h-[46px] whitespace-nowrap overflow-hidden',
                !isExpanded && 'p-2'
              )}
              id={ADD_PERSON_BUTTON_ID}
            >
              {isExpanded ? selectedPersonsListTranslations('button.addPerson') : null}
              <PersonPlusIcon />
            </Button>
            {!isExpanded && (
              <SelectedPersonListTooltip
                id={ADD_PERSON_BUTTON_ID}
                tooltipMessage={selectedPersonsListTranslations('button.addPerson')}
              />
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
