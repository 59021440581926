import React from 'react';

import { WorkingTimePlannerContainerDay } from '@/components/WorkingTimePlanner/WorkingTimePlannerContainer/WorkingTimePlannerContainerDaysList/WorkingTimePlannerContainerDay/WorkingTimePlannerContainerDay';
import { IWorkingTimeDayItem } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';

interface IWorkingTimePlannerContainerDaysList {
  workingTimeDaysList: IWorkingTimeDayItem[] | null;
}

export const WorkingTimePlannerContainerDaysList: React.FC<IWorkingTimePlannerContainerDaysList> =
  ({ workingTimeDaysList }) => {
    return workingTimeDaysList
      ? workingTimeDaysList.map((day) => <WorkingTimePlannerContainerDay day={day} key={day.id} />)
      : null;
  };
