import React from 'react';

import { InitialFunctionTypeContent } from '@/components/Modals/Modals/functionsPage/InitialFunctionTypeChooseModal/InitialFunctionTypeContent/InitialFunctionTypeContent';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { commonModalsActions } from '@/core/redux/slices/modalsSlice/common/commonModalsSlice';
import { commonModalsSelectors } from '@/core/redux/slices/modalsSlice/common/selector';

export const InitialFunctionTypeChooseModal: React.FC = () => {
  const { isRender: isOpened, payload } = useAppSelector(
    commonModalsSelectors.chooseFunctionTypeModal
  );

  const dispatch = useAppDispatch();
  const handleExit = () => {
    dispatch(commonModalsActions.closeChooseFunctionTypeModal());
  };

  if (isOpened) {
    return (
      <Modal onExit={handleExit}>
        <InitialFunctionTypeContent id={payload.id} />
      </Modal>
    );
  }

  return null;
};
