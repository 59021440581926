import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { diagnosticsTypeOfDisabilitySelectors } from '@/core/redux/slices/functions/diagnostics/typeOfDisability/selectors';
import { diagnosticsTypeOfDisabilityActions } from '@/core/redux/slices/functions/diagnostics/typeOfDisability/slice';

export interface ITypeOfDisabilityForm {
  disabilityTypeID?: number;
  disabilityPercentageID?: number;
  note?: string | null;
  emergencyInfo?: string | null;
}

export function useTypeOfDisabilityForm() {
  const form = useForm<ITypeOfDisabilityForm>();

  const typeOfDisability = useAppSelector(diagnosticsTypeOfDisabilitySelectors.typeOfDisability);
  const typeOfDisabilityImportData = useAppSelector(
    diagnosticsTypeOfDisabilitySelectors.typeOfDisabilityImportData
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!typeOfDisability) {
      return;
    }

    const newState: ITypeOfDisabilityForm = {
      disabilityPercentageID: typeOfDisability.disabilityPercentage?.id,
      disabilityTypeID: typeOfDisability.disabilityType?.id,
      emergencyInfo: typeOfDisability.emergencyInfo,
      note: typeOfDisability.note,
    };

    form.reset(newState);
  }, [typeOfDisability]);

  useEffect(() => {
    if (!typeOfDisabilityImportData) {
      return;
    }

    const existNote = form.watch('note') || '';

    const codes = [
      ...existNote.split('\n'),
      ...typeOfDisabilityImportData.codes.map(
        (code) => `${code.id} ${code.code} ${code.description}`
      ),
    ];

    const uniqueCodes = Array.from(new Set(codes)).join('\n');

    form.setValue('note', uniqueCodes, { shouldDirty: true });

    dispatch(diagnosticsTypeOfDisabilityActions.setTypeOfDisabilityImportData(null));
  }, [typeOfDisabilityImportData]);

  return form;
}
