import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@/components/Button/Button';
import {
  IParticipationPlanFormFields,
  ParticipationPlanForm,
} from '@/components/forms/ParticipationPlan/ParticipationPlan';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { SchemaProvider } from '@/components/SchemaContext/SchemaContext';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { participationPlanActions } from '@/core/redux/slices/functions/fundingCycle/participationPlan/participationPlanSlice';
import { participationPlanSelectors } from '@/core/redux/slices/functions/fundingCycle/participationPlan/selectors';
import { participationPlanModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/participationPlan/participationPlanModalSlice';
import { participationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/participationPlan/selectors';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import { ParticipationPlanFormResolver } from './helpers/resolver';

const ModalContent: React.FC = () => {
  const form = useForm<IParticipationPlanFormFields>({
    resolver: yupResolver(ParticipationPlanFormResolver),
  });
  const dispatch = useAppDispatch();
  const { t: participationPlanModalTranslations } = useTranslation('displayParticipationPlanModal');

  // selectors
  const { payload } = useAppSelector(
    participationPlanModalsSelectors.displayParticipationPlanModal
  );
  const participationPlanDetails = useAppSelector(
    participationPlanSelectors.participationPlanDetails
  );
  const caretakerType = useAppSelector(participationPlanSelectors.caretakerType);
  // locks
  const createParticipationPlanLock = useAppSelector(
    participationPlanSelectors.createParticipationPlanLock
  );
  const updateParticipationPlanLock = useAppSelector(
    participationPlanSelectors.updateParticipationPlanLock
  );
  const participationPlanDetailsLock = useAppSelector(
    participationPlanSelectors.participationPlanDetailsLock
  );
  const participationPlanNotification = useAppSelector(
    participationPlanSelectors.participationPlanNotification
  );
  const notificationDelay = useAppSelector(parametersSelectors.forderzyklusErrorNotificationDelay);

  // loading state
  const isLoading =
    createParticipationPlanLock === LoadingStatus.LOADING ||
    updateParticipationPlanLock === LoadingStatus.LOADING;
  const isLoaded =
    createParticipationPlanLock === LoadingStatus.LOADED ||
    updateParticipationPlanLock === LoadingStatus.LOADED;
  const isError =
    createParticipationPlanLock === LoadingStatus.ERROR ||
    updateParticipationPlanLock === LoadingStatus.ERROR;

  // payload data
  const personID = payload?.personID;
  const participationPlanID = payload?.participationPlanID;

  // handle exit

  const handleExit = () => {
    dispatch(participationPlanModalActions.closeisplayParticipationPlannModal());
  };

  // handle submit

  const onSubmit = (data: IParticipationPlanFormFields) => {
    if (!personID || participationPlanID === undefined || isLoading) {
      return;
    }

    if (participationPlanID === 0) {
      dispatch(
        participationPlanActions.createParticipationPlan({
          personID: personID,
          participationPlanData: data,
        })
      );
    } else {
      dispatch(
        participationPlanActions.updateParticipationPlan({
          personID: personID,
          participationPlanID: participationPlanID,
          participationPlanData: data,
        })
      );
    }
  };

  // fetch details

  useEffect(() => {
    if (participationPlanID === undefined || !personID) {
      return;
    }

    if (participationPlanID === 0) {
      dispatch(
        participationPlanActions.fetchParticipationPlanAutofillData({
          personID: personID,
        })
      );
    } else {
      dispatch(
        participationPlanActions.fetchParticipationPlanDetails({
          participationPlanID: participationPlanID,
        })
      );
    }
  }, [participationPlanID]);

  // reset form values on details loaded

  useEffect(() => {
    if (!participationPlanDetails || participationPlanDetailsLock !== LoadingStatus.LOADED) {
      return;
    }

    form.reset({
      name: participationPlanDetails.name ?? '',
      startDate: participationPlanDetails.startDate ?? '',
      caretaker: participationPlanDetails.caretaker?.id,
      socialServiceWorker: participationPlanDetails.socialServiceWorker?.id,
    });
  }, [participationPlanDetails, participationPlanDetailsLock]);

  // exit on update

  useEffect(() => {
    if (isLoaded) {
      handleExit();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isError && participationPlanNotification) {
      dispatch(
        notificationsActions.showNotification({
          notification: {
            type: 'error',
            title: 'Error',
            description: participationPlanNotification,
            delay: notificationDelay,
          },
        })
      );
    }
  }, [isError]);

  // reset state on unmount

  useEffect(() => {
    return () => {
      dispatch(participationPlanActions.setParticipationPlanDetails(null));
      dispatch(participationPlanActions.setParticipationPlanDetailsLock(LoadingStatus.NEVER));
      dispatch(participationPlanActions.setUpdateParticipationPlanLock(LoadingStatus.NEVER));
      dispatch(participationPlanActions.setCreateParticipationPlanLock(LoadingStatus.NEVER));
    };
  }, []);

  const renderFooter = (
    <div className='flex gap-4'>
      <Button
        type='submit'
        onClick={form.handleSubmit(onSubmit)}
        buttonVariant={form.formState.isValid ? 'Default' : 'Disabled'}
      >
        {participationPlanModalTranslations('buttons.submit.label')}
      </Button>
      <Button type='button' buttonVariant='Secondary' onClick={handleExit}>
        {participationPlanModalTranslations('buttons.close.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar onExit={handleExit} className='w-[800px]' footer={renderFooter} position={'right'}>
      <SchemaProvider schema={ParticipationPlanFormResolver}>
        <FormProvider {...form}>
          <div className={'relative p-7'}>
            <ParticipationPlanForm
              className={isLoading ? 'opacity-50' : ''}
              caretakerType={caretakerType}
            />
            {isLoading && (
              <div className={'absolute inset-0 flex items-center justify-center'}>
                <SpinnerIcon className={`text-${colorTypes.Blue} w-fit`} />
              </div>
            )}
          </div>
        </FormProvider>
      </SchemaProvider>
    </Sidebar>
  );
};

export const DisplayParticipationPlanModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    participationPlanModalsSelectors.displayParticipationPlanModal
  );

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
