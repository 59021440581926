import React from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { RadioButtonCheckedIcon } from '@/components/Icons/RadioButtonCheckedIcon';
import { RadioButtonUncheckedIcon } from '@/components/Icons/RadioButtonUncheckedIcon';
import { Typography } from '@/components/Typography/Typography';
import { IAdministrationLocation } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IAdministrationLocationManagementMoveItem {
  location: IAdministrationLocation;
  control: UseControllerProps<FieldValues, string>;
  layer: number;
}

export const AdministrationLocationManagementMoveItem: React.FC<IAdministrationLocationManagementMoveItem> =
  ({ location, control, layer }) => {
    const {
      field: { value, onChange },
    } = useController(control);

    const isSelected = value === location.id;

    const handleChange = (event: React.MouseEvent<HTMLOrSVGElement>) => {
      event.stopPropagation();

      onChange(location.id);
    };

    return (
      <div
        className={twMerge(
          `flex flex-row gap-4 items-center py-[10px] 
        bg-${colorTypes.White} hover:bg-${colorTypes.HoverLight}
        cursor-pointer border-l-2`,
          isSelected
            ? `border-l-${colorTypes.Blue} bg-${colorTypes.HoverLight}`
            : 'border-l-transparent'
        )}
        style={{ paddingLeft: `${30 + layer * 34}px` }}
        onClick={handleChange}
      >
        {isSelected ? (
          <RadioButtonCheckedIcon onClick={handleChange} className={`text-${colorTypes.Blue}`} />
        ) : (
          <RadioButtonUncheckedIcon onClick={handleChange} className={`text-${colorTypes.Blue}`} />
        )}
        <Typography className={'flex-1'}>{location.name}</Typography>
      </div>
    );
  };
