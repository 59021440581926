import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dashboardActions } from '@/core/redux/slices/dashboard/dashboardSlice';
import { dashboardSelectors } from '@/core/redux/slices/dashboard/selectors';

export function useFetchedWidgets() {
  const dispatch = useAppDispatch();
  const widgets = useAppSelector(dashboardSelectors.widgets);

  useEffect(() => {
    dispatch(dashboardActions.fetchWidgets());
  }, [dispatch]);

  const onWidgetPositionUpdate = (newItems: any) => {
    dispatch(dashboardActions.updateWidgetPosition({ widgetsState: newItems }));
  };

  return { widgets, onWidgetPositionUpdate };
}
