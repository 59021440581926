import { useTranslation } from 'react-i18next';

import { useFecthedAppointments } from '@/components/Modals/Modals/efficacyAssessment/RecordingEffectivenessModal/hooks/useFetchedAppointments';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';

interface IAppointmentsTable {
  personID: number | undefined;
  fromDate: string;
  toDate: string;
  className?: string;
}

export const AppointmentsTable: React.FC<IAppointmentsTable> = ({
  personID,
  fromDate,
  toDate,
  className,
}) => {
  const { t: recordingEffectivenessModalTranslations } = useTranslation(
    'recordingEffectivenessModal',
    {
      keyPrefix: 'appointments',
    }
  );

  const columns: ITableColumn[] = [
    {
      header: recordingEffectivenessModalTranslations('table.header.fromTimestamp.label'),
      accessor: 'fromTimestamp',
    },
    {
      header: recordingEffectivenessModalTranslations('table.header.toTimestamp.label'),
      accessor: 'toTimestamp',
    },
    {
      header: recordingEffectivenessModalTranslations('table.header.hours.label'),
      accessor: 'hours',
    },
  ];

  const { appointments } = useFecthedAppointments({
    personID: personID,
    fromDate: fromDate,
    toDate: toDate,
  });

  return (
    <div className='flex flex-col gap-2'>
      <Typography>{recordingEffectivenessModalTranslations('title.label')}</Typography>
      {appointments.length ? (
        <Table columns={columns} data={appointments} className={twMerge('h-[200px]', className)} />
      ) : (
        <Typography>{recordingEffectivenessModalTranslations('empty.label')}</Typography>
      )}
    </div>
  );
};
