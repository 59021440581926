export type ButtonStyles = keyof typeof buttonStyles;

export const buttonStyles = {
  Default: `h-11 p-default rounded-default w-auto flex items-center justify-center text-center}`,
  Circle: 'w-auto aspect-square rounded-full p-1 flex items-center justify-center',
  Square: 'w-auto aspect-square rounded-default p-1 flex items-center justify-center',
  Icon: 'flex items-center justify-center',
  WithIcon: 'flex items-center justify-between p-default rounded-default',
  Text: 'w-auto text-start',
};
