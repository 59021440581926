import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../types';

const selector = (state: IRootState) => state.reports;
const reports = createSelector([selector], (state) => state.reports);

export const reportsSelectors = {
    reports,
};
