import { PayloadAction } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets/typings';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  ICreateIntegrationPlanDocumentsPayload,
  IIntegrationPlanPayload,
  IIntegrationPlanPredefinedVariant,
  IIntegrationPlanVersionHistoryItem,
  IIntegrationPlanVersionHistoryModalPayload,
  integrationPlanModalsActions,
  IQualificationObjectivesModalItem,
  IQualificationObjectivesPayload,
} from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type QualificationObjectivesResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/qualification_objective',
  'get',
  '200'
>;
type QualificationObjectivesRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/integration_plan/add_or_delete_qualification_objectives',
  'post'
>;

type IntegrationPlanPrintVariants = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/print_variants',
  'get',
  '200'
>;

type IntegrationPlanDocumentsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/integration_plan/print_integration_plan',
  'post'
>;

type IntegrationPlanVersionHistoryResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/history',
  'get',
  '200'
>;

interface IQualificationObjectiveRequestItem {
  id: number;
  is_selected: boolean;
}

interface IPrintVariant {
  id: number;
  name: string;
}

interface IRequestPredefinedPrintItem {
  id: number;
  name: string;
  variants_ids: number[];
}

const mapQualificationObjectives = (
  qualificationObjectives: Record<string, boolean>
): IQualificationObjectiveRequestItem[] => {
  const qualificationObjectivesKeys = Object.keys(qualificationObjectives);

  return qualificationObjectivesKeys.reduce<IQualificationObjectiveRequestItem[]>(
    (accum, qualificationObjectiveKey) => {
      return [
        ...accum,
        {
          id: parseInt(qualificationObjectiveKey),
          is_selected: qualificationObjectives[qualificationObjectiveKey],
        },
      ];
    },
    []
  );
};

const mapPrintVariants = (printVariants: IPrintVariant[]): Record<string, string> => {
  return printVariants.reduce((accum, printVariant) => {
    return {
      ...accum,
      [printVariant.id]: printVariant.name,
    };
  }, {});
};

const mapPredefinedPrintVariants = (
  predefinedVariants: IRequestPredefinedPrintItem[]
): IIntegrationPlanPredefinedVariant[] => {
  return predefinedVariants.reduce<IIntegrationPlanPredefinedVariant[]>(
    (accum, predefinedVariant) => {
      return [
        ...accum,
        {
          id: predefinedVariant.id,
          name: predefinedVariant.name,
          variantIds: predefinedVariant.variants_ids,
        },
      ];
    },
    []
  );
};

const mapIntegrationPlanVersionHistory = (
  response: IntegrationPlanVersionHistoryResponse
): IIntegrationPlanVersionHistoryItem[] => {
  const sortedHistory = response.history.sort((a, b) => {
    if (!a.printed_timestamp) return 1;
    if (!b.printed_timestamp) return -1;

    return parseISO(a.printed_timestamp).getTime() - parseISO(b.printed_timestamp).getTime();
  });

  return sortedHistory.map((version) => ({
    id: version.id,
    printedTimestamp: version.printed_timestamp,
  }));
};

function* fetchQualificationObjectivesModal(
  action: PayloadAction<IIntegrationPlanPayload>
): Generator<any, void, QualificationObjectivesResponse> {
  const { integrationPlanID, personID } = action.payload;

  try {
    const response = yield call(restCall, '/integration_plan/qualification_objective', 'get', {
      query: {
        integration_plan_id: integrationPlanID,
        person_id: personID,
      },
      ...authAdd(),
    });

    const { qualification_objectives } = response;

    qualification_objectives.sort((a, b) =>
      a.is_selected === b.is_selected ? 0 : a.is_selected ? 1 : -1
    );

    const qualificationObjectives = qualification_objectives.reduce<
      IQualificationObjectivesModalItem[]
    >((accum, qualificationObjective) => {
      return [
        ...accum,
        {
          id: qualificationObjective.id,
          qualificationObjectiveName: qualificationObjective.qualification_objective_name,
          fromTimestamp: toClientDateInput(qualificationObjective.from_timestamp),
          toTimestamp: toClientDateInput(qualificationObjective.to_timestamp),
          isSelected: qualificationObjective.is_selected,
        },
      ];
    }, []);

    yield put(
      integrationPlanModalsActions.setQualificationObjectivesModalData(qualificationObjectives)
    );
  } catch (error) {
    console.log('Error on qualification objective modal data fetching');
  }
}

function* updateQualificationObjectives(
  action: PayloadAction<IQualificationObjectivesPayload>
): Generator<any, void, any> {
  const { integrationPlanID, personID, qualificationsObjectives } = action.payload;

  if (qualificationsObjectives)
    try {
      const request: QualificationObjectivesRequest = {
        query: {
          integration_plan_id: integrationPlanID,
          person_id: personID,
        },
        json: mapQualificationObjectives(qualificationsObjectives),
        ...authAdd(),
      };

      yield call(restCall, '/integration_plan/add_or_delete_qualification_objectives', 'post', {
        ...request,
      });
    } catch (error) {
      console.log('Error on qualification objective data updating');
    }
}

function* fetchIntegrationPlanPrintVariants(): Generator<any, void, IntegrationPlanPrintVariants> {
  try {
    yield put(integrationPlanModalsActions.setPrintVariantsLoadingStatus(LoadingStatus.LOADING));

    const response = yield call(restCall, '/integration_plan/print_variants', 'get', {
      ...authAdd(),
    });

    const printVariant = mapPrintVariants(response.variants);

    yield put(integrationPlanModalsActions.setIntegrationPlanPrintVariants(printVariant));

    const predefinedVariants = mapPredefinedPrintVariants(response.predefined_variants);

    yield put(
      integrationPlanModalsActions.setIntegrationPlanPredefinedPrintVariants(predefinedVariants)
    );
    yield put(integrationPlanModalsActions.setPrintVariantsLoadingStatus(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on fetching integration plan print variants');
  }
}

function* getIntegrationPlanDocuments(
  action: PayloadAction<ICreateIntegrationPlanDocumentsPayload>
): Generator<any, void, any> {
  const { personID, integrationPlanID, finalizeIntegrationPlan, selectedIds } = action.payload;
  try {
    yield put(integrationPlanModalsActions.setGetDocumentsLoadingStatus(LoadingStatus.LOADING));

    const request: IntegrationPlanDocumentsRequest = {
      json: {
        finalize_integration_plan: finalizeIntegrationPlan ?? false,
        print_model: {
          integration_plan_id: integrationPlanID,
          person_id: personID,
          selected_ids: selectedIds,
        },
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/integration_plan/print_integration_plan',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    if (!response.ok) {
      return;
    }

    const blob = yield response.blob();

    const url = URL.createObjectURL(new Blob([blob]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'IntegrationPlanDocuments.pdf');

    document.body.appendChild(link);

    link.click();

    link.parentNode?.removeChild(link);

    yield put(integrationPlanModalsActions.setGetDocumentsLoadingStatus(LoadingStatus.LOADED));
    yield put(integrationPlanModalsActions.closeCreateIntegrationPlanDocumentsModal());
  } catch (error) {
    console.log('Error on getting integration plan documents');
  }
}

function* getPreviewIntegrationPlanDocuments(
  action: PayloadAction<ICreateIntegrationPlanDocumentsPayload>
): Generator<any, void, any> {
  const { personID, integrationPlanID, finalizeIntegrationPlan, selectedIds } = action.payload;
  try {
    yield put(integrationPlanModalsActions.setGetDocumentsLoadingStatus(LoadingStatus.LOADING));

    const request: IntegrationPlanDocumentsRequest = {
      json: {
        finalize_integration_plan: finalizeIntegrationPlan ?? false,
        print_model: {
          integration_plan_id: integrationPlanID,
          person_id: personID,
          selected_ids: selectedIds,
        },
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/integration_plan/print_integration_plan/merged',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    if (!response.ok) {
      return;
    }

    const blob = yield response.blob();

    const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));

    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';

    document.body.appendChild(link);

    link.click();

    link.parentNode?.removeChild(link);

    yield put(integrationPlanModalsActions.setGetDocumentsLoadingStatus(LoadingStatus.LOADED));
    yield put(integrationPlanModalsActions.closeCreateIntegrationPlanDocumentsModal());
  } catch (error) {
    console.log('Error on getting integration plan documents');
    yield put(integrationPlanModalsActions.setGetDocumentsLoadingStatus(LoadingStatus.ERROR));
  }
}

function* fetchIntegrationPlanHistory(
  action: PayloadAction<IIntegrationPlanVersionHistoryModalPayload>
): Generator<any, void, IntegrationPlanVersionHistoryResponse> {
  const { personID } = action.payload;

  try {
    yield integrationPlanModalsActions.setIntegrationPlanVersionHistoryLock(LoadingStatus.LOADING);

    const response = yield call(restCall, '/integration_plan/history', 'get', {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    });

    const versionHistory = mapIntegrationPlanVersionHistory(response);

    yield put(integrationPlanModalsActions.setIntegrationPlanVersionHistory(versionHistory));
    yield put(
      integrationPlanModalsActions.setIntegrationPlanVersionHistoryLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on fetching integration plan version history');
    yield put(
      integrationPlanModalsActions.setIntegrationPlanVersionHistoryLock(LoadingStatus.ERROR)
    );
  }
}

export const integrationPlanModalsSagas = [
  takeLatest(
    integrationPlanModalsActions.fetchQualificationObjectivesModal,
    fetchQualificationObjectivesModal
  ),
  takeLatest(
    integrationPlanModalsActions.updateQualificationObjectives,
    updateQualificationObjectives
  ),
  takeLatest(
    integrationPlanModalsActions.fetchIntegrationPlanPrintVariants,
    fetchIntegrationPlanPrintVariants
  ),
  takeLatest(integrationPlanModalsActions.getIntegrationPlanDocuments, getIntegrationPlanDocuments),
  takeLatest(
    integrationPlanModalsActions.getPreviewIntegrationPlanDocuments,
    getPreviewIntegrationPlanDocuments
  ),
  takeLatest(
    integrationPlanModalsActions.fetchIntegrationPlanVersionHistory,
    fetchIntegrationPlanHistory
  ),
];
