import React, { CSSProperties } from 'react';
import { Tooltip } from 'react-tooltip';

import { PersonItem } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/PersonItem/PersonItem';
import { PersonsListTooltip } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/PersonsListTooltip/PersonsListTooltip';
import { IPersonsContainer } from '@/types/activitiesWidget';

export const ActivityPersonsContainer: React.FC<IPersonsContainer> = ({
  personsList,
  activityID,
}) => {
  const trimmedPersonsList = personsList?.slice(0, 2);
  const remainingPersonsList = personsList.slice(2);
  const remainingPersonsCount = personsList.length - 2;

  const tooltipStyles: CSSProperties = {
    padding: 0,
  };

  const tooltipAnchorID = `activity-item-tooltip-${activityID}`;

  return (
    <div className={'flex flex-row w-1/3 -ms-[46px]'}>
      <div className={'flex flex-col justify-start items-start '}>
        {trimmedPersonsList.map((person) => (
          <PersonItem {...person} key={person.id} />
        ))}
      </div>
      <div className={'self-end ms-1 cursor-pointer'}>
        {remainingPersonsCount > 0 && (
          <a
            id={tooltipAnchorID}
            className={
              'hover:bg-blue hover:rounded-md hover:text-white p-1 text-primary font-inter'
            }
          >
            +{remainingPersonsCount}
          </a>
        )}
        <Tooltip
          anchorSelect={`#${tooltipAnchorID}`}
          style={tooltipStyles}
          opacity={1}
          positionStrategy={'fixed'}
        >
          <PersonsListTooltip personsList={remainingPersonsList} />
        </Tooltip>
      </div>
    </div>
  );
};
