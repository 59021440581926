import React from 'react';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { ActivityDate } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/ActivityDate/ActivityDate';
import { useDoubleClick } from '@/core/hooks/useDoubleClick';
import { useAppDispatch } from '@/core/redux/hooks';
import { widgetsModalsActions } from '@/core/redux/slices/modalsSlice/widgets/widgetsModalsSlice';
import { ISubActivity } from '@/types/activitiesWidget';

export const SubActivity: React.FC<ISubActivity> = ({ name, persons, is_expired, date }) => {
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    if (!persons) {
      return;
    }
    dispatch(widgetsModalsActions.openActivityPersonsListModal({ personList: persons }));
  };

  const handleDoubleClick = useDoubleClick(handleOpenModal);

  return (
    <div className={'flex flex-col w-2/3 py-[10px] relative'}>
      <Button onClick={handleDoubleClick} buttonStyle={'Text'} buttonVariant={'Transparent'}>
        <Typography type={'16-M'}>{name}</Typography>
        <ActivityDate date={date} isExpired={is_expired} />
      </Button>
    </div>
  );
};
