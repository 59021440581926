import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.selfAssesmentModals;

const m2sID = createSelector([selector], (state) => state.m2sID);
const simpleQuestionnaireModalState = createSelector(
  [selector],
  (state) => state.simpleQuestionnaireModalState
);
const defaultQuestionnaireModalState = createSelector(
  [selector],
  (state) => state.defaultQuestionnaireModalState
);

export const selfAssesmentModalsSelectors = {
  m2sID,
  simpleQuestionnaireModalState,
  defaultQuestionnaireModalState,
};
