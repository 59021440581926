import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface ISummaryFA {
  isPersonnelRequired: boolean;
  isEndeEv1: boolean;
  isEndeEv2: boolean;
  isEndeBBB1: boolean;
  isEndeBBB21: boolean;
  isEndeBBB22: boolean;
  isEndeBBB23: boolean;
  isEndeBBB24: boolean;
  isEndeBBB25: boolean;
  isEndeBBB26: boolean;
  isUnsuitableWfbM: boolean;
  notes?: string | null;
}

export interface ISummaryFAFetchPayload {
  integrationPlanID: number;
}

export interface ISummaryFAUpdatePayload {
  integrationPlanID: number;
  personID: number;
  summaryFA: ISummaryFA;
}

export interface ISummaryFAState {
  summaryFA: ISummaryFA | null;
  summaryFALock: LoadingStatus;
  updateSummaryFALock: LoadingStatus;
}

const initialState: ISummaryFAState = {
  summaryFA: null,
  summaryFALock: LoadingStatus.NEVER,
  updateSummaryFALock: LoadingStatus.NEVER,
};

export const summaryFASlice = createSlice({
  name: 'summaryFA',
  initialState,
  reducers: {
    fetchSummaryFA: (state, action: PayloadAction<ISummaryFAFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateSummaryFA: (state, action: PayloadAction<ISummaryFAUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setSummaryFALock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      summaryFALock: action.payload,
    }),
    setUpdateSummaryFALock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      summaryFALock: action.payload,
    }),
    setSummaryFA: (state, action: PayloadAction<ISummaryFA | null>) => ({
      ...state,
      summaryFA: action.payload,
    }),
  },
});

export const summaryFAReducers = summaryFASlice.reducer;

export const summaryFAActions = summaryFASlice.actions;
