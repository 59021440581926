import React, { useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import clsx from 'clsx';
import fp from 'lodash/fp';

import { Skeleton } from '@/components/Skeleton/Skeleton';
import { AdministrationFunctionCard } from '@/components/views/administration/AdministrationFunctionContainer/AdministrationFunctionGridView/AdministrationFunctionCard/AdministrationFunctionCard';
import { AdministrationFunctionGridViewEmptyPlaceholder } from '@/components/views/administration/AdministrationFunctionContainer/AdministrationFunctionGridView/AdministrationFunctionGridViewEmptyPlaceholder/AdministrationFunctionGridViewEmptyPlaceholder';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { administrationPageActions } from '@/core/redux/slices/administrationPage/slice';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { IFunctionItem, IFunctionView } from '@/types/functions';
import { LoadingStatus } from '@/types/loadingStatus';

import styles from './styles.module.scss';

export const AdministrationFunctionGridView: React.FC<IFunctionView> = ({ functions }) => {
  const [selectedState, setSelectedState] = useState<IFunctionItem[]>([]);
  const [isEmptyState, setIsEmptyState] = useState(false);

  const functionsLock = useAppSelector(functionPageSelectors.functionsLock);

  const dispatch = useAppDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setSelectedState((functions) => {
        const oldIndex = fp.findIndex(['id', active?.id], functions);
        const newIndex = fp.findIndex(['id', over?.id], functions);

        const newState = arrayMove(functions, oldIndex, newIndex);
        dispatch(administrationPageActions.updateFunctionOrderNumber({ functions: newState }));
        return newState;
      });
    }
  };

  useEffect(() => {
    if (!functions) {
      return;
    }
    const favoriteFunctions: IFunctionItem[] = functions.filter((func) => func.isPinned);
    favoriteFunctions.sort((a, b) => a.orderNumber - b.orderNumber);
    setSelectedState(favoriteFunctions);

    setIsEmptyState(favoriteFunctions.length < 1);
  }, [functions]);

  return (
    <Skeleton trigger={functionsLock === LoadingStatus.LOADING} rows={10}>
      {isEmptyState ? (
        <AdministrationFunctionGridViewEmptyPlaceholder />
      ) : (
        <div className={clsx(styles.functionsList, 'flex flex-wrap ms-[30px] mt-[4px]')}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={selectedState} strategy={rectSortingStrategy}>
              {selectedState.map((func) => (
                <AdministrationFunctionCard
                  key={func.id}
                  id={func.id}
                  functionType={func.functionType}
                  name={func.name}
                  iconUrl={func.iconUrl}
                  isPinned={func.isPinned}
                  orderNumber={func.orderNumber}
                  functionID={func.functionID}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      )}
    </Skeleton>
  );
};
