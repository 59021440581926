import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { QuestionnaireAnswerOptions } from '@/core/enums/functions/selfAssessment/questionnaireAnswersEnum';
import {
  ISelfAssessmentAnswer,
  ISelfAssessmentQuestion,
} from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';

interface IDefaultQuestionnaireItem {
  question: ISelfAssessmentQuestion;
  answer: ISelfAssessmentAnswer | null;
  bgColor?: string;
  handleAnswer: (
    question: ISelfAssessmentQuestion,
    answer: ISelfAssessmentAnswer | null,
    selectedOption: QuestionnaireAnswerOptions,
    note: string
  ) => void;
}

export const DefaultQuestionnaireItem: React.FC<IDefaultQuestionnaireItem> = ({
  question,
  answer,
  bgColor,
  handleAnswer,
}) => {
  const { t: selfAssesmentTranslations } = useTranslation('selfAssesment');

  const { watch, setValue, control } = useFormContext();

  const selectedAnswer = watch(`question.${question.sefID}.selectedAnswer`);
  const note = watch(`question.${question.sefID}.note`);

  useEffect(() => {
    if (!answer) {
      return;
    }
    setValue(`question.${question.sefID}.selectedAnswer`, `${answer.answer}`);
    setValue(`question.${question.sefID}.note`, `${answer.note}`);
  }, [question, answer]);

  useEffect(() => {
    if (!selectedAnswer && !note) {
      return;
    }

    handleAnswer(question, answer, Number(selectedAnswer), note);
  }, [selectedAnswer, note]);

  const options: IRadioButtonGroupItem[] = [
    {
      value: `${QuestionnaireAnswerOptions.YES}`,
      element: <Typography>{selfAssesmentTranslations('fields.yes.label')}</Typography>,
    },
    {
      value: `${QuestionnaireAnswerOptions.NO}`,
      element: <Typography>{selfAssesmentTranslations('fields.no.label')}</Typography>,
    },
    {
      value: `${QuestionnaireAnswerOptions.DUNNO}`,
      element: <Typography>{selfAssesmentTranslations('fields.dunno.label')}</Typography>,
    },
  ];

  if (question) {
    return (
      <>
        <tr key={question?.sefID}>
          <td className={'p-4'}>
            <Typography className='font-normal'>{question.name}</Typography>
          </td>
          <td className={'p-4'}>
            <RadioButtonGroup
              options={options}
              name={`question.${question.sefID}.selectedAnswer`}
              className={'flex flex-wrap float-right'}
              itemClassName={''}
              layout={'horizontal'}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2} className='p-4'>
            <TextArea control={control.register(`question.${question.sefID}.note`)} />
          </td>
        </tr>
      </>
    );
  }

  return null;
};
