import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { CircleFilledIcon } from '@/components/Icons/CircleFilledIcon';
import { CircleOutlineIcon } from '@/components/Icons/CircleOutlineIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { efficacyAssessmentActions } from '@/core/redux/slices/efficacyAssessment/efficacyAssessmentSlice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import { ListItem } from '../ListItem/ListItem';

interface IItem {
  id: number;
  items: any[];
  name: string;
}

interface IListGroup {
  id: number;
  items: any[];
  layer: number;
  name: string;
  childGroups?: IItem[];
}

export const DEFAULT_LAYER_PADDING = 40;

export const ListGroup: React.FC<IListGroup> = ({
  id: groupID,
  items,
  childGroups,
  layer,
  name,
}) => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<boolean>(true);

  const oddColor = useAppSelector(parametersSelectors.vocationalTrainingAreaItemOddColor);
  const evenColor = useAppSelector(parametersSelectors.vocationalTrainingAreaItemEvenColor);

  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const handleSelectMeasure = () => {
    dispatch(
      efficacyAssessmentActions.setSelectedMeasure({
        id: groupID,
        name: name,
        layer: layer,
      })
    );
  };

  return (
    <div className={'col-span-12 grid grid-cols-12'}>
      <Button
        buttonVariant={'Transparent'}
        buttonStyle={'Text'}
        onClick={handleExpand}
        className={`col-span-12 hover:bg-${colorTypes.Hover} px-5`}
      >
        <Typography
          style={{ paddingLeft: DEFAULT_LAYER_PADDING * layer }}
          type={'16-M'}
          className={'flex flex-row items-center gap-2 py-1'}
        >
          {expanded ? (
            <CircleFilledIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
          ) : (
            <CircleOutlineIcon className={`text-${colorTypes.Tiffany} w-[10px]`} />
          )}
          {name}
        </Typography>
      </Button>
      <motion.div
        className={twMerge(`col-span-12 grid grid-cols-12 w-full overflow-hidden`)}
        initial={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
        animate={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
      >
        <div className={'col-span-12 grid grid-cols-12'}>
          {childGroups &&
            childGroups.map((child) => <ListGroup {...child} layer={layer + 1} key={child.id} />)}
          {items &&
            items.map((item, index) => (
              <ListItem
                {...item}
                groupID={groupID}
                key={item.id}
                layer={layer}
                bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
                onMeasureSelected={handleSelectMeasure}
              />
            ))}
        </div>
      </motion.div>
    </div>
  );
};
