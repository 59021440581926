import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.integrationPlan.competenceAnalysis;

const competenceAnalysis = createSelector([selector], (state) => state.competenceAnalysis);
const competenceAnalysisIsImported = createSelector(
  [selector],
  (state) => state.isCompetenceAnalysisImported
);
const competenceAnalysisLock = createSelector([selector], (state) => state.competenceAnalysisLock);
const updateCompetenceAnalysisLock = createSelector(
  [selector],
  (state) => state.updateCompetenceAnalysisLock
);

export const competenceAnalysisSelectors = {
  competenceAnalysis,
  competenceAnalysisIsImported,
  competenceAnalysisLock,
  updateCompetenceAnalysisLock,
};
