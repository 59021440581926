import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.logbookModals;

const logbookEditModal = createSelector([selector], (state) => state.logbookEditModal.isRender);
const logbookListModal = createSelector([selector], (state) => state.logbookListModal.isRender);
const newLogbookEntryModal = createSelector([selector], (state) => state.newLogbookEntryModal.isRender);

export const logbookModalsSelectors = {
  logbookEditModal,
  logbookListModal,
  newLogbookEntryModal
};
