import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { useBarChartForm } from '@/components/functions/PerformanceProfile/CompetenciesCharts/CompetenciesBarChart/hooks/useBarChartForm';
import { BarCustomPointLabel } from '@/components/functions/PerformanceProfile/CompetenciesCharts/CustomPointLabel/BarCustomPointLabel';
import { useChartData } from '@/components/functions/PerformanceProfile/CompetenciesCharts/hooks/useChartData';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { colorSchema } from '@/core/constants/colors';
import { ICompetenceReviewItem } from '@/core/redux/slices/functions/performanceProfile/slice';

interface IChart {
  data?: ICompetenceReviewItem[];
  domain?: {
    min: number;
    max: number;
  };
}

export const CompetenciesBarChart: React.FC<IChart> = ({
  data = [],
  domain = { min: 0, max: 4 },
}) => {
  const { t: chartsTranslations } = useTranslation('performanceProfileCharts');

  const { form, chartLayout, showLabel } = useBarChartForm();

  const { chartsData, chartsKeys } = useChartData(data);

  const chartLayoutOptions: IRadioButtonGroupItem[] = [
    {
      value: 'vertical',
      element: <Typography>{chartsTranslations('fields.horizontalLayout.label')}</Typography>,
    },
    {
      value: 'horizontal',
      element: <Typography>{chartsTranslations('fields.verticalLayout.label')}</Typography>,
    },
  ];

  return (
    <>
      <div className={'row-span-2'}>
        <ResponsiveContainer>
          <BarChart data={chartsData} layout={chartLayout} margin={{ top: 25 }}>
            <CartesianGrid strokeDasharray='3 3' />
            {chartLayout === 'vertical' ? (
              <>
                <YAxis dataKey={'index'} type={'category'} />
                <XAxis domain={[0, domain.max]} type={'number'} />
              </>
            ) : (
              <>
                <XAxis dataKey={'index'} />
                <YAxis domain={[0, domain.max]} />
              </>
            )}
            <Legend />
            <Tooltip />
            {chartsKeys.length > 0 &&
              chartsKeys.map((chartKey, index) => (
                <Bar dataKey={chartKey} name={chartKey} key={chartKey} fill={colorSchema[index]}>
                  <LabelList
                    dataKey={chartKey}
                    content={(props) => (
                      <BarCustomPointLabel
                        labelProps={props}
                        chartLayout={chartLayout}
                        isShowLabel={showLabel}
                      />
                    )}
                  />
                </Bar>
              ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
      <FormProvider {...form}>
        <div
          className={'flex flex-col gap-2 p-4 border rounded-default border-${colorTypes.Pressed}'}
        >
          <RadioButtonGroup
            name={'chartLayout'}
            options={chartLayoutOptions}
            className={'flex flex-col gap-2'}
            itemClassName={'flex flex-row'}
          />
          <CheckboxItem
            label={chartsTranslations('fields.showLabels.label')}
            fieldName={'showLabel'}
            labelPosition={'right'}
            className={'mt-4'}
          />
        </div>
      </FormProvider>
    </>
  );
};
