import React, { useEffect, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { isNumber } from 'lodash';

import { AdministrationLocationManagementLocationGroup } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementLocationList/AdministrationLocationManagementLocationGroup';
import { AdministrationLocationManagementLocationHeader } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementLocationList/AdministrationLocationManagementLocationHeader';
import { AdministrationLocationManagementLocationItem } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementLocationList/AdministrationLocationManagementLocationItem';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { administrationLocationManagementSelectors } from '@/core/redux/slices/administrationFunctions/locationManagement/selectors';
import {
  administrationLocationManagementActions,
  IAdministrationLocation,
} from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { colorTypes } from '@/styles/types';

function findLocationById(
  id: number,
  locations: IAdministrationLocation[]
): IAdministrationLocation | null {
  for (const location of locations) {
    if (location.id === id) {
      return location;
    }

    if (location.locations) {
      const found = findLocationById(id, location.locations);
      if (found) {
        return found;
      }
    }
  }

  return null;
}

function moveLocation(
  draggedId: number,
  targetId: number,
  locations: IAdministrationLocation[]
): IAdministrationLocation[] {
  const locationsCopy = structuredClone(locations);

  const draggedLocation = findLocationById(draggedId, locationsCopy);
  const targetLocation = findLocationById(targetId, locationsCopy);

  if (!draggedLocation || !targetLocation) {
    return locationsCopy;
  }

  function removeLocation(id: number, locations: IAdministrationLocation[]): boolean {
    const index = locations.findIndex((location) => location.id === id);
    if (index !== -1) {
      locations.splice(index, 1);
      return true;
    }
    for (const location of locations) {
      if (location.locations && removeLocation(id, location.locations)) {
        return true;
      }
    }
    return false;
  }

  if (!removeLocation(draggedId, locationsCopy)) {
    return locationsCopy;
  }

  if (!targetLocation.locations) {
    targetLocation.locations = [];
  }
  targetLocation.locations.push(draggedLocation);

  function updateCounts(location: IAdministrationLocation): {
    locationsCount: number;
    departmentsCount: number;
  } {
    let totalLocationsCount = 0;
    let totalDepartmentsCount = location.departmentsCount;

    if (location.locations) {
      for (const subLocation of location.locations) {
        const counts = updateCounts(subLocation);
        totalLocationsCount += counts.locationsCount + 1; // Учитываем дочернюю локацию
        totalDepartmentsCount += counts.departmentsCount;
      }
    }

    location.locationsCount = totalLocationsCount;
    location.departmentsCount = totalDepartmentsCount;

    return { locationsCount: totalLocationsCount, departmentsCount: totalDepartmentsCount };
  }

  for (const location of locationsCopy) {
    updateCounts(location);
  }

  return locationsCopy;
}

export const AdministrationLocationManagementLocationList: React.FC = () => {
  const [draggableLocation, setDraggableLocation] = useState<IAdministrationLocation | null>(null);

  const locations = useAppSelector(administrationLocationManagementSelectors.locations);
  const locationsLock = useAppSelector(administrationLocationManagementSelectors.locationsLock);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(administrationLocationManagementActions.fetchLocations());
  }, []);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    const activeID = Number(active.id);
    const overID = Number(over?.id);

    const newState = moveLocation(Number(active.id), Number(over?.id), locations);

    dispatch(administrationLocationManagementActions.setLocations(newState));

    setDraggableLocation(null);

    if (isNumber(activeID) && isNumber(overID)) {
      dispatch(
        administrationLocationManagementActions.updateLocationParent({
          locationID: activeID,
          targetID: overID,
        })
      );
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    const foundLocation = findLocationById(Number(event.active.id), locations);

    setDraggableLocation(foundLocation);
  };

  const sensors = useSensors(useSensor(PointerSensor));

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <div className={'h-full flex flex-col overflow-hidden'}>
        <AdministrationLocationManagementLocationHeader />
        <div className={'flex flex-col flex-1 h-full overflow-y-auto'}>
          {locations &&
            locations.map((location) => (
              <AdministrationLocationManagementLocationGroup
                location={location}
                layer={0}
                key={location.id}
              />
            ))}
        </div>
        <DragOverlay style={{ opacity: '100%' }}>
          {draggableLocation && (
            <div className={'relative'}>
              <AdministrationLocationManagementLocationItem
                layer={0}
                location={draggableLocation}
                className={'border-none rounded-default'}
              />
              <div
                className={`absolute inset-0 rounded-default border-2 border-${colorTypes.Blue} border-dashed`}
                style={{ top: '-3px', bottom: '-3px', left: '-3px', right: '-3px' }}
              />
            </div>
          )}
        </DragOverlay>
      </div>
    </DndContext>
  );
};
