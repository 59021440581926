import { useForm } from 'react-hook-form';

import { useSelectedPersonForm } from '@/components/functions/IntegrationPlan/hooks/useSelectedPersonForm';
import { IIntegrationPlanFormProps } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsContainer/IntegrationPlanFormsContainer';
import { useAppSelector } from '@/core/redux/hooks';
import { IGeneralData } from '@/core/redux/slices/functions/integrationPlan/generalData/generalDataSlice';
import { generalDataSelectors } from '@/core/redux/slices/functions/integrationPlan/generalData/selectors';

export function useGeneralDataForm({ integrationPlanID }: IIntegrationPlanFormProps) {
  const initialValues = useAppSelector(generalDataSelectors.generalData);
  const form = useForm<IGeneralData>();

  useSelectedPersonForm({ form, integrationPlanID, initialValues: initialValues });

  return { form, initialValues };
}
