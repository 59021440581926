import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.personGeneralData.personData;

const personData = createSelector([selector], (state) => state.personData);
const personDataLock = createSelector([selector], (state) => state.personDataLock);

const updatePersonDataLock = createSelector([selector], (state) => state.updatePersonDataLock);

const serviceProviders = createSelector([selector], (state) => state.serviceProviders);

export const personDataSelectors = {
  personData,
  personDataLock,
  serviceProviders,
  updatePersonDataLock,
};
