import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {"context":{"isSystem":false}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** ISO8601 Date values */
  Date: { input: any; output: any; }
  /** BigInt value */
  GraphQLBigInt: { input: any; output: any; }
  /** A Float or a String */
  GraphQLStringOrFloat: { input: any; output: any; }
  /** Hashed string values */
  Hash: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export enum EventEnum {
  Create = 'create',
  Delete = 'delete',
  Update = 'update'
}

export type Mutation = {
  __typename?: 'Mutation';
  create_administration_function_types_item?: Maybe<Administration_Function_Types>;
  create_administration_function_types_items: Array<Administration_Function_Types>;
  create_administration_functions_item?: Maybe<Administration_Functions>;
  create_administration_functions_items: Array<Administration_Functions>;
  create_administration_functions_settings_item?: Maybe<Administration_Functions_Settings>;
  create_administration_functions_settings_items: Array<Administration_Functions_Settings>;
  create_function_types_item?: Maybe<Function_Types>;
  create_function_types_items: Array<Function_Types>;
  create_functions_item?: Maybe<Functions>;
  create_functions_items: Array<Functions>;
  create_functions_settings_item?: Maybe<Functions_Settings>;
  create_functions_settings_items: Array<Functions_Settings>;
  create_logs_item?: Maybe<Logs>;
  create_logs_items: Array<Logs>;
  create_lt_se_bogen_item?: Maybe<Lt_Se_Bogen>;
  create_lt_se_bogen_items: Array<Lt_Se_Bogen>;
  create_lt_se_fragen_item?: Maybe<Lt_Se_Fragen>;
  create_lt_se_fragen_items: Array<Lt_Se_Fragen>;
  create_parameter_groups_item?: Maybe<Parameter_Groups>;
  create_parameter_groups_items: Array<Parameter_Groups>;
  create_parameter_types_item?: Maybe<Parameter_Types>;
  create_parameter_types_items: Array<Parameter_Types>;
  create_parameters_item?: Maybe<Parameters>;
  create_parameters_items: Array<Parameters>;
  create_parameters_parameter_groups_item?: Maybe<Parameters_Parameter_Groups>;
  create_parameters_parameter_groups_items: Array<Parameters_Parameter_Groups>;
  create_reports_item?: Maybe<Reports>;
  create_reports_items: Array<Reports>;
  create_settings_item?: Maybe<Settings>;
  create_settings_items: Array<Settings>;
  create_settings_widgets_item?: Maybe<Settings_Widgets>;
  create_settings_widgets_items: Array<Settings_Widgets>;
  create_shortcut_functions_item?: Maybe<Shortcut_Functions>;
  create_shortcut_functions_items: Array<Shortcut_Functions>;
  create_t_se_antworten_item?: Maybe<T_Se_Antworten>;
  create_t_se_antworten_items: Array<T_Se_Antworten>;
  create_t_se_bogen2frage_item?: Maybe<T_Se_Bogen2frage>;
  create_t_se_bogen2frage_items: Array<T_Se_Bogen2frage>;
  create_t_se_ma2seb_item?: Maybe<T_Se_Ma2seb>;
  create_t_se_ma2seb_items: Array<T_Se_Ma2seb>;
  create_t_se_status_item?: Maybe<T_Se_Status>;
  create_t_se_status_items: Array<T_Se_Status>;
  create_widgets_item?: Maybe<Widgets>;
  create_widgets_items: Array<Widgets>;
  delete_administration_function_types_item?: Maybe<Delete_One>;
  delete_administration_function_types_items?: Maybe<Delete_Many>;
  delete_administration_functions_item?: Maybe<Delete_One>;
  delete_administration_functions_items?: Maybe<Delete_Many>;
  delete_administration_functions_settings_item?: Maybe<Delete_One>;
  delete_administration_functions_settings_items?: Maybe<Delete_Many>;
  delete_function_types_item?: Maybe<Delete_One>;
  delete_function_types_items?: Maybe<Delete_Many>;
  delete_functions_item?: Maybe<Delete_One>;
  delete_functions_items?: Maybe<Delete_Many>;
  delete_functions_settings_item?: Maybe<Delete_One>;
  delete_functions_settings_items?: Maybe<Delete_Many>;
  delete_logs_item?: Maybe<Delete_One>;
  delete_logs_items?: Maybe<Delete_Many>;
  delete_lt_se_bogen_item?: Maybe<Delete_One>;
  delete_lt_se_bogen_items?: Maybe<Delete_Many>;
  delete_lt_se_fragen_item?: Maybe<Delete_One>;
  delete_lt_se_fragen_items?: Maybe<Delete_Many>;
  delete_parameter_groups_item?: Maybe<Delete_One>;
  delete_parameter_groups_items?: Maybe<Delete_Many>;
  delete_parameter_types_item?: Maybe<Delete_One>;
  delete_parameter_types_items?: Maybe<Delete_Many>;
  delete_parameters_item?: Maybe<Delete_One>;
  delete_parameters_items?: Maybe<Delete_Many>;
  delete_parameters_parameter_groups_item?: Maybe<Delete_One>;
  delete_parameters_parameter_groups_items?: Maybe<Delete_Many>;
  delete_reports_item?: Maybe<Delete_One>;
  delete_reports_items?: Maybe<Delete_Many>;
  delete_settings_item?: Maybe<Delete_One>;
  delete_settings_items?: Maybe<Delete_Many>;
  delete_settings_widgets_item?: Maybe<Delete_One>;
  delete_settings_widgets_items?: Maybe<Delete_Many>;
  delete_shortcut_functions_item?: Maybe<Delete_One>;
  delete_shortcut_functions_items?: Maybe<Delete_Many>;
  delete_t_se_antworten_item?: Maybe<Delete_One>;
  delete_t_se_antworten_items?: Maybe<Delete_Many>;
  delete_t_se_bogen2frage_item?: Maybe<Delete_One>;
  delete_t_se_bogen2frage_items?: Maybe<Delete_Many>;
  delete_t_se_ma2seb_item?: Maybe<Delete_One>;
  delete_t_se_ma2seb_items?: Maybe<Delete_Many>;
  delete_t_se_status_item?: Maybe<Delete_One>;
  delete_t_se_status_items?: Maybe<Delete_Many>;
  delete_widgets_item?: Maybe<Delete_One>;
  delete_widgets_items?: Maybe<Delete_Many>;
  update_administration_function_types_batch: Array<Administration_Function_Types>;
  update_administration_function_types_item?: Maybe<Administration_Function_Types>;
  update_administration_function_types_items: Array<Administration_Function_Types>;
  update_administration_functions_batch: Array<Administration_Functions>;
  update_administration_functions_item?: Maybe<Administration_Functions>;
  update_administration_functions_items: Array<Administration_Functions>;
  update_administration_functions_settings_batch: Array<Administration_Functions_Settings>;
  update_administration_functions_settings_item?: Maybe<Administration_Functions_Settings>;
  update_administration_functions_settings_items: Array<Administration_Functions_Settings>;
  update_function_types_batch: Array<Function_Types>;
  update_function_types_item?: Maybe<Function_Types>;
  update_function_types_items: Array<Function_Types>;
  update_functions_batch: Array<Functions>;
  update_functions_item?: Maybe<Functions>;
  update_functions_items: Array<Functions>;
  update_functions_settings_batch: Array<Functions_Settings>;
  update_functions_settings_item?: Maybe<Functions_Settings>;
  update_functions_settings_items: Array<Functions_Settings>;
  update_logs_batch: Array<Logs>;
  update_logs_item?: Maybe<Logs>;
  update_logs_items: Array<Logs>;
  update_lt_se_bogen_batch: Array<Lt_Se_Bogen>;
  update_lt_se_bogen_item?: Maybe<Lt_Se_Bogen>;
  update_lt_se_bogen_items: Array<Lt_Se_Bogen>;
  update_lt_se_fragen_batch: Array<Lt_Se_Fragen>;
  update_lt_se_fragen_item?: Maybe<Lt_Se_Fragen>;
  update_lt_se_fragen_items: Array<Lt_Se_Fragen>;
  update_parameter_groups_batch: Array<Parameter_Groups>;
  update_parameter_groups_item?: Maybe<Parameter_Groups>;
  update_parameter_groups_items: Array<Parameter_Groups>;
  update_parameter_types_batch: Array<Parameter_Types>;
  update_parameter_types_item?: Maybe<Parameter_Types>;
  update_parameter_types_items: Array<Parameter_Types>;
  update_parameters_batch: Array<Parameters>;
  update_parameters_item?: Maybe<Parameters>;
  update_parameters_items: Array<Parameters>;
  update_parameters_parameter_groups_batch: Array<Parameters_Parameter_Groups>;
  update_parameters_parameter_groups_item?: Maybe<Parameters_Parameter_Groups>;
  update_parameters_parameter_groups_items: Array<Parameters_Parameter_Groups>;
  update_reports_batch: Array<Reports>;
  update_reports_item?: Maybe<Reports>;
  update_reports_items: Array<Reports>;
  update_settings_batch: Array<Settings>;
  update_settings_item?: Maybe<Settings>;
  update_settings_items: Array<Settings>;
  update_settings_widgets_batch: Array<Settings_Widgets>;
  update_settings_widgets_item?: Maybe<Settings_Widgets>;
  update_settings_widgets_items: Array<Settings_Widgets>;
  update_shortcut_functions_batch: Array<Shortcut_Functions>;
  update_shortcut_functions_item?: Maybe<Shortcut_Functions>;
  update_shortcut_functions_items: Array<Shortcut_Functions>;
  update_t_se_antworten_batch: Array<T_Se_Antworten>;
  update_t_se_antworten_item?: Maybe<T_Se_Antworten>;
  update_t_se_antworten_items: Array<T_Se_Antworten>;
  update_t_se_bogen2frage_batch: Array<T_Se_Bogen2frage>;
  update_t_se_bogen2frage_item?: Maybe<T_Se_Bogen2frage>;
  update_t_se_bogen2frage_items: Array<T_Se_Bogen2frage>;
  update_t_se_ma2seb_batch: Array<T_Se_Ma2seb>;
  update_t_se_ma2seb_item?: Maybe<T_Se_Ma2seb>;
  update_t_se_ma2seb_items: Array<T_Se_Ma2seb>;
  update_t_se_status_batch: Array<T_Se_Status>;
  update_t_se_status_item?: Maybe<T_Se_Status>;
  update_t_se_status_items: Array<T_Se_Status>;
  update_widgets_batch: Array<Widgets>;
  update_widgets_item?: Maybe<Widgets>;
  update_widgets_items: Array<Widgets>;
};


export type MutationCreate_Administration_Function_Types_ItemArgs = {
  data: Create_Administration_Function_Types_Input;
};


export type MutationCreate_Administration_Function_Types_ItemsArgs = {
  data?: InputMaybe<Array<Create_Administration_Function_Types_Input>>;
  filter?: InputMaybe<Administration_Function_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Administration_Functions_ItemArgs = {
  data: Create_Administration_Functions_Input;
};


export type MutationCreate_Administration_Functions_ItemsArgs = {
  data?: InputMaybe<Array<Create_Administration_Functions_Input>>;
  filter?: InputMaybe<Administration_Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Administration_Functions_Settings_ItemArgs = {
  data: Create_Administration_Functions_Settings_Input;
};


export type MutationCreate_Administration_Functions_Settings_ItemsArgs = {
  data?: InputMaybe<Array<Create_Administration_Functions_Settings_Input>>;
  filter?: InputMaybe<Administration_Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Function_Types_ItemArgs = {
  data: Create_Function_Types_Input;
};


export type MutationCreate_Function_Types_ItemsArgs = {
  data?: InputMaybe<Array<Create_Function_Types_Input>>;
  filter?: InputMaybe<Function_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Functions_ItemArgs = {
  data: Create_Functions_Input;
};


export type MutationCreate_Functions_ItemsArgs = {
  data?: InputMaybe<Array<Create_Functions_Input>>;
  filter?: InputMaybe<Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Functions_Settings_ItemArgs = {
  data: Create_Functions_Settings_Input;
};


export type MutationCreate_Functions_Settings_ItemsArgs = {
  data?: InputMaybe<Array<Create_Functions_Settings_Input>>;
  filter?: InputMaybe<Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Logs_ItemArgs = {
  data: Create_Logs_Input;
};


export type MutationCreate_Logs_ItemsArgs = {
  data?: InputMaybe<Array<Create_Logs_Input>>;
  filter?: InputMaybe<Logs_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Lt_Se_Bogen_ItemArgs = {
  data: Create_Lt_Se_Bogen_Input;
};


export type MutationCreate_Lt_Se_Bogen_ItemsArgs = {
  data?: InputMaybe<Array<Create_Lt_Se_Bogen_Input>>;
  filter?: InputMaybe<Lt_Se_Bogen_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Lt_Se_Fragen_ItemArgs = {
  data: Create_Lt_Se_Fragen_Input;
};


export type MutationCreate_Lt_Se_Fragen_ItemsArgs = {
  data?: InputMaybe<Array<Create_Lt_Se_Fragen_Input>>;
  filter?: InputMaybe<Lt_Se_Fragen_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Parameter_Groups_ItemArgs = {
  data: Create_Parameter_Groups_Input;
};


export type MutationCreate_Parameter_Groups_ItemsArgs = {
  data?: InputMaybe<Array<Create_Parameter_Groups_Input>>;
  filter?: InputMaybe<Parameter_Groups_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Parameter_Types_ItemArgs = {
  data: Create_Parameter_Types_Input;
};


export type MutationCreate_Parameter_Types_ItemsArgs = {
  data?: InputMaybe<Array<Create_Parameter_Types_Input>>;
  filter?: InputMaybe<Parameter_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Parameters_ItemArgs = {
  data: Create_Parameters_Input;
};


export type MutationCreate_Parameters_ItemsArgs = {
  data?: InputMaybe<Array<Create_Parameters_Input>>;
  filter?: InputMaybe<Parameters_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Parameters_Parameter_Groups_ItemArgs = {
  data: Create_Parameters_Parameter_Groups_Input;
};


export type MutationCreate_Parameters_Parameter_Groups_ItemsArgs = {
  data?: InputMaybe<Array<Create_Parameters_Parameter_Groups_Input>>;
  filter?: InputMaybe<Parameters_Parameter_Groups_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Reports_ItemArgs = {
  data: Create_Reports_Input;
};


export type MutationCreate_Reports_ItemsArgs = {
  data?: InputMaybe<Array<Create_Reports_Input>>;
  filter?: InputMaybe<Reports_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Settings_ItemArgs = {
  data: Create_Settings_Input;
};


export type MutationCreate_Settings_ItemsArgs = {
  data?: InputMaybe<Array<Create_Settings_Input>>;
  filter?: InputMaybe<Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Settings_Widgets_ItemArgs = {
  data: Create_Settings_Widgets_Input;
};


export type MutationCreate_Settings_Widgets_ItemsArgs = {
  data?: InputMaybe<Array<Create_Settings_Widgets_Input>>;
  filter?: InputMaybe<Settings_Widgets_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Shortcut_Functions_ItemArgs = {
  data: Create_Shortcut_Functions_Input;
};


export type MutationCreate_Shortcut_Functions_ItemsArgs = {
  data?: InputMaybe<Array<Create_Shortcut_Functions_Input>>;
  filter?: InputMaybe<Shortcut_Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_T_Se_Antworten_ItemArgs = {
  data: Create_T_Se_Antworten_Input;
};


export type MutationCreate_T_Se_Antworten_ItemsArgs = {
  data?: InputMaybe<Array<Create_T_Se_Antworten_Input>>;
  filter?: InputMaybe<T_Se_Antworten_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_T_Se_Bogen2frage_ItemArgs = {
  data: Create_T_Se_Bogen2frage_Input;
};


export type MutationCreate_T_Se_Bogen2frage_ItemsArgs = {
  data?: InputMaybe<Array<Create_T_Se_Bogen2frage_Input>>;
  filter?: InputMaybe<T_Se_Bogen2frage_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_T_Se_Ma2seb_ItemArgs = {
  data: Create_T_Se_Ma2seb_Input;
};


export type MutationCreate_T_Se_Ma2seb_ItemsArgs = {
  data?: InputMaybe<Array<Create_T_Se_Ma2seb_Input>>;
  filter?: InputMaybe<T_Se_Ma2seb_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_T_Se_Status_ItemArgs = {
  data: Create_T_Se_Status_Input;
};


export type MutationCreate_T_Se_Status_ItemsArgs = {
  data?: InputMaybe<Array<Create_T_Se_Status_Input>>;
  filter?: InputMaybe<T_Se_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Widgets_ItemArgs = {
  data: Create_Widgets_Input;
};


export type MutationCreate_Widgets_ItemsArgs = {
  data?: InputMaybe<Array<Create_Widgets_Input>>;
  filter?: InputMaybe<Widgets_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationDelete_Administration_Function_Types_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Administration_Function_Types_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Administration_Functions_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Administration_Functions_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Administration_Functions_Settings_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Administration_Functions_Settings_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Function_Types_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Function_Types_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Functions_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Functions_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Functions_Settings_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Functions_Settings_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Logs_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Logs_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Lt_Se_Bogen_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Lt_Se_Bogen_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Lt_Se_Fragen_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Lt_Se_Fragen_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Parameter_Groups_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Parameter_Groups_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Parameter_Types_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Parameter_Types_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Parameters_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Parameters_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Parameters_Parameter_Groups_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Parameters_Parameter_Groups_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Reports_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Reports_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Settings_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Settings_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Settings_Widgets_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Settings_Widgets_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Shortcut_Functions_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Shortcut_Functions_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_T_Se_Antworten_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_T_Se_Antworten_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_T_Se_Bogen2frage_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_T_Se_Bogen2frage_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_T_Se_Ma2seb_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_T_Se_Ma2seb_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_T_Se_Status_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_T_Se_Status_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Widgets_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Widgets_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationUpdate_Administration_Function_Types_BatchArgs = {
  data?: InputMaybe<Array<Update_Administration_Function_Types_Input>>;
  filter?: InputMaybe<Administration_Function_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Administration_Function_Types_ItemArgs = {
  data: Update_Administration_Function_Types_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Administration_Function_Types_ItemsArgs = {
  data: Update_Administration_Function_Types_Input;
  filter?: InputMaybe<Administration_Function_Types_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Administration_Functions_BatchArgs = {
  data?: InputMaybe<Array<Update_Administration_Functions_Input>>;
  filter?: InputMaybe<Administration_Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Administration_Functions_ItemArgs = {
  data: Update_Administration_Functions_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Administration_Functions_ItemsArgs = {
  data: Update_Administration_Functions_Input;
  filter?: InputMaybe<Administration_Functions_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Administration_Functions_Settings_BatchArgs = {
  data?: InputMaybe<Array<Update_Administration_Functions_Settings_Input>>;
  filter?: InputMaybe<Administration_Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Administration_Functions_Settings_ItemArgs = {
  data: Update_Administration_Functions_Settings_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Administration_Functions_Settings_ItemsArgs = {
  data: Update_Administration_Functions_Settings_Input;
  filter?: InputMaybe<Administration_Functions_Settings_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Function_Types_BatchArgs = {
  data?: InputMaybe<Array<Update_Function_Types_Input>>;
  filter?: InputMaybe<Function_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Function_Types_ItemArgs = {
  data: Update_Function_Types_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Function_Types_ItemsArgs = {
  data: Update_Function_Types_Input;
  filter?: InputMaybe<Function_Types_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Functions_BatchArgs = {
  data?: InputMaybe<Array<Update_Functions_Input>>;
  filter?: InputMaybe<Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Functions_ItemArgs = {
  data: Update_Functions_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Functions_ItemsArgs = {
  data: Update_Functions_Input;
  filter?: InputMaybe<Functions_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Functions_Settings_BatchArgs = {
  data?: InputMaybe<Array<Update_Functions_Settings_Input>>;
  filter?: InputMaybe<Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Functions_Settings_ItemArgs = {
  data: Update_Functions_Settings_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Functions_Settings_ItemsArgs = {
  data: Update_Functions_Settings_Input;
  filter?: InputMaybe<Functions_Settings_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Logs_BatchArgs = {
  data?: InputMaybe<Array<Update_Logs_Input>>;
  filter?: InputMaybe<Logs_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Logs_ItemArgs = {
  data: Update_Logs_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Logs_ItemsArgs = {
  data: Update_Logs_Input;
  filter?: InputMaybe<Logs_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Lt_Se_Bogen_BatchArgs = {
  data?: InputMaybe<Array<Update_Lt_Se_Bogen_Input>>;
  filter?: InputMaybe<Lt_Se_Bogen_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Lt_Se_Bogen_ItemArgs = {
  data: Update_Lt_Se_Bogen_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Lt_Se_Bogen_ItemsArgs = {
  data: Update_Lt_Se_Bogen_Input;
  filter?: InputMaybe<Lt_Se_Bogen_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Lt_Se_Fragen_BatchArgs = {
  data?: InputMaybe<Array<Update_Lt_Se_Fragen_Input>>;
  filter?: InputMaybe<Lt_Se_Fragen_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Lt_Se_Fragen_ItemArgs = {
  data: Update_Lt_Se_Fragen_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Lt_Se_Fragen_ItemsArgs = {
  data: Update_Lt_Se_Fragen_Input;
  filter?: InputMaybe<Lt_Se_Fragen_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Parameter_Groups_BatchArgs = {
  data?: InputMaybe<Array<Update_Parameter_Groups_Input>>;
  filter?: InputMaybe<Parameter_Groups_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Parameter_Groups_ItemArgs = {
  data: Update_Parameter_Groups_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Parameter_Groups_ItemsArgs = {
  data: Update_Parameter_Groups_Input;
  filter?: InputMaybe<Parameter_Groups_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Parameter_Types_BatchArgs = {
  data?: InputMaybe<Array<Update_Parameter_Types_Input>>;
  filter?: InputMaybe<Parameter_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Parameter_Types_ItemArgs = {
  data: Update_Parameter_Types_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Parameter_Types_ItemsArgs = {
  data: Update_Parameter_Types_Input;
  filter?: InputMaybe<Parameter_Types_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Parameters_BatchArgs = {
  data?: InputMaybe<Array<Update_Parameters_Input>>;
  filter?: InputMaybe<Parameters_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Parameters_ItemArgs = {
  data: Update_Parameters_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Parameters_ItemsArgs = {
  data: Update_Parameters_Input;
  filter?: InputMaybe<Parameters_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Parameters_Parameter_Groups_BatchArgs = {
  data?: InputMaybe<Array<Update_Parameters_Parameter_Groups_Input>>;
  filter?: InputMaybe<Parameters_Parameter_Groups_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Parameters_Parameter_Groups_ItemArgs = {
  data: Update_Parameters_Parameter_Groups_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Parameters_Parameter_Groups_ItemsArgs = {
  data: Update_Parameters_Parameter_Groups_Input;
  filter?: InputMaybe<Parameters_Parameter_Groups_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Reports_BatchArgs = {
  data?: InputMaybe<Array<Update_Reports_Input>>;
  filter?: InputMaybe<Reports_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Reports_ItemArgs = {
  data: Update_Reports_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Reports_ItemsArgs = {
  data: Update_Reports_Input;
  filter?: InputMaybe<Reports_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Settings_BatchArgs = {
  data?: InputMaybe<Array<Update_Settings_Input>>;
  filter?: InputMaybe<Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Settings_ItemArgs = {
  data: Update_Settings_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Settings_ItemsArgs = {
  data: Update_Settings_Input;
  filter?: InputMaybe<Settings_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Settings_Widgets_BatchArgs = {
  data?: InputMaybe<Array<Update_Settings_Widgets_Input>>;
  filter?: InputMaybe<Settings_Widgets_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Settings_Widgets_ItemArgs = {
  data: Update_Settings_Widgets_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Settings_Widgets_ItemsArgs = {
  data: Update_Settings_Widgets_Input;
  filter?: InputMaybe<Settings_Widgets_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Shortcut_Functions_BatchArgs = {
  data?: InputMaybe<Array<Update_Shortcut_Functions_Input>>;
  filter?: InputMaybe<Shortcut_Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Shortcut_Functions_ItemArgs = {
  data: Update_Shortcut_Functions_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Shortcut_Functions_ItemsArgs = {
  data: Update_Shortcut_Functions_Input;
  filter?: InputMaybe<Shortcut_Functions_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_T_Se_Antworten_BatchArgs = {
  data?: InputMaybe<Array<Update_T_Se_Antworten_Input>>;
  filter?: InputMaybe<T_Se_Antworten_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_T_Se_Antworten_ItemArgs = {
  data: Update_T_Se_Antworten_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_T_Se_Antworten_ItemsArgs = {
  data: Update_T_Se_Antworten_Input;
  filter?: InputMaybe<T_Se_Antworten_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_T_Se_Bogen2frage_BatchArgs = {
  data?: InputMaybe<Array<Update_T_Se_Bogen2frage_Input>>;
  filter?: InputMaybe<T_Se_Bogen2frage_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_T_Se_Bogen2frage_ItemArgs = {
  data: Update_T_Se_Bogen2frage_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_T_Se_Bogen2frage_ItemsArgs = {
  data: Update_T_Se_Bogen2frage_Input;
  filter?: InputMaybe<T_Se_Bogen2frage_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_T_Se_Ma2seb_BatchArgs = {
  data?: InputMaybe<Array<Update_T_Se_Ma2seb_Input>>;
  filter?: InputMaybe<T_Se_Ma2seb_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_T_Se_Ma2seb_ItemArgs = {
  data: Update_T_Se_Ma2seb_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_T_Se_Ma2seb_ItemsArgs = {
  data: Update_T_Se_Ma2seb_Input;
  filter?: InputMaybe<T_Se_Ma2seb_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_T_Se_Status_BatchArgs = {
  data?: InputMaybe<Array<Update_T_Se_Status_Input>>;
  filter?: InputMaybe<T_Se_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_T_Se_Status_ItemArgs = {
  data: Update_T_Se_Status_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_T_Se_Status_ItemsArgs = {
  data: Update_T_Se_Status_Input;
  filter?: InputMaybe<T_Se_Status_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Widgets_BatchArgs = {
  data?: InputMaybe<Array<Update_Widgets_Input>>;
  filter?: InputMaybe<Widgets_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Widgets_ItemArgs = {
  data: Update_Widgets_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Widgets_ItemsArgs = {
  data: Update_Widgets_Input;
  filter?: InputMaybe<Widgets_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query = {
  __typename?: 'Query';
  administration_function_types: Array<Administration_Function_Types>;
  administration_function_types_aggregated: Array<Administration_Function_Types_Aggregated>;
  administration_function_types_by_id?: Maybe<Administration_Function_Types>;
  administration_functions: Array<Administration_Functions>;
  administration_functions_aggregated: Array<Administration_Functions_Aggregated>;
  administration_functions_by_id?: Maybe<Administration_Functions>;
  administration_functions_settings: Array<Administration_Functions_Settings>;
  administration_functions_settings_aggregated: Array<Administration_Functions_Settings_Aggregated>;
  administration_functions_settings_by_id?: Maybe<Administration_Functions_Settings>;
  function_types: Array<Function_Types>;
  function_types_aggregated: Array<Function_Types_Aggregated>;
  function_types_by_id?: Maybe<Function_Types>;
  functions: Array<Functions>;
  functions_aggregated: Array<Functions_Aggregated>;
  functions_by_id?: Maybe<Functions>;
  functions_settings: Array<Functions_Settings>;
  functions_settings_aggregated: Array<Functions_Settings_Aggregated>;
  functions_settings_by_id?: Maybe<Functions_Settings>;
  logs: Array<Logs>;
  logs_aggregated: Array<Logs_Aggregated>;
  logs_by_id?: Maybe<Logs>;
  lt_se_bogen: Array<Lt_Se_Bogen>;
  lt_se_bogen_aggregated: Array<Lt_Se_Bogen_Aggregated>;
  lt_se_bogen_by_id?: Maybe<Lt_Se_Bogen>;
  lt_se_fragen: Array<Lt_Se_Fragen>;
  lt_se_fragen_aggregated: Array<Lt_Se_Fragen_Aggregated>;
  lt_se_fragen_by_id?: Maybe<Lt_Se_Fragen>;
  parameter_groups: Array<Parameter_Groups>;
  parameter_groups_aggregated: Array<Parameter_Groups_Aggregated>;
  parameter_groups_by_id?: Maybe<Parameter_Groups>;
  parameter_types: Array<Parameter_Types>;
  parameter_types_aggregated: Array<Parameter_Types_Aggregated>;
  parameter_types_by_id?: Maybe<Parameter_Types>;
  parameters: Array<Parameters>;
  parameters_aggregated: Array<Parameters_Aggregated>;
  parameters_by_id?: Maybe<Parameters>;
  parameters_parameter_groups: Array<Parameters_Parameter_Groups>;
  parameters_parameter_groups_aggregated: Array<Parameters_Parameter_Groups_Aggregated>;
  parameters_parameter_groups_by_id?: Maybe<Parameters_Parameter_Groups>;
  reports: Array<Reports>;
  reports_aggregated: Array<Reports_Aggregated>;
  reports_by_id?: Maybe<Reports>;
  settings: Array<Settings>;
  settings_aggregated: Array<Settings_Aggregated>;
  settings_by_id?: Maybe<Settings>;
  settings_widgets: Array<Settings_Widgets>;
  settings_widgets_aggregated: Array<Settings_Widgets_Aggregated>;
  settings_widgets_by_id?: Maybe<Settings_Widgets>;
  shortcut_functions: Array<Shortcut_Functions>;
  shortcut_functions_aggregated: Array<Shortcut_Functions_Aggregated>;
  shortcut_functions_by_id?: Maybe<Shortcut_Functions>;
  t_se_antworten: Array<T_Se_Antworten>;
  t_se_antworten_aggregated: Array<T_Se_Antworten_Aggregated>;
  t_se_antworten_by_id?: Maybe<T_Se_Antworten>;
  t_se_bogen2frage: Array<T_Se_Bogen2frage>;
  t_se_bogen2frage_aggregated: Array<T_Se_Bogen2frage_Aggregated>;
  t_se_bogen2frage_by_id?: Maybe<T_Se_Bogen2frage>;
  t_se_ma2seb: Array<T_Se_Ma2seb>;
  t_se_ma2seb_aggregated: Array<T_Se_Ma2seb_Aggregated>;
  t_se_ma2seb_by_id?: Maybe<T_Se_Ma2seb>;
  t_se_status: Array<T_Se_Status>;
  t_se_status_aggregated: Array<T_Se_Status_Aggregated>;
  t_se_status_by_id?: Maybe<T_Se_Status>;
  widgets: Array<Widgets>;
  widgets_aggregated: Array<Widgets_Aggregated>;
  widgets_by_id?: Maybe<Widgets>;
};


export type QueryAdministration_Function_TypesArgs = {
  filter?: InputMaybe<Administration_Function_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAdministration_Function_Types_AggregatedArgs = {
  filter?: InputMaybe<Administration_Function_Types_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAdministration_Function_Types_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdministration_FunctionsArgs = {
  filter?: InputMaybe<Administration_Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAdministration_Functions_AggregatedArgs = {
  filter?: InputMaybe<Administration_Functions_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAdministration_Functions_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdministration_Functions_SettingsArgs = {
  filter?: InputMaybe<Administration_Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAdministration_Functions_Settings_AggregatedArgs = {
  filter?: InputMaybe<Administration_Functions_Settings_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAdministration_Functions_Settings_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFunction_TypesArgs = {
  filter?: InputMaybe<Function_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFunction_Types_AggregatedArgs = {
  filter?: InputMaybe<Function_Types_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFunction_Types_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFunctionsArgs = {
  filter?: InputMaybe<Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFunctions_AggregatedArgs = {
  filter?: InputMaybe<Functions_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFunctions_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFunctions_SettingsArgs = {
  filter?: InputMaybe<Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFunctions_Settings_AggregatedArgs = {
  filter?: InputMaybe<Functions_Settings_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFunctions_Settings_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLogsArgs = {
  filter?: InputMaybe<Logs_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryLogs_AggregatedArgs = {
  filter?: InputMaybe<Logs_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryLogs_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLt_Se_BogenArgs = {
  filter?: InputMaybe<Lt_Se_Bogen_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryLt_Se_Bogen_AggregatedArgs = {
  filter?: InputMaybe<Lt_Se_Bogen_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryLt_Se_Bogen_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLt_Se_FragenArgs = {
  filter?: InputMaybe<Lt_Se_Fragen_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryLt_Se_Fragen_AggregatedArgs = {
  filter?: InputMaybe<Lt_Se_Fragen_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryLt_Se_Fragen_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryParameter_GroupsArgs = {
  filter?: InputMaybe<Parameter_Groups_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryParameter_Groups_AggregatedArgs = {
  filter?: InputMaybe<Parameter_Groups_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryParameter_Groups_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryParameter_TypesArgs = {
  filter?: InputMaybe<Parameter_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryParameter_Types_AggregatedArgs = {
  filter?: InputMaybe<Parameter_Types_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryParameter_Types_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryParametersArgs = {
  filter?: InputMaybe<Parameters_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryParameters_AggregatedArgs = {
  filter?: InputMaybe<Parameters_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryParameters_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryParameters_Parameter_GroupsArgs = {
  filter?: InputMaybe<Parameters_Parameter_Groups_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryParameters_Parameter_Groups_AggregatedArgs = {
  filter?: InputMaybe<Parameters_Parameter_Groups_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryParameters_Parameter_Groups_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReportsArgs = {
  filter?: InputMaybe<Reports_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryReports_AggregatedArgs = {
  filter?: InputMaybe<Reports_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryReports_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySettingsArgs = {
  filter?: InputMaybe<Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySettings_AggregatedArgs = {
  filter?: InputMaybe<Settings_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySettings_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySettings_WidgetsArgs = {
  filter?: InputMaybe<Settings_Widgets_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySettings_Widgets_AggregatedArgs = {
  filter?: InputMaybe<Settings_Widgets_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySettings_Widgets_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShortcut_FunctionsArgs = {
  filter?: InputMaybe<Shortcut_Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryShortcut_Functions_AggregatedArgs = {
  filter?: InputMaybe<Shortcut_Functions_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryShortcut_Functions_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryT_Se_AntwortenArgs = {
  filter?: InputMaybe<T_Se_Antworten_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryT_Se_Antworten_AggregatedArgs = {
  filter?: InputMaybe<T_Se_Antworten_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryT_Se_Antworten_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryT_Se_Bogen2frageArgs = {
  filter?: InputMaybe<T_Se_Bogen2frage_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryT_Se_Bogen2frage_AggregatedArgs = {
  filter?: InputMaybe<T_Se_Bogen2frage_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryT_Se_Bogen2frage_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryT_Se_Ma2sebArgs = {
  filter?: InputMaybe<T_Se_Ma2seb_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryT_Se_Ma2seb_AggregatedArgs = {
  filter?: InputMaybe<T_Se_Ma2seb_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryT_Se_Ma2seb_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryT_Se_StatusArgs = {
  filter?: InputMaybe<T_Se_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryT_Se_Status_AggregatedArgs = {
  filter?: InputMaybe<T_Se_Status_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryT_Se_Status_By_IdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWidgetsArgs = {
  filter?: InputMaybe<Widgets_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryWidgets_AggregatedArgs = {
  filter?: InputMaybe<Widgets_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryWidgets_By_IdArgs = {
  id: Scalars['ID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  administration_function_types_mutated?: Maybe<Administration_Function_Types_Mutated>;
  administration_functions_mutated?: Maybe<Administration_Functions_Mutated>;
  administration_functions_settings_mutated?: Maybe<Administration_Functions_Settings_Mutated>;
  directus_activity_mutated?: Maybe<Directus_Activity_Mutated>;
  directus_dashboards_mutated?: Maybe<Directus_Dashboards_Mutated>;
  directus_files_mutated?: Maybe<Directus_Files_Mutated>;
  directus_flows_mutated?: Maybe<Directus_Flows_Mutated>;
  directus_folders_mutated?: Maybe<Directus_Folders_Mutated>;
  directus_notifications_mutated?: Maybe<Directus_Notifications_Mutated>;
  directus_operations_mutated?: Maybe<Directus_Operations_Mutated>;
  directus_panels_mutated?: Maybe<Directus_Panels_Mutated>;
  directus_permissions_mutated?: Maybe<Directus_Permissions_Mutated>;
  directus_presets_mutated?: Maybe<Directus_Presets_Mutated>;
  directus_revisions_mutated?: Maybe<Directus_Revisions_Mutated>;
  directus_roles_mutated?: Maybe<Directus_Roles_Mutated>;
  directus_settings_mutated?: Maybe<Directus_Settings_Mutated>;
  directus_shares_mutated?: Maybe<Directus_Shares_Mutated>;
  directus_translations_mutated?: Maybe<Directus_Translations_Mutated>;
  directus_users_mutated?: Maybe<Directus_Users_Mutated>;
  directus_webhooks_mutated?: Maybe<Directus_Webhooks_Mutated>;
  function_types_mutated?: Maybe<Function_Types_Mutated>;
  functions_mutated?: Maybe<Functions_Mutated>;
  functions_settings_mutated?: Maybe<Functions_Settings_Mutated>;
  logs_mutated?: Maybe<Logs_Mutated>;
  lt_se_bogen_mutated?: Maybe<Lt_Se_Bogen_Mutated>;
  lt_se_fragen_mutated?: Maybe<Lt_Se_Fragen_Mutated>;
  parameter_groups_mutated?: Maybe<Parameter_Groups_Mutated>;
  parameter_types_mutated?: Maybe<Parameter_Types_Mutated>;
  parameters_mutated?: Maybe<Parameters_Mutated>;
  parameters_parameter_groups_mutated?: Maybe<Parameters_Parameter_Groups_Mutated>;
  reports_mutated?: Maybe<Reports_Mutated>;
  settings_mutated?: Maybe<Settings_Mutated>;
  settings_widgets_mutated?: Maybe<Settings_Widgets_Mutated>;
  shortcut_functions_mutated?: Maybe<Shortcut_Functions_Mutated>;
  t_se_antworten_mutated?: Maybe<T_Se_Antworten_Mutated>;
  t_se_bogen2frage_mutated?: Maybe<T_Se_Bogen2frage_Mutated>;
  t_se_ma2seb_mutated?: Maybe<T_Se_Ma2seb_Mutated>;
  t_se_status_mutated?: Maybe<T_Se_Status_Mutated>;
  widgets_mutated?: Maybe<Widgets_Mutated>;
};


export type SubscriptionAdministration_Function_Types_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAdministration_Functions_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAdministration_Functions_Settings_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Activity_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Dashboards_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Files_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Flows_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Folders_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Notifications_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Operations_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Panels_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Permissions_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Presets_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Revisions_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Roles_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Settings_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Shares_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Translations_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Users_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Webhooks_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionFunction_Types_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionFunctions_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionFunctions_Settings_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionLogs_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionLt_Se_Bogen_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionLt_Se_Fragen_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionParameter_Groups_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionParameter_Types_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionParameters_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionParameters_Parameter_Groups_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionReports_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionSettings_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionSettings_Widgets_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionShortcut_Functions_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionT_Se_Antworten_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionT_Se_Bogen2frage_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionT_Se_Ma2seb_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionT_Se_Status_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionWidgets_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type Administration_Function_Types = {
  __typename?: 'administration_function_types';
  administration_functions_settings?: Maybe<Array<Maybe<Administration_Functions_Settings>>>;
  administration_functions_settings_func?: Maybe<Count_Functions>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};


export type Administration_Function_TypesAdministration_Functions_SettingsArgs = {
  filter?: InputMaybe<Administration_Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Administration_Function_Types_Aggregated = {
  __typename?: 'administration_function_types_aggregated';
  avg?: Maybe<Administration_Function_Types_Aggregated_Fields>;
  avgDistinct?: Maybe<Administration_Function_Types_Aggregated_Fields>;
  count?: Maybe<Administration_Function_Types_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Administration_Function_Types_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Administration_Function_Types_Aggregated_Fields>;
  min?: Maybe<Administration_Function_Types_Aggregated_Fields>;
  sum?: Maybe<Administration_Function_Types_Aggregated_Fields>;
  sumDistinct?: Maybe<Administration_Function_Types_Aggregated_Fields>;
};

export type Administration_Function_Types_Aggregated_Count = {
  __typename?: 'administration_function_types_aggregated_count';
  administration_functions_settings?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export type Administration_Function_Types_Aggregated_Fields = {
  __typename?: 'administration_function_types_aggregated_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type Administration_Function_Types_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Administration_Function_Types_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Administration_Function_Types_Filter>>>;
  administration_functions_settings?: InputMaybe<Administration_Functions_Settings_Filter>;
  administration_functions_settings_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
};

export type Administration_Function_Types_Mutated = {
  __typename?: 'administration_function_types_mutated';
  data?: Maybe<Administration_Function_Types>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Administration_Functions = {
  __typename?: 'administration_functions';
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Directus_Files>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<Array<Maybe<Administration_Functions_Settings>>>;
  settings_func?: Maybe<Count_Functions>;
};


export type Administration_FunctionsIconArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Administration_FunctionsSettingsArgs = {
  filter?: InputMaybe<Administration_Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Administration_Functions_Aggregated = {
  __typename?: 'administration_functions_aggregated';
  avg?: Maybe<Administration_Functions_Aggregated_Fields>;
  avgDistinct?: Maybe<Administration_Functions_Aggregated_Fields>;
  count?: Maybe<Administration_Functions_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Administration_Functions_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Administration_Functions_Aggregated_Fields>;
  min?: Maybe<Administration_Functions_Aggregated_Fields>;
  sum?: Maybe<Administration_Functions_Aggregated_Fields>;
  sumDistinct?: Maybe<Administration_Functions_Aggregated_Fields>;
};

export type Administration_Functions_Aggregated_Count = {
  __typename?: 'administration_functions_aggregated_count';
  description?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  settings?: Maybe<Scalars['Int']['output']>;
};

export type Administration_Functions_Aggregated_Fields = {
  __typename?: 'administration_functions_aggregated_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type Administration_Functions_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Administration_Functions_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Administration_Functions_Filter>>>;
  description?: InputMaybe<String_Filter_Operators>;
  icon?: InputMaybe<Directus_Files_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  settings?: InputMaybe<Administration_Functions_Settings_Filter>;
  settings_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Administration_Functions_Mutated = {
  __typename?: 'administration_functions_mutated';
  data?: Maybe<Administration_Functions>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Administration_Functions_Settings = {
  __typename?: 'administration_functions_settings';
  administration_functions_id?: Maybe<Administration_Functions>;
  function_type_id?: Maybe<Administration_Function_Types>;
  id: Scalars['ID']['output'];
  is_pinned?: Maybe<Scalars['Boolean']['output']>;
  order_number?: Maybe<Scalars['Int']['output']>;
  settings_id?: Maybe<Settings>;
};


export type Administration_Functions_SettingsAdministration_Functions_IdArgs = {
  filter?: InputMaybe<Administration_Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Administration_Functions_SettingsFunction_Type_IdArgs = {
  filter?: InputMaybe<Administration_Function_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Administration_Functions_SettingsSettings_IdArgs = {
  filter?: InputMaybe<Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Administration_Functions_Settings_Aggregated = {
  __typename?: 'administration_functions_settings_aggregated';
  avg?: Maybe<Administration_Functions_Settings_Aggregated_Fields>;
  avgDistinct?: Maybe<Administration_Functions_Settings_Aggregated_Fields>;
  count?: Maybe<Administration_Functions_Settings_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Administration_Functions_Settings_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Administration_Functions_Settings_Aggregated_Fields>;
  min?: Maybe<Administration_Functions_Settings_Aggregated_Fields>;
  sum?: Maybe<Administration_Functions_Settings_Aggregated_Fields>;
  sumDistinct?: Maybe<Administration_Functions_Settings_Aggregated_Fields>;
};

export type Administration_Functions_Settings_Aggregated_Count = {
  __typename?: 'administration_functions_settings_aggregated_count';
  administration_functions_id?: Maybe<Scalars['Int']['output']>;
  function_type_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is_pinned?: Maybe<Scalars['Int']['output']>;
  order_number?: Maybe<Scalars['Int']['output']>;
  settings_id?: Maybe<Scalars['Int']['output']>;
};

export type Administration_Functions_Settings_Aggregated_Fields = {
  __typename?: 'administration_functions_settings_aggregated_fields';
  administration_functions_id?: Maybe<Scalars['Float']['output']>;
  function_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order_number?: Maybe<Scalars['Float']['output']>;
  settings_id?: Maybe<Scalars['Float']['output']>;
};

export type Administration_Functions_Settings_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Administration_Functions_Settings_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Administration_Functions_Settings_Filter>>>;
  administration_functions_id?: InputMaybe<Administration_Functions_Filter>;
  function_type_id?: InputMaybe<Administration_Function_Types_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  is_pinned?: InputMaybe<Boolean_Filter_Operators>;
  order_number?: InputMaybe<Number_Filter_Operators>;
  settings_id?: InputMaybe<Settings_Filter>;
};

export type Administration_Functions_Settings_Mutated = {
  __typename?: 'administration_functions_settings_mutated';
  data?: Maybe<Administration_Functions_Settings>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Boolean_Filter_Operators = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Count_Function_Filter_Operators = {
  count?: InputMaybe<Number_Filter_Operators>;
};

export type Count_Functions = {
  __typename?: 'count_functions';
  count?: Maybe<Scalars['Int']['output']>;
};

export type Create_Administration_Function_Types_Input = {
  administration_functions_settings?: InputMaybe<Array<InputMaybe<Create_Administration_Functions_Settings_Input>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type Create_Administration_Functions_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Create_Directus_Files_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Array<InputMaybe<Create_Administration_Functions_Settings_Input>>>;
};

export type Create_Administration_Functions_Settings_Input = {
  administration_functions_id?: InputMaybe<Create_Administration_Functions_Input>;
  function_type_id?: InputMaybe<Create_Administration_Function_Types_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_pinned?: InputMaybe<Scalars['Boolean']['input']>;
  order_number?: InputMaybe<Scalars['Int']['input']>;
  settings_id?: InputMaybe<Create_Settings_Input>;
};

export type Create_Directus_Files_Input = {
  charset?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  embed?: InputMaybe<Scalars['String']['input']>;
  filename_disk?: InputMaybe<Scalars['String']['input']>;
  filename_download: Scalars['String']['input'];
  filesize?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  folder?: InputMaybe<Create_Directus_Folders_Input>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  modified_by?: InputMaybe<Create_Directus_Users_Input>;
  modified_on?: InputMaybe<Scalars['Date']['input']>;
  storage: Scalars['String']['input'];
  tags?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uploaded_by?: InputMaybe<Create_Directus_Users_Input>;
  uploaded_on?: InputMaybe<Scalars['Date']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type Create_Directus_Folders_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Create_Directus_Folders_Input>;
};

export type Create_Directus_Roles_Input = {
  admin_access: Scalars['Boolean']['input'];
  app_access?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enforce_tfa: Scalars['Boolean']['input'];
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ip_access?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  users?: InputMaybe<Array<InputMaybe<Create_Directus_Users_Input>>>;
};

export type Create_Directus_Users_Input = {
  auth_data?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Create_Directus_Files_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_notifications?: InputMaybe<Scalars['Boolean']['input']>;
  external_id?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  external_identifier?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  last_access?: InputMaybe<Scalars['Date']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_page?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['Hash']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Create_Directus_Roles_Input>;
  settings?: InputMaybe<Create_Settings_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  tfa_secret?: InputMaybe<Scalars['Hash']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['Hash']['input']>;
};

export type Create_Function_Types_Input = {
  functions_settings?: InputMaybe<Array<InputMaybe<Create_Functions_Settings_Input>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type Create_Functions_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Create_Directus_Files_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  settings?: InputMaybe<Array<InputMaybe<Create_Functions_Settings_Input>>>;
};

export type Create_Functions_Settings_Input = {
  function_type_id?: InputMaybe<Create_Function_Types_Input>;
  functions_id?: InputMaybe<Create_Functions_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_pinned?: InputMaybe<Scalars['Boolean']['input']>;
  order_number?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  settings_id?: InputMaybe<Create_Settings_Input>;
};

export type Create_Logs_Input = {
  correlation_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  log_level: Scalars['String']['input'];
  message: Scalars['String']['input'];
  method: Scalars['String']['input'];
  module: Scalars['String']['input'];
  timestamp?: InputMaybe<Scalars['Date']['input']>;
};

export type Create_Lt_Se_Bogen_Input = {
  fragen?: InputMaybe<Array<InputMaybe<Create_T_Se_Bogen2frage_Input>>>;
  inactive_am?: InputMaybe<Scalars['Date']['input']>;
  inactive_von?: InputMaybe<Scalars['Int']['input']>;
  seb_id?: InputMaybe<Scalars['ID']['input']>;
  seb_name: Scalars['String']['input'];
};

export type Create_Lt_Se_Fragen_Input = {
  bogen?: InputMaybe<Array<InputMaybe<Create_T_Se_Bogen2frage_Input>>>;
  inactive_am?: InputMaybe<Scalars['Date']['input']>;
  inactive_von?: InputMaybe<Scalars['Int']['input']>;
  sef_id?: InputMaybe<Scalars['ID']['input']>;
  sef_name: Scalars['String']['input'];
};

export type Create_Parameter_Groups_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameters?: InputMaybe<Array<InputMaybe<Create_Parameters_Parameter_Groups_Input>>>;
};

export type Create_Parameter_Types_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameters?: InputMaybe<Array<InputMaybe<Create_Parameters_Input>>>;
  sort_id?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
};

export type Create_Parameters_Input = {
  caption?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_license: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  parameter_group?: InputMaybe<Array<InputMaybe<Create_Parameters_Parameter_Groups_Input>>>;
  parameter_type_id?: InputMaybe<Create_Parameter_Types_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Create_Parameters_Parameter_Groups_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  parameter_groups_id?: InputMaybe<Create_Parameter_Groups_Input>;
  parameters_id?: InputMaybe<Create_Parameters_Input>;
};

export type Create_Reports_Input = {
  file_name: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  is_visible?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type Create_Settings_Input = {
  administration_functions?: InputMaybe<Array<InputMaybe<Create_Administration_Functions_Settings_Input>>>;
  favorite_persons?: InputMaybe<Scalars['String']['input']>;
  functions?: InputMaybe<Array<InputMaybe<Create_Functions_Settings_Input>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language_code?: InputMaybe<Scalars['String']['input']>;
  selected_departments?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Array<InputMaybe<Create_Directus_Users_Input>>>;
  widgets?: InputMaybe<Array<InputMaybe<Create_Settings_Widgets_Input>>>;
};

export type Create_Settings_Widgets_Input = {
  column_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_visible?: InputMaybe<Scalars['Boolean']['input']>;
  order_number?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  settings_id?: InputMaybe<Create_Settings_Input>;
  widgets_id?: InputMaybe<Create_Widgets_Input>;
};

export type Create_Shortcut_Functions_Input = {
  function_id?: InputMaybe<Create_Functions_Input>;
  icon?: InputMaybe<Create_Directus_Files_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  tooltip_text?: InputMaybe<Scalars['String']['input']>;
  visibility_type?: InputMaybe<Scalars['String']['input']>;
};

export type Create_T_Se_Antworten_Input = {
  anmerkung: Scalars['String']['input'];
  antwort: Scalars['Int']['input'];
  edit_am?: InputMaybe<Scalars['Date']['input']>;
  m2s_id?: InputMaybe<Create_T_Se_Ma2seb_Input>;
  sea_id?: InputMaybe<Scalars['ID']['input']>;
  sef_id: Scalars['Int']['input'];
};

export type Create_T_Se_Bogen2frage_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  seb_id?: InputMaybe<Create_Lt_Se_Bogen_Input>;
  sef_id?: InputMaybe<Create_Lt_Se_Fragen_Input>;
  sef_order_id: Scalars['Int']['input'];
};

export type Create_T_Se_Ma2seb_Input = {
  m2s_id?: InputMaybe<Scalars['ID']['input']>;
  ma_id: Scalars['Int']['input'];
  new_am?: InputMaybe<Scalars['Date']['input']>;
  new_von: Scalars['Int']['input'];
  se_status_id?: InputMaybe<Create_T_Se_Status_Input>;
  seb_id: Scalars['Int']['input'];
  selbst_beantwortet: Scalars['Boolean']['input'];
};

export type Create_T_Se_Status_Input = {
  order_id?: InputMaybe<Scalars['Int']['input']>;
  se_status_id?: InputMaybe<Scalars['ID']['input']>;
  status_name: Scalars['String']['input'];
};

export type Create_Widgets_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Create_Directus_Files_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  settings?: InputMaybe<Array<InputMaybe<Create_Settings_Widgets_Input>>>;
};

export type Date_Filter_Operators = {
  _between?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _nbetween?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Datetime_Function_Filter_Operators = {
  day?: InputMaybe<Number_Filter_Operators>;
  hour?: InputMaybe<Number_Filter_Operators>;
  minute?: InputMaybe<Number_Filter_Operators>;
  month?: InputMaybe<Number_Filter_Operators>;
  second?: InputMaybe<Number_Filter_Operators>;
  week?: InputMaybe<Number_Filter_Operators>;
  weekday?: InputMaybe<Number_Filter_Operators>;
  year?: InputMaybe<Number_Filter_Operators>;
};

export type Datetime_Functions = {
  __typename?: 'datetime_functions';
  day?: Maybe<Scalars['Int']['output']>;
  hour?: Maybe<Scalars['Int']['output']>;
  minute?: Maybe<Scalars['Int']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  second?: Maybe<Scalars['Int']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
  weekday?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type Delete_Many = {
  __typename?: 'delete_many';
  ids: Array<Maybe<Scalars['ID']['output']>>;
};

export type Delete_One = {
  __typename?: 'delete_one';
  id: Scalars['ID']['output'];
};

export type Directus_Activity = {
  __typename?: 'directus_activity';
  action: Scalars['String']['output'];
  collection: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ip?: Maybe<Scalars['String']['output']>;
  item: Scalars['String']['output'];
  origin?: Maybe<Scalars['String']['output']>;
  revisions?: Maybe<Array<Maybe<Directus_Revisions>>>;
  revisions_func?: Maybe<Count_Functions>;
  timestamp?: Maybe<Scalars['Date']['output']>;
  timestamp_func?: Maybe<Datetime_Functions>;
  user?: Maybe<Directus_Users>;
  user_agent?: Maybe<Scalars['String']['output']>;
};


export type Directus_ActivityRevisionsArgs = {
  filter?: InputMaybe<Directus_Revisions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_ActivityUserArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Activity_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Activity_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Activity_Filter>>>;
  action?: InputMaybe<String_Filter_Operators>;
  collection?: InputMaybe<String_Filter_Operators>;
  comment?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  ip?: InputMaybe<String_Filter_Operators>;
  item?: InputMaybe<String_Filter_Operators>;
  origin?: InputMaybe<String_Filter_Operators>;
  revisions?: InputMaybe<Directus_Revisions_Filter>;
  revisions_func?: InputMaybe<Count_Function_Filter_Operators>;
  timestamp?: InputMaybe<Date_Filter_Operators>;
  timestamp_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  user?: InputMaybe<Directus_Users_Filter>;
  user_agent?: InputMaybe<String_Filter_Operators>;
};

export type Directus_Activity_Mutated = {
  __typename?: 'directus_activity_mutated';
  data?: Maybe<Directus_Activity>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Dashboards = {
  __typename?: 'directus_dashboards';
  color?: Maybe<Scalars['String']['output']>;
  date_created?: Maybe<Scalars['Date']['output']>;
  date_created_func?: Maybe<Datetime_Functions>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  panels?: Maybe<Array<Maybe<Directus_Panels>>>;
  panels_func?: Maybe<Count_Functions>;
  user_created?: Maybe<Directus_Users>;
};


export type Directus_DashboardsPanelsArgs = {
  filter?: InputMaybe<Directus_Panels_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_DashboardsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Dashboards_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Dashboards_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Dashboards_Filter>>>;
  color?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  icon?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  note?: InputMaybe<String_Filter_Operators>;
  panels?: InputMaybe<Directus_Panels_Filter>;
  panels_func?: InputMaybe<Count_Function_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
};

export type Directus_Dashboards_Mutated = {
  __typename?: 'directus_dashboards_mutated';
  data?: Maybe<Directus_Dashboards>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Files = {
  __typename?: 'directus_files';
  charset?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  embed?: Maybe<Scalars['String']['output']>;
  filename_disk?: Maybe<Scalars['String']['output']>;
  filename_download: Scalars['String']['output'];
  filesize?: Maybe<Scalars['GraphQLBigInt']['output']>;
  folder?: Maybe<Directus_Folders>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  metadata_func?: Maybe<Count_Functions>;
  modified_by?: Maybe<Directus_Users>;
  modified_on?: Maybe<Scalars['Date']['output']>;
  modified_on_func?: Maybe<Datetime_Functions>;
  storage: Scalars['String']['output'];
  tags?: Maybe<Scalars['JSON']['output']>;
  tags_func?: Maybe<Count_Functions>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uploaded_by?: Maybe<Directus_Users>;
  uploaded_on?: Maybe<Scalars['Date']['output']>;
  uploaded_on_func?: Maybe<Datetime_Functions>;
  width?: Maybe<Scalars['Int']['output']>;
};


export type Directus_FilesFolderArgs = {
  filter?: InputMaybe<Directus_Folders_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_FilesModified_ByArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_FilesUploaded_ByArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Files_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Files_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Files_Filter>>>;
  charset?: InputMaybe<String_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  duration?: InputMaybe<Number_Filter_Operators>;
  embed?: InputMaybe<String_Filter_Operators>;
  filename_disk?: InputMaybe<String_Filter_Operators>;
  filename_download?: InputMaybe<String_Filter_Operators>;
  filesize?: InputMaybe<Number_Filter_Operators>;
  folder?: InputMaybe<Directus_Folders_Filter>;
  height?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  location?: InputMaybe<String_Filter_Operators>;
  metadata?: InputMaybe<String_Filter_Operators>;
  metadata_func?: InputMaybe<Count_Function_Filter_Operators>;
  modified_by?: InputMaybe<Directus_Users_Filter>;
  modified_on?: InputMaybe<Date_Filter_Operators>;
  modified_on_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  storage?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<String_Filter_Operators>;
  tags_func?: InputMaybe<Count_Function_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  uploaded_by?: InputMaybe<Directus_Users_Filter>;
  uploaded_on?: InputMaybe<Date_Filter_Operators>;
  uploaded_on_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  width?: InputMaybe<Number_Filter_Operators>;
};

export type Directus_Files_Mutated = {
  __typename?: 'directus_files_mutated';
  data?: Maybe<Directus_Files>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Flows = {
  __typename?: 'directus_flows';
  accountability?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  date_created?: Maybe<Scalars['Date']['output']>;
  date_created_func?: Maybe<Datetime_Functions>;
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  operation?: Maybe<Directus_Operations>;
  operations?: Maybe<Array<Maybe<Directus_Operations>>>;
  operations_func?: Maybe<Count_Functions>;
  options?: Maybe<Scalars['JSON']['output']>;
  options_func?: Maybe<Count_Functions>;
  status?: Maybe<Scalars['String']['output']>;
  trigger?: Maybe<Scalars['String']['output']>;
  user_created?: Maybe<Directus_Users>;
};


export type Directus_FlowsOperationArgs = {
  filter?: InputMaybe<Directus_Operations_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_FlowsOperationsArgs = {
  filter?: InputMaybe<Directus_Operations_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_FlowsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Flows_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Flows_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Flows_Filter>>>;
  accountability?: InputMaybe<String_Filter_Operators>;
  color?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  icon?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  operation?: InputMaybe<Directus_Operations_Filter>;
  operations?: InputMaybe<Directus_Operations_Filter>;
  operations_func?: InputMaybe<Count_Function_Filter_Operators>;
  options?: InputMaybe<String_Filter_Operators>;
  options_func?: InputMaybe<Count_Function_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  trigger?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
};

export type Directus_Flows_Mutated = {
  __typename?: 'directus_flows_mutated';
  data?: Maybe<Directus_Flows>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Folders = {
  __typename?: 'directus_folders';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Directus_Folders>;
};


export type Directus_FoldersParentArgs = {
  filter?: InputMaybe<Directus_Folders_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Folders_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Folders_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Folders_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  parent?: InputMaybe<Directus_Folders_Filter>;
};

export type Directus_Folders_Mutated = {
  __typename?: 'directus_folders_mutated';
  data?: Maybe<Directus_Folders>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Notifications = {
  __typename?: 'directus_notifications';
  collection?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  item?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Directus_Users>;
  sender?: Maybe<Directus_Users>;
  status?: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['Date']['output']>;
  timestamp_func?: Maybe<Datetime_Functions>;
};


export type Directus_NotificationsRecipientArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_NotificationsSenderArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Notifications_Mutated = {
  __typename?: 'directus_notifications_mutated';
  data?: Maybe<Directus_Notifications>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Operations = {
  __typename?: 'directus_operations';
  date_created?: Maybe<Scalars['Date']['output']>;
  date_created_func?: Maybe<Datetime_Functions>;
  flow?: Maybe<Directus_Flows>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  options_func?: Maybe<Count_Functions>;
  position_x: Scalars['Int']['output'];
  position_y: Scalars['Int']['output'];
  reject?: Maybe<Directus_Operations>;
  resolve?: Maybe<Directus_Operations>;
  type: Scalars['String']['output'];
  user_created?: Maybe<Directus_Users>;
};


export type Directus_OperationsFlowArgs = {
  filter?: InputMaybe<Directus_Flows_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_OperationsRejectArgs = {
  filter?: InputMaybe<Directus_Operations_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_OperationsResolveArgs = {
  filter?: InputMaybe<Directus_Operations_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_OperationsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Operations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Operations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Operations_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  flow?: InputMaybe<Directus_Flows_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  key?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  options?: InputMaybe<String_Filter_Operators>;
  options_func?: InputMaybe<Count_Function_Filter_Operators>;
  position_x?: InputMaybe<Number_Filter_Operators>;
  position_y?: InputMaybe<Number_Filter_Operators>;
  reject?: InputMaybe<Directus_Operations_Filter>;
  resolve?: InputMaybe<Directus_Operations_Filter>;
  type?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
};

export type Directus_Operations_Mutated = {
  __typename?: 'directus_operations_mutated';
  data?: Maybe<Directus_Operations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Panels = {
  __typename?: 'directus_panels';
  color?: Maybe<Scalars['String']['output']>;
  dashboard?: Maybe<Directus_Dashboards>;
  date_created?: Maybe<Scalars['Date']['output']>;
  date_created_func?: Maybe<Datetime_Functions>;
  height: Scalars['Int']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  options_func?: Maybe<Count_Functions>;
  position_x: Scalars['Int']['output'];
  position_y: Scalars['Int']['output'];
  show_header: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  user_created?: Maybe<Directus_Users>;
  width: Scalars['Int']['output'];
};


export type Directus_PanelsDashboardArgs = {
  filter?: InputMaybe<Directus_Dashboards_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_PanelsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Panels_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Panels_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Panels_Filter>>>;
  color?: InputMaybe<String_Filter_Operators>;
  dashboard?: InputMaybe<Directus_Dashboards_Filter>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  height?: InputMaybe<Number_Filter_Operators>;
  icon?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  note?: InputMaybe<String_Filter_Operators>;
  options?: InputMaybe<String_Filter_Operators>;
  options_func?: InputMaybe<Count_Function_Filter_Operators>;
  position_x?: InputMaybe<Number_Filter_Operators>;
  position_y?: InputMaybe<Number_Filter_Operators>;
  show_header?: InputMaybe<Boolean_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  width?: InputMaybe<Number_Filter_Operators>;
};

export type Directus_Panels_Mutated = {
  __typename?: 'directus_panels_mutated';
  data?: Maybe<Directus_Panels>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Permissions = {
  __typename?: 'directus_permissions';
  action: Scalars['String']['output'];
  collection: Scalars['String']['output'];
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  permissions?: Maybe<Scalars['JSON']['output']>;
  permissions_func?: Maybe<Count_Functions>;
  presets?: Maybe<Scalars['JSON']['output']>;
  presets_func?: Maybe<Count_Functions>;
  role?: Maybe<Directus_Roles>;
  validation?: Maybe<Scalars['JSON']['output']>;
  validation_func?: Maybe<Count_Functions>;
};


export type Directus_PermissionsRoleArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Permissions_Mutated = {
  __typename?: 'directus_permissions_mutated';
  data?: Maybe<Directus_Permissions>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Presets = {
  __typename?: 'directus_presets';
  bookmark?: Maybe<Scalars['String']['output']>;
  collection?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  filter?: Maybe<Scalars['JSON']['output']>;
  filter_func?: Maybe<Count_Functions>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  layout?: Maybe<Scalars['String']['output']>;
  layout_options?: Maybe<Scalars['JSON']['output']>;
  layout_options_func?: Maybe<Count_Functions>;
  layout_query?: Maybe<Scalars['JSON']['output']>;
  layout_query_func?: Maybe<Count_Functions>;
  refresh_interval?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Directus_Roles>;
  search?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Directus_Users>;
};


export type Directus_PresetsRoleArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_PresetsUserArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Presets_Mutated = {
  __typename?: 'directus_presets_mutated';
  data?: Maybe<Directus_Presets>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Revisions = {
  __typename?: 'directus_revisions';
  activity?: Maybe<Directus_Activity>;
  collection: Scalars['String']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  data_func?: Maybe<Count_Functions>;
  delta?: Maybe<Scalars['JSON']['output']>;
  delta_func?: Maybe<Count_Functions>;
  id: Scalars['ID']['output'];
  item: Scalars['String']['output'];
  parent?: Maybe<Directus_Revisions>;
};


export type Directus_RevisionsActivityArgs = {
  filter?: InputMaybe<Directus_Activity_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_RevisionsParentArgs = {
  filter?: InputMaybe<Directus_Revisions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Revisions_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Revisions_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Revisions_Filter>>>;
  activity?: InputMaybe<Directus_Activity_Filter>;
  collection?: InputMaybe<String_Filter_Operators>;
  data?: InputMaybe<String_Filter_Operators>;
  data_func?: InputMaybe<Count_Function_Filter_Operators>;
  delta?: InputMaybe<String_Filter_Operators>;
  delta_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  item?: InputMaybe<String_Filter_Operators>;
  parent?: InputMaybe<Directus_Revisions_Filter>;
};

export type Directus_Revisions_Mutated = {
  __typename?: 'directus_revisions_mutated';
  data?: Maybe<Directus_Revisions>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Roles = {
  __typename?: 'directus_roles';
  admin_access: Scalars['Boolean']['output'];
  app_access?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enforce_tfa: Scalars['Boolean']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ip_access?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  users?: Maybe<Array<Maybe<Directus_Users>>>;
  users_func?: Maybe<Count_Functions>;
};


export type Directus_RolesUsersArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Roles_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Roles_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Roles_Filter>>>;
  admin_access?: InputMaybe<Boolean_Filter_Operators>;
  app_access?: InputMaybe<Boolean_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  enforce_tfa?: InputMaybe<Boolean_Filter_Operators>;
  icon?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  ip_access?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  users?: InputMaybe<Directus_Users_Filter>;
  users_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Directus_Roles_Mutated = {
  __typename?: 'directus_roles_mutated';
  data?: Maybe<Directus_Roles>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Settings = {
  __typename?: 'directus_settings';
  auth_login_attempts?: Maybe<Scalars['Int']['output']>;
  auth_password_policy?: Maybe<Scalars['String']['output']>;
  basemaps?: Maybe<Scalars['JSON']['output']>;
  basemaps_func?: Maybe<Count_Functions>;
  custom_aspect_ratios?: Maybe<Scalars['JSON']['output']>;
  custom_aspect_ratios_func?: Maybe<Count_Functions>;
  custom_css?: Maybe<Scalars['String']['output']>;
  default_language?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mapbox_key?: Maybe<Scalars['String']['output']>;
  module_bar?: Maybe<Scalars['JSON']['output']>;
  module_bar_func?: Maybe<Count_Functions>;
  /** $t:field_options.directus_settings.project_color_note */
  project_color?: Maybe<Scalars['String']['output']>;
  project_descriptor?: Maybe<Scalars['String']['output']>;
  project_logo?: Maybe<Directus_Files>;
  project_name?: Maybe<Scalars['String']['output']>;
  project_url?: Maybe<Scalars['String']['output']>;
  public_background?: Maybe<Directus_Files>;
  public_foreground?: Maybe<Directus_Files>;
  public_note?: Maybe<Scalars['String']['output']>;
  storage_asset_presets?: Maybe<Scalars['JSON']['output']>;
  storage_asset_presets_func?: Maybe<Count_Functions>;
  storage_asset_transform?: Maybe<Scalars['String']['output']>;
  storage_default_folder?: Maybe<Directus_Folders>;
};


export type Directus_SettingsProject_LogoArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_SettingsPublic_BackgroundArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_SettingsPublic_ForegroundArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_SettingsStorage_Default_FolderArgs = {
  filter?: InputMaybe<Directus_Folders_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Settings_Mutated = {
  __typename?: 'directus_settings_mutated';
  data?: Maybe<Directus_Settings>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Shares = {
  __typename?: 'directus_shares';
  collection: Scalars['String']['output'];
  date_created?: Maybe<Scalars['Date']['output']>;
  date_created_func?: Maybe<Datetime_Functions>;
  /** $t:shared_leave_blank_for_unlimited */
  date_end?: Maybe<Scalars['Date']['output']>;
  date_end_func?: Maybe<Datetime_Functions>;
  /** $t:shared_leave_blank_for_unlimited */
  date_start?: Maybe<Scalars['Date']['output']>;
  date_start_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  item: Scalars['String']['output'];
  /** $t:shared_leave_blank_for_unlimited */
  max_uses?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** $t:shared_leave_blank_for_passwordless_access */
  password?: Maybe<Scalars['Hash']['output']>;
  role?: Maybe<Directus_Roles>;
  times_used?: Maybe<Scalars['Int']['output']>;
  user_created?: Maybe<Directus_Users>;
};


export type Directus_SharesRoleArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_SharesUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Shares_Mutated = {
  __typename?: 'directus_shares_mutated';
  data?: Maybe<Directus_Shares>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Translations = {
  __typename?: 'directus_translations';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  language: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Directus_Translations_Mutated = {
  __typename?: 'directus_translations_mutated';
  data?: Maybe<Directus_Translations>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Users = {
  __typename?: 'directus_users';
  auth_data?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Directus_Files>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_notifications?: Maybe<Scalars['Boolean']['output']>;
  external_id?: Maybe<Scalars['GraphQLBigInt']['output']>;
  external_identifier?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  last_access?: Maybe<Scalars['Date']['output']>;
  last_access_func?: Maybe<Datetime_Functions>;
  last_name?: Maybe<Scalars['String']['output']>;
  last_page?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['Hash']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Directus_Roles>;
  settings?: Maybe<Settings>;
  status?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Scalars['JSON']['output']>;
  tags_func?: Maybe<Count_Functions>;
  tfa_secret?: Maybe<Scalars['Hash']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['Hash']['output']>;
};


export type Directus_UsersAvatarArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_UsersRoleArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_UsersSettingsArgs = {
  filter?: InputMaybe<Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Users_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Users_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Users_Filter>>>;
  auth_data?: InputMaybe<String_Filter_Operators>;
  avatar?: InputMaybe<Directus_Files_Filter>;
  description?: InputMaybe<String_Filter_Operators>;
  email?: InputMaybe<String_Filter_Operators>;
  email_notifications?: InputMaybe<Boolean_Filter_Operators>;
  external_id?: InputMaybe<Number_Filter_Operators>;
  external_identifier?: InputMaybe<String_Filter_Operators>;
  first_name?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  language?: InputMaybe<String_Filter_Operators>;
  last_access?: InputMaybe<Date_Filter_Operators>;
  last_access_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  last_name?: InputMaybe<String_Filter_Operators>;
  last_page?: InputMaybe<String_Filter_Operators>;
  location?: InputMaybe<String_Filter_Operators>;
  password?: InputMaybe<Hash_Filter_Operators>;
  provider?: InputMaybe<String_Filter_Operators>;
  role?: InputMaybe<Directus_Roles_Filter>;
  settings?: InputMaybe<Settings_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<String_Filter_Operators>;
  tags_func?: InputMaybe<Count_Function_Filter_Operators>;
  tfa_secret?: InputMaybe<Hash_Filter_Operators>;
  theme?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  token?: InputMaybe<Hash_Filter_Operators>;
};

export type Directus_Users_Mutated = {
  __typename?: 'directus_users_mutated';
  data?: Maybe<Directus_Users>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Webhooks = {
  __typename?: 'directus_webhooks';
  actions: Array<Maybe<Scalars['String']['output']>>;
  collections: Array<Maybe<Scalars['String']['output']>>;
  data?: Maybe<Scalars['Boolean']['output']>;
  headers?: Maybe<Scalars['JSON']['output']>;
  headers_func?: Maybe<Count_Functions>;
  id: Scalars['ID']['output'];
  method?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type Directus_Webhooks_Mutated = {
  __typename?: 'directus_webhooks_mutated';
  data?: Maybe<Directus_Webhooks>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Function_Types = {
  __typename?: 'function_types';
  functions_settings?: Maybe<Array<Maybe<Functions_Settings>>>;
  functions_settings_func?: Maybe<Count_Functions>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};


export type Function_TypesFunctions_SettingsArgs = {
  filter?: InputMaybe<Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Function_Types_Aggregated = {
  __typename?: 'function_types_aggregated';
  count?: Maybe<Function_Types_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Function_Types_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Function_Types_Aggregated_Count = {
  __typename?: 'function_types_aggregated_count';
  functions_settings?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export type Function_Types_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Function_Types_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Function_Types_Filter>>>;
  functions_settings?: InputMaybe<Functions_Settings_Filter>;
  functions_settings_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
};

export type Function_Types_Mutated = {
  __typename?: 'function_types_mutated';
  data?: Maybe<Function_Types>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Functions = {
  __typename?: 'functions';
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Directus_Files>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  settings?: Maybe<Array<Maybe<Functions_Settings>>>;
  settings_func?: Maybe<Count_Functions>;
};


export type FunctionsIconArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type FunctionsSettingsArgs = {
  filter?: InputMaybe<Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Functions_Aggregated = {
  __typename?: 'functions_aggregated';
  count?: Maybe<Functions_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Functions_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Functions_Aggregated_Count = {
  __typename?: 'functions_aggregated_count';
  description?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  settings?: Maybe<Scalars['Int']['output']>;
};

export type Functions_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Functions_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Functions_Filter>>>;
  description?: InputMaybe<String_Filter_Operators>;
  icon?: InputMaybe<Directus_Files_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  settings?: InputMaybe<Functions_Settings_Filter>;
  settings_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Functions_Mutated = {
  __typename?: 'functions_mutated';
  data?: Maybe<Functions>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Functions_Settings = {
  __typename?: 'functions_settings';
  function_type_id?: Maybe<Function_Types>;
  functions_id?: Maybe<Functions>;
  id: Scalars['ID']['output'];
  is_pinned?: Maybe<Scalars['Boolean']['output']>;
  order_number?: Maybe<Scalars['GraphQLBigInt']['output']>;
  settings_id?: Maybe<Settings>;
};


export type Functions_SettingsFunction_Type_IdArgs = {
  filter?: InputMaybe<Function_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Functions_SettingsFunctions_IdArgs = {
  filter?: InputMaybe<Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Functions_SettingsSettings_IdArgs = {
  filter?: InputMaybe<Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Functions_Settings_Aggregated = {
  __typename?: 'functions_settings_aggregated';
  avg?: Maybe<Functions_Settings_Aggregated_Fields>;
  avgDistinct?: Maybe<Functions_Settings_Aggregated_Fields>;
  count?: Maybe<Functions_Settings_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Functions_Settings_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Functions_Settings_Aggregated_Fields>;
  min?: Maybe<Functions_Settings_Aggregated_Fields>;
  sum?: Maybe<Functions_Settings_Aggregated_Fields>;
  sumDistinct?: Maybe<Functions_Settings_Aggregated_Fields>;
};

export type Functions_Settings_Aggregated_Count = {
  __typename?: 'functions_settings_aggregated_count';
  function_type_id?: Maybe<Scalars['Int']['output']>;
  functions_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is_pinned?: Maybe<Scalars['Int']['output']>;
  order_number?: Maybe<Scalars['Int']['output']>;
  settings_id?: Maybe<Scalars['Int']['output']>;
};

export type Functions_Settings_Aggregated_Fields = {
  __typename?: 'functions_settings_aggregated_fields';
  function_type_id?: Maybe<Scalars['Float']['output']>;
  functions_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order_number?: Maybe<Scalars['Float']['output']>;
  settings_id?: Maybe<Scalars['Float']['output']>;
};

export type Functions_Settings_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Functions_Settings_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Functions_Settings_Filter>>>;
  function_type_id?: InputMaybe<Function_Types_Filter>;
  functions_id?: InputMaybe<Functions_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  is_pinned?: InputMaybe<Boolean_Filter_Operators>;
  order_number?: InputMaybe<Number_Filter_Operators>;
  settings_id?: InputMaybe<Settings_Filter>;
};

export type Functions_Settings_Mutated = {
  __typename?: 'functions_settings_mutated';
  data?: Maybe<Functions_Settings>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Hash_Filter_Operators = {
  _empty?: InputMaybe<Scalars['Boolean']['input']>;
  _nempty?: InputMaybe<Scalars['Boolean']['input']>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Logs = {
  __typename?: 'logs';
  correlation_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  log_level: Scalars['String']['output'];
  message: Scalars['String']['output'];
  method: Scalars['String']['output'];
  module: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['Date']['output']>;
  timestamp_func?: Maybe<Datetime_Functions>;
};

export type Logs_Aggregated = {
  __typename?: 'logs_aggregated';
  count?: Maybe<Logs_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Logs_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Logs_Aggregated_Count = {
  __typename?: 'logs_aggregated_count';
  correlation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  log_level?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['Int']['output']>;
  method?: Maybe<Scalars['Int']['output']>;
  module?: Maybe<Scalars['Int']['output']>;
  timestamp?: Maybe<Scalars['Int']['output']>;
};

export type Logs_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Logs_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Logs_Filter>>>;
  correlation_id?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  log_level?: InputMaybe<String_Filter_Operators>;
  message?: InputMaybe<String_Filter_Operators>;
  method?: InputMaybe<String_Filter_Operators>;
  module?: InputMaybe<String_Filter_Operators>;
  timestamp?: InputMaybe<Date_Filter_Operators>;
  timestamp_func?: InputMaybe<Datetime_Function_Filter_Operators>;
};

export type Logs_Mutated = {
  __typename?: 'logs_mutated';
  data?: Maybe<Logs>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Lt_Se_Bogen = {
  __typename?: 'lt_se_bogen';
  fragen?: Maybe<Array<Maybe<T_Se_Bogen2frage>>>;
  fragen_func?: Maybe<Count_Functions>;
  inactive_am?: Maybe<Scalars['Date']['output']>;
  inactive_am_func?: Maybe<Datetime_Functions>;
  inactive_von?: Maybe<Scalars['Int']['output']>;
  seb_id: Scalars['ID']['output'];
  seb_name: Scalars['String']['output'];
};


export type Lt_Se_BogenFragenArgs = {
  filter?: InputMaybe<T_Se_Bogen2frage_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Lt_Se_Bogen_Aggregated = {
  __typename?: 'lt_se_bogen_aggregated';
  avg?: Maybe<Lt_Se_Bogen_Aggregated_Fields>;
  avgDistinct?: Maybe<Lt_Se_Bogen_Aggregated_Fields>;
  count?: Maybe<Lt_Se_Bogen_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Lt_Se_Bogen_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Lt_Se_Bogen_Aggregated_Fields>;
  min?: Maybe<Lt_Se_Bogen_Aggregated_Fields>;
  sum?: Maybe<Lt_Se_Bogen_Aggregated_Fields>;
  sumDistinct?: Maybe<Lt_Se_Bogen_Aggregated_Fields>;
};

export type Lt_Se_Bogen_Aggregated_Count = {
  __typename?: 'lt_se_bogen_aggregated_count';
  fragen?: Maybe<Scalars['Int']['output']>;
  inactive_am?: Maybe<Scalars['Int']['output']>;
  inactive_von?: Maybe<Scalars['Int']['output']>;
  seb_id?: Maybe<Scalars['Int']['output']>;
  seb_name?: Maybe<Scalars['Int']['output']>;
};

export type Lt_Se_Bogen_Aggregated_Fields = {
  __typename?: 'lt_se_bogen_aggregated_fields';
  inactive_von?: Maybe<Scalars['Float']['output']>;
  seb_id?: Maybe<Scalars['Float']['output']>;
};

export type Lt_Se_Bogen_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Lt_Se_Bogen_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Lt_Se_Bogen_Filter>>>;
  fragen?: InputMaybe<T_Se_Bogen2frage_Filter>;
  fragen_func?: InputMaybe<Count_Function_Filter_Operators>;
  inactive_am?: InputMaybe<Date_Filter_Operators>;
  inactive_am_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  inactive_von?: InputMaybe<Number_Filter_Operators>;
  seb_id?: InputMaybe<Number_Filter_Operators>;
  seb_name?: InputMaybe<String_Filter_Operators>;
};

export type Lt_Se_Bogen_Mutated = {
  __typename?: 'lt_se_bogen_mutated';
  data?: Maybe<Lt_Se_Bogen>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Lt_Se_Fragen = {
  __typename?: 'lt_se_fragen';
  bogen?: Maybe<Array<Maybe<T_Se_Bogen2frage>>>;
  bogen_func?: Maybe<Count_Functions>;
  inactive_am?: Maybe<Scalars['Date']['output']>;
  inactive_am_func?: Maybe<Datetime_Functions>;
  inactive_von?: Maybe<Scalars['Int']['output']>;
  sef_id: Scalars['ID']['output'];
  sef_name: Scalars['String']['output'];
};


export type Lt_Se_FragenBogenArgs = {
  filter?: InputMaybe<T_Se_Bogen2frage_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Lt_Se_Fragen_Aggregated = {
  __typename?: 'lt_se_fragen_aggregated';
  avg?: Maybe<Lt_Se_Fragen_Aggregated_Fields>;
  avgDistinct?: Maybe<Lt_Se_Fragen_Aggregated_Fields>;
  count?: Maybe<Lt_Se_Fragen_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Lt_Se_Fragen_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Lt_Se_Fragen_Aggregated_Fields>;
  min?: Maybe<Lt_Se_Fragen_Aggregated_Fields>;
  sum?: Maybe<Lt_Se_Fragen_Aggregated_Fields>;
  sumDistinct?: Maybe<Lt_Se_Fragen_Aggregated_Fields>;
};

export type Lt_Se_Fragen_Aggregated_Count = {
  __typename?: 'lt_se_fragen_aggregated_count';
  bogen?: Maybe<Scalars['Int']['output']>;
  inactive_am?: Maybe<Scalars['Int']['output']>;
  inactive_von?: Maybe<Scalars['Int']['output']>;
  sef_id?: Maybe<Scalars['Int']['output']>;
  sef_name?: Maybe<Scalars['Int']['output']>;
};

export type Lt_Se_Fragen_Aggregated_Fields = {
  __typename?: 'lt_se_fragen_aggregated_fields';
  inactive_von?: Maybe<Scalars['Float']['output']>;
  sef_id?: Maybe<Scalars['Float']['output']>;
};

export type Lt_Se_Fragen_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Lt_Se_Fragen_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Lt_Se_Fragen_Filter>>>;
  bogen?: InputMaybe<T_Se_Bogen2frage_Filter>;
  bogen_func?: InputMaybe<Count_Function_Filter_Operators>;
  inactive_am?: InputMaybe<Date_Filter_Operators>;
  inactive_am_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  inactive_von?: InputMaybe<Number_Filter_Operators>;
  sef_id?: InputMaybe<Number_Filter_Operators>;
  sef_name?: InputMaybe<String_Filter_Operators>;
};

export type Lt_Se_Fragen_Mutated = {
  __typename?: 'lt_se_fragen_mutated';
  data?: Maybe<Lt_Se_Fragen>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Number_Filter_Operators = {
  _between?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _eq?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _gt?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _gte?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _lt?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _lte?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _nbetween?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _neq?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Parameter_Groups = {
  __typename?: 'parameter_groups';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parameters?: Maybe<Array<Maybe<Parameters_Parameter_Groups>>>;
  parameters_func?: Maybe<Count_Functions>;
};


export type Parameter_GroupsParametersArgs = {
  filter?: InputMaybe<Parameters_Parameter_Groups_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Parameter_Groups_Aggregated = {
  __typename?: 'parameter_groups_aggregated';
  count?: Maybe<Parameter_Groups_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Parameter_Groups_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Parameter_Groups_Aggregated_Count = {
  __typename?: 'parameter_groups_aggregated_count';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  parameters?: Maybe<Scalars['Int']['output']>;
};

export type Parameter_Groups_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Parameter_Groups_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Parameter_Groups_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  parameters?: InputMaybe<Parameters_Parameter_Groups_Filter>;
  parameters_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Parameter_Groups_Mutated = {
  __typename?: 'parameter_groups_mutated';
  data?: Maybe<Parameter_Groups>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Parameter_Types = {
  __typename?: 'parameter_types';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parameters?: Maybe<Array<Maybe<Parameters>>>;
  parameters_func?: Maybe<Count_Functions>;
  sort_id?: Maybe<Scalars['GraphQLBigInt']['output']>;
};


export type Parameter_TypesParametersArgs = {
  filter?: InputMaybe<Parameters_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Parameter_Types_Aggregated = {
  __typename?: 'parameter_types_aggregated';
  count?: Maybe<Parameter_Types_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Parameter_Types_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Parameter_Types_Aggregated_Count = {
  __typename?: 'parameter_types_aggregated_count';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  parameters?: Maybe<Scalars['Int']['output']>;
  sort_id?: Maybe<Scalars['Int']['output']>;
};

export type Parameter_Types_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Parameter_Types_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Parameter_Types_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  parameters?: InputMaybe<Parameters_Filter>;
  parameters_func?: InputMaybe<Count_Function_Filter_Operators>;
  sort_id?: InputMaybe<Number_Filter_Operators>;
};

export type Parameter_Types_Mutated = {
  __typename?: 'parameter_types_mutated';
  data?: Maybe<Parameter_Types>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Parameters = {
  __typename?: 'parameters';
  caption?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  is_license: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parameter_group?: Maybe<Array<Maybe<Parameters_Parameter_Groups>>>;
  parameter_group_func?: Maybe<Count_Functions>;
  parameter_type_id?: Maybe<Parameter_Types>;
  value?: Maybe<Scalars['String']['output']>;
};


export type ParametersParameter_GroupArgs = {
  filter?: InputMaybe<Parameters_Parameter_Groups_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ParametersParameter_Type_IdArgs = {
  filter?: InputMaybe<Parameter_Types_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Parameters_Aggregated = {
  __typename?: 'parameters_aggregated';
  count?: Maybe<Parameters_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Parameters_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Parameters_Aggregated_Count = {
  __typename?: 'parameters_aggregated_count';
  caption?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is_license?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  parameter_group?: Maybe<Scalars['Int']['output']>;
  parameter_type_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type Parameters_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Parameters_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Parameters_Filter>>>;
  caption?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  is_license?: InputMaybe<Boolean_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  parameter_group?: InputMaybe<Parameters_Parameter_Groups_Filter>;
  parameter_group_func?: InputMaybe<Count_Function_Filter_Operators>;
  parameter_type_id?: InputMaybe<Parameter_Types_Filter>;
  value?: InputMaybe<String_Filter_Operators>;
};

export type Parameters_Mutated = {
  __typename?: 'parameters_mutated';
  data?: Maybe<Parameters>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Parameters_Parameter_Groups = {
  __typename?: 'parameters_parameter_groups';
  id: Scalars['ID']['output'];
  parameter_groups_id?: Maybe<Parameter_Groups>;
  parameters_id?: Maybe<Parameters>;
};


export type Parameters_Parameter_GroupsParameter_Groups_IdArgs = {
  filter?: InputMaybe<Parameter_Groups_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Parameters_Parameter_GroupsParameters_IdArgs = {
  filter?: InputMaybe<Parameters_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Parameters_Parameter_Groups_Aggregated = {
  __typename?: 'parameters_parameter_groups_aggregated';
  avg?: Maybe<Parameters_Parameter_Groups_Aggregated_Fields>;
  avgDistinct?: Maybe<Parameters_Parameter_Groups_Aggregated_Fields>;
  count?: Maybe<Parameters_Parameter_Groups_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Parameters_Parameter_Groups_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Parameters_Parameter_Groups_Aggregated_Fields>;
  min?: Maybe<Parameters_Parameter_Groups_Aggregated_Fields>;
  sum?: Maybe<Parameters_Parameter_Groups_Aggregated_Fields>;
  sumDistinct?: Maybe<Parameters_Parameter_Groups_Aggregated_Fields>;
};

export type Parameters_Parameter_Groups_Aggregated_Count = {
  __typename?: 'parameters_parameter_groups_aggregated_count';
  id?: Maybe<Scalars['Int']['output']>;
  parameter_groups_id?: Maybe<Scalars['Int']['output']>;
  parameters_id?: Maybe<Scalars['Int']['output']>;
};

export type Parameters_Parameter_Groups_Aggregated_Fields = {
  __typename?: 'parameters_parameter_groups_aggregated_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parameter_groups_id?: Maybe<Scalars['Float']['output']>;
  parameters_id?: Maybe<Scalars['Float']['output']>;
};

export type Parameters_Parameter_Groups_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Parameters_Parameter_Groups_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Parameters_Parameter_Groups_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  parameter_groups_id?: InputMaybe<Parameter_Groups_Filter>;
  parameters_id?: InputMaybe<Parameters_Filter>;
};

export type Parameters_Parameter_Groups_Mutated = {
  __typename?: 'parameters_parameter_groups_mutated';
  data?: Maybe<Parameters_Parameter_Groups>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Reports = {
  __typename?: 'reports';
  file_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  is_visible?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type Reports_Aggregated = {
  __typename?: 'reports_aggregated';
  avg?: Maybe<Reports_Aggregated_Fields>;
  avgDistinct?: Maybe<Reports_Aggregated_Fields>;
  count?: Maybe<Reports_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Reports_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Reports_Aggregated_Fields>;
  min?: Maybe<Reports_Aggregated_Fields>;
  sum?: Maybe<Reports_Aggregated_Fields>;
  sumDistinct?: Maybe<Reports_Aggregated_Fields>;
};

export type Reports_Aggregated_Count = {
  __typename?: 'reports_aggregated_count';
  file_name?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is_visible?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
};

export type Reports_Aggregated_Fields = {
  __typename?: 'reports_aggregated_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type Reports_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Reports_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Reports_Filter>>>;
  file_name?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  is_visible?: InputMaybe<Boolean_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Reports_Mutated = {
  __typename?: 'reports_mutated';
  data?: Maybe<Reports>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Settings = {
  __typename?: 'settings';
  administration_functions?: Maybe<Array<Maybe<Administration_Functions_Settings>>>;
  administration_functions_func?: Maybe<Count_Functions>;
  favorite_persons?: Maybe<Scalars['String']['output']>;
  functions?: Maybe<Array<Maybe<Functions_Settings>>>;
  functions_func?: Maybe<Count_Functions>;
  id: Scalars['ID']['output'];
  language_code?: Maybe<Scalars['String']['output']>;
  selected_departments?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Array<Maybe<Directus_Users>>>;
  user_func?: Maybe<Count_Functions>;
  widgets?: Maybe<Array<Maybe<Settings_Widgets>>>;
  widgets_func?: Maybe<Count_Functions>;
};


export type SettingsAdministration_FunctionsArgs = {
  filter?: InputMaybe<Administration_Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type SettingsFunctionsArgs = {
  filter?: InputMaybe<Functions_Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type SettingsUserArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type SettingsWidgetsArgs = {
  filter?: InputMaybe<Settings_Widgets_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Settings_Aggregated = {
  __typename?: 'settings_aggregated';
  count?: Maybe<Settings_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Settings_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Settings_Aggregated_Count = {
  __typename?: 'settings_aggregated_count';
  administration_functions?: Maybe<Scalars['Int']['output']>;
  favorite_persons?: Maybe<Scalars['Int']['output']>;
  functions?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language_code?: Maybe<Scalars['Int']['output']>;
  selected_departments?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<Scalars['Int']['output']>;
  widgets?: Maybe<Scalars['Int']['output']>;
};

export type Settings_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Settings_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Settings_Filter>>>;
  administration_functions?: InputMaybe<Administration_Functions_Settings_Filter>;
  administration_functions_func?: InputMaybe<Count_Function_Filter_Operators>;
  favorite_persons?: InputMaybe<String_Filter_Operators>;
  functions?: InputMaybe<Functions_Settings_Filter>;
  functions_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  language_code?: InputMaybe<String_Filter_Operators>;
  selected_departments?: InputMaybe<String_Filter_Operators>;
  user?: InputMaybe<Directus_Users_Filter>;
  user_func?: InputMaybe<Count_Function_Filter_Operators>;
  widgets?: InputMaybe<Settings_Widgets_Filter>;
  widgets_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Settings_Mutated = {
  __typename?: 'settings_mutated';
  data?: Maybe<Settings>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Settings_Widgets = {
  __typename?: 'settings_widgets';
  column_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  is_visible?: Maybe<Scalars['Boolean']['output']>;
  order_number?: Maybe<Scalars['GraphQLBigInt']['output']>;
  settings_id?: Maybe<Settings>;
  widgets_id?: Maybe<Widgets>;
};


export type Settings_WidgetsSettings_IdArgs = {
  filter?: InputMaybe<Settings_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Settings_WidgetsWidgets_IdArgs = {
  filter?: InputMaybe<Widgets_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Settings_Widgets_Aggregated = {
  __typename?: 'settings_widgets_aggregated';
  avg?: Maybe<Settings_Widgets_Aggregated_Fields>;
  avgDistinct?: Maybe<Settings_Widgets_Aggregated_Fields>;
  count?: Maybe<Settings_Widgets_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Settings_Widgets_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Settings_Widgets_Aggregated_Fields>;
  min?: Maybe<Settings_Widgets_Aggregated_Fields>;
  sum?: Maybe<Settings_Widgets_Aggregated_Fields>;
  sumDistinct?: Maybe<Settings_Widgets_Aggregated_Fields>;
};

export type Settings_Widgets_Aggregated_Count = {
  __typename?: 'settings_widgets_aggregated_count';
  column_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is_visible?: Maybe<Scalars['Int']['output']>;
  order_number?: Maybe<Scalars['Int']['output']>;
  settings_id?: Maybe<Scalars['Int']['output']>;
  widgets_id?: Maybe<Scalars['Int']['output']>;
};

export type Settings_Widgets_Aggregated_Fields = {
  __typename?: 'settings_widgets_aggregated_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_number?: Maybe<Scalars['Float']['output']>;
  settings_id?: Maybe<Scalars['Float']['output']>;
  widgets_id?: Maybe<Scalars['Float']['output']>;
};

export type Settings_Widgets_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Settings_Widgets_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Settings_Widgets_Filter>>>;
  column_id?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  is_visible?: InputMaybe<Boolean_Filter_Operators>;
  order_number?: InputMaybe<Number_Filter_Operators>;
  settings_id?: InputMaybe<Settings_Filter>;
  widgets_id?: InputMaybe<Widgets_Filter>;
};

export type Settings_Widgets_Mutated = {
  __typename?: 'settings_widgets_mutated';
  data?: Maybe<Settings_Widgets>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Shortcut_Functions = {
  __typename?: 'shortcut_functions';
  function_id?: Maybe<Functions>;
  icon?: Maybe<Directus_Files>;
  id: Scalars['ID']['output'];
  tooltip_text?: Maybe<Scalars['String']['output']>;
  visibility_type?: Maybe<Scalars['String']['output']>;
};


export type Shortcut_FunctionsFunction_IdArgs = {
  filter?: InputMaybe<Functions_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Shortcut_FunctionsIconArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Shortcut_Functions_Aggregated = {
  __typename?: 'shortcut_functions_aggregated';
  count?: Maybe<Shortcut_Functions_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Shortcut_Functions_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Shortcut_Functions_Aggregated_Count = {
  __typename?: 'shortcut_functions_aggregated_count';
  function_id?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  tooltip_text?: Maybe<Scalars['Int']['output']>;
  visibility_type?: Maybe<Scalars['Int']['output']>;
};

export type Shortcut_Functions_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Shortcut_Functions_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Shortcut_Functions_Filter>>>;
  function_id?: InputMaybe<Functions_Filter>;
  icon?: InputMaybe<Directus_Files_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  tooltip_text?: InputMaybe<String_Filter_Operators>;
  visibility_type?: InputMaybe<String_Filter_Operators>;
};

export type Shortcut_Functions_Mutated = {
  __typename?: 'shortcut_functions_mutated';
  data?: Maybe<Shortcut_Functions>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type String_Filter_Operators = {
  _contains?: InputMaybe<Scalars['String']['input']>;
  _empty?: InputMaybe<Scalars['Boolean']['input']>;
  _ends_with?: InputMaybe<Scalars['String']['input']>;
  _eq?: InputMaybe<Scalars['String']['input']>;
  _icontains?: InputMaybe<Scalars['String']['input']>;
  _iends_with?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  _istarts_with?: InputMaybe<Scalars['String']['input']>;
  _ncontains?: InputMaybe<Scalars['String']['input']>;
  _nempty?: InputMaybe<Scalars['Boolean']['input']>;
  _nends_with?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _niends_with?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  _nistarts_with?: InputMaybe<Scalars['String']['input']>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _nstarts_with?: InputMaybe<Scalars['String']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
  _starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type T_Se_Antworten = {
  __typename?: 't_se_antworten';
  anmerkung: Scalars['String']['output'];
  antwort: Scalars['Int']['output'];
  edit_am?: Maybe<Scalars['Date']['output']>;
  edit_am_func?: Maybe<Datetime_Functions>;
  m2s_id?: Maybe<T_Se_Ma2seb>;
  sea_id: Scalars['ID']['output'];
  sef_id: Scalars['Int']['output'];
};


export type T_Se_AntwortenM2s_IdArgs = {
  filter?: InputMaybe<T_Se_Ma2seb_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type T_Se_Antworten_Aggregated = {
  __typename?: 't_se_antworten_aggregated';
  avg?: Maybe<T_Se_Antworten_Aggregated_Fields>;
  avgDistinct?: Maybe<T_Se_Antworten_Aggregated_Fields>;
  count?: Maybe<T_Se_Antworten_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<T_Se_Antworten_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<T_Se_Antworten_Aggregated_Fields>;
  min?: Maybe<T_Se_Antworten_Aggregated_Fields>;
  sum?: Maybe<T_Se_Antworten_Aggregated_Fields>;
  sumDistinct?: Maybe<T_Se_Antworten_Aggregated_Fields>;
};

export type T_Se_Antworten_Aggregated_Count = {
  __typename?: 't_se_antworten_aggregated_count';
  anmerkung?: Maybe<Scalars['Int']['output']>;
  antwort?: Maybe<Scalars['Int']['output']>;
  edit_am?: Maybe<Scalars['Int']['output']>;
  m2s_id?: Maybe<Scalars['Int']['output']>;
  sea_id?: Maybe<Scalars['Int']['output']>;
  sef_id?: Maybe<Scalars['Int']['output']>;
};

export type T_Se_Antworten_Aggregated_Fields = {
  __typename?: 't_se_antworten_aggregated_fields';
  antwort?: Maybe<Scalars['Float']['output']>;
  m2s_id?: Maybe<Scalars['Float']['output']>;
  sea_id?: Maybe<Scalars['Float']['output']>;
  sef_id?: Maybe<Scalars['Float']['output']>;
};

export type T_Se_Antworten_Filter = {
  _and?: InputMaybe<Array<InputMaybe<T_Se_Antworten_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<T_Se_Antworten_Filter>>>;
  anmerkung?: InputMaybe<String_Filter_Operators>;
  antwort?: InputMaybe<Number_Filter_Operators>;
  edit_am?: InputMaybe<Date_Filter_Operators>;
  edit_am_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  m2s_id?: InputMaybe<T_Se_Ma2seb_Filter>;
  sea_id?: InputMaybe<Number_Filter_Operators>;
  sef_id?: InputMaybe<Number_Filter_Operators>;
};

export type T_Se_Antworten_Mutated = {
  __typename?: 't_se_antworten_mutated';
  data?: Maybe<T_Se_Antworten>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type T_Se_Bogen2frage = {
  __typename?: 't_se_bogen2frage';
  id: Scalars['ID']['output'];
  seb_id?: Maybe<Lt_Se_Bogen>;
  sef_id?: Maybe<Lt_Se_Fragen>;
  sef_order_id: Scalars['Int']['output'];
};


export type T_Se_Bogen2frageSeb_IdArgs = {
  filter?: InputMaybe<Lt_Se_Bogen_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type T_Se_Bogen2frageSef_IdArgs = {
  filter?: InputMaybe<Lt_Se_Fragen_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type T_Se_Bogen2frage_Aggregated = {
  __typename?: 't_se_bogen2frage_aggregated';
  avg?: Maybe<T_Se_Bogen2frage_Aggregated_Fields>;
  avgDistinct?: Maybe<T_Se_Bogen2frage_Aggregated_Fields>;
  count?: Maybe<T_Se_Bogen2frage_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<T_Se_Bogen2frage_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<T_Se_Bogen2frage_Aggregated_Fields>;
  min?: Maybe<T_Se_Bogen2frage_Aggregated_Fields>;
  sum?: Maybe<T_Se_Bogen2frage_Aggregated_Fields>;
  sumDistinct?: Maybe<T_Se_Bogen2frage_Aggregated_Fields>;
};

export type T_Se_Bogen2frage_Aggregated_Count = {
  __typename?: 't_se_bogen2frage_aggregated_count';
  id?: Maybe<Scalars['Int']['output']>;
  seb_id?: Maybe<Scalars['Int']['output']>;
  sef_id?: Maybe<Scalars['Int']['output']>;
  sef_order_id?: Maybe<Scalars['Int']['output']>;
};

export type T_Se_Bogen2frage_Aggregated_Fields = {
  __typename?: 't_se_bogen2frage_aggregated_fields';
  id?: Maybe<Scalars['Float']['output']>;
  seb_id?: Maybe<Scalars['Float']['output']>;
  sef_id?: Maybe<Scalars['Float']['output']>;
  sef_order_id?: Maybe<Scalars['Float']['output']>;
};

export type T_Se_Bogen2frage_Filter = {
  _and?: InputMaybe<Array<InputMaybe<T_Se_Bogen2frage_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<T_Se_Bogen2frage_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  seb_id?: InputMaybe<Lt_Se_Bogen_Filter>;
  sef_id?: InputMaybe<Lt_Se_Fragen_Filter>;
  sef_order_id?: InputMaybe<Number_Filter_Operators>;
};

export type T_Se_Bogen2frage_Mutated = {
  __typename?: 't_se_bogen2frage_mutated';
  data?: Maybe<T_Se_Bogen2frage>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type T_Se_Ma2seb = {
  __typename?: 't_se_ma2seb';
  m2s_id: Scalars['ID']['output'];
  ma_id: Scalars['Int']['output'];
  new_am?: Maybe<Scalars['Date']['output']>;
  new_am_func?: Maybe<Datetime_Functions>;
  new_von: Scalars['Int']['output'];
  se_status_id?: Maybe<T_Se_Status>;
  seb_id: Scalars['Int']['output'];
  selbst_beantwortet: Scalars['Boolean']['output'];
};


export type T_Se_Ma2sebSe_Status_IdArgs = {
  filter?: InputMaybe<T_Se_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type T_Se_Ma2seb_Aggregated = {
  __typename?: 't_se_ma2seb_aggregated';
  avg?: Maybe<T_Se_Ma2seb_Aggregated_Fields>;
  avgDistinct?: Maybe<T_Se_Ma2seb_Aggregated_Fields>;
  count?: Maybe<T_Se_Ma2seb_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<T_Se_Ma2seb_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<T_Se_Ma2seb_Aggregated_Fields>;
  min?: Maybe<T_Se_Ma2seb_Aggregated_Fields>;
  sum?: Maybe<T_Se_Ma2seb_Aggregated_Fields>;
  sumDistinct?: Maybe<T_Se_Ma2seb_Aggregated_Fields>;
};

export type T_Se_Ma2seb_Aggregated_Count = {
  __typename?: 't_se_ma2seb_aggregated_count';
  m2s_id?: Maybe<Scalars['Int']['output']>;
  ma_id?: Maybe<Scalars['Int']['output']>;
  new_am?: Maybe<Scalars['Int']['output']>;
  new_von?: Maybe<Scalars['Int']['output']>;
  se_status_id?: Maybe<Scalars['Int']['output']>;
  seb_id?: Maybe<Scalars['Int']['output']>;
  selbst_beantwortet?: Maybe<Scalars['Int']['output']>;
};

export type T_Se_Ma2seb_Aggregated_Fields = {
  __typename?: 't_se_ma2seb_aggregated_fields';
  m2s_id?: Maybe<Scalars['Float']['output']>;
  ma_id?: Maybe<Scalars['Float']['output']>;
  new_von?: Maybe<Scalars['Float']['output']>;
  se_status_id?: Maybe<Scalars['Float']['output']>;
  seb_id?: Maybe<Scalars['Float']['output']>;
};

export type T_Se_Ma2seb_Filter = {
  _and?: InputMaybe<Array<InputMaybe<T_Se_Ma2seb_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<T_Se_Ma2seb_Filter>>>;
  m2s_id?: InputMaybe<Number_Filter_Operators>;
  ma_id?: InputMaybe<Number_Filter_Operators>;
  new_am?: InputMaybe<Date_Filter_Operators>;
  new_am_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  new_von?: InputMaybe<Number_Filter_Operators>;
  se_status_id?: InputMaybe<T_Se_Status_Filter>;
  seb_id?: InputMaybe<Number_Filter_Operators>;
  selbst_beantwortet?: InputMaybe<Boolean_Filter_Operators>;
};

export type T_Se_Ma2seb_Mutated = {
  __typename?: 't_se_ma2seb_mutated';
  data?: Maybe<T_Se_Ma2seb>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type T_Se_Status = {
  __typename?: 't_se_status';
  order_id?: Maybe<Scalars['Int']['output']>;
  se_status_id: Scalars['ID']['output'];
  status_name: Scalars['String']['output'];
};

export type T_Se_Status_Aggregated = {
  __typename?: 't_se_status_aggregated';
  avg?: Maybe<T_Se_Status_Aggregated_Fields>;
  avgDistinct?: Maybe<T_Se_Status_Aggregated_Fields>;
  count?: Maybe<T_Se_Status_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<T_Se_Status_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<T_Se_Status_Aggregated_Fields>;
  min?: Maybe<T_Se_Status_Aggregated_Fields>;
  sum?: Maybe<T_Se_Status_Aggregated_Fields>;
  sumDistinct?: Maybe<T_Se_Status_Aggregated_Fields>;
};

export type T_Se_Status_Aggregated_Count = {
  __typename?: 't_se_status_aggregated_count';
  order_id?: Maybe<Scalars['Int']['output']>;
  se_status_id?: Maybe<Scalars['Int']['output']>;
  status_name?: Maybe<Scalars['Int']['output']>;
};

export type T_Se_Status_Aggregated_Fields = {
  __typename?: 't_se_status_aggregated_fields';
  order_id?: Maybe<Scalars['Float']['output']>;
  se_status_id?: Maybe<Scalars['Float']['output']>;
};

export type T_Se_Status_Filter = {
  _and?: InputMaybe<Array<InputMaybe<T_Se_Status_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<T_Se_Status_Filter>>>;
  order_id?: InputMaybe<Number_Filter_Operators>;
  se_status_id?: InputMaybe<Number_Filter_Operators>;
  status_name?: InputMaybe<String_Filter_Operators>;
};

export type T_Se_Status_Mutated = {
  __typename?: 't_se_status_mutated';
  data?: Maybe<T_Se_Status>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Update_Administration_Function_Types_Input = {
  administration_functions_settings?: InputMaybe<Array<InputMaybe<Update_Administration_Functions_Settings_Input>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Update_Administration_Functions_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Update_Directus_Files_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Array<InputMaybe<Update_Administration_Functions_Settings_Input>>>;
};

export type Update_Administration_Functions_Settings_Input = {
  administration_functions_id?: InputMaybe<Update_Administration_Functions_Input>;
  function_type_id?: InputMaybe<Update_Administration_Function_Types_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_pinned?: InputMaybe<Scalars['Boolean']['input']>;
  order_number?: InputMaybe<Scalars['Int']['input']>;
  settings_id?: InputMaybe<Update_Settings_Input>;
};

export type Update_Directus_Files_Input = {
  charset?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  embed?: InputMaybe<Scalars['String']['input']>;
  filename_disk?: InputMaybe<Scalars['String']['input']>;
  filename_download?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  folder?: InputMaybe<Update_Directus_Folders_Input>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  modified_by?: InputMaybe<Update_Directus_Users_Input>;
  modified_on?: InputMaybe<Scalars['Date']['input']>;
  storage?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uploaded_by?: InputMaybe<Update_Directus_Users_Input>;
  uploaded_on?: InputMaybe<Scalars['Date']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type Update_Directus_Folders_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Update_Directus_Folders_Input>;
};

export type Update_Directus_Roles_Input = {
  admin_access?: InputMaybe<Scalars['Boolean']['input']>;
  app_access?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enforce_tfa?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ip_access?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Update_Directus_Users_Input>>>;
};

export type Update_Directus_Users_Input = {
  auth_data?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Update_Directus_Files_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_notifications?: InputMaybe<Scalars['Boolean']['input']>;
  external_id?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  external_identifier?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  last_access?: InputMaybe<Scalars['Date']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_page?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['Hash']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Update_Directus_Roles_Input>;
  settings?: InputMaybe<Update_Settings_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  tfa_secret?: InputMaybe<Scalars['Hash']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['Hash']['input']>;
};

export type Update_Function_Types_Input = {
  functions_settings?: InputMaybe<Array<InputMaybe<Update_Functions_Settings_Input>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Update_Functions_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Update_Directus_Files_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Array<InputMaybe<Update_Functions_Settings_Input>>>;
};

export type Update_Functions_Settings_Input = {
  function_type_id?: InputMaybe<Update_Function_Types_Input>;
  functions_id?: InputMaybe<Update_Functions_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_pinned?: InputMaybe<Scalars['Boolean']['input']>;
  order_number?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  settings_id?: InputMaybe<Update_Settings_Input>;
};

export type Update_Logs_Input = {
  correlation_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  log_level?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  module?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['Date']['input']>;
};

export type Update_Lt_Se_Bogen_Input = {
  fragen?: InputMaybe<Array<InputMaybe<Update_T_Se_Bogen2frage_Input>>>;
  inactive_am?: InputMaybe<Scalars['Date']['input']>;
  inactive_von?: InputMaybe<Scalars['Int']['input']>;
  seb_id?: InputMaybe<Scalars['ID']['input']>;
  seb_name?: InputMaybe<Scalars['String']['input']>;
};

export type Update_Lt_Se_Fragen_Input = {
  bogen?: InputMaybe<Array<InputMaybe<Update_T_Se_Bogen2frage_Input>>>;
  inactive_am?: InputMaybe<Scalars['Date']['input']>;
  inactive_von?: InputMaybe<Scalars['Int']['input']>;
  sef_id?: InputMaybe<Scalars['ID']['input']>;
  sef_name?: InputMaybe<Scalars['String']['input']>;
};

export type Update_Parameter_Groups_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameters?: InputMaybe<Array<InputMaybe<Update_Parameters_Parameter_Groups_Input>>>;
};

export type Update_Parameter_Types_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameters?: InputMaybe<Array<InputMaybe<Update_Parameters_Input>>>;
  sort_id?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
};

export type Update_Parameters_Input = {
  caption?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_license?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter_group?: InputMaybe<Array<InputMaybe<Update_Parameters_Parameter_Groups_Input>>>;
  parameter_type_id?: InputMaybe<Update_Parameter_Types_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Update_Parameters_Parameter_Groups_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  parameter_groups_id?: InputMaybe<Update_Parameter_Groups_Input>;
  parameters_id?: InputMaybe<Update_Parameters_Input>;
};

export type Update_Reports_Input = {
  file_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_visible?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Update_Settings_Input = {
  administration_functions?: InputMaybe<Array<InputMaybe<Update_Administration_Functions_Settings_Input>>>;
  favorite_persons?: InputMaybe<Scalars['String']['input']>;
  functions?: InputMaybe<Array<InputMaybe<Update_Functions_Settings_Input>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language_code?: InputMaybe<Scalars['String']['input']>;
  selected_departments?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Array<InputMaybe<Update_Directus_Users_Input>>>;
  widgets?: InputMaybe<Array<InputMaybe<Update_Settings_Widgets_Input>>>;
};

export type Update_Settings_Widgets_Input = {
  column_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_visible?: InputMaybe<Scalars['Boolean']['input']>;
  order_number?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  settings_id?: InputMaybe<Update_Settings_Input>;
  widgets_id?: InputMaybe<Update_Widgets_Input>;
};

export type Update_Shortcut_Functions_Input = {
  function_id?: InputMaybe<Update_Functions_Input>;
  icon?: InputMaybe<Update_Directus_Files_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  tooltip_text?: InputMaybe<Scalars['String']['input']>;
  visibility_type?: InputMaybe<Scalars['String']['input']>;
};

export type Update_T_Se_Antworten_Input = {
  anmerkung?: InputMaybe<Scalars['String']['input']>;
  antwort?: InputMaybe<Scalars['Int']['input']>;
  edit_am?: InputMaybe<Scalars['Date']['input']>;
  m2s_id?: InputMaybe<Update_T_Se_Ma2seb_Input>;
  sea_id?: InputMaybe<Scalars['ID']['input']>;
  sef_id?: InputMaybe<Scalars['Int']['input']>;
};

export type Update_T_Se_Bogen2frage_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  seb_id?: InputMaybe<Update_Lt_Se_Bogen_Input>;
  sef_id?: InputMaybe<Update_Lt_Se_Fragen_Input>;
  sef_order_id?: InputMaybe<Scalars['Int']['input']>;
};

export type Update_T_Se_Ma2seb_Input = {
  m2s_id?: InputMaybe<Scalars['ID']['input']>;
  ma_id?: InputMaybe<Scalars['Int']['input']>;
  new_am?: InputMaybe<Scalars['Date']['input']>;
  new_von?: InputMaybe<Scalars['Int']['input']>;
  se_status_id?: InputMaybe<Update_T_Se_Status_Input>;
  seb_id?: InputMaybe<Scalars['Int']['input']>;
  selbst_beantwortet?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Update_T_Se_Status_Input = {
  order_id?: InputMaybe<Scalars['Int']['input']>;
  se_status_id?: InputMaybe<Scalars['ID']['input']>;
  status_name?: InputMaybe<Scalars['String']['input']>;
};

export type Update_Widgets_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Update_Directus_Files_Input>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Array<InputMaybe<Update_Settings_Widgets_Input>>>;
};

export type Widgets = {
  __typename?: 'widgets';
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Directus_Files>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  settings?: Maybe<Array<Maybe<Settings_Widgets>>>;
  settings_func?: Maybe<Count_Functions>;
};


export type WidgetsIconArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type WidgetsSettingsArgs = {
  filter?: InputMaybe<Settings_Widgets_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Widgets_Aggregated = {
  __typename?: 'widgets_aggregated';
  count?: Maybe<Widgets_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Widgets_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Widgets_Aggregated_Count = {
  __typename?: 'widgets_aggregated_count';
  description?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  settings?: Maybe<Scalars['Int']['output']>;
};

export type Widgets_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Widgets_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Widgets_Filter>>>;
  description?: InputMaybe<String_Filter_Operators>;
  icon?: InputMaybe<Directus_Files_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  settings?: InputMaybe<Settings_Widgets_Filter>;
  settings_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Widgets_Mutated = {
  __typename?: 'widgets_mutated';
  data?: Maybe<Widgets>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type GetAdministrationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdministrationSettingsQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'settings', administration_functions?: Array<{ __typename?: 'administration_functions_settings', id: string, is_pinned?: boolean | null, order_number?: number | null, function_type_id?: { __typename?: 'administration_function_types', type: string } | null, administration_functions_id?: { __typename?: 'administration_functions', id: string, name?: string | null, icon?: { __typename?: 'directus_files', filename_disk?: string | null } | null } | null } | null> | null }> };

export type UpdateAdministrationFunctionTypeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  function_type?: InputMaybe<Update_Administration_Function_Types_Input>;
}>;


export type UpdateAdministrationFunctionTypeMutation = { __typename?: 'Mutation', update_administration_functions_settings_item?: { __typename?: 'administration_functions_settings', function_type_id?: { __typename?: 'administration_function_types', type: string } | null } | null };

export type UpdateAdministrationPinnedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  is_pinned: Scalars['Boolean']['input'];
}>;


export type UpdateAdministrationPinnedMutation = { __typename?: 'Mutation', update_administration_functions_settings_item?: { __typename?: 'administration_functions_settings', is_pinned?: boolean | null } | null };

export type UpdateAdministrationFunctionOrderNumberMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  order_number?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateAdministrationFunctionOrderNumberMutation = { __typename?: 'Mutation', update_administration_functions_settings_item?: { __typename?: 'administration_functions_settings', order_number?: number | null } | null };

export type GetWidgetsSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWidgetsSettingsQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'settings', widgets?: Array<{ __typename?: 'settings_widgets', id: string, is_visible?: boolean | null, column_id?: string | null, order_number?: any | null, widgets_id?: { __typename?: 'widgets', name: string, icon?: { __typename?: 'directus_files', filename_disk?: string | null } | null } | null } | null> | null }> };

export type UpdateWidgetsPositionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  column_id?: InputMaybe<Scalars['String']['input']>;
  order_number?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
}>;


export type UpdateWidgetsPositionMutation = { __typename?: 'Mutation', update_settings_widgets_item?: { __typename?: 'settings_widgets', id: string } | null };

export type UpdateWidgetsSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  is_visible: Scalars['Boolean']['input'];
}>;


export type UpdateWidgetsSettingsMutation = { __typename?: 'Mutation', update_settings_widgets_item?: { __typename?: 'settings_widgets', id: string } | null };

export type CreateSelfAssessmentAnswerMutationVariables = Exact<{
  sefID: Scalars['Int']['input'];
  answer: Scalars['Int']['input'];
  note: Scalars['String']['input'];
}>;


export type CreateSelfAssessmentAnswerMutation = { __typename?: 'Mutation', create_t_se_antworten_item?: { __typename?: 't_se_antworten', sea_id: string, sef_id: number, antwort: number, anmerkung: string, edit_am?: any | null, m2s_id?: { __typename?: 't_se_ma2seb', m2s_id: string } | null } | null };

export type CreateSelfAssessmentForPersonMutationVariables = Exact<{
  data: Create_T_Se_Ma2seb_Input;
}>;


export type CreateSelfAssessmentForPersonMutation = { __typename?: 'Mutation', create_t_se_ma2seb_item?: { __typename?: 't_se_ma2seb', m2s_id: string, ma_id: number, seb_id: number, selbst_beantwortet: boolean, new_von: number, new_am?: any | null, se_status_id?: { __typename?: 't_se_status', se_status_id: string, status_name: string } | null } | null };

export type GetFunctionByIdQueryVariables = Exact<{
  functionID?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
}>;


export type GetFunctionByIdQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'settings', id: string, functions?: Array<{ __typename?: 'functions_settings', id: string, is_pinned?: boolean | null, order_number?: any | null, function_type_id?: { __typename?: 'function_types', type: string } | null, functions_id?: { __typename?: 'functions', id: string, name: string, icon?: { __typename?: 'directus_files', filename_disk?: string | null } | null } | null } | null> | null }> };

export type GetSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingsQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'settings', id: string, functions?: Array<{ __typename?: 'functions_settings', id: string, is_pinned?: boolean | null, order_number?: any | null, function_type_id?: { __typename?: 'function_types', type: string } | null, functions_id?: { __typename?: 'functions', id: string, name: string, icon?: { __typename?: 'directus_files', filename_disk?: string | null } | null } | null } | null> | null }> };

export type GetFunctionTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFunctionTypesQuery = { __typename?: 'Query', function_types: Array<{ __typename?: 'function_types', id: string, type: string }> };

export type GetSelfAssessmentAnswerQueryVariables = Exact<{
  m2sID?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  sefID?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
}>;


export type GetSelfAssessmentAnswerQuery = { __typename?: 'Query', t_se_antworten: Array<{ __typename?: 't_se_antworten', sea_id: string, sef_id: number, antwort: number, anmerkung: string, edit_am?: any | null, m2s_id?: { __typename?: 't_se_ma2seb', m2s_id: string } | null }> };

export type GetSelfAssessmentAnswersQueryVariables = Exact<{
  m2sID?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
}>;


export type GetSelfAssessmentAnswersQuery = { __typename?: 'Query', t_se_antworten: Array<{ __typename?: 't_se_antworten', sea_id: string, sef_id: number, antwort: number, anmerkung: string, edit_am?: any | null, m2s_id?: { __typename?: 't_se_ma2seb', m2s_id: string } | null }> };

export type GetSelfAssessmentAnswersByPersonQueryVariables = Exact<{
  maID: Scalars['GraphQLStringOrFloat']['input'];
}>;


export type GetSelfAssessmentAnswersByPersonQuery = { __typename?: 'Query', t_se_antworten: Array<{ __typename?: 't_se_antworten', sea_id: string, sef_id: number, antwort: number, anmerkung: string, edit_am?: any | null, m2s_id?: { __typename?: 't_se_ma2seb', m2s_id: string } | null }> };

export type GetSelfAssessmentPersonQuestionnaireQueryVariables = Exact<{
  m2sID?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
}>;


export type GetSelfAssessmentPersonQuestionnaireQuery = { __typename?: 'Query', t_se_ma2seb: Array<{ __typename?: 't_se_ma2seb', m2s_id: string, ma_id: number, seb_id: number, selbst_beantwortet: boolean, new_von: number, new_am?: any | null, se_status_id?: { __typename?: 't_se_status', se_status_id: string, status_name: string } | null }> };

export type GetSelfAssessmentQuestionnaireQueryVariables = Exact<{
  sebID?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
}>;


export type GetSelfAssessmentQuestionnaireQuery = { __typename?: 'Query', lt_se_bogen: Array<{ __typename?: 'lt_se_bogen', seb_id: string, seb_name: string, fragen?: Array<{ __typename?: 't_se_bogen2frage', sef_id?: { __typename?: 'lt_se_fragen', sef_id: string, sef_name: string } | null } | null> | null }> };

export type GetSelfAssessmentQuestionnairesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSelfAssessmentQuestionnairesQuery = { __typename?: 'Query', lt_se_bogen: Array<{ __typename?: 'lt_se_bogen', seb_id: string, seb_name: string, fragen?: Array<{ __typename?: 't_se_bogen2frage', sef_id?: { __typename?: 'lt_se_fragen', sef_id: string, sef_name: string } | null } | null> | null }> };

export type GetSelfAssessmentQuestionnairesByPersonQueryVariables = Exact<{
  maID: Scalars['GraphQLStringOrFloat']['input'];
}>;


export type GetSelfAssessmentQuestionnairesByPersonQuery = { __typename?: 'Query', t_se_ma2seb: Array<{ __typename?: 't_se_ma2seb', m2s_id: string, ma_id: number, seb_id: number, selbst_beantwortet: boolean, new_von: number, new_am?: any | null, se_status_id?: { __typename?: 't_se_status', se_status_id: string, status_name: string } | null }> };

export type GetSelfAssessmentQuestionsQueryVariables = Exact<{
  bogenId?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
}>;


export type GetSelfAssessmentQuestionsQuery = { __typename?: 'Query', lt_se_fragen: Array<{ __typename?: 'lt_se_fragen', sef_id: string, sef_name: string }> };

export type UpdateFunctionOrderNumberMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  order_number?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
}>;


export type UpdateFunctionOrderNumberMutation = { __typename?: 'Mutation', update_functions_settings_item?: { __typename?: 'functions_settings', order_number?: any | null } | null };

export type UpdateFunctionTypeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  function_type?: InputMaybe<Update_Function_Types_Input>;
}>;


export type UpdateFunctionTypeMutation = { __typename?: 'Mutation', update_functions_settings_item?: { __typename?: 'functions_settings', function_type_id?: { __typename?: 'function_types', type: string } | null } | null };

export type UpdatePinnedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  is_pinned: Scalars['Boolean']['input'];
}>;


export type UpdatePinnedMutation = { __typename?: 'Mutation', update_functions_settings_item?: { __typename?: 'functions_settings', is_pinned?: boolean | null } | null };

export type UpdateSelfAssessmentAnswerMutationVariables = Exact<{
  seaID: Scalars['ID']['input'];
  data: Update_T_Se_Antworten_Input;
}>;


export type UpdateSelfAssessmentAnswerMutation = { __typename?: 'Mutation', update_t_se_antworten_item?: { __typename?: 't_se_antworten', sea_id: string, sef_id: number, antwort: number, anmerkung: string, edit_am?: any | null, m2s_id?: { __typename?: 't_se_ma2seb', m2s_id: string } | null } | null };

export type UpdateSelfAssessmentForPersonMutationVariables = Exact<{
  m2sID: Scalars['ID']['input'];
  data: Update_T_Se_Ma2seb_Input;
}>;


export type UpdateSelfAssessmentForPersonMutation = { __typename?: 'Mutation', update_t_se_ma2seb_item?: { __typename?: 't_se_ma2seb', m2s_id: string, ma_id: number, seb_id: number, selbst_beantwortet: boolean, new_von: number, new_am?: any | null, se_status_id?: { __typename?: 't_se_status', se_status_id: string, status_name: string } | null } | null };

export type GetAppParametersQueryVariables = Exact<{
  parameterNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type GetAppParametersQuery = { __typename?: 'Query', parameters: Array<{ __typename?: 'parameters', id: string, name?: string | null, value?: string | null }> };

export type GetReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReportsQuery = { __typename?: 'Query', reports: Array<{ __typename?: 'reports', id: string, file_name: string, name: string, is_visible?: boolean | null }> };

export type GetUserSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserSettingsQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'settings', language_code?: string | null, selected_departments?: string | null, favorite_persons?: string | null, id: string }> };

export type UpdateUserFavoritePersonsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  favorite_persons?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserFavoritePersonsMutation = { __typename?: 'Mutation', update_settings_item?: { __typename?: 'settings', favorite_persons?: string | null } | null };

export type UpdateUserSelectedDepartmentsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  selected_departments?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserSelectedDepartmentsMutation = { __typename?: 'Mutation', update_settings_item?: { __typename?: 'settings', id: string } | null };

export type UpdateUserSettingsLanguageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  language_code?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserSettingsLanguageMutation = { __typename?: 'Mutation', update_settings_item?: { __typename?: 'settings', id: string } | null };


export const GetAdministrationSettingsDocument = gql`
    query getAdministrationSettings {
  settings {
    administration_functions {
      id
      is_pinned
      order_number
      function_type_id {
        type
      }
      administration_functions_id {
        icon {
          filename_disk
        }
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetAdministrationSettingsQuery__
 *
 * To run a query within a React component, call `useGetAdministrationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdministrationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdministrationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdministrationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdministrationSettingsQuery, GetAdministrationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdministrationSettingsQuery, GetAdministrationSettingsQueryVariables>(GetAdministrationSettingsDocument, options);
      }
export function useGetAdministrationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdministrationSettingsQuery, GetAdministrationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdministrationSettingsQuery, GetAdministrationSettingsQueryVariables>(GetAdministrationSettingsDocument, options);
        }
export function useGetAdministrationSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdministrationSettingsQuery, GetAdministrationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdministrationSettingsQuery, GetAdministrationSettingsQueryVariables>(GetAdministrationSettingsDocument, options);
        }
export type GetAdministrationSettingsQueryHookResult = ReturnType<typeof useGetAdministrationSettingsQuery>;
export type GetAdministrationSettingsLazyQueryHookResult = ReturnType<typeof useGetAdministrationSettingsLazyQuery>;
export type GetAdministrationSettingsSuspenseQueryHookResult = ReturnType<typeof useGetAdministrationSettingsSuspenseQuery>;
export type GetAdministrationSettingsQueryResult = Apollo.QueryResult<GetAdministrationSettingsQuery, GetAdministrationSettingsQueryVariables>;
export const UpdateAdministrationFunctionTypeDocument = gql`
    mutation updateAdministrationFunctionType($id: ID!, $function_type: update_administration_function_types_input) {
  update_administration_functions_settings_item(
    id: $id
    data: {function_type_id: $function_type}
  ) {
    function_type_id {
      type
    }
  }
}
    `;
export type UpdateAdministrationFunctionTypeMutationFn = Apollo.MutationFunction<UpdateAdministrationFunctionTypeMutation, UpdateAdministrationFunctionTypeMutationVariables>;

/**
 * __useUpdateAdministrationFunctionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAdministrationFunctionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdministrationFunctionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdministrationFunctionTypeMutation, { data, loading, error }] = useUpdateAdministrationFunctionTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      function_type: // value for 'function_type'
 *   },
 * });
 */
export function useUpdateAdministrationFunctionTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdministrationFunctionTypeMutation, UpdateAdministrationFunctionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdministrationFunctionTypeMutation, UpdateAdministrationFunctionTypeMutationVariables>(UpdateAdministrationFunctionTypeDocument, options);
      }
export type UpdateAdministrationFunctionTypeMutationHookResult = ReturnType<typeof useUpdateAdministrationFunctionTypeMutation>;
export type UpdateAdministrationFunctionTypeMutationResult = Apollo.MutationResult<UpdateAdministrationFunctionTypeMutation>;
export type UpdateAdministrationFunctionTypeMutationOptions = Apollo.BaseMutationOptions<UpdateAdministrationFunctionTypeMutation, UpdateAdministrationFunctionTypeMutationVariables>;
export const UpdateAdministrationPinnedDocument = gql`
    mutation updateAdministrationPinned($id: ID!, $is_pinned: Boolean!) {
  update_administration_functions_settings_item(
    id: $id
    data: {is_pinned: $is_pinned}
  ) {
    is_pinned
  }
}
    `;
export type UpdateAdministrationPinnedMutationFn = Apollo.MutationFunction<UpdateAdministrationPinnedMutation, UpdateAdministrationPinnedMutationVariables>;

/**
 * __useUpdateAdministrationPinnedMutation__
 *
 * To run a mutation, you first call `useUpdateAdministrationPinnedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdministrationPinnedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdministrationPinnedMutation, { data, loading, error }] = useUpdateAdministrationPinnedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      is_pinned: // value for 'is_pinned'
 *   },
 * });
 */
export function useUpdateAdministrationPinnedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdministrationPinnedMutation, UpdateAdministrationPinnedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdministrationPinnedMutation, UpdateAdministrationPinnedMutationVariables>(UpdateAdministrationPinnedDocument, options);
      }
export type UpdateAdministrationPinnedMutationHookResult = ReturnType<typeof useUpdateAdministrationPinnedMutation>;
export type UpdateAdministrationPinnedMutationResult = Apollo.MutationResult<UpdateAdministrationPinnedMutation>;
export type UpdateAdministrationPinnedMutationOptions = Apollo.BaseMutationOptions<UpdateAdministrationPinnedMutation, UpdateAdministrationPinnedMutationVariables>;
export const UpdateAdministrationFunctionOrderNumberDocument = gql`
    mutation updateAdministrationFunctionOrderNumber($id: ID!, $order_number: Int) {
  update_administration_functions_settings_item(
    id: $id
    data: {order_number: $order_number}
  ) {
    order_number
  }
}
    `;
export type UpdateAdministrationFunctionOrderNumberMutationFn = Apollo.MutationFunction<UpdateAdministrationFunctionOrderNumberMutation, UpdateAdministrationFunctionOrderNumberMutationVariables>;

/**
 * __useUpdateAdministrationFunctionOrderNumberMutation__
 *
 * To run a mutation, you first call `useUpdateAdministrationFunctionOrderNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdministrationFunctionOrderNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdministrationFunctionOrderNumberMutation, { data, loading, error }] = useUpdateAdministrationFunctionOrderNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order_number: // value for 'order_number'
 *   },
 * });
 */
export function useUpdateAdministrationFunctionOrderNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdministrationFunctionOrderNumberMutation, UpdateAdministrationFunctionOrderNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdministrationFunctionOrderNumberMutation, UpdateAdministrationFunctionOrderNumberMutationVariables>(UpdateAdministrationFunctionOrderNumberDocument, options);
      }
export type UpdateAdministrationFunctionOrderNumberMutationHookResult = ReturnType<typeof useUpdateAdministrationFunctionOrderNumberMutation>;
export type UpdateAdministrationFunctionOrderNumberMutationResult = Apollo.MutationResult<UpdateAdministrationFunctionOrderNumberMutation>;
export type UpdateAdministrationFunctionOrderNumberMutationOptions = Apollo.BaseMutationOptions<UpdateAdministrationFunctionOrderNumberMutation, UpdateAdministrationFunctionOrderNumberMutationVariables>;
export const GetWidgetsSettingsDocument = gql`
    query getWidgetsSettings {
  settings {
    widgets {
      id
      is_visible
      column_id
      order_number
      widgets_id {
        name
        icon {
          filename_disk
        }
      }
    }
  }
}
    `;

/**
 * __useGetWidgetsSettingsQuery__
 *
 * To run a query within a React component, call `useGetWidgetsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWidgetsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWidgetsSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWidgetsSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetWidgetsSettingsQuery, GetWidgetsSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWidgetsSettingsQuery, GetWidgetsSettingsQueryVariables>(GetWidgetsSettingsDocument, options);
      }
export function useGetWidgetsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWidgetsSettingsQuery, GetWidgetsSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWidgetsSettingsQuery, GetWidgetsSettingsQueryVariables>(GetWidgetsSettingsDocument, options);
        }
export function useGetWidgetsSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWidgetsSettingsQuery, GetWidgetsSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWidgetsSettingsQuery, GetWidgetsSettingsQueryVariables>(GetWidgetsSettingsDocument, options);
        }
export type GetWidgetsSettingsQueryHookResult = ReturnType<typeof useGetWidgetsSettingsQuery>;
export type GetWidgetsSettingsLazyQueryHookResult = ReturnType<typeof useGetWidgetsSettingsLazyQuery>;
export type GetWidgetsSettingsSuspenseQueryHookResult = ReturnType<typeof useGetWidgetsSettingsSuspenseQuery>;
export type GetWidgetsSettingsQueryResult = Apollo.QueryResult<GetWidgetsSettingsQuery, GetWidgetsSettingsQueryVariables>;
export const UpdateWidgetsPositionDocument = gql`
    mutation updateWidgetsPosition($id: ID!, $column_id: String, $order_number: GraphQLBigInt) {
  update_settings_widgets_item(
    id: $id
    data: {column_id: $column_id, order_number: $order_number}
  ) {
    id
  }
}
    `;
export type UpdateWidgetsPositionMutationFn = Apollo.MutationFunction<UpdateWidgetsPositionMutation, UpdateWidgetsPositionMutationVariables>;

/**
 * __useUpdateWidgetsPositionMutation__
 *
 * To run a mutation, you first call `useUpdateWidgetsPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWidgetsPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWidgetsPositionMutation, { data, loading, error }] = useUpdateWidgetsPositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      column_id: // value for 'column_id'
 *      order_number: // value for 'order_number'
 *   },
 * });
 */
export function useUpdateWidgetsPositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWidgetsPositionMutation, UpdateWidgetsPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWidgetsPositionMutation, UpdateWidgetsPositionMutationVariables>(UpdateWidgetsPositionDocument, options);
      }
export type UpdateWidgetsPositionMutationHookResult = ReturnType<typeof useUpdateWidgetsPositionMutation>;
export type UpdateWidgetsPositionMutationResult = Apollo.MutationResult<UpdateWidgetsPositionMutation>;
export type UpdateWidgetsPositionMutationOptions = Apollo.BaseMutationOptions<UpdateWidgetsPositionMutation, UpdateWidgetsPositionMutationVariables>;
export const UpdateWidgetsSettingsDocument = gql`
    mutation updateWidgetsSettings($id: ID!, $is_visible: Boolean!) {
  update_settings_widgets_item(id: $id, data: {is_visible: $is_visible}) {
    id
  }
}
    `;
export type UpdateWidgetsSettingsMutationFn = Apollo.MutationFunction<UpdateWidgetsSettingsMutation, UpdateWidgetsSettingsMutationVariables>;

/**
 * __useUpdateWidgetsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateWidgetsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWidgetsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWidgetsSettingsMutation, { data, loading, error }] = useUpdateWidgetsSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      is_visible: // value for 'is_visible'
 *   },
 * });
 */
export function useUpdateWidgetsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWidgetsSettingsMutation, UpdateWidgetsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWidgetsSettingsMutation, UpdateWidgetsSettingsMutationVariables>(UpdateWidgetsSettingsDocument, options);
      }
export type UpdateWidgetsSettingsMutationHookResult = ReturnType<typeof useUpdateWidgetsSettingsMutation>;
export type UpdateWidgetsSettingsMutationResult = Apollo.MutationResult<UpdateWidgetsSettingsMutation>;
export type UpdateWidgetsSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateWidgetsSettingsMutation, UpdateWidgetsSettingsMutationVariables>;
export const CreateSelfAssessmentAnswerDocument = gql`
    mutation createSelfAssessmentAnswer($sefID: Int!, $answer: Int!, $note: String!) {
  create_t_se_antworten_item(
    data: {m2s_id: null, sef_id: $sefID, antwort: $answer, anmerkung: $note}
  ) {
    sea_id
    m2s_id {
      m2s_id
    }
    sef_id
    antwort
    anmerkung
    edit_am
  }
}
    `;
export type CreateSelfAssessmentAnswerMutationFn = Apollo.MutationFunction<CreateSelfAssessmentAnswerMutation, CreateSelfAssessmentAnswerMutationVariables>;

/**
 * __useCreateSelfAssessmentAnswerMutation__
 *
 * To run a mutation, you first call `useCreateSelfAssessmentAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSelfAssessmentAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSelfAssessmentAnswerMutation, { data, loading, error }] = useCreateSelfAssessmentAnswerMutation({
 *   variables: {
 *      sefID: // value for 'sefID'
 *      answer: // value for 'answer'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useCreateSelfAssessmentAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateSelfAssessmentAnswerMutation, CreateSelfAssessmentAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSelfAssessmentAnswerMutation, CreateSelfAssessmentAnswerMutationVariables>(CreateSelfAssessmentAnswerDocument, options);
      }
export type CreateSelfAssessmentAnswerMutationHookResult = ReturnType<typeof useCreateSelfAssessmentAnswerMutation>;
export type CreateSelfAssessmentAnswerMutationResult = Apollo.MutationResult<CreateSelfAssessmentAnswerMutation>;
export type CreateSelfAssessmentAnswerMutationOptions = Apollo.BaseMutationOptions<CreateSelfAssessmentAnswerMutation, CreateSelfAssessmentAnswerMutationVariables>;
export const CreateSelfAssessmentForPersonDocument = gql`
    mutation createSelfAssessmentForPerson($data: create_t_se_ma2seb_input!) {
  create_t_se_ma2seb_item(data: $data) {
    m2s_id
    ma_id
    seb_id
    selbst_beantwortet
    new_von
    new_am
    se_status_id {
      se_status_id
      status_name
    }
  }
}
    `;
export type CreateSelfAssessmentForPersonMutationFn = Apollo.MutationFunction<CreateSelfAssessmentForPersonMutation, CreateSelfAssessmentForPersonMutationVariables>;

/**
 * __useCreateSelfAssessmentForPersonMutation__
 *
 * To run a mutation, you first call `useCreateSelfAssessmentForPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSelfAssessmentForPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSelfAssessmentForPersonMutation, { data, loading, error }] = useCreateSelfAssessmentForPersonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSelfAssessmentForPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreateSelfAssessmentForPersonMutation, CreateSelfAssessmentForPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSelfAssessmentForPersonMutation, CreateSelfAssessmentForPersonMutationVariables>(CreateSelfAssessmentForPersonDocument, options);
      }
export type CreateSelfAssessmentForPersonMutationHookResult = ReturnType<typeof useCreateSelfAssessmentForPersonMutation>;
export type CreateSelfAssessmentForPersonMutationResult = Apollo.MutationResult<CreateSelfAssessmentForPersonMutation>;
export type CreateSelfAssessmentForPersonMutationOptions = Apollo.BaseMutationOptions<CreateSelfAssessmentForPersonMutation, CreateSelfAssessmentForPersonMutationVariables>;
export const GetFunctionByIdDocument = gql`
    query getFunctionByID($functionID: GraphQLStringOrFloat) {
  settings {
    id
    functions(
      sort: ["functions_id.name"]
      filter: {functions_id: {id: {_eq: $functionID}}}
    ) {
      id
      is_pinned
      order_number
      function_type_id {
        type
      }
      functions_id {
        id
        name
        icon {
          filename_disk
        }
      }
    }
  }
}
    `;

/**
 * __useGetFunctionByIdQuery__
 *
 * To run a query within a React component, call `useGetFunctionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFunctionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFunctionByIdQuery({
 *   variables: {
 *      functionID: // value for 'functionID'
 *   },
 * });
 */
export function useGetFunctionByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetFunctionByIdQuery, GetFunctionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFunctionByIdQuery, GetFunctionByIdQueryVariables>(GetFunctionByIdDocument, options);
      }
export function useGetFunctionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFunctionByIdQuery, GetFunctionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFunctionByIdQuery, GetFunctionByIdQueryVariables>(GetFunctionByIdDocument, options);
        }
export function useGetFunctionByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFunctionByIdQuery, GetFunctionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFunctionByIdQuery, GetFunctionByIdQueryVariables>(GetFunctionByIdDocument, options);
        }
export type GetFunctionByIdQueryHookResult = ReturnType<typeof useGetFunctionByIdQuery>;
export type GetFunctionByIdLazyQueryHookResult = ReturnType<typeof useGetFunctionByIdLazyQuery>;
export type GetFunctionByIdSuspenseQueryHookResult = ReturnType<typeof useGetFunctionByIdSuspenseQuery>;
export type GetFunctionByIdQueryResult = Apollo.QueryResult<GetFunctionByIdQuery, GetFunctionByIdQueryVariables>;
export const GetSettingsDocument = gql`
    query getSettings {
  settings {
    id
    functions(sort: ["functions_id.name"]) {
      id
      is_pinned
      order_number
      function_type_id {
        type
      }
      functions_id {
        id
        name
        icon {
          filename_disk
        }
      }
    }
  }
}
    `;

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
      }
export function useGetSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
        }
export function useGetSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
        }
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>;
export type GetSettingsLazyQueryHookResult = ReturnType<typeof useGetSettingsLazyQuery>;
export type GetSettingsSuspenseQueryHookResult = ReturnType<typeof useGetSettingsSuspenseQuery>;
export type GetSettingsQueryResult = Apollo.QueryResult<GetSettingsQuery, GetSettingsQueryVariables>;
export const GetFunctionTypesDocument = gql`
    query getFunctionTypes {
  function_types {
    id
    type
  }
}
    `;

/**
 * __useGetFunctionTypesQuery__
 *
 * To run a query within a React component, call `useGetFunctionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFunctionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFunctionTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFunctionTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetFunctionTypesQuery, GetFunctionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFunctionTypesQuery, GetFunctionTypesQueryVariables>(GetFunctionTypesDocument, options);
      }
export function useGetFunctionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFunctionTypesQuery, GetFunctionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFunctionTypesQuery, GetFunctionTypesQueryVariables>(GetFunctionTypesDocument, options);
        }
export function useGetFunctionTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFunctionTypesQuery, GetFunctionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFunctionTypesQuery, GetFunctionTypesQueryVariables>(GetFunctionTypesDocument, options);
        }
export type GetFunctionTypesQueryHookResult = ReturnType<typeof useGetFunctionTypesQuery>;
export type GetFunctionTypesLazyQueryHookResult = ReturnType<typeof useGetFunctionTypesLazyQuery>;
export type GetFunctionTypesSuspenseQueryHookResult = ReturnType<typeof useGetFunctionTypesSuspenseQuery>;
export type GetFunctionTypesQueryResult = Apollo.QueryResult<GetFunctionTypesQuery, GetFunctionTypesQueryVariables>;
export const GetSelfAssessmentAnswerDocument = gql`
    query getSelfAssessmentAnswer($m2sID: GraphQLStringOrFloat, $sefID: GraphQLStringOrFloat) {
  t_se_antworten(filter: {m2s_id: {m2s_id: {_eq: $m2sID}}, sef_id: {_eq: $sefID}}) {
    sea_id
    m2s_id {
      m2s_id
    }
    sef_id
    antwort
    anmerkung
    edit_am
  }
}
    `;

/**
 * __useGetSelfAssessmentAnswerQuery__
 *
 * To run a query within a React component, call `useGetSelfAssessmentAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfAssessmentAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfAssessmentAnswerQuery({
 *   variables: {
 *      m2sID: // value for 'm2sID'
 *      sefID: // value for 'sefID'
 *   },
 * });
 */
export function useGetSelfAssessmentAnswerQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfAssessmentAnswerQuery, GetSelfAssessmentAnswerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfAssessmentAnswerQuery, GetSelfAssessmentAnswerQueryVariables>(GetSelfAssessmentAnswerDocument, options);
      }
export function useGetSelfAssessmentAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfAssessmentAnswerQuery, GetSelfAssessmentAnswerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfAssessmentAnswerQuery, GetSelfAssessmentAnswerQueryVariables>(GetSelfAssessmentAnswerDocument, options);
        }
export function useGetSelfAssessmentAnswerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSelfAssessmentAnswerQuery, GetSelfAssessmentAnswerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfAssessmentAnswerQuery, GetSelfAssessmentAnswerQueryVariables>(GetSelfAssessmentAnswerDocument, options);
        }
export type GetSelfAssessmentAnswerQueryHookResult = ReturnType<typeof useGetSelfAssessmentAnswerQuery>;
export type GetSelfAssessmentAnswerLazyQueryHookResult = ReturnType<typeof useGetSelfAssessmentAnswerLazyQuery>;
export type GetSelfAssessmentAnswerSuspenseQueryHookResult = ReturnType<typeof useGetSelfAssessmentAnswerSuspenseQuery>;
export type GetSelfAssessmentAnswerQueryResult = Apollo.QueryResult<GetSelfAssessmentAnswerQuery, GetSelfAssessmentAnswerQueryVariables>;
export const GetSelfAssessmentAnswersDocument = gql`
    query getSelfAssessmentAnswers($m2sID: GraphQLStringOrFloat) {
  t_se_antworten(filter: {m2s_id: {m2s_id: {_eq: $m2sID}}}) {
    sea_id
    m2s_id {
      m2s_id
    }
    sef_id
    antwort
    anmerkung
    edit_am
  }
}
    `;

/**
 * __useGetSelfAssessmentAnswersQuery__
 *
 * To run a query within a React component, call `useGetSelfAssessmentAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfAssessmentAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfAssessmentAnswersQuery({
 *   variables: {
 *      m2sID: // value for 'm2sID'
 *   },
 * });
 */
export function useGetSelfAssessmentAnswersQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfAssessmentAnswersQuery, GetSelfAssessmentAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfAssessmentAnswersQuery, GetSelfAssessmentAnswersQueryVariables>(GetSelfAssessmentAnswersDocument, options);
      }
export function useGetSelfAssessmentAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfAssessmentAnswersQuery, GetSelfAssessmentAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfAssessmentAnswersQuery, GetSelfAssessmentAnswersQueryVariables>(GetSelfAssessmentAnswersDocument, options);
        }
export function useGetSelfAssessmentAnswersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSelfAssessmentAnswersQuery, GetSelfAssessmentAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfAssessmentAnswersQuery, GetSelfAssessmentAnswersQueryVariables>(GetSelfAssessmentAnswersDocument, options);
        }
export type GetSelfAssessmentAnswersQueryHookResult = ReturnType<typeof useGetSelfAssessmentAnswersQuery>;
export type GetSelfAssessmentAnswersLazyQueryHookResult = ReturnType<typeof useGetSelfAssessmentAnswersLazyQuery>;
export type GetSelfAssessmentAnswersSuspenseQueryHookResult = ReturnType<typeof useGetSelfAssessmentAnswersSuspenseQuery>;
export type GetSelfAssessmentAnswersQueryResult = Apollo.QueryResult<GetSelfAssessmentAnswersQuery, GetSelfAssessmentAnswersQueryVariables>;
export const GetSelfAssessmentAnswersByPersonDocument = gql`
    query getSelfAssessmentAnswersByPerson($maID: GraphQLStringOrFloat!) {
  t_se_antworten(filter: {m2s_id: {ma_id: {_eq: $maID}}}) {
    sea_id
    m2s_id {
      m2s_id
    }
    sef_id
    antwort
    anmerkung
    edit_am
  }
}
    `;

/**
 * __useGetSelfAssessmentAnswersByPersonQuery__
 *
 * To run a query within a React component, call `useGetSelfAssessmentAnswersByPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfAssessmentAnswersByPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfAssessmentAnswersByPersonQuery({
 *   variables: {
 *      maID: // value for 'maID'
 *   },
 * });
 */
export function useGetSelfAssessmentAnswersByPersonQuery(baseOptions: Apollo.QueryHookOptions<GetSelfAssessmentAnswersByPersonQuery, GetSelfAssessmentAnswersByPersonQueryVariables> & ({ variables: GetSelfAssessmentAnswersByPersonQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfAssessmentAnswersByPersonQuery, GetSelfAssessmentAnswersByPersonQueryVariables>(GetSelfAssessmentAnswersByPersonDocument, options);
      }
export function useGetSelfAssessmentAnswersByPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfAssessmentAnswersByPersonQuery, GetSelfAssessmentAnswersByPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfAssessmentAnswersByPersonQuery, GetSelfAssessmentAnswersByPersonQueryVariables>(GetSelfAssessmentAnswersByPersonDocument, options);
        }
export function useGetSelfAssessmentAnswersByPersonSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSelfAssessmentAnswersByPersonQuery, GetSelfAssessmentAnswersByPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfAssessmentAnswersByPersonQuery, GetSelfAssessmentAnswersByPersonQueryVariables>(GetSelfAssessmentAnswersByPersonDocument, options);
        }
export type GetSelfAssessmentAnswersByPersonQueryHookResult = ReturnType<typeof useGetSelfAssessmentAnswersByPersonQuery>;
export type GetSelfAssessmentAnswersByPersonLazyQueryHookResult = ReturnType<typeof useGetSelfAssessmentAnswersByPersonLazyQuery>;
export type GetSelfAssessmentAnswersByPersonSuspenseQueryHookResult = ReturnType<typeof useGetSelfAssessmentAnswersByPersonSuspenseQuery>;
export type GetSelfAssessmentAnswersByPersonQueryResult = Apollo.QueryResult<GetSelfAssessmentAnswersByPersonQuery, GetSelfAssessmentAnswersByPersonQueryVariables>;
export const GetSelfAssessmentPersonQuestionnaireDocument = gql`
    query getSelfAssessmentPersonQuestionnaire($m2sID: GraphQLStringOrFloat) {
  t_se_ma2seb(filter: {m2s_id: {_eq: $m2sID}}) {
    m2s_id
    ma_id
    seb_id
    selbst_beantwortet
    new_von
    new_am
    se_status_id {
      se_status_id
      status_name
    }
  }
}
    `;

/**
 * __useGetSelfAssessmentPersonQuestionnaireQuery__
 *
 * To run a query within a React component, call `useGetSelfAssessmentPersonQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfAssessmentPersonQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfAssessmentPersonQuestionnaireQuery({
 *   variables: {
 *      m2sID: // value for 'm2sID'
 *   },
 * });
 */
export function useGetSelfAssessmentPersonQuestionnaireQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfAssessmentPersonQuestionnaireQuery, GetSelfAssessmentPersonQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfAssessmentPersonQuestionnaireQuery, GetSelfAssessmentPersonQuestionnaireQueryVariables>(GetSelfAssessmentPersonQuestionnaireDocument, options);
      }
export function useGetSelfAssessmentPersonQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfAssessmentPersonQuestionnaireQuery, GetSelfAssessmentPersonQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfAssessmentPersonQuestionnaireQuery, GetSelfAssessmentPersonQuestionnaireQueryVariables>(GetSelfAssessmentPersonQuestionnaireDocument, options);
        }
export function useGetSelfAssessmentPersonQuestionnaireSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSelfAssessmentPersonQuestionnaireQuery, GetSelfAssessmentPersonQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfAssessmentPersonQuestionnaireQuery, GetSelfAssessmentPersonQuestionnaireQueryVariables>(GetSelfAssessmentPersonQuestionnaireDocument, options);
        }
export type GetSelfAssessmentPersonQuestionnaireQueryHookResult = ReturnType<typeof useGetSelfAssessmentPersonQuestionnaireQuery>;
export type GetSelfAssessmentPersonQuestionnaireLazyQueryHookResult = ReturnType<typeof useGetSelfAssessmentPersonQuestionnaireLazyQuery>;
export type GetSelfAssessmentPersonQuestionnaireSuspenseQueryHookResult = ReturnType<typeof useGetSelfAssessmentPersonQuestionnaireSuspenseQuery>;
export type GetSelfAssessmentPersonQuestionnaireQueryResult = Apollo.QueryResult<GetSelfAssessmentPersonQuestionnaireQuery, GetSelfAssessmentPersonQuestionnaireQueryVariables>;
export const GetSelfAssessmentQuestionnaireDocument = gql`
    query getSelfAssessmentQuestionnaire($sebID: GraphQLStringOrFloat) {
  lt_se_bogen(filter: {seb_id: {_eq: $sebID}}) {
    seb_id
    seb_name
    fragen(sort: "sef_order_id") {
      sef_id {
        sef_id
        sef_name
      }
    }
  }
}
    `;

/**
 * __useGetSelfAssessmentQuestionnaireQuery__
 *
 * To run a query within a React component, call `useGetSelfAssessmentQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfAssessmentQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfAssessmentQuestionnaireQuery({
 *   variables: {
 *      sebID: // value for 'sebID'
 *   },
 * });
 */
export function useGetSelfAssessmentQuestionnaireQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfAssessmentQuestionnaireQuery, GetSelfAssessmentQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfAssessmentQuestionnaireQuery, GetSelfAssessmentQuestionnaireQueryVariables>(GetSelfAssessmentQuestionnaireDocument, options);
      }
export function useGetSelfAssessmentQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfAssessmentQuestionnaireQuery, GetSelfAssessmentQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfAssessmentQuestionnaireQuery, GetSelfAssessmentQuestionnaireQueryVariables>(GetSelfAssessmentQuestionnaireDocument, options);
        }
export function useGetSelfAssessmentQuestionnaireSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSelfAssessmentQuestionnaireQuery, GetSelfAssessmentQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfAssessmentQuestionnaireQuery, GetSelfAssessmentQuestionnaireQueryVariables>(GetSelfAssessmentQuestionnaireDocument, options);
        }
export type GetSelfAssessmentQuestionnaireQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionnaireQuery>;
export type GetSelfAssessmentQuestionnaireLazyQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionnaireLazyQuery>;
export type GetSelfAssessmentQuestionnaireSuspenseQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionnaireSuspenseQuery>;
export type GetSelfAssessmentQuestionnaireQueryResult = Apollo.QueryResult<GetSelfAssessmentQuestionnaireQuery, GetSelfAssessmentQuestionnaireQueryVariables>;
export const GetSelfAssessmentQuestionnairesDocument = gql`
    query getSelfAssessmentQuestionnaires {
  lt_se_bogen {
    seb_id
    seb_name
    fragen(sort: "sef_order_id") {
      sef_id {
        sef_id
        sef_name
      }
    }
  }
}
    `;

/**
 * __useGetSelfAssessmentQuestionnairesQuery__
 *
 * To run a query within a React component, call `useGetSelfAssessmentQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfAssessmentQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfAssessmentQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfAssessmentQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfAssessmentQuestionnairesQuery, GetSelfAssessmentQuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfAssessmentQuestionnairesQuery, GetSelfAssessmentQuestionnairesQueryVariables>(GetSelfAssessmentQuestionnairesDocument, options);
      }
export function useGetSelfAssessmentQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfAssessmentQuestionnairesQuery, GetSelfAssessmentQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfAssessmentQuestionnairesQuery, GetSelfAssessmentQuestionnairesQueryVariables>(GetSelfAssessmentQuestionnairesDocument, options);
        }
export function useGetSelfAssessmentQuestionnairesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSelfAssessmentQuestionnairesQuery, GetSelfAssessmentQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfAssessmentQuestionnairesQuery, GetSelfAssessmentQuestionnairesQueryVariables>(GetSelfAssessmentQuestionnairesDocument, options);
        }
export type GetSelfAssessmentQuestionnairesQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionnairesQuery>;
export type GetSelfAssessmentQuestionnairesLazyQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionnairesLazyQuery>;
export type GetSelfAssessmentQuestionnairesSuspenseQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionnairesSuspenseQuery>;
export type GetSelfAssessmentQuestionnairesQueryResult = Apollo.QueryResult<GetSelfAssessmentQuestionnairesQuery, GetSelfAssessmentQuestionnairesQueryVariables>;
export const GetSelfAssessmentQuestionnairesByPersonDocument = gql`
    query getSelfAssessmentQuestionnairesByPerson($maID: GraphQLStringOrFloat!) {
  t_se_ma2seb(filter: {ma_id: {_eq: $maID}}) {
    m2s_id
    ma_id
    seb_id
    selbst_beantwortet
    new_von
    new_am
    se_status_id {
      se_status_id
      status_name
    }
  }
}
    `;

/**
 * __useGetSelfAssessmentQuestionnairesByPersonQuery__
 *
 * To run a query within a React component, call `useGetSelfAssessmentQuestionnairesByPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfAssessmentQuestionnairesByPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfAssessmentQuestionnairesByPersonQuery({
 *   variables: {
 *      maID: // value for 'maID'
 *   },
 * });
 */
export function useGetSelfAssessmentQuestionnairesByPersonQuery(baseOptions: Apollo.QueryHookOptions<GetSelfAssessmentQuestionnairesByPersonQuery, GetSelfAssessmentQuestionnairesByPersonQueryVariables> & ({ variables: GetSelfAssessmentQuestionnairesByPersonQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfAssessmentQuestionnairesByPersonQuery, GetSelfAssessmentQuestionnairesByPersonQueryVariables>(GetSelfAssessmentQuestionnairesByPersonDocument, options);
      }
export function useGetSelfAssessmentQuestionnairesByPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfAssessmentQuestionnairesByPersonQuery, GetSelfAssessmentQuestionnairesByPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfAssessmentQuestionnairesByPersonQuery, GetSelfAssessmentQuestionnairesByPersonQueryVariables>(GetSelfAssessmentQuestionnairesByPersonDocument, options);
        }
export function useGetSelfAssessmentQuestionnairesByPersonSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSelfAssessmentQuestionnairesByPersonQuery, GetSelfAssessmentQuestionnairesByPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfAssessmentQuestionnairesByPersonQuery, GetSelfAssessmentQuestionnairesByPersonQueryVariables>(GetSelfAssessmentQuestionnairesByPersonDocument, options);
        }
export type GetSelfAssessmentQuestionnairesByPersonQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionnairesByPersonQuery>;
export type GetSelfAssessmentQuestionnairesByPersonLazyQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionnairesByPersonLazyQuery>;
export type GetSelfAssessmentQuestionnairesByPersonSuspenseQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionnairesByPersonSuspenseQuery>;
export type GetSelfAssessmentQuestionnairesByPersonQueryResult = Apollo.QueryResult<GetSelfAssessmentQuestionnairesByPersonQuery, GetSelfAssessmentQuestionnairesByPersonQueryVariables>;
export const GetSelfAssessmentQuestionsDocument = gql`
    query getSelfAssessmentQuestions($bogenId: GraphQLStringOrFloat) {
  lt_se_fragen(filter: {bogen: {seb_id: {seb_id: {_eq: $bogenId}}}}) {
    sef_id
    sef_name
  }
}
    `;

/**
 * __useGetSelfAssessmentQuestionsQuery__
 *
 * To run a query within a React component, call `useGetSelfAssessmentQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfAssessmentQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfAssessmentQuestionsQuery({
 *   variables: {
 *      bogenId: // value for 'bogenId'
 *   },
 * });
 */
export function useGetSelfAssessmentQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfAssessmentQuestionsQuery, GetSelfAssessmentQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfAssessmentQuestionsQuery, GetSelfAssessmentQuestionsQueryVariables>(GetSelfAssessmentQuestionsDocument, options);
      }
export function useGetSelfAssessmentQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfAssessmentQuestionsQuery, GetSelfAssessmentQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfAssessmentQuestionsQuery, GetSelfAssessmentQuestionsQueryVariables>(GetSelfAssessmentQuestionsDocument, options);
        }
export function useGetSelfAssessmentQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSelfAssessmentQuestionsQuery, GetSelfAssessmentQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfAssessmentQuestionsQuery, GetSelfAssessmentQuestionsQueryVariables>(GetSelfAssessmentQuestionsDocument, options);
        }
export type GetSelfAssessmentQuestionsQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionsQuery>;
export type GetSelfAssessmentQuestionsLazyQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionsLazyQuery>;
export type GetSelfAssessmentQuestionsSuspenseQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuestionsSuspenseQuery>;
export type GetSelfAssessmentQuestionsQueryResult = Apollo.QueryResult<GetSelfAssessmentQuestionsQuery, GetSelfAssessmentQuestionsQueryVariables>;
export const UpdateFunctionOrderNumberDocument = gql`
    mutation updateFunctionOrderNumber($id: ID!, $order_number: GraphQLBigInt) {
  update_functions_settings_item(id: $id, data: {order_number: $order_number}) {
    order_number
  }
}
    `;
export type UpdateFunctionOrderNumberMutationFn = Apollo.MutationFunction<UpdateFunctionOrderNumberMutation, UpdateFunctionOrderNumberMutationVariables>;

/**
 * __useUpdateFunctionOrderNumberMutation__
 *
 * To run a mutation, you first call `useUpdateFunctionOrderNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFunctionOrderNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFunctionOrderNumberMutation, { data, loading, error }] = useUpdateFunctionOrderNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order_number: // value for 'order_number'
 *   },
 * });
 */
export function useUpdateFunctionOrderNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFunctionOrderNumberMutation, UpdateFunctionOrderNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFunctionOrderNumberMutation, UpdateFunctionOrderNumberMutationVariables>(UpdateFunctionOrderNumberDocument, options);
      }
export type UpdateFunctionOrderNumberMutationHookResult = ReturnType<typeof useUpdateFunctionOrderNumberMutation>;
export type UpdateFunctionOrderNumberMutationResult = Apollo.MutationResult<UpdateFunctionOrderNumberMutation>;
export type UpdateFunctionOrderNumberMutationOptions = Apollo.BaseMutationOptions<UpdateFunctionOrderNumberMutation, UpdateFunctionOrderNumberMutationVariables>;
export const UpdateFunctionTypeDocument = gql`
    mutation updateFunctionType($id: ID!, $function_type: update_function_types_input) {
  update_functions_settings_item(
    id: $id
    data: {function_type_id: $function_type}
  ) {
    function_type_id {
      type
    }
  }
}
    `;
export type UpdateFunctionTypeMutationFn = Apollo.MutationFunction<UpdateFunctionTypeMutation, UpdateFunctionTypeMutationVariables>;

/**
 * __useUpdateFunctionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateFunctionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFunctionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFunctionTypeMutation, { data, loading, error }] = useUpdateFunctionTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      function_type: // value for 'function_type'
 *   },
 * });
 */
export function useUpdateFunctionTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFunctionTypeMutation, UpdateFunctionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFunctionTypeMutation, UpdateFunctionTypeMutationVariables>(UpdateFunctionTypeDocument, options);
      }
export type UpdateFunctionTypeMutationHookResult = ReturnType<typeof useUpdateFunctionTypeMutation>;
export type UpdateFunctionTypeMutationResult = Apollo.MutationResult<UpdateFunctionTypeMutation>;
export type UpdateFunctionTypeMutationOptions = Apollo.BaseMutationOptions<UpdateFunctionTypeMutation, UpdateFunctionTypeMutationVariables>;
export const UpdatePinnedDocument = gql`
    mutation updatePinned($id: ID!, $is_pinned: Boolean!) {
  update_functions_settings_item(id: $id, data: {is_pinned: $is_pinned}) {
    is_pinned
  }
}
    `;
export type UpdatePinnedMutationFn = Apollo.MutationFunction<UpdatePinnedMutation, UpdatePinnedMutationVariables>;

/**
 * __useUpdatePinnedMutation__
 *
 * To run a mutation, you first call `useUpdatePinnedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePinnedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePinnedMutation, { data, loading, error }] = useUpdatePinnedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      is_pinned: // value for 'is_pinned'
 *   },
 * });
 */
export function useUpdatePinnedMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePinnedMutation, UpdatePinnedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePinnedMutation, UpdatePinnedMutationVariables>(UpdatePinnedDocument, options);
      }
export type UpdatePinnedMutationHookResult = ReturnType<typeof useUpdatePinnedMutation>;
export type UpdatePinnedMutationResult = Apollo.MutationResult<UpdatePinnedMutation>;
export type UpdatePinnedMutationOptions = Apollo.BaseMutationOptions<UpdatePinnedMutation, UpdatePinnedMutationVariables>;
export const UpdateSelfAssessmentAnswerDocument = gql`
    mutation updateSelfAssessmentAnswer($seaID: ID!, $data: update_t_se_antworten_input!) {
  update_t_se_antworten_item(id: $seaID, data: $data) {
    sea_id
    m2s_id {
      m2s_id
    }
    sef_id
    antwort
    anmerkung
    edit_am
  }
}
    `;
export type UpdateSelfAssessmentAnswerMutationFn = Apollo.MutationFunction<UpdateSelfAssessmentAnswerMutation, UpdateSelfAssessmentAnswerMutationVariables>;

/**
 * __useUpdateSelfAssessmentAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateSelfAssessmentAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfAssessmentAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfAssessmentAnswerMutation, { data, loading, error }] = useUpdateSelfAssessmentAnswerMutation({
 *   variables: {
 *      seaID: // value for 'seaID'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSelfAssessmentAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelfAssessmentAnswerMutation, UpdateSelfAssessmentAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelfAssessmentAnswerMutation, UpdateSelfAssessmentAnswerMutationVariables>(UpdateSelfAssessmentAnswerDocument, options);
      }
export type UpdateSelfAssessmentAnswerMutationHookResult = ReturnType<typeof useUpdateSelfAssessmentAnswerMutation>;
export type UpdateSelfAssessmentAnswerMutationResult = Apollo.MutationResult<UpdateSelfAssessmentAnswerMutation>;
export type UpdateSelfAssessmentAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateSelfAssessmentAnswerMutation, UpdateSelfAssessmentAnswerMutationVariables>;
export const UpdateSelfAssessmentForPersonDocument = gql`
    mutation updateSelfAssessmentForPerson($m2sID: ID!, $data: update_t_se_ma2seb_input!) {
  update_t_se_ma2seb_item(id: $m2sID, data: $data) {
    m2s_id
    ma_id
    seb_id
    selbst_beantwortet
    new_von
    new_am
    se_status_id {
      se_status_id
      status_name
    }
  }
}
    `;
export type UpdateSelfAssessmentForPersonMutationFn = Apollo.MutationFunction<UpdateSelfAssessmentForPersonMutation, UpdateSelfAssessmentForPersonMutationVariables>;

/**
 * __useUpdateSelfAssessmentForPersonMutation__
 *
 * To run a mutation, you first call `useUpdateSelfAssessmentForPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfAssessmentForPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfAssessmentForPersonMutation, { data, loading, error }] = useUpdateSelfAssessmentForPersonMutation({
 *   variables: {
 *      m2sID: // value for 'm2sID'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSelfAssessmentForPersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelfAssessmentForPersonMutation, UpdateSelfAssessmentForPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelfAssessmentForPersonMutation, UpdateSelfAssessmentForPersonMutationVariables>(UpdateSelfAssessmentForPersonDocument, options);
      }
export type UpdateSelfAssessmentForPersonMutationHookResult = ReturnType<typeof useUpdateSelfAssessmentForPersonMutation>;
export type UpdateSelfAssessmentForPersonMutationResult = Apollo.MutationResult<UpdateSelfAssessmentForPersonMutation>;
export type UpdateSelfAssessmentForPersonMutationOptions = Apollo.BaseMutationOptions<UpdateSelfAssessmentForPersonMutation, UpdateSelfAssessmentForPersonMutationVariables>;
export const GetAppParametersDocument = gql`
    query getAppParameters($parameterNames: [String]) {
  parameters(filter: {name: {_in: $parameterNames}}) {
    id
    name
    value
  }
}
    `;

/**
 * __useGetAppParametersQuery__
 *
 * To run a query within a React component, call `useGetAppParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppParametersQuery({
 *   variables: {
 *      parameterNames: // value for 'parameterNames'
 *   },
 * });
 */
export function useGetAppParametersQuery(baseOptions?: Apollo.QueryHookOptions<GetAppParametersQuery, GetAppParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppParametersQuery, GetAppParametersQueryVariables>(GetAppParametersDocument, options);
      }
export function useGetAppParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppParametersQuery, GetAppParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppParametersQuery, GetAppParametersQueryVariables>(GetAppParametersDocument, options);
        }
export function useGetAppParametersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAppParametersQuery, GetAppParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAppParametersQuery, GetAppParametersQueryVariables>(GetAppParametersDocument, options);
        }
export type GetAppParametersQueryHookResult = ReturnType<typeof useGetAppParametersQuery>;
export type GetAppParametersLazyQueryHookResult = ReturnType<typeof useGetAppParametersLazyQuery>;
export type GetAppParametersSuspenseQueryHookResult = ReturnType<typeof useGetAppParametersSuspenseQuery>;
export type GetAppParametersQueryResult = Apollo.QueryResult<GetAppParametersQuery, GetAppParametersQueryVariables>;
export const GetReportsDocument = gql`
    query getReports {
  reports {
    id
    file_name
    name
    is_visible
  }
}
    `;

/**
 * __useGetReportsQuery__
 *
 * To run a query within a React component, call `useGetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
      }
export function useGetReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
        }
export function useGetReportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
        }
export type GetReportsQueryHookResult = ReturnType<typeof useGetReportsQuery>;
export type GetReportsLazyQueryHookResult = ReturnType<typeof useGetReportsLazyQuery>;
export type GetReportsSuspenseQueryHookResult = ReturnType<typeof useGetReportsSuspenseQuery>;
export type GetReportsQueryResult = Apollo.QueryResult<GetReportsQuery, GetReportsQueryVariables>;
export const GetUserSettingsDocument = gql`
    query getUserSettings {
  settings {
    language_code
    selected_departments
    favorite_persons
    id
  }
}
    `;

/**
 * __useGetUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(GetUserSettingsDocument, options);
      }
export function useGetUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(GetUserSettingsDocument, options);
        }
export function useGetUserSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(GetUserSettingsDocument, options);
        }
export type GetUserSettingsQueryHookResult = ReturnType<typeof useGetUserSettingsQuery>;
export type GetUserSettingsLazyQueryHookResult = ReturnType<typeof useGetUserSettingsLazyQuery>;
export type GetUserSettingsSuspenseQueryHookResult = ReturnType<typeof useGetUserSettingsSuspenseQuery>;
export type GetUserSettingsQueryResult = Apollo.QueryResult<GetUserSettingsQuery, GetUserSettingsQueryVariables>;
export const UpdateUserFavoritePersonsDocument = gql`
    mutation updateUserFavoritePersons($id: ID!, $favorite_persons: String) {
  update_settings_item(id: $id, data: {favorite_persons: $favorite_persons}) {
    favorite_persons
  }
}
    `;
export type UpdateUserFavoritePersonsMutationFn = Apollo.MutationFunction<UpdateUserFavoritePersonsMutation, UpdateUserFavoritePersonsMutationVariables>;

/**
 * __useUpdateUserFavoritePersonsMutation__
 *
 * To run a mutation, you first call `useUpdateUserFavoritePersonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFavoritePersonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFavoritePersonsMutation, { data, loading, error }] = useUpdateUserFavoritePersonsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      favorite_persons: // value for 'favorite_persons'
 *   },
 * });
 */
export function useUpdateUserFavoritePersonsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserFavoritePersonsMutation, UpdateUserFavoritePersonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserFavoritePersonsMutation, UpdateUserFavoritePersonsMutationVariables>(UpdateUserFavoritePersonsDocument, options);
      }
export type UpdateUserFavoritePersonsMutationHookResult = ReturnType<typeof useUpdateUserFavoritePersonsMutation>;
export type UpdateUserFavoritePersonsMutationResult = Apollo.MutationResult<UpdateUserFavoritePersonsMutation>;
export type UpdateUserFavoritePersonsMutationOptions = Apollo.BaseMutationOptions<UpdateUserFavoritePersonsMutation, UpdateUserFavoritePersonsMutationVariables>;
export const UpdateUserSelectedDepartmentsDocument = gql`
    mutation updateUserSelectedDepartments($id: ID!, $selected_departments: String) {
  update_settings_item(
    id: $id
    data: {selected_departments: $selected_departments}
  ) {
    id
  }
}
    `;
export type UpdateUserSelectedDepartmentsMutationFn = Apollo.MutationFunction<UpdateUserSelectedDepartmentsMutation, UpdateUserSelectedDepartmentsMutationVariables>;

/**
 * __useUpdateUserSelectedDepartmentsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSelectedDepartmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSelectedDepartmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSelectedDepartmentsMutation, { data, loading, error }] = useUpdateUserSelectedDepartmentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      selected_departments: // value for 'selected_departments'
 *   },
 * });
 */
export function useUpdateUserSelectedDepartmentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSelectedDepartmentsMutation, UpdateUserSelectedDepartmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSelectedDepartmentsMutation, UpdateUserSelectedDepartmentsMutationVariables>(UpdateUserSelectedDepartmentsDocument, options);
      }
export type UpdateUserSelectedDepartmentsMutationHookResult = ReturnType<typeof useUpdateUserSelectedDepartmentsMutation>;
export type UpdateUserSelectedDepartmentsMutationResult = Apollo.MutationResult<UpdateUserSelectedDepartmentsMutation>;
export type UpdateUserSelectedDepartmentsMutationOptions = Apollo.BaseMutationOptions<UpdateUserSelectedDepartmentsMutation, UpdateUserSelectedDepartmentsMutationVariables>;
export const UpdateUserSettingsLanguageDocument = gql`
    mutation updateUserSettingsLanguage($id: ID!, $language_code: String) {
  update_settings_item(id: $id, data: {language_code: $language_code}) {
    id
  }
}
    `;
export type UpdateUserSettingsLanguageMutationFn = Apollo.MutationFunction<UpdateUserSettingsLanguageMutation, UpdateUserSettingsLanguageMutationVariables>;

/**
 * __useUpdateUserSettingsLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsLanguageMutation, { data, loading, error }] = useUpdateUserSettingsLanguageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      language_code: // value for 'language_code'
 *   },
 * });
 */
export function useUpdateUserSettingsLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingsLanguageMutation, UpdateUserSettingsLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSettingsLanguageMutation, UpdateUserSettingsLanguageMutationVariables>(UpdateUserSettingsLanguageDocument, options);
      }
export type UpdateUserSettingsLanguageMutationHookResult = ReturnType<typeof useUpdateUserSettingsLanguageMutation>;
export type UpdateUserSettingsLanguageMutationResult = Apollo.MutationResult<UpdateUserSettingsLanguageMutation>;
export type UpdateUserSettingsLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateUserSettingsLanguageMutation, UpdateUserSettingsLanguageMutationVariables>;