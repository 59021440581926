import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FunctionTypes, IFunctionItem } from '@/types/functions';
import { LoadingStatus } from '@/types/loadingStatus';
import { IPerson } from '@/types/person';

export interface IUpdatePinnedPayload {
  id: string;
  is_pinned: boolean;
}

export interface IUpdateFunctionOrderNumberPayload {
  functions: IFunctionItem[];
}

export interface IUpdateFunctionType {
  id: string;
  functionType: string | undefined;
}

export interface IAddShortcutTabLockResponse {
  personID: number | null;
  tabID: string | null;
}
export interface IAddShortcutTabLock {
  status: LoadingStatus;
  data: IAddShortcutTabLockResponse | null;
}

export interface IFunctionPageState {
  status: LoadingStatus;
  functions: IFunctionItem[];
  activeTabId: null | string;
  tabs: ITabsState;
  selectedPerson: IPerson | null;
  pinFunctionLock: LoadingStatus;
  addShortcutTabLock: IAddShortcutTabLock;
  usersActiveTab: {
    [userId: string]: string | null;
  };
}

export interface Tab {
  [tabId: string]: {
    title: string;
    functionItem?: IAddTabFunctionItem;
  };
}

export interface IMutateTabPayload {
  userId: number;
  tabId: string;
  tabData: {
    title: string;
    functionItem?: {
      name?: string;
      id: string;
      iconUrl: string;
    };
  };
}

export interface IActiveTabPayload {
  userId: number;
  activeTab: string | null;
}

export interface IAddTabFunctionItem {
  name: string;
  id: string;
  iconUrl: string;
  functionType?: FunctionTypes;
}

interface IAddTabFunctionPayload {
  userId: number;
  tabId: string;
  functionItem: IAddTabFunctionItem;
}

interface ITabsState {
  [userId: string]: Tab;
}

const initialState: IFunctionPageState = {
  status: LoadingStatus.NEVER,
  functions: [],
  activeTabId: null,
  selectedPerson: null,
  tabs: {},
  usersActiveTab: {},
  pinFunctionLock: LoadingStatus.NEVER,
  addShortcutTabLock: {
    status: LoadingStatus.NEVER,
    data: {
      personID: null,
      tabID: null,
    },
  },
};

const functionPageSlice = createSlice({
  name: 'functionPage',
  initialState,
  reducers: {
    fetchFunctions: () => {},
    updatePinned: (state, action: PayloadAction<IUpdatePinnedPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateFunctionOrderNumber: (
      state,
      action: PayloadAction<IUpdateFunctionOrderNumberPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    updateFunctionType: (state, action: PayloadAction<IUpdateFunctionType>) => ({
      ...state,
      payload: action.payload,
    }),
    setFunctions: (state, action: PayloadAction<IFunctionItem[]>) => ({
      ...state,
      functions: action.payload,
    }),
    setFunctionsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      status: action.payload,
    }),
    setActiveTabId: (state, action: PayloadAction<string | null>) => ({
      ...state,
      activeTabId: action.payload,
    }),
    setUserActiveTab: (state, action: PayloadAction<IActiveTabPayload>) => {
      const { userId, activeTab } = action.payload;
      const activeTabsState = {
        ...state.usersActiveTab,
        [userId]: activeTab,
      };

      return {
        ...state,
        usersActiveTab: activeTabsState,
        activeTabId: activeTab,
      };
    },
    setSelectedPerson: (state, action: PayloadAction<IPerson | null>) => ({
      ...state,
      selectedPerson: action.payload,
    }),
    loadTabsFromLocale: (state) => {
      const storedTabs = localStorage.getItem('tabs');
      let newTabs = state.tabs;
      if (storedTabs) {
        newTabs = JSON.parse(storedTabs);
      }
      return {
        ...state,
        tabs: newTabs,
      };
    },
    addTab: (state, action: PayloadAction<IMutateTabPayload>) => {
      const { userId, tabId, tabData } = action.payload;
      const tabs = state.tabs[userId] || {};

      // Check if a tab with the same functionItem.id already exists
      const existingTabId = Object.keys(tabs).find(
        (key) => tabs[key]?.functionItem?.id === tabData.functionItem?.id
      );

      if (existingTabId) {
        const activeTabsState = {
          ...state.usersActiveTab,
          [userId]: existingTabId,
        };

        return {
          ...state,
          usersActiveTab: activeTabsState,
          activeTabId: existingTabId,
        };
      }

      const tabsState = {
        ...state.tabs,
        [userId]: {
          ...(state.tabs[userId] || {}),
          [tabId]: tabData,
        },
      };

      localStorage.setItem('tabs', JSON.stringify(tabsState));

      return {
        ...state,
        usersActiveTab: {
          ...state.usersActiveTab,
          [userId]: tabId,
        },
        activeTabId: tabId,
        tabs: tabsState,
      };
    },
    setTabFunction: (state, action: PayloadAction<IAddTabFunctionPayload>) => {
      const { userId, tabId, functionItem } = action.payload;
      const tabs = state.tabs[userId] || {};

      // Check if a tab with the same functionItem.id already exists
      const existingTabId = Object.keys(tabs).find(
        (key) => tabs[key]?.functionItem?.id === functionItem.id
      );

      if (existingTabId) {
        const activeTabsState = {
          ...state.usersActiveTab,
          [userId]: existingTabId,
        };

        return {
          ...state,
          usersActiveTab: activeTabsState,
          activeTabId: existingTabId,
        };
      } else {
        const tabsState = {
          ...state.tabs,
          [userId]: {
            ...tabs,
            [tabId]: { ...tabs[tabId], functionItem },
          },
        };

        localStorage.setItem('tabs', JSON.stringify(tabsState));

        return {
          ...state,
          tabs: tabsState,
        };
      }
    },
    removeTab: (state, action: PayloadAction<Omit<IMutateTabPayload, 'tabData'>>) => {
      const { userId, tabId } = action.payload;
      const updatedUserTabs = Object.keys(state.tabs[userId] || {}).reduce((result: Tab, key) => {
        const res = result;
        if (key !== tabId) {
          res[key] = state.tabs[userId][key];
        }
        return res;
      }, {});

      const newTabsState = {
        ...state.tabs,
        [userId]: {
          ...(updatedUserTabs || {}),
        },
      };

      localStorage.setItem('tabs', JSON.stringify(newTabsState));

      return {
        ...state,
        tabs: newTabsState,
      };
    },
    addShortcutTab: (state, action: PayloadAction<any>) => ({
      ...state,
      payload: action.payload,
    }),
    setAddShortcutTabLock: (state, action: PayloadAction<IAddShortcutTabLock>) => ({
      ...state,
      addShortcutTabLock: action.payload,
    }),
    setPinFunctionLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      pinFunctionLock: action.payload,
    }),
  },
});

export const functionPageReducer = functionPageSlice.reducer;
export const functionPageActions = functionPageSlice.actions;
