import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  IPersonData,
  IPersonDataFetchPayload,
  IPersonDataUpdatePayload,
  IServiceProvider,
  personDataActions,
} from './personDataSlice';

type PersonDataResponse = OASOutput<NormalizeOAS<typeof oas>, '/person/general_data', 'get', '200'>;
type PersonDataUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/person/general_data',
  'put'
>;
type ServiceProvidersResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/dropdown_items/service_provider',
  'get',
  '200'
>;

function* fetchPersonData(
  action: PayloadAction<IPersonDataFetchPayload>
): Generator<any, void, PersonDataResponse> {
  yield put(personDataActions.setPersonDataLock(LoadingStatus.LOADING));

  const { personID } = action.payload;

  try {
    const response = yield call(restCall, '/person/general_data', 'get', {
      query: { person_id: personID },
      ...authAdd(),
    });

    const { general_data } = response;

    const generalData: IPersonData = {
      ...toCamelCase(general_data),
      birthDate: toClientDateInput(general_data.birth_date),
      inactiveTimestamp: toClientDateInput(general_data.inactive_timestamp),
      salutation: general_data.salutation?.id,
      gender: general_data.gender?.id,
      maritalStatus: general_data.marital_status?.id,
      nationality: general_data.nationality?.id,
      confession: general_data.confession?.id,
      supervisor: general_data.supervisor?.id,
      foreignLegalStatus: general_data.foreign_legal_status?.id,
      serviceProvider: general_data.service_provider?.id,
      healthInsurance: general_data.health_insurance?.id,
      housingForm: general_data.housing_form?.id,
      educationalSupporter: general_data.educational_supporter?.id,
      socialServiceWorker: general_data.social_service_worker?.id,
      groupManager: general_data.group_manager?.id,
      jobcoach: general_data.jobcoach?.id,
      manager: general_data.manager?.id,
      specialist: general_data.specialist?.id,
    };

    yield put(personDataActions.setPersonData(generalData));

    yield put(personDataActions.setPersonDataLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on person general data fetching');
    yield put(personDataActions.setPersonDataLock(LoadingStatus.ERROR));
  }
}

function* updatePersonData(
  action: PayloadAction<IPersonDataUpdatePayload>
): Generator<any, void, any> {
  const { personID, personData } = action.payload;

  try {
    yield put(personDataActions.setUpdatePersonDataLock(LoadingStatus.LOADING));

    const request: PersonDataUpdateRequest = {
      json: {
        id: personID,
        name: personData.name ?? '',
        surname: personData.surname ?? '',
        soz_vers_nr: personData.sozVersNr,
        birth_date: toBackendDate(personData.birthDate),
        birth_place: personData.birthPlace,
        kids_number: personData.kidsNumber ?? 0,
        is_photo_consent: personData.isPhotoConsent,
        is_admission_reserved: personData.isAdmissionReserved,
        inactive_timestamp: toBackendDate(personData.inactiveTimestamp),
        cost_bearer: personData.costBearer,
        salutation_id: personData.salutation,
        gender_id: personData.gender,
        marital_status_id: personData.maritalStatus,
        nationality_id: personData.nationality,
        confession_id: personData.confession,
        supervisor_id: personData.supervisor,
        foreign_legal_status_id: personData.foreignLegalStatus,
        service_provider_id: personData.serviceProvider,
        health_insurance_id: personData.healthInsurance,
        housing_form_id: personData.housingForm,
        educational_supporter_id: personData.educationalSupporter,
        social_service_worker_id: personData.socialServiceWorker,
        group_manager_id: personData.groupManager,
        jobcoach_id: personData.jobcoach,
        manager_id: personData.manager,
        specialist_id: personData.specialist,
      },
      ...authAdd(),
    };

    yield call(restCall, '/person/general_data', 'put', request);

    yield put(personDataActions.setUpdatePersonDataLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on medication update', error);
    yield put(personDataActions.setUpdatePersonDataLock(LoadingStatus.ERROR));
  }
}

function* fetchServiceProviders(): Generator<any, void, ServiceProvidersResponse> {
  try {
    const response = yield call(restCall, '/dropdown_items/service_provider', 'get', {
      ...authAdd(),
    });

    const serviceProviders: IServiceProvider[] = response.dropdown_items;

    yield put(personDataActions.setServiceProviders(serviceProviders));
  } catch (error) {
    console.log('Error on medication update', error);
  }
}

export const personDataSagas = [
  takeLatest(personDataActions.fetchPersonData, fetchPersonData),
  takeLatest(personDataActions.fetchServiceProviders, fetchServiceProviders),
  takeLatest(personDataActions.updatePersonData, updatePersonData),
];
