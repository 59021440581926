import { useForm } from 'react-hook-form';

interface IBarChartForm {
  chartLayout: 'vertical' | 'horizontal';
  showLabel: boolean;
}

export function useBarChartForm() {
  const form = useForm<IBarChartForm>({
    defaultValues: {
      chartLayout: 'horizontal',
      showLabel: true,
    },
  });

  const chartLayout = form.watch().chartLayout;
  const showLabel = form.watch().showLabel;

  return { form, chartLayout, showLabel };
}
