import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAdditionalPersonRequirementForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/hooks/useAdditionalPersonRequirementForm';
import { IPersonRequirementTimeListForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/hooks/usePersonRequirementTimeListForm';
import { OrganizationalUnitErrors } from '@/core/enums/functions/organizationalUnits/organizationalUnitErrorsEnum';
import { OrganizationalUnitsTypes } from '@/core/enums/functions/organizationalUnits/organizationalUnitsTypesEnum';
import { OrganizationUnitBBBActionTypes } from '@/core/enums/functions/organizationalUnits/organizationUnitBBBActionTypesEnum';
import { UpdateBBBStatusTypes } from '@/core/enums/functions/organizationalUnits/updateBBBStatusTypesEnum';
import {
  IAdditionalPersonRequirementAssistance,
  IAdditionalPersonRequirementPayload,
  IAdditionalPersonRequirementResponsible,
  IOrganizationalUnit,
  IPersonRequirement,
} from '@/core/redux/slices/functions/organizationalUnits/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IModal {
  isRender: boolean;
}

export interface IOrganizationUnitBBBAction {
  id: number;
  name: string;
  type: OrganizationUnitBBBActionTypes;
  value?: number | null;
}

export interface IOrganizationalUnitBBBFormValues {
  startDate?: string;
  comment?: string;
  actionID?: number;
  actionType?: OrganizationUnitBBBActionTypes;
  actionValue?: number;
  isWithoutAdaptation?: boolean;
  adaptationPeriod?: number;
}

export interface IAvailableBBBActionsPayload {
  personID: number;
}

export interface IAvailableActions {
  actionsList: IOrganizationUnitBBBAction[] | null;
  selectedAction?: IOrganizationUnitBBBAction | null;
  actionValue?: number | null;
  isWithoutAdaptation?: boolean | null;
  adaptationPeriod?: number | null;
  initialDate: string | null;
}

export interface IOrganizationUnitBBB {
  availableActions?: IAvailableActions | null;
  formValues?: IOrganizationalUnitBBBFormValues | null;
}

export interface IAvailableOrganizationUnit {
  unitID: number;
  name?: string | null;
  type: OrganizationalUnitsTypes;
}

export interface IOrganizationalUnitBBBInterruption {
  formValues?: IOrganizationalUnitBBBInterruptionFormValues | null;
}

export interface ICreateOrganizationalUnitModal extends IModal {
  payload: ICreateOrganizationalUnitModalPayload | null;
  fetchAvailableUnitsPayload: IAvailableOrganizationalUnitsFetchPayload | null;
  updateBBBStatus: UpdateBBBStatusTypes | null;
  availableOrganizationUnits: IAvailableOrganizationUnit[] | null;
  availableOrganizationUnitsLock: LoadingStatus;
}

export interface ICreateOrganizationalUnitModalPayload {
  personID: number;
}

export interface IAvailableOrganizationalUnitsFetchPayload {
  personID: number;
}

export interface ICreateOrganizationalUnitPayload {
  personID: number;
  type: OrganizationalUnitsTypes;
  fromTimestamp: string;
  toTimestamp?: string | null;
  note?: string | null;
}

export interface IOrganizationalUnitBBBInterruptionFormValues {
  fromTimestamp: string;
  toTimestamp?: string | null;
  note?: string | null;
}

export interface IUpdateOrganizationalUnitBBBInterruptionPayload {
  id: number;
  personID: number;
  fromTimestamp: string;
  toTimestamp?: string | null;
  note?: string | null;
}

export interface IUpdateOrganizationalUnitBBBPayload {
  personID: number;
  formValues: IOrganizationalUnitBBBFormValues;
  unitID: number;
}

export interface IUpdateOrganizationalUnitModalPayload {
  personID: number;
  organizationalUnit: IOrganizationalUnit;
  unitID: number;
}

export interface IUpdateOrganizationalUnitModal extends IModal {
  payload: IUpdateOrganizationalUnitModalPayload | null;
}

export interface IAdditionalPersonRequirementCreatePayload {
  personID: number;
  formValues: IAdditionalPersonRequirementForm;
}

export interface IAdditionalPersonRequirementUpdatePayload
  extends IAdditionalPersonRequirementCreatePayload {
  additionalPersonRequirementID: number;
}

export interface IAdditionalPersonRequirementModal extends IModal {
  additionalPersonRequirementID: number | null;
  responsible?: IAdditionalPersonRequirementResponsible | null;
  additionalPersonRequirement: IPersonRequirement | null;
  additionalPersonRequirementLock: LoadingStatus;
  availableAssistanceTypes: IAdditionalPersonRequirementAssistance[];
}

export interface IAdditionalPersonRequirementModalPayload {
  additionalPersonRequirementID: number | null;
  responsible?: IAdditionalPersonRequirementResponsible | null;
}

export interface IPersonRequirementTimeListPayload {
  additionalPersonRequirementID: number;
}

export interface IPersonRequirementTimeListModalPayload extends IPersonRequirementTimeListPayload {
  assistanceName?: string | null;
}

export interface IPersonRequirementTimeCycle {
  id: number;
  name?: string | null;
  divisor?: number | null;
}

export interface IPersonRequirementTimeItem {
  id: number;
  name?: string | null;
  value?: number | null;
  isInGroup?: boolean | null;
  cycleID?: number | null;
  minutesPerDay?: number | null;
  comment?: string | null;
}

export interface IPersonRequirementTimeGroup {
  id: number;
  name?: string | null;
  items: IPersonRequirementTimeItem[];
  maxEntriesCount?: number | null;
}

export interface IPersonRequirementTimeListUpdatePayload {
  formValues: IPersonRequirementTimeListForm;
  additionalPersonRequirementID: number;
  personID: number;
}

export interface IAdditionalPersonRequirementTimeListModal extends IModal {
  payload: IPersonRequirementTimeListModalPayload | null;
  personRequirementTimeGroups: IPersonRequirementTimeGroup[];
  personRequirementTimeGroupsLock: LoadingStatus;
  availablePersonRequirementTimeCycles: IPersonRequirementTimeCycle[];
  updatePersonRequirementTimeListPayload: IPersonRequirementTimeListUpdatePayload | null;
  updatePersonRequirementTimeListLock: LoadingStatus;
}

export interface IAdditionalPersonRequirementAssessmentPrognosisPayload {
  additionalPersonRequirementID: number;
  assistanceName?: string | null;
}

export interface IAdditionalPersonRequirementAssessmentPrognosisUpdatePayload
  extends IAdditionalPersonRequirementAssessmentPrognosisPayload {
  assessment?: string | null;
  prognosis?: string | null;
}

export interface IAdditionalPersonRequirementAssessmentPrognosis {
  assessment?: string | null;
  prognosis?: string | null;
}

export interface IAdditionalPersonRequirementAssessmentPrognosisModal extends IModal {
  additionalPersonRequirementAssessmentPrognosis: IAdditionalPersonRequirementAssessmentPrognosis | null;
  additionalPersonRequirementAssessmentPrognosisLock: LoadingStatus;
  payload: IAdditionalPersonRequirementAssessmentPrognosisPayload | null;
  updatePayload: IAdditionalPersonRequirementAssessmentPrognosisUpdatePayload | null;
}

export interface IOrganizationalUnitsModalsState {
  createOrganizationalUnitModal: ICreateOrganizationalUnitModal;
  updateOrganizationalUnitModal: IUpdateOrganizationalUnitModal;
  organizationalUnitsForms: {
    organizationalUnitBBB?: IOrganizationUnitBBB | null;
    organizationalUnitBBBInterruption?: IOrganizationalUnitBBBInterruption | null;
  };
  organizationalUnitErrors: Record<OrganizationalUnitErrors, boolean>;
  additionalPersonRequirementModal: IAdditionalPersonRequirementModal;
  additionalPersonRequirementTimeListModal: IAdditionalPersonRequirementTimeListModal;
  additionalPersonRequirementAssessmentPrognosisModal: IAdditionalPersonRequirementAssessmentPrognosisModal;
}

const initialState: IOrganizationalUnitsModalsState = {
  createOrganizationalUnitModal: {
    isRender: false,
    payload: null,
    fetchAvailableUnitsPayload: null,
    availableOrganizationUnits: null,
    availableOrganizationUnitsLock: LoadingStatus.NEVER,
    updateBBBStatus: null,
  },
  updateOrganizationalUnitModal: {
    isRender: false,
    payload: null,
  },
  organizationalUnitsForms: {
    organizationalUnitBBB: null,
    organizationalUnitBBBInterruption: null,
  },
  organizationalUnitErrors: {
    [OrganizationalUnitErrors.WITHOUT_NOTE]: false,
    [OrganizationalUnitErrors.UPDATE_OUT_OF_RANGE]: false,
    [OrganizationalUnitErrors.CREATE_OUT_OF_RANGE]: false,
    [OrganizationalUnitErrors.END_DATE_BEFORE_START_DATE]: false,
    [OrganizationalUnitErrors.WITHOUT_PERSON]: false,
    [OrganizationalUnitErrors.INCORRECT_DATE]: false,
  },
  additionalPersonRequirementModal: {
    isRender: false,
    additionalPersonRequirement: null,
    additionalPersonRequirementID: null,
    additionalPersonRequirementLock: LoadingStatus.NEVER,
    availableAssistanceTypes: [],
  },
  additionalPersonRequirementTimeListModal: {
    isRender: false,
    payload: null,
    updatePersonRequirementTimeListPayload: null,
    updatePersonRequirementTimeListLock: LoadingStatus.NEVER,
    personRequirementTimeGroups: [],
    personRequirementTimeGroupsLock: LoadingStatus.NEVER,
    availablePersonRequirementTimeCycles: [],
  },
  additionalPersonRequirementAssessmentPrognosisModal: {
    isRender: false,
    additionalPersonRequirementAssessmentPrognosis: null,
    payload: null,
    updatePayload: null,
    additionalPersonRequirementAssessmentPrognosisLock: LoadingStatus.NEVER,
  },
};

const organizationalUnitsModalsSlice = createSlice({
  name: 'organizationalUnitsModals',
  initialState,
  reducers: {
    //Create organizational unit modal
    openCreateOrganizationalUnitModal: (
      state,
      action: PayloadAction<ICreateOrganizationalUnitModalPayload>
    ) => ({
      ...state,
      createOrganizationalUnitModal: {
        isRender: true,
        availableOrganizationUnits: null,
        payload: action.payload,
        fetchAvailableUnitsPayload: null,
        availableOrganizationUnitsLock: LoadingStatus.NEVER,
        updateBBBStatus: null,
      },
    }),
    closeCreateOrganizationalUnitModal: (state) => ({
      ...state,
      createOrganizationalUnitModal: {
        isRender: false,
        availableOrganizationUnits: null,
        payload: null,
        fetchAvailableUnitsPayload: null,
        availableOrganizationUnitsLock: LoadingStatus.NEVER,
        updateBBBStatus: null,
      },
    }),
    fetchAvailableOrganizationalUnit: (
      state,
      action: PayloadAction<IAvailableOrganizationalUnitsFetchPayload>
    ) => ({
      ...state,
      createOrganizationalUnitModal: {
        ...state.createOrganizationalUnitModal,
        fetchAvailableUnitsPayload: action.payload,
      },
    }),
    setAvailableOrganizationalUnit: (
      state,
      action: PayloadAction<IAvailableOrganizationUnit[]>
    ) => ({
      ...state,
      createOrganizationalUnitModal: {
        ...state.createOrganizationalUnitModal,
        availableOrganizationUnits: action.payload,
      },
    }),
    setAvailableOrganizationalUnitLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      createOrganizationalUnitModal: {
        ...state.createOrganizationalUnitModal,
        availableOrganizationUnitsLock: action.payload,
      },
    }),
    createOrganizationalUnit: (state, action: PayloadAction<ICreateOrganizationalUnitPayload>) => ({
      ...state,
      payload: action.payload,
    }),

    //Update Organizational Unit
    openUpdateOrganizationalUnitModal: (
      state,
      action: PayloadAction<IUpdateOrganizationalUnitModalPayload>
    ) => ({
      ...state,
      updateOrganizationalUnitModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeUpdateOrganizationalUnitModal: (state) => ({
      ...state,
      updateOrganizationalUnitModal: {
        isRender: false,
        payload: null,
      },
    }),

    // BBB Organizational Unit
    fetchOrganizationalUnitAvailableBBBActions: (
      state,
      action: PayloadAction<IAvailableBBBActionsPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setOrganizationalUnitAvailableBBBActions: (
      state,
      action: PayloadAction<IAvailableActions | null>
    ) => ({
      ...state,
      organizationalUnitsForms: {
        ...state.organizationalUnitsForms,
        organizationalUnitBBB: {
          ...state.organizationalUnitsForms.organizationalUnitBBB,
          availableActions: action.payload,
        },
      },
    }),
    setOrganizationalUnitAvailableBBBActionsLock: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => ({
      ...state,
      organizationalUnitsForms: {
        ...state.organizationalUnitsForms,
        organizationalUnitBBB: {
          ...state.organizationalUnitsForms.organizationalUnitBBB,
          availableActionsLock: action.payload,
        },
      },
    }),
    updateOrganizationalUnitBBB: (
      state,
      action: PayloadAction<IUpdateOrganizationalUnitBBBPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setOrganizationalUnitBBBFormValues: (
      state,
      action: PayloadAction<IOrganizationalUnitBBBFormValues | null>
    ) => ({
      ...state,
      organizationalUnitsForms: {
        ...state.organizationalUnitsForms,
        organizationalUnitBBB: {
          ...state.organizationalUnitsForms.organizationalUnitBBB,
          formValues: action.payload,
        },
      },
    }),
    setUpdateOrganizationalUnitBBBStatus: (
      state,
      action: PayloadAction<UpdateBBBStatusTypes | null>
    ) => ({
      ...state,
      createOrganizationalUnitModal: {
        ...state.createOrganizationalUnitModal,
        updateBBBStatus: action.payload,
      },
    }),
    setOrganizationalErrors: (
      state,
      action: PayloadAction<Partial<Record<OrganizationalUnitErrors, boolean>>>
    ) => ({
      ...state,
      organizationalUnitErrors: {
        ...state.organizationalUnitErrors,
        ...action.payload,
      },
    }),

    // BBB Interruption Organizational Unit
    setOrganizationalUnitBBBInterruptionFormValues: (
      state,
      action: PayloadAction<IOrganizationalUnitBBBInterruptionFormValues | null>
    ) => ({
      ...state,
      organizationalUnitsForms: {
        ...state.organizationalUnitsForms,
        organizationalUnitsForms: {
          ...state.organizationalUnitsForms,
          organizationalUnitBBBInterruption: {
            ...state.organizationalUnitsForms.organizationalUnitBBBInterruption,
            formValues: action.payload,
          },
        },
      },
    }),
    updateOrganizationalUnitBBBInterruption: (
      state,
      action: PayloadAction<IUpdateOrganizationalUnitBBBInterruptionPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    //Additional person requirement
    openAdditionalPersonRequirementModal: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementModalPayload>
    ) => ({
      ...state,
      additionalPersonRequirementModal: {
        ...state.additionalPersonRequirementModal,
        isRender: true,
        additionalPersonRequirementID: action.payload.additionalPersonRequirementID,
        responsible: action.payload.responsible,
      },
    }),
    closeAdditionalPersonRequirementModal: (state) => ({
      ...state,
      additionalPersonRequirementModal: {
        isRender: false,
        additionalPersonRequirementID: null,
        additionalPersonRequirement: null,
        additionalPersonRequirementLock: LoadingStatus.NEVER,
        availableAssistanceTypes: [],
        responsibleList: [],
      },
    }),
    fetchAdditionalPersonRequirementDetails: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementModalPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    fetchAvailableAssistanceTypes: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setAvailableAssistanceTypes: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementAssistance[]>
    ) => ({
      ...state,
      additionalPersonRequirementModal: {
        ...state.additionalPersonRequirementModal,
        availableAssistanceTypes: action.payload,
      },
    }),
    setAdditionalPersonRequirementDetails: (state, action: PayloadAction<IPersonRequirement>) => ({
      ...state,
      additionalPersonRequirementModal: {
        ...state.additionalPersonRequirementModal,
        additionalPersonRequirement: action.payload,
      },
    }),
    setAdditionalPersonRequirementDetailsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      additionalPersonRequirementModal: {
        ...state.additionalPersonRequirementModal,
        additionalPersonRequirementLock: action.payload,
      },
    }),
    createAdditionalPersonRequirement: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementCreatePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    updateAdditionalPersonRequirement: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementUpdatePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    //additional person requirement time list
    openAdditionalPersonRequirementTimeListModal: (
      state,
      action: PayloadAction<IPersonRequirementTimeListModalPayload>
    ) => ({
      ...state,
      additionalPersonRequirementTimeListModal: {
        ...state.additionalPersonRequirementTimeListModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAdditionalPersonRequirementTimeListModal: (state) => ({
      ...state,
      additionalPersonRequirementTimeListModal: {
        isRender: false,
        payload: null,
        updatePersonRequirementTimeListPayload: null,
        updatePersonRequirementTimeListLock: LoadingStatus.NEVER,
        personRequirementTimeGroups: [],
        personRequirementTimeGroupsLock: LoadingStatus.NEVER,
        availablePersonRequirementTimeCycles: [],
      },
    }),
    fetchAdditionalPersonRequirementTimeList: (
      state,
      action: PayloadAction<IPersonRequirementTimeListPayload>
    ) => ({
      ...state,
      additionalPersonRequirementTimeListModal: {
        ...state.additionalPersonRequirementTimeListModal,
        payload: {
          ...state.additionalPersonRequirementTimeListModal.payload,
          additionalPersonRequirementID: action.payload.additionalPersonRequirementID,
        },
      },
    }),
    setAdditionalPersonRequirementTimeList: (
      state,
      action: PayloadAction<IPersonRequirementTimeGroup[]>
    ) => ({
      ...state,
      additionalPersonRequirementTimeListModal: {
        ...state.additionalPersonRequirementTimeListModal,
        personRequirementTimeGroups: action.payload,
      },
    }),
    setAdditionalPersonRequirementTimeListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      additionalPersonRequirementTimeListModal: {
        ...state.additionalPersonRequirementTimeListModal,
        personRequirementTimeGroupsLock: action.payload,
      },
    }),
    setAvailablePersonRequirementTimeCycles: (
      state,
      action: PayloadAction<IPersonRequirementTimeCycle[]>
    ) => ({
      ...state,
      additionalPersonRequirementTimeListModal: {
        ...state.additionalPersonRequirementTimeListModal,
        availablePersonRequirementTimeCycles: action.payload,
      },
    }),
    fetchAvailablePersonRequirementTimeCycles: () => {},
    updatePersonRequirementTimeList: (
      state,
      action: PayloadAction<IPersonRequirementTimeListUpdatePayload>
    ) => ({
      ...state,
      additionalPersonRequirementTimeListModal: {
        ...state.additionalPersonRequirementTimeListModal,
        updatePersonRequirementTimeListPayload: action.payload,
      },
    }),
    setUpdatePersonRequirementTimeListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      additionalPersonRequirementTimeListModal: {
        ...state.additionalPersonRequirementTimeListModal,
        updatePersonRequirementTimeListLock: action.payload,
      },
    }),

    //additional person requirement assessment prognosis
    openAdditionalPersonRequirementAssessmentPrognosisModal: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementAssessmentPrognosisPayload>
    ) => ({
      ...state,
      additionalPersonRequirementAssessmentPrognosisModal: {
        ...state.additionalPersonRequirementAssessmentPrognosisModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAdditionalPersonRequirementAssessmentPrognosisModal: (state) => ({
      ...state,
      additionalPersonRequirementAssessmentPrognosisModal: {
        isRender: false,
        additionalPersonRequirementAssessmentPrognosis: null,
        additionalPersonRequirementAssessmentPrognosisLock: LoadingStatus.NEVER,
        payload: null,
        updatePayload: null,
      },
    }),
    fetchAdditionalPersonRequirementAssessmentPrognosis: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementAssessmentPrognosisPayload>
    ) => ({
      ...state,
      additionalPersonRequirementAssessmentPrognosisModal: {
        ...state.additionalPersonRequirementAssessmentPrognosisModal,
        payload: action.payload,
      },
    }),
    setAdditionalPersonRequirementAssessmentPrognosis: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementAssessmentPrognosis>
    ) => ({
      ...state,
      additionalPersonRequirementAssessmentPrognosisModal: {
        ...state.additionalPersonRequirementAssessmentPrognosisModal,
        additionalPersonRequirementAssessmentPrognosis: action.payload,
      },
    }),
    setAdditionalPersonRequirementAssessmentPrognosisLock: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => ({
      ...state,
      additionalPersonRequirementAssessmentPrognosisModal: {
        ...state.additionalPersonRequirementAssessmentPrognosisModal,
        loadingStatus: action.payload,
      },
    }),
    updateAdditionalPersonRequirementAssessmentPrognosis: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementAssessmentPrognosisUpdatePayload>
    ) => ({
      ...state,
      additionalPersonRequirementAssessmentPrognosisModal: {
        ...state.additionalPersonRequirementAssessmentPrognosisModal,
        updatePayload: action.payload,
      },
    }),
  },
});

export const organizationalUnitsModalsReducer = organizationalUnitsModalsSlice.reducer;
export const organizationalUnitsModalsActions = organizationalUnitsModalsSlice.actions;
