import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useBlockDeleteAdministrationDepartmentDialog } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementDialogs/useBlockDeleteAdministrationDepartmentDialog';
import { IAdministrationLocationManagementForm } from '@/components/administartionFunctions/locationManagement/hooks/useAdministrationLocationManagementForm';
import { Button } from '@/components/Button/Button';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { administrationLocationManagementSelectors } from '@/core/redux/slices/administrationFunctions/locationManagement/selectors';
import { administrationLocationManagementActions } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { administrationLocationManagementModalsActions } from '@/core/redux/slices/modalsSlice/administrationFunctions/locationManagement/slice';

export const AdministrationLocationManagementFooter: React.FC = () => {
  const { t: administrationLocationManagementTranslations } = useTranslation(
    'administrationLocationManagement'
  );

  const departments = useAppSelector(administrationLocationManagementSelectors.departments);

  const dispatch = useAppDispatch();

  const { watch } = useFormContext<IAdministrationLocationManagementForm>();

  const selectedDepartmentsState = watch('departments') || [];
  const selectedDepartments = departments.filter((_, index) => selectedDepartmentsState[index]);

  const { renderDialogContainer, openDialog } =
    useBlockDeleteAdministrationDepartmentDialog(selectedDepartments);

  const handleMoveDepartments = () => {
    dispatch(
      administrationLocationManagementModalsActions.openAdministrationDepartmentMoveModal({
        departments: selectedDepartments,
      })
    );
  };

  const handleDeleteDepartments = () => {
    const hasNonDeletable = selectedDepartments?.some((department) => !department.isDeletable);

    if (hasNonDeletable) {
      openDialog();
      return;
    }

    dispatch(
      administrationLocationManagementActions.deleteDepartment({
        departments: selectedDepartments,
      })
    );
  };

  return (
    <div className={'grid grid-cols-2 w-full'}>
      <div className={'flex flex-row gap-3'}>
        <Button disabled={true}>
          {administrationLocationManagementTranslations('locations.buttons.newLocation.label')}
        </Button>
      </div>
      <div className={'flex flex-row gap-3'}>
        {selectedDepartments.length > 1 ? (
          <>
            <Button onClick={handleMoveDepartments}>
              {administrationLocationManagementTranslations(
                'departments.buttons.moveDepartments.label'
              )}
            </Button>
            <Button onClick={handleDeleteDepartments}>
              {administrationLocationManagementTranslations(
                'departments.buttons.deleteDepartments.label'
              )}
            </Button>
          </>
        ) : (
          <Button disabled={true}>
            {administrationLocationManagementTranslations(
              'departments.buttons.newDepartment.label'
            )}
          </Button>
        )}
      </div>
      {renderDialogContainer()}
    </div>
  );
};
