import { useMemo } from 'react';

import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';

export function useAnySelectedPersons(): boolean {
  const [personsChecksState] = usePersonPageStoreSelector((store) => store.personsChecksState);

  return useMemo(() => {
    if (personsChecksState && personsChecksState.persons) {
      return Object.keys(personsChecksState.persons).reduce(
        (accum, personKey) => accum || personsChecksState.persons?.[personKey] || false,
        false
      );
    }
    return false;
  }, [personsChecksState]);
}
