import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Parameter } from '@/core/enums/parametersEnum';

export interface IParameterItem {
  name?: string | null;
  value?: string | null;
  id: string;
}

export interface IParametersState {
  parameters: IParameterItem[];
}

export type IFetchParametersPayload = Parameter[] | undefined;

const initialState: IParametersState = {
  parameters: [],
};

export const parametersSlice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    fetchParameters: (state, action: PayloadAction<IFetchParametersPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setParameters: (state, action: PayloadAction<IParameterItem[]>) => ({
      ...state,
      parameters: action.payload,
    }),
  },
});

export const parametersReducer = parametersSlice.reducer;
export const parametersActions = parametersSlice.actions;
