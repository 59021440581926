import { TFunction } from 'i18next';
import fp from 'lodash/fp';
import { ObjectSchema } from 'yup';

import { IDevelopmentReportReviewSupportForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/ReviewSupport/helper/reviewSupportForm';
import { yup } from '@/core/utils/commonUtils';

export const reviewSupportValidatorBuilder = (
  translations: TFunction<'developmentReportReviewSupport', undefined>
): ObjectSchema<IDevelopmentReportReviewSupportForm> =>
  yup.object().shape({
    reviews: yup
      .array()
      .of(
        yup.object().shape({
          lfdNr: yup.number().required(),
          selectedReview: yup.string().required(),
          comment: yup.string().nullable().required(),
          goal: yup.string().nullable(),
          smartGoalId: yup.number().nullable(),
        })
      )
      .required(),
  });

export const reviewSupportValidator: ObjectSchema<IDevelopmentReportReviewSupportForm> = yup
  .object()
  .shape({
    reviews: yup
      .array()
      .of(
        yup.object().shape({
          lfdNr: yup.number().optional(),
          selectedReview: yup.string().when(['comment', 'goal'], {
            is: (comment: string | undefined | null, goal: string | undefined | null) =>
              !fp.isEmpty(comment) || !fp.isEmpty(goal),
            then: () => yup.string().required(),
            otherwise: () => yup.string().optional(),
          }),
          comment: yup.string().nullable().optional(),
          goal: yup.string().nullable(),
          smartGoalId: yup.number().nullable(),
        })
      )
      .required(),
  });
