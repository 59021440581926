import { Parameter } from '../enums/parametersEnum';

export const parametrsList = [
  Parameter.TableEvenBackground,
  Parameter.TableOddBackground,
  Parameter.SelfAssessmentEvenBackgroundColor,
  Parameter.SelfAssessmentOddBackgroundColor,
  Parameter.InternalDifferentiationItemEvenBackground,
  Parameter.InternalDifferentiationItemOddBackground,
  Parameter.VocationalTrainingAreaEvenItemBackgroundColor,
  Parameter.VocationalTrainingAreaOddItemBackgroundColor,
  Parameter.AdditionalPersonRequirementConfirmCreatingText,
  Parameter.AdditionalPersonRequirementResponsibleLeaderRequired,
  Parameter.PerformanceProfileNoAllocatedCatalogs,
  Parameter.StartedStatusTagBgColor,
  Parameter.StartedStatusTagTextColor,
  Parameter.CompleteStatusTagBgColor,
  Parameter.CompleteStatusTagTextColor,
  Parameter.ReadyForClosingStatusTagBgColor,
  Parameter.ReadyForClosingStatusTagTextColor,
  Parameter.ClosedStatusTagBgColor,
  Parameter.ClosedStatusTagTextColor,
  Parameter.IsMnTerminWirksamkeitPflicht,
  Parameter.GlobalNotificationDelay,
  Parameter.VocationalTrainingAreaFileNotFoundNotificationDelay,
  Parameter.StammdatenPersonUpdateDataNotificationDelay,
  Parameter.TableCopyPopupNotificationDelay,
  Parameter.QualificationDocumentationBlockedActionNotificationDelay,
  Parameter.MedikamentenverabreichungUpdateDataNotificationDelay,
  Parameter.ForderzyklusErrorNotificationDelay,
  Parameter.AlwaysShowPersonShortcutFunctions,
  Parameter.allowChangeBinnDiffInEP,
];
