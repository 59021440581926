import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { competenceAnalysisActions } from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/competenceAnalysisSlice';

interface ICompetenceAnalysisImportModalContent {
  personID?: number;
  handleCloseModal: () => void;
}

interface ICompetenceAnalysisImportModalForm {
  includeComments: boolean;
}

export const CompetenceAnalysisImportModalContent: React.FC<ICompetenceAnalysisImportModalContent> =
  ({ personID, handleCloseModal }) => {
    const { t: importModalTranslations } = useTranslation('competenceAnalysisImportModal');

    const form = useForm<ICompetenceAnalysisImportModalForm>({
      defaultValues: {
        includeComments: false,
      },
    });

    const dispatch = useAppDispatch();

    const handleImportCompetenceAnalysis = () => {
      if (personID) {
        dispatch(
          competenceAnalysisActions.fetchCompetenceAnalysisImport({
            personID: personID,
            includesComments: form.watch().includeComments,
          })
        );

        dispatch(competenceAnalysisActions.setCompetenceAnalysisIsImported(true));
      }

      handleCloseModal();
    };

    return (
      <div className={'flex flex-col min-w-[500px] max-w-[740px] min-h-[200px] gap-3'}>
        <Typography type={'H3'}>{importModalTranslations('header.label')}</Typography>
        <Typography className={'mt-4'}>{importModalTranslations('warning.label')}</Typography>
        <Typography>{importModalTranslations('question.label')}</Typography>
        <FormProvider {...form}>
          <CheckboxItem
            label={importModalTranslations('fields.includeComments.label')}
            fieldName={'includeComments'}
            labelPosition={'left'}
          />
        </FormProvider>
        <div className={'flex flex-row justify-center gap-4 mt-4'}>
          <Button buttonVariant={'Secondary'} onClick={handleCloseModal}>
            {importModalTranslations('buttons.no.label')}
          </Button>
          <Button onClick={handleImportCompetenceAnalysis}>
            {importModalTranslations('buttons.yes.label')}
          </Button>
        </div>
      </div>
    );
  };
