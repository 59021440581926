import { useEffect, useState } from 'react';

export const useDoubleClick = (onDoubleClick: () => void) => {
  const [clicked, setClicked] = useState(false);

  const handleDoubleClick = () => {
    if (!clicked) {
      setClicked(true);
      return;
    }
    onDoubleClick();
  };

  useEffect(() => {
    const timer = setTimeout(() => setClicked(false), 300);

    return () => clearTimeout(timer);
  }, [clicked]);

  return handleDoubleClick;
};
