import React from 'react';
import fp from 'lodash/fp';

import { useAppSelector } from '@/core/redux/hooks';
import { authSelectors } from '@/core/redux/slices/auth/selectors';
import { IPrivateImage } from '@/types/privateImage';

export const PrivateImage = React.memo(({ id, className, alt }: IPrivateImage) => {
  const token = useAppSelector(authSelectors.accessToken);
  let computedSrc = '';

  if (fp.isEmpty(token)) {
    return <div />;
  }

  if (id) {
    computedSrc = `${process.env.REACT_APP_DIRECTUS_ADDRESS}/assets/${id}?access_token=${token}`;
  }

  return id ? <img src={computedSrc} alt={alt} className={className} loading={'lazy'} /> : null;
});
