import { createSelector } from '@reduxjs/toolkit';

import { Parameter } from '@/core/enums/parametersEnum';
import { IParameterItem } from '@/core/redux/slices/parameters/parametersSlice';
import { intToColor } from '@/core/utils/commonUtils';

import { IRootState } from '../../types';

const getParameterColor = (parameterName: Parameter, parameters?: IParameterItem[]) => {
  if (!parameters) {
    return undefined;
  }

  const foundParameter = parameters.find((parameter) => parameter.name === parameterName);

  if (!foundParameter) {
    return undefined;
  }

  return intToColor(Number(foundParameter.value));
};

const selector = (state: IRootState) => state.parameters;
const parameters = createSelector([selector], (state) => state.parameters);

const internalDifferentiationItemOddColor = createSelector([selector], (state) =>
  getParameterColor(Parameter.InternalDifferentiationItemOddBackground, state.parameters)
);
const internalDifferentiationItemEvenColor = createSelector([selector], (state) =>
  getParameterColor(Parameter.InternalDifferentiationItemEvenBackground, state.parameters)
);

const vocationalTrainingAreaItemEvenColor = createSelector([selector], (state) =>
  getParameterColor(Parameter.VocationalTrainingAreaEvenItemBackgroundColor, state.parameters)
);
const vocationalTrainingAreaItemOddColor = createSelector([selector], (state) =>
  getParameterColor(Parameter.VocationalTrainingAreaOddItemBackgroundColor, state.parameters)
);

const selfAssessmentEvenBackgroundColor = createSelector([selector], (state) =>
  getParameterColor(Parameter.SelfAssessmentEvenBackgroundColor, state.parameters)
);
const selfAssessmentOddBackgroundColor = createSelector([selector], (state) =>
  getParameterColor(Parameter.SelfAssessmentOddBackgroundColor, state.parameters)
);

const additionalPersonRequirementConfirmCreatingText = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter: IParameterItem) =>
      parameter.name === Parameter.AdditionalPersonRequirementConfirmCreatingText
  );

  return foundParameter?.value;
});

const additionalPersonRequirementResponsibleRequired = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter: IParameterItem) =>
      parameter.name === Parameter.AdditionalPersonRequirementResponsibleLeaderRequired
  );

  return foundParameter?.value === '1';
});

const performanceProfileNoAllocatedCatalogs = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter: IParameterItem) =>
      parameter.name === Parameter.PerformanceProfileNoAllocatedCatalogs
  );

  return foundParameter?.value;
});

const isMnTerminWirksamkeitPflicht = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter) => parameter.name === Parameter.IsMnTerminWirksamkeitPflicht
  );

  return foundParameter?.value === '1';
});

const globalNotificationDelay = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter) => parameter.name === Parameter.GlobalNotificationDelay
  );

  return Number(foundParameter?.value);
});

const vocationalTrainingAreaFileNotFoundNotificationDelay = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter) => parameter.name === Parameter.VocationalTrainingAreaFileNotFoundNotificationDelay
  );

  return foundParameter?.value !== null ? Number(foundParameter?.value) : null;
});

const stammdatenPersonUpdateDataNotificationDelay = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter) => parameter.name === Parameter.StammdatenPersonUpdateDataNotificationDelay
  );

  return foundParameter?.value !== null ? Number(foundParameter?.value) : null;
});

const tableCopyPopupNotificationDelay = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter) => parameter.name === Parameter.TableCopyPopupNotificationDelay
  );

  return foundParameter?.value !== null ? Number(foundParameter?.value) : null;
});

const qualificationDocumentationBlockedActionNotificationDelay = createSelector(
  [selector],
  (state) => {
    const foundParameter = state.parameters.find(
      (parameter) =>
        parameter.name === Parameter.QualificationDocumentationBlockedActionNotificationDelay
    );

    return foundParameter?.value !== null ? Number(foundParameter?.value) : null;
  }
);

const medikamentenverabreichungUpdateDataNotificationDelay = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter) => parameter.name === Parameter.MedikamentenverabreichungUpdateDataNotificationDelay
  );

  return foundParameter?.value !== null ? Number(foundParameter?.value) : null;
});

const forderzyklusErrorNotificationDelay = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter) => parameter.name === Parameter.ForderzyklusErrorNotificationDelay
  );

  return foundParameter?.value !== null ? Number(foundParameter?.value) : null;
});

const isAlwaysShowPersonShortcutFunctions = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter) => parameter.name === Parameter.AlwaysShowPersonShortcutFunctions
  );

  return foundParameter?.value === '1';
});

const isAllowChangeBinnDiffInEP = createSelector([selector], (state) => {
  const foundParameter = state.parameters.find(
    (parameter: IParameterItem) => parameter.name === Parameter.allowChangeBinnDiffInEP
  );

  return foundParameter?.value === '1';
});

export const parametersSelectors = {
  parameters,
  internalDifferentiationItemOddColor,
  internalDifferentiationItemEvenColor,
  vocationalTrainingAreaItemEvenColor,
  vocationalTrainingAreaItemOddColor,
  selfAssessmentEvenBackgroundColor,
  selfAssessmentOddBackgroundColor,
  additionalPersonRequirementConfirmCreatingText,
  additionalPersonRequirementResponsibleRequired,
  performanceProfileNoAllocatedCatalogs,
  isMnTerminWirksamkeitPflicht,
  globalNotificationDelay,
  vocationalTrainingAreaFileNotFoundNotificationDelay,
  stammdatenPersonUpdateDataNotificationDelay,
  tableCopyPopupNotificationDelay,
  qualificationDocumentationBlockedActionNotificationDelay,
  medikamentenverabreichungUpdateDataNotificationDelay,
  forderzyklusErrorNotificationDelay,
  isAlwaysShowPersonShortcutFunctions,
  isAllowChangeBinnDiffInEP,
};
