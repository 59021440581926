import React, { useEffect, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { motion } from 'framer-motion';

import { Accordion, IAccordionHeader } from '@/components/Accordion/Accordion';
import { useBlockDeleteAdministrationLocationDialog } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementDialogs/useBlockDeleteAdministrationLocationDialog';
import { useConfirmDeleteAdministrationLocationDialog } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementDialogs/useConfirmDeleteAdministrationLocationDialog';
import { useRenameAdministrationLocationDialog } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementDialogs/useRenameAdministrationLocationDialog';
import { AdministrationLocationManagementLocationItem } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementLocationList/AdministrationLocationManagementLocationItem';
import { AdministrationLocationManagementLocationItemOverlay } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementLocationList/AdministrationLocationManagementLocationItemOverlay';
import { ArrowIcon } from '@/components/Icons/ArrowIcon';
import { Typography } from '@/components/Typography/Typography';
import { IAdministrationLocation } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { colorTypes } from '@/styles/types';

export interface IAdministrationLocationManagementLocationGroup {
  layer: number;
  location: IAdministrationLocation;
}

export const AdministrationLocationManagementLocationGroup: React.FC<IAdministrationLocationManagementLocationGroup> =
  ({ location, layer }) => {
    const { setNodeRef, isOver } = useDroppable({ id: location.id });

    const [isHovered, setIsHovered] = useState<boolean>(false);

    const {
      renderDialogContainer: renderConfirmDeleteDialog,
      openDialog: openConfirmDeleteDialog,
    } = useConfirmDeleteAdministrationLocationDialog(location);
    const { renderDialogContainer: renderBlockDeleteDialog, openDialog: openBlockDeleteDialog } =
      useBlockDeleteAdministrationLocationDialog(location);
    const { renderDialogContainer: renderRenameDialog, openDialog: openRenameDialog } =
      useRenameAdministrationLocationDialog(location);

    const AccordionHeader: React.FC<IAccordionHeader> = ({ setIsExpanded, isExpanded }) => {
      const handleExpand = (event: React.MouseEvent) => {
        event.preventDefault();

        setIsExpanded?.(!isExpanded);
      };

      useEffect(() => {
        if (isOver) {
          setIsExpanded?.(true);
        }
      }, [isOver]);

      return (
        <div
          className={`relative flex flex-row
          bg-${colorTypes.HoverLight} hover:bg-${colorTypes.Hover}
          border-b border-b-${colorTypes.StrokeLight}
          pr-[30px] py-[10px] gap-4
          cursor-pointer`}
          style={{ paddingLeft: `${30 + layer * 34}px` }}
          onClick={handleExpand}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <motion.div
            className={`cursor-pointer text-${colorTypes.Primary} h-fit`}
            initial={{ rotate: isExpanded ? 180 : 0 }}
            animate={{ rotate: isExpanded ? 180 : 0 }}
          >
            <ArrowIcon />
          </motion.div>
          <div className={`flex flex-col gap-1`}>
            <Typography type={'H3'}>{location.name}</Typography>
            <div className={'flex flex-row gap-4'}>
              <Typography
                type={'14-R'}
                color={'Secondary'}
              >{`${location.locationsCount} Standorte`}</Typography>
              <Typography
                type={'14-R'}
                color={'Secondary'}
              >{`${location.departmentsCount} Abteilungen`}</Typography>
            </div>
          </div>
          <AdministrationLocationManagementLocationItemOverlay
            isActive={isHovered}
            isSelected={false}
            location={location}
            setIsHovered={setIsHovered}
            openBlockDeleteDialog={openBlockDeleteDialog}
            openConfirmDeleteDialog={openConfirmDeleteDialog}
            openRenameDialog={openRenameDialog}
          />
        </div>
      );
    };

    const items = (location.locations || []).filter(
      (_location) => _location.locations && _location.locations?.length < 1
    );
    const groups = (location.locations || []).filter(
      (_location) => _location.locations && _location.locations?.length > 1
    );

    return (
      <div ref={setNodeRef}>
        <Accordion customHeader={AccordionHeader}>
          {items.map((item) => (
            <AdministrationLocationManagementLocationItem
              layer={layer + 1}
              location={item}
              key={item.id}
              openBlockDeleteDialog={openBlockDeleteDialog}
              openConfirmDeleteDialog={openConfirmDeleteDialog}
              openRenameDialog={openRenameDialog}
            />
          ))}
          {groups.length > 0 &&
            groups.map((group) => (
              <AdministrationLocationManagementLocationGroup
                layer={layer + 1}
                location={group}
                key={group.id}
              />
            ))}
        </Accordion>
        {renderConfirmDeleteDialog()}
        {renderBlockDeleteDialog()}
        {renderRenameDialog()}
      </div>
    );
  };
