import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const TableHeader: React.FC = () => {
  const { t: questionnaireTranslations } = useTranslation('questionnaire', {
    keyPrefix: 'table.header',
  });

  return (
    <div
      className={`grid grid-cols-12 items-center border-y border-${colorTypes.Stroke} px-[30px] py-3`}
    >
      <Typography color={'Secondary'} className={'col-span-7'}>
        {questionnaireTranslations('catalogType.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {questionnaireTranslations('mark.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-4'}>
        {questionnaireTranslations('description.label')}
      </Typography>
    </div>
  );
};
