import React from 'react';

export const RadarChartIcon: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32px'
    height='32px'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      d='M4.02 5.97C2.75 7.65 2 9.74 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      opacity='0.34'
      d='M5 12C5 15.87 8.13 19 12 19C15.87 19 19 15.87 19 12C19 8.13 15.87 5 12 5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      opacity='0.34'
      d='M12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
