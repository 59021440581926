import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/Button/Button';
import { IWorkingTimePredefinedVariantsForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/WorkingTimePredefinedVariantsModalContent';
import { Typography } from '@/components/Typography/Typography';
import { IWorkingTimePredefinedVariant } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/workingTimeMobilitModalsSlice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IPredefinedVariantsListItem extends IWorkingTimePredefinedVariant {
  bgColor?: string;
  onClick?: (id: number) => void;
}

export const PredefinedVariantsListItem: React.FC<IPredefinedVariantsListItem> = ({
  id,
  name,
  bgColor,
  workingWeek,
  onClick,
}) => {
  const handleClick = () => {
    onClick?.(id);
  };

  const { watch } = useFormContext<IWorkingTimePredefinedVariantsForm>();
  const isSelected = id === watch('selectedID');

  return (
    <Button
      buttonVariant={'Transparent'}
      buttonStyle={'Text'}
      className={twMerge(
        'grid grid-cols-6 gap-3 py-2 border-l border-l-transparent',
        isSelected && `bg-${colorTypes.Hover} border-l border-${colorTypes.Blue}`
      )}
      onClick={handleClick}
      style={{ backgroundColor: bgColor }}
    >
      <Typography className={'col-span-3 pl-3'}>{name}</Typography>
      <Typography>{workingWeek.sumNet?.toFixed(2)}</Typography>
      <Typography>{workingWeek.sumGross?.toFixed(2)}</Typography>
      <Typography>{workingWeek.sumPauseDuration?.toFixed(2)}</Typography>
    </Button>
  );
};
