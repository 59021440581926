import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import fp from 'lodash/fp';

import { Avatar } from '@/components/Avatar/Avatar';
import { Button } from '@/components/Button/Button';
import { Dropdown } from '@/components/Dropdown/Dropdown';
import { LogoutIcon } from '@/components/Icons/LogoutIcon';
import { SwitchIcon } from '@/components/Icons/SwitchIcon';
import { Typography } from '@/components/Typography/Typography';
import { AUTH_URL } from '@/core/constants/urls';
import { Language } from '@/core/enums/languageEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { authActions } from '@/core/redux/slices/auth/authSlice';
import { authSelectors } from '@/core/redux/slices/auth/selectors';
import { userSettingsSelectors } from '@/core/redux/slices/userSettings/selectors';
import { userSettingsActions } from '@/core/redux/slices/userSettings/userSettingsSlice';
import { generateName } from '@/core/utils/commonUtils';
import { useCookies } from '@/core/utils/cookiesUtil';
import { useLogoutMutation } from '@/services/graphql/system/graphql';

import styles from '../styles.module.scss';

export const UserCard: React.FC = () => {
  const { t, i18n } = useTranslation('tms', { keyPrefix: 'ui.button' });
  const [expanded, setExpanded] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const user = useAppSelector(authSelectors.profileEntity);
  const locale = useAppSelector(userSettingsSelectors.userSettingsLanguage);

  const navigate = useNavigate();

  const [logoutMutate] = useLogoutMutation({
    errorPolicy: 'ignore',
    onCompleted: () => navigate(AUTH_URL),
    onError: () => navigate(AUTH_URL),
  });
  const cookies = useCookies();

  const logout = () => {
    logoutMutate({
      variables: {
        refreshToken: fp.toString(localStorage.getItem('refreshToken')),
      },
    }).then();
    localStorage.removeItem('refreshToken');
    cookies.remove('accessToken');
    dispatch(authActions.setUser(null));
    dispatch(authActions.setAToken(null));
  };

  const handleSwitchLanguage = () => {
    const newLocale = locale === Language.DEUTSCH ? Language.ENGLISH : Language.DEUTSCH;
    dispatch(userSettingsActions.updateUserSettingsLanguage(newLocale));
    i18n.changeLanguage(newLocale);
    setExpanded(false);
  };

  const renderDropDownContent = useCallback(
    () => (
      <div className={'bg-white p-3 flex flex-col items-start justify-start'}>
        <Button
          onClick={handleSwitchLanguage}
          className={'w-full flex flex-row justify-between gap-7 outline-0'}
          buttonVariant={'UserSelect'}
          buttonStyle={'WithIcon'}
        >
          <SwitchIcon />
          {t('switchLanguage')}
        </Button>
        <Button
          onClick={logout}
          className={'w-full flex flex-row justify-between gap-7 outline-0'}
          buttonVariant={'UserSelect'}
          buttonStyle={'WithIcon'}
        >
          <LogoutIcon />
          {t('logout')}
        </Button>
      </div>
    ),
    [locale, user]
  );

  return (
    <div className={clsx(styles.userCard, 'flex items-center')}>
      <div className='flex flex-col items-end'>
        <Typography type={'16-R'}>
          {user ? generateName({ name: user.first_name, surname: user.last_name }) : 'Name Surname'}
        </Typography>
        <Typography type={'14-R'} color={'Secondary'}>
          {user && user.title ? user.title : 'Spezialist'}
        </Typography>
      </div>
      <Dropdown
        position={'bottom-end'}
        contentOffset={{ crossAxis: 45, mainAxis: 18 }}
        content={renderDropDownContent()}
        open={expanded}
        setOpen={setExpanded}
      >
        <Avatar imageUrl={user ? user.avatar.filename_disk : ''} />
      </Dropdown>
    </div>
  );
};
