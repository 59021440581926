import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../types';

const selector = (state: IRootState) => state.administrationPage;
const activeTabId = createSelector([selector], (state) => state.activeTabId);
const functions = createSelector([selector], (state) => state.functions);
const functionsLock = createSelector([selector], (state) => state.status);
const tabs = createSelector([selector], (state) => state.tabs);
const updatePinLock = createSelector([selector], (state) => state.updatePinLock);

export const administrationPageSelectors = {
  activeTabId,
  functions,
  functionsLock,
  tabs,
  updatePinLock,
};
