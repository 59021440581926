import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

// interfaces

export interface IUnit {
  name?: string | null;
  isAdded: boolean;
  id: number;
}

export interface IUser {
  name?: string | null;
  surname?: string | null;
  id: number;
}

// payloads

export interface IAvailableUnitsFetchPayload {
  userID: number;
  isOnlyAdded: boolean;
}

export interface IUnitAddPayload {
  userID: number;
  organizationUnitID: number;
}

export interface IUnitDeletePayload {
  userID: number;
  organizationUnitID: number;
}

export interface IUsersByOrganizationUnitFetchPayload {
  organizationUnitID: number;
  isAdded: boolean;
}

// state

export interface ISpecialistOrganizationUnitsState {
  // departments
  availableUnits: IUnit[];
  availableUnitsLock: LoadingStatus;
  updateUnitsLock: LoadingStatus;
  // users
  organizationUnitUsers: IUser[];
  organizationUnitUsersLock: LoadingStatus;
}

const initialState: ISpecialistOrganizationUnitsState = {
  availableUnits: [],
  organizationUnitUsers: [],
  // locks
  availableUnitsLock: LoadingStatus.NEVER,
  updateUnitsLock: LoadingStatus.NEVER,
  organizationUnitUsersLock: LoadingStatus.NEVER,
};

export const specialistOrganizationUnitsSlice = createSlice({
  name: 'specialistOrganizationUnits',
  initialState,
  reducers: {
    // smart goals
    fetchAvailableUnits: (state, action: PayloadAction<IAvailableUnitsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAvailableUnits: (state, action: PayloadAction<IUnit[]>) => ({
      ...state,
      availableUnits: action.payload,
    }),
    setAvailableUnitsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      availableUnitsLock: action.payload,
    }),
    // create
    addUserUnit: (state, action: PayloadAction<IUnitAddPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    // delete
    deleteUserUnit: (state, action: PayloadAction<IUnitDeletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    // update lock
    setUpdateUserUnitsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateUnitsLock: action.payload,
    }),
    // department users
    fetchOrganizationUnitUsers: (state, action: PayloadAction<IUsersByOrganizationUnitFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setOrganizationUnitUsers: (state, action: PayloadAction<IUser[]>) => ({
      ...state,
      organizationUnitUsers: action.payload,
    }),
    setOrganizationUnitUsersLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      organizationUnitUsersLock: action.payload,
    }),
  },
});

export const specialistOrganizationUnitsReducer = specialistOrganizationUnitsSlice.reducer;
export const specialistOrganizationUnitsActions = specialistOrganizationUnitsSlice.actions;
