import { FormItem } from '@/components/FormRender/FormRender';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';

export const generalDataFirstColumn: FormItem[] = [
  {
    name: 'salutation',
    label: 'fields.salutation.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.SALUTATION,
  },
  {
    name: 'name',
    label: 'fields.name.label',
    type: 'input',
  },
  {
    name: 'surname',
    label: 'fields.surname.label',
    type: 'input',
  },
  {
    name: 'maritalStatus',
    label: 'fields.maritalStatus.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.MARITAL_STATUS,
  },
  {
    name: 'gender',
    label: 'fields.gender.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.GENDER,
  },
  {
    name: 'supervisor',
    label: 'fields.supervisor.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.SUPERVISOR,
  },
  {
    name: 'housingForm',
    label: 'fields.housingForm.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.HOUSING_FORM,
  },
  {
    name: 'educationalSupporter',
    label: 'fields.educationalSupporter.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.EDUCATIONAL_SUPPORTER,
  },
  {
    name: 'socialServiceWorker',
    label: 'fields.socialServiceWorker.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.SOCIAL_SERVICE_WORKER,
  },
  {
    name: 'manager',
    label: 'fields.manager.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.MANAGER,
  },
];

export const generalDataSecondColumn: FormItem[] = [
  {
    name: 'sozVersNr',
    label: 'fields.sozVersNr.label',
    type: 'input',
  },
  {
    name: 'birthDate',
    label: 'fields.birthDate.label',
    type: 'datepicker',
    className: 'w-[200px]',
  },
  {
    name: 'birthPlace',
    label: 'fields.birthPlace.label',
    type: 'input',
  },
  {
    name: 'kidsNumber',
    label: 'fields.kidsNumber.label',
    type: 'input',
    className: 'w-[200px]',
  },
  {
    name: 'nationality',
    label: 'fields.nationality.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.NATIONALITY,
  },
  {
    name: 'confession',
    label: 'fields.confession.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.CONFESSION,
  },
  {
    name: 'healthInsurance',
    label: 'fields.healthInsurance.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.HEALTH_INSURANCE,
  },
  {
    name: 'jobcoach',
    label: 'fields.jobcoach.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.JOBCOACH,
  },
  {
    name: 'groupManager',
    label: 'fields.groupManager.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.GROUP_MANAGER,
  },
  {
    name: '',
    label: ' ',
    type: 'checkbox_group',
    layout: 'vertical',
    options: [
      { id: 'isPhotoConsent', name: 'fields.isPhotoConsent.label' },
      {
        id: 'isAdmissionReserved',
        name: 'fields.isAdmissionReserved.label',
      },
    ],
  },
];

export const educationalSupporterForm: FormItem[] = [
  {
    name: 'educationalSupporter',
    label: 'fields.educationalSupporter.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.EDUCATIONAL_SUPPORTER,
  },
  {
    name: 'costBearer',
    label: 'fields.costBearer.label',
    type: 'input',
  },
  {
    name: 'foreignLegalStatus',
    label: 'fields.foreignLegalStatus.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.FOREIGN_LEGAL_STATUS,
  },
  {
    name: 'inactiveTimestamp',
    label: 'fields.inactiveTimestamp.label',
    type: 'datepicker',
  },
];
