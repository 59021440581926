import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.fundingCycleModals.smartGoalsModals;

const displaySmartGoalModal = createSelector([selector], (state) => state.displaySmartGoalModal);
const addMeasuersModal = createSelector([selector], (state) => state.addMeasuersModal);
const confirmUnpinActivityModal = createSelector([selector], (state) => state.confirmUnpinActivityModal);
const confirmDeleteSmartGoalModal = createSelector([selector], (state) => state.confirmDeleteSmartGoalModal);
const confirmDeleteMeasureModal = createSelector([selector], (state) => state.confirmDeleteMeasureModal);

export const smartGoalsModalsSelectors = {
    displaySmartGoalModal,
    addMeasuersModal,
    confirmUnpinActivityModal,
    confirmDeleteSmartGoalModal,
    confirmDeleteMeasureModal
};
