import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useImperativeHandle,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import fp from 'lodash/fp';

import { InternshipsDocumentation } from '@/components/forms/QualificationDocumentation/InternshipsDocumentation/InternshipsDocumentation';
import { QualificationObjectives } from '@/components/forms/QualificationDocumentation/QualificationObjectives/QualificationObjectives';
import { useSelectedPersonForm } from '@/components/functions/IntegrationPlan/hooks/useSelectedPersonForm';
import { IntegrationPlanFormActions } from '@/components/functions/IntegrationPlan/IntegrationPlan';
import { IIntegrationPlanFormProps } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsContainer/IntegrationPlanFormsContainer';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  IQualificationDocumentation,
  qualificationDocumentationActions,
} from '@/core/redux/slices/functions/integrationPlan/qualificationDocumentation/qualificationDocumentationSlice';
import { qualificationDocumentationSelectors } from '@/core/redux/slices/functions/integrationPlan/qualificationDocumentation/selectors';

export const QualificationDocumentation: ForwardRefExoticComponent<
  Omit<IIntegrationPlanFormProps, 'ref'> & RefAttributes<IntegrationPlanFormActions>
> = forwardRef((props, ref) => {
  const { integrationPlanID, personID } = props;

  const initialValues = useAppSelector(
    qualificationDocumentationSelectors.qualificationDocumentation
  );

  const form = useForm<IQualificationDocumentation>();

  useSelectedPersonForm({ form, integrationPlanID, initialValues });

  const dispatch = useAppDispatch();

  const onBeforeExitCheck = () => {
    const currentValues = form.watch();

    if (fp.isEqual(JSON.stringify(currentValues), JSON.stringify(initialValues))) {
      return true;
    }

    if (!personID || integrationPlanID === null) {
      return true;
    }

    dispatch(
      qualificationDocumentationActions.updateQualificationDocumentation({
        personID: personID,
        integrationPlanID: integrationPlanID,
        qualificationDocumentation: currentValues,
      })
    );

    dispatch(qualificationDocumentationActions.setQualificationDocumentation(null));

    return true;
  };

  useImperativeHandle(ref, () => ({
    onBeforeExitCheck: onBeforeExitCheck,
  }));

  useEffect(() => {
    if (!integrationPlanID || !personID) {
      return;
    }

    dispatch(
      qualificationDocumentationActions.fetchQualificationDocumentation({
        integrationPlanID: integrationPlanID,
      })
    );
  }, [integrationPlanID, personID]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(() => {})}>
        <QualificationObjectives
          qualificationsDocumentations={initialValues?.qualificationsDocumentations}
          integrationPlanID={integrationPlanID}
          personID={personID}
        />
        <InternshipsDocumentation internships={initialValues?.internships} />
      </form>
    </FormProvider>
  );
});
