import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IMedicationModalPayload {
  personID: number;
  medicationID: number;
}

export interface IModal {
  isRender: boolean;
  payload: IMedicationModalPayload | null;
}

export interface IMedicationModalState {
  displayMedicationModal: IModal;
  stopAdministratingModal: IModal;
}

const initialState: IMedicationModalState = {
  displayMedicationModal: {
    isRender: false,
    payload: null,
  },
  stopAdministratingModal: {
    isRender: false,
    payload: null,
  },
};

export const medicationModalsSlice = createSlice({
  name: 'medicationModals',
  initialState,
  reducers: {
    //Display medication modal
    openDisplayMedicationModal: (state, action: PayloadAction<IMedicationModalPayload>) => ({
      ...state,
      displayMedicationModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeDisplayMedicationModal: (state) => ({
      ...state,
      displayMedicationModal: {
        isRender: false,
        payload: null,
      },
    }),
    //Delete medication modal
    openStopAdministratingModal: (state, action: PayloadAction<IMedicationModalPayload>) => ({
      ...state,
      stopAdministratingModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeStopAdministratingModal: (state) => ({
      ...state,
      stopAdministratingModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const medicationModalsReducer = medicationModalsSlice.reducer;
export const medicationModalsActions = medicationModalsSlice.actions;
