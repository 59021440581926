import { useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { generateName } from '@/core/utils/commonUtils';

interface IUseSelectedPerson {
  selectedPersonID: number | undefined;
  selectedPersonName: string | undefined;
}

export function useSelectedPerson(): IUseSelectedPerson {
  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const selectedPersonID = selectedPerson?.id;
  const selectedPersonName = generateName(selectedPerson);

  return { selectedPersonID, selectedPersonName };
}
