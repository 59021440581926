import React from 'react';

import { IIcon } from '@/types/icon';

export const FolderIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g clipPath='url(#clip0_5537_2434)'>
      <path
        d='M21 19.2222C21 19.6937 20.8104 20.1459 20.4728 20.4793C20.1352 20.8127 19.6774 21 19.2 21H4.8C4.32261 21 3.86477 20.8127 3.52721 20.4793C3.18964 20.1459 3 19.6937 3 19.2222V6.77778C3 6.30628 3.18964 5.8541 3.52721 5.5207C3.86477 5.1873 4.32261 5 4.8 5H9.3L11.1 7.66667H19.2C19.6774 7.66667 20.1352 7.85397 20.4728 8.18737C20.8104 8.52076 21 8.97295 21 9.44444V19.2222Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_5537_2434'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
