import React from 'react';

import { Button } from '@/components/Button/Button';
import { IActivityPlanningWizardView } from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardContainer/ActivityPlanningWizardContainer';
import { useActivityPlaningWizardSelector } from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardContext/ActivityPlanningContext';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

interface IActivityPlanningWizardNavigation {
  views: IActivityPlanningWizardView[];
}

export const ActivityPlanningWizardNavigation: React.FC<IActivityPlanningWizardNavigation> = ({
  views,
}) => {
  const [step, setStep] = useActivityPlaningWizardSelector((store) => store.wizardStep);

  const handleNavigate = (index: number) => {
    setStep({ wizardStep: index });
  };

  return (
    <div className={'flex flex-row gap-2'}>
      {views.map((view, index) => (
        <div className={'flex flex-row gap-2 items-center'} key={index}>
          <Button
            buttonStyle={'Text'}
            buttonVariant={'BreadCrumbs'}
            className={step === index ? `text-${colorTypes.Blue}` : ''}
            onClick={() => handleNavigate(index)}
          >
            {view.title}
          </Button>
          {index < views.length - 1 && (
            <Typography type={'16-M'} color={'Disabled'}>
              /
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};
