import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { useAdministrationChecklistSelector } from '@/components/administartionFunctions/checklist/AdministrationChecklistContext/AdministrationChecklistContext';
import { useAdministrationCheckpointForm } from '@/components/administartionFunctions/checklist/AdministrationCheckpointList/hooks/useAdministrationCheckpointForm';
import { Button } from '@/components/Button/Button';
import FormRender from '@/components/FormRender/FormRender';
import { useAppDispatch } from '@/core/redux/hooks';
import { administrationChecklistActions } from '@/core/redux/slices/administrationFunctions/checkList/slice';

export const AdministrationCheckpointForm: React.FC = () => {
  const { t: administrationChecklistTranslations } = useTranslation('administrationChecklist');

  const { form, formFields, defaultValues } = useAdministrationCheckpointForm();

  const [selectedCheckpoint, setState] = useAdministrationChecklistSelector(
    (state) => state.selectedCheckpoint
  );
  const [selectedChecklist] = useAdministrationChecklistSelector(
    (state) => state.selectedChecklist
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedCheckpoint) {
      form.reset(defaultValues);
      return;
    }

    form.reset({
      correspondingDocumentID: selectedCheckpoint.correspondingDocument?.id,
      days: selectedCheckpoint.days,
      infoDocumentName: {
        fileName: selectedCheckpoint.infoDocument || undefined,
      },
      name: selectedCheckpoint.name ?? '',
      validFrom: selectedCheckpoint.validFrom,
      disabilityTypeID: selectedCheckpoint.disabilityTypeID,
    });
  }, [selectedCheckpoint]);

  const handleClickCreateCheckpoint = () => {
    form.reset(defaultValues);
    setState({ selectedCheckpoint: null });
  };

  const handleSubmit = () => {
    form.trigger().then((isValid) => {
      if (!isValid) {
        return;
      }

      const currentValues = fp.cloneDeep(form.watch());

      if (!selectedCheckpoint && selectedChecklist) {
        dispatch(
          administrationChecklistActions.createCheckpoint({
            checklistID: selectedChecklist.id,
            formValues: currentValues,
          })
        );

        form.reset(defaultValues);
        return;
      }

      if (selectedCheckpoint && selectedChecklist) {
        dispatch(
          administrationChecklistActions.updateCheckpoint({
            checkpointID: selectedCheckpoint.id,
            formValues: currentValues,
          })
        );

        form.reset(currentValues);
      }
    });
  };

  return selectedChecklist ? (
    <FormProvider {...form}>
      <FormRender list={formFields} containerClassName={'shrink-0'} className={'gap-y-2'} />
      {selectedCheckpoint ? (
        form.formState.isDirty ? (
          <div className={'flex flex-row gap-2 justify-end'}>
            <Button onClick={handleSubmit}>
              {administrationChecklistTranslations('buttons.updateCheckpoint.label')}
            </Button>
            <Button onClick={() => form.reset()}>
              {administrationChecklistTranslations('buttons.cancel.label')}
            </Button>
          </div>
        ) : (
          <Button onClick={handleClickCreateCheckpoint}>
            {administrationChecklistTranslations('buttons.createCheckpoint.label')}
          </Button>
        )
      ) : (
        <div className={'flex flex-row gap-2 justify-end'}>
          <Button onClick={handleSubmit} disabled={!form.formState.isDirty}>
            Save
          </Button>
          <Button onClick={() => form.reset(defaultValues)}>
            {administrationChecklistTranslations('buttons.cancel.label')}
          </Button>
        </div>
      )}
    </FormProvider>
  ) : null;
};
