import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IAppointmentDetailsForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/hooks/useAppointmentDetailsForm';
import { useSchemaContext } from '@/components/SchemaContext/SchemaContext';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';
import { IAppointmentDetails } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';

const DEFAULT_LOCATION_ID = String(-1);
interface IAppointmentDetailsLocationSelect {
  appointmentDetails: IAppointmentDetails | null;
}

const FIELD_NAME = 'locationID';

export const AppointmentDetailsLocationSelect: React.FC<IAppointmentDetailsLocationSelect> = ({
  appointmentDetails,
}) => {
  const { t: appointmentDetailTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentDetails',
  });

  const { setValue, control } = useFormContext<IAppointmentDetailsForm>();

  const locationValues: ISelectItem[] = [];

  const dropdownItems = useAppSelector(dropdownItemsSelectors.dropdownItems);

  if (dropdownItems && dropdownItems[DropdownItemsByFetch.MEASURE_LOCATION]) {
    dropdownItems[DropdownItemsByFetch.MEASURE_LOCATION].forEach((dropdownItem) => {
      const newItem: ISelectItem = {
        id: String(dropdownItem.id),
        component: dropdownItem.value,
      };

      locationValues.push(newItem);
    });

    if (appointmentDetails && appointmentDetails.location) {
      const defaultValue: ISelectItem = {
        id: DEFAULT_LOCATION_ID,
        component: appointmentDetails.location,
      };

      locationValues.push(defaultValue);
    }
  }

  const handleChange = (id: string) => {
    if (!dropdownItems || !dropdownItems[DropdownItemsByFetch.MEASURE_LOCATION]) {
      return;
    }

    const foundElement = dropdownItems[DropdownItemsByFetch.MEASURE_LOCATION].find(
      (location) => location.id === Number(id)
    );

    if (!foundElement) {
      return;
    }

    setValue(FIELD_NAME, foundElement.id, { shouldDirty: true });
    setValue('locationName', foundElement.value, { shouldDirty: true });
  };

  const { isRequiredField } = useSchemaContext(FIELD_NAME);

  return (
    <SelectForm
      control={control.register('locationID')}
      label={appointmentDetailTranslations('fields.location.label')}
      isRequired={isRequiredField}
      values={locationValues}
      initialValueID={DEFAULT_LOCATION_ID}
      onChange={handleChange}
    />
  );
};
