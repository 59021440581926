import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@/components/Input/Input';
import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';

const SEARCH_FIELD_NAME = 'search_person';
export const PersonSearch: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.label' });
  const [, setPersonSearchValue] = usePersonPageStoreSelector((store) => store.personsSearchValue);

  const form = useForm({ defaultValues: { [SEARCH_FIELD_NAME]: '' } });
  const searchValue = form.watch(SEARCH_FIELD_NAME);

  useEffect(() => {
    setPersonSearchValue({ personsSearchValue: searchValue });
  }, [searchValue, setPersonSearchValue]);

  return (
    <FormProvider {...form}>
      <Input
        inputVariant='Search'
        control={form.control.register(SEARCH_FIELD_NAME)}
        placeholder={t('searchInPersons')}
        containerClassName={'w-full mt-[40px] pr-[30px]'}
      />
    </FormProvider>
  );
};
