import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { AddNewLogbook } from '@/components/forms/AddNewLogbook/AddNewLogbook';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { logbookActions } from '@/core/redux/slices/logbook/logbookSlice';
import { logbookSelectors } from '@/core/redux/slices/logbook/selectors';
import { functionsPageModalsActions } from '@/core/redux/slices/modalsSlice/functionsPage/functionsPageModalsSlice';
import { logbookModalsActions } from '@/core/redux/slices/modalsSlice/logbook/logbookModalSlice';
import { logbookModalsSelectors } from '@/core/redux/slices/modalsSlice/logbook/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

interface IAddLogbookItemForm {
  categoryID: number | undefined;
  note: string | undefined;
}

const ModalContent: React.FC = () => {
  const dispatch = useAppDispatch();

  const { t: logbookAddModalTranslations } = useTranslation('logbookAddModal');

  const createLogbookItemLock = useAppSelector(logbookSelectors.createLogbookItemLock);
  const personID = useAppSelector(functionPageSelectors.personID);

  const form = useForm<IAddLogbookItemForm>();

  const handleExit = () => {
    dispatch(functionsPageModalsActions.closePersonInfoModal());
    dispatch(logbookModalsActions.closeNewLogbookEntryModal());
  };

  const handleSubmit = (data: IAddLogbookItemForm) => {
    if (!personID || !data['categoryID'] || !data['note']) {
      return;
    }

    dispatch(
      logbookActions.createLogbookItem({
        personID: personID,
        categoryID: data['categoryID'],
        note: data['note'],
      })
    );
  };

  // const openLogbookList = () => {
  //   dispatch(logbookModalsActions.openLogbookListModal());
  // };

  const renderFooter = (
    <div className='flex gap-4'>
      <Button onClick={form.handleSubmit(handleSubmit)}>
        {logbookAddModalTranslations('buttons.add.label')}
      </Button>
      <Button
        type='button'
        buttonVariant='Secondary'
        onClick={() => dispatch(logbookModalsActions.closeNewLogbookEntryModal())}
      >
        {logbookAddModalTranslations('buttons.cancel.label')}
      </Button>
    </div>
  );

  useEffect(() => {
    if (createLogbookItemLock === LoadingStatus.LOADED) {
      dispatch(logbookModalsActions.closeNewLogbookEntryModal());
    }
  }, [createLogbookItemLock]);

  useEffect(() => {
    return () => {
      dispatch(logbookActions.setCreateLogbookItemLock(LoadingStatus.NEVER));
    };
  }, []);

  return (
    <Sidebar
      onExit={handleExit}
      footer={renderFooter}
      position={'right'}
      className='w-[716px] p-0'
      isNested
      onBackTo={() => dispatch(logbookModalsActions.closeNewLogbookEntryModal())}
      title={logbookAddModalTranslations('title.label')}
    >
      <div className='p-6 flex flex-col gap-5'>
        <FormProvider {...form}>
          <AddNewLogbook />
        </FormProvider>
        {/* <div className='flex gap-4'>
          <Button onClick={openLogbookList}>
            {logbookAddModalTranslations('buttons.openList.label')}
          </Button>
        </div> */}
      </div>
    </Sidebar>
  );
};

export const LogbookAddModal: React.FC = () => {
  const isOpened = useAppSelector(logbookModalsSelectors.newLogbookEntryModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
