export const timeMask = (previousValue: string, newValue: string): string => {
  const digits = newValue.replace(/\D/g, '');

  let hours = '';
  let minutes = '';

  if (digits.length >= 2) {
    hours = digits.substring(0, 2);
    minutes = digits.substring(2);
  } else {
    hours = digits;
  }

  const hoursNumber = parseInt(hours, 10);

  if (hoursNumber > 24) hours = '24';
  if (hoursNumber < 0) hours = '00';
  if (hours.length === 1 && hoursNumber > 2) hours = '0' + hoursNumber;

  if (hoursNumber === 24) {
    minutes = '00';
  } else {
    if (minutes.length > 0) {
      const minutesNumber = parseInt(minutes, 10);
      if (minutesNumber > 59) minutes = '59';
      if (minutesNumber < 0) minutes = '00';
    }
    if (minutes.length > 2) minutes = minutes.substring(0, 2);
  }

  let formatted = hours;
  if (minutes.length > 0) formatted += ':' + minutes;

  return formatted;
};
