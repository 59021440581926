import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.functions.activityPlanning.appointmentParticipantsList;

const appointmentParticipantsList = createSelector([selector], (state) => state.participantsList);
const appointmentParticipantsListLock = createSelector(
  [selector],
  (state) => state.participantsListLock
);

export const activityPlaningAppointmentParticipantsListSelectors = {
  appointmentParticipantsList,
  appointmentParticipantsListLock,
};
