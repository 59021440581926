import { TFunction } from 'i18next';
import { ObjectSchema } from 'yup';

import { IPersonRequirementTimeListForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/hooks/usePersonRequirementTimeListForm';
import { yup } from '@/core/utils/commonUtils';

const personRequirementTimeGroupItemFormSchema = (
  translations: TFunction<'personRequirementTimeListModal', undefined>
) =>
  yup.object().shape({
    id: yup.number().required(),
    value: yup.number().nullable(),
    comment: yup.string().nullable(),
    minutesPerDay: yup.number().nullable(),
    isInGroup: yup.boolean().nullable(),
    cycleID: yup
      .number()
      .nullable()
      .when('value', {
        is: (val: number | null) => val !== null && val !== undefined,
        then: () => yup.number().required(translations('table.header.cycle.errors.required.label')),
      }),
  });

const personRequirementTimeGroupFormSchema = (
  translations: TFunction<'personRequirementTimeListModal', undefined>
) =>
  yup.object().shape({
    sum: yup.number().nullable(),
    items: yup.array().of(personRequirementTimeGroupItemFormSchema(translations)).required(),
  });

export const personRequirementTimeListFormSchema = (
  translations: TFunction<'personRequirementTimeListModal', undefined>
): ObjectSchema<IPersonRequirementTimeListForm> =>
  yup.object().shape({
    isOnlyActive: yup.boolean().optional(),
    sum: yup.number().optional(),
    personRequirementTimeListGroups: yup
      .array()
      .of(personRequirementTimeGroupFormSchema(translations))
      .required(),
  });
