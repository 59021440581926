import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { DashboardDnD } from '@/components/views/common/Dashboard/DashboardDnD/DashboardDnD';
import { useAppDispatch } from '@/core/redux/hooks';
import { widgetsModalsActions } from '@/core/redux/slices/modalsSlice/widgets/widgetsModalsSlice';

import styles from './styles.module.scss';

export const Dashboard: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui' });
  const dispatch = useAppDispatch();

  const openWidgetControlModal = useCallback(() => {
    dispatch(widgetsModalsActions.openWidgetControlModal());
  }, [dispatch]);

  return (
    <div className={clsx('flex flex-col w-full', styles.container)}>
      <div className='flex justify-between'>
        <Typography type='H1'>{t('title.infocenter')}</Typography>
        <div className='flex gap-4'>
          <Button onClick={openWidgetControlModal}>{t('button.editWidgets')}</Button>
        </div>
      </div>
      <div className={clsx(styles.wrapper, 'flex gap-3 overflow-auto flex-1 p-2')}>
        <DashboardDnD />
      </div>
    </div>
  );
};
