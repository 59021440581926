import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { MainDashWrapper } from '@/components/wrapper/MainDashWrapper/MainDashWrapper';
import { UserRoles } from '@/core/enums/common/userRolesEnum';
import AdministrationRootRoute from '@/pages/administration/AdministrationRootRoute';
import DashboardRootRoute from '@/pages/dashboard/DashboardRootRoute';
import MedicationRootRoute from '@/pages/medication/MedicationRootRoute';
import PersonRootRoute from '@/pages/persons/PersonRootRoute';
import ReportsRootRoute from '@/pages/reports/ReportsRootRoute';
import SpecialistsRootRoute from '@/pages/specialists/SpecialistsRootRoute';
import { IUser } from '@/types/user';

interface IPrivateRoutes {
  user: IUser | null;
}

const PrivateRoutes: React.FC<IPrivateRoutes> = ({ user }) => {
  return (
    <Routes>
      <Route element={<MainDashWrapper />}>
        <Route path={'/dashboard/*'} element={<DashboardRootRoute />} />
        <Route path={'/persons/*'} element={<PersonRootRoute />} />
        <Route path={'/medication/*'} element={<MedicationRootRoute />} />
        <Route path={'/reports/*'} element={<ReportsRootRoute />} />
        {user?.role === UserRoles.Admin && (
          <>
            <Route path={'/administration/*'} element={<AdministrationRootRoute />} />
            <Route path={'/specialists/*'} element={<SpecialistsRootRoute />} />
          </>
        )}
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
