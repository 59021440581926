import { PayloadAction } from '@reduxjs/toolkit';
import { type NormalizeOAS, type OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  IDevelopmentReportPersonDetailsForm,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/PersonDetails/helper/personDetailsForm';
import { authAdd, restCall } from '@/core/clients/rest';
import {
  DevelopmentReportDisabilityTypes,
} from '@/core/enums/functions/developmentReport/developmentReportDisabilityTypesEnum';
import {
  DevelopmentReportLegalSupportTypes,
} from '@/core/enums/functions/developmentReport/developmentReportLegalSupportTypesEnum';
import {
  developmentReportPersonDetailsActions,
  IDevelopmentReportPersonDetailsFetchPayload,
  IDevelopmentReportPersonDetailsUpdatePayload,
  IImportedDevelopmentReportPersonDetailsFetchPayload,
} from '@/core/redux/slices/functions/developmentReport/developmentReportPersonDetailsSlice/slice';
import { getSelectedOption, toCamelCase } from '@/core/utils/commonUtils';
import { isEnumValue } from '@/core/utils/enumUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type FetchDevelopmentReportPersonDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details',
  'get'
>;


type DevelopmentReportPersonDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details',
  'get',
  '200'
>;

type FetchImportedDevelopmentReportPersonDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details/import',
  'get'
>;


type ImportedDevelopmentReportPersonDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details/import',
  'get',
  '200'
>;

type UpdateDevelopmentReportPersonDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/personal_details',
  'post'
>;

const mapDevelopmentReportPersonDetailsResponse = (response: DevelopmentReportPersonDetailsResponse): IDevelopmentReportPersonDetailsForm => {
  const { personal_details } = response;

  const hbgOptions: Record<DevelopmentReportDisabilityTypes, boolean> = {
    [DevelopmentReportDisabilityTypes.HBG1]: Boolean(personal_details.hbg1),
    [DevelopmentReportDisabilityTypes.HBG2]: Boolean(personal_details.hbg2),
    [DevelopmentReportDisabilityTypes.HBG3]: Boolean(personal_details.hgb3),
    [DevelopmentReportDisabilityTypes.Default]: false,
  };

  const hbgSelectedOption =  getSelectedOption(hbgOptions);

  const legalGuardianOptions: Record<DevelopmentReportLegalSupportTypes, boolean> = {
    [DevelopmentReportLegalSupportTypes.Yes]: Boolean(personal_details.legal_guardian_yes_flag),
    [DevelopmentReportLegalSupportTypes.No]: Boolean(personal_details.legal_guardian_no_flag),
    [DevelopmentReportLegalSupportTypes.Default]: false,
  };

  const legalGuardianSelectedOption = getSelectedOption(legalGuardianOptions)

  return {
    hbg: isEnumValue(DevelopmentReportDisabilityTypes, hbgSelectedOption) ? hbgSelectedOption : DevelopmentReportDisabilityTypes.Default,
    legalGuardianFlag: isEnumValue(DevelopmentReportLegalSupportTypes, legalGuardianSelectedOption) ? legalGuardianSelectedOption : DevelopmentReportLegalSupportTypes.Default,
    communicationNote: personal_details.communication_note,
    defaultLegalGuardianNameAddress: personal_details.default_legal_guardian_name_address,
    diagnosis: personal_details.diagnosis,
    goalsWishes: personal_details.goals_wishes,
    internships: personal_details.internships,
    isMobilityOepnv: personal_details.is_mobility_oepnv,
    isMobilityOnFoot: personal_details.is_mobility_on_foot,
    isMobilitySelfDrive: personal_details.is_mobility_self_drive,
    isMobilityTransportService: personal_details.is_mobility_transport_service,
    legalGuardianNameAddress: personal_details.legal_guardian_name_address,
    wfbmContactPerson: personal_details.wfbm_contact_person,
    wfbmName: personal_details.wfbm_name,
    wfbmOperatingUnit: personal_details.wfbm_operating_unit,
    workingDaysPerWeek: personal_details.working_days_per_week,
    workingHoursPerDay: personal_details.working_hours_per_day,
    workingHoursPerWeek: personal_details.working_hours_per_week,
  };
};

function* fetchDevelopmentReportPersonDetails(action: PayloadAction<IDevelopmentReportPersonDetailsFetchPayload>): Generator<any, void, DevelopmentReportPersonDetailsResponse> {
  const { developmentReportID } = action.payload;

  try {
    yield put(developmentReportPersonDetailsActions.setDevelopmentReportPersonDetailsLock(LoadingStatus.LOADING));
    const request: FetchDevelopmentReportPersonDetailsRequest = {
      query: {
        development_report_id: developmentReportID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/development_report/personal_details', 'get', request);
    const mappedResponse = mapDevelopmentReportPersonDetailsResponse(response);

    yield put(developmentReportPersonDetailsActions.setDevelopmentReportPersonDetails(mappedResponse));
    yield put(developmentReportPersonDetailsActions.setDevelopmentReportPersonDetailsLock(LoadingStatus.LOADED));
  } catch (error) {
    yield put(developmentReportPersonDetailsActions.setDevelopmentReportPersonDetailsLock(LoadingStatus.ERROR));
    console.log('Error on development report person details fetching', error);
  }
}

function* updateDevelopmentReportPersonDetails(action: PayloadAction<IDevelopmentReportPersonDetailsUpdatePayload>): Generator<any, void, DevelopmentReportPersonDetailsResponse> {
  const { developmentReportID, personID, formValues } = action.payload;

  try {
    const request: UpdateDevelopmentReportPersonDetailsRequest = {
      json: {
        development_report_id: developmentReportID,
        person_id: personID,
        hbg1: formValues.hbg === DevelopmentReportDisabilityTypes.HBG1,
        hbg2: formValues.hbg === DevelopmentReportDisabilityTypes.HBG2,
        hgb3: formValues?.hbg === DevelopmentReportDisabilityTypes.HBG3,
        legal_guardian_yes_flag: formValues.legalGuardianFlag === DevelopmentReportLegalSupportTypes.Yes,
        legal_guardian_no_flag: formValues.legalGuardianFlag === DevelopmentReportLegalSupportTypes.No,
        is_mobility_oepnv: formValues.isMobilityOepnv ?? false,
        is_mobility_transport_service: formValues.isMobilityTransportService ?? false,
        is_mobility_on_foot: formValues.isMobilityOnFoot ?? false,
        is_mobility_self_drive: formValues.isMobilitySelfDrive ?? false,
        wfbm_name: formValues?.wfbmName,
        wfbm_operating_unit: formValues?.wfbmOperatingUnit,
        wfbm_contact_person: formValues?.wfbmContactPerson,
        legal_guardian_name_address: formValues?.legalGuardianNameAddress,
        working_hours_per_week: formValues?.workingHoursPerWeek,
        working_days_per_week: formValues?.workingDaysPerWeek,
        working_hours_per_day: formValues?.workingHoursPerDay,
        diagnosis: formValues?.diagnosis,
        internships: formValues?.internships,
        communication_note: formValues?.communicationNote,
        goals_wishes: formValues?.goalsWishes,
      },
      ...authAdd(),
    };

    yield call(restCall, '/development_report/personal_details', 'post', request);
  } catch (error) {
    console.log('Error on development report person details updating', error);
  }
}

function* fetchImportedDevelopmentReportPersonDetails(action: PayloadAction<IImportedDevelopmentReportPersonDetailsFetchPayload>): Generator<any, void, ImportedDevelopmentReportPersonDetailsResponse> {
  const { personID } = action.payload;

  try {
    yield put(developmentReportPersonDetailsActions.setImportedDevelopmentReportPersonDetailsLock(LoadingStatus.LOADING));
    const request: FetchImportedDevelopmentReportPersonDetailsRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/development_report/personal_details/import', 'get', request);
    const mappedResponse = toCamelCase(response.personal_details);

    yield put(developmentReportPersonDetailsActions.setImportedDevelopmentReportPersonDetails(mappedResponse));
    yield put(developmentReportPersonDetailsActions.setImportedDevelopmentReportPersonDetailsLock(LoadingStatus.LOADED));
  } catch (error) {
    yield put(developmentReportPersonDetailsActions.setImportedDevelopmentReportPersonDetailsLock(LoadingStatus.ERROR));
    console.log('Error on development report person details importing', error);
  }
}

export const developmentReportPersonDetailsSagas = [
  takeLatest(developmentReportPersonDetailsActions.fetchDevelopmentReportPersonDetails, fetchDevelopmentReportPersonDetails),
  takeLatest(developmentReportPersonDetailsActions.updateDevelopmentReportPersonDetails, updateDevelopmentReportPersonDetails),
  takeLatest(developmentReportPersonDetailsActions.fetchImportedDevelopmentReportPersonDetails, fetchImportedDevelopmentReportPersonDetails),
];
