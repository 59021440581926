import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { specialistOrganizationUnitsActions } from '@/core/redux/slices/functions/specialistGeneralData/organizationUnits/organizationUnitsSlice';
import { specialistOrganizationUnitsSelectors } from '@/core/redux/slices/functions/specialistGeneralData/organizationUnits/selectors';
import { specialistGeneralDataModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/specialistGeneralData/selector';
import { specialistGeneralDataModalsActions } from '@/core/redux/slices/modalsSlice/functions/specialistGeneralData/specialistGeneralDataModalsSlice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IModalContent {
  organizationUnitID: number;
}

const ModalContent: React.FC<IModalContent> = ({ organizationUnitID }) => {
  const { t: organizationUnitSpecialistsModalTranslations } = useTranslation('organizationUnitSpecialistsModal');

  const dispatch = useAppDispatch();

  const [isShowUnauthorized, setIsShowUnauthorized] = useState(false);

  // selectors
  const organizationUnitUsers = useAppSelector(specialistOrganizationUnitsSelectors.organizationUnitUsers);
  const updateUserUnitsLock = useAppSelector(specialistOrganizationUnitsSelectors.updateUserUnitsLock);

  // handle exit
  const handleExit = () => {
    dispatch(specialistGeneralDataModalsActions.closeOrganizationUnitSpecialistsModal());
  };

  // actions
  const handleAddUnitToUser = (organizationUnitID: number, userID: number) => {
    dispatch(
      specialistOrganizationUnitsActions.addUserUnit({
        userID: userID,
        organizationUnitID: organizationUnitID,
      })
    );
  };

  const handleRemoveUnitFromUser = (organizationUnitID: number, userID: number) => {
    dispatch(
      specialistOrganizationUnitsActions.deleteUserUnit({
        userID: userID,
        organizationUnitID: organizationUnitID,
      })
    );
  };

  // effects
  useEffect(() => {
    if (!organizationUnitID) {
      return;
    }

    dispatch(
      specialistOrganizationUnitsActions.fetchOrganizationUnitUsers({
        organizationUnitID: organizationUnitID,
        isAdded: !isShowUnauthorized,
      })
    );
  }, [isShowUnauthorized]);

  useEffect(() => {
    if (updateUserUnitsLock === LoadingStatus.LOADED) {
      dispatch(
        specialistOrganizationUnitsActions.fetchOrganizationUnitUsers({
          organizationUnitID: organizationUnitID,
          isAdded: !isShowUnauthorized,
        })
      );
    }
  }, [updateUserUnitsLock]);

  const columns: ITableColumn[] = [
    {
      header: organizationUnitSpecialistsModalTranslations('table.columns.isAdded.label'),
      accessor: '',
      isSortable: false,
      template: (_value: boolean, row) => (
        <CheckboxUI
          checked={!isShowUnauthorized}
          onChange={(value) => {
            value
              ? handleAddUnitToUser(organizationUnitID, row.id)
              : handleRemoveUnitFromUser(organizationUnitID, row.id);
          }}
        />
      ),
    },
    {
      header: organizationUnitSpecialistsModalTranslations('table.columns.name.label'),
      accessor: 'name',
      className: 'w-3/4',
      template: (_value, row) => row.name + ' ' + row.surname,
    },
  ];

  return (
    <Sidebar
      onExit={handleExit}
      className='w-[1000px]'
      position={'right'}
      title={organizationUnitSpecialistsModalTranslations('title.label')}
    >
      <div className='flex flex-col gap-4'>
        <label className='w-fit flex gap-2 cursor-pointer px-5'>
          <CheckboxUI
            className='pointer-events-none'
            checked={isShowUnauthorized}
            onChange={(value: boolean) => {
              setIsShowUnauthorized(value);
            }}
          />
          <Typography>{organizationUnitSpecialistsModalTranslations('filters.isAdded.label')}</Typography>
        </label>
        <Table
          columns={columns}
          data={organizationUnitUsers}
          selectable={false}
          className='max-h-[500px]'
        />
      </div>
    </Sidebar>
  );
};

export const OrganizationUnitSpecialistModal: React.FC = () => {
  const { isRender: isOpened, payload } = useAppSelector(
    specialistGeneralDataModalsSelectors.organizationUnitSpecialistsModal
  );

  if (isOpened && payload) {
    return <ModalContent organizationUnitID={payload.organizationUnitID} />;
  }

  return null;
};
