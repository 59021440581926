import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { ISerialAppointmentForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/hooks/useSerialAppointmentForm';
import { Typography } from '@/components/Typography/Typography';

export interface ISerialAppointmentDurationForm {
  isFixedAppointmentsCount: boolean;
  appointmentsNumber?: number | null;
  endDate?: string | null;
}

export const SerialAppointmentDurationForm: React.FC = () => {
  const { t: serialAppointmentTranslations } = useTranslation('serialAppointmentModal', {
    keyPrefix: 'duration',
  });

  const { watch } = useFormContext<ISerialAppointmentForm>();

  const isFixedAppointmentsCount = watch('duration.isFixedAppointmentsCount');

  const durationRadioButtonOptions: IFormRadioButtonGroupItem[] = [
    {
      id: 'duration.isFixedAppointmentsCount.true',
      value: true,
      component: (
        <label htmlFor={'duration.isFixedAppointmentsCount.true'}>
          <Typography>{serialAppointmentTranslations('fields.endsAfter.label')}</Typography>
        </label>
      ),
    },
    {
      id: 'duration.isFixedAppointmentsCount.false',
      value: false,
      component: (
        <label htmlFor={'duration.isFixedAppointmentsCount.false'}>
          <Typography>{serialAppointmentTranslations('fields.endsOn.label')}</Typography>
        </label>
      ),
    },
  ];

  const serialDurationForm: (
    | FormItem<ISerialAppointmentForm>
    | FormGroup<ISerialAppointmentForm>
  )[] = [
    {
      type: 'radio-button-group',
      name: 'duration.isFixedAppointmentsCount',
      radioButtonGroupOptions: {
        options: durationRadioButtonOptions,
        containerClassName: 'flex flex-row gap-5',
      },
    },
    {
      condition: isFixedAppointmentsCount,
      type: 'input',
      label: serialAppointmentTranslations('fields.date.label'),
      name: 'duration.appointmentsNumber',
    },
    {
      condition: !isFixedAppointmentsCount,
      type: 'datepicker',
      name: 'duration.endDate',
    },
  ];

  return <FormRender list={serialDurationForm} />;
};
