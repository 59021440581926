import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IItemICF {
  code: string;
  id: number;
  name: string;
}

export interface IUpdateItemICF {
  id: number;
  isSelected: boolean;
}

export interface IAvailableItemsICF {
  code: string;
  name: string;
  text: string;
  id: number;
  isSelected: boolean;
  description?: string | null;
}

export interface IItemsICFState {
  itemsICF: {
    status: LoadingStatus;
    data: IItemICF[];
    totalCount: number;
  };
  availableItemsICF: {
    status: LoadingStatus;
    data: IAvailableItemsICF[];
    totalCount: number;
  };
}

export interface IItemsICFFetchPayload {
  personID: number;
  limit?: number;
  offset?: number;
  page?: number;
}

export interface IAvailableItemsICFFetchPayload extends IItemsICFFetchPayload {
  search?: string;
  isSelected?: boolean;
}
export interface IItemsICFUpdatePayload {
  personID: number;
  updatedItems: IUpdateItemICF[];
}

const initialState: IItemsICFState = {
  itemsICF: {
    status: LoadingStatus.NEVER,
    data: [],
    totalCount: 0,
  },
  availableItemsICF: {
    status: LoadingStatus.NEVER,
    data: [],
    totalCount: 0,
  },
};

export const itemsICFSlice = createSlice({
  name: 'itemsICF',
  initialState,
  reducers: {
    //ICF items
    fetchItemsICF: (state, action: PayloadAction<IItemsICFFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setItemsICFData: (state, action: PayloadAction<IItemICF[]>) => ({
      ...state,
      itemsICF: {
        ...state.itemsICF,
        data: action.payload,
      },
    }),
    setItemsICFStatus: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      itemsICF: {
        ...state.itemsICF,
        status: action.payload,
      },
    }),
    setItemsICFToatalCount: (state, action: PayloadAction<number>) => ({
      ...state,
      itemsICF: {
        ...state.itemsICF,
        totalCount: action.payload,
      },
    }),
    //Available ICF items
    fetchAvailableICFItems: (state, action: PayloadAction<IAvailableItemsICFFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAvailableICFItemsData: (state, action: PayloadAction<IAvailableItemsICF[]>) => ({
      ...state,
      availableItemsICF: {
        ...state.availableItemsICF,
        data: action.payload,
      },
    }),
    setAvailableICFItemsStatus: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      availableItemsICF: {
        ...state.availableItemsICF,
        status: action.payload,
      },
    }),
    setAvailableICFItemsTotalCount: (state, action: PayloadAction<number>) => ({
      ...state,
      availableItemsICF: {
        ...state.availableItemsICF,
        totalCount: action.payload,
      },
    }),
    //Update ICF Items
    updateItemsICF: (state, action: PayloadAction<IItemsICFUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const itemsICFReducer = itemsICFSlice.reducer;
export const itemsICFActions = itemsICFSlice.actions;
