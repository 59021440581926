import React from 'react';

export const MedicationAdministrationIcon: React.FC = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.06122 3.71777H17.6327C17.9522 3.71777 18.2112 3.45872 18.2112 3.1392V2.7192C18.2112 2.39967 17.9522 2.14062 17.6327 2.14062H6.06122C5.7417 2.14062 5.48265 2.39967 5.48265 2.7192V3.1392C5.48265 3.45872 5.7417 3.71777 6.06122 3.71777ZM5.09375 10.7499H18.5937V17.9093H5.09375V10.7499ZM12.4223 14.9485H13.7723C14.0918 14.9485 14.3509 14.6895 14.3509 14.3699C14.3509 14.0504 14.0918 13.7914 13.7723 13.7914H12.4223V12.4414C12.4223 12.1219 12.1633 11.8628 11.8437 11.8628C11.5242 11.8628 11.2652 12.1219 11.2652 12.4414V13.7914H9.91518C9.59565 13.7914 9.33661 14.0504 9.33661 14.3699C9.33661 14.6895 9.59565 14.9485 9.91518 14.9485H11.2652V16.2985C11.2652 16.618 11.5242 16.8771 11.8437 16.8771C12.1633 16.8771 12.4223 16.618 12.4223 16.2985V14.9485ZM18.5937 9.54542V9.05791C18.5937 8.30534 18.1756 7.62876 17.5025 7.29223C17.2239 7.15295 17.0509 6.87296 17.0509 6.56153V4.87953H6.63661V6.56149C6.63661 6.87292 6.46358 7.15291 6.18501 7.29219C5.5119 7.6288 5.09375 8.30534 5.09375 9.05791V9.54542H18.5937ZM5.09375 19.7636V19.185H18.5937V19.7636C18.5937 20.9333 17.6421 21.885 16.4723 21.885H7.21518C6.04542 21.885 5.09375 20.9333 5.09375 19.7636Z'
      fill='currentColor'
    />
  </svg>
);
