import React, { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, Ref } from 'react';

import { ButtonStyles, buttonStyles } from '@/components/Button/helpers/buttonStyles';
import { ButtonVariants, getButtonVariant } from '@/components/Button/helpers/buttonVariants';
import { twMerge } from '@/core/utils/tailwindUtil';

interface IButton
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  children?: React.ReactNode;
  buttonStyle?: ButtonStyles;
  buttonVariant?: ButtonVariants;
  ref?: Ref<HTMLButtonElement>;
}

export const Button: React.FC<IButton> = forwardRef<HTMLButtonElement, IButton>(
  (
    {
      buttonVariant = 'Default',
      buttonStyle = 'Default',
      className,
      onClick,
      disabled = false,
      children,
      type = 'button',
      ...rest
    },
    ref
  ) => {
    return (
      <button
        className={twMerge(buttonStyles[buttonStyle], getButtonVariant(buttonVariant), className)}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        type={type}
        {...rest}
      >
        {children}
      </button>
    );
  }
);
