import { ObjectSchema } from 'yup';

import { IParticipationPlanFormFields } from '@/components/forms/ParticipationPlan/ParticipationPlan';
import { yup } from '@/core/utils/commonUtils';

export const ParticipationPlanFormResolver: ObjectSchema<IParticipationPlanFormFields> = yup
  .object()
  .shape({
    name: yup.string().trim().required(),
    caretaker: yup.number().required(),
    socialServiceWorker: yup.number().required(),
    startDate: yup.string().required(),
  });
