import { call, put, takeLatest } from 'redux-saga/effects';

import { initializeApollo } from "@/core/clients/apollo";
import { toCamelCase } from "@/core/utils/commonUtils";
import { GetReportsDocument, GetReportsQuery, GetReportsQueryHookResult } from "@/services/graphql/base/graphql";
import { Camelize } from "@/types/camelize";

import { reportsActions } from './reportsSlice';

function* fetchReports(): Generator<any, void, GetReportsQueryHookResult> {


    const client = initializeApollo();
    const response = yield call(client.query<GetReportsQuery>, {
      query: GetReportsDocument,
    });

    const camelizeResponse: Camelize<GetReportsQuery> = toCamelCase(response.data)


    yield put(reportsActions.setReports(camelizeResponse.reports));
  }


  export const reportsSagas = [
    takeLatest(reportsActions.fetchReports, fetchReports),
  ];
  