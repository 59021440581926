import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Dialog, IDialog } from '@/components/Dialog/Dialog';
import { DialogContent } from '@/components/Dialog/DialogContent/DialogContent';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';

export const ConfirmCreatingDialog: React.FC<IDialog> = ({ onCancel, onConfirm }) => {
  const { t: additionalPersonRequirementsModalTranslations } = useTranslation(
    'additionalPersonRequirementModal',
    { keyPrefix: 'dialogs.confirmCreating' }
  );

  const message = useAppSelector(
    parametersSelectors.additionalPersonRequirementConfirmCreatingText
  );

  const handleClose = () => {
    onCancel?.();
  };

  const handleSave = () => {
    onConfirm?.();
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <div className={'flex flex-col gap-5'}>
          <Typography type={'H3'}>
            {additionalPersonRequirementsModalTranslations('title.label')}
          </Typography>
          {message && <Typography>{message}</Typography>}
          <div className={'flex flex-row gap-2 justify-center'}>
            <Button onClick={handleSave}>
              {additionalPersonRequirementsModalTranslations('buttons.yes.label')}
            </Button>
            <Button buttonVariant={'Secondary'} onClick={handleClose}>
              {additionalPersonRequirementsModalTranslations('buttons.no.label')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
