import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isBefore, parse } from 'date-fns';
import fp from 'lodash/fp';

import { FRONTEND_DATE_FORMAT } from '@/core/constants/dateFormat';
import { AdditionalPersonRequirementValidationStatus } from '@/core/enums/functions/organizationalUnits/additionalPersonRequirementValidationStatus';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';

export interface IAdditionalPersonRequirementForm {
  id: number;
  assistanceID: number;
  responsibleID?: number | null;
  approvedTo?: string | null;
  approvedFrom?: string | null;
  applicationDate?: string | null;
  minutesPerDay?: number | null;
  comment?: string | null;
}

export const useAdditionalPersonRequirementForm = () => {
  const { additionalPersonRequirement, responsible } = useAppSelector(
    organizationalUnitsModalsSelectors.additionalPersonRequirementModal
  );
  const responsibleRequired = useAppSelector(
    parametersSelectors.additionalPersonRequirementResponsibleRequired
  );

  const [validationStatus, setValidationStatus] =
    useState<AdditionalPersonRequirementValidationStatus>(
      AdditionalPersonRequirementValidationStatus.Default
    );

  const form = useForm<IAdditionalPersonRequirementForm>({
    defaultValues: {
      id: undefined,
      assistanceID: undefined,
      responsibleID: undefined,
      approvedTo: undefined,
      approvedFrom: undefined,
      applicationDate: undefined,
      minutesPerDay: undefined,
      comment: undefined,
    },
  });

  useEffect(() => {
    if (!additionalPersonRequirement) {
      return;
    }

    form.reset({
      id: additionalPersonRequirement.id,
      assistanceID: additionalPersonRequirement.assistance.id,
      responsibleID: additionalPersonRequirement.responsible?.id,
      approvedTo: additionalPersonRequirement.approvedToTimestamp,
      approvedFrom: additionalPersonRequirement.approvedFromTimestamp,
      applicationDate: additionalPersonRequirement.appliedTimestamp,
      minutesPerDay: additionalPersonRequirement.minutesPerDay,
      comment: additionalPersonRequirement.comment,
    });
  }, [additionalPersonRequirement]);

  useEffect(() => {
    if (!responsible) {
      return;
    }

    form.setValue('responsibleID', responsible.id);
  }, [responsible]);

  const dispatch = useAppDispatch();
  const { selectedPersonID } = useSelectedPerson();

  const handleSubmit = () => {
    if (!selectedPersonID) {
      return;
    }

    const currentValues = fp.cloneDeep(form.watch());

    dispatch(
      organizationalUnitsModalsActions.createAdditionalPersonRequirement({
        formValues: currentValues,
        personID: selectedPersonID,
      })
    );

    dispatch(organizationalUnitsModalsActions.closeAdditionalPersonRequirementModal());
  };

  const validate = () => {
    const approvedFromTimestamp = form.watch('approvedFrom');
    const approvedToTimestamp = form.watch('approvedTo');
    const applicationTimestamp = form.watch('applicationDate');

    const responsible = form.watch('responsibleID');

    const approvedFromDate = approvedFromTimestamp
      ? parse(approvedFromTimestamp, FRONTEND_DATE_FORMAT, new Date())
      : null;
    const approvedToDate = approvedToTimestamp
      ? parse(approvedToTimestamp, FRONTEND_DATE_FORMAT, new Date())
      : null;
    const applicationDate = applicationTimestamp
      ? parse(applicationTimestamp, FRONTEND_DATE_FORMAT, new Date())
      : null;

    if (responsibleRequired && !responsible) {
      setValidationStatus(
        AdditionalPersonRequirementValidationStatus.RequiredResponsibleNotSelected
      );

      return;
    }

    if (!applicationDate) {
      form.setValue('approvedFrom', undefined);
    }

    if (!applicationDate && !approvedToDate) {
      handleSubmit();
      return;
    }

    if (approvedFromDate && applicationDate && isBefore(approvedFromDate, applicationDate)) {
      setValidationStatus(
        AdditionalPersonRequirementValidationStatus.ApprovedBeforeApplicationDate
      );

      return;
    }

    if (approvedToDate && !approvedFromDate) {
      setValidationStatus(
        AdditionalPersonRequirementValidationStatus.ApprovedToWithoutApprovedFromDate
      );

      return;
    }

    if (approvedToDate && approvedFromDate && isBefore(approvedToDate, approvedFromDate)) {
      setValidationStatus(
        AdditionalPersonRequirementValidationStatus.ApprovedToBeforeApprovedFromDate
      );

      return;
    }

    setValidationStatus(AdditionalPersonRequirementValidationStatus.Valid);
  };

  return { form, validationStatus, setValidationStatus, validate };
};
