import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { IQuestionnaireQuestionsGroup } from '@/core/redux/slices/functions/performanceProfile/slice';

export interface IQuestionFormValues {
  review?: number | null;
  description?: string | null;
}

export interface IQuestionnaireForm {
  [questionGroupID: number]: {
    questions: Record<number, IQuestionFormValues>;
  };
}

export function useQuestionnaireQuestionsListForm(questionsGroups: IQuestionnaireQuestionsGroup[]) {
  const form = useForm<IQuestionnaireForm>();
  const currentValues = form.watch();

  useEffect(() => {
    if (questionsGroups.length <= 0) {
      return;
    }

    const defaultValues = questionsGroups.reduce<IQuestionnaireForm>(
      (accum, questionGroup) => ({
        ...accum,
        [questionGroup.id]: {
          questions: questionGroup.questionsList.reduce<Record<number, IQuestionFormValues>>(
            (accum, question) => ({
              ...accum,
              [question.id]: {
                review: question.review ?? null,
                description: question.description ?? null,
              },
            }),
            {}
          ),
        },
      }),
      {}
    );

    form.reset(defaultValues);
  }, [questionsGroups]);

  function areAllReviewsPresent(data: IQuestionnaireForm): boolean {
    for (const questionGroupID in data) {
      if (data.hasOwnProperty(questionGroupID)) {
        const section = data[questionGroupID];
        for (const questionID in section.questions) {
          if (section.questions.hasOwnProperty(questionID)) {
            const question = section.questions[questionID];
            if (question.review === null) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  const isAllCompleted = areAllReviewsPresent(currentValues);

  return { form, isAllCompleted };
}
