import React from 'react';

import { Button } from '@/components/Button/Button';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import { SelectedPersonListTooltip } from '@/components/views/functions/SelectedPersonsList/SelectedPersonListTooltip';
import { generateName, getPersonInitials } from '@/core/utils/commonUtils';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';
import { IPerson } from '@/types/person';

interface ISelectedUserItem {
  person: IPerson;
  handleSelect: (person: IPerson) => void;
  isActive: boolean;
  isListExpanded: boolean;
}

export const SelectedSpecialistItem: React.FC<ISelectedUserItem> = ({
  person,
  handleSelect,
  isActive,
  isListExpanded,
}) => {
  const tooltipID = `person-tooltip-${person.id}`;

  return isListExpanded ? (
    <Button
      className={twMerge(
        'px-4 py-3 text-disabled flex items-center justify-between border-l-transparent border-l-2 border-solid hover:text-blue hover:bg-hover hover:border-l-blue',
        isActive && 'text-blue bg-hover border-l-blue'
      )}
      buttonVariant={'Transparent'}
      buttonStyle={'Text'}
      onClick={() => handleSelect(person)}
    >
      <span className='font-semibold overflow-x-hidden truncate'>{generateName(person)}</span>
      {isActive && <ArrowRightIcon />}
    </Button>
  ) : (
    <div
      className={twMerge(
        'flex items-center justify-center border-l-2 border-transparent',
        isActive ? `bg-${colorTypes.Hover} border-${colorTypes.Blue}` : ''
      )}
    >
      <Button
        buttonVariant={'Transparent'}
        buttonStyle={'Circle'}
        id={tooltipID}
        onClick={() => handleSelect(person)}
        className={twMerge(
          `w-[50px] p-3 bg-${colorTypes.HoverLight} border border-${colorTypes.Disabled} hover:text-${colorTypes.Blue} hover:border-${colorTypes.Blue} font-semibold text-disabled`,
          isActive && `border-${colorTypes.Blue} text-${colorTypes.Blue}`
        )}
      >
        {getPersonInitials(person)}
      </Button>
      <SelectedPersonListTooltip id={tooltipID} tooltipMessage={generateName(person)} />
    </div>
  );
};
