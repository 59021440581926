import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { BackToIcon } from '@/components/Icons/BackToIcon';
import { DownloadIcon } from '@/components/Icons/DownloadIcon';
import { InfoIcon } from '@/components/Icons/InfoIcon';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Typography } from '@/components/Typography/Typography';
import { useDialog } from '@/core/hooks/useDialog';
import { extractErrorsByKey } from '@/core/utils/formUtils';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

interface IDevelopmentReportActionsBar {
  importOptions?: {
    handleImport: () => void;
    loadingStatus: LoadingStatus;
  };
  errorsOptions?: {
    errors: FieldErrors;
    formKey: string;
  };
  resetOptions?: {
    isDirty: boolean;
    onReset: () => void;
  };
  customOptions?: {
    component: React.ReactNode;
  };
}

export const DevelopmentReportActionsBar: React.FC<IDevelopmentReportActionsBar> = ({
  importOptions,
  errorsOptions,
  resetOptions,
  customOptions,
}) => {
  const { t: developmentReportTranslations } = useTranslation('developmentReport', {
    keyPrefix: 'importDataDialog',
  });

  const errorMessages = extractErrorsByKey(errorsOptions?.errors, errorsOptions?.formKey, true);

  const {
    renderDialogContainer: renderImportDialog,
    openDialog: openImportDialog,
    closeDialog,
  } = useDialog({
    description: developmentReportTranslations('description.label'),
    title: developmentReportTranslations('title.label'),
    confirmButton: (
      <Button
        onClick={() => {
          importOptions?.handleImport();
          closeDialog();
        }}
      >
        {developmentReportTranslations('buttons.confirm.label')}
      </Button>
    ),
    cancelButton: (
      <Button onClick={() => closeDialog()}>
        {developmentReportTranslations('buttons.cancel.label')}
      </Button>
    ),
  });

  const { renderDialogContainer: renderErrorsDialog, openDialog: openErrorsDialog } = useDialog({
    title: developmentReportTranslations('title.label'),
    description: (
      <div className={'flex flex-col gap-1'}>
        {errorMessages?.map((message, index) => (
          <Typography key={index}>{message}</Typography>
        ))}
      </div>
    ),
    showConfirmButton: false,
  });

  return (
    <div className={'w-full flex flex-row sticky bottom-3 justify-end gap-2 p-5'}>
      {resetOptions?.isDirty && (
        <Button
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
          onClick={() => resetOptions.onReset()}
          className={`rounded-full bg-${colorTypes.Blue} text-white hover:text-white opacity-50 hover:opacity-100 w-fit p-4`}
        >
          <BackToIcon className={'w-[24px] h-[24px]'} />
        </Button>
      )}
      {importOptions && (
        <Button
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
          onClick={() => openImportDialog()}
          className={`rounded-full bg-${colorTypes.Blue} text-white hover:text-white opacity-50 hover:opacity-100 w-fit p-4`}
        >
          {importOptions.loadingStatus === LoadingStatus.LOADING ? (
            <SpinnerIcon className={'w-[24px] h-[24px]'} />
          ) : (
            <DownloadIcon className={'w-[24px] h-[24px]'} />
          )}
        </Button>
      )}
      {errorMessages && (
        <Button
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
          onClick={() => openErrorsDialog()}
          className={`rounded-full bg-${colorTypes.Error} text-white hover:text-white opacity-50 hover:opacity-100 w-fit p-4`}
        >
          <InfoIcon className={'w-[24px] h-[24px]'} />
        </Button>
      )}
      {customOptions && customOptions.component}
      {renderImportDialog()}
      {renderErrorsDialog()}
    </div>
  );
};
