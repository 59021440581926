import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import FunctionsRoute from '@/pages/dashboard/FunctionsRoute';
import PersonsRoute from '@/pages/dashboard/PersonsRoute';

const PersonRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route index element={<PersonsRoute />} />
      <Route path={'functions'} element={<FunctionsRoute />} />
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </Routes>
  );
};

export default PersonRootRoute;
