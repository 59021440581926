import React from 'react';

import { AppliedTestProcedures } from '@/components/functions/TestingResult/AppliedTestProcedures/AppliedTestProcedures';
import { InternalDifferentiation } from '@/components/functions/TestingResult/InternalDifferentiation/InternalDifferentiation';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';

export const TestingResult: React.FC = () => {
  return (
    <FunctionWrapper>
      <FunctionContent className={'px-5'}>
        <InternalDifferentiation />
        <AppliedTestProcedures />
      </FunctionContent>
    </FunctionWrapper>
  );
};
