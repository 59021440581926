import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { UserIcon } from '@/components/Icons/UserIcon';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { specialistOrganizationUnitsActions } from '@/core/redux/slices/functions/specialistGeneralData/organizationUnits/organizationUnitsSlice';
import { specialistOrganizationUnitsSelectors } from '@/core/redux/slices/functions/specialistGeneralData/organizationUnits/selectors';
import { specialistGeneralDataModalsActions } from '@/core/redux/slices/modalsSlice/functions/specialistGeneralData/specialistGeneralDataModalsSlice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

interface IOrganizationUnits {
  userID: number;
}

export const OrganizationUnits: React.FC<IOrganizationUnits> = ({ userID }) => {
  const { t: specialistOrganizationUnitsTranslations } = useTranslation(
    'specialistOrganizationUnits'
  );

  const dispatch = useAppDispatch();

  const availableUnits = useAppSelector(specialistOrganizationUnitsSelectors.availableUnits);
  const updateUserUnitsLock = useAppSelector(
    specialistOrganizationUnitsSelectors.updateUserUnitsLock
  );

  const [isOnlyAdded, setIsOnlyAdded] = useState(false);

  const handleAddUnitToUser = (organizationUnitID: number, userID: number) => {
    dispatch(
      specialistOrganizationUnitsActions.addUserUnit({
        userID: userID,
        organizationUnitID: organizationUnitID,
      })
    );
  };

  const handleRemoveUnitFromUser = (organizationUnitID: number, userID: number) => {
    dispatch(
      specialistOrganizationUnitsActions.deleteUserUnit({
        userID: userID,
        organizationUnitID: organizationUnitID,
      })
    );
  };

  const handleOpenOrganiztionUnitSpecialistsModal = (organizationUnitID: number) => {
    dispatch(
      specialistGeneralDataModalsActions.openOrganizationUnitSpecialistsModal({
        organizationUnitID: organizationUnitID,
      })
    );
  };

  const columns: ITableColumn[] = [
    {
      header: specialistOrganizationUnitsTranslations('table.columns.name.label'),
      accessor: 'name',
      className: 'w-3/4',
    },
    {
      header: specialistOrganizationUnitsTranslations('table.columns.isAdded.label'),
      accessor: 'isAdded',
      template: (value: boolean, row) => (
        <CheckboxUI
          checked={value}
          onChange={(value) => {
            value ? handleAddUnitToUser(row.id, userID) : handleRemoveUnitFromUser(row.id, userID);
          }}
        />
      ),
    },
    {
      header: '',
      accessor: '',
      isSortable: false,
      className: 'py-2',
      template: (_value, row) => (
        <>
          <Button
            buttonVariant={'Icon'}
            buttonStyle={'Icon'}
            className={`text-${colorTypes.Grey}  p-2 hover:bg-stroke rounded-md cursor-pointer`}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenOrganiztionUnitSpecialistsModal(row.id);
            }}
          >
            <UserIcon />
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      specialistOrganizationUnitsActions.fetchAvailableUnits({
        userID: userID,
        isOnlyAdded: isOnlyAdded,
      })
    );
  }, [userID, isOnlyAdded]);

  useEffect(() => {
    if (updateUserUnitsLock === LoadingStatus.LOADED) {
      dispatch(
        specialistOrganizationUnitsActions.fetchAvailableUnits({
          userID: userID,
          isOnlyAdded: isOnlyAdded,
        })
      );
    }
  }, [updateUserUnitsLock]);

  return (
    <div className='w-full flex flex-col gap-4 h-full overflow-auto p-5'>
      <div className={`border-b border-b-${colorTypes.StrokeLight} pb-2.5 mb-5`}>
        <Typography type='H3'>{specialistOrganizationUnitsTranslations('title.label')}</Typography>
      </div>
      <div className='flex flex-col gap-4'>
        <label className='w-fit flex gap-2 cursor-pointer'>
          <CheckboxUI
            className='pointer-events-none'
            checked={isOnlyAdded}
            onChange={(value: boolean) => {
              setIsOnlyAdded(value);
            }}
          />
          <Typography>
            {specialistOrganizationUnitsTranslations('filters.isOnlyAdded.label')}
          </Typography>
        </label>
        <Table
          data={availableUnits}
          columns={columns}
          selectable={false}
          className='max-h-[500px]'
        />
      </div>
    </div>
  );
};
