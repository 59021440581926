import { FormItem } from '@/components/FormRender/FormRender';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';

export const addressForm: FormItem[] = [
  {
    name: 'salutationId',
    label: 'fields.salutationId.label',
    type: 'external-select',
    tableName: DropdownItemsByFetch.SALUTATION,
  },
  {
    name: 'name',
    label: 'fields.name.label',
    type: 'input',
  },
  {
    name: 'surname',
    label: 'fields.surname.label',
    type: 'input',
  },
  {
    name: 'address',
    label: 'fields.address.label',
    type: 'input',
  },
  {
    name: 'plz',
    label: 'fields.plz.label',
    type: 'number',
  },
  {
    name: 'location',
    label: 'fields.location.label',
    type: 'input',
  },
  {
    name: 'telephoneNumber',
    label: 'fields.telephoneNumber.label',
    type: 'input',
  },
  {
    name: 'cellPhoneNumber',
    label: 'fields.cellPhoneNumber.label',
    type: 'input',
  },
  {
    name: 'email',
    label: 'fields.email.label',
    type: 'input',
  },
  {
    name: 'fax',
    label: 'fields.fax.label',
    type: 'input',
  },
  {
    name: 'notes',
    label: 'fields.notes.label',
    type: 'textarea',
    className: 'col-span-2',
  },
  {
    name: 'isLegalGuardian',
    label: 'fields.isLegalGuardian.label',
    type: 'checkbox',
    className: 'col-span-2 flex flex-row-reverse gap-2 justify-end',
  },
];
