import React from 'react';

import { AdditionalPersonRequirementModalContent } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/AdditionalPersonRequirementModalContent';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';

export const AdditionalPersonRequirementModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    organizationalUnitsModalsSelectors.additionalPersonRequirementModal
  );

  const dispatch = useAppDispatch();

  return isOpened ? <AdditionalPersonRequirementModalContent /> : null;
};
