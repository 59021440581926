import React, { useState } from 'react';
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react';

import { QuestionnaireReviewsHistoryItem } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionItem/QuationnaireReviewsHistory/QuestionnaireReviewsHistoryItem/QuestionnaireReviewsHistoryItem';
import { HistoryIcon } from '@/components/Icons/HistoryIcon';
import { IQuestionnaireQuestionReviewHistoryItem } from '@/core/redux/slices/functions/performanceProfile/slice';
import { colorTypes } from '@/styles/types';

interface IQuestionnaireReviewsHistory {
  reviewsHistory: IQuestionnaireQuestionReviewHistoryItem[];
}

export const QuestionnaireReviewsHistory: React.FC<IQuestionnaireReviewsHistory> = ({
  reviewsHistory,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(0), flip()],
    placement: 'bottom-start',
  });

  const handleMouseOver = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div ref={refs.setReference} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <HistoryIcon
        className={`cursor-pointer text-${colorTypes.Grey} hover:text-${colorTypes.Blue}`}
      />
      {isOpen && (
        <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 1000 }} className={'py-4'}>
          <div
            className={
              'py-2 px-4 bg-white rounded-default shadow-default grid grid-cols-[max-content,_auto] gap-x-[30px] gap-y-3 z-20 max-h-[30vh] overflow-y-auto'
            }
          >
            {reviewsHistory.map((reviewHistory) => (
              <QuestionnaireReviewsHistoryItem
                reviewHistoryItem={reviewHistory}
                key={reviewHistory.id}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
