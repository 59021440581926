import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useBlockDeleteAdministrationDepartmentDialog } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementDialogs/useBlockDeleteAdministrationDepartmentDialog';
import { useConfirmDeleteAdministrationDepartmentDialog } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementDialogs/useConfirmDeleteAdministrationDepartmentDialog';
import { useRenameAdministrationDepartmentDialog } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementDialogs/useRenameAdministrationDepartmentDialog';
import { IAdministrationLocationManagementForm } from '@/components/administartionFunctions/locationManagement/hooks/useAdministrationLocationManagementForm';
import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { EditTextIcon } from '@/components/Icons/EditTextIcon';
import { FolderIcon } from '@/components/Icons/FolderIcon';
import { TrashIcon } from '@/components/Icons/TrashIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { IAdministrationDepartment } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { administrationLocationManagementModalsActions } from '@/core/redux/slices/modalsSlice/administrationFunctions/locationManagement/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IAdministrationLocationManagementDepartmentItem {
  department: IAdministrationDepartment;
  formIndex: number;
}

export const AdministrationLocationManagementDepartmentItem: React.FC<IAdministrationLocationManagementDepartmentItem> =
  ({ department, formIndex }) => {
    const { watch } = useFormContext<IAdministrationLocationManagementForm>();
    const isSelected = watch(`departments.${formIndex}`);

    const dispatch = useAppDispatch();

    const {
      renderDialogContainer: renderDepartmentRenameDialog,
      openDialog: openDepartmentRenameDialog,
    } = useRenameAdministrationDepartmentDialog(department);
    const {
      renderDialogContainer: renderDepartmentBlockDeleteDialog,
      openDialog: openDepartmentBlockDeleteDialog,
    } = useBlockDeleteAdministrationDepartmentDialog([department]);
    const {
      renderDialogContainer: renderDepartmentConfirmDeleteDialog,
      openDialog: openDepartmentConfirmDeleteDialog,
    } = useConfirmDeleteAdministrationDepartmentDialog([department]);

    const handleOpenModal = () => {
      dispatch(
        administrationLocationManagementModalsActions.openAdministrationDepartmentMoveModal({
          departments: [department],
        })
      );
    };

    return (
      <div
        className={twMerge(
          `flex flex-row items-center gap-[10px] w-full px-[30px] py-3 border-b border-b-${colorTypes.StrokeLight} hover:bg-${colorTypes.HoverLight}`,
          isSelected && `bg-${colorTypes.HoverLight}`
        )}
      >
        <Checkbox name={`departments.${formIndex}`} id={String(department.id)} />
        <label htmlFor={String(department.id)} className={'flex-1'}>
          <Typography type={'14-R'}>{department.name}</Typography>
        </label>
        <div className={'flex flex-row items-center'}>
          <Button
            buttonVariant={'Icon'}
            buttonStyle={'Icon'}
            className={`p-2 text-${colorTypes.Secondary}`}
            onClick={openDepartmentRenameDialog}
          >
            <EditTextIcon />
          </Button>
          <Button
            buttonVariant={'Icon'}
            buttonStyle={'Icon'}
            className={`p-2 text-${colorTypes.Secondary} `}
            onClick={handleOpenModal}
          >
            <FolderIcon />
          </Button>
          <Button
            buttonVariant={'Icon'}
            buttonStyle={'Icon'}
            className={`p-2 text-${colorTypes.Secondary} `}
            onClick={
              department.isDeletable
                ? openDepartmentConfirmDeleteDialog
                : openDepartmentBlockDeleteDialog
            }
          >
            <TrashIcon />
          </Button>
        </div>
        {renderDepartmentRenameDialog()}
        {renderDepartmentConfirmDeleteDialog()}
        {renderDepartmentBlockDeleteDialog()}
      </div>
    );
  };
