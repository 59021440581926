import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { efficacyAssessmentModalActions } from '@/core/redux/slices/modalsSlice/efficacyAssessment/efficacyAssessmentModalSlice';
import { efficacyAssessmentModalSelectors } from '@/core/redux/slices/modalsSlice/efficacyAssessment/selectors';

import { EfficacyAssessmentTable } from './EfficacyAssessmentTable/EfficacyAssessmentTable';

const ModalContent: React.FC = () => {
  const { t: efficacyAssessmentModalTranslations } = useTranslation('efficacyAssessmentModal');

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(efficacyAssessmentModalActions.closeEfficacyAssessmentModal());
  };

  const renderFooter = (
    <div className='flex gap-2.5 justify-start'>
      <Button type='button' onClick={handleExit} buttonVariant='Secondary'>
        {efficacyAssessmentModalTranslations('buttons.close.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar
      onExit={handleExit}
      className='w-[1200px]'
      footer={renderFooter}
      title={efficacyAssessmentModalTranslations('title.label')}
      position='right'
    >
      <div className='flex flex-col gap-8 max-h-full'>
        <EfficacyAssessmentTable />
      </div>
    </Sidebar>
  );
};

export const EfficacyAssessmentModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    efficacyAssessmentModalSelectors.efficacyAssessmentModal
  );

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
