import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput } from 'fets';
import fp from 'lodash/fp';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { restCall } from '@/core/clients/rest';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';
import { IPerson, IShortcutFunctionsItem } from '@/types/person';

import { authSelectors } from '../auth/selectors';

import { ISpecialistsFecthPayload, specialistsActions } from './specilistsSlice';

type SpecialistsResponse = OASOutput<NormalizeOAS<typeof oas>, '/person', 'get', '200'>;

function* fetchSpecialists(
  action: PayloadAction<ISpecialistsFecthPayload>
): Generator<any, void, SpecialistsResponse> {
  yield put(specialistsActions.setSpecialistsLock(LoadingStatus.LOADING));
  const aToken = yield select(authSelectors.accessToken);

  const { search } = action.payload;

  const response = yield call(restCall, '/person', 'get', {
    query: {
      search: search,
    },
    aToken,
  });

  yield put(
    specialistsActions.setSpecialistsLock(
      fp.isEmpty(response.data) ? LoadingStatus.LOADED_EMPTY : LoadingStatus.LOADED
    )
  );

  const specialists: IPerson[] = response.data.map((person) => ({
    name: person.name || '',
    surname: person.surname || '',
    last_modified: person.last_modified || '',
    id: person.id,
    functions: person.functions?.map<IShortcutFunctionsItem>((func) => ({
      id: func.id,
      name: func.name,
      iconFilenameDisk: func.icon_filename_disk,
      tooltip: func.tooltip,
    })),
  }));

  yield put(specialistsActions.setSpecialists(specialists));
}

export const specialistsSagas = [takeLatest(specialistsActions.fetchSpecialists, fetchSpecialists)];
