import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { disabilityCharacteristicsValidatorBuilder } from '@/components/functions/MedicalInformation/DisabilityCharacteristics/helpers/disabilityCharacteristicsValidator';
import { useAppSelector } from '@/core/redux/hooks';
import { disabilityCharacteristicsSelectors } from '@/core/redux/slices/functions/medicalInformation/disabilityCharacteristics/selectors';

export interface IDisabilityCharacteristicsForm {
  characteristics: Record<string, boolean>;
  isDisabledPass: boolean;
  identityCardAuthoritiesID?: number | null;
  validDate?: string | null;
  referenceNumber?: string | null;
  isUnlimited: boolean;
}

const DISABILITY_CHARACTERISTICS_DEFAULT_VALUES: IDisabilityCharacteristicsForm = {
  characteristics: {},
  isDisabledPass: false,
  identityCardAuthoritiesID: null,
  validDate: null,
  referenceNumber: null,
  isUnlimited: false,
};

export function useDisabilityCharacteristicsForm() {
  const { t: disabilityCharacteristicsTranslations } = useTranslation('disabilityCharacteristics');

  const form = useForm<IDisabilityCharacteristicsForm>({
    defaultValues: DISABILITY_CHARACTERISTICS_DEFAULT_VALUES,
    resolver: yupResolver(
      disabilityCharacteristicsValidatorBuilder(disabilityCharacteristicsTranslations)
    ),
    reValidateMode: 'onChange',
  });

  const currentValues = form.watch();

  const disabilityCharacteristics = useAppSelector(
    disabilityCharacteristicsSelectors.disabilityCharacteristics
  );

  useEffect(() => {
    if (!disabilityCharacteristics) {
      return;
    }

    const newFormState: IDisabilityCharacteristicsForm = {
      ...disabilityCharacteristics,
      characteristics: disabilityCharacteristics.characteristics.reduce<Record<string, boolean>>(
        (accum, characteristic) => ({
          ...accum,
          [characteristic.id]: characteristic.isSelected,
        }),
        {}
      ),
    };

    form.reset(newFormState);
  }, [disabilityCharacteristics]);

  useEffect(() => {
    if (!form.formState.isSubmitted) {
      return;
    }

    form.trigger();
  }, [currentValues.isDisabledPass, currentValues.isUnlimited, currentValues.validDate]);

  return { form, defaultValues: DISABILITY_CHARACTERISTICS_DEFAULT_VALUES };
}
