import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CompleteAppointmentTable } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/CompleteAppointmentTable';
import {
  ICompleteAppointmentFormGroup,
  useCompleteAppointmentModalForm,
} from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/hooks/useCompleteAppointmentModalForm';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/selectors';
import { activityPlaningAppointmentDetailsModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';

export const CompleteAppointmentModal: React.FC = () => {
  const { isRender } = useAppSelector(
    activityPlaningAppointmentDetailsModalsSelectors.completeAppointmentModal
  );

  return isRender ? <CompleteAppointmentModalContent /> : null;
};

export const CompleteAppointmentModalContent: React.FC = () => {
  const { t: completeAppointmentModalTranslations } = useTranslation('completeAppointmentModal');

  const { payload } = useAppSelector(
    activityPlaningAppointmentDetailsModalsSelectors.completeAppointmentModal
  );

  const { form } = useCompleteAppointmentModalForm();
  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(activityPlaningAppointmentDetailsModalsActions.closeCompleteAppointmentModal());
  };

  const handleReset = () => {
    if (form.formState.isDirty) {
      form.reset();
      return;
    }

    handleExit();
  };

  const handleSubmit = (data: ICompleteAppointmentFormGroup) => {
    if (!data || !payload?.measureID || !selectedPersonID) {
      return;
    }

    dispatch(
      activityPlaningAppointmentDetailsModalsActions.finishAppointment({
        formValues: data,
        personID: selectedPersonID,
        measureID: payload.measureID,
      })
    );
  };

  useEffect(() => {
    if (!payload?.measureID) {
      return;
    }

    dispatch(
      activityPlaningAppointmentDetailsModalsActions.fetchAppointmentCompleteQuestionnaire({
        measureID: payload.measureID,
        appointmentID: payload.appointmentID,
      })
    );
  }, []);

  const footer = (
    <div className={'flex flex-row gap-2'}>
      {form.formState.isDirty && (
        <Button type={'submit'} onClick={form.handleSubmit(handleSubmit)}>
          {completeAppointmentModalTranslations('buttons.save.label')}
        </Button>
      )}
      <Button buttonVariant={'Secondary'} onClick={handleReset}>
        {completeAppointmentModalTranslations('buttons.cancel.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar
      onExit={handleExit}
      className={'max-w-[90vw]'}
      position={'right'}
      layer={2}
      footer={footer}
      title={completeAppointmentModalTranslations('title.label', {
        date: payload?.startDate,
        fromTimestamp: payload?.startTime,
        toTimestamp: payload?.endTime,
      })}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className={'p-5'}>
          <CompleteAppointmentTable />
        </form>
      </FormProvider>
    </Sidebar>
  );
};
