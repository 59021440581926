import React from 'react';

import { IIcon } from '@/types/icon';

export const BackToIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32px'
    height='32px'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path d='M2 11l7-9v5c11.953 0 13.332 9.678 13 15-.502-2.685-.735-7-13-7v5l-7-9z' />
  </svg>
);
