import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface ICategory {
  id: number;
  name: string;
  isDefault: boolean;
}

export interface ILogbookSummary {
  id: number;
  note: string;
  createdTimestamp: string;
  category: {
    id: number;
    name: string;
  };
  createdBy: string;
}

export interface ILogbook {
  id: number;
  note: string;
  category: string;
  person: string;
  department: string;
  createdTimestamp: string;
  createdBy: string;
  isHidden: boolean;
}

export interface ILogbookPerson {
  id: number;
  note: string;
  category: {
    id: number;
    name: string;
  };
  createdTimestamp: string;
  createdBy: string;
  isHidden: boolean;
}

export interface ILogbookSummaryFetchPayload {
  personID: number;
}

export interface ILogbookPersonFetchPayload {
  personID: number;
}
export interface ILogbookListFetchPayload {
  search?: string;
  categoryID?: number;
  isCreatedByMe?: boolean;
}

export interface ILogbookItemUpdatePayload {
  logbookItemID: number;
  categoryID: number;
  note: string;
  createdTimestamp: string;
}

export interface ILogbookItemCreatePayload {
  personID: number;
  categoryID: number;
  note: string;
}

export interface ILogbookItemChangeHideStatusPayload {
  logbookItemID: number;
}

export interface ILogbookState {
  logbookPerson: ILogbookPerson[];
  logbookPersonLock: LoadingStatus;

  logbookSummary: ILogbookSummary[];
  logbookSummaryLock: LoadingStatus;

  categories: ICategory[];
  categoriesLock: LoadingStatus;

  updateLogbookItemLock: LoadingStatus;
  createLogbookItemLock: LoadingStatus;
  changeHideStatusLock: LoadingStatus;
}

const initialState: ILogbookState = {
  //logbook
  logbookPerson: [],
  logbookPersonLock: LoadingStatus.NEVER,
  //summary
  logbookSummary: [],
  logbookSummaryLock: LoadingStatus.NEVER,
  //categories
  categories: [],
  categoriesLock: LoadingStatus.NEVER,
  //update
  updateLogbookItemLock: LoadingStatus.NEVER,
  //create
  createLogbookItemLock: LoadingStatus.NEVER,
  //stop administrating
  changeHideStatusLock: LoadingStatus.NEVER,
};

export const logbookSlice = createSlice({
  name: 'logbook',
  initialState,
  reducers: {
    //logbook py person
    fetchLogbookPerson: (state, action: PayloadAction<ILogbookPersonFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    fetchLogbookList: (state, action: PayloadAction<ILogbookListFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setLogbookPersonLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      logbookPersonLock: action.payload,
    }),
    setLogbookPerson: (state, action: PayloadAction<ILogbookPerson[]>) => ({
      ...state,
      logbookPerson: action.payload ?? [],
    }),
    //logbook summary
    fetchLogbookSummary: (state, action: PayloadAction<ILogbookSummaryFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setLogbookSummaryLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      logbookSummaryLock: action.payload,
    }),
    setLogbookSummary: (state, action: PayloadAction<ILogbookSummary[]>) => ({
      ...state,
      logbookSummary: action.payload ?? [],
    }),
    //categories
    fetchCategories: () => {},
    setCategoriesLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      categoriesLock: action.payload,
    }),
    setCategories: (state, action: PayloadAction<ICategory[]>) => ({
      ...state,
      categories: action.payload ?? [],
    }),
    //update
    updateLogbookItem: (state, action: PayloadAction<ILogbookItemUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateLogbookItemLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateLogbookItemLock: action.payload,
    }),
    //create
    createLogbookItem: (state, action: PayloadAction<ILogbookItemCreatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setCreateLogbookItemLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      createLogbookItemLock: action.payload,
    }),
    //change hide status
    changeHideStatus: (state, action: PayloadAction<ILogbookItemChangeHideStatusPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setChangeHideStatusLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      changeHideStatusLock: action.payload,
    }),
  },
});

export const logbookReducer = logbookSlice.reducer;
export const logbookActions = logbookSlice.actions;
