import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const AdministrationLocationManagementLocationHeader: React.FC = () => {
  const { t: administrationLocationManagementTranslations } = useTranslation(
    'administrationLocationManagement'
  );

  return (
    <div
      className={`flex flex-row items-center gap-[10px] px-[30px] py-[10px] bg-${colorTypes.HoverLight} border-y border-y-${colorTypes.StrokeLight}`}
    >
      <Typography className={'flex-1'}>
        {administrationLocationManagementTranslations('locations.title.label')}
      </Typography>
    </div>
  );
};
