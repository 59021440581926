import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { ExclamationIcon } from '@/components/Icons/ExclamationIcon';
import { Typography } from '@/components/Typography/Typography';
import { useDialog } from '@/core/hooks/useDialog';
import { IAdministrationDepartment } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';

export function useBlockDeleteAdministrationDepartmentDialog(
  department: IAdministrationDepartment[]
) {
  const { t: administrationLocationManagementTranslations } = useTranslation(
    'administrationLocationManagement',
    { keyPrefix: 'departments.dialogs.deleteDepartment.blocked' }
  );

  const { renderDialogContainer, openDialog, closeDialog } = useDialog({
    description: (
      <div className={'flex flex-col gap-4 items-center max-w-[440px] text-center'}>
        <ExclamationIcon />
        <Typography type={'H2'}>
          {administrationLocationManagementTranslations('title.label')}
        </Typography>
        <Typography>{administrationLocationManagementTranslations('description.label')}</Typography>
      </div>
    ),
    showConfirmButton: false,
    cancelButton: (
      <Button onClick={() => closeDialog()} buttonVariant={'Secondary'} className={'w-full'}>
        {administrationLocationManagementTranslations('buttons.cancel.label')}
      </Button>
    ),
  });

  return { renderDialogContainer, openDialog };
}
