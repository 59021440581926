import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.modals.functions.developmentReportModals.developmentReportVersionHistoryModal;

const developmentReportVersionHistoryModal = createSelector([selector], (state) => state);

export const developmentReportVersionHistoryModalSelectors = {
  developmentReportVersionHistoryModal,
};
