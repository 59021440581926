import React from 'react';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

interface IWorkingTimePlannerHeaderItem {
  name: string;
  value?: number | null;
}

export const WorkingTimePlannerHeaderItem: React.FC<IWorkingTimePlannerHeaderItem> = ({
  name,
  value,
}) => {
  return (
    <div className={'flex flex-row items-end gap-2'}>
      <Typography>{name}</Typography>
      <Typography color={'Secondary'} className={`bg-${colorTypes.Fields} rounded-l px-3`}>
        {value?.toFixed(2) ?? 0}
      </Typography>
    </div>
  );
};
