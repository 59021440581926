import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { mapInternalDifferentiationResponse } from '@/core/redux/slices/functions/testingResult/sagas';
import {
  IInternalDifferentiation,
  testingResultActions,
} from '@/core/redux/slices/functions/testingResult/testingResultSlice';
import {
  IAppliedTestProcedureCreatePayload,
  IAppliedTestProcedureUpdatePayload,
  IImportInternalDifferentiationModalPayload,
  testingResultModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';
import { toBackendDate } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type DropdownItemsResponse = OASOutput<NormalizeOAS<typeof oas>, '/dropdown_items', 'get', '200'>;

type CreateAppliedTestProcedureRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/testing_results/applied_test_procedure',
  'post'
>;
type UpdateAppliedTestProcedureRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/testing_results/applied_test_procedure',
  'put'
>;

type InternalDifferentiationResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/testing_results/internal_differentiation/import',
  'get',
  '200'
>;

type DeleteAppliedTestProcedureRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/testing_results/applied_test_procedure',
  'delete'
>;

export function mapCreateAppliedTestProcedure(
  payload: IAppliedTestProcedureCreatePayload
): Pick<CreateAppliedTestProcedureRequest, 'json'> | null {
  const { appliedTestProcedure: testProcedure, personID } = payload;
  if (
    testProcedure.procedure &&
    testProcedure.procedure.id !== undefined &&
    testProcedure.performedTimestamp &&
    testProcedure.testResult &&
    testProcedure.performedBy &&
    testProcedure.performedBy.id !== undefined
  ) {
    return {
      json: {
        performed_by: testProcedure.performedBy.id,
        test_result: testProcedure.testResult,
        performed_timestamp: toBackendDate(testProcedure.performedTimestamp) ?? '',
        procedure_id: testProcedure.procedure.id,
        person_id: personID,
      },
    };
  } else {
    return null;
  }
}

function mapUpdateAppliedTestProcedure(
  payload: IAppliedTestProcedureUpdatePayload
): Pick<UpdateAppliedTestProcedureRequest, 'json'> | null {
  const { appliedTestProcedure: testProcedure, procedureID } = payload;
  if (
    testProcedure.procedure &&
    testProcedure.procedure.id !== undefined &&
    testProcedure.performedTimestamp &&
    testProcedure.testResult &&
    testProcedure.performedBy &&
    testProcedure.performedBy.id !== undefined
  ) {
    return {
      json: {
        performed_by: testProcedure.performedBy.id,
        test_result: testProcedure.testResult,
        performed_timestamp: toBackendDate(testProcedure.performedTimestamp) ?? '',
        procedure_id: testProcedure.procedure.id,
        id: procedureID,
      },
    };
  } else {
    return null;
  }
}

function* deleteAppliedTestProcedure(action: PayloadAction<number>): Generator<any, void, any> {
  try {
    const request: DeleteAppliedTestProcedureRequest = {
      query: {
        applied_test_procedure_id: action.payload,
      },
      ...authAdd(),
    };

    yield call(restCall, '/testing_results/applied_test_procedure', 'delete', request);
    yield put(testingResultActions.reFetchAppliedTestProcedureList());
    yield put(testingResultModalsActions.setDeleteAppliedTestProcedureLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on deleting applied test procedure', error);
    yield put(testingResultModalsActions.setDeleteAppliedTestProcedureLock(LoadingStatus.ERROR));
  }
}

function* updateAppliedTestProcedure(
  action: PayloadAction<IAppliedTestProcedureUpdatePayload>
): Generator<any, void, any> {
  yield put(testingResultModalsActions.setUpdatingAppliedTestProcedureLock(LoadingStatus.LOADING));

  try {
    const mappedAppliedTestProcedure = mapUpdateAppliedTestProcedure(action.payload);

    if (!mappedAppliedTestProcedure) {
      yield put(
        testingResultModalsActions.setUpdatingAppliedTestProcedureLock(LoadingStatus.ERROR)
      );
      return;
    }

    yield call(restCall, '/testing_results/applied_test_procedure', 'put', {
      ...mappedAppliedTestProcedure,
      ...authAdd(),
    });

    yield put(testingResultActions.reFetchAppliedTestProcedureList());
    yield put(testingResultModalsActions.setUpdatingAppliedTestProcedureLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on updating applied test procedure');
    yield put(testingResultModalsActions.setUpdatingAppliedTestProcedureLock(LoadingStatus.ERROR));
  }
}

function* createAppliedTestProcedure(
  action: PayloadAction<IAppliedTestProcedureCreatePayload>
): Generator<any, void, any> {
  yield put(testingResultModalsActions.setCreateAppliedTestProcedureLock(LoadingStatus.LOADING));

  try {
    const mappedAppliedTestProcedure = mapCreateAppliedTestProcedure(action.payload);

    if (!mappedAppliedTestProcedure) {
      yield put(testingResultModalsActions.setCreateAppliedTestProcedureLock(LoadingStatus.ERROR));
      return;
    }

    yield call(restCall, '/testing_results/applied_test_procedure', 'post', {
      ...mappedAppliedTestProcedure,
      ...authAdd(),
    });

    yield put(testingResultActions.reFetchAppliedTestProcedureList());
    yield put(testingResultModalsActions.setCreateAppliedTestProcedureLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on creating applied test procedure', error);
    yield put(testingResultModalsActions.setCreateAppliedTestProcedureLock(LoadingStatus.ERROR));
  }
}

function* fetchTestPerformerList(
  action: PayloadAction<DropdownItemsByFetch>
): Generator<any, void, DropdownItemsResponse> {
  try {
    const response = yield call(restCall, '/dropdown_items', 'get', {
      query: {
        dropdown_table: action.payload,
      },
      ...authAdd(),
    });

    yield put(testingResultModalsActions.setTestPerformerList(response.dropdown_items));
  } catch (error) {
    console.log('Error on specialist list fetching', error);
  }
}

function* fetchTestProcedureList(
  action: PayloadAction<DropdownItemsByFetch>
): Generator<any, void, DropdownItemsResponse> {
  yield put(testingResultModalsActions.setTestProceduresListLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/dropdown_items', 'get', {
      query: {
        dropdown_table: action.payload,
      },
      ...authAdd(),
    });

    yield put(testingResultModalsActions.setTestProceduresList(response.dropdown_items));
    yield put(testingResultModalsActions.setTestProceduresListLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on test procedure list fetching', error);
    yield put(testingResultModalsActions.setTestProceduresListLock(LoadingStatus.ERROR));
  }
}

function* fetchImportInternalDifferentiation(
  action: PayloadAction<IImportInternalDifferentiationModalPayload>
): Generator<any, void, InternalDifferentiationResponse> {
  const { personID } = action.payload;

  try {
    yield put(
      testingResultModalsActions.setImportInternalDifferentiationLock(LoadingStatus.LOADING)
    );

    const response = yield call(
      restCall,
      '/testing_results/internal_differentiation/import',
      'get',
      {
        query: {
          person_id: personID,
          ...authAdd(),
        },
      }
    );

    const internalDifferentiation: IInternalDifferentiation =
      mapInternalDifferentiationResponse(response);

    yield put(testingResultModalsActions.setImportInternalDifferentiation(internalDifferentiation));
    yield put(
      testingResultModalsActions.setImportInternalDifferentiationLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on internal differentiation import', error);
    yield put(testingResultModalsActions.setImportInternalDifferentiationLock(LoadingStatus.ERROR));
  }
}

export const testingResultModalsSagas = [
  takeLatest(testingResultModalsActions.deleteAppliedTestProcedure, deleteAppliedTestProcedure),
  takeLatest(testingResultModalsActions.updateAppliedTestProcedure, updateAppliedTestProcedure),
  takeLatest(testingResultModalsActions.createAppliedTestProcedure, createAppliedTestProcedure),
  takeLatest(testingResultModalsActions.fetchTestPerformerList, fetchTestPerformerList),
  takeLatest(testingResultModalsActions.fetchTestProceduresList, fetchTestProcedureList),
  takeLatest(
    testingResultModalsActions.fetchImportInternalDifferentiation,
    fetchImportInternalDifferentiation
  ),
];
