import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { InternalDifferentiationPosition } from '@/core/enums/testingResult/internalDifferentiationPositionEnum';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import {
  IAppliedTestProcedure,
  IInternalDifferentiation,
  IInternalDifferentiationItem,
  IInternalDifferentiationUpdatePayload,
  ITestingResultFetchPayload,
  testingResultActions,
} from '@/core/redux/slices/functions/testingResult/testingResultSlice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import { isEnumValue } from '@/core/utils/enumUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';
import { IPerson } from '@/types/person';

type InternalDifferentiationResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/testing_results/internal_differentiation',
  'get',
  '200'
>;
type InternalDifferentiationRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/testing_results/internal_differentiation',
  'post'
>;

type AppliedTestProcedureResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/testing_results/applied_test_procedure',
  'get',
  '200'
>;

interface InternalDifferentiationRequestBody {
  person_id: number;
  zero_id?: number;
  plus_one_id?: number;
}

export const mapInternalDifferentiationResponse = (
  response: InternalDifferentiationResponse
): IInternalDifferentiation => {
  return {
    internalDifferentiationList: response.internal_differentiation.map(
      (internalDifferentiation) => ({
        id: internalDifferentiation.id,
        description: internalDifferentiation.description,
        name: internalDifferentiation.name,
        isChecked: internalDifferentiation.is_checked,
        checkValues: internalDifferentiation.check_values,
        pointsFrom: internalDifferentiation.point_from,
        pointsTo: internalDifferentiation.point_to,
        position: isEnumValue(InternalDifferentiationPosition, internalDifferentiation.position)
          ? internalDifferentiation.position
          : InternalDifferentiationPosition.Zero,
      })
    ),
    lastCompetenceAnalysisTimestamp: response.last_competence_analysis_timestamp,
    lastInternalDifferentiationUpdateTimestamp: response.last_internal_differentiation_timestamp,
    isObsolete: response.is_obsolete,
    id: 1,
  };
};

const mapInternalDifferentiationRequest = (
  internalDifferentiation: Record<string, boolean>,
  internalDifferentiationItems: IInternalDifferentiationItem[],
  personID: number
): Pick<InternalDifferentiationRequest, 'json'> => {
  const formKeys = Object.keys(internalDifferentiation);

  const requestBody = formKeys.reduce<InternalDifferentiationRequestBody>(
    (accum, formKey) => {
      if (internalDifferentiation[formKey]) {
        const foundItem = internalDifferentiationItems.find(
          (internalDifferentiationItem) => internalDifferentiationItem.id === parseInt(formKey)
        );

        if (!foundItem) {
          return accum;
        }

        foundItem.position === InternalDifferentiationPosition.PlusOne
          ? (accum['zero_id'] = foundItem.id)
          : (accum['plus_one_id'] = foundItem.id);
      }

      return accum;
    },
    { person_id: personID }
  );

  return {
    json: requestBody,
  };
};

const mapInternalDifferentiation = (
  response: InternalDifferentiationResponse
): IInternalDifferentiation => {
  return {
    internalDifferentiationList: response.internal_differentiation.map(
      (internalDifferentiation) => ({
        id: internalDifferentiation.id,
        description: internalDifferentiation.description,
        name: internalDifferentiation.name,
        isChecked: internalDifferentiation.is_checked,
        checkValues: internalDifferentiation.check_values,
        pointsFrom: internalDifferentiation.point_from,
        pointsTo: internalDifferentiation.point_to,
        position: isEnumValue(InternalDifferentiationPosition, internalDifferentiation.position)
          ? internalDifferentiation.position
          : InternalDifferentiationPosition.Zero,
      })
    ),
    lastCompetenceAnalysisTimestamp: response.last_competence_analysis_timestamp,
    lastInternalDifferentiationUpdateTimestamp: response.last_internal_differentiation_timestamp,
    isObsolete: response.is_obsolete,
    id: 1,
  };
};

const mapAppliedTestProcedures = (
  response: AppliedTestProcedureResponse
): IAppliedTestProcedure[] => {
  return response.applied_test_procedures.map((appliedTestProcedure) => ({
    id: appliedTestProcedure.id,
    procedure: appliedTestProcedure.procedure,
    performedTimestamp: toClientDateInput(appliedTestProcedure.performed_timestamp),
    testResult: appliedTestProcedure.test_result,
    performedBy: appliedTestProcedure.performed_by,
  }));
};

function* fetchAppliedTestProcedures(
  action: PayloadAction<ITestingResultFetchPayload>
): Generator<any, void, AppliedTestProcedureResponse> {
  const { personID } = action.payload;

  yield put(testingResultActions.setAppliedTestProcedureLock(LoadingStatus.LOADING));
  try {
    const response = yield call(restCall, '/testing_results/applied_test_procedure', 'get', {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    });

    const appliedTestProcedures = mapAppliedTestProcedures(response);

    yield put(testingResultActions.setAppliedTestProcedure(appliedTestProcedures));
    yield put(testingResultActions.setAppliedTestProcedureLock(LoadingStatus.LOADED));
  } catch (error) {
    yield put(testingResultActions.setAppliedTestProcedureLock(LoadingStatus.ERROR));
    console.log('Error on applied test procedures fetching', error);
  }
}

function* fetchInternalDifferentiation(
  action: PayloadAction<ITestingResultFetchPayload>
): Generator<any, void, InternalDifferentiationResponse> {
  const { personID } = action.payload;

  try {
    yield put(testingResultActions.setInternalDifferentiationLock(LoadingStatus.LOADING));

    const response = yield call(restCall, '/testing_results/internal_differentiation', 'get', {
      query: {
        person_id: personID,
        ...authAdd(),
      },
    });

    const internalDifferentiation: IInternalDifferentiation = mapInternalDifferentiation(response);

    yield put(testingResultActions.setInternalDifferentiation(internalDifferentiation));
    yield put(testingResultActions.setInternalDifferentiationLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on internal differentiation fetch', error);
    yield put(testingResultActions.setInternalDifferentiationLock(LoadingStatus.ERROR));
  }
}

function* updateDifferentiation(action: PayloadAction<IInternalDifferentiationUpdatePayload>) {
  const { personID, internalDifferentiation, internalDifferentiationItems } = action.payload;

  try {
    yield put(testingResultActions.setUpdateInternalDifferentiationLock(LoadingStatus.LOADING));

    const mappedInternalDifferentiation = mapInternalDifferentiationRequest(
      internalDifferentiation,
      internalDifferentiationItems,
      personID
    );

    yield call(restCall, '/testing_results/internal_differentiation', 'post', {
      query: {
        person_id: personID,
      },
      ...mappedInternalDifferentiation,
      ...authAdd(),
    });

    yield put(testingResultActions.setUpdateInternalDifferentiationLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on internal differentiation fetch', error);
    yield put(testingResultActions.setUpdateInternalDifferentiationLock(LoadingStatus.ERROR));
  }
}

function* reFetchAppliedTestProcedureList() {
  const selectedPerson: IPerson = yield select(functionPageSelectors.selectedPerson);
  const selectedPersonID = selectedPerson?.id;

  if (!selectedPersonID) {
    return;
  }

  yield put(testingResultActions.fetchAppliedTestProcedures({ personID: selectedPersonID }));
}

export const testingResultSagas = [
  takeLatest(testingResultActions.fetchAppliedTestProcedures, fetchAppliedTestProcedures),
  takeLatest(testingResultActions.fetchInternalDifferentiation, fetchInternalDifferentiation),
  takeLatest(testingResultActions.updateInternalDifferentiation, updateDifferentiation),
  takeLatest(testingResultActions.reFetchAppliedTestProcedureList, reFetchAppliedTestProcedureList),
];
