import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@/components/Button/Button';
import { Input } from '@/components/Input/Input';
import { Modal } from '@/components/Overlays/Modal/Modal';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import {
  IUser,
  performanceProfileModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/performanceProfile/performancePrfoileModalSlice';
import { performanceProfileModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/performanceProfile/selector';
import { yup } from '@/core/utils/commonUtils';

type FormValues = {
  selectedUser: string;
  filter: string;
};

const validationSchema = yup.object().shape({
  filter: yup.string().default(''),
  selectedUser: yup.string().required(),
});

const searchInput = 'filter';

export const SelectColleagueModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    performanceProfileModalsSelectors.selectColleagueModal
  );

  if (isOpened) {
    return <ModalContent />;
  }
};

const ModalContent: React.FC = () => {
  const { t: selectColleagueModal } = useTranslation('selectColleagueModal');

  const { payload } = useAppSelector(performanceProfileModalsSelectors.selectColleagueModal);

  const dispatch = useAppDispatch();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const users: IUser[] = payload?.users || [];
  const competenceAnalysisID = payload?.competenceAnalysisID;

  const searchValue: string = methods.watch(searchInput) || '';

  const options: IRadioButtonGroupItem[] = useMemo(
    () =>
      users
        .map((item) => ({
          value: String(item.id),
          element: `${item.surname}, ${item.name}`,
        }))
        .filter((option) => option.element.toLowerCase().includes(searchValue.toLowerCase())),
    [users, searchValue]
  );

  const handleExit = () => {
    dispatch(performanceProfileModalsActions.closeSelectColleagueModal());
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (!competenceAnalysisID) {
      return;
    }

    const selectedUser = Number(data.selectedUser);

    dispatch(
      performanceProfileActions.sendNotification({
        competenceAnalysisID: competenceAnalysisID,
        usersIds: [selectedUser],
      })
    );
    handleExit();
  };

  const renderHeader = (
    <div className='mb-5 px-8 py-5 pb-0'>
      <Typography type='H2' className='block mb-2.5'>
        {selectColleagueModal('title')}
      </Typography>
      <Typography className='block' color='Secondary'>
        {selectColleagueModal('subtitle')}
      </Typography>
    </div>
  );

  const renderFooter = (
    <div className='flex gap-2.5 px-8 py-4'>
      <Button type='button' className='w-full' buttonVariant='Secondary' onClick={handleExit}>
        {selectColleagueModal('buttons.cancel')}
      </Button>
      <Button
        type='submit'
        className='w-full'
        onClick={methods.handleSubmit(onSubmit)}
        disabled={!methods.formState.isValid}
      >
        {selectColleagueModal('buttons.choose')}
      </Button>
    </div>
  );

  return (
    <Modal
      className='w-[700px] h-[600px] p-0'
      onExit={handleExit}
      header={renderHeader}
      footer={renderFooter}
    >
      <FormProvider {...methods}>
        <form>
          <div className='px-8 mb-2.5'>
            <Input
              inputVariant='WidgetSearch'
              placeholder={selectColleagueModal('filter.placeholder')}
              control={methods.control.register(searchInput)}
              className='bg-white'
            />
          </div>
          <div className='h-[540px] overflow-auto'>
            <RadioButtonGroup options={options} name={'selectedUser'} itemClassName='px-8 py-2.5' />
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
