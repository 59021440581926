import React, { useCallback } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import fp from 'lodash/fp';

import { MockWidget } from '@/components/dashboard/widgets/MockWidget/MockWidget';
import { MoveIcon } from '@/components/Icons/MoveIcon';
import { MoveIconDragged } from '@/components/Icons/MoveIconDragged';
import { PrivateImage } from '@/components/PrivateImage/PrivateImage';
import { Typography } from '@/components/Typography/Typography';
import { ActivitiesWidget } from '@/components/widgets/ActivitiesWidget/ActivitiesWidget';
import { ActivitiesWidgetHeader } from '@/components/widgets/ActivitiesWidget/ActivitiesWidgetHeader/ActivitiesWidgetHeader';
import { FavoritePersonWidgetHeader } from '@/components/widgets/PersonWidget/FavoritePersonWidgetHeader/FavoritePersonWidgetHeader';
import { PersonWidget } from '@/components/widgets/PersonWidget/PersonWidget';
import { WidgetsTypes } from '@/core/enums/widgetsTypesEnum';
import { ISortableItem } from '@/types/sortableItem';

import styles from './styles.module.scss';

const initialStyles = {
  x: 0,
  y: 0,
  scale: 1,
};

export const WidgetContainer: React.FC<ISortableItem> = ({
  id,
  icon,
  title,
  onOverlay = false,
  dragged = false,
  widgetName,
}) => {
  const { listeners, setNodeRef, transform, isDragging, setActivatorNodeRef, attributes } =
    useSortable({
      id,
      transition: null,
    });

  const renderWidgetContent = useCallback(() => {
    switch (widgetName) {
      case WidgetsTypes.PERSONS:
        return <PersonWidget />;
      case WidgetsTypes.ACTIVITIES:
        return <ActivitiesWidget />;
      default:
        return <MockWidget />;
    }
  }, [widgetName]);

  const renderWidgetHeaderControl = useCallback(() => {
    switch (widgetName) {
      case WidgetsTypes.ACTIVITIES:
        return <ActivitiesWidgetHeader />;
      case WidgetsTypes.PERSONS:
        return <FavoritePersonWidgetHeader />;
      default:
        return null;
    }
  }, [widgetName]);

  return (
    <motion.div
      className={clsx(styles.container, 'w-full flex flex-col')}
      ref={setNodeRef}
      layoutId={id}
      animate={{
        ...(transform
          ? {
              ...fp.omit(['scaleY', 'scaleX', 'scale'], transform),
              zIndex: isDragging ? 1 : 0,
            }
          : initialStyles),
        outline: onOverlay ? `2px dashed #3575EF` : 'rgb(0, 0, 0) none 0px',
        opacity: dragged ? 0.4 : 1,
        scale: onOverlay ? 1.01 : 1,
      }}
      transition={{
        duration: !isDragging ? 0.25 : 0,
        easings: { type: 'spring' },
        scale: { duration: 0.25 },
        outline: { duration: 0.1 },
        zIndex: { delay: isDragging ? 0 : 0.25 },
      }}
    >
      <div className='flex w-full justify-between items-center'>
        <div className='flex'>
          <div className='w-10 h-10 mr-2'>
            <PrivateImage id={icon || ''} alt='icon' className='w-full' />
          </div>
          <Typography type='H3'>{title}</Typography>
        </div>
        <div className='flex-1'>{renderWidgetHeaderControl()}</div>
        <motion.div
          whileHover={{ cursor: onOverlay ? 'grabbing' : 'grab' }}
          {...listeners}
          {...attributes}
          ref={setActivatorNodeRef}
        >
          {onOverlay ? <MoveIconDragged /> : <MoveIcon />}
        </motion.div>
      </div>
      {renderWidgetContent()}
    </motion.div>
  );
};
