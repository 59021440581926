import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.administrationFunctions.locationManagement;

//locations
const locations = createSelector([selector], (state) => state.locations);
const locationsLock = createSelector([selector], (state) => state.locationsLock);

//departments
const departments = createSelector([selector], (state) => state.departments);
const departmentsLock = createSelector([selector], (state) => state.departmentsLock);

//unattached departments
const unattachedDepartments = createSelector([selector], (state) => state.unattachedDepartments);
const unattachedDepartmentsLock = createSelector(
  [selector],
  (state) => state.unattachedDepartmentsLock
);

export const administrationLocationManagementSelectors = {
  locations,
  locationsLock,
  departments,
  departmentsLock,
  unattachedDepartments,
  unattachedDepartmentsLock,
};
