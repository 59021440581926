import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.userSettings;

const userSettingsLanguage = createSelector([selector], (state) => state.languageCode);
const userSelectedDepartments = createSelector([selector], (state) => state.selectedDepartments);
const userFavoritePersons = createSelector([selector], (state) => state.favoritePersons);

export const userSettingsSelectors = {
  userSettingsLanguage,
  userSelectedDepartments,
  userFavoritePersons,
};
