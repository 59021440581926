import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WorkingTimeType } from '@/core/enums/functions/workingTimeMobility/employmentTypesEnum';
import { WorkingTimePartTimeReason } from '@/core/enums/functions/workingTimeMobility/partTimeReasonsEnum';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IWorkingTimeFetchPayload {
  personID: number;
}

export interface IWorkingTimeUpdatePayload {
  personID: number;
  workingTime: IWorkingTime;
}

export interface IWorkingDay {
  fromDateStamp?: string | null;
  toDateStamp?: string | null;
  pauseDuration?: number | null;
}

export interface IWorkingTimeDayItem {
  id: number;
  name?: string | null;
}

export interface IWorkingTimeWeek {
  sumNet?: number | null;
  sumGross?: number | null;
  sumPauseDuration?: number | null;
  workingDaysCount?: number | null;
  workingDays?: Record<string, IWorkingDay | null> | null;
}

export interface IWorkingTime {
  appliesFromTimeStamp: string;
  workingTimeType: WorkingTimeType;
  partTimeReasonType?: WorkingTimePartTimeReason | null;
  otherPartTimeReasonText?: string | null;
  workingWeek: IWorkingTimeWeek;
}

export interface IWorkingTimeState {
  workingTime: IWorkingTime | null;
  workingTimeLock: LoadingStatus;
  workingTimeDaysList: IWorkingTimeDayItem[] | null;
  updateWorkingTimeLock: LoadingStatus;
  updatedWorkingTime: IWorkingTime | null;
}

const initialState: IWorkingTimeState = {
  workingTime: null,
  workingTimeLock: LoadingStatus.NEVER,
  workingTimeDaysList: null,
  updateWorkingTimeLock: LoadingStatus.NEVER,
  updatedWorkingTime: null,
};

const workingTimeSlice = createSlice({
  name: 'workingTime',
  initialState,
  reducers: {
    fetchWorkingTime: (state, action: PayloadAction<IWorkingTimeFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setWorkingTime: (state, action: PayloadAction<IWorkingTime | null>) => ({
      ...state,
      workingTime: action.payload,
    }),
    setWorkingTimeDaysList: (state, action: PayloadAction<IWorkingTimeDayItem[]>) => ({
      ...state,
      workingTimeDaysList: action.payload,
    }),
    setWorkingTimeLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      workingTimeLock: action.payload,
    }),
    updateWorkingTime: (state, action: PayloadAction<IWorkingTimeUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateWorkingTimeLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateWorkingTimeLock: action.payload,
    }),
    setUpdatedWorkingTime: (state, action: PayloadAction<IWorkingTime | null>) => ({
      ...state,
      updatedWorkingTime: action.payload,
    }),
  },
});

export const workingTimeReducer = workingTimeSlice.reducer;
export const workingTimeActions = workingTimeSlice.actions;
