import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.specialistGeneralDataModals;

const assignedSpecialistsModal = createSelector([selector], (state) => state.assignedSpecialistsModal);
const organizationUnitSpecialistsModal = createSelector([selector], (state) => state.organizationUnitSpecialistsModal);


export const specialistGeneralDataModalsSelectors = {
  assignedSpecialistsModal,
  organizationUnitSpecialistsModal
};
