import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.dropdownItems;

const dropdownItems = createSelector([selector], (state) => state.dropdownItems);

export const dropdownItemsSelectors = {
  dropdownItems,
};
