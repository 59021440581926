import React, { useEffect } from 'react';

import {
  ActivityPlaningProvider,
  IActivityPlaningContext,
} from '@/components/functions/ActivityPlanning/FullFunction/ActivityPlanningContext/ActivityPlanningContext';
import { AppointmentDetails } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/AppointmentDetails';
import { MeasuresAppointmentsList } from '@/components/functions/ActivityPlanning/FullFunction/MeasuresAppointmentsList/MeasuresAppointmentsList';
import { MeasuresList } from '@/components/functions/ActivityPlanning/FullFunction/MeasuresList/MeasuresList';
import { ParticipantsList } from '@/components/functions/ActivityPlanning/FullFunction/ParticipantsList/ParticipantsList';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { useAppDispatch } from '@/core/redux/hooks';
import { activityPlanningAppointmentParticipantsListActions } from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/slice';
import { activityPlanningAppointmentsMeasuresListActions } from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/slice';
import { activityPlaningMeasuresListActions } from '@/core/redux/slices/functions/activityPlanning/measuresList/slice';

export const ActivityPlanningFullFunction: React.FC = () => {
  const initialState: IActivityPlaningContext = {
    isActionsBlocked: false,
    selectedMeasureID: null,
    selectedMeasureName: null,
    selectedAppointmentID: null,
    personLocation: null,
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(activityPlaningMeasuresListActions.setMeasuresList([]));
      dispatch(activityPlanningAppointmentsMeasuresListActions.setMeasuresAppointmentsList([]));
      dispatch(
        activityPlanningAppointmentParticipantsListActions.setAppointmentParticipantsList(null)
      );
    };
  }, []);

  return (
    <FunctionWrapper>
      <FunctionContent className={'p-5'}>
        <div className={'grid grid-cols-[40%,_60%] grid-rows-[35%,_65%] gap-2 h-[1550px]'}>
          <ActivityPlaningProvider state={initialState}>
            <MeasuresList />
            <MeasuresAppointmentsList />
            <ParticipantsList />
            <AppointmentDetails />
          </ActivityPlaningProvider>
        </div>
      </FunctionContent>
    </FunctionWrapper>
  );
};
