import React from 'react';

import { CreateOrganizationalUnitModalContent } from '@/components/Modals/Modals/functions/organizationalUnitsModals/CreateOrganizationalUnitModal/CreateOrganizationalUnitModalContent/CreateOrganizationalUnitModalContent';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

export const CreateOrganizationalUnitModal: React.FC = () => {
  const { isRender: isOpened, payload } = useAppSelector(
    organizationalUnitsModalsSelectors.createOrganizationalUnitModal
  );

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(organizationalUnitsModalsActions.closeCreateOrganizationalUnitModal());
  };

  return isOpened && payload?.personID ? (
    <CreateOrganizationalUnitModalContent personID={payload.personID} handleExit={handleExit} />
  ) : null;
};
