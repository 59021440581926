import React, { useEffect } from 'react';

import { MobilityHeader } from '@/components/functions/Mobility/MobilityHeader/MobilityHeader';
import { MobilityItem } from '@/components/functions/Mobility/MobilityItem/MobilityItem';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { mobilityActions } from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import { mobilitySelectors } from '@/core/redux/slices/functions/workingTimeMobility/mobility/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IMobilitySubForm {
  view: JSX.Element;
  type: MobilityFormTypes;
}

export const Mobility: React.FC = () => {
  const loadingStatus = useAppSelector(mobilitySelectors.mobilityLock);
  const mobilityItems = useAppSelector(mobilitySelectors.mobilityItems);

  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      mobilityActions.fetchMobility({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID]);

  return (
    <FunctionWrapper>
      <FunctionContent>
        <Skeleton trigger={loadingStatus === LoadingStatus.LOADING} rows={10}>
          <div className={'flex flex-col gap-[20px] py-3 px-7 max-w-[856px]'}>
            <MobilityHeader />
            {mobilityItems?.length &&
              mobilityItems.map((mobilityItem) => (
                <MobilityItem {...mobilityItem} personID={selectedPersonID} key={mobilityItem.id} />
              ))}
          </div>
        </Skeleton>
      </FunctionContent>
    </FunctionWrapper>
  );
};
