import React from 'react';
import clsx from 'clsx';

import styles from '@/components/Avatar/styles.module.scss';
import { PrivateImage } from '@/components/PrivateImage/PrivateImage';
import { IAvatar } from '@/types/avatar';

export const Avatar: React.FC<IAvatar> = ({ imageUrl }) => (
  <div className={clsx(styles.avatar, 'flex justify-center items-center')}>
    {<PrivateImage id={imageUrl} alt='user_avatar' className='w-full rounded-full' />}
  </div>
);
