import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import {
  FormRadioButtonGroup,
  IRadioButtonGroupChangeEvent,
} from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import { Typography } from '@/components/Typography/Typography';
import { StatusReportCompetenceAnalysisOrientationTypes } from '@/core/enums/functions/integrationPlan/statusReport/StatusReportCompetenceAnalysisOrientationTypesEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { IStatusReport } from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { colorTypes } from '@/styles/types';

export const CompetenceAnalysis: React.FC = () => {
  const { t: competenceAnalysisTranslations } = useTranslation('statusReport', {
    keyPrefix: 'competenceAnalysis',
  });

  const isAllowChangeBinnDiffInEP = useAppSelector(parametersSelectors.isAllowChangeBinnDiffInEP);

  const dispatch = useAppDispatch();

  const { control } = useFormContext<IStatusReport>();

  const options: IFormRadioButtonGroupItem[] = [
    {
      id: StatusReportCompetenceAnalysisOrientationTypes.isActivityOriented,
      value: StatusReportCompetenceAnalysisOrientationTypes.isActivityOriented,
      component: (
        <label htmlFor={StatusReportCompetenceAnalysisOrientationTypes.isActivityOriented}>
          <Typography>
            {competenceAnalysisTranslations('fields.isActivityOrientation.label')}
          </Typography>
        </label>
      ),
    },
    {
      id: StatusReportCompetenceAnalysisOrientationTypes.isOccupationalFieldOriented,
      value: StatusReportCompetenceAnalysisOrientationTypes.isOccupationalFieldOriented,
      component: (
        <label htmlFor={StatusReportCompetenceAnalysisOrientationTypes.isOccupationalFieldOriented}>
          <Typography>
            {competenceAnalysisTranslations('fields.isOccupationalFieldOriented.label')}
          </Typography>
        </label>
      ),
    },
    {
      id: StatusReportCompetenceAnalysisOrientationTypes.isWorkplaceOriented,
      value: StatusReportCompetenceAnalysisOrientationTypes.isWorkplaceOriented,
      component: (
        <label htmlFor={StatusReportCompetenceAnalysisOrientationTypes.isWorkplaceOriented}>
          <Typography>
            {competenceAnalysisTranslations('fields.isWorkplaceOriented.label')}
          </Typography>
        </label>
      ),
    },
    {
      id: StatusReportCompetenceAnalysisOrientationTypes.isJobProfileOriented,
      value: StatusReportCompetenceAnalysisOrientationTypes.isJobProfileOriented,
      component: (
        <label htmlFor={StatusReportCompetenceAnalysisOrientationTypes.isJobProfileOriented}>
          <Typography>
            {competenceAnalysisTranslations('fields.isJobProfileOriented.label')}
          </Typography>
        </label>
      ),
    },
  ];

  const handleRadioChanged = (_?: any, event?: IRadioButtonGroupChangeEvent) => {
    if (!isAllowChangeBinnDiffInEP) {
      event?.preventDefault();

      dispatch(
        notificationsActions.showNotification({
          notification: {
            description: competenceAnalysisTranslations(
              'notifications.notAllowed.description.label'
            ),
            title: competenceAnalysisTranslations('notifications.notAllowed.title.label'),
          },
        })
      );
    }
  };

  const handleBlockedClick = () => {
    dispatch(
      notificationsActions.showNotification({
        notification: {
          description: competenceAnalysisTranslations('notifications.notAllowed.description.label'),
          title: competenceAnalysisTranslations('notifications.notAllowed.title.label'),
        },
      })
    );
  };

  return (
    <Accordion
      title={<Typography type={'H3'}>{competenceAnalysisTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'flex flex-col mt-4'}>
        <Typography>{competenceAnalysisTranslations('classificationUpToDate.label')}</Typography>
        <div className={'grid grid-cols-4 grid-rows-2 gap-4 mt-4'}>
          <CheckboxItem
            label={
              <Typography type={'16-M'}>
                {competenceAnalysisTranslations('fields.isCompetenceAnalysisUnchanged.label')}
              </Typography>
            }
            fieldName={'isCompetenceAnalysisUnchanged'}
            className={'row-span-2'}
            labelPosition={'left'}
            onClick={isAllowChangeBinnDiffInEP ? undefined : handleBlockedClick}
          />
          <CheckboxItem
            label={
              <Typography type={'16-M'}>
                {competenceAnalysisTranslations('fields.isNewClassification.label')}
              </Typography>
            }
            fieldName={'isNewClassification'}
            className={'row-span-2'}
            labelPosition={'left'}
            onClick={isAllowChangeBinnDiffInEP ? undefined : handleBlockedClick}
          />
          <FormRadioButtonGroup
            containerClassName={'grid grid-cols-2 items-center justify-content-center'}
            className={'row-span-2 col-span-2'}
            control={control.register('selectedOrientation')}
            options={options}
            onChange={handleRadioChanged}
          />
        </div>
      </div>
    </Accordion>
  );
};
