import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Input } from '@/components/Input/Input';
import { useFecthedCategories } from '@/components/Modals/Modals/logbook/hooks/useFetchLogbookCategories';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { logbookActions } from '@/core/redux/slices/logbook/logbookSlice';
import { logbookSelectors } from '@/core/redux/slices/logbook/selectors';
import { logbookModalsActions } from '@/core/redux/slices/modalsSlice/logbook/logbookModalSlice';
import { logbookModalsSelectors } from '@/core/redux/slices/modalsSlice/logbook/selectors';

interface IFilterForm {
  search?: string;
  categoryID?: string;
  isCreatedByMe?: boolean;
}

const ModalContent: React.FC = () => {
  const {t: logbookListModalTranlsations} = useTranslation('logbookListModal')
  const form = useForm<IFilterForm>();

  const logbook = useAppSelector(logbookSelectors.logbook);

  const columns: ITableColumn[] = [
    {
      header: logbookListModalTranlsations('columns.createdTimestamp.label'),
      accessor: 'createdTimestamp',
    },
    {
      header:  logbookListModalTranlsations('columns.createdBy.label'),
      accessor: 'createdBy',
    },
    {
      header: logbookListModalTranlsations('columns.person.label'),
      accessor: 'person',
    },
    {
      header: logbookListModalTranlsations('columns.department.label'),
      accessor: 'department',
    },
    {
      header: logbookListModalTranlsations('columns.category.label'),
      accessor: 'category',
    },
    {
      header: logbookListModalTranlsations('columns.note.label'),
      accessor: 'note',
    },
  ];

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(logbookModalsActions.closeLogbookListModal());
  };

  const fetchList = (data?: IFilterForm) => {
    // dispatch(
    //   logbookActions.fetchLogbookPerson({
    //     search: data?.search,
    //     categoryID: data?.categoryID ? Number(data.categoryID) : undefined,
    //     isCreatedByMe: data?.isCreatedByMe,
    //   })
    // );
  };

  useEffect(() => {
      fetchList()
  }, [])

  useEffect(() => {
    const subscription = form.watch((value) => {
      fetchList(value);
    });

    return () => subscription.unsubscribe();
  }, [form.watch]);

  const { options: categoryOptions } = useFecthedCategories();

  const renderFooter = (
    <div className='flex gap-2.5 px-8 py-4 justify-end'>
      <Button type='button' onClick={handleExit}>
          {logbookListModalTranlsations('buttons.close.label')}
      </Button>
    </div>
  );
  return (
    <Modal onExit={handleExit} footer={renderFooter} layer={4} className='w-[1200px]'>
      <Typography type='H2'>{logbookListModalTranlsations('title.label')}</Typography>

      <FormProvider {...form}>
        <form className='flex gap-4'>
          <div className='flex gap-4 items-center'>
            <div className='flex gap-2 items-center'>
              <Typography>{logbookListModalTranlsations('filter.isCreatedByMe.label')}</Typography>
              <Checkbox name='isCreatedByMe' />
            </div>
            <div className='flex gap-2 items-center'>
              <Typography>{logbookListModalTranlsations('filter.search.label')}</Typography>
              <Input control={form.control.register('search')} />
            </div>

            <div className='flex gap-2 items-center'>
              <Typography>{logbookListModalTranlsations('filter.categoryID.label')}</Typography>
              <SelectForm control={form.control.register('categoryID')} values={categoryOptions} />
            </div>
          </div>
        </form>
      </FormProvider>
      <Table columns={columns} data={logbook} className='my-4 h-[500px]' singleSelect />
    </Modal>
  );
};

export const LogbookListModal: React.FC = () => {
  const isOpened = useAppSelector(logbookModalsSelectors.logbookListModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
