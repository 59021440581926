import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets/typings';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { actionCourseDateRangeTypes } from '@/core/enums/actionCourseDateRangeEnum';
import {
  actionCourseActions,
  IActionCourse,
  IActionCourseFetchPayload,
  IActionCourseUpdatePayload,
  IOrganizationUnitsItem,
} from '@/core/redux/slices/functions/integrationPlan/actionCourse/actionCourseSlice';
import { integrationPlanActions } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import { getSelectedOption } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type ActionCourseResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/action_course',
  'get',
  '200'
>;
type ActionCourseRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/integration_plan/action_course',
  'post'
>;

function* fetchActionCourse(
  action: PayloadAction<IActionCourseFetchPayload>
): Generator<any, void, ActionCourseResponse> {
  yield put(actionCourseActions.setActionCourseLock(LoadingStatus.LOADING));

  const { integrationPlanID, personID } = action.payload;

  try {
    const response = yield call(restCall, '/integration_plan/action_course', 'get', {
      query: {
        person_id: personID,
        integration_plan_id: integrationPlanID,
      },
      ...authAdd(),
    });

    const { action_course, organization_units } = response;

    const dateBlock: Record<string, boolean> = {
      [actionCourseDateRangeTypes.isEndeEV]: action_course.is_ende_ev,
      [actionCourseDateRangeTypes.isEndeBBB1]: action_course.is_ende_bbb1,
      [actionCourseDateRangeTypes.isEndeBBB2]: action_course.is_ende_bbb2,
      [actionCourseDateRangeTypes.isIndividualAppointment]: action_course.is_individual_appointment,
    };

    const actionCourse: IActionCourse = {
      createdTimestamp: toClientDateInput(action_course.created_timestamp),
      dateBlock: getSelectedOption(dateBlock),
      individualAppointmentReason: action_course.individual_appointment_reason,
      observationFromTimestamp: toClientDateInput(action_course.observation_from_timestamp),
      observationToTimestamp: toClientDateInput(action_course.observation_to_timestamp),
      nextCheckTimestamp: toClientDateInput(action_course.next_check_timestamp),
      qualificationGoal: action_course.qualification_goal,
      specialQuestions: action_course.special_questions,
      integrationPlanComment: action_course.integration_plan_comment,
      bbbComment: action_course.bbb_comment,
      apgNote: action_course.apg_note,
      notes: action_course.notes,
    };

    const organizationUnits = organization_units.reduce<IOrganizationUnitsItem[]>(
      (accum, organizationUnit) => {
        return [
          ...accum,
          {
            organizationUnitID: organizationUnit.organization_unit_id,
            fromTimestamp: organizationUnit.from_timestamp ?? '',
            toTimestamp: organizationUnit.to_timestamp ?? '',
          },
        ];
      },
      []
    );

    yield put(actionCourseActions.setActionCourse(actionCourse));
    yield put(actionCourseActions.setOrganizationUnits(organizationUnits));

    yield put(actionCourseActions.setActionCourseLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration plan action course fetching');
    yield put(actionCourseActions.setActionCourseLock(LoadingStatus.ERROR));
  }
}

function* updateActionCourse(
  action: PayloadAction<IActionCourseUpdatePayload>
): Generator<any, void, any> {
  const { integrationPlanID, personID, actionCourse } = action.payload;

  try {
    yield put(actionCourseActions.setUpdateActionCourseLock(LoadingStatus.LOADING));

    const request: ActionCourseRequest = {
      query: {
        person_id: personID,
        integration_plan_id: integrationPlanID,
      },
      json: {
        is_ende_ev: actionCourse?.dateBlock === actionCourseDateRangeTypes.isEndeEV,
        is_ende_bbb1: actionCourse?.dateBlock === actionCourseDateRangeTypes.isEndeBBB1,
        is_ende_bbb2: actionCourse?.dateBlock === actionCourseDateRangeTypes.isEndeBBB2,
        is_individual_appointment:
          actionCourse?.dateBlock === actionCourseDateRangeTypes.isIndividualAppointment,
        notes: actionCourse.notes ?? null,
        individual_appointment_reason: actionCourse?.individualAppointmentReason ?? null,
        apg_note: actionCourse.apgNote ?? null,
        created_timestamp: toBackendDate(actionCourse?.createdTimestamp, true),
        bbb_comment: actionCourse.bbbComment ?? null,
        integration_plan_comment: actionCourse.integrationPlanComment ?? null,
        next_check_timestamp: toBackendDate(actionCourse.nextCheckTimestamp, true),
        observation_from_timestamp: toBackendDate(actionCourse.observationFromTimestamp, true),
        observation_to_timestamp: toBackendDate(actionCourse.observationToTimestamp, true),
        qualification_goal: actionCourse.qualificationGoal ?? null,
        special_questions: actionCourse.specialQuestions ?? null,
      },
      ...authAdd(),
    };

    const updateResponse = yield call(restCall, '/integration_plan/action_course', 'post', {
      ...request,
    });

    yield put(
      integrationPlanActions.checkIntegrationPlanUpdate({
        integrationPlanID,
        updateResponse: updateResponse,
      })
    );

    yield put(actionCourseActions.setUpdateActionCourseLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration plan action course data updating', error);
    yield put(actionCourseActions.setUpdateActionCourseLock(LoadingStatus.ERROR));
  }
}

function* updateWithFetch(
  action: PayloadAction<IActionCourseUpdatePayload>
): Generator<any, void, any> {
  yield call(updateActionCourse, action);

  yield call(fetchActionCourse, action);
}

export const actionCourseSagas = [
  takeLatest(actionCourseActions.fetchActionCourse, fetchActionCourse),
  takeLatest(actionCourseActions.updateActionCourse, updateActionCourse),
  takeLatest(actionCourseActions.updateWithFetchActionCourse, updateWithFetch),
];
