import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  from,
  HttpLink,
  InMemoryCache,
  split,
  UriFunction,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import fp from 'lodash/fp';

import { authAdd } from '@/core/clients/rest';
import { fromRawCookies } from '@/core/utils/cookiesUtil';
import { RefreshTokenDocument, RefreshTokenMutation } from '@/services/graphql/system/graphql';

interface Apollo extends Object {}

let apolloClient: ApolloClient<Apollo>;

const opsName: (start: string) => UriFunction = (start) => (operation) =>
  `${start}?operationName=${operation.operationName}`;

const systemUri = opsName(`${process.env.REACT_APP_DIRECTUS_ADDRESS}/graphql/system`);

const serviceLink = split(
  (operation) => operation.getContext().isSystem,
  new HttpLink({ uri: systemUri }),
  new HttpLink({ uri: opsName(`${process.env.REACT_APP_DIRECTUS_ADDRESS}/graphql`) })
);

const errorTemplate = {
  message: 'Token expired.',
};

const errorLink = onError(({ forward, operation, graphQLErrors, networkError }) => {
  if (
    fp.includes(fp.get('statusCode', networkError), [400, 401]) &&
    typeof window !== 'undefined'
  ) {
    const cookie = fromRawCookies();

    cookie.remove('accessToken');

    const refresh = localStorage?.getItem('refreshToken');

    if (fp.findIndex(errorTemplate, graphQLErrors) && !fp.isEmpty(refresh)) {
      const client = new ApolloClient({
        uri: systemUri,
        cache: new InMemoryCache(),
      });

      client
        .mutate<RefreshTokenMutation>({
          mutation: RefreshTokenDocument,
          variables: {
            token: refresh,
          },
        })
        .then(({ data }) => {
          if (data?.auth_refresh) {
            localStorage.setItem('refreshToken', data.auth_refresh.refresh_token as string);

            const token = data.auth_refresh.access_token;

            cookie.set('accessToken', token);

            const oldHeaders = operation.getContext().headers;

            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${token}`,
              },
            });
          }

          return forward(operation);
        });
    }
  }

  if (fp.includes(fp.get('statusCode', networkError), [403]) && typeof window !== 'undefined') {
    const cookie = fromRawCookies();

    cookie.remove('accessToken');

    window.location.href = '/auth';
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = (token: string | null = null) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...authAdd(token).headers,
      },
    }));

    return forward(operation);
  });

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const createApolloClient = (token: string | null = null) =>
  new ApolloClient({
    // ssrMode: typeof window === 'undefined',
    link: from([new RetryLink({ attempts: { max: 2 } }), errorLink, authLink(token), serviceLink]),
    cache: new InMemoryCache(),
    queryDeduplication: true,
    defaultOptions,
  });

export function initializeApollo(initialState: string | null = null): ApolloClient<Apollo> {
  const _apolloClient = apolloClient ?? createApolloClient(initialState);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps

    const data = fp.mergeWith(
      (obj, src) => (fp.isArray(obj) ? [...src, ...fp.filter(fp.every(src), obj)] : undefined),
      initialState,
      existingCache
    );

    // const data = fp.mergeWith(initialState, existingCache, {
    //     combine arrays using object equality (like in sets)
    // arrayMerge: (destinationArray: any[], sourceArray: any[]) => [
    //     ...sourceArray,
    //     ...destinationArray.filter((d) =>
    //         sourceArray.every((s) => !isEqual(d, s))
    //     ),
    // ],
    // })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function useApollo(initialState: string) {
  return useMemo(() => initializeApollo(initialState), [initialState]);
}
