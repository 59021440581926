import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAdditionalPersonRequirementField } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/AdditionalPersonRequirementModalContent';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';

export const PersonRequirementComment: React.FC<IAdditionalPersonRequirementField> = ({ form }) => {
  const { t: additionalPersonRequirementsModalTranslations } = useTranslation(
    'additionalPersonRequirementModal'
  );

  return (
    <div className={'flex flex-col gap-2'}>
      <Typography>
        {additionalPersonRequirementsModalTranslations('fields.comment.label')}
      </Typography>
      <TextArea control={form.register('comment')} />
    </div>
  );
};
