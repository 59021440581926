import React from 'react';
import { getYear } from 'date-fns';

import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';

const YEAR_OFFSET = 100;

const getYearsArray = (isFutureSelectionBlocked: boolean = false): number[] => {
  const currentYear = getYear(new Date());
  const years: number[] = [];
  const endYear = isFutureSelectionBlocked ? currentYear : currentYear + YEAR_OFFSET;
  for (let year = endYear; year >= currentYear - YEAR_OFFSET; year--) {
    years.push(year);
  }
  return years;
};

interface ICalendarYearSelect {
  setSelectedYear: React.Dispatch<React.SetStateAction<number>>;
  selectedYear: number;
  isFutureSelectionLocked?: boolean;
}

export const CalendarYearSelect: React.FC<ICalendarYearSelect> = ({
  selectedYear,
  setSelectedYear,
  isFutureSelectionLocked,
}) => {
  const yearsArray = getYearsArray(isFutureSelectionLocked);
  const selectOptions: ISelectItem[] = yearsArray.map<ISelectItem>((year) => ({
    id: String(year),
    component: String(year),
    showCheckIcon: false,
  }));

  const handleChange = (id: string) => {
    setSelectedYear(Number(id));
  };

  return (
    <Select
      selectedContainerClassName={'bg-transparent border-none p-1'}
      values={selectOptions}
      initialValueID={String(selectedYear)}
      onChange={handleChange}
      initialScrollToValue={String(selectedYear)}
      isSorted={false}
    />
  );
};
