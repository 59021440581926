export const colorSchema = [
  '#029392',
  '#3ab185',
  '#9ccb86',
  '#e9e29c',
  '#eeb479',
  '#e78471',
  '#d0597e',
  '#005275',
  '#069099',
  '#7bcba2',
  '#fcde9c',
  '#f1746e',
  '#db3977',
  '#7c1d6f',
];
