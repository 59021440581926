import React from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { motion } from 'framer-motion';

import { Accordion, IAccordionHeader } from '@/components/Accordion/Accordion';
import { ArrowIcon } from '@/components/Icons/ArrowIcon';
import { RadioButtonCheckedIcon } from '@/components/Icons/RadioButtonCheckedIcon';
import { RadioButtonUncheckedIcon } from '@/components/Icons/RadioButtonUncheckedIcon';
import { AdministrationLocationManagementMoveItem } from '@/components/Modals/Modals/administrationModals/locationManagement/AdministrationLocationMoveModal/AdministrationLocationManagementMoveItem';
import { Typography } from '@/components/Typography/Typography';
import { IAdministrationLocation } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IAdministrationLocationManagementMoveGroup {
  location: IAdministrationLocation;
  layer: number;
  control: UseControllerProps<FieldValues, string>;
}

const hasSelectedChild = (
  location: IAdministrationLocation,
  isSelected: boolean,
  value: number
): boolean => {
  if (isSelected) return true;
  return (location.locations || []).some(
    (loc) => loc.id === value || hasSelectedChild(loc, isSelected, value)
  );
};

export const AdministrationLocationManagementMoveGroup: React.FC<IAdministrationLocationManagementMoveGroup> =
  ({ location, layer, control }) => {
    const {
      field: { value, onChange },
    } = useController(control);

    const isSelected = value === location.id;

    const AccordionHeader: React.FC<IAccordionHeader> = ({ setIsExpanded, isExpanded }) => {
      const handleExpand = (event: React.MouseEvent) => {
        event.preventDefault();

        setIsExpanded?.(!isExpanded);
      };

      const handleChange = (event: React.MouseEvent<HTMLOrSVGElement>) => {
        event.stopPropagation();

        onChange(location.id);
      };

      return (
        <div
          className={twMerge(
            `w-full flex flex-row items-center
          bg-${colorTypes.White} hover:bg-${colorTypes.HoverLight}
          pr-[30px] py-[10px] gap-4 cursor-pointer border-l-2`,
            isSelected
              ? `border-l-${colorTypes.Blue} bg-${colorTypes.HoverLight}`
              : 'border-l-transparent'
          )}
          style={{ paddingLeft: `${30 + layer * 34}px` }}
          onClick={handleExpand}
        >
          {isSelected ? (
            <RadioButtonCheckedIcon onClick={handleChange} className={`text-${colorTypes.Blue}`} />
          ) : (
            <RadioButtonUncheckedIcon
              onClick={handleChange}
              className={`text-${colorTypes.Blue}`}
            />
          )}
          <Typography type={layer === 0 ? 'H3' : '16-Sb'} className={'flex-1'}>
            {location.name}
          </Typography>
          <motion.div
            className={`cursor-pointer text-${colorTypes.Primary} h-fit`}
            initial={{ rotate: isExpanded ? 180 : 0 }}
            animate={{ rotate: isExpanded ? 180 : 0 }}
          >
            <ArrowIcon />
          </motion.div>
        </div>
      );
    };

    const items = (location.locations || []).filter(
      (_location) => _location.locations && _location.locations?.length < 1
    );
    const groups = (location.locations || []).filter(
      (_location) => _location.locations && _location.locations?.length > 1
    );

    const initialExpanded = hasSelectedChild(location, isSelected, value);

    return (
      <Accordion
        customHeader={AccordionHeader}
        className={'w-full'}
        initialIsExpanded={initialExpanded}
      >
        {items.map((item) => (
          <AdministrationLocationManagementMoveItem
            location={item}
            control={control}
            layer={layer + 1}
            key={item.id}
          />
        ))}
        {groups.map((group) => (
          <AdministrationLocationManagementMoveGroup
            layer={layer + 1}
            location={group}
            control={control}
            key={group.id}
          />
        ))}
      </Accordion>
    );
  };
