import React, { createElement, DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';

import { TypographyTypes } from '@/components/Typography/helpers/typographyTypes';
import { twMerge } from '@/core/utils/tailwindUtil';
import { ColorTypes, colorTypes, TextTypes, textTypes } from '@/styles/types';

interface ITypographyV2 extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  as?: TypographyTypes;
  type?: TextTypes;
  color?: ColorTypes;
  className?: string;
  children?: React.ReactNode;
  htmlFor?: string;
}

export const Typography = forwardRef<HTMLElement, ITypographyV2>(
  (
    { as = 'span', className, children, type = '16-R', color = 'Primary', htmlFor, ...rest },
    ref
  ) => {
    const typographyProps = {
      className: twMerge(textTypes[type], `text-${colorTypes[color]}`, className),
      ...(as === 'label' && htmlFor && { htmlFor }),
      ...rest,
      ref,
    };

    return createElement(as, typographyProps, children);
  }
);
