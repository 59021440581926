import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';

interface IQualificationObjectiveNameExtension {
  objectiveID: string;
  viewMode: 'text' | 'input';
  nameExtension?: string;
  onEnterPress?: (event: React.KeyboardEvent) => void;
}

const NAME_DIVIDER = ' - ';

export const QualificationObjectiveNameExtension: React.FC<IQualificationObjectiveNameExtension> =
  ({ objectiveID, viewMode, onEnterPress }) => {
    const { control, watch } = useFormContext();

    const fieldName = `qualificationsDocumentations.${objectiveID}.qualificationObjectiveExtension`;

    if (viewMode === 'text' && watch(fieldName)) {
      return <Typography>{NAME_DIVIDER + watch(fieldName)}</Typography>;
    }

    if (viewMode === 'input') {
      return <Input control={control.register(fieldName)} onKeyDown={onEnterPress} />;
    }
  };
