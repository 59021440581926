import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import fp from 'lodash/fp';

import { IAdministrationLocation } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';

export interface IAdministrationLocationManagementForm {
  selectedLocation: IAdministrationLocation | null;
  departments: boolean[];
  departmentsSelectAll: boolean;
}

export function useAdministrationLocationManagementForm() {
  const form = useForm<IAdministrationLocationManagementForm>();
  const formValues = form.watch();

  const [formState, setFormState] = useState<IAdministrationLocationManagementForm>(formValues);

  useEffect(() => {
    if (fp.isEqual(formState, formValues)) {
      return;
    }

    let newFormState = { ...formValues };

    if (!fp.isEqual(formValues.departments, formState?.departments)) {
      const isAllSelected = formValues.departments.every((department) => department);

      newFormState = {
        ...formValues,
        departments: formValues.departments,
        departmentsSelectAll: isAllSelected,
      };
    }

    if (formValues.departmentsSelectAll !== formState?.departmentsSelectAll) {
      newFormState = {
        ...formValues,
        departmentsSelectAll: formValues.departmentsSelectAll,
        departments: formValues.departments.map(
          (department) => (department = formValues.departmentsSelectAll)
        ),
      };
    }

    form.reset(newFormState);
    setFormState(newFormState);
  }, [form.watch()]);

  return { form };
}
