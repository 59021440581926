import React from 'react';
import { parseInt } from 'lodash';
import { LabelProps } from 'recharts';

interface ICustomPointLabel {
  isShowPoints?: boolean;
  isShowLabel?: boolean;
  labelProps: LabelProps;
}

export const RadarCustomPointLabel: React.FC<ICustomPointLabel> = (props) => {
  const {
    labelProps: { x, y, value },
    isShowLabel = false,
    isShowPoints = false,
  } = props;

  if (!x || !y) {
    return null;
  }

  const pointRadius = 6;

  const positionX = typeof x === 'string' ? parseInt(x) : x;
  const positionY = typeof y === 'string' ? parseInt(y) : y;

  const containerWidth = 30;
  const containerHeight = 25;

  const dividerWidth = 10;

  const svgWidth = pointRadius + dividerWidth + containerWidth;
  const svgHeight = pointRadius + containerHeight;

  return (
    <svg
      x={positionX - pointRadius}
      y={positionY - svgHeight / 2}
      width={svgWidth}
      height={svgHeight}
    >
      {isShowLabel && (
        <>
          <line
            x1={pointRadius}
            x2={dividerWidth + pointRadius}
            y1={'50%'}
            y2={'50%'}
            strokeWidth={2}
            stroke={'#3575EF'}
          />
          <g transform={`translate(${dividerWidth + pointRadius}, ${-pointRadius / 2})`}>
            <rect
              x={0}
              y={pointRadius}
              width={containerWidth}
              height={containerHeight}
              opacity={1}
              rx={5}
              ry={5}
              fill={'#3575EF'}
            />
            <text
              x={containerWidth / 2}
              y={svgHeight / 2 + pointRadius / 2}
              fill={'white'}
              dominantBaseline={'middle'}
              textAnchor={'middle'}
            >
              {value}
            </text>
          </g>
        </>
      )}
      {isShowPoints && <circle cx={pointRadius} cy={'50%'} r={pointRadius} fill='blue' />}
    </svg>
  );
};
