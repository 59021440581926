import React from 'react';
import clsx from 'clsx';

import { Modal } from '@/components/Overlays/Modal/Modal';
import { DepartmentView } from '@/components/views/person/PersonPage/DepartmentView/DepartmentView';
import { PersonView } from '@/components/views/person/PersonPage/PersonView/PersonView';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { commonModalsActions } from '@/core/redux/slices/modalsSlice/common/commonModalsSlice';
import { commonModalsSelectors } from '@/core/redux/slices/modalsSlice/common/selector';

import styles from './styles.module.scss';

export const AddPersonsModal: React.FC = () => {
  const isOpened = useAppSelector(commonModalsSelectors.addPersonModal);
  const dispatch = useAppDispatch();
  const handleExit = () => {
    dispatch(commonModalsActions.closeAddPersonModal());
  };

  if (isOpened) {
    return (
      <Modal
        onExit={handleExit}
        className={'p-0 pt-[30px]'}
        bgClassName='rounded-b-none'
        containerClassName={'items-end'}
      >
        <div
          className={clsx(styles.container, 'grid grid-cols-2 gap-x-5 overflow-hidden w-[960px]')}
        >
          <DepartmentView />
          <PersonView />
        </div>
      </Modal>
    );
  }

  return null;
};
