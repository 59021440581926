import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  codesICDActions,
  IAvailableCodesICD,
  IAvailableCodesICDFetchPayload,
  ICodeICD,
  ICodesICDFetchPayload,
  ICodesICDUpdatePayload,
  IUpdateCodeICD,
} from './codesICDSlice';

type CodesICDResponse = OASOutput<NormalizeOAS<typeof oas>, '/diagnostics/icd_codes', 'get', '200'>;
type AvailableCodesICDResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/diagnostics/icd_codes/available',
  'get',
  '200'
>;
type CodesICDRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/diagnostics/icd_codes/add_or_delete_icd_codes',
  'post'
>;

const mapUpdatedCodes = (codes: IUpdateCodeICD[]) =>
  codes.map((item) => ({
    code: item.code,
    is_selected: item.isSelected,
  }));

function* fetchCodesICD(
  action: PayloadAction<ICodesICDFetchPayload>
): Generator<any, void, CodesICDResponse> {
  yield put(codesICDActions.setCodesICDStatus(LoadingStatus.LOADING));
  const { payload } = action;

  try {
    const response = yield call(restCall, '/diagnostics/icd_codes', 'get', {
      query: {
        person_id: payload.personID,
        limit: payload.limit,
        offset: payload.offset,
        page: payload.page,
      },
      ...authAdd(),
    });

    const codesICD: ICodeICD[] = response.data || [];
    const totalCount: number = response.total;

    yield put(codesICDActions.setCodesICDData(codesICD));
    yield put(codesICDActions.setCodesICDToatalCount(totalCount));
    yield put(codesICDActions.setCodesICDStatus(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on ICD codes fetching');
    yield put(codesICDActions.setCodesICDStatus(LoadingStatus.ERROR));
  }
}

function* fetchAvailableICDCodes(
  action: PayloadAction<IAvailableCodesICDFetchPayload>
): Generator<any, void, AvailableCodesICDResponse> {
  yield put(codesICDActions.setAvailableICDCodesStatus(LoadingStatus.LOADING));
  const { payload } = action;

  try {
    const response = yield call(restCall, '/diagnostics/icd_codes/available', 'get', {
      query: {
        person_id: payload.personID,
        limit: payload.limit,
        offset: payload.offset,
        page: payload.page,
        is_selected: payload.isSelected,
        search: payload.search,
      },
      ...authAdd(),
    });

    const availableCodesICD: IAvailableCodesICD[] =
      response.data.map((code) => ({
        code: code.code,
        description: code.description,
        id: code.id,
        isSelected: code.is_selected,
      })) || [];

    const availableTotalCount: number = response.total;

    yield put(codesICDActions.setAvailableICDCodesData(availableCodesICD));
    yield put(codesICDActions.setAvailableICDCodesTotalCount(availableTotalCount));
    yield put(codesICDActions.setAvailableICDCodesStatus(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on ICD codes fetching');
    yield put(codesICDActions.setAvailableICDCodesStatus(LoadingStatus.ERROR));
  }
}

function* updateCodesICD(action: PayloadAction<ICodesICDUpdatePayload>): Generator<any, void, any> {
  const { updatedCodes, personID } = action.payload;

  try {
    const request: CodesICDRequest = {
      query: { person_id: personID },
      json: mapUpdatedCodes(updatedCodes),
      ...authAdd(),
    };

    yield call(
      restCall,
      '/diagnostics/icd_codes/add_or_delete_icd_codes',
      'post',
      {
        ...request,
      },
      null,
      true
    );
  } catch (error) {
    console.log('Error on ICD codes data updating', error);
  }
}

export const codesICDSagas = [
  takeLatest(codesICDActions.fetchCodesICD, fetchCodesICD),
  takeLatest(codesICDActions.fetchAvailableICDCodes, fetchAvailableICDCodes),
  takeLatest(codesICDActions.updateCodesICD, updateCodesICD),
];
