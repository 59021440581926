import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  FetchContext,
  usePersonPageStoreSelector,
} from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { PersonDropDownItem } from '@/components/views/person/PersonPage/PersonView/PersonDropDown/PersonDropDownItem/PersonDropDownItem';

import styles from './styles.module.scss';

export const PersonDropDownContent: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.button' });
  const [fetchContext, setFetchContext] = usePersonPageStoreSelector((store) => store.fetchContext);

  const handleRelatedFilterClick = () => {
    const newContext =
      fetchContext === FetchContext.default ? FetchContext.related : FetchContext.default;
    setFetchContext({ fetchContext: newContext });
  };

  return (
    <div className={clsx(styles.dropDownContent, 'flex flex-col items-start justify-start')}>
      <PersonDropDownItem
        text={t('personsAssignedToMe')}
        onClick={handleRelatedFilterClick}
        selected={fetchContext === FetchContext.related}
      />
      <PersonDropDownItem text={t('missingAssessment')} />
      <PersonDropDownItem text={t('openCheckpoints')} />
    </div>
  );
};
