import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

// interfaces

export interface IUserDepartment {
  id: number;
  name: string;
  isAdded: boolean;
}

export interface IUser {
  name?: string | null;
  surname?: string | null;
  id: number;
}

// payloads

export interface IAvailableUserDepartmentsFetchPayload {
  userID: number;
  isOnlyAdded: boolean;
}

export interface IUserDepartmentAddPayload {
  userID: number;
  departmentID: number;
}

export interface IUserDepartmentDeletePayload {
  userID: number;
  departmentID: number;
}

export interface IUsersByDepartmentFetchPayload {
  departmentID: number;
  isAdded: boolean;
}

// state

export interface ICostCentersState {
  // departments
  availableUserDepartments: IUserDepartment[];
  availableUserDepartmentsLock: LoadingStatus;
  updateUserDepartmentsLock: LoadingStatus;
  // users
  departmentUsers: IUser[];
  departmentUsersLock: LoadingStatus;
}

const initialState: ICostCentersState = {
  availableUserDepartments: [],
  departmentUsers: [],
  // locks
  availableUserDepartmentsLock: LoadingStatus.NEVER,
  updateUserDepartmentsLock: LoadingStatus.NEVER,
  departmentUsersLock: LoadingStatus.NEVER,
};

export const costCentersSlice = createSlice({
  name: 'costCenters',
  initialState,
  reducers: {
    // smart goals
    fetchAvailableUserDepartments: (
      state,
      action: PayloadAction<IAvailableUserDepartmentsFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setAvailableUserDepartments: (state, action: PayloadAction<IUserDepartment[]>) => ({
      ...state,
      availableUserDepartments: action.payload,
    }),
    setAvailableUserDepartmentsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      availableUserDepartmentsLock: action.payload,
    }),
    // create
    addUserDepartment: (state, action: PayloadAction<IUserDepartmentAddPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    // delete
    deleteUserDepartment: (state, action: PayloadAction<IUserDepartmentDeletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    // update lock
    setUpdateUserDepartmentsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateUserDepartmentsLock: action.payload,
    }),
    // department users
    fetchDepartmentUsers: (state, action: PayloadAction<IUsersByDepartmentFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setDepartmentUsers: (state, action: PayloadAction<IUser[]>) => ({
      ...state,
      departmentUsers: action.payload,
    }),
    setDepartmentUsersLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      departmentUsersLock: action.payload,
    }),
  },
});

export const costCentersReducer = costCentersSlice.reducer;
export const costCentersActions = costCentersSlice.actions;
