import React from 'react';

import { IIcon } from '@/types/icon';

export const CalendarIcon: React.FC<IIcon> = ({ className, onClick }) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.46914 5.16688C4.08731 5.16688 3.77778 5.47642 3.77778 5.85824V16.92C3.77778 17.3018 4.08731 17.6113 4.46914 17.6113H15.5309C15.9127 17.6113 16.2222 17.3018 16.2222 16.92V5.85824C16.2222 5.47642 15.9127 5.16688 15.5309 5.16688H4.46914ZM2 5.85824C2 4.49458 3.10547 3.38911 4.46914 3.38911H15.5309C16.8945 3.38911 18 4.49458 18 5.85824V16.92C18 18.2836 16.8945 19.3891 15.5309 19.3891H4.46914C3.10547 19.3891 2 18.2836 2 16.92V5.85824Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.5556 1.61133C14.0465 1.61133 14.4444 2.0093 14.4444 2.50022V6.05577C14.4444 6.54669 14.0465 6.94466 13.5556 6.94466C13.0646 6.94466 12.6667 6.54669 12.6667 6.05577V2.50022C12.6667 2.0093 13.0646 1.61133 13.5556 1.61133Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.44444 1.61133C6.93536 1.61133 7.33333 2.0093 7.33333 2.50022V6.05577C7.33333 6.54669 6.93536 6.94466 6.44444 6.94466C5.95352 6.94466 5.55556 6.54669 5.55556 6.05577V2.50022C5.55556 2.0093 5.95352 1.61133 6.44444 1.61133Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 8.72244C2 8.23152 2.39797 7.83355 2.88889 7.83355H17.1111C17.602 7.83355 18 8.23152 18 8.72244C18 9.21336 17.602 9.61133 17.1111 9.61133H2.88889C2.39797 9.61133 2 9.21336 2 8.72244Z'
      fill='currentColor'
    />
  </svg>
);
