import React, { useEffect } from 'react';

import { useAdministrationChecklistSelector } from '@/components/administartionFunctions/checklist/AdministrationChecklistContext/AdministrationChecklistContext';
import { Button } from '@/components/Button/Button';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { administrationCheckListSelectors } from '@/core/redux/slices/administrationFunctions/checkList/selectors';
import {
  administrationChecklistActions,
  IAdministrationChecklistGroup,
} from '@/core/redux/slices/administrationFunctions/checkList/slice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

export const AdministrationChecklistNavigation: React.FC = () => {
  const checklistGroups = useAppSelector(administrationCheckListSelectors.checklistGroups);
  const checklistGroupsLock = useAppSelector(administrationCheckListSelectors.checklistGroupsLock);

  const [selectedGroup, setState] = useAdministrationChecklistSelector(
    (state) => state.selectedChecklistGroup
  );

  const dispatch = useAppDispatch();

  const handleSelectGroup = (group: IAdministrationChecklistGroup) => {
    setState({ selectedChecklistGroup: group });
    setState({ selectedChecklist: null });
    setState({ selectedCheckpoint: null });
  };

  useEffect(() => {
    dispatch(administrationChecklistActions.fetchChecklistGroups());
  }, []);

  useEffect(() => {
    if (!checklistGroups && !checklistGroups[0]) {
      return;
    }

    setState({ selectedChecklistGroup: checklistGroups[0] });
  }, [checklistGroups]);

  return (
    <div
      className={`flex flex-row overflow-x-auto gap-1 border-b border-b-${colorTypes.Stroke} shrink-0`}
    >
      <Skeleton trigger={checklistGroupsLock === LoadingStatus.LOADING} isSingle={true}>
        {checklistGroups &&
          checklistGroups.map((group) => (
            <Button
              onClick={() => handleSelectGroup(group)}
              buttonVariant={group.id === selectedGroup?.id ? 'ActiveTab' : 'Tab'}
              className={'flex-shrink-0'}
              key={group.id}
            >
              {group.name}
            </Button>
          ))}
      </Skeleton>
    </div>
  );
};
