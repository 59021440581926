import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { TimelineItem } from '@/components/Timeline/TimelineItem';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { medicationAdministrationModalsActions } from '@/core/redux/slices/modalsSlice/medicationAdministration/medicationAdministrationModalSlice';
import { medicationAdministrationModalsSelectors } from '@/core/redux/slices/modalsSlice/medicationAdministration/selectors';
import { colorTypes } from '@/styles/types';

export const ModalContent: React.FC = () => {
  const { t: medicationAdministrationHistoryTranslations } = useTranslation(
    'medicationAdministrationHistory'
  );

  const { payload } = useAppSelector(
    medicationAdministrationModalsSelectors.medicationAdministrationHistoryModal
  );
  const dispatch = useAppDispatch();

  const { medication } = payload;

  const handleExit = () => {
    dispatch(medicationAdministrationModalsActions.closeHistorynModal());
  };

  const history = [
    {
      id: 1,
      header: 'Vergabe – 13:30',
      content:
        'Wirkung zu beschleunigen, können Erwachsene die Dosis auf 2 Kapseln (400 mg) erhöhen. ',
    },
    {
      id: 2,
      header: 'Storno – 13:00',
      content:
        'Die empfohlene Dosis für Kinder über 12 und Erwachsene ist 1 Kapsel 200 mg 3-4 mal pro Tag.',
    },
    {
      id: 3,
      header: 'Storno – 13:00',
      content:
        'Die empfohlene Dosis für Kinder über 12 und Erwachsene ist 1 Kapsel 200 mg 3-4 mal pro Tag.',
    },
    {
      id: 4,
      header: 'Storno – 13:00',
      content:
        'Die empfohlene Dosis für Kinder über 12 und Erwachsene ist 1 Kapsel 200 mg 3-4 mal pro Tag.',
    },
    {
      id: 5,
      header: 'Vergabe – 13:30',
      content:
        'Wirkung zu beschleunigen, können Erwachsene die Dosis auf 2 Kapseln (400 mg) erhöhen. ',
    },
  ];

  return (
    <Sidebar onExit={handleExit} position='right' className='w-[716px]'>
      {medication && (
        <>
          <div className='p-8'>
            <Typography type={'H2'}>{medication.personName}</Typography>
          </div>
          <div className={`px-8 py-5 border-y border-y-${colorTypes.StrokeLight}`}>
            <div className='flex flex-col gap-2'>
              <div className='flex gap-10'>
                <Typography className='w-[120px]' color='Secondary'>
                  {medicationAdministrationHistoryTranslations('info.medicationName.label')}
                </Typography>
                <Typography type='16-Sb'>{medication.medicationName}</Typography>
              </div>
              <div className='flex gap-10'>
                <Typography className='w-[120px]' color='Secondary'>
                  {medicationAdministrationHistoryTranslations(
                    'info.administeredOnTimestamp.label'
                  )}
                </Typography>
                <Typography type='16-Sb'>{medication.administeredOnTimestamp}</Typography>
              </div>
              <div className='flex gap-10'>
                <Typography className='w-[120px]' color='Secondary'>
                  {medicationAdministrationHistoryTranslations('info.daytime.label')}
                </Typography>
                <Typography>{medication.daytime}</Typography>
              </div>
            </div>
          </div>

          <div className='flex-grow h-full flex flex-col'>
            <div className='p-8'>
              {history.map((item) => (
                <TimelineItem id={item.id} header={item.header} content={item.content} />
              ))}
            </div>
          </div>
        </>
      )}
    </Sidebar>
  );
};

export const MedicationAdministrationHistoryModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    medicationAdministrationModalsSelectors.medicationAdministrationHistoryModal
  );

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
