import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  LabelList,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from 'recharts';

import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { useRadarChartForm } from '@/components/functions/PerformanceProfile/CompetenciesCharts/CompetenciesRadarChart/hooks/useRadarChartForm';
import { RadarCustomPointLabel } from '@/components/functions/PerformanceProfile/CompetenciesCharts/CustomPointLabel/RadarCustomPointLabel';
import { useChartData } from '@/components/functions/PerformanceProfile/CompetenciesCharts/hooks/useChartData';
import { colorSchema } from '@/core/constants/colors';
import { ICompetenceReviewItem } from '@/core/redux/slices/functions/performanceProfile/slice';

interface IChart {
  data?: ICompetenceReviewItem[];
  domain?: {
    min: number;
    max: number;
  };
}

export const CompetenciesRadarChart: React.FC<IChart> = ({
  data = [],
  domain = { min: 0, max: 4 },
}) => {
  const { t: chartsTranslations } = useTranslation('performanceProfileCharts');

  const { form, showLabel, showAxis } = useRadarChartForm();

  const { chartsData, chartsKeys } = useChartData(data);

  return (
    <>
      <div className={'row-span-2'}>
        <ResponsiveContainer>
          <RadarChart data={chartsData} outerRadius={'80%'}>
            <PolarGrid />
            <PolarAngleAxis dataKey='index' />
            <PolarRadiusAxis
              angle={90}
              tickLine={showAxis}
              tick={showAxis}
              domain={[domain.min, domain.max]}
            />
            <Legend />
            {chartsKeys.map((chartKey, index) => (
              <Radar
                key={chartKey}
                name={chartKey}
                dataKey={chartKey}
                fillOpacity={0.6}
                isAnimationActive={false}
                fill={colorSchema[index]}
              >
                <LabelList
                  dataKey={chartKey}
                  content={(props) => (
                    <RadarCustomPointLabel labelProps={props} isShowLabel={showLabel} />
                  )}
                />
              </Radar>
            ))}
            ,
          </RadarChart>
        </ResponsiveContainer>
      </div>
      <FormProvider {...form}>
        <div
          className={
            'flex flex-col gap-4 h-auto p-4 border rounded-default border-${colorTypes.Pressed}'
          }
        >
          <CheckboxItem
            label={chartsTranslations('fields.showLabels.label')}
            fieldName={'showLabel'}
            labelPosition={'right'}
          />
          <CheckboxItem
            label={chartsTranslations('fields.showAxis.label')}
            fieldName={'showAxis'}
            labelPosition={'right'}
          />
        </div>
      </FormProvider>
    </>
  );
};
