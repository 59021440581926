import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDevelopmentReportReviewSupportForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/ReviewSupport/helper/reviewSupportForm';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IDevelopmentReportReviewSupportFetchPayload {
  developmentReportID: number;
}

export interface IImportedDevelopmentReportReviewSupportFetchPayload {
  personID: number;
}

export interface IDevelopmentReportReviewSupportUpdatePayload {
  developmentReportID: number;
  personID: number;
  formValues: IDevelopmentReportReviewSupportForm;
}

export interface IDevelopmentReportReviewSupportState {
  developmentReportReviewSupport: IDevelopmentReportReviewSupportForm | null;
  developmentReportReviewSupportLock: LoadingStatus;
  importedDevelopmentReportReviewSupport: IDevelopmentReportReviewSupportForm | null;
  importedDevelopmentReportReviewSupportLock: LoadingStatus;
  fetchPayload: IDevelopmentReportReviewSupportFetchPayload | null;
  importPayload: IImportedDevelopmentReportReviewSupportFetchPayload | null;
  updatePayload: IDevelopmentReportReviewSupportUpdatePayload | null;
}

const initialState: IDevelopmentReportReviewSupportState = {
  developmentReportReviewSupport: null,
  developmentReportReviewSupportLock: LoadingStatus.NEVER,
  importedDevelopmentReportReviewSupport: null,
  importedDevelopmentReportReviewSupportLock: LoadingStatus.NEVER,
  fetchPayload: null,
  importPayload: null,
  updatePayload: null,
};

const developmentReportReviewSupportSlice = createSlice({
  name: 'developmentReportReviewSupport',
  initialState,
  reducers: {
    fetchDevelopmentReportReviewSupport: (
      state,
      action: PayloadAction<IDevelopmentReportReviewSupportFetchPayload>
    ) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setDevelopmentReportReviewSupport: (
      state,
      action: PayloadAction<IDevelopmentReportReviewSupportForm | null>
    ) => ({
      ...state,
      developmentReportReviewSupport: action.payload,
    }),
    setDevelopmentReportReviewSupportLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      developmentReportReviewSupportLock: action.payload,
    }),
    updateDevelopmentReportReviewSupport: (
      state,
      action: PayloadAction<IDevelopmentReportReviewSupportUpdatePayload>
    ) => ({
      ...state,
      updatePayload: action.payload,
    }),
    fetchImportedDevelopmentReportReviewSupport: (
      state,
      action: PayloadAction<IImportedDevelopmentReportReviewSupportFetchPayload>
    ) => ({
      ...state,
      importPayload: action.payload,
    }),
    setImportedDevelopmentReportReviewSupport: (
      state,
      action: PayloadAction<IDevelopmentReportReviewSupportForm | null>
    ) => ({
      ...state,
      importedDevelopmentReportReviewSupport: action.payload,
    }),
    setImportedDevelopmentReportReviewSupportLock: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => ({
      ...state,
      importedDevelopmentReportReviewSupportLock: action.payload,
    }),
  },
});

export const developmentReportReviewSupportReducer = developmentReportReviewSupportSlice.reducer;
export const developmentReportReviewSupportActions = developmentReportReviewSupportSlice.actions;
