import React, { useEffect, useState } from 'react';
import { isAfter } from 'date-fns';
import fp from 'lodash/fp';

import { Calendar } from '../Calendar/Calendar';

interface DatePickerProps {
  type: 'single' | 'range';
  onChange: (date: Date) => void;
  selectedDate: Date | null;
  isFutureSelectionLocked?: boolean;
}

const CalendarRangePicker: React.FC<DatePickerProps> = ({
  type,
  onChange,
  selectedDate,
  isFutureSelectionLocked,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(selectedDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const handleDateClick = (date: Date) => {
    const today = new Date();
    const isInFuture = isAfter(date, today);

    if (isFutureSelectionLocked && isInFuture) {
      return;
    }

    if (type === 'single') {
      setSelectedStartDate(date);
    }
  };

  useEffect(() => {
    setSelectedStartDate(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (!selectedStartDate) {
      return;
    }

    if (fp.isEqual(selectedDate, selectedStartDate)) {
      return;
    }

    onChange(selectedStartDate);
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className='flex'>
      <div className='p-5'>
        {type === 'range' && (
          <div className='flex gap-[50px]'>
            <Calendar
              prevMonth
              selectedStartDate={selectedStartDate}
              selectedEndDate={null}
              onDateChange={handleDateClick}
              isFutureSelectionLocked={isFutureSelectionLocked}
            />
            <Calendar
              nextMonth
              selectedStartDate={null}
              selectedEndDate={selectedEndDate}
              onDateChange={handleDateClick}
              isFutureSelectionLocked={isFutureSelectionLocked}
            />
          </div>
        )}
        {type === 'single' && (
          <Calendar
            prevMonth
            nextMonth
            selectedStartDate={selectedStartDate}
            selectedEndDate={null}
            onDateChange={handleDateClick}
            isFutureSelectionLocked={isFutureSelectionLocked}
          />
        )}
      </div>
    </div>
  );
};

export default CalendarRangePicker;
