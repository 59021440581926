import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  administrationLocationManagementActions,
  IAdministrationDepartment,
  IAdministrationDepartmentDeletePayload,
  IAdministrationDepartmentFetchPayload,
  IAdministrationDepartmentUpdateLocationPayload,
  IAdministrationDepartmentUpdateNamePayload,
  IAdministrationLocation,
  IAdministrationLocationDeletePayload,
  IAdministrationLocationUpdateNamePayload,
  IAdministrationLocationUpdateParentPayload,
  IAdministrationUnattachedDepartment,
} from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type DepartmentRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/location/department', 'get'>;

type DepartmentResponse = OASOutput<NormalizeOAS<typeof oas>, '/location/department', 'get', '200'>;

type UnattachedDepartmentRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/department/unattached',
  'get'
>;

type UnattachedDepartmentResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/department/unattached',
  'get',
  '200'
>;

type UpdateLocationParentRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/location/change_parent',
  'post'
>;
type DeleteLocationRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/location', 'delete'>;
type UpdateLocationNameRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/location', 'put'>;

type UpdateDepartmentNameRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/department', 'put'>;
type DeleteDepartmentRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/department', 'delete'>;
type UpdateDepartmentLocationRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/location/department',
  'post'
>;

function mapToAdministrationLocation(responseData: any[]): IAdministrationLocation[] {
  return responseData.map((item) => ({
    id: item.id,
    name: item.name,
    locationsCount: item.locations_count,
    departmentsCount: item.departments_count,
    isDeletable: item.is_deletable,
    locations: item.locations ? mapToAdministrationLocation(item.locations) : null,
  }));
}

function* fetchLocations(): Generator<any, void, any> {
  try {
    yield put(administrationLocationManagementActions.setLocationsLock(LoadingStatus.LOADING));

    const response = yield call(restCall, '/location', 'get', { ...authAdd() });

    const mappedResponse = mapToAdministrationLocation(response.locations);

    yield put(administrationLocationManagementActions.setLocations(mappedResponse));
    yield put(administrationLocationManagementActions.setLocationsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on administration locations fetch', error);
    yield put(administrationLocationManagementActions.setLocationsLock(LoadingStatus.ERROR));
  }
}

function* fetchDepartments(
  action: PayloadAction<IAdministrationDepartmentFetchPayload>
): Generator<any, void, DepartmentResponse> {
  const { locationID } = action.payload;

  try {
    yield put(administrationLocationManagementActions.setDepartmentsLock(LoadingStatus.LOADING));

    const request: DepartmentRequest = {
      query: {
        location_id: locationID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/location/department', 'get', request);

    const mappedResponse: IAdministrationDepartment[] =
      response.departments.map<IAdministrationDepartment>((department) => ({
        id: department.id,
        name: department.name,
        isDeletable: department.is_deletable,
      }));

    yield put(administrationLocationManagementActions.setDepartments(mappedResponse));
    yield put(administrationLocationManagementActions.setDepartmentsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on administration departments fetch', error);
    yield put(administrationLocationManagementActions.setDepartmentsLock(LoadingStatus.ERROR));
  }
}

function* fetchUnattachedDepartments(): Generator<any, void, UnattachedDepartmentResponse> {
  try {
    yield put(
      administrationLocationManagementActions.setUnattachedDepartmentsLock(LoadingStatus.LOADING)
    );

    const request: UnattachedDepartmentRequest = {
      ...authAdd(),
    };

    const response = yield call(restCall, '/department/unattached', 'get', request);

    const mappedResponse: IAdministrationUnattachedDepartment[] =
      response.departments.map<IAdministrationUnattachedDepartment>((department) => ({
        id: department.id,
        name: department.name,
      }));

    yield put(administrationLocationManagementActions.setUnattachedDepartments(mappedResponse));
    yield put(
      administrationLocationManagementActions.setUnattachedDepartmentsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on administration departments fetch', error);
    yield put(
      administrationLocationManagementActions.setUnattachedDepartmentsLock(LoadingStatus.ERROR)
    );
  }
}

function* updateLocationParent(
  action: PayloadAction<IAdministrationLocationUpdateParentPayload>
): Generator<any, void, any> {
  const { locationID, targetID } = action.payload;

  try {
    const request: UpdateLocationParentRequest = {
      json: [
        {
          id: locationID,
          parent_id: targetID,
        },
      ],
      ...authAdd(),
    };

    yield call(restCall, '/location/change_parent', 'post', request);
    yield put(
      notificationsActions.showNotification({
        notification: {
          title: 'warning',
          description: 'success',
          delay: 2500,
        },
      })
    );
  } catch (error) {
    console.log('Error on location parent update', error);
    yield put(
      notificationsActions.showNotification({
        notification: {
          title: 'warning',
          description: 'error',
          permanent: true,
        },
      })
    );
  }
}

function* deleteLocation(
  action: PayloadAction<IAdministrationLocationDeletePayload>
): Generator<any, void, any> {
  const { locationID } = action.payload;

  try {
    const request: DeleteLocationRequest = {
      query: {
        location_id: locationID,
      },
      ...authAdd(),
    };

    yield call(restCall, '/location', 'delete', request);
  } catch (error) {
    console.log('Error on location delete', error);
  }
}

function* updateLocationName(
  action: PayloadAction<IAdministrationLocationUpdateNamePayload>
): Generator<any, void, any> {
  const { locationID, name } = action.payload;

  try {
    const request: UpdateLocationNameRequest = {
      json: {
        id: locationID,
        name: name,
      },
      ...authAdd(),
    };

    yield call(restCall, '/location', 'put', request);
  } catch (error) {
    console.log('Error on location name update', error);
  }
}

function* updateDepartmentName(
  action: PayloadAction<IAdministrationDepartmentUpdateNamePayload>
): Generator<any, void, any> {
  const { departmentID, name } = action.payload;

  try {
    const request: UpdateDepartmentNameRequest = {
      json: {
        id: departmentID,
        name: name,
      },
      ...authAdd(),
    };

    yield call(restCall, '/department', 'put', request);
  } catch (error) {
    console.log('Error on department name update', error);
  }
}

function* deleteDepartment(
  action: PayloadAction<IAdministrationDepartmentDeletePayload>
): Generator<any, void, any> {
  const { departments } = action.payload;

  try {
    const request: DeleteDepartmentRequest = {
      json: departments.map((department) => department.id),
      ...authAdd(),
    };

    yield call(restCall, '/department', 'delete', request);
  } catch (error) {
    console.log('Error on department delete', error);
  }
}

function* updateDepartmentLocation(
  action: PayloadAction<IAdministrationDepartmentUpdateLocationPayload>
): Generator<any, void, any> {
  const { locationID, departments } = action.payload;

  try {
    const request: UpdateDepartmentLocationRequest = {
      json: departments?.map<UpdateDepartmentLocationRequest['json'][0]>((department) => ({
        department_id: department.id,
        location_id: locationID,
      })),
      ...authAdd(),
    };

    yield call(restCall, '/location/department', 'post', request);
    yield put(
      notificationsActions.showNotification({
        notification: {
          title: 'warning',
          description: 'success',
          delay: 2500,
        },
      })
    );
  } catch (error) {
    console.log('Error on location parent update', error);
    yield put(
      notificationsActions.showNotification({
        notification: {
          title: 'warning',
          description: 'error',
          permanent: true,
        },
      })
    );
  }
}

export const administrationLocationManagementSagas = [
  takeLatest(administrationLocationManagementActions.fetchLocations, fetchLocations),
  takeLatest(administrationLocationManagementActions.fetchDepartments, fetchDepartments),
  takeLatest(
    administrationLocationManagementActions.fetchUnattachedDepartments,
    fetchUnattachedDepartments
  ),
  takeLatest(administrationLocationManagementActions.updateLocationParent, updateLocationParent),
  takeLatest(administrationLocationManagementActions.deleteLocation, deleteLocation),
  takeLatest(administrationLocationManagementActions.updateLocationName, updateLocationName),
  takeLatest(administrationLocationManagementActions.updateDepartmentName, updateDepartmentName),
  takeLatest(administrationLocationManagementActions.deleteDepartment, deleteDepartment),
  takeLatest(
    administrationLocationManagementActions.updateDepartmentLocation,
    updateDepartmentLocation
  ),
];
