import React from 'react';

export const ArrowIcon: React.FC = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4 8L9.16366 12.6942C9.35437 12.8676 9.64563 12.8676 9.83634 12.6942L15 8'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);
