import React from 'react';
import clsx from 'clsx';

import { Typography } from '@/components/Typography/Typography';
import { IFunctionTypeCard } from '@/types/functions';

import styles from './styles.module.scss';

export const FunctionTypeCard: React.FC<IFunctionTypeCard> = ({
  selected,
  handleTypeChange,
  type,
  text,
  badgeColor,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleTypeChange(type);
    }
  };

  const handleOnClick = () => {
    handleTypeChange(type);
  };

  return (
    <div
      className={clsx(styles.card, 'flex flex-col justify-between', {
        [styles.selected]: selected,
      })}
      onClick={handleOnClick}
      role='button'
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <div
        className={clsx(styles.placeholder, 'self-end')}
        style={{ backgroundColor: badgeColor }}
      />
      <Typography type='16-Sb' color={selected ? 'White' : 'Primary'} className='self-start'>
        {text}
      </Typography>
    </div>
  );
};
