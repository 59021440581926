import { createClient, NormalizeOAS, OASClient } from 'fets';

import { fromRawCookies } from '@/core/utils/cookiesUtil';
import { handleRequestError } from '@/core/utils/errorHandler/handleRequestError';
import openapi from '@/services/rest/base/openapi';

export type Normalized = NormalizeOAS<typeof openapi>;
export const client = createClient<Normalized>({
  endpoint: process.env.REACT_APP_PYTHON_ADDRESS,
});

export const authAdd = (customToken: string | null = null) => {
  const token = fromRawCookies().get('accessToken');

  return {
    headers: {
      Authorization: `Bearer ${customToken || token || ''}` as `Bearer ${string}`,
    },
  };
};

export type ClientType = OASClient<Normalized>;

export const restCall = async <
  Path extends keyof ClientType
  // Method extends keyof ClientType[Path],
  // Params extends Omit<'headers', Parameters<ClientType[Path][Method]>[0]>,
  // RType extends any
  // RType extends ReturnType<ReturnType<ClientType[Path][Method]>['json']>
>(
  path: Path,
  method: string,
  data: any,
  token: string | null = null,
  rawResponse: boolean | null = false
) => {
  const response = await (client as any)[path][method]({
    ...data,
    ...authAdd(token),
  });

  switch (response.status) {
    case 204: {
      return response;
    }
    case 422: {
      const resp = await response.json();
      throw new Error(resp.detail[0].msg);
    }
    case 400: {
      const parsedResponse = await response.json();
      await handleRequestError(parsedResponse.code);
      throw parsedResponse;
    }
    default:
      return rawResponse ? response : await response.json();
  }
};
