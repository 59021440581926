import { DevelopmentReportVisionAndGoalsTypes } from '@/core/enums/functions/developmentReport/developmentReportVisionAndGoalsTypesEnum';

export interface IDevelopmentReportVisionAndGoal {
  lfdNr: number;
  viewType: DevelopmentReportVisionAndGoalsTypes;
  ownView?: string | null;
  representativeView?: string | null;
}

export interface IDevelopmentReportVisionAndGoalsForm {
  visionAndGoals: IDevelopmentReportVisionAndGoal[];
}

export const visionAndGoalsDefaultValues: IDevelopmentReportVisionAndGoalsForm = {
  visionAndGoals: [],
};
