import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { medicationAdministrationActions } from '@/core/redux/slices/medicationAdministration/medicationAdministrationSlice';
import { medicationAdministrationModalsActions } from '@/core/redux/slices/modalsSlice/medicationAdministration/medicationAdministrationModalSlice';
import { medicationAdministrationModalsSelectors } from '@/core/redux/slices/modalsSlice/medicationAdministration/selectors';

const ModalContent: React.FC = () => {
  const { payload } = useAppSelector(
    medicationAdministrationModalsSelectors.confirmMarkAdministrationModal
  );
  const { t: confirmMarkModalTranslations } = useTranslation('confirmMarkModal');

  const form = useForm({
    defaultValues: {
      administrationRemark: '',
    },
  });

  const { control } = form;

  const medicationID = payload?.medicationID;
  const isGiven = payload?.isGiven ?? false;

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(medicationAdministrationModalsActions.closeConfirmMarknModal());
  };

  const markMedication = (id: number | undefined, administrationRemark: string) => {
    if (!id) {
      return;
    }

    dispatch(
      medicationAdministrationActions.markAdministrated({
        medicationID: id,
        administrationRemark: administrationRemark,
        isGiven: isGiven,
      })
    );

    handleExit();
  };

  const renderFooter = (
    <div className='flex gap-2.5 px-8 py-4 justify-end'>
      <Button
        type='button'
        onClick={() => markMedication(medicationID, form.getValues('administrationRemark'))}
      >
        {confirmMarkModalTranslations('buttons.save.label')}
      </Button>
      <Button type='button' onClick={handleExit} buttonVariant='Secondary'>
        {confirmMarkModalTranslations('buttons.cancel.label')}
      </Button>
    </div>
  );

  return (
    <Modal onExit={handleExit} className='w-[700px]' footer={renderFooter}>
      <div className='flex flex-col gap-8'>
        <Typography type={'H3'}>
          {confirmMarkModalTranslations(
            payload?.isOnlyOnDemand ? 'title.give.label' : 'title.notGive.label'
          )}
        </Typography>
        <FormProvider {...form}>
          <TextArea control={control.register('administrationRemark')} className='min-h-[140px]' />
        </FormProvider>
      </div>
    </Modal>
  );
};

export const ConfirmMarkAdministrationModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    medicationAdministrationModalsSelectors.confirmMarkAdministrationModal
  );

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
