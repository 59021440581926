import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { IAdditionalPersonRequirementField } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/AdditionalPersonRequirementModalContent';
import { Typography } from '@/components/Typography/Typography';

interface IPersonRequirementDateBlock extends IAdditionalPersonRequirementField {
  approvedFromIsOpened: boolean;
  approvedToIsOpened: boolean;
}

export const PersonRequirementDateBlock: React.FC<IPersonRequirementDateBlock> = ({
  form,
  approvedFromIsOpened,
  approvedToIsOpened,
}) => {
  const { t: additionalPersonRequirementsModalTranslations } = useTranslation(
    'additionalPersonRequirementModal'
  );

  return (
    <div className={'flex flex-row gap-3'}>
      <div className={'flex flex-col gap-2'}>
        <Typography>
          {additionalPersonRequirementsModalTranslations('fields.appliedOn.label')}
        </Typography>
        <DatePicker control={form.register('applicationDate')} />
      </div>
      <div className={'flex flex-col gap-2'}>
        <Typography>
          {additionalPersonRequirementsModalTranslations('fields.approvedFrom.label')}
        </Typography>
        <DatePicker control={form.register('approvedFrom')} isOpen={approvedFromIsOpened} />
      </div>
      <div className={'flex flex-col gap-2'}>
        <Typography>
          {additionalPersonRequirementsModalTranslations('fields.approvedUntil.label')}
        </Typography>
        <DatePicker control={form.register('approvedTo')} isOpen={approvedToIsOpened} />
      </div>
    </div>
  );
};
