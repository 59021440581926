import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IAdministrationLocation {
  id: number;
  name: string;
  locationsCount: number;
  departmentsCount: number;
  isDeletable: boolean;
  locations?: IAdministrationLocation[] | null;
}

export interface IAdministrationLocationUpdateParentPayload {
  locationID: number;
  targetID: number;
}

export interface IAdministrationLocationDeletePayload {
  locationID: number;
}

export interface IAdministrationLocationUpdateNamePayload {
  locationID: number;
  name: string;
}

export interface IAdministrationDepartment {
  id: number;
  name: string;
  isDeletable: boolean;
}

export interface IAdministrationUnattachedDepartment {
  id: number;
  name: string;
}

export interface IAdministrationDepartmentFetchPayload {
  locationID: number;
}

export interface IAdministrationDepartmentDeletePayload {
  departments: IAdministrationDepartment[];
}

export interface IAdministrationDepartmentUpdateNamePayload {
  departmentID: number;
  name: string;
}

export interface IAdministrationDepartmentUpdateLocationPayload {
  departments: IAdministrationDepartment[];
  locationID: number;
}

export interface IAdministrationLocationManagementState {
  locations: IAdministrationLocation[];
  locationsLock: LoadingStatus;
  departments: IAdministrationDepartment[];
  departmentsLock: LoadingStatus;
  unattachedDepartments: IAdministrationUnattachedDepartment[];
  unattachedDepartmentsLock: LoadingStatus;
}

const initialState: IAdministrationLocationManagementState = {
  locations: [],
  locationsLock: LoadingStatus.NEVER,
  departments: [],
  departmentsLock: LoadingStatus.NEVER,
  unattachedDepartments: [],
  unattachedDepartmentsLock: LoadingStatus.NEVER,
};

const administrationLocationManagementSlice = createSlice({
  name: 'administrationLocationManagement',
  initialState,
  reducers: {
    // locations
    fetchLocations: (state) => state,
    setLocations: (state, action: PayloadAction<IAdministrationLocation[]>) => ({
      ...state,
      locations: action.payload,
    }),
    setLocationsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      locationsLock: action.payload,
    }),
    updateLocationParent: (
      state,
      action: PayloadAction<IAdministrationLocationUpdateParentPayload>
    ) => state,
    deleteLocation: (state, action: PayloadAction<IAdministrationLocationDeletePayload>) => state,
    updateLocationName: (state, action: PayloadAction<IAdministrationLocationUpdateNamePayload>) =>
      state,

    //departments
    fetchDepartments: (state, action: PayloadAction<IAdministrationDepartmentFetchPayload>) =>
      state,
    setDepartments: (state, action: PayloadAction<IAdministrationDepartment[]>) => ({
      ...state,
      departments: action.payload,
    }),
    setDepartmentsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      departmentsLock: action.payload,
    }),

    //unattached departments
    fetchUnattachedDepartments: (state) => state,
    setUnattachedDepartments: (
      state,
      action: PayloadAction<IAdministrationUnattachedDepartment[]>
    ) => ({
      ...state,
      unattachedDepartments: action.payload,
    }),
    setUnattachedDepartmentsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      unattachedDepartmentsLock: action.payload,
    }),
    updateDepartmentLocation: (
      state,
      action: PayloadAction<IAdministrationDepartmentUpdateLocationPayload>
    ) => state,
    deleteDepartment: (state, action: PayloadAction<IAdministrationDepartmentDeletePayload>) =>
      state,
    updateDepartmentName: (
      state,
      action: PayloadAction<IAdministrationDepartmentUpdateNamePayload>
    ) => state,
  },
});

export const administrationLocationManagementReducer =
  administrationLocationManagementSlice.reducer;
export const administrationLocationManagementActions =
  administrationLocationManagementSlice.actions;
