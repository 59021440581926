import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { useDialog } from '@/core/hooks/useDialog';
import { useAppDispatch } from '@/core/redux/hooks';
import {
  administrationLocationManagementActions,
  IAdministrationLocation,
} from '@/core/redux/slices/administrationFunctions/locationManagement/slice';

export function useConfirmDeleteAdministrationLocationDialog(location: IAdministrationLocation) {
  const { t: administrationLocationManagementTranslations } = useTranslation(
    'administrationLocationManagement',
    { keyPrefix: 'locations.dialogs.deleteLocation.confirm' }
  );

  const dispatch = useAppDispatch();

  const handleDeleteLocation = () => {
    dispatch(
      administrationLocationManagementActions.deleteLocation({
        locationID: location.id,
      })
    );

    closeDialog();
  };

  const { renderDialogContainer, openDialog, closeDialog } = useDialog({
    title: (
      <Typography type={'H2'}>
        {administrationLocationManagementTranslations('title.label')}
      </Typography>
    ),
    description: (
      <Typography>{administrationLocationManagementTranslations('description.label')}</Typography>
    ),
    confirmButton: (
      <Button onClick={handleDeleteLocation} className={'w-full'}>
        {administrationLocationManagementTranslations('buttons.confirm.label')}
      </Button>
    ),
    cancelButton: (
      <Button onClick={() => closeDialog()} buttonVariant={'Secondary'} className={'w-full'}>
        {administrationLocationManagementTranslations('buttons.cancel.label')}
      </Button>
    ),
    className: 'w-[500px] flex flex-col gap-4 items-center text-center',
    buttonsContainerClassName: 'w-full flex-row-reverse mt-3',
  });

  return { renderDialogContainer, openDialog };
}
