import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { AdministrationFunctionContainer } from '@/components/views/administration/AdministrationFunctionContainer/AdministrationFunctionContainer';

export const AdministrationPage: React.FC = () => {
  const { t: administrationPageTranslations } = useTranslation('administration');
  return (
    <div className={'flex flex-col px-[40px] justify-end w-full overflow-hidden'}>
      <div className={'h-[calc(100vh_-_138px)] pt-[30px] rounded-t-[30px] bg-white flex flex-col'}>
        <Typography type={'H1'} className={'ml-[20px]'}>
          {administrationPageTranslations('title.label')}
        </Typography>
        <AdministrationFunctionContainer />
      </div>
    </div>
  );
};
