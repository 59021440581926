import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { InternshipsDocumentationItem } from '@/components/forms/QualificationDocumentation/InternshipsDocumentation/InternshipsDocumentationItem/InternshipsDocumentationItem';
import { TableHeader } from '@/components/forms/QualificationDocumentation/InternshipsDocumentation/TableHeader/TableHeader';
import { useIntegrationPlanLock } from '@/components/functions/IntegrationPlan/hooks/useIntegrationPlanLock';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { IInternship } from '@/core/redux/slices/functions/integrationPlan/qualificationDocumentation/qualificationDocumentationSlice';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';

interface IInternshipsDocumentation {
  internships?: Record<string, IInternship> | null;
}

export const InternshipsDocumentation: React.FC<IInternshipsDocumentation> = ({ internships }) => {
  const { t: internshipsDocumentationTranslations } = useTranslation('qualificationDocumentation', {
    keyPrefix: 'internshipsDocumentation',
  });

  const isLocked = useIntegrationPlanLock();

  let internshipsKeys: string[] = [];

  if (internships) {
    internshipsKeys = Object.keys(internships);
  }

  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(integrationPlanModalsActions.openInternshipDocumentationModal());
  };

  return (
    <div>
      <Typography type={'H3'}>{internshipsDocumentationTranslations('title.label')}</Typography>
      <div className={'flex flex-col gap-3 mt-4'}>
        <TableHeader />
        <div className={'flex flex-col gap-3'}>
          {internshipsKeys.map((internshipKey) => (
            <InternshipsDocumentationItem
              internshipsDocumentationItemID={internshipKey}
              key={internshipKey}
            />
          ))}
        </div>
        <Button
          className={'w-auto self-end'}
          type={'button'}
          onClick={handleClick}
          disabled={isLocked}
        >
          {internshipsDocumentationTranslations('buttons.addInternship.label')}
        </Button>
      </div>
    </div>
  );
};
