import { PayloadAction } from '@reduxjs/toolkit';
import { delay, put, select, takeEvery } from 'redux-saga/effects';
import { v4 } from 'uuid';

import { DEFAULT_NOTIFICATION_TIMEOUT } from '@/core/constants/settings';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';

import { IShowNotificationPayload, notificationsActions } from './notificationsSlice';

function* addNotification(action: PayloadAction<IShowNotificationPayload>) {
  const { payload } = action;

  const globalDelay: number | null = yield select(parametersSelectors.globalNotificationDelay);
  const incomingDelay = payload.notification?.delay;
  const notificationDelay: number = incomingDelay ?? globalDelay ?? DEFAULT_NOTIFICATION_TIMEOUT;

  const id = v4();

  const isPermanent = payload.notification?.permanent ?? (globalDelay === 0 || incomingDelay === 0);

  yield put(
    notificationsActions.addNotification({
      id,
      notification: {
        ...payload.notification,
        permanent: isPermanent,
      },
    })
  );

  if (!isPermanent && notificationDelay > 0) {
    yield delay(notificationDelay);
    yield put(notificationsActions.hideNotification(id));
  }
}

export const notificationsSagas = [
  takeEvery(notificationsActions.showNotification, addNotification),
];
