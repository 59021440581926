import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { ILogbookSummary, logbookActions } from '@/core/redux/slices/logbook/logbookSlice';
import { logbookSelectors } from '@/core/redux/slices/logbook/selectors';
import { logbookModalsActions } from '@/core/redux/slices/modalsSlice/logbook/logbookModalSlice';

import { LogbookSummaryItem } from '../LogbookSummaryItem/LogbookSummaryItem';

export const LogbookSummaryContent: React.FC = () => {
  const dispatch = useAppDispatch();

  const logbookSummary = useAppSelector(logbookSelectors.logbookSummary);

  const { t: logbookSummaryTranslations } = useTranslation('logbookViewModal');

  const personID = useAppSelector(functionPageSelectors.personID);

  useEffect(() => {
    if (!personID) {
      return;
    }

    dispatch(
      logbookActions.fetchLogbookSummary({
        personID: personID,
      })
    );
  }, []);

  return (
    <div className='flex flex-col h-full'>
      <div className='p-6 mt-4  pt-3.5 flex flex-col gap-2.5 flex-grow overflow-auto max-h-[calc(100vh_-_240px)]'>
        {logbookSummary.map((item: ILogbookSummary) => (
          <LogbookSummaryItem
            id={item.id}
            note={item.note}
            createdBy={item.createdBy}
            createdTimestamp={item.createdTimestamp}
            key={item.id}
          />
        ))}
      </div>
      <div className='shadow-[0px_2px_10px_0px_rgba(110,123,148,0.20)] px-[30px] py-4 mt-auto flex gap-2.5'>
        <Button onClick={() => dispatch(logbookModalsActions.openNewLogbookEntryModal())}>
          {logbookSummaryTranslations('buttons.addNewLogbook.label')}
        </Button>
        <Button onClick={() => dispatch(logbookModalsActions.openLogbookEditModal())}>
          {logbookSummaryTranslations('buttons.openEdit.label')}
        </Button>
      </div>
    </div>
  );
};
