import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets/typings';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { ActivityPlanningMeasureType } from '@/core/enums/functions/activityPlanning/measureTypesEnum';
import {
  activityPlaningMeasuresListActions,
  IActivityPlanningMeasure,
  IMeasuresListFetchPayload,
  IPersonLocationFetchPayload,
} from '@/core/redux/slices/functions/activityPlanning/measuresList/slice';
import { isEnumValue } from '@/core/utils/enumUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type MeasuresListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure',
  'get',
  '200'
>;

type MeasuresListRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure',
  'get'
>;

type PersonLocationResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/department/person',
  'get',
  '200'
>;

type PersonLocationRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/department/person',
  'get'
>;

function* fetchMeasuresList(
  action: PayloadAction<IMeasuresListFetchPayload>
): Generator<any, void, MeasuresListResponse> {
  const { personID } = action.payload;

  try {
    yield put(activityPlaningMeasuresListActions.setMeasuresListLock(LoadingStatus.LOADING));

    const request: MeasuresListRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/activity_planning/measure', 'get', request);

    const measuresList: IActivityPlanningMeasure[] =
      response.measures.map<IActivityPlanningMeasure>((measure) => ({
        id: measure.id,
        measureID: measure.measure_id,
        measureType: isEnumValue(ActivityPlanningMeasureType, measure.measure_type)
          ? measure.measure_type
          : ActivityPlanningMeasureType.Default,
        name: measure.name,
      }));

    yield put(activityPlaningMeasuresListActions.setMeasuresList(measuresList));

    yield put(activityPlaningMeasuresListActions.setMeasuresListLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on activity planing measures list fetch', error);
    yield put(activityPlaningMeasuresListActions.setMeasuresListLock(LoadingStatus.ERROR));
  }
}

function* fetchPersonLocation(
  action: PayloadAction<IPersonLocationFetchPayload>
): Generator<any, void, PersonLocationResponse> {
  const { personID } = action.payload;

  try {
    yield put(activityPlaningMeasuresListActions.setPersonLocationLock(LoadingStatus.LOADING));

    const request: PersonLocationRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/department/person', 'get', request);

    yield put(activityPlaningMeasuresListActions.setPersonLocation(response.department));

    yield put(activityPlaningMeasuresListActions.setPersonLocationLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on activity planing measures list fetch', error);
    yield put(activityPlaningMeasuresListActions.setPersonLocationLock(LoadingStatus.ERROR));
  }
}

export const activityPlaningMeasuresListSagas = [
  takeLatest(activityPlaningMeasuresListActions.fetchMeasuresList, fetchMeasuresList),
  takeLatest(activityPlaningMeasuresListActions.fetchPersonLocation, fetchPersonLocation),
];
