import React, { createContext, useContext } from 'react';

export interface IPermission {
  isReadOnly: boolean;
  isVisible: boolean;
}

export interface IPermissions {
  all?: IPermission;
  [key: string]: IPermission | undefined;
}

interface IPermissionsContextProvider {
  permissions: IPermissions;
  children: React.ReactNode;
}

const defaultPermissions: IPermission = { isReadOnly: false, isVisible: true };

const PermissionsContext = createContext<IPermissions | null>(null);

export const PermissionsProvider: React.FC<IPermissionsContextProvider> = ({
  children,
  permissions,
}) => <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>;

export const usePermissions = (name: string): IPermission => {
  const context = useContext(PermissionsContext);

  if (!context) {
    return defaultPermissions;
  }

  if (context.all) {
    return context.all;
  }

  if (!context[name]) {
    return defaultPermissions;
  }

  return context[name] as IPermission;
};
