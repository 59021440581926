import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import {
  ISmartGoalCompitience,
  smartGoalsActions,
} from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { smartGoalsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/selectors';
import { smartGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/smartGoalsModalSlice';

import { CompitenceCard } from './CompetencieCard/CompetenciesCard';
import { IBreadcrumbItem, MeasureBreadcrumbs } from './MeasureBreadcrumbs/MeasureBreadcrumbs';
import { MeasuresAvailableView } from './MeasuresAvailableView/MeasuresAvailableView';

const ModalContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t: addMeasuresModalTranslations } = useTranslation('addMeasuresModal');

  const rootBreadcrumb = [
    {
      id: undefined,
      label: addMeasuresModalTranslations('breadcrumbs.root.label'),
    },
  ];

  // selectors
  const { payload } = useAppSelector(smartGoalsModalsSelectors.addMeasuersModal);
  const smartGoalID = payload?.smartGoalID;

  const smartGoalCompetencies = useAppSelector(smartGoalsSelectors.smartGoalCompetencies);

  //state
  const [competencieParentID, setCompetencieParentID] = useState<number>();
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbItem[]>(rootBreadcrumb);
  const [isMeasureView, setIsMeasureView] = useState<boolean>(false);

  const handleExit = () => {
    dispatch(smartGoalsModalActions.closeAddMeasuresModal());
  };

  const handleBreadcrumbClick = (id: number | undefined) => {
    setIsMeasureView(false);

    // Find the index of the selected breadcrumb
    const selectedIndex = breadcrumbs.findIndex((item) => item.id === id);

    // If the selected index is found, slice the array up to that index (inclusive)
    if (selectedIndex !== -1) {
      setBreadcrumbs(breadcrumbs.slice(0, selectedIndex + 1));
    }

    // Select parent id
    setCompetencieParentID(id);
  };

  const handleCardClick = ({ id, hasChildren, name }: ISmartGoalCompitience) => {
    if (!hasChildren) {
      setIsMeasureView(true);
    }

    setBreadcrumbs((prev) => {
      return [...prev, { id: id, label: name || '' }];
    });

    // Select parent id
    setCompetencieParentID(id);
  };

  useEffect(() => {
    if (!smartGoalID) {
      return;
    }

    dispatch(
      smartGoalsActions.fetchSmartGoalCompetencies({
        smartGoalID: smartGoalID,
        parentID: competencieParentID,
      })
    );
  }, [competencieParentID]);

  return (
    <Sidebar
      onExit={handleExit}
      className='w-[1000px]'
      position={'right'}
      title={addMeasuresModalTranslations('title.label')}
    >
      <div className='p-6'>
        <MeasureBreadcrumbs
          items={breadcrumbs}
          onItemClick={(id) => {
            handleBreadcrumbClick(id);
          }}
        />
      </div>
      {!isMeasureView && (
        <div className='p-6 pt-0'>
          <div className='flex gap-5 flex-wrap'>
            {smartGoalCompetencies.map((compitencie) => (
              <CompitenceCard
                {...compitencie}
                key={compitencie.id}
                onCardClick={(compitencie) => {
                  handleCardClick(compitencie);
                }}
              />
            ))}
          </div>
        </div>
      )}
      {isMeasureView && smartGoalID && competencieParentID && (
        <MeasuresAvailableView smartGoalID={smartGoalID} competenceID={competencieParentID} />
      )}
    </Sidebar>
  );
};

export const AddMeasuresModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(smartGoalsModalsSelectors.addMeasuersModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
