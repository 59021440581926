import React, { ReactNode } from 'react';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

interface IFundingCycleSectionHeader {
  title?: string | ReactNode;
  controls?: React.ReactNode;
}

export const FundingCycleSectionHeader: React.FC<IFundingCycleSectionHeader> = ({
  title,
  controls,
}) => {
  return (
    <div
      className={`flex flex-row justify-between gap-3 items-center min-h-[56px] px-6 py-[10px] border-y border-${colorTypes.Fields} bg-${colorTypes.HoverLight}`}
    >
      {typeof title === 'string' && <Typography type={'H3'}>{title}</Typography>}
      {typeof title !== 'string' && title}
      {controls && controls}
    </div>
  );
};
