import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@/components/Input/Input';
import { IAdditionalPersonRequirementField } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/AdditionalPersonRequirementModalContent';
import { Typography } from '@/components/Typography/Typography';

export const PersonRequirementTimeSpent: React.FC<IAdditionalPersonRequirementField> = ({
  form,
}) => {
  const { t: additionalPersonRequirementsModalTranslations } = useTranslation(
    'additionalPersonRequirementModal'
  );

  const { register, watch } = form;
  const personRequirementID = watch('id');

  return (
    <>
      <Typography>
        {additionalPersonRequirementsModalTranslations('fields.timeSpentPerDay.label')}
      </Typography>
      <div className={'flex flex-col gap-2'}>
        <Typography>
          {additionalPersonRequirementsModalTranslations('fields.optional.label')}
        </Typography>
        <Input control={register('minutesPerDay')} />
      </div>
    </>
  );
};
