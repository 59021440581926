import { Key, useEffect, useMemo } from 'react';
import fp from 'lodash/fp';

import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { personPageSelectors } from '@/core/redux/slices/personPage/personPageSelectors';
import { personPageActions } from '@/core/redux/slices/personPage/personPageSlice';
import { resolveProgress } from '@/core/utils/commonUtils';
import { filterDepartments, sortByName, sortDepartments } from '@/core/utils/departmentUtils';
import { IDepartment } from '@/types/department';
import { SortingBy } from '@/types/sortingBy';

import { getAllIds } from '../DepartmentsTreeSelect/utils';

export default function useFetchedDepartments() {
  const dispatch = useAppDispatch();
  const fetchedDepartments = useAppSelector(personPageSelectors.departments);
  const departmentsStatus = useAppSelector(personPageSelectors.departmentsStatus);

  const [sortBy] = usePersonPageStoreSelector((store) => store.departmentsSortedBy);
  const [searchValue] = usePersonPageStoreSelector((store) => store.departmentSearchValue);
  const [locationsCheckState] = usePersonPageStoreSelector((store) => store.locationsChecksState);
  const [isAllSelected, setIsAllSelected] = usePersonPageStoreSelector(
    (store) => store.allDepartmentsSelected
  );

  useEffect(() => {
    if (fp.isEmpty(fetchedDepartments)) {
      dispatch(personPageActions.fetchDynamicDepartments());
    }
  }, [dispatch, fetchedDepartments]);

  const compareKeys = (arr1: Key[], arr2: Key[]): boolean => {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    if (set1.size !== set2.size) return false;

    let allElementsMatch = true;

    set1.forEach((item) => {
      if (!set2.has(item)) {
        allElementsMatch = false;
      }
    });

    return allElementsMatch;
  };

  const departments = useMemo(() => {
    if (fp.isEmpty(fetchedDepartments) || fp.isEmpty(sortBy) || searchValue === undefined) {
      return null;
    }

    const filteredDepartments = filterDepartments(fetchedDepartments, searchValue);
    const sortedDepartments = sortDepartments(filteredDepartments, sortBy);

    if (locationsCheckState) {
      const selectedKeys = [
        ...(Array.isArray(locationsCheckState.checked) ? locationsCheckState.checked : []),
        ...(Array.isArray(locationsCheckState.halfChecked) ? locationsCheckState.halfChecked : []),
      ];
      const filteredKeys = getAllIds(filteredDepartments);

      if (selectedKeys.length > filteredKeys.length) {
        setIsAllSelected({
          allDepartmentsSelected: true,
        });
      } else {
        const isAllDepartmentsSelected = compareKeys(selectedKeys, filteredKeys);

        setIsAllSelected({
          allDepartmentsSelected: isAllDepartmentsSelected,
        });
      }
    }

    return sortedDepartments;
  }, [fetchedDepartments, sortBy, searchValue]);

  const isDepartmentsInProgress = useMemo(() => {
    return resolveProgress(departmentsStatus);
  }, [departmentsStatus]);

  return { departments, isDepartmentsInProgress };
}
