import React from 'react';

export const CalendarSmallIcon: React.FC = () => (
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.418 1.83203C11.418 1.41782 11.0822 1.08203 10.668 1.08203C10.2538 1.08203 9.91797 1.41782 9.91797 1.83203V2.41797H6.08203V1.83203C6.08203 1.41782 5.74624 1.08203 5.33203 1.08203C4.91782 1.08203 4.58203 1.41782 4.58203 1.83203V2.41797H3.33333C2.18274 2.41797 1.25 3.35071 1.25 4.5013V13.8346C1.25 14.9852 2.18274 15.918 3.33333 15.918H12.6667C13.8173 15.918 14.75 14.9852 14.75 13.8346V4.5013C14.75 3.35071 13.8173 2.41797 12.6667 2.41797H11.418V1.83203ZM13.25 6.41797V4.5013C13.25 4.17914 12.9888 3.91797 12.6667 3.91797H11.418V4.4987C11.418 4.91291 11.0822 5.2487 10.668 5.2487C10.2538 5.2487 9.91797 4.91291 9.91797 4.4987V3.91797H6.08203V4.4987C6.08203 4.91291 5.74624 5.2487 5.33203 5.2487C4.91782 5.2487 4.58203 4.91291 4.58203 4.4987V3.91797H3.33333C3.01117 3.91797 2.75 4.17914 2.75 4.5013V6.41797H13.25ZM2.75 7.91797H13.25V13.8346C13.25 14.1568 12.9888 14.418 12.6667 14.418H3.33333C3.01117 14.418 2.75 14.1568 2.75 13.8346V7.91797Z'
      fill='currentColor'
    />
  </svg>
);
