import React from 'react';

import { Typography } from '@/components/Typography/Typography';
import { IQuestionnaireQuestionReviewHistoryItem } from '@/core/redux/slices/functions/performanceProfile/slice';

interface IQuestionnaireReviewsHistoryItem {
  reviewHistoryItem: IQuestionnaireQuestionReviewHistoryItem;
}

export const QuestionnaireReviewsHistoryItem: React.FC<IQuestionnaireReviewsHistoryItem> = ({
  reviewHistoryItem,
}) => {
  return (
    <>
      <Typography>{reviewHistoryItem.fromTimestamp?.replaceAll('-', '.')}</Typography>
      <Typography>{reviewHistoryItem.review}</Typography>
    </>
  );
};
