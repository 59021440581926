import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useQuestionnaireStoreSelector } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireContext/QuestionnaireContext';
import { QuestionnaireItemReviewSelectItem } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionItem/QuestionItemReviewSelect/QuestionnaireItemReviewSelectItem/QuestionnaireItemReviewSelectItem';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { useAppDispatch } from '@/core/redux/hooks';
import {
  IQuestionnaireQuestionReview,
  performanceProfileActions,
} from '@/core/redux/slices/functions/performanceProfile/slice';
import { colorTypes } from '@/styles/types';

interface IQuestionItemMarkSelect {
  id: number;
  parentID: number;
  reviewsList: IQuestionnaireQuestionReview[];
  review?: number | null;
}

export const QuestionItemReviewSelect: React.FC<IQuestionItemMarkSelect> = ({
  id,
  parentID,
  reviewsList,
  review,
}) => {
  const findInitialID = (initialReview?: number | null) => {
    const foundReview = reviewsList.find((review) => review.review === initialReview);

    if (foundReview === null || foundReview === undefined) {
      return undefined;
    }

    return foundReview.id.toString();
  };

  const [selectedItem, setSelectedItem] = useState<string | undefined>(findInitialID(review));

  const [isFormEditable] = useQuestionnaireStoreSelector((store) => store.isFormEditable);

  const formContext = useFormContext();

  const dispatch = useAppDispatch();

  const values = reviewsList.reduce<ISelectItem[]>((accum, review) => {
    const newReviewItem: ISelectItem = {
      id: review.id.toString(),
      component: (
        <QuestionnaireItemReviewSelectItem
          review={review.review}
          description={review.description}
        />
      ),
      componentContainerClassName: 'items-center gap-3',
      showCheckIcon: false,
    };

    accum.push(newReviewItem);

    return accum;
  }, []);

  const handleChange = (selectedID: string) => {
    const foundReview = reviewsList.find((review) => review.id.toString() === selectedID);

    if (!foundReview) {
      return;
    }

    dispatch(
      performanceProfileActions.updateQuestionnaire({
        competence: {
          id: id,
          review: foundReview.review,
          description: foundReview.description ?? null,
        },
      })
    );

    setSelectedItem(foundReview.review.toString());
    formContext.setValue(`${parentID}.questions.${id}.review`, foundReview.review);
    formContext.setValue(`${parentID}.questions.${id}.description`, foundReview.description);
  };

  return (
    <Select
      values={values}
      placeholder={'-'}
      onChange={handleChange}
      variant={'QuestionnaireQuestionsMark'}
      initialValueID={selectedItem}
      selectedComponent={selectedItem}
      selectedContainerClassName={selectedItem ? `bg-${colorTypes.Blue} text-white` : ''}
      iconClassName={selectedItem && 'text-white'}
      dropdownClassName={'self-center'}
      contentClassName={`w-full`}
      readOnly={!isFormEditable}
      showExpandIcon={isFormEditable}
    />
  );
};
