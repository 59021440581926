import React from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionItem } from '@/components/forms/ParticipantExpectation/QuestionItem/QuestionItem';
import { Typography } from '@/components/Typography/Typography';

export const InterestsAndInclinations: React.FC = () => {
  const { t: interestsAndInclinationsTranslations } = useTranslation('participantExpectation', {
    keyPrefix: 'interestsAndInclinations',
  });

  return (
    <div>
      <Typography type={'H3'}>{interestsAndInclinationsTranslations('title.label')}</Typography>
      <div className={'grid grid-cols-5 mt-4 gap-4'}>
        <Typography color={'Secondary'} type={'14-M'}>
          {interestsAndInclinationsTranslations('tableHead.questions.label')}
        </Typography>
        <Typography color={'Secondary'} type={'14-M'} className={'col-span-2'}>
          {interestsAndInclinationsTranslations('tableHead.description.label')}
        </Typography>
        <Typography color={'Secondary'} type={'14-M'} className={'col-span-2'}>
          {interestsAndInclinationsTranslations('tableHead.methods.label')}
        </Typography>
      </div>
      <div className={'flex flex-col mt-2 gap-4'}>
        <QuestionItem
          title={interestsAndInclinationsTranslations('fields.inm1.label')}
          fieldName={'inm1'}
        />
        <QuestionItem
          title={interestsAndInclinationsTranslations('fields.inm2.label')}
          fieldName={'inm2'}
        />
        <QuestionItem
          title={interestsAndInclinationsTranslations('fields.inm3.label')}
          fieldName={'inm3'}
        />
        <QuestionItem
          title={interestsAndInclinationsTranslations('fields.inm4.label')}
          fieldName={'inm4'}
        />
      </div>
    </div>
  );
};
