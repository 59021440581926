import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.integrationPlan.statusReport;

const statusReport = createSelector([selector], (state) => state.statusReport);
const statusReportLock = createSelector([selector], (state) => state.statusReportLock);
const updateStatusReportLock = createSelector([selector], (state) => state.updateStatusReportLock);

export const statusReportSelectors = {
  statusReport,
  statusReportLock,
  updateStatusReportLock,
};
