import React from 'react';

import { IIcon } from '@/types/icon';

export const PlusCircleIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9984 3.35039C6.94503 3.35039 2.84844 7.44699 2.84844 12.5004C2.84844 17.5538 6.94503 21.6504 11.9984 21.6504C17.0518 21.6504 21.1484 17.5538 21.1484 12.5004C21.1484 7.44699 17.0518 3.35039 11.9984 3.35039ZM1.14844 12.5004C1.14844 6.5081 6.00615 1.65039 11.9984 1.65039C17.9907 1.65039 22.8484 6.5081 22.8484 12.5004C22.8484 18.4927 17.9907 23.3504 11.9984 23.3504C6.00615 23.3504 1.14844 18.4927 1.14844 12.5004Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.8484 8.00039C12.8484 7.53095 12.4679 7.15039 11.9984 7.15039C11.529 7.15039 11.1484 7.53095 11.1484 8.00039V11.6504H7.49844C7.029 11.6504 6.64844 12.0309 6.64844 12.5004C6.64844 12.9698 7.029 13.3504 7.49844 13.3504H11.1484V17.0004C11.1484 17.4698 11.529 17.8504 11.9984 17.8504C12.4679 17.8504 12.8484 17.4698 12.8484 17.0004V13.3504H16.4984C16.9679 13.3504 17.3484 12.9698 17.3484 12.5004C17.3484 12.0309 16.9679 11.6504 16.4984 11.6504H12.8484V8.00039Z'
      fill='currentColor'
    />
  </svg>
);
