import React from 'react';

export const SwitchIcon: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M21 6H3m18 0l-4 4m4-4l-4-4' />
    <path d='M3 18h18M3 18l4 4m-4-4l4-4' />
  </svg>
);
