import React from 'react';

import { FormRadioButton } from '@/components/FormRadioButtonGroup/FormRadioButton';
import { IRadioButtonGroupState } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';

export interface IFormRadioButtonGroupItem extends IRadioButtonGroupState {
  id?: number | string;
  value: any;
  component?: React.ReactNode;
  onChange?: (value: any) => void;
  isSelected?: boolean;
  readOnly?: boolean;
  className?: string;
}

export const FormRadioButtonGroupItem: React.FC<IFormRadioButtonGroupItem> = ({
  id,
  value,
  component,
  isSelected,
  className,
  readOnly,
  onChange,
}) => {
  return (
    <div className={twMerge('flex flex-row gap-2', className)}>
      <FormRadioButton
        onChange={onChange}
        value={value}
        id={String(id)}
        readOnly={readOnly}
        isSelected={isSelected}
      />
      {component ? (
        component
      ) : (
        <label htmlFor={String(id)} className={'cursor-pointer'}>
          <Typography>{value}</Typography>
        </label>
      )}
    </div>
  );
};
