import { Button } from '@/components/Button/Button';
import { ExitImage } from '@/components/Images/ExitImage';
import { LeftImage } from '@/components/Images/LeftImage';
import { RightImage } from '@/components/Images/RightImage';
import { ISelfAssessmentQuestion } from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';
import { colorTypes } from '@/styles/types';

export interface ISimpleQuestionnaireNavigation {
  selectedIndex: number;
  questions: ISelfAssessmentQuestion[];
  handleNext: () => void;
  handlePrevious: () => void;
  handleExit: () => void;
}

export const SimpleQuestionnaireNavigation: React.FC<ISimpleQuestionnaireNavigation> = ({
  selectedIndex,
  questions,
  handleNext,
  handlePrevious,
  handleExit,
}) => {
  return (
    <div className='flex flex-wrap'>
      <div className='sm:w-1/3 px-4 container flex flex-col items-center'>
        <Button
          buttonVariant={'SimpleQuestionnaire'}
          buttonStyle={'WithIcon'}
          className={`border hover:bg-${colorTypes.SelfAssessmentHover} hover:border-${colorTypes.SelfAssessmentHoverBorder}`}
          onClick={handlePrevious}
          disabled={selectedIndex === 0}
        >
          <div className='flex justify-center w-[100px]'>
            <LeftImage className='img-responsive'></LeftImage>
          </div>
        </Button>
      </div>
      <div className='sm:w-1/3 px-4 container flex flex-col items-center'>
        <Button
          buttonVariant='SimpleQuestionnaire'
          buttonStyle='WithIcon'
          className={`hover:bg-${colorTypes.SelfAssessmentHover} hover:border-${colorTypes.SelfAssessmentHoverBorder}`}
          onClick={handleExit}
        >
          <ExitImage className='img-responsive'></ExitImage>
        </Button>
      </div>
      <div className='sm:w-1/3 px-4 container flex flex-col items-center'>
        <Button
          buttonVariant='SimpleQuestionnaire'
          buttonStyle='WithIcon'
          className={`border hover:bg-${colorTypes.SelfAssessmentHover} hover:border-${colorTypes.SelfAssessmentHoverBorder}`}
          onClick={handleNext}
          disabled={selectedIndex === questions.length - 1}
        >
          <div className='flex justify-center w-[100px]'>
            <RightImage className='img-responsive'></RightImage>
          </div>
        </Button>
      </div>
    </div>
  );
};
