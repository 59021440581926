import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const TableHeader: React.FC = () => {
  const { t: internshipsDocumentationTranslations } = useTranslation('qualificationDocumentation', {
    keyPrefix: 'internshipsDocumentation.tableHeader',
  });

  return (
    <div className={'grid grid-cols-3 gap-3 items-center'}>
      <Typography color={'Secondary'} type={'14-M'}>
        {internshipsDocumentationTranslations('internshipType.label')}
      </Typography>
      <Typography color={'Secondary'} type={'14-M'}>
        {internshipsDocumentationTranslations('place.label')}
      </Typography>
      <Typography color={'Secondary'} type={'14-M'}>
        {internshipsDocumentationTranslations('period.label')}
      </Typography>
    </div>
  );
};
