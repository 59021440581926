import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import fp from 'lodash/fp';

import {
  goalsAndActivityDefaultValues,
  IDevelopmentReportGoalsAndActivityForm,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/GoalsAndActivity/helper/goalsAndActivityForm';
import {
  IDevelopmentReportPersonDetailsForm,
  personDetailsDefaultValues,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/PersonDetails/helper/personDetailsForm';
import {
  IDevelopmentReportReviewSupportForm,
  reviewSupportDefaultValues,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/ReviewSupport/helper/reviewSupportForm';
import {
  IDevelopmentReportVisionAndGoalsForm,
  visionAndGoalsDefaultValues,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/VisionAndGoals/helper/visionAndGoalsForm';
import { developmentReportValidator } from '@/components/functions/DevelopmentReport/hooks/developmentReportValidatorBuilder';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { developmentReportGoalsAndActivitySelectors } from '@/core/redux/slices/functions/developmentReport/developmentReportGoalsAndActivitySlice/selectors';
import { developmentReportPersonDetailsSelectors } from '@/core/redux/slices/functions/developmentReport/developmentReportPersonDetailsSlice/selectors';
import { developmentReportReviewSupportSelectors } from '@/core/redux/slices/functions/developmentReport/developmentReportReviewSupportSlice/selectors';
import { developmentReportVisionAndGoalsSelectors } from '@/core/redux/slices/functions/developmentReport/developmentReportVisionAndGoalsSlice/selectors';

export interface IDevelopmentReportForm {
  [DevelopmentReportForms.PersonDetails]: IDevelopmentReportPersonDetailsForm;
  [DevelopmentReportForms.VisionAndGoals]: IDevelopmentReportVisionAndGoalsForm;
  [DevelopmentReportForms.Review]: IDevelopmentReportReviewSupportForm;
  [DevelopmentReportForms.GoalsAndActivityPlaning]: IDevelopmentReportGoalsAndActivityForm;
}

export function useDevelopmentReportForm() {
  const form = useForm<IDevelopmentReportForm>({
    resolver: yupResolver(developmentReportValidator),
    defaultValues: {
      [DevelopmentReportForms.PersonDetails]: personDetailsDefaultValues,
    },
  });

  const currentValues = fp.cloneDeep(form.watch());

  const developmentReportPersonDetails = useAppSelector(
    developmentReportPersonDetailsSelectors.developmentReportPersonDetails
  );
  const developmentReportGoalsAndActivity = useAppSelector(
    developmentReportGoalsAndActivitySelectors.developmentReportGoalsAndActivity
  );
  const developmentReportVisionAndGoals = useAppSelector(
    developmentReportVisionAndGoalsSelectors.developmentReportVisionAndGoals
  );
  const developmentReportReviewSupport = useAppSelector(
    developmentReportReviewSupportSelectors.developmentReportReviewSupport
  );

  useEffect(() => {
    form.reset({
      ...currentValues,
      [DevelopmentReportForms.PersonDetails]:
        developmentReportPersonDetails || personDetailsDefaultValues,
      [DevelopmentReportForms.GoalsAndActivityPlaning]:
        developmentReportGoalsAndActivity || goalsAndActivityDefaultValues,
      [DevelopmentReportForms.VisionAndGoals]:
        developmentReportVisionAndGoals || visionAndGoalsDefaultValues,
      [DevelopmentReportForms.Review]: developmentReportReviewSupport || reviewSupportDefaultValues,
    });
  }, [
    developmentReportPersonDetails,
    developmentReportGoalsAndActivity,
    developmentReportVisionAndGoals,
    developmentReportReviewSupport,
  ]);

  return { form };
}
