import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// payloads
export interface ISmartGoalsModalPayload {
  personID: number;
  smartGoalID: number;
}

export interface IAddMeasuresModalPayload {
  smartGoalID: number;
}

export interface IConfirmUnpinActivityModalPayload {
  smartGoalID: number;
  measureID: number;
}

export interface IConfirmDeleteSmartGoalModalPayload {
  smartGoalID: number;
  personID: number;
}

export interface IConfirmDeleteMeasureModalPayload {
  smartGoalID: number;
  measureID: number;
}

// interfaces
export interface IDsiplaySmartGoalModal {
  isRender: boolean;
  payload: ISmartGoalsModalPayload | null;
}

export interface IAddMeasuresModal {
  isRender: boolean;
  payload: IAddMeasuresModalPayload | null;
}

export interface IConfirmUnpinActivityModal {
  isRender: boolean;
  payload: IConfirmUnpinActivityModalPayload | null;
}

export interface IConfirmDeleteSmartGoalModal {
  isRender: boolean;
  payload: IConfirmDeleteSmartGoalModalPayload | null;
}

export interface IConfirmDeleteMeasureModal {
  isRender: boolean;
  payload: IConfirmDeleteMeasureModalPayload | null;
}

// state
export interface ISmartGoalsModalState {
  displaySmartGoalModal: IDsiplaySmartGoalModal;
  addMeasuersModal: IAddMeasuresModal;
  confirmUnpinActivityModal: IConfirmUnpinActivityModal;
  confirmDeleteSmartGoalModal: IConfirmDeleteSmartGoalModal;
  confirmDeleteMeasureModal: IConfirmDeleteMeasureModal;
}

const initialState: ISmartGoalsModalState = {
  displaySmartGoalModal: {
    isRender: false,
    payload: null,
  },
  addMeasuersModal: {
    isRender: false,
    payload: null,
  },
  confirmUnpinActivityModal: {
    isRender: false,
    payload: null,
  },
  confirmDeleteSmartGoalModal: {
    isRender: false,
    payload: null,
  },
  confirmDeleteMeasureModal: {
    isRender: false,
    payload: null,
  },
};

export const smartGoalsModalSlice = createSlice({
  name: 'smartGoalsModal',
  initialState,
  reducers: {
    // display info
    openDisplaySmartGoalModal: (state, action: PayloadAction<ISmartGoalsModalPayload>) => ({
      ...state,
      displaySmartGoalModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeDisplaySmartGoalModal: (state) => ({
      ...state,
      displaySmartGoalModal: {
        isRender: false,
        payload: null,
      },
    }),
    // add measures
    openAddMeasuresModal: (state, action: PayloadAction<IAddMeasuresModalPayload>) => ({
      ...state,
      addMeasuersModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAddMeasuresModal: (state) => ({
      ...state,
      addMeasuersModal: {
        isRender: false,
        payload: null,
      },
    }),
    // unpin activity
    openConfirmUnpinActivityModal: (
      state,
      action: PayloadAction<IConfirmUnpinActivityModalPayload>
    ) => ({
      ...state,
      confirmUnpinActivityModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeConfirmUnpinActivityModal: (state) => ({
      ...state,
      confirmUnpinActivityModal: {
        isRender: false,
        payload: null,
      },
    }),
    // delete smart goal
    openConfirmDeleteSmartGoalModal: (
      state,
      action: PayloadAction<IConfirmDeleteSmartGoalModalPayload>
    ) => ({
      ...state,
      confirmDeleteSmartGoalModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeConfirmDeleteSmartGoalModal: (state) => ({
      ...state,
      confirmDeleteSmartGoalModal: {
        isRender: false,
        payload: null,
      },
    }),
    // delete measure
    openConfirmDeleteMeasureModal: (
      state,
      action: PayloadAction<IConfirmDeleteMeasureModalPayload>
    ) => ({
      ...state,
      confirmDeleteMeasureModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeConfirmDeleteMeasureModal: (state) => ({
      ...state,
      confirmDeleteMeasureModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const smartGoalsModalReducer = smartGoalsModalSlice.reducer;
export const smartGoalsModalActions = smartGoalsModalSlice.actions;
