import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useImperativeHandle,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { CommentItem } from '@/components/forms/ActionCourse/CommentItem/CommentItem';
import { DateBlock } from '@/components/forms/ActionCourse/DateBlock/DateBlock';
import { useSelectedPersonForm } from '@/components/functions/IntegrationPlan/hooks/useSelectedPersonForm';
import { IntegrationPlanFormActions } from '@/components/functions/IntegrationPlan/IntegrationPlan';
import { IIntegrationPlanFormProps } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsContainer/IntegrationPlanFormsContainer';
import { Typography } from '@/components/Typography/Typography';
import { actionCourseDateRangeTypes } from '@/core/enums/actionCourseDateRangeEnum';
import { useDateFormSwitch } from '@/core/hooks/useDateFormSwitch';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  actionCourseActions,
  IActionCourse,
  IOrganizationUnitsItem,
} from '@/core/redux/slices/functions/integrationPlan/actionCourse/actionCourseSlice';
import { actionCourseSelectors } from '@/core/redux/slices/functions/integrationPlan/actionCourse/selectors';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';

export const ActionCourse: ForwardRefExoticComponent<
  Omit<IIntegrationPlanFormProps, 'ref'> & RefAttributes<IntegrationPlanFormActions>
> = forwardRef((props, ref) => {
  const { t: actionCourseTranslations } = useTranslation('actionCourse');

  const { integrationPlanID, personID } = props;

  const initialValues = useAppSelector(actionCourseSelectors.actionCourse);
  const organizationUnits = useAppSelector(actionCourseSelectors.organizationUnits);

  const form = useForm<IActionCourse>();

  useSelectedPersonForm({ form, integrationPlanID, initialValues });

  const onBeforeExitCheck = () => {
    const currentValues = form.watch();

    if (fp.isEqual(currentValues, initialValues)) {
      return true;
    }

    if (!personID || integrationPlanID === null) {
      return true;
    }

    dispatch(
      actionCourseActions.updateActionCourse({
        personID: personID,
        integrationPlanID: integrationPlanID,
        actionCourse: currentValues,
      })
    );

    dispatch(actionCourseActions.setActionCourse(null));

    return true;
  };

  useImperativeHandle(ref, () => ({
    onBeforeExitCheck: onBeforeExitCheck,
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!organizationUnits) {
      return;
    }

    const dateBlock = form.watch('dateBlock');
    let foundOrganizationUnit: IOrganizationUnitsItem | undefined;

    switch (dateBlock) {
      case actionCourseDateRangeTypes.isEndeEV:
        foundOrganizationUnit = organizationUnits.find(
          (organizationUnit) => organizationUnit.organizationUnitID === 1
        );
        break;
      case actionCourseDateRangeTypes.isEndeBBB1:
        foundOrganizationUnit = organizationUnits.find(
          (organizationUnit) => organizationUnit.organizationUnitID === 2
        );
        break;
      case actionCourseDateRangeTypes.isEndeBBB2:
        foundOrganizationUnit = organizationUnits.find(
          (organizationUnit) => organizationUnit.organizationUnitID === 3
        );
        break;
      default:
        break;
    }

    if (foundOrganizationUnit) {
      form.setValue(
        'observationFromTimestamp',
        toClientDateInput(foundOrganizationUnit.fromTimestamp)
      );
      form.setValue('observationToTimestamp', toClientDateInput(foundOrganizationUnit.toTimestamp));
    }
  }, [form.watch('dateBlock')]);

  useEffect(() => {
    if (!integrationPlanID || !personID) {
      return;
    }

    dispatch(
      actionCourseActions.fetchActionCourse({
        integrationPlanID: integrationPlanID,
        personID: personID,
      })
    );
  }, [integrationPlanID, personID]);

  useDateFormSwitch(form, 'observationFromTimestamp', 'observationToTimestamp');

  return (
    <FormProvider {...form}>
      <form className={'flex flex-col gap-4'} onSubmit={form.handleSubmit(() => {})}>
        <div className={'flex flex-row gap-2 items-center justify-between'}>
          <Typography type={'H3'}>{actionCourseTranslations('creationReason.label')}</Typography>
          <div className={'flex flex-row items-center gap-4'}>
            <Typography type={'H3'} className={'ms-4'}>
              {actionCourseTranslations('fields.createdTimestamp.label')}
            </Typography>
            <DatePicker control={form.control.register('createdTimestamp')} />
          </div>
        </div>
        <DateBlock />
        <div className={'grid grid-cols-5-min-content grid-rows-2 gap-4 items-center mt-4'}>
          <Typography className={'me-4'}>
            {actionCourseTranslations('fields.observationPeriod.label')}
          </Typography>
          <Typography>
            {actionCourseTranslations(
              'fields.observationPeriod.dateLabels.observationFromTimestamp.label'
            )}
          </Typography>
          <DatePicker
            control={form.control.register('observationFromTimestamp')}
            readOnly={
              form.watch('dateBlock') !== actionCourseDateRangeTypes.isIndividualAppointment
            }
          />
          <Typography>
            {actionCourseTranslations(
              'fields.observationPeriod.dateLabels.observationToTimestamp.label'
            )}
          </Typography>
          <DatePicker
            control={form.control.register('observationToTimestamp')}
            readOnly={
              form.watch('dateBlock') !== actionCourseDateRangeTypes.isIndividualAppointment
            }
          />
          <Typography className={'me-4'}>
            {actionCourseTranslations('fields.nextCheck.label')}
          </Typography>
          <Typography>
            {actionCourseTranslations('fields.nextCheck.dateLabels.nextCheckTimestamp.label')}
          </Typography>
          <DatePicker
            control={form.control.register('nextCheckTimestamp')}
            readOnly={
              form.watch('dateBlock') !== actionCourseDateRangeTypes.isIndividualAppointment
            }
          />
        </div>
        <div className={'mt-4'}>
          <CommentItem
            label={actionCourseTranslations('fields.qualificationGoal.label')}
            fieldName={'qualificationGoal'}
            readOnly={true}
          />
          <CommentItem
            label={actionCourseTranslations('fields.specialQuestions.label')}
            fieldName={'specialQuestions'}
          />
          <CommentItem
            label={actionCourseTranslations('fields.integrationPlanComment.label')}
            fieldName={'integrationPlanComment'}
          />
          <CommentItem
            label={actionCourseTranslations('fields.bbbComment.label')}
            fieldName={'bbbComment'}
          />
          <CommentItem
            label={actionCourseTranslations('fields.apgNote.label')}
            fieldName={'apgNote'}
          />
          <CommentItem label={actionCourseTranslations('fields.notes.label')} fieldName={'notes'} />
        </div>
      </form>
    </FormProvider>
  );
});
