import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.integrationPlan.actionCourse;

const actionCourse = createSelector([selector], (state) => state.actionCourse);
const actionCourseLock = createSelector([selector], (state) => state.actionCourseLock);
const updateActionCourseLock = createSelector([selector], (state) => state.updateActionCourseLock);
const organizationUnits = createSelector([selector], (state) => state.organizationUnits);

export const actionCourseSelectors = {
  actionCourse,
  actionCourseLock,
  updateActionCourseLock,
  organizationUnits,
};
