import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { FormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import { WorkingTimeContainerPartTimeReason } from '@/components/functions/WorkingTime/WorkingTimeContainer/WorkingTimeContainerPartTimeReason/WorkingTimeContainerPartTimeReason';
import { Typography } from '@/components/Typography/Typography';
import { WorkingTimeType } from '@/core/enums/functions/workingTimeMobility/employmentTypesEnum';
import { IWorkingTime } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { twMerge } from '@/core/utils/tailwindUtil';

interface IWorkingTimeContainer {
  className?: string;
}

export const WorkingTimeContainer: React.FC<IWorkingTimeContainer> = ({ className }) => {
  const { t: workingTimeTranslations } = useTranslation('workingTime');

  const { watch, register } = useFormContext<IWorkingTime>();

  const employmentTypeOptions: IFormRadioButtonGroupItem[] = [
    {
      value: WorkingTimeType.FullTime,
      component: <Typography>{workingTimeTranslations('fields.fullTime.label')}</Typography>,
    },
    {
      value: WorkingTimeType.PartTime,
      component: <Typography>{workingTimeTranslations('fields.partTime.label')}</Typography>,
    },
  ];

  const selectedWorkingTimeType = watch('workingTimeType');

  return (
    <div className={twMerge('flex flex-col gap-3', className)}>
      <div className={'grid grid-cols-2'}>
        <div className={'flex flex-col gap-3'}>
          <Typography>{workingTimeTranslations('employmentType.label')}</Typography>
          <FormRadioButtonGroup
            options={employmentTypeOptions}
            control={register('workingTimeType')}
            className={'gap-3'}
          />
        </div>
        <div className={'flex flex-col gap-3'}>
          <Typography>{workingTimeTranslations('fields.appliesFrom.label')}</Typography>
          <DatePicker control={register('appliesFromTimeStamp')} />
        </div>
      </div>
      {selectedWorkingTimeType && selectedWorkingTimeType === WorkingTimeType.PartTime && (
        <WorkingTimeContainerPartTimeReason className={'mt-1'} />
      )}
    </div>
  );
};
