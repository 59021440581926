import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { LoadingStatus } from '@/types/loadingStatus';

// interfaces

export interface IParticipantPlan {
  id: number;
  name?: string | null;
  caretaker?: string | null;
  socialServiceWorker?: string | null;
  startDate: string;
  createdBy?: string | null;
  isClosed: boolean;
}

export interface IParticipantPlanData {
  name: string;
  caretaker: number;
  socialServiceWorker: number;
  startDate: string;
}
export interface IParticipationPlanDetails {
  id: number;
  name?: string | null;
  caretaker?: { value?: string | null | undefined; id: number } | null;
  socialServiceWorker?: { value?: string | null | undefined; id: number } | null;
  startDate: string | null;
}

export interface IGoalWhish {
  id: number;
  topic?: string | null;
  explanationDescription?: string | null;
  method?: number;
  maxCharacters?: number | null;
  maxCrlf?: number | null;
}

export interface IUpdateGoalWhish {
  goalsWishesID: number;
  explanationDescription?: string | null;
  methodID?: number | null;
}

// payloads

export interface IParticipantPlansFetchPayload {
  personID: number;
}
export interface IParticipationPlanDetailsFetchPayload {
  participationPlanID: number;
}

export interface IParticipationPlanAutofillFetchPayload {
  personID: number;
}
export interface IParticipationPlanCreatePayload {
  personID: number;
  participationPlanData: IParticipantPlanData;
}

export interface IParticipationPlanUpdatePayload {
  personID: number;
  participationPlanID: number;
  participationPlanData: IParticipantPlanData;
}

export interface IParticipationPlanDeletePayload {
  participationPlanID: number;
  personID: number;
}
export interface IGoalsWishesFetchPayload {
  participationPlanID: number;
}

export interface IGoalsWishesUpdatePayload {
  goalsWishesData: IUpdateGoalWhish[];
  participationPlanID: number;
}

type CaretakerType =
  | DropdownItemsByFetch.EDUCATIONAL_SUPPORTER
  | DropdownItemsByFetch.GROUP_MANAGER;

// state

export interface IPaticipationPlanState {
  selectedParticipantPlan: number | null;
  participantPlans: IParticipantPlan[];
  participationPlanDetails: IParticipationPlanDetails | null;
  caretakerType: CaretakerType | undefined;
  createParticipationPlanLock: LoadingStatus;
  updateParticipationPlanLock: LoadingStatus;
  participationPlanDetailsLock: LoadingStatus;
  goalsWishes: IGoalWhish[];
  goalWishesLock: LoadingStatus;
  participationPlanNotification: string | null;
}

const initialState: IPaticipationPlanState = {
  selectedParticipantPlan: null,
  participantPlans: [],
  participationPlanDetails: null,
  goalsWishes: [],
  goalWishesLock: LoadingStatus.NEVER,
  caretakerType: undefined,

  createParticipationPlanLock: LoadingStatus.NEVER,
  updateParticipationPlanLock: LoadingStatus.NEVER,
  participationPlanDetailsLock: LoadingStatus.NEVER,
  participationPlanNotification: null,
};

export const participationPlanSlice = createSlice({
  name: 'participationPlan',
  initialState,
  reducers: {
    // participant plans
    fetchParticipantPlans: (state, action: PayloadAction<IParticipantPlansFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setParticipanPlans: (state, action: PayloadAction<IParticipantPlan[]>) => ({
      ...state,
      participantPlans: action.payload,
    }),
    // selected
    setSelectedParticipanPlan: (state, action: PayloadAction<number | null>) => ({
      ...state,
      selectedParticipantPlan: action.payload,
    }),
    // create
    createParticipationPlan: (state, action: PayloadAction<IParticipationPlanCreatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setCreateParticipationPlanLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      createParticipationPlanLock: action.payload,
    }),
    // update
    updateParticipationPlan: (state, action: PayloadAction<IParticipationPlanUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateParticipationPlanLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateParticipationPlanLock: action.payload,
    }),
    //delete
    deleteParticipationPlan: (state, action: PayloadAction<IParticipationPlanDeletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    // details
    fetchParticipationPlanDetails: (
      state,
      action: PayloadAction<IParticipationPlanDetailsFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    fetchParticipationPlanAutofillData: (
      state,
      action: PayloadAction<IParticipationPlanAutofillFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setParticipationPlanDetails: (
      state,
      action: PayloadAction<IParticipationPlanDetails | null>
    ) => ({
      ...state,
      participationPlanDetails: action.payload,
    }),
    setCaretakerType: (state, action: PayloadAction<CaretakerType | undefined>) => ({
      ...state,
      caretakerType: action.payload,
    }),
    setParticipationPlanDetailsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      participationPlanDetailsLock: action.payload,
    }),
    // goals wishes
    fetchGoalsWishes: (state, action: PayloadAction<IGoalsWishesFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setGoalsWishes: (state, action: PayloadAction<IGoalWhish[]>) => ({
      ...state,
      goalsWishes: action.payload,
    }),
    updateGoalsWishes: (state, action: PayloadAction<IGoalsWishesUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setGoalsWishesLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      goalWishesLock: action.payload,
    }),
    // error handling
    setParticipationPlanNotification: (state, action: PayloadAction<string | null>) => ({
      ...state,
      participationPlanNotification: action.payload,
    }),
  },
});

export const participationPlanReducer = participationPlanSlice.reducer;
export const participationPlanActions = participationPlanSlice.actions;
