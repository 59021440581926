import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IMobilityTransportTypesForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/MobilityTransportTypesModal/MobilityTransportTypesModalContent/MobilityTransportTypesModalContent';
import { ITableColumn, Table } from '@/components/Table/Table';
import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import { IMobilityTransportType } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/workingTimeMobilitModalsSlice';

interface IMobilityTransportTypesTable {
  transportTypes: IMobilityTransportType[];
  form: UseFormReturn<IMobilityTransportTypesForm, any, undefined>;
  formDefaultValues: IMobilityTransportTypesForm;
}

interface IMobilityTransportTypeTableItem {
  transportTypeName: string;
  transportFormType: MobilityFormTypes;
  transportFormTypeID: number;
  id: number;
}

export const MobilityTransportTypesTable: React.FC<IMobilityTransportTypesTable> = ({
  transportTypes,
  form,
  formDefaultValues,
}) => {
  const { t: transportTypesModalTableTranslations } = useTranslation(
    'mobilityTransportTypesModal',
    { keyPrefix: 'table.header' }
  );

  const mobilityTransportTypes = transportTypes.reduce<IMobilityTransportTypeTableItem[]>(
    (accum, transportType) => {
      const newItem = {
        transportTypeName: transportType.transportTypeName,
        transportFormType: transportType.transportFormType.formType,
        transportFormTypeID: transportType.transportFormType.id,
        id: transportType.id,
      };

      accum.push(newItem);

      return accum;
    },
    []
  );

  const columns: ITableColumn[] = [
    {
      header: transportTypesModalTableTranslations('transportTypeName.label'),
      accessor: 'transportTypeName',
    },
    {
      header: transportTypesModalTableTranslations('transportFormTypeName.label'),
      accessor: 'transportFormType',
    },
  ];

  const handleTableSelect = (id: number | null) => {
    if (mobilityTransportTypes.length < 1) {
      return;
    }

    const foundElement = mobilityTransportTypes.find((transportType) => transportType.id === id);

    if (!foundElement) {
      form.reset(formDefaultValues);
      return;
    }

    const newFormState: IMobilityTransportTypesForm = {
      id: foundElement.id,
      transportTypeName: foundElement.transportTypeName,
      transportFormTypeID: foundElement.transportFormTypeID,
    };

    form.reset(newFormState);
  };

  return (
    mobilityTransportTypes.length > 0 && (
      <Table
        columns={columns}
        data={mobilityTransportTypes}
        singleSelect={true}
        onSingleSelectChange={handleTableSelect}
      />
    )
  );
};
