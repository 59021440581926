import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ICompleteAppointmentFormGroup } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/hooks/useCompleteAppointmentModalForm';
import { authAdd, restCall } from '@/core/clients/rest';
import { activityPlanningAppointmentsMeasuresListActions } from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/slice';
import {
  activityPlaningAppointmentDetailsModalsActions,
  IAppointmentCompleteModalFetchPayload,
  IAppointmentFinishPayload,
  IAppointmentQuestionnaire,
  ICompleteAppointmentChildGroup,
  ICompleteAppointmentItem,
} from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';
import oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type AppointmentCompleteQuestionnaireRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/finish',
  'get'
>;

type FinishAppointmentRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/finish',
  'post'
>;

interface ICompleteAppointmentItemResponse {
  id: number;
  text: string;
  explanation: string;
  type: string;
  from_timestamp: string | null;
  to_timestamp: string | null;
  hours: number | null;
  is_participated: boolean;
  is_under_direction: boolean;
  is_almost_independent: boolean;
  is_independent: boolean;
  note: string | null;
}

interface ICompleteAppointmentChildGroupResponse {
  id: number;
  name: string;
  child_groups: ICompleteAppointmentChildGroupResponse[] | null;
  items: ICompleteAppointmentItemResponse[] | null;
}

export interface IAppointmentQuestionnaireResponse {
  id: number;
  name: string;
  child_groups: ICompleteAppointmentChildGroupResponse[] | null;
  items: ICompleteAppointmentItemResponse[] | null;
}

export interface ICompleteAppointmentResponse {
  group: IAppointmentQuestionnaireResponse;
}

function mapCompleteAppointmentItem(
  item: ICompleteAppointmentItemResponse
): ICompleteAppointmentItem {
  return {
    id: item.id,
    text: item.text,
    explanation: item.explanation,
    type: item.type,
    fromTimestamp: item.from_timestamp,
    toTimestamp: item.to_timestamp,
    hours: item.hours,
    isParticipated: item.is_participated,
    isUnderDirection: item.is_under_direction,
    isAlmostIndependent: item.is_almost_independent,
    isIndependent: item.is_independent,
    note: item.note,
  };
}

function mapCompleteAppointmentChildGroup(
  childGroup: ICompleteAppointmentChildGroupResponse
): ICompleteAppointmentChildGroup {
  return {
    id: childGroup.id,
    name: childGroup.name,
    childGroups: childGroup.child_groups
      ? childGroup.child_groups.map(mapCompleteAppointmentChildGroup)
      : null,
    items: childGroup.items ? childGroup.items.map(mapCompleteAppointmentItem) : null,
  };
}

function mapCompleteAppointmentQuestionnaire(
  group: IAppointmentQuestionnaireResponse
): IAppointmentQuestionnaire {
  return {
    id: group.id,
    name: group.name,
    childGroups: group.child_groups
      ? group.child_groups.map(mapCompleteAppointmentChildGroup)
      : null,
    items: group.items ? group.items.map(mapCompleteAppointmentItem) : null,
  };
}

const mapFinishAppointment = (
  formValues: ICompleteAppointmentFormGroup
): FinishAppointmentRequest['json'] => {
  const result: FinishAppointmentRequest['json'] = [];

  function traverse(group: ICompleteAppointmentFormGroup, appointmentId: number) {
    if (group.items) {
      group.items.forEach((item) => {
        result.push({
          measure_to_person_id: group.id,
          appointment_id: appointmentId,
          level: item.id,
          is_participated: item.isParticipated,
          is_under_direction: item.isUnderDirection,
          is_almost_independent: item.isAlmostIndependent,
          is_independent: item.isIndependent,
          note: item.comment,
        });
      });
    }

    if (group.childGroup) {
      group.childGroup.forEach((child) => traverse(child, group.id));
    }
  }

  traverse(formValues, formValues.id);

  return result;
};

function* fetchAppointmentCompleteQuestionnaire(
  action: PayloadAction<IAppointmentCompleteModalFetchPayload>
): Generator<any, void, any> {
  const { measureID, appointmentID } = action.payload;

  yield put(
    activityPlaningAppointmentDetailsModalsActions.setAppointmentCompleteQuestionnaireLock(
      LoadingStatus.LOADING
    )
  );

  try {
    const request: AppointmentCompleteQuestionnaireRequest = {
      query: {
        measure_id: measureID,
        appointment_id: appointmentID,
      },
      ...authAdd(),
    };

    const response: ICompleteAppointmentResponse = yield call(
      restCall,
      '/activity_planning/measure/appointment/finish',
      'get',
      request
    );

    const appointmentCompleteQuestionnaire = mapCompleteAppointmentQuestionnaire(response.group);

    yield put(
      activityPlaningAppointmentDetailsModalsActions.setAppointmentCompleteQuestionnaire(
        appointmentCompleteQuestionnaire
      )
    );

    yield put(
      activityPlaningAppointmentDetailsModalsActions.setAppointmentCompleteQuestionnaireLock(
        LoadingStatus.LOADED
      )
    );
  } catch (error) {
    yield put(
      activityPlaningAppointmentDetailsModalsActions.setAppointmentCompleteQuestionnaireLock(
        LoadingStatus.ERROR
      )
    );
  }
}

function* finishAppointment(
  action: PayloadAction<IAppointmentFinishPayload>
): Generator<any, void, any> {
  const { formValues, personID, measureID } = action.payload;

  try {
    const request: FinishAppointmentRequest = {
      json: mapFinishAppointment(formValues),
      ...authAdd(),
    };

    yield call(restCall, '/activity_planning/measure/appointment/finish', 'post', request);

    yield put(activityPlaningAppointmentDetailsModalsActions.closeCompleteAppointmentModal());
    yield put(
      activityPlanningAppointmentsMeasuresListActions.fetchMeasuresAppointmentsList({
        personID: personID,
        measureID: measureID,
      })
    );
  } catch (error) {
    console.log('Error on appointment finish', error);
  }
}

export const activityPlaningAppointmentDetailsModalsSagas = [
  takeLatest(
    activityPlaningAppointmentDetailsModalsActions.fetchAppointmentCompleteQuestionnaire,
    fetchAppointmentCompleteQuestionnaire
  ),
  takeLatest(activityPlaningAppointmentDetailsModalsActions.finishAppointment, finishAppointment),
];
