import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IDiagnosticsSpecialFeaturesFetchPayload {
  personID: number;
}

export interface IDiagnosticsSpecialFeaturesUpdatePayload {
  personID: number;
  formValues: IDiagnosticsSpecialFeatures;
}

export interface IDiagnosticsSpecialFeatures {
  id: number;
  isDiabetic: boolean;
  isTabletsRequired: boolean;
  isInsulinRequired: boolean;
  isOther: boolean;
  isSeizureDisorder: boolean;
  isAsthmatic: boolean;
  isAllergic: boolean;
  isHeartDisease: boolean;
  isEmergencyMedication: boolean;
  lastSeizureDate?: string | null;
  seizureDisorderNote?: string | null;
  hasAllergyPassport?: boolean | null;
  allergicNote?: string | null;
  asthmaticNote?: string | null;
  heartDiseaseNote?: string | null;
  seizureDisorderForm?: string | null;
  diabeticNote?: string | null;
  otherNote?: string | null;
  careLevelID?: string | null;
}

export interface IDiagnosticsSpecialFeaturesState {
  diagnosticsSpecialFeatures: IDiagnosticsSpecialFeatures | null;
  diagnosticsSpecialFeaturesLock: LoadingStatus;
  fetchPayload: IDiagnosticsSpecialFeaturesFetchPayload | null;
  updatePayload: IDiagnosticsSpecialFeaturesUpdatePayload | null;
  updateLock: LoadingStatus;
}

const initialState: IDiagnosticsSpecialFeaturesState = {
  diagnosticsSpecialFeatures: null,
  diagnosticsSpecialFeaturesLock: LoadingStatus.NEVER,
  fetchPayload: null,
  updatePayload: null,
  updateLock: LoadingStatus.NEVER,
};

export const diagnosticsSpecialFeaturesSlice = createSlice({
  name: 'diagnosticsSpecialFeatures',
  initialState,
  reducers: {
    fetchDiagnosticsSpecialFeatures: (
      state,
      action: PayloadAction<IDiagnosticsSpecialFeaturesFetchPayload>
    ) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setDiagnosticsSpecialFeatures: (
      state,
      action: PayloadAction<IDiagnosticsSpecialFeatures | null>
    ) => ({
      ...state,
      diagnosticsSpecialFeatures: action.payload,
    }),
    setDiagnosticsSpecialFeaturesLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      diagnosticsSpecialFeaturesLock: action.payload,
    }),
    updateDiagnosticsSpecialFeatures: (
      state,
      action: PayloadAction<IDiagnosticsSpecialFeaturesUpdatePayload>
    ) => ({
      ...state,
      updatePayload: action.payload,
    }),
    setUpdateDiagnosticsSpecialFeaturesLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateLock: action.payload,
    }),
  },
});

export const diagnosticsSpecialFeaturesReducer = diagnosticsSpecialFeaturesSlice.reducer;
export const diagnosticsSpecialFeaturesActions = diagnosticsSpecialFeaturesSlice.actions;
