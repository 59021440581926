import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import fp from 'lodash/fp';
import { v4 } from 'uuid';

import { Button } from '@/components/Button/Button';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { PlusIcon } from '@/components/Icons/PlusIcon';
import { PrivateImage } from '@/components/PrivateImage/PrivateImage';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageActions, Tab } from '@/core/redux/slices/functionPage/functionPageSlice';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';

import styles from './styles.module.scss';

export const FunctionTabs = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.button' });
  const usersActiveTab = useAppSelector(functionPageSelectors.usersActiveTab);
  const tabs = useAppSelector(functionPageSelectors.tabs);
  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const selectedPersonId = selectedPerson?.id;
  const userTabs = selectedPersonId ? tabs[selectedPersonId] : {};
  const tabsArray = userTabs ? Object.keys(userTabs) : [];
  const [hasEmptyTab, setHasEmptyTab] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const activeUserTabId: string | null = useMemo(
    () => (selectedPersonId ? usersActiveTab[selectedPersonId] : null),
    [selectedPersonId, usersActiveTab]
  );

  const dispatch = useAppDispatch();
  const addNewTab = () => {
    if (selectedPersonId) {
      dispatch(
        functionPageActions.addTab({
          userId: selectedPersonId,
          tabId: v4(),
          tabData: { title: t('newFunction') },
        })
      );
    }
  };

  const handleClick = (id: string) => {
    if (selectedPersonId) {
      dispatch(functionPageActions.setUserActiveTab({ userId: selectedPersonId, activeTab: id }));
    }
  };

  const renderCrossIcon = (id: string) => {
    const handleClick = (e: React.MouseEvent<HTMLOrSVGElement>) => {
      e.stopPropagation();
      if (selectedPersonId) {
        dispatch(functionPageActions.removeTab({ userId: selectedPersonId, tabId: id }));
        dispatch(
          functionPageActions.setUserActiveTab({ userId: selectedPersonId, activeTab: null })
        );
      }
    };
    return (
      <div className={'shrink-0'}>
        <CrossIcon onClick={handleClick} />
      </div>
    );
  };

  useEffect(() => {
    dispatch(functionPageActions.loadTabsFromLocale());
  }, [dispatch]);

  const renderIcon = (iconUrl: string, isActive: boolean) => (
    <PrivateImage
      className={clsx({ [styles.activeIcon]: isActive }, 'w-5 h-5 shrink-0')}
      id={iconUrl}
      alt='function_logo'
    />
  );

  function checkEmptyTab(userTab: Tab): boolean {
    return fp.some((value) => !fp.has('functionItem', value), userTab);
  }

  useEffect(() => {
    const hasEmpty = checkEmptyTab(userTabs);
    setHasEmptyTab(!fp.isEmpty(userTabs) && hasEmpty);
  }, [userTabs]);

  useEffect(() => {
    dispatch(functionPageActions.setActiveTabId(activeUserTabId));

    if (activeUserTabId) {
      searchParams.set('activeTabID', activeUserTabId);
      setSearchParams(searchParams);
    }
  }, [activeUserTabId]);

  return (
    <div className={clsx(styles.tabContainer, 'flex flex-nowrap gap-[4px]')}>
      {tabsArray.map((key) => {
        const iconUrl = userTabs[key].functionItem?.iconUrl;
        return (
          <Button
            key={key}
            buttonVariant={activeUserTabId === key ? 'FunctionTab' : 'InActiveFunctionTab'}
            onClick={() => handleClick(key)}
          >
            {iconUrl ? renderIcon(iconUrl, activeUserTabId === key) : undefined}
            <div className={'max-w-[140px] truncate'}>
              {userTabs[key].functionItem?.name || t('newFunction')}
            </div>
            {renderCrossIcon(key)}
          </Button>
        );
      })}
      <div className={styles.newTabButtonContainer}>
        <Button
          onClick={addNewTab}
          buttonStyle={'Icon'}
          buttonVariant={'AddNewTab'}
          disabled={hasEmptyTab}
        >
          <PlusIcon />
        </Button>
      </div>
    </div>
  );
};
