import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const TableHeader: React.FC = () => {
  const { t: qualificationObjectivesTranslations } = useTranslation('statusReport', {
    keyPrefix: 'qualificationObjectives.tableHeader',
  });

  return (
    <div className={'grid grid-cols-12 gap-3 items-center'}>
      <Typography color={'Secondary'} type={'14-M'} className={'col-span-5'}>
        {qualificationObjectivesTranslations('qualificationObjective.label')}
      </Typography>
      <Typography color={'Secondary'} type={'14-M'} className={'justify-self-center'}>
        {qualificationObjectivesTranslations('launched.label')}
      </Typography>
      <Typography color={'Secondary'} type={'14-M'} className={'justify-self-center'}>
        {qualificationObjectivesTranslations('accomplished.label')}
      </Typography>
      <Typography color={'Secondary'} type={'14-M'} className={'justify-self-center text-center'}>
        {qualificationObjectivesTranslations('notStarted.label')}
      </Typography>
      <Typography color={'Secondary'} type={'14-M'} className={'col-span-4'}>
        {qualificationObjectivesTranslations('notStartedReason.label')}
      </Typography>
    </div>
  );
};
