import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { TestPerformerSelect } from '@/components/Modals/Modals/functions/testingResultModals/TestPerformerSelect/TestPerformerSelect';
import { TestProcedureSelect } from '@/components/Modals/Modals/functions/testingResultModals/TestProcedureSelect/TestProcedureSelect';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { testingResultSelectors } from '@/core/redux/slices/functions/testingResult/selectors';
import { IAppliedTestProcedure } from '@/core/redux/slices/functions/testingResult/testingResultSlice';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';
import { testingResultModalsActions } from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';
import { LoadingStatus } from '@/types/loadingStatus';

export const UpdateAppliedTestProcedureModalContent: React.FC = () => {
  const { t: updateAppliedTestProcedureModalTranslations } = useTranslation(
    'updateAppliedTestProcedureModal'
  );

  const appliedTestProcedureList = useAppSelector(testingResultSelectors.appliedTestProcedures);

  const { procedureID, updateAppliedTestProcedureLock } = useAppSelector(
    testingResultModalsSelectors.updateAppliedTestingResultModal
  );

  const dispatch = useAppDispatch();

  const form = useForm<IAppliedTestProcedure>();
  const currentValues = form.watch();
  const formIsDirty = form.formState.isDirty;

  const handleClose = () => {
    dispatch(testingResultModalsActions.closeUpdateAppliedTestProcedureModal());
  };

  const handleUpdate = () => {
    if (!procedureID) {
      return;
    }

    dispatch(
      testingResultModalsActions.updateAppliedTestProcedure({
        procedureID: procedureID,
        appliedTestProcedure: currentValues,
      })
    );
  };

  const handleCancel = () => {
    form.reset();
  };

  useEffect(() => {
    if (!procedureID || !appliedTestProcedureList) {
      return;
    }

    const foundAppliedProcedure = appliedTestProcedureList.find(
      (appliedProcedure) => appliedProcedure.id === procedureID
    );

    if (!foundAppliedProcedure) {
      return;
    }

    form.reset(foundAppliedProcedure);
  }, [appliedTestProcedureList, procedureID]);

  useEffect(() => {
    if (updateAppliedTestProcedureLock === LoadingStatus.LOADED) {
      handleClose();
    }
  }, [updateAppliedTestProcedureLock]);

  const allValuesFilled =
    currentValues.procedure &&
    currentValues.procedure.id !== undefined &&
    currentValues.performedTimestamp &&
    currentValues.testResult &&
    currentValues.performedBy &&
    currentValues.performedBy.id !== undefined;

  const footer = (
    <div className={'flex flex-row justify-end gap-3'}>
      {formIsDirty ? (
        <>
          <Button onClick={handleUpdate} disabled={!allValuesFilled}>
            {updateAppliedTestProcedureModalTranslations('buttons.save.label')}
          </Button>
          <Button buttonVariant={'Secondary'} onClick={handleCancel}>
            {updateAppliedTestProcedureModalTranslations('buttons.cancel.label')}
          </Button>
        </>
      ) : (
        <>
          <Button onClick={handleClose}>
            {updateAppliedTestProcedureModalTranslations('buttons.close.label')}
          </Button>
        </>
      )}
    </div>
  );

  return (
    <Sidebar position={'right'} onExit={handleClose} footer={footer}>
      <div className={'p-5 flex flex-col gap-4 min-w-[600px]'}>
        <FormProvider {...form}>
          <Typography type={'H3'}>
            {updateAppliedTestProcedureModalTranslations('title.label')}
          </Typography>
          <TestProcedureSelect />
          <div className={'flex flex-row gap-2 items-center justify-between'}>
            <div className={'flex flex-row items-center gap-4'}>
              <Typography>
                {updateAppliedTestProcedureModalTranslations('fields.createdDate.label')}
              </Typography>
              <DatePicker control={form.register('performedTimestamp')} />
            </div>
            <div className={'flex flex-row items-center gap-4'}>
              <Typography className={'shrink-0'}>
                {updateAppliedTestProcedureModalTranslations('fields.createdBy.label')}
              </Typography>
              <TestPerformerSelect />
            </div>
          </div>
          <div className={'flex flex-col gap-2 mt-4'}>
            <Typography>
              {updateAppliedTestProcedureModalTranslations('fields.procedureResult.label')}
            </Typography>
            <TextArea control={form.register('testResult')} />
          </div>
        </FormProvider>
      </div>
    </Sidebar>
  );
};
