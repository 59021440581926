import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IModalPayload {
  personID: number;
  measureID: number;
}

export interface IRecordingEffectivenessModalPayload {
  personID: number;
  measureID: number;
  selectedType: string;
  levelID: number;
}

export interface IModal {
  isRender: boolean;
  payload: IModalPayload | null;
}

export interface IRecordingEffectivenessModal {
  isRender: boolean;
  payload: IRecordingEffectivenessModalPayload | null;
}

export interface IEfficacyAssessmentModalsState {
  efficacyAssessmentModal: IModal;
  recordingEffectivenessModal: IRecordingEffectivenessModal;
}

const initialState: IEfficacyAssessmentModalsState = {
  efficacyAssessmentModal: {
    isRender: false,
    payload: null,
  },
  recordingEffectivenessModal: {
    isRender: false,
    payload: null,
  },
};

export const efficacyAssessmentModalSlice = createSlice({
  name: 'efficacyAssessmentModals',
  initialState,
  reducers: {
    // List modal
    openEfficacyAssessmentModal: (state, action: PayloadAction<IModalPayload>) => {
      return {
        ...state,
        efficacyAssessmentModal: {
          isRender: true,
          payload: action.payload,
        },
      };
    },
    closeEfficacyAssessmentModal: (state) => {
      return {
        ...state,
        efficacyAssessmentModal: {
          isRender: false,
          payload: null,
        },
      };
    },
    // Effectivness
    openRecordingEffectivenessModal: (state, action: PayloadAction<IRecordingEffectivenessModalPayload>) => {
      return {
        ...state,
        recordingEffectivenessModal: {
          isRender: true,
          payload: action.payload,
        },
      };
    },
    closeRecordingEffectivenessModal: (state) => {
      return {
        ...state,
        recordingEffectivenessModal: {
          isRender: false,
          payload: null,
        },
      };
    },
  },
});

export const efficacyAssessmentModalReducer = efficacyAssessmentModalSlice.reducer;
export const efficacyAssessmentModalActions = efficacyAssessmentModalSlice.actions;
