import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { SpecialistGeneralDataViews } from '@/core/enums/specialistGeneralDataViewsEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { specialistsSelectors } from '@/core/redux/slices/specialists/selectors';
import { colorTypes } from '@/styles/types';

import { CostCenters } from './CostCenters/CostCenters';
import { GeneralData } from './GeneralData/GeneralData';
import { Internships } from './Internships/Internships';
import { OrganizationUnits } from './OrganizationUnits/OrganizationUnits';

interface View {
  type: SpecialistGeneralDataViews;
  view: JSX.Element | null;
  title: string;
}

export const SpecialistGeneralData: React.FC = () => {
  const { t: specialistGeneralDataTranslations } = useTranslation('specialistGeneralData');

  const selectedSpecialist = useAppSelector(specialistsSelectors.selectedSpecialist);
  const userID = selectedSpecialist?.id;

  const [selectedView, setSelectedView] = useState<SpecialistGeneralDataViews>(
    SpecialistGeneralDataViews.GeneralData
  );

  const specialistGeneralDataTabs: Record<SpecialistGeneralDataViews, View> = {
    [SpecialistGeneralDataViews.GeneralData]: {
      type: SpecialistGeneralDataViews.GeneralData,
      view: userID ? <GeneralData userID={userID} /> : null,
      title: specialistGeneralDataTranslations('tabs.generalData.label'),
    },
    [SpecialistGeneralDataViews.CostCenters]: {
      type: SpecialistGeneralDataViews.CostCenters,
      view: userID ? <CostCenters userID={userID} /> : null,
      title: specialistGeneralDataTranslations('tabs.costCenters.label'),
    },
    [SpecialistGeneralDataViews.OrganizationUnits]: {
      type: SpecialistGeneralDataViews.OrganizationUnits,
      view: userID ? <OrganizationUnits userID={userID} /> : null,
      title: specialistGeneralDataTranslations('tabs.organizationUnits.label'),
    },
    [SpecialistGeneralDataViews.Internships]: {
      type: SpecialistGeneralDataViews.Internships,
      view: userID ? <Internships userID={userID}/> : null,
      title: specialistGeneralDataTranslations('tabs.internships.label'),
    },
  };

  const handleTabClick = (view: SpecialistGeneralDataViews) => {
    setSelectedView(view);
  };

  return (
    <FunctionWrapper>
      <FunctionContent className={'flex flex-col h-full'}>
        {userID && (
          <div className={`flex flex-row gap-3 border-b border-b-${colorTypes.Stroke} px-7`}>
            {Object.values(specialistGeneralDataTabs).map((view) => (
              <Button
                onClick={() => handleTabClick(view.type)}
                buttonVariant={selectedView === view.type ? 'Default' : 'Secondary'}
                key={view.type}
                className={'rounded-b-none px-4 pt-2 pb-[7px]'}
              >
                {view.title}
              </Button>
            ))}
          </div>
        )}
        {specialistGeneralDataTabs[selectedView].view}
      </FunctionContent>
    </FunctionWrapper>
  );
};
