import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.integrationPlan.qualificationDocumentation;

const qualificationDocumentation = createSelector(
  [selector],
  (state) => state.qualificationDocumentation
);
const qualificationDocumentationLock = createSelector(
  [selector],
  (state) => state.qualificationDocumentationLock
);
const updateQualificationDocumentationLock = createSelector(
  [selector],
  (state) => state.updateQualificationDocumentationLock
);
const qualificationObjectives = createSelector(
  [selector],
  (state) => state.qualificationDocumentation?.qualificationsDocumentations
);

export const qualificationDocumentationSelectors = {
  qualificationDocumentation,
  qualificationObjectives,
  qualificationDocumentationLock,
  updateQualificationDocumentationLock,
};
