import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const CompleteMeasureTableHeader: React.FC = () => {
  const { t: completeMeasureModalTranslations } = useTranslation('completeMeasureModal', {
    keyPrefix: 'measureTable.header',
  });

  return (
    <div
      className={`grid grid-cols-12 gap-3 items-center border-y border-${colorTypes.Stroke} px-[30px] py-3`}
    >
      <Typography color={'Secondary'} className={'col-span-2'}>
        {completeMeasureModalTranslations('stage.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-3'}>
        {completeMeasureModalTranslations('review.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeMeasureModalTranslations('isUnderDirection.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeMeasureModalTranslations('isParticipated.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeMeasureModalTranslations('isAlmostIndependent.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeMeasureModalTranslations('isIndependent.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-3'}>
        {completeMeasureModalTranslations('comment.label')}
      </Typography>
    </div>
  );
};
