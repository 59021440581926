import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import type oas from '@/services/rest/base/openapi';
import { Camelize } from '@/types/camelize';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  IInternshipsFetchPayload,
  IInternshipUpdatePayload,
  ILocationUpdatePayload,
  internshipsActions,
} from './internshipsSlice';

// types

type InternshipsResponse = OASOutput<NormalizeOAS<typeof oas>, '/internship', 'get', '200'>;
type UpdateInternshipRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/internship/assign_or_suspend',
  'post'
>;
type UpdateLocationRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/internship/location/assign_or_suspend',
  'post'
>;

// sagas

function* fecthInternships(
  action: PayloadAction<IInternshipsFetchPayload>
): Generator<any, void, InternshipsResponse> {
  const { specialistID } = action.payload;

  yield put(internshipsActions.setInternshipsLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/internship', 'get', {
      query: {
        specialist_id: specialistID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<InternshipsResponse> = toCamelCase(response);
    const internships = camelizeResponse.internships;

    yield put(internshipsActions.setInternships(internships));
    yield put(internshipsActions.setInternshipsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on internships fetching');
    yield put(internshipsActions.setInternshipsLock(LoadingStatus.ERROR));
  }
}

function* updateInternship(
  action: PayloadAction<IInternshipUpdatePayload>
): Generator<any, void, any> {
  const { specialistID, internshipID, isSelected } = action.payload;

  yield put(internshipsActions.setUpdateInternshipLock(LoadingStatus.LOADING));

  try {
    const request: UpdateInternshipRequest = {
      json: {
        specialist_id: specialistID,
        internship_id: internshipID,
        is_selected: isSelected,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/internship/assign_or_suspend',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    yield put(internshipsActions.setUpdateInternshipLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on update internship', error);
    yield put(internshipsActions.setUpdateInternshipLock(LoadingStatus.ERROR));
  }
}

function* updateLocation(
    action: PayloadAction<ILocationUpdatePayload>
  ): Generator<any, void, any> {
    const { specialistID, internshipID, isSelected, locationID } = action.payload;
  
    yield put(internshipsActions.setUpdateLocationLock(LoadingStatus.LOADING));
  
    try {
      const request: UpdateLocationRequest = {
        json: {
          specialist_id: specialistID,
          internship_id: internshipID,
          location_id: locationID,
          is_selected: isSelected,
        },
        ...authAdd(),
      };
  
      yield call(
        restCall,
        '/internship/location/assign_or_suspend',
        'post',
        {
          ...request,
        },
        null,
        true
      );
  
      yield put(internshipsActions.setUpdateLocationLock(LoadingStatus.LOADED));
    } catch (error) {
      console.error('Error on update location', error);
      yield put(internshipsActions.setUpdateLocationLock(LoadingStatus.ERROR));
    }
  }



export const internshipsSagas = [
  takeLatest(internshipsActions.fecthInternships, fecthInternships),
  takeLatest(internshipsActions.updateInternship, updateInternship),
  takeLatest(internshipsActions.updateLocation, updateLocation),
];
