import { useAppSelector } from '../redux/hooks';
import { IParameterItem } from '../redux/slices/parameters/parametersSlice';
import { parametersSelectors } from '../redux/slices/parameters/selectors';
import { intToColor } from '../utils/commonUtils';

export const useAppParameterValue = (parameterName: string): string => {
  const parameters = useAppSelector(parametersSelectors.parameters);

  const parameter =
    parameters.find((item: IParameterItem) => item.name === parameterName)?.value ?? '';

  return parameter;
};

export const useAppParameterColorValue = (parameterName: string): string => {
  const parameters = useAppSelector(parametersSelectors.parameters);

  const parameter =
    parameters.find((item: IParameterItem) => item.name === parameterName)?.value ?? '';

  return intToColor(Number(parameter));
};
