import { PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import type { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets/typings';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { BACKEND_DATE_FORMAT } from '@/core/constants/dateFormat';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { SerialAppointmentWeekNumberTypes } from '@/core/enums/functions/activityPlanning/serialAppointmentWeekNumberTypesEnum';
import { dropdownItemsActions } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import {
  activityPlanningAppointmentDetailsActions,
  IActivityPlanningAppointmentDetailsFetchPayload,
  IActivityPlanningAppointmentDetailsValuesFetchPayload,
  IAppointmentDetails,
  ICreateActivityPlanningAppointmentPayload,
  ISerialAppointmentDetailsCalculatePayload,
  ISerialAppointmentItem,
  IUpdateActivityPlanningAppointmentPayload,
} from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';
import { activityPlanningAppointmentsMeasuresListActions } from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/slice';
import { activityPlaningAppointmentDetailsModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';
import { parseTime, toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type AppointmentDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/details',
  'get',
  '200'
>;

type AppointmentDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/details',
  'get'
>;

type MeasureAidsValuesResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/vocational_training_area/measure/document',
  'get',
  '200'
>;

type MeasureAidsValuesRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/vocational_training_area/measure/document',
  'get'
>;

type CreateAppointmentRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment',
  'post'
>;

type UpdateAppointmentRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment',
  'put'
>;

type SerialAppointmentDetailsCalculateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/serial',
  'post'
>;

type SerialAppointmentListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/serial',
  'post',
  '200'
>;

const mapImplementationIDs = (implementationAids?: Record<string, boolean> | null): number[] => {
  if (!implementationAids) {
    return [];
  }

  const implementationAidsKeys = Object.keys(implementationAids);

  return implementationAidsKeys.reduce<number[]>((accum, aidKey) => {
    if (implementationAids[aidKey]) {
      accum.push(Number(aidKey));
    }

    return accum;
  }, []);
};

function* fetchAppointmentDetails(
  action: PayloadAction<IActivityPlanningAppointmentDetailsFetchPayload>
): Generator<any, void, any> {
  const { personID, appointmentID, measureID } = action.payload;

  try {
    yield put(
      activityPlanningAppointmentDetailsActions.setAppointmentDetailsLock(LoadingStatus.LOADING)
    );

    const request: AppointmentDetailsRequest = {
      query: {
        person_id: personID,
        appointment_id: appointmentID,
      },
      ...authAdd(),
    };

    const response: AppointmentDetailsResponse = yield call(
      restCall,
      '/activity_planning/measure/appointment/details',
      'get',
      request
    );

    const { appointment } = response;

    const appointmentDetails: IAppointmentDetails = {
      id: appointment.id,
      appointmentType: appointment.appointment_type,
      responsibleUser: appointment.responsible_user,
      appointmentEndTime: parseTime(appointment.appointment_end_time),
      appointmentStartTime: parseTime(appointment.appointment_start_time),
      appointmentInfo: appointment.appointment_info,
      hours: appointment.hours?.toFixed(2),
      location: appointment.location,
      measureTitle: appointment.measure_title,
      places: appointment.places,
      appointmentStartDate: toClientDateInput(appointment.appointment_start_date),
      appointmentEndDate: toClientDateInput(appointment.appointment_end_date),
      implementationAids: appointment.implementation_aids,
    };

    yield put(activityPlanningAppointmentDetailsActions.setAppointmentDetails(appointmentDetails));

    yield put(
      activityPlanningAppointmentDetailsActions.fetchAppointmentDetailsFields({
        measureID: measureID,
      })
    );

    yield put(
      activityPlanningAppointmentDetailsActions.setAppointmentDetailsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on activity planing measures list fetch', error);
    yield put(
      activityPlanningAppointmentDetailsActions.setAppointmentDetailsLock(LoadingStatus.ERROR)
    );
  }
}

function* fetchAppointmentDetailsFields(
  action: PayloadAction<IActivityPlanningAppointmentDetailsValuesFetchPayload>
): Generator<any, void, any> {
  const { measureID } = action.payload;

  try {
    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.MEASURE_LOCATION,
      })
    );

    const measureAidsValuesRequest: MeasureAidsValuesRequest = {
      query: {
        measure_id: measureID,
      },
      ...authAdd(),
    };

    const measureAidsResponse: MeasureAidsValuesResponse = yield call(
      restCall,
      '/vocational_training_area/measure/document',
      'get',
      measureAidsValuesRequest
    );

    yield put(
      activityPlanningAppointmentDetailsActions.setMeasureAidsValues(measureAidsResponse.documents)
    );

    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.APPOINTMENT_TYPE,
      })
    );
  } catch (error) {
    console.log('Error on appointment details values fetching', error);
  }
}

function* createAppointment(
  action: PayloadAction<ICreateActivityPlanningAppointmentPayload>
): Generator<any, void, any> {
  const { formValues, measureID, personID } = action.payload;

  if (!formValues) {
    return;
  }

  const today = format(new Date(), BACKEND_DATE_FORMAT);

  try {
    const request: CreateAppointmentRequest = {
      json: {
        measure_title: formValues.measureTitle ?? '',
        appointment_info: formValues.appointmentInfo ?? '',
        appointment_start_time: formValues.startTime,
        appointment_end_time: formValues.endTime,
        appointment_end_date: toBackendDate(formValues.endDate) ?? today,
        appointment_start_date: toBackendDate(formValues.startDate) ?? today,
        location: formValues.locationName ?? '',
        hours: formValues.duration,
        appointment_type_id: formValues.appointmentTypeID ?? -1,
        measure_id: measureID,
        places: formValues.places ?? 5,
        person_id: personID,
        implementation_aids_ids: mapImplementationIDs(formValues.implementationAids),
      },
      ...authAdd(),
    };

    yield call(restCall, '/activity_planning/measure/appointment', 'post', request);

    yield put(
      activityPlanningAppointmentsMeasuresListActions.fetchMeasuresAppointmentsList({
        measureID: measureID,
        personID: personID,
      })
    );

    yield put(activityPlaningAppointmentDetailsModalsActions.closeAppointmentDetailsModal());
  } catch (error) {
    console.log('Error on activity planning appointment creating', error);
  }
}

function* updateAppointment(
  action: PayloadAction<IUpdateActivityPlanningAppointmentPayload>
): Generator<any, void, any> {
  const { formValues, measureID, personID, appointmentID } = action.payload;

  if (!formValues) {
    return;
  }

  const today = format(new Date(), BACKEND_DATE_FORMAT);

  try {
    const request: UpdateAppointmentRequest = {
      json: {
        id: appointmentID,
        measure_title: formValues.measureTitle ?? '',
        appointment_info: formValues.appointmentInfo ?? '',
        appointment_start_time: formValues.startTime ?? null,
        appointment_end_time: formValues.endTime ?? null,
        appointment_end_date: toBackendDate(formValues.endDate) ?? today,
        appointment_start_date: toBackendDate(formValues.startDate) ?? today,
        location: formValues.locationName ?? '',
        hours: formValues.duration,
        appointment_type_id: formValues.appointmentTypeID ?? -1,
        places: formValues.places,
        implementation_aids_ids: mapImplementationIDs(formValues.implementationAids),
      },
      ...authAdd(),
    };

    yield call(restCall, '/activity_planning/measure/appointment', 'put', request);

    yield put(
      activityPlanningAppointmentsMeasuresListActions.fetchMeasuresAppointmentsList({
        measureID: measureID,
        personID: personID,
      })
    );

    yield put(activityPlaningAppointmentDetailsModalsActions.closeAppointmentDetailsModal());
  } catch (error) {
    console.log('Error on activity planning appointment creating', error);
  }
}

function* calculateSerialAppointmentDetails(
  action: PayloadAction<ISerialAppointmentDetailsCalculatePayload>
): Generator<any, void, SerialAppointmentListResponse> {
  const { formValues, appointmentEndTime, appointmentStartTime, firstAppointmentDate, personID } =
    action.payload;

  try {
    yield put(
      activityPlanningAppointmentDetailsActions.setSerialAppointmentDetailsCalculateLock(
        LoadingStatus.LOADING
      )
    );

    const parsedStartDate = toBackendDate(firstAppointmentDate);

    if (!appointmentEndTime || !appointmentStartTime || !parsedStartDate) {
      return;
    }

    const request: SerialAppointmentDetailsCalculateRequest = {
      json: {
        person_id: personID,
        appointment_end_time: appointmentEndTime,
        appointment_start_time: appointmentStartTime,
        first_appointment_date: parsedStartDate,
        serial_type_id: formValues?.serialTypeID ?? 1,
        end_date: toBackendDate(formValues?.duration.endDate),
        appointments_number: formValues?.duration.appointmentsNumber,
        daily:
          formValues?.serialTypeID === 1
            ? {
                is_working_days_only: formValues?.daily.isWorkingDaysOnly,
                days_interval: formValues?.daily.daysInterval,
              }
            : null,
        weekly:
          formValues?.serialTypeID === 2
            ? {
                weeks_interval: formValues?.weekly.weeksInterval || 0,
                on_sunday: formValues?.weekly.onSunday,
                on_monday: formValues?.weekly.onMonday,
                on_tuesday: formValues?.weekly.onTuesday,
                on_wednesday: formValues?.weekly.onWednesday,
                on_thursday: formValues?.weekly.onThursday,
                on_friday: formValues?.weekly.onFriday,
                on_saturday: formValues?.weekly.onSaturday,
              }
            : null,
        monthly:
          formValues?.serialTypeID === 3
            ? {
                is_regular_day: formValues?.monthly.isRegularDayOfMonth,
                months_interval: formValues?.monthly.monthsInterval || 0,
                days_interval: formValues?.monthly.daysInterval,
                week_number: formValues?.monthly.weekNumber
                  ? SerialAppointmentWeekNumberTypes[
                      formValues.monthly
                        .weekNumber as unknown as keyof typeof SerialAppointmentWeekNumberTypes
                    ]
                  : undefined,
                week_day_id: formValues?.monthly.weekDayID,
              }
            : null,
        yearly:
          formValues?.serialTypeID === 4
            ? {
                is_regular_month: formValues?.yearly.isRegularMonthOfYear,
                years_interval: formValues?.yearly.yearsInterval || 0,
                month_id: formValues?.yearly.monthID || 0,
                days_interval: formValues?.yearly.specificMonthDay,
                week_number: formValues?.monthly.weekNumber
                  ? SerialAppointmentWeekNumberTypes[
                      formValues.monthly
                        .weekNumber as unknown as keyof typeof SerialAppointmentWeekNumberTypes
                    ]
                  : undefined,
                week_day_id: formValues?.yearly.weekDayID,
              }
            : null,
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/activity_planning/measure/appointment/serial',
      'post',
      request
    );
    yield put(
      activityPlanningAppointmentDetailsActions.setSerialAppointmentDetailsCalculateLock(
        LoadingStatus.LOADED
      )
    );

    const mappedSerialAppointmentList = response.appointments_dates.map<ISerialAppointmentItem>(
      (appointmentDate) => ({
        appointmentDate: toClientDateInput(appointmentDate.appointment_date) || '',
        isValid: appointmentDate.is_valid,
      })
    );

    yield put(
      activityPlanningAppointmentDetailsActions.setSerialAppointmentList(
        mappedSerialAppointmentList
      )
    );
  } catch (error) {
    console.log('Error on serial appointment details updating', error);
    yield put(
      activityPlanningAppointmentDetailsActions.setSerialAppointmentDetailsCalculateLock(
        LoadingStatus.ERROR
      )
    );
  }
}

export const activityPlaningAppointmentDetailsSagas = [
  takeLatest(
    activityPlanningAppointmentDetailsActions.fetchAppointmentDetails,
    fetchAppointmentDetails
  ),
  takeLatest(activityPlanningAppointmentDetailsActions.createAppointment, createAppointment),
  takeLatest(activityPlanningAppointmentDetailsActions.updateAppointment, updateAppointment),
  takeLatest(
    activityPlanningAppointmentDetailsActions.fetchAppointmentDetailsFields,
    fetchAppointmentDetailsFields
  ),
  takeLatest(
    activityPlanningAppointmentDetailsActions.calculateSerialAppointmentDetails,
    calculateSerialAppointmentDetails
  ),
];
