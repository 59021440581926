import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets/typings';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { participantExpectationFunctionsTypes } from '@/core/enums/participantExpectationFunctionsEnum';
import { integrationPlanActions } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import {
  IParticipantExpectation,
  IParticipantExpectationFetchPayload,
  IParticipantExpectationUpdatePayload,
  participantExpectationActions,
} from '@/core/redux/slices/functions/integrationPlan/participantExpectation/participantExpectationSlice';
import { getSelectedOption } from '@/core/utils/commonUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type ParticipantExpectationResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/participant_expectation',
  'get',
  '200'
>;
type ParticipantExpectationRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/integration_plan/participant_expectation',
  'post'
>;

function* fetchParticipantExpectation(
  action: PayloadAction<IParticipantExpectationFetchPayload>
): Generator<any, void, ParticipantExpectationResponse> {
  yield put(participantExpectationActions.setParticipantExpectationLock(LoadingStatus.LOADING));

  const { integrationPlanID } = action.payload;

  try {
    const response = yield call(restCall, `/integration_plan/participant_expectation`, 'get', {
      query: { integration_plan_id: integrationPlanID },
      ...authAdd(),
    });

    const { participant_expectation } = response;

    const creationReasonBlock: Record<string, boolean> = {
      [participantExpectationFunctionsTypes.isRecord]: participant_expectation.is_record,
      [participantExpectationFunctionsTypes.isEndeBBB]: participant_expectation.is_ende_bbb,
      [participantExpectationFunctionsTypes.isIndividualAppointment]:
        participant_expectation.is_individual_appointment,
    };

    const participantExpectation: IParticipantExpectation = {
      creationReason: getSelectedOption(creationReasonBlock),
      individualAppointmentReason: participant_expectation.individual_appointment_reason,
      inm1: participant_expectation.inm1,
      inm1Method: participant_expectation.inm1_method,
      inm2: participant_expectation.inm2,
      inm2Method: participant_expectation.inm2_method,
      inm3: participant_expectation.inm3,
      inm3Method: participant_expectation.inm3_method,
      inm4: participant_expectation.inm4,
      inm4Method: participant_expectation.inm4_method,
      wem1: participant_expectation.wem1,
      wem1Method: participant_expectation.wem1_method,
      wem2: participant_expectation.wem2,
      wem2Method: participant_expectation.wem2_method,
      wem3: participant_expectation.wem3,
      wem3Method: participant_expectation.wem3_method,
      notes: participant_expectation.notes,
    };

    yield put(participantExpectationActions.setParticipantExpectation(participantExpectation));

    yield put(participantExpectationActions.setParticipantExpectationLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration plan participant expectation fetching');
    yield put(participantExpectationActions.setParticipantExpectationLock(LoadingStatus.ERROR));
  }
}

function* updateParticipantExpectation(
  action: PayloadAction<IParticipantExpectationUpdatePayload>
): Generator<any, void, any> {
  const { integrationPlanID, personID, participantExpectation } = action.payload;
  try {
    yield put(
      participantExpectationActions.setUpdateParticipantExpectationLock(LoadingStatus.LOADING)
    );

    const request: ParticipantExpectationRequest = {
      query: {
        person_id: personID,
        integration_plan_id: integrationPlanID,
      },
      json: {
        is_individual_appointment:
          participantExpectation.creationReason ===
          participantExpectationFunctionsTypes.isIndividualAppointment,
        is_record:
          participantExpectation.creationReason === participantExpectationFunctionsTypes.isRecord,
        is_ende_bbb:
          participantExpectation.creationReason === participantExpectationFunctionsTypes.isEndeBBB,
        notes: participantExpectation.notes ?? null,
        individual_appointment_reason: participantExpectation.individualAppointmentReason ?? null,
        wem1: participantExpectation.wem1 ?? null,
        wem2: participantExpectation.wem2 ?? null,
        wem3: participantExpectation.wem3 ?? null,
        inm1: participantExpectation.inm1 ?? null,
        inm2: participantExpectation.inm2 ?? null,
        inm3: participantExpectation.inm3 ?? null,
        inm4: participantExpectation.inm4 ?? null,
        wem1_method: participantExpectation.wem1Method ?? null,
        wem2_method: participantExpectation.wem2Method ?? null,
        wem3_method: participantExpectation.wem3Method ?? null,
        inm1_method: participantExpectation.inm1Method ?? null,
        inm2_method: participantExpectation.inm2Method ?? null,
        inm3_method: participantExpectation.inm3Method ?? null,
        inm4_method: participantExpectation.inm4Method ?? null,
      },
      ...authAdd(),
    };

    const updateResponse = yield call(
      restCall,
      '/integration_plan/participant_expectation',
      'post',
      {
        ...request,
      }
    );

    yield put(
      integrationPlanActions.checkIntegrationPlanUpdate({
        integrationPlanID,
        updateResponse: updateResponse,
      })
    );

    yield put(
      participantExpectationActions.setUpdateParticipantExpectationLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on integration plan participant expectation updating');
    yield put(
      participantExpectationActions.setUpdateParticipantExpectationLock(LoadingStatus.ERROR)
    );
  }
}

export const participantExpectationSagas = [
  takeLatest(
    participantExpectationActions.fetchParticipantExpectation,
    fetchParticipantExpectation
  ),
  takeLatest(
    participantExpectationActions.updateParticipantExpectation,
    updateParticipantExpectation
  ),
];
