import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { MedicalInformationTypes } from '@/core/enums/functions/medicalInformation/medicalInformationTypesEnum';

interface IMedicalInformationTabs {
  selectedTab: MedicalInformationTypes;
  setSelectedTab: (selectedTab: MedicalInformationTypes) => void;
}

export const MedicalInformationTabs: React.FC<IMedicalInformationTabs> = ({
  selectedTab,
  setSelectedTab,
}) => {
  const { t: medicalInformationTranslations } = useTranslation('medicalInformation', {
    keyPrefix: 'tabs',
  });

  const tabs = Object.values(MedicalInformationTypes);

  const handleSelectTab = (selectedTab: MedicalInformationTypes) => {
    setSelectedTab(selectedTab);
  };

  return (
    <div className={'flex flex-row gap-3 border-b border-b-${colorTypes.Stroke}'}>
      {tabs.map((tab, index) => (
        <Button
          buttonVariant={selectedTab === tab ? 'Default' : 'Secondary'}
          className={'rounded-b-none px-4 pt-2 pb-[7px]'}
          key={index}
          onClick={() => handleSelectTab(tab)}
        >
          {medicalInformationTranslations(`${tab}.label`)}
        </Button>
      ))}
    </div>
  );
};
