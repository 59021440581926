import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { AdministrationLocationManagementDepartmentHeader } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementDepartmentList/AdministrationLocationManagementDepartmentHeader';
import { AdministrationLocationManagementDepartmentItem } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementDepartmentList/AdministrationLocationManagementDepartmentItem';
import { IAdministrationLocationManagementForm } from '@/components/administartionFunctions/locationManagement/hooks/useAdministrationLocationManagementForm';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { administrationLocationManagementSelectors } from '@/core/redux/slices/administrationFunctions/locationManagement/selectors';
import { administrationLocationManagementActions } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';

export const AdministrationLocationManagementDepartmentList: React.FC = () => {
  const departments = useAppSelector(administrationLocationManagementSelectors.departments);
  const departmentsLock = useAppSelector(administrationLocationManagementSelectors.departmentsLock);

  const { watch } = useFormContext<IAdministrationLocationManagementForm>();
  const selectedLocation = watch('selectedLocation');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedLocation) {
      return;
    }

    dispatch(
      administrationLocationManagementActions.fetchDepartments({
        locationID: selectedLocation.id,
      })
    );
  }, [selectedLocation]);

  return (
    <div className={'h-full flex flex-col overflow-hidden'}>
      <AdministrationLocationManagementDepartmentHeader />
      <div className={'flex flex-col flex-1 overflow-auto'}>
        {selectedLocation &&
          departments &&
          departments.map((department, index) => (
            <AdministrationLocationManagementDepartmentItem
              department={department}
              key={department.id}
              formIndex={index}
            />
          ))}
      </div>
    </div>
  );
};
