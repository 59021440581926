import { FormItem } from '@/components/FormRender/FormRender';

export const firstColumn: FormItem[] = [
  {
    id: '1',
    name: 'gender',
    label: 'fields.gender.label',
    type: 'select',
    options: [
      {
        id: 'one',
        name: 'One',
      },
      {
        id: 'two',
        name: 'Two',
      },
    ],
  },
  {
    id: '2',
    name: 'name',
    label: 'fields.name.label',
    type: 'input',
  },
  {
    id: '3',
    name: 'forename',
    label: 'fields.surname.label',
    type: 'input',
  },
  {
    id: '4',
    name: 'address',
    label: 'fields.address.label',
    type: 'input',
  },
  {
    id: '5',
    name: 'post-code',
    label: 'fields.postCode.label',
    className: 'w-[calc(50%_-_10px)] mr-2.5',
    type: 'input',
  },
  {
    id: '6',
    name: 'city',
    label: 'fields.city.label',
    className: 'w-1/2',
    type: 'input',
  },
  {
    id: '12',
    name: 'check',
    options: ['Check one'],
    type: 'checkbox',
  },
];

export const secondColumn: FormItem[] = [
  {
    id: '7',
    name: 'telephone',
    label: 'fields.telephone.label',
    type: 'input',
  },
  {
    id: '8',
    name: 'mobilephone',
    label: 'fields.mobilePhone.label',
    type: 'input',
  },
  {
    id: '9',
    name: 'email',
    label: 'fields.email.label',
    type: 'input',
  },
  {
    id: '10',
    name: 'fax',
    label: 'fields.fax.label',
    type: 'input',
  },
  {
    id: '11',
    name: 'association',
    label: 'fields.association.label',
    type: 'select',
    options: [
      { id: 'one', name: 'One' },
      {
        id: 'two',
        name: 'Two',
      },
    ],
  },
];
