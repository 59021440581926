import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { IFormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { DevelopmentReportActionsBar } from '@/components/functions/DevelopmentReport/DevelopmentReportActionsBar/DevelopmentReportActionsBar';
import { visionAndGoalsDefaultValues } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/VisionAndGoals/helper/visionAndGoalsForm';
import { useDevelopmentReport } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReport';
import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { DevelopmentReportVisionAndGoalsTypes } from '@/core/enums/functions/developmentReport/developmentReportVisionAndGoalsTypesEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { developmentReportVisionAndGoalsSelectors } from '@/core/redux/slices/functions/developmentReport/developmentReportVisionAndGoalsSlice/selectors';
import { developmentReportVisionAndGoalsActions } from '@/core/redux/slices/functions/developmentReport/developmentReportVisionAndGoalsSlice/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const DevelopmentReportVisionAndGoals: React.FC = () => {
  const { t: visionAndGoalsTranslations } = useTranslation('developmentReportVisionAndGoals');

  const { developmentReportID } = useDevelopmentReport();

  const {
    reset,
    watch,
    formState: { errors, isDirty },
  } = useFormContext<IDevelopmentReportForm>();
  const dispatch = useAppDispatch();

  const developmentReportVisionAndGoals = useAppSelector(
    developmentReportVisionAndGoalsSelectors.developmentReportVisionAndGoals
  );
  const developmentReportVisionAndGoalsLock = useAppSelector(
    developmentReportVisionAndGoalsSelectors.developmentReportVisionAndGoalsLock
  );

  useEffect(() => {
    if (!developmentReportID) {
      return;
    }

    dispatch(
      developmentReportVisionAndGoalsActions.fetchDevelopmentReportVisionAndGoals({
        developmentReportID: developmentReportID,
      })
    );

    return () => {
      dispatch(
        developmentReportVisionAndGoalsActions.setDevelopmentReportVisionAndGoals({
          visionAndGoals: [],
        })
      );

      const currentValues = fp.cloneDeep(watch());
      reset({
        ...currentValues,
        [DevelopmentReportForms.VisionAndGoals]: visionAndGoalsDefaultValues,
      });
    };
  }, [developmentReportID]);

  const viewTypeOptions: Omit<IFormRadioButtonGroup, 'control'> = {
    options: [
      {
        value: DevelopmentReportVisionAndGoalsTypes.OwnExclusion,
        component: (
          <Typography>{visionAndGoalsTranslations('fields.ownExclusion.label')}</Typography>
        ),
      },
      {
        value: DevelopmentReportVisionAndGoalsTypes.DeputyExclusion,
        component: (
          <Typography>{visionAndGoalsTranslations('fields.deputyExclusion.label')}</Typography>
        ),
      },
    ],
    containerClassName: 'flex-row gap-5',
  };

  const developmentReportGoalsAndActivityForm: (
    | FormItem<IDevelopmentReportForm>
    | FormGroup<IDevelopmentReportForm>
  )[] = developmentReportVisionAndGoals
    ? developmentReportVisionAndGoals.visionAndGoals.map<
        FormItem<IDevelopmentReportForm> | FormGroup<IDevelopmentReportForm>
      >((visionAndGoal, index) => ({
        fieldsContainerClassName: 'grid grid-cols-2 gap-5',
        fields: [
          {
            fieldsContainerClassName: 'flex flex-col gap-2',
            fields: [
              {
                name: `${DevelopmentReportForms.VisionAndGoals}.visionAndGoals.${index}.viewType`,
                type: 'radio-button-group',
                radioButtonGroupOptions: viewTypeOptions,
              },
              {
                name: `${DevelopmentReportForms.VisionAndGoals}.visionAndGoals.${index}.ownView`,
                type: 'textarea',
              },
            ],
          },
          {
            name: `${DevelopmentReportForms.VisionAndGoals}.visionAndGoals.${index}.representativeView`,
            type: 'textarea',
            label: 'fields.professionalView.label',
          },
        ],
      }))
    : [];

  return (
    <Skeleton trigger={developmentReportVisionAndGoalsLock === LoadingStatus.LOADING}>
      <FormRender<IDevelopmentReportForm>
        list={developmentReportGoalsAndActivityForm}
        translationContext={visionAndGoalsTranslations}
        className={'py-4 flex flex-col gap-2'}
      />
      <DevelopmentReportActionsBar
        errorsOptions={{
          errors: errors,
          formKey: DevelopmentReportForms.VisionAndGoals,
        }}
        resetOptions={{
          isDirty: isDirty,
          onReset: reset,
        }}
      />
    </Skeleton>
  );
};
