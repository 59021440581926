import React from 'react';

import { QualificationObjectivesItem } from '@/components/Modals/Modals/functions/integrationPlanModals/QualificationObjectivesModal/QualificationObjectivesContent/QualificationObjectivesList/QualificationObjectivesItem/QualificationObjectivesItem';
import { IQualificationObjectivesModalItem } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';

interface IQualificationObjectiveList {
  qualificationObjectives: IQualificationObjectivesModalItem[];
}

export const QualificationObjectivesList: React.FC<IQualificationObjectiveList> = ({
  qualificationObjectives,
}) => {
  if (qualificationObjectives.length < 0) {
    return null;
  }

  return qualificationObjectives.map((qualificationObjective) => (
    <QualificationObjectivesItem
      name={qualificationObjective.qualificationObjectiveName}
      id={`${qualificationObjective.id}`}
      dateFrom={qualificationObjective.fromTimestamp}
      dateTo={qualificationObjective.toTimestamp}
      key={qualificationObjective.id}
    />
  ));
};
