import React, { useEffect } from 'react';
import { setDefaultOptions } from 'date-fns';

import useAppInitialization from '@/app/hooks/useAppInitialization';
import { NotificationsContainer } from '@/components/NotificationsContainer/NotificationsContainer';
import { useAppSelector } from '@/core/redux/hooks';
import { userSettingsSelectors } from '@/core/redux/slices/userSettings/selectors';
import { getDateFnsLocale } from '@/core/utils/dateTimeUtil';
import RouteHolder from '@/route/RouteHolder/v1/RouteHolder';

const Main: React.FC = () => {
  const isAppInitializationInProgress = useAppInitialization();

  const locale = useAppSelector(userSettingsSelectors.userSettingsLanguage);

  useEffect(() => {
    const fnsLocale = getDateFnsLocale({ locale: locale });
    setDefaultOptions({ locale: fnsLocale });
  }, [locale]);

  return (
    <>
      {!isAppInitializationInProgress && (
        <NotificationsContainer>
          <RouteHolder />
        </NotificationsContainer>
      )}
    </>
  );
};

export default Main;
