import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IModal {
  isRender: boolean;
}

export interface IIntegrationPlanPayload {
  integrationPlanID: number;
  personID: number;
}

export interface IQualificationObjectivesPayload extends IIntegrationPlanPayload {
  qualificationsObjectives: Record<string, boolean> | null;
}

export interface IQualificationObjectivesModalItem {
  id: number;
  isSelected: boolean;
  qualificationObjectiveName?: string | null;
  fromTimestamp?: string | null;
  toTimestamp?: string | null;
}

export interface IQualificationObjectivesModal extends IModal {
  payload: IQualificationObjectivesPayload | null;
  qualificationObjectives: IQualificationObjectivesModalItem[];
}

export interface IActionCourseCreatedDateModalPayload extends IIntegrationPlanPayload {
  createdTimestamp: string;
}

export interface IActionCourseCreatedDateModal extends IModal {
  payload: IActionCourseCreatedDateModalPayload | null;
}

export interface IGeneralDataUpdatedDataModalPayload extends IIntegrationPlanPayload {
  updatedData: Record<string, string>;
}

export interface IGeneralDataUpdatedDataModal extends IModal {
  payload: IGeneralDataUpdatedDataModalPayload | null;
}

export interface ICompetenceAnalysisImportModalPayload {
  personID: number;
}

export interface ICompetenceAnalysisImportModal extends IModal {
  payload: ICompetenceAnalysisImportModalPayload | null;
}

export interface IIntegrationPlanPredefinedVariant {
  id: number;
  name: string;
  variantIds: number[];
}

export interface ICreateIntegrationPlanDocumentsPayload {
  personID: number;
  integrationPlanID: number;
  finalizeIntegrationPlan: boolean | null;
  selectedIds: number[] | undefined;
}

export interface ICreateIntegrationPlanDocumentsModal extends IModal {
  payload: ICreateIntegrationPlanDocumentsPayload | null;
  printVariants: Record<string, string>;
  predefinedVariants: IIntegrationPlanPredefinedVariant[];
  printVariantsLoadingStatus: LoadingStatus;
  getDocumentsLoadingStatus: LoadingStatus;
}

export interface IIntegrationPlanVersionHistoryItem {
  id: number;
  printedTimestamp?: string | null;
}

export interface IIntegrationPlanVersionHistoryModalPayload {
  personID: number;
}

export interface IIntegrationPlanVersionHistoryModal extends IModal {
  payload: IIntegrationPlanVersionHistoryModalPayload | null;
  integrationPlanVersionHistory: IIntegrationPlanVersionHistoryItem[];
  integrationPlanVersionHistoryLock: LoadingStatus;
}

export interface IIntegrationPlanModalsState {
  qualificationObjectivesModal: IQualificationObjectivesModal;
  internshipDocumentationModal: IModal;
  actionCourseCreatedDateModal: IActionCourseCreatedDateModal;
  generalDataUpdatedDataModal: IGeneralDataUpdatedDataModal;
  createIntegrationPlanDocumentsModal: ICreateIntegrationPlanDocumentsModal;
  competenceAnalysisImportModal: ICompetenceAnalysisImportModal;
  integrationPlanVersionHistory: IIntegrationPlanVersionHistoryModal;
}

const initialState: IIntegrationPlanModalsState = {
  qualificationObjectivesModal: {
    isRender: false,
    payload: null,
    qualificationObjectives: [],
  },
  internshipDocumentationModal: {
    isRender: false,
  },
  createIntegrationPlanDocumentsModal: {
    isRender: false,
    payload: null,
    printVariants: {},
    predefinedVariants: [],
    printVariantsLoadingStatus: LoadingStatus.NEVER,
    getDocumentsLoadingStatus: LoadingStatus.NEVER,
  },
  actionCourseCreatedDateModal: {
    isRender: false,
    payload: null,
  },
  generalDataUpdatedDataModal: {
    isRender: false,
    payload: null,
  },
  competenceAnalysisImportModal: {
    isRender: false,
    payload: null,
  },
  integrationPlanVersionHistory: {
    isRender: false,
    payload: null,
    integrationPlanVersionHistory: [],
    integrationPlanVersionHistoryLock: LoadingStatus.NEVER,
  },
};

export const integrationPlanModalsSlice = createSlice({
  name: 'integrationPlanModals',
  initialState,
  reducers: {
    //Qualification objectives
    openQualificationObjectivesModal: (state, action: PayloadAction<IIntegrationPlanPayload>) => ({
      ...state,
      qualificationObjectivesModal: {
        ...state.qualificationObjectivesModal,
        isRender: true,
        payload: {
          qualificationsObjectives: null,
          integrationPlanID: action.payload.integrationPlanID,
          personID: action.payload.personID,
        },
      },
    }),
    fetchQualificationObjectivesModal: (state, action: PayloadAction<IIntegrationPlanPayload>) => ({
      ...state,
      qualificationObjectivesModal: {
        ...state.qualificationObjectivesModal,
        payload: {
          qualificationsObjectives: null,
          integrationPlanID: action.payload.integrationPlanID,
          personID: action.payload.personID,
        },
      },
    }),
    updateQualificationObjectives: (
      state,
      action: PayloadAction<IQualificationObjectivesPayload>
    ) => {
      return {
        ...state,
        qualificationObjectivesModal: {
          ...state.qualificationObjectivesModal,
          payload: action.payload,
        },
      };
    },
    setQualificationObjectivesModalData: (
      state,
      action: PayloadAction<IQualificationObjectivesModalItem[]>
    ) => {
      return {
        ...state,
        qualificationObjectivesModal: {
          ...state.qualificationObjectivesModal,
          qualificationObjectives: action.payload,
        },
      };
    },
    closeQualificationObjectivesModal: (state) => ({
      ...state,
      qualificationObjectivesModal: {
        isRender: false,
        payload: null,
        updateQualificationObjectivesPayload: [],
        qualificationObjectives: [],
      },
    }),

    //Internships
    openInternshipDocumentationModal: (state) => ({
      ...state,
      internshipDocumentationModal: {
        isRender: true,
      },
    }),
    closeInternshipDocumentationModal: (state) => ({
      ...state,
      internshipDocumentationModal: {
        isRender: false,
      },
    }),

    //General data updated data
    openGeneralDataUpdatedDataModal: (
      state,
      action: PayloadAction<IGeneralDataUpdatedDataModalPayload>
    ) => ({
      ...state,
      generalDataUpdatedDataModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeGeneralDataUpdatedDataModal: (state) => ({
      ...state,
      generalDataUpdatedDataModal: {
        isRender: false,
        payload: null,
      },
    }),

    //Action course created date
    openActionCourseCreatedDateModal: (
      state,
      action: PayloadAction<IActionCourseCreatedDateModalPayload>
    ) => ({
      ...state,
      actionCourseCreatedDateModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeActionCourseCreatedDateModal: (state) => ({
      ...state,
      actionCourseCreatedDateModal: {
        isRender: false,
        payload: null,
      },
    }),

    //Integration plan documents
    openCreateIntegrationPlanDocumentsModal: (state) => ({
      ...state,
      createIntegrationPlanDocumentsModal: {
        ...state.createIntegrationPlanDocumentsModal,
        isRender: true,
      },
    }),
    closeCreateIntegrationPlanDocumentsModal: (state) => ({
      ...state,
      createIntegrationPlanDocumentsModal: {
        isRender: false,
        payload: null,
        printVariants: {},
        predefinedVariants: [],
        printVariantsLoadingStatus: LoadingStatus.NEVER,
        getDocumentsLoadingStatus: LoadingStatus.NEVER,
      },
    }),
    fetchIntegrationPlanPrintVariants: () => {},
    setIntegrationPlanPrintVariants: (state, action: PayloadAction<Record<string, string>>) => ({
      ...state,
      createIntegrationPlanDocumentsModal: {
        ...state.createIntegrationPlanDocumentsModal,
        printVariants: action.payload,
      },
    }),
    setIntegrationPlanPredefinedPrintVariants: (
      state,
      action: PayloadAction<IIntegrationPlanPredefinedVariant[]>
    ) => ({
      ...state,
      createIntegrationPlanDocumentsModal: {
        ...state.createIntegrationPlanDocumentsModal,
        predefinedVariants: action.payload,
      },
    }),
    getIntegrationPlanDocuments: (
      state,
      action: PayloadAction<ICreateIntegrationPlanDocumentsPayload>
    ) => ({
      ...state,
      createIntegrationPlanDocumentsModal: {
        ...state.createIntegrationPlanDocumentsModal,
        payload: action.payload,
      },
    }),
    setPrintVariantsLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      createIntegrationPlanDocumentsModal: {
        ...state.createIntegrationPlanDocumentsModal,
        printVariantsLoadingStatus: action.payload,
      },
    }),
    setGetDocumentsLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      createIntegrationPlanDocumentsModal: {
        ...state.createIntegrationPlanDocumentsModal,
        getDocumentsLoadingStatus: action.payload,
      },
    }),
    getPreviewIntegrationPlanDocuments: (
      state,
      action: PayloadAction<ICreateIntegrationPlanDocumentsPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    //Competence analysis import
    openCompetenceAnalysisImportModal: (
      state,
      action: PayloadAction<ICompetenceAnalysisImportModalPayload>
    ) => ({
      ...state,
      competenceAnalysisImportModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeCompetenceAnalysisImportModal: (state) => ({
      ...state,
      competenceAnalysisImportModal: {
        isRender: false,
        payload: null,
      },
    }),

    //Integration plan version history
    openIntegrationPlanVersionHistoryModal: (
      state,
      action: PayloadAction<IIntegrationPlanVersionHistoryModalPayload>
    ) => ({
      ...state,
      integrationPlanVersionHistory: {
        ...state.integrationPlanVersionHistory,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeIntegrationPlanVersionHistoryModal: (state) => ({
      ...state,
      integrationPlanVersionHistory: {
        isRender: false,
        payload: null,
        integrationPlanVersionHistory: [],
        integrationPlanVersionHistoryLock: LoadingStatus.NEVER,
      },
    }),
    fetchIntegrationPlanVersionHistory: (
      state,
      action: PayloadAction<IIntegrationPlanVersionHistoryModalPayload>
    ) => ({
      ...state,
      integrationPlanVersionHistory: {
        ...state.integrationPlanVersionHistory,
        payload: action.payload,
      },
    }),
    setIntegrationPlanVersionHistory: (
      state,
      action: PayloadAction<IIntegrationPlanVersionHistoryItem[]>
    ) => ({
      ...state,
      integrationPlanVersionHistory: {
        ...state.integrationPlanVersionHistory,
        integrationPlanVersionHistory: action.payload,
      },
    }),
    setIntegrationPlanVersionHistoryLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      integrationPlanVersionHistory: {
        ...state.integrationPlanVersionHistory,
        integrationPlanVersionHistoryLock: action.payload,
      },
    }),
  },
});

export const integrationPlanModalsReducers = integrationPlanModalsSlice.reducer;
export const integrationPlanModalsActions = integrationPlanModalsSlice.actions;
