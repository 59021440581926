import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Typography } from '@/components/Typography/Typography';
import { ActivitiesContainer } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/ActivitiesContainer';
import { ActivitiesDatePicker } from '@/components/widgets/ActivitiesWidget/ActivitiesDatePicker/ActivitiesDatePicker';
import { TotalActivitiesCount } from '@/components/widgets/ActivitiesWidget/TotalActivitiesCount/TotalActivitiesCount';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dashboardActions } from '@/core/redux/slices/dashboard/dashboardSlice';
import { dashboardSelectors } from '@/core/redux/slices/dashboard/selectors';

import styles from './styles.module.scss';

export const ActivitiesWidget: React.FC = () => {
  const { t: activitiesWidgetTranslations } = useTranslation('activitiesWidget', {
    keyPrefix: 'activitiesContentTable',
  });

  const chosenDateRange = useAppSelector(dashboardSelectors.activitiesDateRange);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!chosenDateRange) {
      dispatch(dashboardActions.fetchActivities(null));
      return;
    }
    dispatch(dashboardActions.fetchActivities({ dateRangeType: chosenDateRange }));
  }, [chosenDateRange]);

  return (
    <>
      <ActivitiesDatePicker />
      <div className={'w-full flex flex-col justify-start'}>
        <TotalActivitiesCount />
        <div
          className={
            'flex flex-row justify-between border-y border-activity-border py-3 px-9 mt-[20px]'
          }
        >
          <Typography type={'16-R'} color={'Secondary'} className={'w-2/3'}>
            {activitiesWidgetTranslations('activityColumn.title.label')}
          </Typography>
          <Typography type={'16-R'} color={'Secondary'} className={'w-1/3 ms-9'}>
            {activitiesWidgetTranslations('personsColumn.title.label')}
          </Typography>
        </div>
        <div className={clsx('flex flex-row w-full max-h-[600px] overflow-auto', styles.scrollbar)}>
          <ActivitiesContainer />
        </div>
      </div>
    </>
  );
};
