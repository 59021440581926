import React from 'react';
import clsx from 'clsx';

import { Typography } from '@/components/Typography/Typography';
import { FunctionItem } from '@/components/views/functions/FunctionContainer/FunctionListView/FunctionGroupItem/FunctionItem/FunctionItem';
import { IFunctionListItem } from '@/types/functions';

import styles from './styles.module.scss';

export const FunctionGroupItem: React.FC<IFunctionListItem> = ({ heading, functions }) => (
  <div className={clsx(styles.container, 'flex flex-col items-start justify-start w-full')}>
    <Typography type='H3' color='Primary' className='ms-7'>
      {heading}
    </Typography>
    {functions &&
      functions.map((item) => (
        <FunctionItem
          id={item.id}
          functionID={item.functionID}
          isPinned={item.isPinned}
          functionType={item.functionType}
          iconUrl={item.iconUrl}
          name={item.name}
          key={item.id}
          orderNumber={item.orderNumber}
        />
      ))}
  </div>
);
