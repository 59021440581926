import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.researchResult;

const appliedTestProcedures = createSelector([selector], (state) => state.appliedTestProcedures);
const appliedTestProceduresLock = createSelector(
  [selector],
  (state) => state.appliedTestProceduresLock
);

const internalDifferentiation = createSelector(
  [selector],
  (state) => state.internalDifferentiation
);
const isShouldUpdate = createSelector([selector], (state) => state.isShouldUpdate);
const internalDifferentiationLock = createSelector(
  [selector],
  (state) => state.internalDifferentiationLock
);

const updateInternalDifferentiationLock = createSelector(
  [selector],
  (state) => state.updateInternalDifferentiationLock
);

export const testingResultSelectors = {
  appliedTestProcedures,
  appliedTestProceduresLock,
  internalDifferentiation,
  isShouldUpdate,
  internalDifferentiationLock,
  updateInternalDifferentiationLock,
};
