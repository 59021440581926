import React from 'react';

import { IIcon } from '@/types/icon';

export const AsterikIcon: React.FC<IIcon> = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_656_706)'>
      <path
        d='M11.9999 18H5.99986V14.325L3.10936 16.125L0.00585938 11.1412L3.44461 9L0.00585938 6.85875L3.10936 1.875L5.99986 3.675V0H11.9999V3.675L14.8904 1.875L17.9939 6.861L14.5551 9L17.9939 11.1412L14.8904 16.125L11.9999 14.325V18ZM7.49986 16.5H10.4999V11.625L14.4096 14.0602L15.9284 11.625L11.7179 9L15.9284 6.375L14.4096 3.939L10.4999 6.375V1.5H7.49986V6.375L3.59011 3.939L2.07136 6.375L6.28186 9L2.07136 11.625L3.59011 14.064L7.49986 11.625V16.5Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_656_706'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
