import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isBefore, parse } from 'date-fns';

import { Button } from '@/components/Button/Button';
import { useActivityPlaningSelector } from '@/components/functions/ActivityPlanning/FullFunction/ActivityPlanningContext/ActivityPlanningContext';
import { appointmentDetailsSchemaBuilder } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/hooks/appointmentDetailsValidator';
import { IAppointmentDetailsForm } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/hooks/useAppointmentDetailsForm';
import { FRONTEND_DATE_FORMAT, FRONTEND_TIME_FORMAT } from '@/core/constants/dateFormat';
import useRequiredFieldsWatcher from '@/core/hooks/useRequiredFieldsWatcher';
import { useAppDispatch } from '@/core/redux/hooks';
import { IAppointmentDetails } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';
import { activityPlaningAppointmentDetailsModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';

interface IAppointmentDetailsFooter {
  appointmentDetails: IAppointmentDetails | null;
  handleCreateAppointment: () => void;
  appointmentContext: 'create' | 'edit';
  setAppointmentContext: (newContext: 'create' | 'edit') => void;
  form: UseFormReturn<IAppointmentDetailsForm, any, undefined>;
}

export const AppointmentDetailsFooter: React.FC<IAppointmentDetailsFooter> = ({
  appointmentDetails,
  handleCreateAppointment,
  appointmentContext,
  setAppointmentContext,
  form,
}) => {
  const { t: appointmentDetailTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentDetails.footer',
  });

  const [selectedMeasureID] = useActivityPlaningSelector((store) => store.selectedMeasureID);
  const [selectedAppointmentID] = useActivityPlaningSelector(
    (store) => store.selectedAppointmentID
  );
  const [, setIsActionBlocked] = useActivityPlaningSelector((store) => store.isActionsBlocked);

  const dispatch = useAppDispatch();

  let isCompletable = false;
  const isDirty = form.formState.isDirty;

  const isRequiredFieldsFilled = useRequiredFieldsWatcher(
    appointmentDetailsSchemaBuilder(appointmentDetailTranslations),
    form.watch()
  );

  if (appointmentDetails && appointmentDetails.appointmentEndDate) {
    const today = new Date();

    const parsedTime = appointmentDetails.appointmentEndTime
      ? parse(
          `${appointmentDetails.appointmentEndDate} ${appointmentDetails.appointmentEndTime}`,
          `${FRONTEND_DATE_FORMAT} ${FRONTEND_TIME_FORMAT}`,
          new Date()
        )
      : parse(appointmentDetails?.appointmentEndDate, FRONTEND_DATE_FORMAT, new Date());

    isCompletable = isBefore(parsedTime, today);
  }

  const openCompleteAppointmentModal = () => {
    if (!selectedMeasureID || !selectedAppointmentID) {
      return;
    }

    dispatch(
      activityPlaningAppointmentDetailsModalsActions.openCompleteAppointmentModal({
        appointmentID: selectedAppointmentID,
        measureID: selectedMeasureID,
        endTime: form.getValues('endTime'),
        startDate: form.getValues('startDate'),
        startTime: form.getValues('startTime'),
      })
    );
  };

  const handleCancelCreate = () => {
    setAppointmentContext('edit');
    setIsActionBlocked({ isActionsBlocked: false });
  };

  const handleReset = () => {
    form.reset();
  };

  return (
    <div className={'flex flex-row gap-2 justify-between'}>
      {appointmentContext === 'edit' ? (
        <>
          <div className={'flex flex-row gap-2'}>
            <Button className={'h-auto'} onClick={handleCreateAppointment}>
              {appointmentDetailTranslations('buttons.newAppointment.label')}
            </Button>
          </div>
          <div className={'flex flex-row gap-2'}>
            {isCompletable && (
              <Button className={'h-auto'} onClick={openCompleteAppointmentModal}>
                {appointmentDetailTranslations('buttons.completeAppointment.label')}
              </Button>
            )}
            {isDirty && (
              <div className={'flex flex-row gap-2'}>
                <Button
                  type={'submit'}
                  buttonVariant={isRequiredFieldsFilled ? 'Default' : 'Disabled'}
                >
                  {appointmentDetailTranslations('buttons.save.label')}
                </Button>
                <Button onClick={handleReset}>
                  {appointmentDetailTranslations('buttons.cancel.label')}
                </Button>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={'flex flex-row gap-2'}>
          <Button type={'submit'} buttonVariant={isRequiredFieldsFilled ? 'Default' : 'Disabled'}>
            {appointmentDetailTranslations('buttons.save.label')}
          </Button>
          <Button onClick={handleCancelCreate} buttonVariant={'Secondary'}>
            {appointmentDetailTranslations('buttons.cancel.label')}
          </Button>
        </div>
      )}
    </div>
  );
};
