import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { SpecialistGeneralData } from '@/components/functions/SpecialistGeneralData/SpecialistGeneralData';
import { Typography } from '@/components/Typography/Typography';

import styles from './styles.module.scss';

export const SpecialistFunctionContainer: React.FC = () => {
  const {t: specialistsTranslations} = useTranslation('specialists')

  return (
    <div className={twMerge('overflow-y-hidden w-full flex flex-col', styles.container)}>
      <div className='flex flex-col justify-between ms-7 pr-7 overflow-hidden'>
        <Typography type='H2'>{specialistsTranslations('functions.title.label')}</Typography>
        <SpecialistGeneralData />
      </div>
      <div></div>
    </div>
  );
};
