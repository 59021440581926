import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.functions.developmentReport.developmentReportGoalsAndActivity;

const developmentReportGoalsAndActivity = createSelector(
  [selector],
  (state) => state.developmentReportGoalsAndActivity
);
const developmentReportGoalsAndActivityLock = createSelector(
  [selector],
  (state) => state.developmentReportGoalsAndActivityLock
);
const importedDevelopmentReportGoalsAndActivity = createSelector(
  [selector],
  (state) => state.importedDevelopmentReportGoalsAndActivity
);
const importedDevelopmentReportGoalsAndActivityLock = createSelector(
  [selector],
  (state) => state.importedDevelopmentReportGoalsAndActivityLock
);

export const developmentReportGoalsAndActivitySelectors = {
  developmentReportGoalsAndActivity,
  developmentReportGoalsAndActivityLock,
  importedDevelopmentReportGoalsAndActivity,
  importedDevelopmentReportGoalsAndActivityLock,
};
