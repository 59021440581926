import React from 'react';

import { Button } from '@/components/Button/Button';
import { EyeIcon } from '@/components/Icons/EyeIcon';
import { colorTypes } from '@/styles/types';
import { IIcon } from '@/types/icon';

interface IEyeHider extends IIcon {
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EyeHider: React.FC<IEyeHider> = ({ isShow, setIsShow, className }) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsShow((prev) => !prev);
  };

  return (
    <Button buttonStyle={'Icon'} buttonVariant={'Icon'} className={className} onClick={handleClick}>
      <EyeIcon className={isShow ? `text-${colorTypes.Blue}` : `text-${colorTypes.Secondary}`} />
    </Button>
  );
};
