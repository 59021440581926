import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../../types';

const selector = (state: IRootState) => state.modals.commonModals;
const addPersonModal = createSelector([selector], (state) => state.addPersonModal.isRender);
const chooseFunctionTypeModal = createSelector(
  [selector],
  (state) => state.chooseFunctionTypeModal
);

export const commonModalsSelectors = {
  addPersonModal,
  chooseFunctionTypeModal,
};
