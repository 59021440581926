import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  codesICDActions,
  ICodeICD,
} from '@/core/redux/slices/functions/diagnostics/codesICD/codesICDSlice';
import { codesICDSelectors } from '@/core/redux/slices/functions/diagnostics/codesICD/selectors';
import { diagnosticsModalsActions } from '@/core/redux/slices/modalsSlice/functions/diagnostics/diagnosticsModalsSlice';

export interface ICodesICD {
  personID?: number | null;
}

export const CodesICD: React.FC<ICodesICD> = ({ personID }) => {
  const { t: codesICDTranslations } = useTranslation('codesICD');
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const codesICD = useAppSelector(codesICDSelectors.codesICDData);
  const dispatch = useAppDispatch();

  const handleAddCodes = () => {
    if (!personID) {
      return;
    }

    dispatch(
      diagnosticsModalsActions.openAddICDCodesModal({
        personID: personID,
        selectedPage: selectedPage - 1,
      })
    );
  };

  useEffect(() => {
    if (!personID) {
      return;
    }

    dispatch(
      codesICDActions.fetchCodesICD({
        personID: personID,
        limit: 0,
        page: selectedPage - 1,
      })
    );
  }, [dispatch, personID, selectedPage]);

  return (
    <div className='h-full flex flex-col overflow-hidden'>
      <div className='flex-1 overflow-y-auto'>
        {codesICD.map((code: ICodeICD) => (
          <div key={code.id} className='flex border-b'>
            <div className='w-1/3 py-3'>{code.code}</div>
            <div className='w-2/3 py-3'>{code.description}</div>
          </div>
        ))}
      </div>
      <div className='mt-5 flex justify-start w-full'>
        <Button onClick={handleAddCodes}>{codesICDTranslations('buttons.add.label')}</Button>
      </div>
    </div>
  );
};
