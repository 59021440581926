import React from 'react';

export const MockWidget: React.FC = () => (
  <div>
    Mock
    <br />
    Mock
    <br />
    Mock
    <br />
    Mock
    <br />
    Mock
  </div>
);
