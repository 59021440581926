import { colorTypes, textTypes } from '@/styles/types';

export type TextAreaVariants = keyof typeof textAreaVariants;

const textAreaVariants = {
  Default: {
    base: `bg-${colorTypes.Fields} text-${colorTypes.Primary} placeholder:text-${colorTypes.Disabled} ${textTypes['16-R']} border border-${colorTypes.Fields} rounded-default px-5 py-[10px]  outline-none w-full h-auto min-h-[55px]`,
    hovered: '',
    active: '',
    focused: `focus:border-${colorTypes.Blue}`,
    disabled: `disabled:text-${colorTypes.Disabled}`,
    readOnly: `read-only:text-${colorTypes.Disabled}`,
  },
  EditTextPopUp: {
    base: `text-${colorTypes.Primary} ${textTypes['14-R']} p-3 outline-none w-full`,
    hovered: '',
    active: '',
    focused: ``,
    disabled: ``,
    readOnly: ``,
  },
};

export function getTextAreaVariant(key: TextAreaVariants): string {
  const { base, hovered, active, focused, disabled, readOnly } = textAreaVariants[key];

  return [base, hovered, active, focused, disabled, readOnly].join(' ');
}
