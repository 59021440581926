import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.activityPlanning.measuresList;

const measuresList = createSelector([selector], (state) => state.measuresList);
const measuresListLock = createSelector([selector], (state) => state.measuresListLock);
const personLocation = createSelector([selector], (state) => state.personLocation);
const personLocationLock = createSelector([selector], (state) => state.personLocationLock);

export const activityPlaningMeasuresListSelectors = {
  measuresList,
  measuresListLock,
  personLocation,
  personLocationLock,
};
