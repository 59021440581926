import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const icon = {
  hidden: { pathLength: 0, x: -10, opacity: 0, transition: { duration: 0.15 } },
  hiddenIncrease: { pathLength: 0, x: 10, opacity: 0, transition: { duration: 0.15 } },
  visible: { x: 0, pathLength: 1, opacity: 1, transition: { duration: 0.15, delay: 0.4 } },
};

const propsRollUpRight = { initial: icon.hidden, animate: icon.visible, exit: icon.hidden };

const propsRollUpLeft = {
  initial: icon.hiddenIncrease,
  animate: icon.visible,
  exit: icon.hiddenIncrease,
};

export const RollUpIcon = ({ isOpen = false }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <AnimatePresence initial={false}>
      {isOpen ? (
        <motion.path
          {...propsRollUpRight}
          key='first0'
          d='M23 6H15'
          stroke='#1E1A1B'
          strokeWidth='2'
        />
      ) : (
        <motion.path
          {...propsRollUpLeft}
          key='first1'
          d='M2 6L10 6'
          stroke='#1E1A1B'
          strokeWidth='2'
        />
      )}
      {isOpen ? (
        <motion.path
          {...propsRollUpLeft}
          key='second0'
          d='M1.29289 11.2929C0.902369 11.6834 0.902369 12.3166 1.29289 12.7071L7.65685 19.0711C8.04738 19.4616 8.68054 19.4616 9.07107 19.0711C9.46159 18.6805 9.46159 18.0474 9.07107 17.6569L3.41421 12L9.07107 6.34315C9.46159 5.95262 9.46159 5.31946 9.07107 4.92893C8.68054 4.53841 8.04738 4.53841 7.65685 4.92893L1.29289 11.2929ZM23 11L2 11L2 13L23 13L23 11Z'
          fill='#1E1A1B'
        />
      ) : (
        <motion.path
          {...propsRollUpRight}
          key='second1'
          d='M23.7071 12.7071C24.0976 12.3166 24.0976 11.6834 23.7071 11.2929L17.3431 4.92893C16.9526 4.53841 16.3195 4.53841 15.9289 4.92893C15.5384 5.31946 15.5384 5.95262 15.9289 6.34314L21.5858 12L15.9289 17.6569C15.5384 18.0474 15.5384 18.6805 15.9289 19.0711C16.3195 19.4616 16.9526 19.4616 17.3431 19.0711L23.7071 12.7071ZM2 13L23 13L23 11L2 11L2 13Z'
          fill='#1E1A1B'
        />
      )}
      {isOpen ? (
        <motion.path
          {...propsRollUpRight}
          key='third0'
          d='M23 18H15'
          stroke='#1E1A1B'
          strokeWidth='2'
        />
      ) : (
        <motion.path
          {...propsRollUpLeft}
          key='third1'
          d='M2 18L10 18'
          stroke='#1E1A1B'
          strokeWidth='2'
        />
      )}
    </AnimatePresence>
  </svg>
);
