import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { InternshipDocumentationItem } from '@/components/Modals/Modals/functions/integrationPlanModals/InternshipDocumentationModal/InternshipDocumentationContent/InternshipDocumentationItem/InternshipDocumentationItem';
import { TableHeader } from '@/components/Modals/Modals/functions/integrationPlanModals/InternshipDocumentationModal/InternshipDocumentationContent/TableHeader/TableHeader';
import { Typography } from '@/components/Typography/Typography';

interface IQualificationObjectivesContent {
  onExit: () => void;
}

const TEST_DATA = [
  {
    name: 'test_1',
    place: 'place 1',
    dateFrom: '2023-10-10',
    dateTo: '2023-10-11',
    fieldName: 'test_1',
    key: 1,
  },
  {
    name: 'test_2',
    place: 'place 2',
    dateFrom: '2023-10-10',
    dateTo: '2023-10-11',
    fieldName: 'test_2',
    key: 2,
  },
  {
    name: 'test_3',
    place: 'place 3',
    dateFrom: '2023-10-10',
    dateTo: '2023-10-11',
    fieldName: 'test_3',
    key: 3,
  },
];

export const InternshipDocumentationContent: React.FC<IQualificationObjectivesContent> = ({
  onExit,
}) => {
  const { t: internshipDocumentationTranslations } = useTranslation('internshipDocumentationModal');

  const form = useForm();

  const handleExit = () => {
    onExit();
  };

  return (
    <div className={'flex flex-col gap-4 max-w-[1000px]'}>
      <Typography type={'H3'}>{internshipDocumentationTranslations('title.label')}</Typography>
      <div>
        <TableHeader />
        <FormProvider {...form}>
          <div className={'flex flex-col'}>
            {TEST_DATA.map((objective) => (
              <InternshipDocumentationItem {...objective} />
            ))}
          </div>
        </FormProvider>
      </div>
      <div className={'self-end w-auto flex flex-row gap-3'}>
        <Button onClick={handleExit}>
          {internshipDocumentationTranslations('buttons.closeSection.label')}
        </Button>
      </div>
    </div>
  );
};
