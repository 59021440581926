import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const TableHeader: React.FC = () => {
  const { t: qualificationObjectivesTranslations } = useTranslation('qualificationDocumentation', {
    keyPrefix: 'qualificationObjectives.tableHeader',
  });

  return (
    <div className={'grid grid-cols-16 gap-3 items-center px-2'}>
      <div className={'col-span-11 grid grid-cols-12 gap-3 items-center'}>
        <Typography color={'Secondary'} type={'14-M'} className={'col-span-6'}>
          {qualificationObjectivesTranslations('qualificationGoal.label')}
        </Typography>
        <Typography color={'Secondary'} type={'14-M'} className={'col-span-3 justify-self-center'}>
          {qualificationObjectivesTranslations('agreementDate.label')}
        </Typography>
        <div className={'col-span-3 grid grid-cols-3 items-center justify-items-center'}>
          <Typography color={'Secondary'} type={'14-M'} className={'justify-self-center'}>
            {qualificationObjectivesTranslations('yes.label')}
          </Typography>
          <Typography color={'Secondary'} type={'14-M'} className={'justify-self-center'}>
            {qualificationObjectivesTranslations('partial.label')}
          </Typography>
          <Typography color={'Secondary'} type={'14-M'} className={'justify-self-center'}>
            {qualificationObjectivesTranslations('no.label')}
          </Typography>
        </div>
      </div>
      <Typography color={'Secondary'} type={'14-M'} className={'col-span-5'}>
        {qualificationObjectivesTranslations('comments.label')}
      </Typography>
    </div>
  );
};
