import React from 'react';

import { IQuestionaryImage } from '@/types/questionaryImage';

export const LeftImage: React.FC<IQuestionaryImage> = ({ className }) => (
  <img
    alt=''
    src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAUCAYAAAC07qxWAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB9sHBAciNuQoyDcAAAGQSURBVCjPdZK9ahtBFIW/MzKG1NIGAikDu+DVYzgQNqTUoC1d6wn8HEm6SN2a1TOkTRsXmwWpMe4MQQ4uQtzppNhdyQFnipmB+825P3PEM6vMIhiqbU2ZxdfGl+GfYH96gNL4Cvgk630YgtWmZp7NALjqlBLEEvMBETQoztOIRP8gJoKVTaGOuAnHtKba1JTpLAGv4ADtAYV5OsM2V9s18zQmSCuswrhLZQsgdDWtmaezRGIFFMgI2QC9rADKNL60WAoKAGNjJAmMkW9DmcY3wBdw0fdlgQalTkqcWHwWnPfQ3hD6KAbU70HwFfgthknZYDSIIcCMml37bTrOfyLOMadClnRIig3iYQTQ3LfX08nZHegt4tTdTLreEUgPocyiAKrNeolY2DwCwWjflweYAPYRrpeSF0KPQNDhg48dUKYzqu16cNAF8NHmhWCPfDsawOa+pcwiza6l2bXX03F+B34n6QT0Kzx1T7WpD76stvUStMD+YxjzH4fryf2izOL30XPgdHJGPsn1Yyhjkt/8BYSMqywfHVUbAAAAAElFTkSuQmCC'
    className={className}
  />
);
