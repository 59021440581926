import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IModal {
  isRender: boolean;
}

export interface IChooseFunctionTypeModal extends IModal {
  payload: {
    id: string | null;
  };
}

export interface ICommonModalsState {
  addPersonModal: IModal;
  chooseFunctionTypeModal: IChooseFunctionTypeModal;
}

const initialState: ICommonModalsState = {
  addPersonModal: {
    isRender: false,
  },
  chooseFunctionTypeModal: {
    isRender: false,
    payload: {
      id: null,
    },
  },
};

export const commonModalsSlice = createSlice({
  name: 'commonModals',
  initialState,
  reducers: {
    openAddPersonModal: (state) => {
      return {
        ...state,
        addPersonModal: {
          isRender: true,
        },
      };
    },
    closeAddPersonModal: (state) => {
      return {
        ...state,
        addPersonModal: {
          isRender: false,
        },
      };
    },
    openChooseFunctionTypeModal: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        chooseFunctionTypeModal: {
          isRender: true,
          payload: {
            id: action.payload,
          },
        },
      };
    },
    closeChooseFunctionTypeModal: (state) => {
      return {
        ...state,
        chooseFunctionTypeModal: {
          isRender: false,
          payload: {
            id: null,
          },
        },
      };
    },
  },
});

export const commonModalsReducers = commonModalsSlice.reducer;
export const commonModalsActions = commonModalsSlice.actions;
