import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import type oas from '@/services/rest/base/openapi';
import { Camelize } from '@/types/camelize';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  IAvailableUnitsFetchPayload,
  IUnitAddPayload,
  IUnitDeletePayload,
  IUsersByOrganizationUnitFetchPayload,
  specialistOrganizationUnitsActions,
} from './organizationUnitsSlice';

// types

type OrganizationUnitsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/organization_unit/user/available',
  'get',
  '200'
>;
type AddUnitRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/organization_unit/user', 'post'>;
type DeleteUnitRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/organization_unit/user',
  'delete'
>;
type UsersByDepartmentResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/user/organization_unit',
  'get',
  '200'
>;

// sagas

function* fetchAvailableUnits(
  action: PayloadAction<IAvailableUnitsFetchPayload>
): Generator<any, void, OrganizationUnitsResponse> {
  const { userID, isOnlyAdded } = action.payload;

  yield put(specialistOrganizationUnitsActions.setAvailableUnitsLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/organization_unit/user/available', 'get', {
      query: {
        user_id: userID,
        only_added: isOnlyAdded,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<OrganizationUnitsResponse> = toCamelCase(response);
    const availableUnits = camelizeResponse.units;

    yield put(specialistOrganizationUnitsActions.setAvailableUnits(availableUnits));
    yield put(specialistOrganizationUnitsActions.setAvailableUnitsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on available user departments fetching');
    yield put(specialistOrganizationUnitsActions.setAvailableUnitsLock(LoadingStatus.ERROR));
  }
}

function* addUserUnit(action: PayloadAction<IUnitAddPayload>): Generator<any, void, any> {
  const { userID, organizationUnitID } = action.payload;

  yield put(specialistOrganizationUnitsActions.setUpdateUserUnitsLock(LoadingStatus.LOADING));

  try {
    const request: AddUnitRequest = {
      json: {
        user_id: userID,
        organization_unit_id: organizationUnitID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/organization_unit/user',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    yield put(specialistOrganizationUnitsActions.setUpdateUserUnitsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on add organization unit', error);
    yield put(specialistOrganizationUnitsActions.setUpdateUserUnitsLock(LoadingStatus.ERROR));
  }
}

function* deleteUserUnit(action: PayloadAction<IUnitDeletePayload>): Generator<any, void, any> {
  const { userID, organizationUnitID } = action.payload;

  yield put(specialistOrganizationUnitsActions.setUpdateUserUnitsLock(LoadingStatus.LOADING));

  try {
    const request: DeleteUnitRequest = {
      json: {
        user_id: userID,
        organization_unit_id: organizationUnitID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/organization_unit/user',
      'delete',
      {
        ...request,
      },
      null,
      true
    );
    yield put(specialistOrganizationUnitsActions.setUpdateUserUnitsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on delete organization unit', error);
    yield put(specialistOrganizationUnitsActions.setUpdateUserUnitsLock(LoadingStatus.ERROR));
  }
}

function* fetchOrganizationUnitUsers(
  action: PayloadAction<IUsersByOrganizationUnitFetchPayload>
): Generator<any, void, UsersByDepartmentResponse> {
  const { organizationUnitID, isAdded } = action.payload;

  yield put(specialistOrganizationUnitsActions.setOrganizationUnitUsersLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/user/organization_unit', 'get', {
      query: {
        organization_unit_id: organizationUnitID,
        is_added: isAdded,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<UsersByDepartmentResponse> = toCamelCase(response);
    const organizationUnitUsers = camelizeResponse.users;

    yield put(specialistOrganizationUnitsActions.setOrganizationUnitUsers(organizationUnitUsers));
    yield put(specialistOrganizationUnitsActions.setOrganizationUnitUsersLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on fetch organization unit users');
    yield put(specialistOrganizationUnitsActions.setOrganizationUnitUsersLock(LoadingStatus.ERROR));
  }
}

export const specialistOrganizationalUnitsSagas = [
  takeLatest(specialistOrganizationUnitsActions.fetchAvailableUnits, fetchAvailableUnits),
  takeLatest(specialistOrganizationUnitsActions.addUserUnit, addUserUnit),
  takeLatest(specialistOrganizationUnitsActions.deleteUserUnit, deleteUserUnit),
  takeLatest(specialistOrganizationUnitsActions.fetchOrganizationUnitUsers, fetchOrganizationUnitUsers),
];
