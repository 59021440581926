import createStoreContext from '@/components/Context/ReduceModelStoreContext';
import {
  IAdministrationChecklist,
  IAdministrationChecklistCheckpoint,
  IAdministrationChecklistGroup,
} from '@/core/redux/slices/administrationFunctions/checkList/slice';

export interface IAdministrationChecklistContext {
  selectedChecklistGroup: IAdministrationChecklistGroup | null;
  selectedChecklist: IAdministrationChecklist | null;
  selectedCheckpoint: IAdministrationChecklistCheckpoint | null;
}

export const {
  useStoreSelector: useAdministrationChecklistSelector,
  StoreProvider: AdministrationChecklistProvider,
} = createStoreContext<IAdministrationChecklistContext>();
