import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  IAvailableItemsICF,
  IAvailableItemsICFFetchPayload,
  IItemICF,
  IItemsICFFetchPayload,
  IItemsICFUpdatePayload,
  itemsICFActions,
  IUpdateItemICF,
} from './itemsICFSlice';

type ItemsICFResponse = OASOutput<NormalizeOAS<typeof oas>, '/diagnostics/icf_items', 'get', '200'>;
type AvailableItemsICFResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/diagnostics/icf_items/available',
  'get',
  '200'
>;
type ItemsICFRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/diagnostics/icf_items/add_or_delete_icf_items',
  'post'
>;

const mapUpdatedItems = (items: IUpdateItemICF[]) =>
  items.map((item) => ({
    id: item.id,
    is_selected: item.isSelected,
  }));

function* fetchItemsICF(
  action: PayloadAction<IItemsICFFetchPayload>
): Generator<any, void, ItemsICFResponse> {
  yield put(itemsICFActions.setItemsICFStatus(LoadingStatus.LOADING));
  const { payload } = action;

  try {
    const response = yield call(restCall, '/diagnostics/icf_items', 'get', {
      query: {
        person_id: payload.personID,
        limit: payload.limit,
        offset: payload.offset,
        page: payload.page,
      },
      ...authAdd(),
    });

    const itemsICF: IItemICF[] = response.data || [];
    const totalCount: number = response.total;

    yield put(itemsICFActions.setItemsICFData(itemsICF));
    yield put(itemsICFActions.setItemsICFToatalCount(totalCount));
    yield put(itemsICFActions.setItemsICFStatus(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on ICF items fetching');
    yield put(itemsICFActions.setItemsICFStatus(LoadingStatus.ERROR));
  }
}

function* fetchAvailableICFItems(
  action: PayloadAction<IAvailableItemsICFFetchPayload>
): Generator<any, void, AvailableItemsICFResponse> {
  yield put(itemsICFActions.setAvailableICFItemsStatus(LoadingStatus.LOADING));
  const { payload } = action;

  try {
    const response = yield call(restCall, '/diagnostics/icf_items/available', 'get', {
      query: {
        person_id: payload.personID,
        limit: payload.limit,
        offset: payload.offset,
        page: payload.page,
        is_selected: payload.isSelected,
        search: payload.search,
      },
      ...authAdd(),
    });

    const availableItemsICF: IAvailableItemsICF[] =
      response.data.map((code) => ({
        code: code.code,
        description: code.description,
        id: code.id,
        isSelected: code.is_selected,
        name: code.name,
        text: code.text,
      })) || [];

    const availableTotalCount: number = response.total;

    yield put(itemsICFActions.setAvailableICFItemsData(availableItemsICF));
    yield put(itemsICFActions.setAvailableICFItemsTotalCount(availableTotalCount));
    yield put(itemsICFActions.setAvailableICFItemsStatus(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on ICF items fetching');
    yield put(itemsICFActions.setAvailableICFItemsStatus(LoadingStatus.ERROR));
  }
}

function* updateItemsICF(action: PayloadAction<IItemsICFUpdatePayload>): Generator<any, void, any> {
  const { updatedItems, personID } = action.payload;

  try {
    const request: ItemsICFRequest = {
      query: { person_id: personID },
      json: mapUpdatedItems(updatedItems),
      ...authAdd(),
    };

    yield call(
      restCall,
      '/diagnostics/icf_items/add_or_delete_icf_items',
      'post',
      {
        ...request,
      },
      null,
      true
    );
  } catch (error) {
    console.log('Error on ICF items data updating', error);
  }
}

export const itemsICFSagas = [
  takeLatest(itemsICFActions.fetchItemsICF, fetchItemsICF),
  takeLatest(itemsICFActions.fetchAvailableICFItems, fetchAvailableICFItems),
  takeLatest(itemsICFActions.updateItemsICF, updateItemsICF),
];
