import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets/typings';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { qualificationDocumentationStatusEnum } from '@/core/enums/qualificationDocumentationStatusEnum';
import { integrationPlanActions } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import {
  IInternship,
  IQualificationDocumentation,
  IQualificationDocumentationFetchPayload,
  IQualificationDocumentationItem,
  IQualificationDocumentationUpdatePayload,
  IQualificationObjectiveRemovePayload,
  qualificationDocumentationActions,
} from '@/core/redux/slices/functions/integrationPlan/qualificationDocumentation/qualificationDocumentationSlice';
import { qualificationDocumentationSelectors } from '@/core/redux/slices/functions/integrationPlan/qualificationDocumentation/selectors';
import { IQualificationObjectivesModalItem } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { getSelectedOption } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type QualificationDocumentationResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/qualification_documentation',
  'get',
  '200'
>;
type QualificationDocumentationRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/integration_plan/qualification_documentation',
  'post'
>;

interface IQualificationObjectivesDocumentationsItemRequest {
  qualification_objective_id: number;
  yes_flag: boolean;
  partially_flag: boolean;
  no_flag: boolean;
  agreement_timestamp: string | null;
  comment: string | null;
  extension: string | null;
}

interface IQualificationDocumentationsItemResponse {
  qualification_objective_id: number;
  yes_flag: boolean;
  partially_flag: boolean;
  no_flag: boolean;
  qualification_objective_name: string;
  is_qualification_objective_fixed: boolean;
  comment?: string | null;
  extension?: string | null;
  agreement_timestamp?: string | null;
}

interface IInternshipItemResponse {
  order_number: number;
  internship_name?: string | null;
  internship_location?: string | null;
  internship_period?: string | null;
}

interface IInternshipItemRequest {
  order_number: number;
  internship_name: string | null;
  internship_location: string | null;
  internship_period: string | null;
}

const mapQualificationsDocumentationResponse = (
  qualificationsDocumentations: IQualificationDocumentationsItemResponse[] | null
): Record<string, IQualificationDocumentationItem> => {
  if (!qualificationsDocumentations) {
    return {};
  }

  return qualificationsDocumentations.reduce<Record<string, IQualificationDocumentationItem>>(
    (accum, documentation) => {
      const statusOptions = {
        [qualificationDocumentationStatusEnum.isStarted]: documentation.yes_flag,
        [qualificationDocumentationStatusEnum.isAchieved]: documentation.partially_flag,
        [qualificationDocumentationStatusEnum.isNotStarted]: documentation.no_flag,
      };

      let formattedDate = null;

      if (documentation.agreement_timestamp) {
        formattedDate = toClientDateInput(documentation.agreement_timestamp);
      }

      const newItem: IQualificationDocumentationItem = {
        qualification_objective_id: documentation.qualification_objective_id,
        isQualificationObjectiveFixed: documentation.is_qualification_objective_fixed,
        qualificationObjectiveName: documentation.qualification_objective_name,
        status: getSelectedOption(statusOptions),
        agreementTimestamp: formattedDate,
        qualificationObjectiveComment: documentation.comment,
        qualificationObjectiveExtension: documentation.extension,
      };

      return {
        ...accum,
        [documentation.qualification_objective_id]: newItem,
      };
    },
    {}
  );
};

const mapInternshipsResponse = (
  internships: IInternshipItemResponse[]
): Record<string, IInternship> => {
  if (!internships) {
    return {};
  }

  return internships.reduce<Record<string, IInternship>>((accum, internship) => {
    const newItem: IInternship = {
      orderNumber: internship.order_number,
      internshipPeriod: internship.internship_period,
      internshipName: internship.internship_name,
      internshipLocation: internship.internship_location,
    };

    return {
      ...accum,
      [internship.order_number]: newItem,
    };
  }, {});
};

const mapQualificationsDocumentationRequest = (
  qualificationDocumentations?: Record<string, IQualificationDocumentationItem>
): IQualificationObjectivesDocumentationsItemRequest[] => {
  if (!qualificationDocumentations) {
    return [];
  }

  const mappedQualificationsDocumentation: IQualificationObjectivesDocumentationsItemRequest[] = [];

  const qualificationsKeys = Object.keys(qualificationDocumentations);

  qualificationsKeys.forEach((qualificationKey) => {
    let formattedDate = null;

    const agreementTimestamp = qualificationDocumentations[qualificationKey].agreementTimestamp;

    if (agreementTimestamp) {
      formattedDate = toBackendDate(agreementTimestamp);
    }

    const newQualificationItem: IQualificationObjectivesDocumentationsItemRequest = {
      qualification_objective_id:
        qualificationDocumentations[qualificationKey].qualification_objective_id,
      agreement_timestamp: formattedDate,
      yes_flag:
        qualificationDocumentations[qualificationKey].status ===
        qualificationDocumentationStatusEnum.isStarted,
      partially_flag:
        qualificationDocumentations[qualificationKey].status ===
        qualificationDocumentationStatusEnum.isAchieved,
      no_flag:
        qualificationDocumentations[qualificationKey].status ===
        qualificationDocumentationStatusEnum.isNotStarted,
      extension:
        qualificationDocumentations[qualificationKey].qualificationObjectiveExtension ?? null,
      comment: qualificationDocumentations[qualificationKey].qualificationObjectiveComment ?? null,
    };

    mappedQualificationsDocumentation.push(newQualificationItem);
  });

  return mappedQualificationsDocumentation;
};

const mapInternshipRequest = (
  internships?: Record<string, IInternship>
): IInternshipItemRequest[] => {
  if (!internships) {
    return [];
  }

  const mappedInternships: IInternshipItemRequest[] = [];

  const internshipsKeys = Object.keys(internships);

  internshipsKeys.forEach((internshipKey) => {
    const newInternship: IInternshipItemRequest = {
      internship_location: internships[internshipKey].internshipLocation ?? null,
      internship_period: internships[internshipKey].internshipPeriod ?? null,
      internship_name: internships[internshipKey].internshipName ?? null,
      order_number: internships[internshipKey].orderNumber,
    };

    mappedInternships.push(newInternship);
  });

  return mappedInternships;
};

function* fetchQualificationDocumentation(
  action: PayloadAction<IQualificationDocumentationFetchPayload>
): Generator<any, void, QualificationDocumentationResponse> {
  yield put(
    qualificationDocumentationActions.setQualificationDocumentationLock(LoadingStatus.LOADING)
  );

  const { integrationPlanID } = action.payload;

  try {
    const response = yield call(restCall, '/integration_plan/qualification_documentation', 'get', {
      query: { integration_plan_id: integrationPlanID },
      ...authAdd(),
    });

    const qualificationDocumentation: IQualificationDocumentation = {
      qualificationsDocumentations: mapQualificationsDocumentationResponse(
        response.qualification_objectives_documentations
      ),
      internships: mapInternshipsResponse(response.internships),
    };

    yield put(
      qualificationDocumentationActions.setQualificationDocumentation(qualificationDocumentation)
    );

    yield put(
      qualificationDocumentationActions.setQualificationDocumentationLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on integration plan qualification documentation fetching');
    yield put(
      qualificationDocumentationActions.setQualificationDocumentationLock(LoadingStatus.ERROR)
    );
  }
}

function* updateQualificationDocumentation(
  action: PayloadAction<IQualificationDocumentationUpdatePayload>
): Generator<any, void, any> {
  const { integrationPlanID, personID, qualificationDocumentation } = action.payload;

  try {
    yield put(
      qualificationDocumentationActions.setUpdateQualificationDocumentationLock(
        LoadingStatus.LOADING
      )
    );

    const request: QualificationDocumentationRequest = {
      query: {
        person_id: personID,
        integration_plan_id: integrationPlanID,
      },
      json: {
        qualification_objectives_documentations: mapQualificationsDocumentationRequest(
          qualificationDocumentation?.qualificationsDocumentations
        ),
        internships: mapInternshipRequest(qualificationDocumentation?.internships),
      },
      ...authAdd(),
    };

    const updateResponse = yield call(
      restCall,
      '/integration_plan/qualification_documentation',
      'post',
      {
        ...request,
      }
    );

    yield put(
      integrationPlanActions.checkIntegrationPlanUpdate({
        integrationPlanID,
        updateResponse: updateResponse,
      })
    );

    yield put(
      qualificationDocumentationActions.setUpdateQualificationDocumentationLock(
        LoadingStatus.LOADED
      )
    );
  } catch (error) {
    console.log('Error on integration plan qualification documentation data updating');
    yield put(
      qualificationDocumentationActions.setUpdateQualificationDocumentationLock(LoadingStatus.ERROR)
    );
  }
}

function* removeQualificationObjective(
  action: PayloadAction<IQualificationObjectiveRemovePayload>
): Generator<any, void, any> {
  const { integrationPlanID, personID, qualificationObjectives, qualificationDocumentation } =
    action.payload;
  try {
    yield call(restCall, '/integration_plan/qualification_objective_documentation', 'delete', {
      query: {
        integration_plan_id: integrationPlanID,
        person_id: personID,
        qualification_objectives_ids: qualificationObjectives,
      },
    });

    yield put(
      qualificationDocumentationActions.updateQualificationDocumentation({
        integrationPlanID,
        personID,
        qualificationDocumentation,
      })
    );
  } catch (error) {
    console.log('Error on integration plan qualification objective removing');
  }
}

function* updateQualificationObjectives(
  action: PayloadAction<Record<string, IQualificationObjectivesModalItem>>
): Generator<any, void, any> {
  const qualificationObjectivePayload = action.payload;
  const qualificationObjectivesPayloadKeys = Object.keys(action.payload);

  const qualificationObjectives: Record<string, IQualificationDocumentationItem> = yield select(
    qualificationDocumentationSelectors.qualificationObjectives
  );
  const qualificationObjectivesKeys = Object.keys(qualificationObjectives);

  const newQualificationObjectives = qualificationObjectivesPayloadKeys.reduce<
    Record<string, IQualificationDocumentationItem>
  >((accum, qualificationObjectiveKey) => {
    if (
      !qualificationObjectivesKeys.includes(qualificationObjectiveKey) &&
      qualificationObjectivePayload[qualificationObjectiveKey].isSelected
    ) {
      return {
        ...accum,
        [qualificationObjectiveKey]: {
          qualification_objective_id: qualificationObjectivePayload[qualificationObjectiveKey].id,
          qualificationObjectiveName:
            qualificationObjectivePayload[qualificationObjectiveKey].qualificationObjectiveName ??
            '',
          isQualificationObjectiveFixed: false,
          status: '',
        },
      };
    }

    if (qualificationObjectivePayload[qualificationObjectiveKey].isSelected) {
      return {
        ...accum,
        [qualificationObjectiveKey]: qualificationObjectives[qualificationObjectiveKey],
      };
    }

    return accum;
  }, {});

  yield put(
    qualificationDocumentationActions.setQualificationObjectives(newQualificationObjectives)
  );
}

export const qualificationDocumentationSagas = [
  takeLatest(
    qualificationDocumentationActions.fetchQualificationDocumentation,
    fetchQualificationDocumentation
  ),
  takeLatest(
    qualificationDocumentationActions.updateQualificationDocumentation,
    updateQualificationDocumentation
  ),
  takeLatest(
    qualificationDocumentationActions.removeQualificationObjective,
    removeQualificationObjective
  ),
  takeLatest(
    qualificationDocumentationActions.updateQualificationObjectives,
    updateQualificationObjectives
  ),
];
