import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.workingTimeMobility.workingTime;

const workingTime = createSelector([selector], (state) => state.workingTime);
const workingTimeDaysList = createSelector([selector], (state) => state.workingTimeDaysList);
const workingTimeLock = createSelector([selector], (state) => state.workingTimeLock);
const updateWorkingTimeLock = createSelector([selector], (state) => state.updateWorkingTimeLock);
const updatedWorkingTime = createSelector([selector], (state) => state.updatedWorkingTime);

export const workingTimeSelectors = {
  workingTime,
  workingTimeDaysList,
  workingTimeLock,
  updateWorkingTimeLock,
  updatedWorkingTime,
};
