import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.simpleSelfAssesment;

const availableQuestionnaires = createSelector(
  [selector],
  (state) => state.availableQuestionnaires
);
const personQuestionnaires = createSelector([selector], (state) => state.personQuestionnaires);
const personAnswers = createSelector([selector], (state) => state.personAnswers);
const personQuestionnaire = createSelector([selector], (state) => state.personQuestionnaire);
const personQuestionnaireAnswers = createSelector(
  [selector],
  (state) => state.personQuestionnaireAnswers
);
const updatedPersonQuestionnaireID = createSelector(
  [selector],
  (state) => state.updatedPersonQuestionnaireID
);
const selectedPersonQuestionnaire = createSelector(
  [selector],
  (state) => state.viewDetails.selectedPersonQuestionnaire
);
const selectedPersonSimpleVersion = createSelector(
  [selector],
  (state) => state.viewDetails.selectedPersonSimpleVersion
);

export const simpleSelfAssesmentSelectors = {
  availableQuestionnaires,
  personQuestionnaires,
  personAnswers,
  personQuestionnaire,
  personQuestionnaireAnswers,
  updatedPersonQuestionnaireID,
  selectedPersonQuestionnaire,
  selectedPersonSimpleVersion,
};
