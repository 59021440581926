import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAppState {
  accessToken?: string | null;
  isAppInitializationInProgress?: boolean;
}

const initialState: IAppState = {
  accessToken: null,
  isAppInitializationInProgress: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppInitialization: (state, action: PayloadAction<IAppState | null>) => ({
      ...state,
    }),
    setAppInitializationSuccess: (state, action: PayloadAction<IAppState | null>) => ({
      ...state,
      isAppInitializationInProgress: action.payload?.isAppInitializationInProgress,
    }),
  },
});

export const appReducer = appSlice.reducer;
export const appActions = appSlice.actions;
