import React from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { Typography } from '@/components/Typography/Typography';
import { ModalCloseButtonPosition } from '@/core/enums/modalCloseButtonPositionEnum';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import styles from './styles.module.scss';

export interface IModal {
  onExit: () => void;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  containerClassName?: string;
  bgClassName?: string;
  closeButtonPosition?: ModalCloseButtonPosition;
  customCloseButtonPosition?: string;
  title?: string;
  layer?: 1 | 2 | 3 | 4;
}

export const Modal: React.FC<IModal> = ({
  onExit,
  children,
  className,
  containerClassName,
  bgClassName,
  customCloseButtonPosition,
  title,
  header,
  footer,
  closeButtonPosition = ModalCloseButtonPosition.TopRight,
  layer = 1,
}) => {
  return (
    <AnimatePresence>
      <motion.div
        className={clsx(styles.modalContainer, `fixed inset-0 z-${layer * 50}`)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <motion.div
          className={twMerge(
            'w-screen h-screen flex items-center justify-center',
            containerClassName
          )}
        >
          <div className='relative'>
            <div className={twMerge(`bg-${colorTypes.White} rounded-2xl`, bgClassName)}>
              {header && header}
              <div className={twMerge(`p-8`, className)}>
                <div className='flex flex-col h-full'>
                  {title && (
                    <Typography type='H2' className='block mb-5'>
                      {title}
                    </Typography>
                  )}
                  <div className='flex-grow overflow-auto'>{children}</div>
                </div>
              </div>
              {footer && (
                <div className='shadow-[0px_2px_10px_0px_rgba(110,123,148,0.20)]'>{footer}</div>
              )}
            </div>
            <div
              className={clsx(
                styles.closeButtonContainer,
                'absolute w-[40px] h-[40px] flex items-center justify-center',
                closeButtonPosition === ModalCloseButtonPosition.TopRight && '-right-[50px] top-0 ',
                closeButtonPosition === ModalCloseButtonPosition.TopLeft && '-left-[50px] top-0 ',
                closeButtonPosition === ModalCloseButtonPosition.Flexible &&
                  customCloseButtonPosition
              )}
            >
              <Button
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={clsx(styles.closeButton, 'flex items-center')}
                onClick={onExit}
              >
                <CrossIcon />
              </Button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
