import React from 'react';

import { IIcon } from '@/types/icon';

export const ExclamationIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='32'
    height='33'
    viewBox='0 0 32 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g clipPath='url(#clip0_5107_153899)'>
      <path
        d='M16 0.5C7.1625 0.5 0 7.6625 0 16.5C0 25.3375 7.1625 32.5 16 32.5C24.8375 32.5 32 25.3375 32 16.5C32 7.6625 24.8375 0.5 16 0.5ZM18.7625 24.5312C18.7625 26.0562 17.525 27.2938 16 27.2938C14.475 27.2938 13.2375 26.0562 13.2375 24.5312C13.2375 23.0063 14.475 21.7687 16 21.7687C17.525 21.7687 18.7625 23.0063 18.7625 24.5312ZM18.7625 17.7938C18.7063 19.275 17.4875 20.45 16 20.45C14.5125 20.45 13.2937 19.275 13.2375 17.7938L12.875 8.5875C12.8125 7.0625 14 5.775 15.5312 5.7125H15.6375H16.3687C17.8937 5.7125 19.1375 6.95 19.1375 8.48125V8.5875L18.7625 17.7938Z'
        fill='#DC1717'
      />
    </g>
    <defs>
      <clipPath id='clip0_5107_153899'>
        <rect width='32' height='32' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>
);
