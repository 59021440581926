import createStoreContext from '@/components/Context/ReduceModelStoreContext';
import { IPersonLocation } from '@/core/redux/slices/functions/activityPlanning/measuresList/slice';

export interface IActivityPlaningWizardContext {
  selectedMeasureID: number | null;
  selectedMeasureName: string | null;
  personLocation: IPersonLocation | null;
  selectedAppointmentID: number | null;
  wizardStep: number;
}

export const {
  useStoreSelector: useActivityPlaningWizardSelector,
  StoreProvider: ActivityPlaningWizardProvider,
} = createStoreContext<IActivityPlaningWizardContext>();
