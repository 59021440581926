import { useEffect, useMemo } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import { useSchemaContext } from '@/components/SchemaContext/SchemaContext';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  dropdownItemsActions,
  IDropdownItem,
} from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import { ISelect, Select } from '../Select';
import { ISelectItem } from '../SelectItem/SelectItem';

export interface IExternalSelectForm extends ISelect {
  control: UseControllerProps;
  tableName?: string;
}

export const ExternalSelectForm: React.FC<IExternalSelectForm> = (props) => {
  const { control, tableName, label } = props;

  const dropdownItems = useAppSelector(dropdownItemsSelectors.dropdownItems);
  const dispatch = useAppDispatch();
  const {
    field: { value, name, onChange: controllerOnChange },
    fieldState,
  } = useController(control);
  const { isRequiredField } = useSchemaContext(name);

  const externalOptions: IDropdownItem[] = useMemo(() => {
    if (!tableName || !dropdownItems || !dropdownItems[tableName]) {
      return [];
    }

    return dropdownItems[tableName];
  }, [dropdownItems, tableName]);

  const externalSelectOptions: ISelectItem[] = useMemo(
    () =>
      externalOptions.map((item) => ({
        id: String(item.id),
        component: item.value,
      })),
    [externalOptions]
  );

  const onChange = (id: string) => {
    controllerOnChange(id);
  };

  useEffect(() => {
    if (!tableName) {
      return;
    }

    dispatch(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: tableName,
      })
    );
  }, [tableName]);

  return (
    <>
      <Select
        {...props}
        values={externalSelectOptions}
        onChange={onChange}
        initialValueID={value}
        label={label}
        isRequired={isRequiredField}
        selectedContainerClassName={twMerge(
          fieldState.error && `border-${colorTypes.Error} focus:border-${colorTypes.Error}`
        )}
      />
      {fieldState.error && (
        <Typography type={'14-R'} color={'Error'} className={'mt-1'}>
          {fieldState.error.message}
        </Typography>
      )}
    </>
  );
};
