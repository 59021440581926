export enum DateRangeType {
  THIS_WEEK = 'this_week',
  THIS_MONTH = 'this_month',
  THIS_QUARTER = 'this_quarter',
  NEXT_WEEK = 'next_week',
  NEXT_MONTH = 'next_month',
  NEXT_QUARTER = 'next_quarter',
  CHOSEN_DAY = 'chosen_day',
  CHOSEN_PERIOD = 'chosen_period',
}
