import React, { useEffect } from 'react';

import { MobilityTransportTypesModalContent } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/MobilityTransportTypesModal/MobilityTransportTypesModalContent/MobilityTransportTypesModalContent';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { workingTimeMobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/selectors';
import { workingTimeMobilityModalsActions } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/workingTimeMobilitModalsSlice';

export const MobilityTransportTypesModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    workingTimeMobilityModalsSelectors.transportTypesModal
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpened) {
      dispatch(workingTimeMobilityModalsActions.fetchTransportTypes(1));
    }
  }, [isOpened]);

  const handleClose = () => {
    dispatch(workingTimeMobilityModalsActions.closeTransportTypesModal());
  };

  if (isOpened) {
    return (
      <Modal onExit={handleClose} className={'flex items-center justify-center'}>
        <MobilityTransportTypesModalContent />
      </Modal>
    );
  }

  return null;
};
