import React from 'react';

import { IIcon } from '@/types/icon';

export const SortByIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    className={className}
  >
    <path
      d='M6.02305 3.99522C5.97053 3.94349 5.90813 3.90245 5.83944 3.87445C5.77075 3.84645 5.6971 3.83203 5.62273 3.83203C5.54836 3.83203 5.47472 3.84645 5.40602 3.87445C5.33733 3.90245 5.27494 3.94349 5.22241 3.99522L1.82988 7.32803C1.72371 7.43234 1.66406 7.5738 1.66406 7.72131C1.66406 7.86881 1.72371 8.01028 1.82988 8.11458C1.93605 8.21888 2.08005 8.27748 2.2302 8.27748C2.38035 8.27748 2.52435 8.21888 2.63052 8.11458L5.05731 5.72939V16.6088C5.05731 16.7561 5.11688 16.8974 5.22292 17.0016C5.32896 17.1058 5.47277 17.1643 5.62273 17.1643C5.77269 17.1643 5.91651 17.1058 6.02255 17.0016C6.12858 16.8974 6.18815 16.7561 6.18815 16.6088V5.72939L8.61495 8.11458C8.72112 8.21888 8.86512 8.27748 9.01527 8.27748C9.16541 8.27748 9.30941 8.21888 9.41559 8.11458C9.52176 8.01028 9.5814 7.86881 9.5814 7.72131C9.5814 7.5738 9.52176 7.43234 9.41559 7.32803L6.02305 3.99522ZM13.1451 17.0087C13.2506 17.1092 13.3917 17.1654 13.5386 17.1654C13.6856 17.1654 13.8267 17.1092 13.9322 17.0087L17.3247 13.787C17.4327 13.6845 17.4948 13.544 17.4973 13.3965C17.4999 13.249 17.4427 13.1066 17.3383 13.0005C17.2339 12.8944 17.0909 12.8334 16.9408 12.8309C16.7906 12.8284 16.6456 12.8846 16.5376 12.9872L14.1041 15.299V4.3896C14.1041 4.24228 14.0445 4.101 13.9385 3.99683C13.8324 3.89266 13.6886 3.83414 13.5386 3.83414C13.3887 3.83414 13.2449 3.89266 13.1388 3.99683C13.0328 4.101 12.9732 4.24228 12.9732 4.3896V15.299L10.5396 12.9883C10.4865 12.9368 10.4235 12.8963 10.3542 12.8689C10.285 12.8415 10.211 12.8278 10.1364 12.8287C10.0617 12.8295 9.98804 12.8449 9.91949 12.8739C9.85095 12.9028 9.78892 12.9448 9.73698 12.9975C9.68504 13.0501 9.64422 13.1123 9.61688 13.1805C9.58953 13.2487 9.5762 13.3216 9.57765 13.3949C9.5791 13.4682 9.5953 13.5405 9.62533 13.6076C9.65535 13.6747 9.6986 13.7353 9.75258 13.7859L13.1451 17.0087Z'
      fill='currentColor'
    />
  </svg>
);
