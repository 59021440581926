import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, debounce, put } from 'redux-saga/effects';

import { ISerialAppointmentForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/hooks/useSerialAppointmentForm';
import { authAdd, restCall } from '@/core/clients/rest';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { dropdownItemsActions } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import {
  ISerialAppointmentDetailsFetchPayload,
  serialAppointmentModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningSerialAppointment/slice';
import oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type SerialAppointmentDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/serial',
  'get'
>;

type SerialAppointmentDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/serial',
  'get',
  '200'
>;

type SerialAppointmentWeekNumbersResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/dropdown_items/week_number',
  'get',
  '200'
>;

function* fetchSerialAppointmentDetails(
  action: PayloadAction<ISerialAppointmentDetailsFetchPayload>
): Generator<any, void, any> {
  const { appointmentSeriesID } = action.payload;

  try {
    yield put(
      serialAppointmentModalsActions.setSerialAppointmentDetailsLock(LoadingStatus.LOADING)
    );

    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.WEEK_DAY,
      })
    );

    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.APPOINTMENT_SERIAL_TYPE,
      })
    );

    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.MONTH,
      })
    );

    const weekNumbersResponse: SerialAppointmentWeekNumbersResponse = yield call(
      restCall,
      '/dropdown_items/week_number',
      'get',
      {
        ...authAdd(),
      }
    );

    yield put(
      serialAppointmentModalsActions.setSerialAppointmentWeekNumbers(
        weekNumbersResponse.dropdown_items
      )
    );

    yield put(serialAppointmentModalsActions.setSerialAppointmentDetailsLock(LoadingStatus.LOADED));

    if (!appointmentSeriesID) {
      return;
    }

    yield put(
      serialAppointmentModalsActions.setSerialAppointmentDetailsLock(LoadingStatus.LOADING)
    );

    const request: SerialAppointmentDetailsRequest = {
      query: {
        appointment_series_id: appointmentSeriesID,
      },
      ...authAdd(),
    };

    const serialAppointmentDetailsResponse: SerialAppointmentDetailsResponse = yield call(
      restCall,
      '/activity_planning/measure/appointment/serial',
      'get',
      request
    );

    const mappedResponse: ISerialAppointmentForm = {
      serialTypeID: serialAppointmentDetailsResponse.serial_data.serial_type_id,
      daily: {
        isWorkingDaysOnly: Boolean(
          serialAppointmentDetailsResponse.serial_data.daily?.is_working_days_only
        ),
        daysInterval: serialAppointmentDetailsResponse.serial_data.daily?.days_interval,
      },
      weekly: {
        weeksInterval: serialAppointmentDetailsResponse.serial_data.weekly?.weeks_interval,
        onMonday: Boolean(serialAppointmentDetailsResponse.serial_data.weekly?.on_monday),
        onTuesday: Boolean(serialAppointmentDetailsResponse.serial_data.weekly?.on_tuesday),
        onWednesday: Boolean(serialAppointmentDetailsResponse.serial_data.weekly?.on_wednesday),
        onThursday: Boolean(serialAppointmentDetailsResponse.serial_data.weekly?.on_thursday),
        onFriday: Boolean(serialAppointmentDetailsResponse.serial_data.weekly?.on_friday),
        onSaturday: Boolean(serialAppointmentDetailsResponse.serial_data.weekly?.on_saturday),
        onSunday: Boolean(serialAppointmentDetailsResponse.serial_data.weekly?.on_sunday),
      },
      monthly: {
        isRegularDayOfMonth: Boolean(
          serialAppointmentDetailsResponse.serial_data.monthly?.is_regular_day
        ),
        daysInterval: serialAppointmentDetailsResponse.serial_data.monthly?.days_interval,
        monthsInterval: serialAppointmentDetailsResponse.serial_data.monthly?.months_interval,
        weekNumber: serialAppointmentDetailsResponse.serial_data.monthly?.week_number?.id,
        weekDayID: serialAppointmentDetailsResponse.serial_data.monthly?.week_day?.id,
      },
      yearly: {
        isRegularMonthOfYear: Boolean(
          serialAppointmentDetailsResponse.serial_data.yearly?.is_regular_month
        ),
        yearsInterval: serialAppointmentDetailsResponse.serial_data.yearly?.years_interval,
        weekNumber: serialAppointmentDetailsResponse.serial_data.yearly?.week_number?.id,
        weekDayID: serialAppointmentDetailsResponse.serial_data.yearly?.week_day?.id,
        specificMonthDay: serialAppointmentDetailsResponse.serial_data.yearly?.days_interval,
        monthID: serialAppointmentDetailsResponse.serial_data.yearly?.month?.id,
      },
      duration: {
        isFixedAppointmentsCount: false,
        appointmentsNumber: serialAppointmentDetailsResponse.serial_data.appointments_number,
        endDate: serialAppointmentDetailsResponse.serial_data.end_date,
      },
    };

    yield put(serialAppointmentModalsActions.setSerialAppointmentDetails(mappedResponse));

    yield put(serialAppointmentModalsActions.setSerialAppointmentDetailsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on serial appointment details fetching', error);
  }
}

export const serialAppointmentModalSagas = [
  debounce(
    500,
    serialAppointmentModalsActions.fetchSerialAppointmentDetails,
    fetchSerialAppointmentDetails
  ),
];
