import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDate, toBackendDateTime, toClientDateInput } from '@/core/utils/dateTimeUtil';
import { readStreamAsBlob } from '@/core/utils/fileUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  ICategory,
  ILogbook,
  ILogbookItemChangeHideStatusPayload,
  ILogbookItemCreatePayload,
  ILogbookItemUpdatePayload,
  ILogbookPerson,
  ILogbookPersonFetchPayload,
  ILogbookSummary,
  ILogbookSummaryFetchPayload,
  logbookActions,
} from './logbookSlice';

type LogbookPersonResponse = OASOutput<NormalizeOAS<typeof oas>, '/logbook/person', 'get', '200'>;
type LogbookSummaryResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/logbook/person/summary',
  'get',
  '200'
>;

type CategoriesResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/logbook/person/category',
  'get',
  '200'
>;

type LogbookItemCreateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/logbook/person',
  'post'
>;
type LogbookItemUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/logbook/person',
  'put'
>;
type ChangeHideStatusRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/logbook/person/change_hide_status',
  'put'
>;

// function* fetchLogbookList(
//   action: PayloadAction<ILogbookPersonFetchPayload>
// ): Generator<any, void, LogbookPersonResponse> {
//   yield put(logbookActions.setLogbookPersonLock(LoadingStatus.LOADING));

//   const { search, categoryID, isCreatedByMe } = action.payload;

//   try {
//     const response = yield call(restCall, '/logbook/person', 'get', {
//       query: { category_id: categoryID, search: search, only_created_by_me: isCreatedByMe },
//       ...authAdd(),
//     });

//     const formatedPerson: ILogbook[] = toCamelCase(response.items);

//     const logbookPerson: ILogbook[] = formatedPerson.map((item) => ({
//       ...item,
//       createdTimestamp: toClientDateInput(item.createdTimestamp) ?? '',
//     }));

//     yield put(logbookActions.setLogbookPerson(logbookPerson));

//     yield put(logbookActions.setLogbookPersonLock(LoadingStatus.LOADED));
//   } catch (error) {
//     console.log('Error on logbook data fetching');
//     yield put(logbookActions.setLogbookPersonLock(LoadingStatus.ERROR));
//   }
// }

function* fetchLogbookPerson(
  action: PayloadAction<ILogbookPersonFetchPayload>
): Generator<any, void, LogbookPersonResponse> {
  yield put(logbookActions.setLogbookPersonLock(LoadingStatus.LOADING));

  const { personID } = action.payload;

  try {
    const response = yield call(restCall, '/logbook/person', 'get', {
      query: { person_id: personID},
      ...authAdd(),
    });

    const formatedPerson: ILogbookPerson[] = toCamelCase(response.items);

    const logbookPerson: ILogbookPerson[] = formatedPerson.map((item) => ({
      ...item,
      createdTimestamp: toClientDateInput(item.createdTimestamp) ?? '',
    }));

    yield put(logbookActions.setLogbookPerson(logbookPerson));

    yield put(logbookActions.setLogbookPersonLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on logbook data fetching');
    yield put(logbookActions.setLogbookPersonLock(LoadingStatus.ERROR));
  }
}

function* fetchLogbookSummary(
  action: PayloadAction<ILogbookSummaryFetchPayload>
): Generator<any, void, LogbookSummaryResponse> {
  yield put(logbookActions.setLogbookSummaryLock(LoadingStatus.LOADING));

  const { personID } = action.payload;

  try {
    const response = yield call(restCall, '/logbook/person/summary', 'get', {
      query: { person_id: personID },
      ...authAdd(),
    });

    const logbookSummaryFormat: ILogbookSummary[] = toCamelCase(response.items);

    const logbookSummary: ILogbookSummary[] = logbookSummaryFormat.map((item) => ({
      ...item,
      createdTimestamp: toClientDateInput(item.createdTimestamp) ?? '',
    }));

    yield put(logbookActions.setLogbookSummary(logbookSummary));

    yield put(logbookActions.setLogbookSummaryLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on logbook data fetching');
    yield put(logbookActions.setLogbookSummaryLock(LoadingStatus.ERROR));
  }
}

function* fetchCategories(): Generator<any, void, CategoriesResponse> {
  yield put(logbookActions.setCategoriesLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/logbook/person/category', 'get', {
      ...authAdd(),
    });

    const categories: ICategory[] = toCamelCase(response.categories);

    yield put(logbookActions.setCategories(categories));

    yield put(logbookActions.setCategoriesLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on categorires data fetching');
    yield put(logbookActions.setCategoriesLock(LoadingStatus.ERROR));
  }
}

function* updateLogbookItem(
  action: PayloadAction<ILogbookItemUpdatePayload>
): Generator<any, void, any> {
  const { logbookItemID, categoryID, note, createdTimestamp } = action.payload;

  try {
    yield put(logbookActions.setUpdateLogbookItemLock(LoadingStatus.LOADING));

    const request: LogbookItemUpdateRequest = {
      json: {
        id: logbookItemID,
        category_id: categoryID,
        note: note,
        created_timestamp: toBackendDate(createdTimestamp, true) || '',
      },
      ...authAdd(),
    };

    yield call(restCall, '/logbook/person', 'put', request);

    yield put(logbookActions.setUpdateLogbookItemLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on logbook item update', error);
    yield put(logbookActions.setUpdateLogbookItemLock(LoadingStatus.ERROR));
  }
}

function* createLogbookItem(
  action: PayloadAction<ILogbookItemCreatePayload>
): Generator<any, void, any> {
  const { personID, categoryID, note } = action.payload;

  try {
    yield put(logbookActions.setCreateLogbookItemLock(LoadingStatus.LOADING));

    const request: LogbookItemCreateRequest = {
      json: {
        person_id: personID,
        category_id: categoryID,
        note: note,
      },
      ...authAdd(),
    };

    yield call(restCall, '/logbook/person', 'post', request);

    yield put(logbookActions.setCreateLogbookItemLock(LoadingStatus.LOADED));
    yield put(logbookActions.fetchLogbookSummary({ personID: personID }));
  } catch (error) {
    console.log('Error on logbook item create', error);
    yield put(logbookActions.setCreateLogbookItemLock(LoadingStatus.ERROR));
  }
}

function* changeHideStatus(
  action: PayloadAction<ILogbookItemChangeHideStatusPayload>
): Generator<any, void, any> {
  const { logbookItemID } = action.payload;

  try {
    yield put(logbookActions.setChangeHideStatusLock(LoadingStatus.LOADING));

    const request: ChangeHideStatusRequest = {
      query: { logbook_item_id: logbookItemID },
      ...authAdd(),
    };

    yield call(restCall, '/logbook/person/change_hide_status', 'delete', request);

    yield put(logbookActions.setChangeHideStatusLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on change hide status', error);
    yield put(logbookActions.setChangeHideStatusLock(LoadingStatus.ERROR));
  }
}

export const logbookSagas = [
  takeLatest(logbookActions.fetchLogbookPerson, fetchLogbookPerson),
  takeLatest(logbookActions.fetchLogbookSummary, fetchLogbookSummary),
  takeLatest(logbookActions.fetchCategories, fetchCategories),
  takeLatest(logbookActions.createLogbookItem, createLogbookItem),
  takeLatest(logbookActions.updateLogbookItem, updateLogbookItem),
  takeLatest(logbookActions.changeHideStatus, changeHideStatus),
];
