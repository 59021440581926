import createStoreContext from '@/components/Context/ReduceModelStoreContext';
import { IPersonLocation } from '@/core/redux/slices/functions/activityPlanning/measuresList/slice';

export interface IActivityPlaningContext {
  selectedMeasureID: number | null;
  selectedMeasureName: string | null;
  personLocation: IPersonLocation | null;
  selectedAppointmentID: number | null;
  isActionsBlocked: boolean;
}

export const {
  useStoreSelector: useActivityPlaningSelector,
  StoreProvider: ActivityPlaningProvider,
} = createStoreContext<IActivityPlaningContext>();
