import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListViewIcon } from '@/components/Icons/ListViewIcon';
import { Typography } from '@/components/Typography/Typography';

export const FunctionGridViewEmptyPlaceholder: React.FC = () => {
  const { t: functionsPageTranslations } = useTranslation('functionsPage', {
    keyPrefix: 'placeholders',
  });

  return (
    <div className={'flex-grow flex flex-row justify-center items-center gap-3'}>
      <ListViewIcon />
      <Typography>{functionsPageTranslations('pinFunction.label')}</Typography>
    </div>
  );
};
