import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  formatOptionsToSchema,
  formatSchemaToOptions,
  toCamelCase,
} from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { Camelize } from '@/types/camelize';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  IAdministerFetchPayload,
  IGeneralData,
  IGeneralDataFetchPayload,
  IGeneralDataUpdatePayload,
  specialistGeneralDataActions,
} from './specialistGeneralDataSlice';

type GeneralDataResponse = OASOutput<NormalizeOAS<typeof oas>, '/user/general_data', 'get', '200'>;
type AdministerResponse = OASOutput<NormalizeOAS<typeof oas>, '/user/administer', 'get', '200'>;
type GeneralDataUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/user/general_data',
  'put'
>;

function* fetchGeneralData(
  action: PayloadAction<IGeneralDataFetchPayload>
): Generator<any, void, GeneralDataResponse> {
  yield put(specialistGeneralDataActions.setGeneralDataLock(LoadingStatus.LOADING));

  const { userID } = action.payload;

  try {
    const response = yield call(restCall, '/user/general_data', 'get', {
      query: {
        user_id: userID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<GeneralDataResponse> = toCamelCase(response);
    const generalDataResponse = camelizeResponse.generalData;

    const generalData: IGeneralData = {
      ...generalDataResponse,
      birthDate: toClientDateInput(generalDataResponse.birthDate),
      professionSinceTimestamp: toClientDateInput(generalDataResponse.professionSinceTimestamp),
      inactiveTimestamp: toClientDateInput(generalDataResponse.inactiveTimestamp),
      gender: generalDataResponse.gender?.id,
      salutation: generalDataResponse.salutation?.id,
      roles: formatOptionsToSchema(generalDataResponse.roles),
      targetCatalogTypes: formatOptionsToSchema(generalDataResponse.targetCatalogTypes),
    };

    yield put(specialistGeneralDataActions.setGeneralData(generalData));

    yield put(specialistGeneralDataActions.setGeneralDataLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on specialist general data fetching');
    yield put(specialistGeneralDataActions.setGeneralDataLock(LoadingStatus.ERROR));
  }
}

function* updateGeneralData(
  action: PayloadAction<IGeneralDataUpdatePayload>
): Generator<any, void, any> {
  const { userID, generalData } = action.payload;

  try {
    yield put(specialistGeneralDataActions.setUpdateGeneralDataLock(LoadingStatus.LOADING));

    const request: GeneralDataUpdateRequest = {
      json: {
        id: userID,
        name: generalData.name || '',
        surname: generalData.surname || '',
        birth_date: toBackendDate(generalData.birthDate),
        telephone_number: generalData.telephoneNumber,
        cell_phone_number: generalData.cellPhoneNumber,
        email: generalData.email,
        profession: generalData.profession,
        profession_since_timestamp: toBackendDate(generalData.professionSinceTimestamp),
        is_educational_supporter: generalData.isEducationalSupporter,
        is_social_service_worker: generalData.isSocialServiceWorker,
        is_group_manager: generalData.isGroupManager,
        is_internship_supervisor: generalData.isInternshipSupervisor,
        is_specialist: generalData.isSpecialist,
        is_jobcoach: generalData.isJobcoach,
        is_manager: generalData.isManager,
        inactive_timestamp: toBackendDate(generalData.inactiveTimestamp),
        salutation_id: generalData.salutation,
        gender_id: generalData.gender,
        roles_ids: formatSchemaToOptions(generalData.roles),
        target_catalog_types: formatSchemaToOptions(generalData.targetCatalogTypes),
      },
      ...authAdd(),
    };

    yield call(restCall, '/user/general_data', 'put', request);

    yield put(specialistGeneralDataActions.setUpdateGeneralDataLock(LoadingStatus.LOADED));
    yield put(specialistGeneralDataActions.setGeneralData(generalData));
  } catch (error) {
    console.log('Error on medication update', error);
    yield put(specialistGeneralDataActions.setUpdateGeneralDataLock(LoadingStatus.ERROR));
  }
}

function* fetchAdministers(
  action: PayloadAction<IAdministerFetchPayload>
): Generator<any, void, AdministerResponse> {
  yield put(specialistGeneralDataActions.setAdministersLock(LoadingStatus.LOADING));

  const { date } = action.payload;

  try {
    const response = yield call(restCall, '/user/administer', 'get', {
      query: {
        date: toBackendDate(date),
      },
      ...authAdd(),
    });
    const { administers } = response;

    yield put(specialistGeneralDataActions.setAdministers(administers));

    yield put(specialistGeneralDataActions.setAdministersLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on specialist general data fetching');
    yield put(specialistGeneralDataActions.setAdministersLock(LoadingStatus.ERROR));
  }
}

export const specialistGeneralDataSagas = [
  takeLatest(specialistGeneralDataActions.fetchGeneralData, fetchGeneralData),
  takeLatest(specialistGeneralDataActions.fetchAdministers, fetchAdministers),
  takeLatest(specialistGeneralDataActions.updateGeneralData, updateGeneralData),
];
