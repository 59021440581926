import { IFormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { DevelopmentReportDisabilityTypes } from '@/core/enums/functions/developmentReport/developmentReportDisabilityTypesEnum';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { DevelopmentReportLegalSupportTypes } from '@/core/enums/functions/developmentReport/developmentReportLegalSupportTypesEnum';

export interface IDevelopmentReportPersonDetailsForm {
  hbg: DevelopmentReportDisabilityTypes;
  legalGuardianFlag: DevelopmentReportLegalSupportTypes;
  isMobilityOepnv: boolean;
  isMobilityOnFoot: boolean;
  isMobilitySelfDrive: boolean;
  wfbmName?: string | null;
  wfbmOperatingUnit?: string | null;
  wfbmContactPerson?: string | null;
  legalGuardianNameAddress?: string | null;
  isMobilityTransportService: boolean;
  workingHoursPerWeek?: number | null;
  workingDaysPerWeek?: number | null;
  workingHoursPerDay?: number | null;
  diagnosis?: string | null;
  internships?: string | null;
  communicationNote?: string | null;
  goalsWishes?: string | null;
  defaultLegalGuardianNameAddress?: string | null;
}

export const personDetailsDefaultValues: IDevelopmentReportPersonDetailsForm = {
  hbg: DevelopmentReportDisabilityTypes.Default,
  legalGuardianFlag: DevelopmentReportLegalSupportTypes.Default,
  isMobilityOepnv: false,
  isMobilityOnFoot: false,
  isMobilitySelfDrive: false,
  isMobilityTransportService: false,
  wfbmName: '',
  wfbmOperatingUnit: '',
  wfbmContactPerson: '',
  legalGuardianNameAddress: '',
  workingHoursPerWeek: null,
  workingDaysPerWeek: null,
  workingHoursPerDay: null,
  diagnosis: '',
  internships: '',
  communicationNote: '',
  goalsWishes: '',
  defaultLegalGuardianNameAddress: '',
};

export const personDetailsForm = (props: {
  hbgRadioButtonGroupOptions: Omit<IFormRadioButtonGroup, 'control'>;
  legalGuardianRadioButtonGroupOptions: Omit<IFormRadioButtonGroup, 'control'>;
}): (FormItem<IDevelopmentReportForm> | FormGroup<IDevelopmentReportForm>)[] => [
  {
    fieldsContainerClassName: 'flex flex-row flex-wrap gap-12 w-full',
    fields: [
      {
        fieldsContainerClassName: 'flex flex-col gap-5',
        fields: [
          {
            title: 'groups.disabilityType.title.label',
            fieldsContainerClassName: 'gap-5',
            type: 'radio-button-group',
            name: `${DevelopmentReportForms.PersonDetails}.hbg`,
            radioButtonGroupOptions: props.hbgRadioButtonGroupOptions,
          },
          {
            title: 'groups.legalSupport.title.label',
            fieldsContainerClassName: 'flex flex-col gap-2',
            fields: [
              {
                fieldsContainerClassName: 'flex flex-row gap-2',
                type: 'radio-button-group',
                name: `${DevelopmentReportForms.PersonDetails}.legalGuardianFlag`,
                radioButtonGroupOptions: props.legalGuardianRadioButtonGroupOptions,
              },
              {
                type: 'input',
                name: `${DevelopmentReportForms.PersonDetails}.legalGuardianNameAddress`,
              },
            ],
          },
        ],
      },
      {
        className: 'flex-grow',
        fieldsContainerClassName: 'flex flex-col gap-2',
        fields: [
          {
            type: 'input',
            label: 'groups.wfbm.name.label',
            name: `${DevelopmentReportForms.PersonDetails}.wfbmName`,
            className: 'grid grid-cols-2',
          },
          {
            type: 'input',
            label: 'groups.wfbm.operatingUnit.label',
            name: `${DevelopmentReportForms.PersonDetails}.wfbmOperatingUnit`,
            className: 'grid grid-cols-2',
          },
          {
            type: 'input',
            label: 'groups.wfbm.contactPerson.label',
            name: `${DevelopmentReportForms.PersonDetails}.wfbmContactPerson`,
            className: 'grid grid-cols-2',
          },
        ],
      },
      {
        title: 'groups.mobility.title.label',
        fieldsContainerClassName: 'flex flex-col gap-3',
        fields: [
          {
            type: 'checkbox',
            label: 'groups.mobility.fields.publicTransport.label',
            name: `${DevelopmentReportForms.PersonDetails}.isMobilityOepnv`,
            className: 'grid grid-cols-2',
          },
          {
            type: 'checkbox',
            label: 'groups.mobility.fields.carService.label',
            name: `${DevelopmentReportForms.PersonDetails}.isMobilityTransportService`,
            className: 'grid grid-cols-2',
          },
          {
            type: 'checkbox',
            label: 'groups.mobility.fields.onFoot.label',
            name: `${DevelopmentReportForms.PersonDetails}.isMobilityOnFoot`,
            className: 'grid grid-cols-2',
          },
          {
            type: 'checkbox',
            label: 'groups.mobility.fields.selfDrive.label',
            name: `${DevelopmentReportForms.PersonDetails}.isMobilitySelfDrive`,
            className: 'grid grid-cols-2',
          },
        ],
      },
    ],
  },
  {
    title: 'groups.workingTime.title.label',
    fieldsContainerClassName: 'flex flex-col gap-3 w-fit',
    fields: [
      {
        type: 'input',
        label: 'groups.workingTime.fields.hoursPerWeek.label',
        name: `${DevelopmentReportForms.PersonDetails}.workingHoursPerWeek`,
        className: 'grid grid-cols-2',
      },
      {
        type: 'input',
        label: 'groups.workingTime.fields.dayPerWeek.label',
        name: `${DevelopmentReportForms.PersonDetails}.workingDaysPerWeek`,
        className: 'grid grid-cols-2',
      },
      {
        type: 'input',
        label: 'groups.workingTime.fields.hoursPerDay.label',
        name: `${DevelopmentReportForms.PersonDetails}.workingHoursPerDay`,
        className: 'grid grid-cols-2',
      },
    ],
  },
  {
    type: 'textarea',
    label: 'fields.diagnosis.label',
    name: `${DevelopmentReportForms.PersonDetails}.diagnosis`,
  },
  {
    type: 'textarea',
    label: 'fields.internships.label',
    name: `${DevelopmentReportForms.PersonDetails}.internships`,
  },
  {
    type: 'textarea',
    label: 'fields.communication.label',
    name: `${DevelopmentReportForms.PersonDetails}.communicationNote`,
  },
  {
    type: 'textarea',
    label: 'fields.goalsWishes.label',
    name: `${DevelopmentReportForms.PersonDetails}.defaultLegalGuardianNameAddress`,
  },
];
