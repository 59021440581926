import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functionsPageModals;

const personInfoModal = createSelector([selector], (state) => state.personInfoModal);

export const functionsPageModalsSelectors = {
  personInfoModal,
};
