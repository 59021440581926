import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import fp from 'lodash/fp';

import { completeMeasureFormSchema } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/hooks/completeMeasureModalFormValidator';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';
import {
  IAppointment,
  ICloseMeasureItem,
  IMeasure,
  IMeasuresGroup,
  IRootMeasureGroup,
  vocationalTrainingAreaModalActions,
} from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';

export interface ICompleteMeasureFormGroupItem {
  id: number;
  isParticipated: boolean;
  isUnderDirection: boolean;
  isAlmostIndependent: boolean;
  isIndependent: boolean;
  comment?: string | null;
}

export interface ICompleteMeasureFormGroup {
  id: number;
  items?: ICompleteMeasureFormGroupItem[];
}

export interface ICompleteMeasureFormRootGroup {
  id: number;
  groups?: ICompleteMeasureFormGroup[];
}

export interface ICompleteMeasureForm {
  rootGroups?: ICompleteMeasureFormRootGroup[];
  selectedMeasure?: string;
  appointments?: ICompleteMeasureFormGroupItem[];
}

export const mapFormItem = (
  items: IMeasure[] | IAppointment[],
  isInitial?: boolean
): ICompleteMeasureFormGroupItem[] => {
  return items.map((item) => ({
    id: item.id,
    isAlmostIndependent: isInitial ? false : Boolean(item.isAlmostIndependent),
    isIndependent: isInitial ? false : Boolean(item.isIndependent),
    isParticipated: isInitial ? false : Boolean(item.isParticipated),
    isUnderDirection: isInitial ? false : Boolean(item.isUnderDirection),
    comment: isInitial ? undefined : item.note,
  }));
};

const mapFormGroup = (
  group: IMeasuresGroup[],
  isInitial?: boolean
): ICompleteMeasureFormGroup[] => {
  return group.map<ICompleteMeasureFormGroup>((_group) => ({
    items: _group.items ? mapFormItem(_group.items, isInitial) : undefined,
    id: _group.id,
  }));
};

const mapFormRootGroup = (
  group: IRootMeasureGroup[],
  isInitial?: boolean
): ICompleteMeasureFormGroup[] => {
  return group.map<ICompleteMeasureFormGroup>((_group) => ({
    groups: _group.groups ? mapFormGroup(_group.groups, isInitial) : undefined,
    id: _group.id,
  }));
};

const extractCloseMeasureItems = (form: ICompleteMeasureForm): ICloseMeasureItem[] => {
  const result: ICloseMeasureItem[] = [];

  const traverseGroup = (group: ICompleteMeasureFormGroup) => {
    if (!group.items) return;
    group.items.forEach((item) => {
      if (
        item.isParticipated ||
        item.isUnderDirection ||
        item.isAlmostIndependent ||
        item.isIndependent
      ) {
        result.push({
          id: group.id,
          level: item.id,
          isParticipated: item.isParticipated,
          isUnderDirection: item.isUnderDirection,
          isAlmostIndependent: item.isAlmostIndependent,
          isIndependent: item.isIndependent,
          note: item.comment,
        });
      }
    });
  };

  const traverseRootGroup = (rootGroup: ICompleteMeasureFormRootGroup) => {
    rootGroup.groups?.forEach((group) => traverseGroup(group));
  };

  form.rootGroups?.forEach((rootGroup) => traverseRootGroup(rootGroup));

  return result;
};

export function useCompleteMeasureModalForm() {
  const { t: completeMeasureModalTranslations } = useTranslation('completeMeasureModal');

  const { measureDetails } = useAppSelector(
    vocationalTrainingAreaModalSelectors.completeMeasureModal
  );

  const isMnTerminWirksamkeitPflicht = useAppSelector(
    parametersSelectors.isMnTerminWirksamkeitPflicht
  );

  const dispatch = useAppDispatch();

  const form = useForm<ICompleteMeasureForm>({
    resolver: yupResolver(
      completeMeasureFormSchema(isMnTerminWirksamkeitPflicht, completeMeasureModalTranslations)
    ),
  });

  useEffect(() => {
    if (!measureDetails) {
      return;
    }

    let appointments: ICompleteMeasureFormGroupItem[] = [];

    const measureID = measureDetails[0]?.groups[0]?.items[0]?.id;
    const groupID = measureDetails[0]?.groups[0]?.id;

    const selectedMeasure = `groupID=${groupID}&measureID=${measureID}`;

    const selectedAppointments = measureDetails[0]?.groups[0]?.appointments;

    if (selectedAppointments) {
      appointments = mapFormItem(measureDetails[0].groups[0].appointments);
    }

    const newFormValues: ICompleteMeasureForm = {
      rootGroups: mapFormRootGroup(measureDetails, true),
      selectedMeasure: selectedMeasure,
      appointments: appointments,
    };

    form.reset(newFormValues);
  }, [measureDetails]);

  const handleCloseMeasure = () => {
    form.trigger().then((isValid) => {
      if (!isValid) {
        return;
      }

      const formValues = fp.cloneDeep(form.watch());
      const measureItems = extractCloseMeasureItems(formValues);

      dispatch(
        vocationalTrainingAreaModalActions.closeMeasure({
          measureItems: measureItems,
        })
      );
    });
  };

  return { form, handleCloseMeasure };
}
