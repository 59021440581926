import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all, ForkEffect, put } from 'redux-saga/effects';

import { ActionCreator } from '@/core/redux/actions';
import { LoadingStatus } from '@/types/loadingStatus';

export const sagaMiddleware = createSagaMiddleware();

export const createRootSaga = (sagas: ForkEffect<never>[] = []) => {
  return function* main() {
    try {
      yield all(sagas);
    } catch (e) {
      console.warn(e);
    }
  };
};

export const mixinWithLoading =
  (action: ActionCreator | ActionCreatorWithPayload<any, string>) => (next: Function) =>
    function* busyWrapper() {
      yield put(action(LoadingStatus.LOADING));
      yield next();
      yield put(action(LoadingStatus.LOADED));
    };

export const mixinWithOnlyStartLoading =
  (action: ActionCreator | ActionCreatorWithPayload<any, string>) => (next: Function) =>
    function* busyWrapper() {
      yield put(action(LoadingStatus.LOADING));
      yield next();
    };

export const mixinWithErrorHandler =
  (key: string, action: ActionCreator | ActionCreatorWithPayload<any, string>) =>
  (next: Function) =>
    function* errorHandlerWrapper() {
      try {
        yield next();
      } catch (error: any) {
        console.log(error.message);
        yield put(action(LoadingStatus.ERROR));
      }
    };
