import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//@ts-expect-error: TelerikReportViewer doesn't have types support
import { TelerikReportViewer } from '@progress/telerik-react-report-viewer';

import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { IReport, reportsActions } from '@/core/redux/slices/reports/reportsSlice';
import { reportsSelectors } from '@/core/redux/slices/reports/selectors';
import { twMerge } from '@/core/utils/tailwindUtil';

import styles from './styles.module.scss';

const getReportFileName = (reports: IReport[], id?: string) => {
  if (!id) {
    return undefined;
  }

  const foundReport = reports.find((item) => item.id === id);
  return foundReport?.fileName;
};

export const ReportsPage: React.FC = () => {
  const reports = useAppSelector(reportsSelectors.reports);
  const dispatch = useAppDispatch();

  const viewer = useRef<any>(null);

  const { t: reportsTranslations } = useTranslation('reports');

  const [selectedReportID, setSelectedReportID] = useState<string>();

  const reportOptions: ISelectItem[] = useMemo(
    () =>
      reports.map((report) => ({
        id: report.id,
        component: report.name,
      })),
    [reports]
  );

  const handleReportChange = (id: string) => {
    setSelectedReportID(id);

    const fileName = getReportFileName(reports, id);

    if (viewer.current && fileName) {
      viewer.current.setReportSource({
        report: fileName,
        parameters: {},
      });
    }
  };

  useEffect(() => {
    dispatch(reportsActions.fetchReports());
  }, [dispatch]);

  return (
    <div className={'px-[40px] w-full flex flex-col'}>
      <div className='py-5'>
        <Typography type='H1'>{reportsTranslations('title.label')}</Typography>
      </div>

      <div className={twMerge(styles.container, 'overflow-hidden flex flex-col flex-grow w-full')}>
        <Select
          values={reportOptions}
          isFlexible
          onChange={handleReportChange}
          className='w-[300px] mb-4'
        />
        <div className={'flex-grow'}>
          <TelerikReportViewer
            ref={viewer}
            serviceUrl={process.env.REACT_APP_REPORT_ADDRESS}
            reportSource={{
              report: getReportFileName(reports, selectedReportID),
              parameters: {},
            }}
            viewerContainerStyle={{
              height: '100%',
              overflow: 'hidden',
              clear: 'both',
            }}
            viewMode='INTERACTIVE'
            scaleMode='SPECIFIC'
            scale={1.0}
            enableAccessibility={false}
          />
        </div>
      </div>
    </div>
  );
};
