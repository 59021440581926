import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from '@/core/redux/rootReducer';
import { rootSaga } from '@/core/redux/rootSaga';
import { sagaMiddleware } from '@/core/redux/sagas';

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
