import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { DEFAULT_LAYER_PADDING } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaGroupedList/VocationalTrainingAreaGroupedListGroup/VocationalTrainingAreaGroupedListGroup';
import { ICompleteAppointmentFormGroup } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/hooks/useCompleteAppointmentModalForm';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { ICompleteAppointmentItem } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';
import { colorTypes } from '@/styles/types';

interface ICompleteAppointmentGroupedListItem extends ICompleteAppointmentItem {
    layer: number;
    bgColor?: string;
    formPath?: string;
}

export const CompleteAppointmentGroupedListItem: React.FC<ICompleteAppointmentGroupedListItem> = ({
                                                                                                      text,
                                                                                                      fromTimestamp,
                                                                                                      toTimestamp,
                                                                                                      hours,
                                                                                                      note,
                                                                                                      type,
                                                                                                      formPath,
                                                                                                      id,
                                                                                                      layer,
                                                                                                      bgColor,
                                                                                                  }) => {
    const { watch, setValue, register } = useFormContext();

    const currentValues: ICompleteAppointmentFormGroup = formPath ? watch(formPath) : watch();

    const foundIndex =
        currentValues && currentValues.items
            ? currentValues?.items?.findIndex((item) => item.id === id)
            : 0;

    const basePath = `${formPath}.items`;

    const isParticipatedPath = `${formPath}.items.${foundIndex}.isParticipated`;
    const isUnderDirectionPath = `${formPath}.items.${foundIndex}.isUnderDirection`;
    const isAlmostIndependentPath = `${formPath}.items.${foundIndex}.isAlmostIndependent`;
    const isIndependentPath = `${formPath}.items.${foundIndex}.isIndependent`;

    const handleCheck = (formPath: string) => {
        if (!currentValues || !currentValues.items) {
            return;
        }

        const tempValues = [...currentValues.items];
        tempValues.forEach((item) => {
            item.isUnderDirection = false;
            item.isParticipated = false;
            item.isIndependent = false;
            item.isAlmostIndependent = false;
        });

        setValue(basePath, tempValues);
        setValue(formPath, true, { shouldDirty: true });
    };

    return (
        <Button
            buttonStyle={'Text'}
            buttonVariant={'Transparent'}
            className={`col-span-12 grid grid-cols-12 hover:bg-${colorTypes.HoverLight} py-1 px-[30px]`}
            style={{ backgroundColor: bgColor }}
        >
            <Typography
                className={'col-span-1 whitespace-normal'}
                style={{ paddingLeft: DEFAULT_LAYER_PADDING * (layer + 1) }}
                type={'16-M'}
                color={'Secondary'}
            >
                {type}
            </Typography>
            <Typography className={'col-span-3 whitespace-normal'} type={'16-M'} color={'Secondary'}>
                {text}
            </Typography>
            <Typography className={'col-span-1'} type={'16-M'} color={'Secondary'}>
                {fromTimestamp}
            </Typography>
            <Typography className={'col-span-1'} type={'16-M'} color={'Secondary'}>
                {toTimestamp}
            </Typography>
            <Typography className={'col-span-1'} type={'16-M'} color={'Secondary'}>
                {hours}
            </Typography>
            <Checkbox
                name={isParticipatedPath}
                onClick={() => handleCheck(isParticipatedPath)}
                preventDefault={true}
            />
            <Checkbox
                name={isUnderDirectionPath}
                onClick={() => handleCheck(isUnderDirectionPath)}
                preventDefault={true}
            />
            <Checkbox
                name={isAlmostIndependentPath}
                onClick={() => handleCheck(isAlmostIndependentPath)}
                preventDefault={true}
            />
            <Checkbox
                name={isIndependentPath}
                onClick={() => handleCheck(isIndependentPath)}
                preventDefault={true}
            />
            <TextArea
                control={register(`${formPath}.items.${foundIndex}.comment`)}
                className={'col-span-2'}
            >
                {note}
            </TextArea>
        </Button>
    );
};
