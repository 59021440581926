import React, { useState } from 'react';

import { Button } from '@/components/Button/Button';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import {
  ICompetenceChartsVariant,
  performanceProfileActions,
} from '@/core/redux/slices/functions/performanceProfile/slice';
import { getLetter } from '@/core/utils/commonUtils';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import styles from './styles.module.scss';

interface IChartsList {
  reviewVariants?: ICompetenceChartsVariant[];
  selectedIds: number[];
}

export const ChartsList: React.FC<IChartsList> = ({ reviewVariants, selectedIds }) => {
  const [activeVariantID, setActiveVariantID] = useState<number | null>(null);

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const loadingStatus = useAppSelector(performanceProfileSelectors.competenceChartsDataLock);

  const dispatch = useAppDispatch();

  const handleClick = (id: number) => {
    if (!selectedPerson) {
      return;
    }

    setActiveVariantID(id);
    dispatch(
      performanceProfileActions.fetchCompetenceChartsData({
        personID: selectedPerson?.id,
        parentID: id,
        competenceAnalysisIds: selectedIds,
      })
    );
  };

  const handleBack = () => {
    setActiveVariantID(null);

    if (!selectedPerson) {
      return;
    }
    dispatch(
      performanceProfileActions.fetchCompetenceChartsData({
        personID: selectedPerson.id,
        parentID: 0,
        competenceAnalysisIds: selectedIds,
      })
    );
  };

  return (
    <div
      className={twMerge(
        styles.container,
        `flex flex-col justify-between p-4 border rounded-default border-${colorTypes.Pressed}`
      )}
    >
      <Skeleton
        trigger={loadingStatus === LoadingStatus.NEVER || loadingStatus === LoadingStatus.LOADING}
        rows={3}
      >
        {reviewVariants && (
          <>
            <div className={'flex flex-col gap-2'}>
              {reviewVariants.map((reviewVariant, index) => (
                <Button
                  key={reviewVariant.id}
                  buttonStyle={'Text'}
                  buttonVariant={'Transparent'}
                  disabled={!reviewVariant.hasChilds}
                  className={`text-${colorTypes.Disabled} hover:text-${colorTypes.Blue}`}
                  onClick={() => handleClick(reviewVariant.id)}
                >
                  {`${getLetter(index)} - ${reviewVariant.name}`}
                </Button>
              ))}
            </div>
            {activeVariantID && (
              <Button className={'justify-center mt-[50px]'} onClick={handleBack}>
                Back
              </Button>
            )}
          </>
        )}
      </Skeleton>
    </div>
  );
};
