import React from 'react';

import { WidgetControlModalContent } from '@/components/Modals/Modals/widgets/WidgetControlModal/WidgetControlModalContent/WidgetControlModal';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { widgetModalsSelectors } from '@/core/redux/slices/modalsSlice/widgets/selector';
import { widgetsModalsActions } from '@/core/redux/slices/modalsSlice/widgets/widgetsModalsSlice';

export const WidgetControlModal: React.FC = () => {
  const isOpened = useAppSelector(widgetModalsSelectors.widgetControlModal);

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(widgetsModalsActions.closeWidgetControlModal());
  };

  if (isOpened) {
    return (
      <Modal onExit={handleExit}>
        <WidgetControlModalContent />
      </Modal>
    );
  }

  return null;
};
