import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';
import { testingResultModalsActions } from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';

export const TestPerformerSelect: React.FC = () => {
  const [selected, setSelected] = useState<string | undefined>();

  const testPerformerList = useAppSelector(testingResultModalsSelectors.testPerformerList);
  const formIsDirty = useFormContext().formState.isDirty;

  const formContext = useFormContext();

  const initialValues: ISelectItem[] = testPerformerList.map((testPerformer) => ({
    id: testPerformer.id.toString(),
    component: <Typography>{testPerformer.value}</Typography>,
    showCheckIcon: false,
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      testingResultModalsActions.fetchTestPerformerList(
        DropdownItemsByFetch.TEST_PROCEDURE_PERFORMER
      )
    );
  }, []);

  const handleChange = (id: string) => {
    formContext.setValue('performedBy.id', id, { shouldDirty: true });
  };

  useEffect(() => {
    if (!formIsDirty) {
      setSelected(formContext.watch('performedBy.id'));
    }
  }, [formIsDirty]);

  return (
    <Select
      values={initialValues}
      initialValueID={formContext.watch('performedBy.id')}
      onChange={handleChange}
      selectedExternal={selected}
      setSelectedExternal={setSelected}
    />
  );
};
