import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.administrationFunctions.checkList;

//checklist
const checklist = createSelector([selector], (state) => state.checklist);
const checklistLock = createSelector([selector], (state) => state.checklistLock);

//checklist groups
const checklistGroups = createSelector([selector], (state) => state.checklistGroups);
const checklistGroupsLock = createSelector([selector], (state) => state.checklistGroupsLock);

//checklist checkpoints
const checklistCheckpoints = createSelector([selector], (state) => state.checklistCheckpoints);
const checklistCheckpointsLock = createSelector(
  [selector],
  (state) => state.checklistCheckpointsLock
);

//dropdowns
const correspondingDocuments = createSelector([selector], (state) => state.correspondingDocuments);
const disabilityTypes = createSelector([selector], (state) => state.disabilityTypes);

export const administrationCheckListSelectors = {
  checklist,
  checklistLock,
  checklistGroups,
  checklistGroupsLock,
  checklistCheckpoints,
  checklistCheckpointsLock,
  correspondingDocuments,
  disabilityTypes,
};
