import { PayloadAction } from '@reduxjs/toolkit';
import { type NormalizeOAS, type OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  IDevelopmentReportReview,
  IDevelopmentReportReviewSupportForm,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/ReviewSupport/helper/reviewSupportForm';
import { authAdd, restCall } from '@/core/clients/rest';
import {
  DevelopmentReportReviewTypes,
} from '@/core/enums/functions/developmentReport/developmentReportReviewTypesEnum';
import {
  developmentReportReviewSupportActions,
  IDevelopmentReportReviewSupportFetchPayload,
  IDevelopmentReportReviewSupportUpdatePayload,
  IImportedDevelopmentReportReviewSupportFetchPayload,
} from '@/core/redux/slices/functions/developmentReport/developmentReportReviewSupportSlice/slice';
import { getSelectedOption } from '@/core/utils/commonUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type FetchDevelopmentReportViewSupportRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/review',
  'get'
>;


type DevelopmentReportViewSupportResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/development_report/review',
  'get',
  '200'
>;

type FetchImportedDevelopmentReportViewSupportRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/review/import',
  'get'
>;


type ImportedDevelopmentReportViewSupportResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/development_report/review/import',
  'get',
  '200'
>;

type UpdateDevelopmentReportViewSupportRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/review',
  'post'
>;

const mapDevelopmentReportReviewSupportResponse = (response: DevelopmentReportViewSupportResponse | ImportedDevelopmentReportViewSupportResponse):IDevelopmentReportReviewSupportForm  => {
  return {
    reviews: response.items.map<IDevelopmentReportReview>((item) => {

      const options: Record<DevelopmentReportReviewTypes, boolean> = {
        [DevelopmentReportReviewTypes.Yes]: Boolean(item.yes_flag),
        [DevelopmentReportReviewTypes.No]: Boolean(item.no_flag),
        [DevelopmentReportReviewTypes.Partially]: Boolean(item.partially_flag),
        [DevelopmentReportReviewTypes.Default]: false
      };

      const selectedReview = getSelectedOption(options) || DevelopmentReportReviewTypes.Default

      return {
        comment: item.comment,
        goal: item.goal,
        lfdNr: item.lfd_nr,
        smartGoalId: item.smart_goal_id,
        selectedReview: selectedReview,
      };
    }),
  };
}

function* fetchDevelopmentReportReviewSupport(action: PayloadAction<IDevelopmentReportReviewSupportFetchPayload>): Generator<any, void, DevelopmentReportViewSupportResponse> {
  const { developmentReportID } = action.payload;

  try {
    yield put(developmentReportReviewSupportActions.setDevelopmentReportReviewSupportLock(LoadingStatus.LOADING))
    const request: FetchDevelopmentReportViewSupportRequest = {
      query: {
        development_report_id: developmentReportID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/development_report/review', 'get', request);

    const mappedResponse: IDevelopmentReportReviewSupportForm = mapDevelopmentReportReviewSupportResponse(response)

    yield put(developmentReportReviewSupportActions.setDevelopmentReportReviewSupport(mappedResponse));
    yield put(developmentReportReviewSupportActions.setDevelopmentReportReviewSupportLock(LoadingStatus.LOADED))
  } catch (error) {
    yield put(developmentReportReviewSupportActions.setDevelopmentReportReviewSupportLock(LoadingStatus.ERROR))
    console.log('Error on development report review support fetching', error);
  }
}

function* updateDevelopmentReportReviewSupport(action: PayloadAction<IDevelopmentReportReviewSupportUpdatePayload>): Generator<any, void, any> {
  const { developmentReportID, personID, formValues } = action.payload;

  try {
     const request: UpdateDevelopmentReportViewSupportRequest = {
       json: {
         person_id: personID,
         development_report_id: developmentReportID,
         items: formValues.reviews.map<UpdateDevelopmentReportViewSupportRequest['json']['items'][0]>((review) => ({
           lfd_nr: review.lfdNr || -1 ,
           goal:  review.goal,
           yes_flag:  review.selectedReview === DevelopmentReportReviewTypes.Yes,
           partially_flag:  review.selectedReview === DevelopmentReportReviewTypes.Partially,
           no_flag: review.selectedReview === DevelopmentReportReviewTypes.No,
           comment: review.comment || '',
           smart_goal_id:  review.smartGoalId,
         }))
       },
       ...authAdd(),
     };

    yield call(restCall, '/development_report/review', 'post', request);
  } catch (error) {
    console.log('Error on development report review support updating', error);
  }
}

function* fetchImportedDevelopmentReportReviewSupport(action: PayloadAction<IImportedDevelopmentReportReviewSupportFetchPayload>): Generator<any, void, ImportedDevelopmentReportViewSupportResponse> {
  const { personID } = action.payload;

  try {
    yield put(developmentReportReviewSupportActions.setImportedDevelopmentReportReviewSupportLock(LoadingStatus.LOADING))
    const request: FetchImportedDevelopmentReportViewSupportRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/development_report/review/import', 'get', request);

    const mappedResponse: IDevelopmentReportReviewSupportForm = mapDevelopmentReportReviewSupportResponse(response)

    yield put(developmentReportReviewSupportActions.setImportedDevelopmentReportReviewSupport(mappedResponse));
    yield put(developmentReportReviewSupportActions.setImportedDevelopmentReportReviewSupportLock(LoadingStatus.LOADED))
  } catch (error) {
    yield put(developmentReportReviewSupportActions.setImportedDevelopmentReportReviewSupportLock(LoadingStatus.ERROR))
    console.log('Error on development report review support importing', error);
  }
}

export const developmentReportReviewSupportSagas = [
  takeLatest(developmentReportReviewSupportActions.fetchDevelopmentReportReviewSupport, fetchDevelopmentReportReviewSupport),
  takeLatest(developmentReportReviewSupportActions.updateDevelopmentReportReviewSupport, updateDevelopmentReportReviewSupport),
  takeLatest(developmentReportReviewSupportActions.fetchImportedDevelopmentReportReviewSupport, fetchImportedDevelopmentReportReviewSupport),
];
