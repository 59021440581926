import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { SerialAppointmentButton } from '@/components/functions/ActivityPlanning/components/SerialAppointmentButton/SerialAppointmentButton';
import { timeMask } from '@/components/Input/helpers/timeMask';
import { Input } from '@/components/Input/Input';
import { AppointmentDetailsFooter } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/AppointmentDetailsFooter/AppointmentDetailsFooter';
import { AppointmentDetailsLocationSelect } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/AppointmentDetailsLocationSelect/AppointmentDetailsLocationSelect';
import { AppointmentDetailsTypeSelect } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/AppointmentDetailsTypeSelect/AppointmentDetailsTypeSelect';
import { appointmentDetailsSchemaBuilder } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/hooks/appointmentDetailsValidator';
import { useAppointmentDetailsForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/hooks/useAppointmentDetailsForm';
import { ImplementationAidsSelect } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/ImplementationAidsSelect/ImplementationAidsSelect';
import { SchemaProvider } from '@/components/SchemaContext/SchemaContext';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { useDateFormSwitch } from '@/core/hooks/useDateFormSwitch';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsSelectors } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/selectors';
import { activityPlanningAppointmentDetailsActions } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';
import { activityPlaningAppointmentDetailsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

export const AppointmentDetails: React.FC = () => {
  const { t: appointmentDetailTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentDetails',
  });

  const { payload } = useAppSelector(
    activityPlaningAppointmentDetailsModalsSelectors.appointmentDetailsModal
  );

  const loadingStatus = useAppSelector(
    activityPlaningAppointmentDetailsSelectors.appointmentDetailLock
  );

  const dispatch = useAppDispatch();

  const { form, appointmentDetails, handleSubmit, isStartEndSameDate } =
    useAppointmentDetailsForm(payload);

  useDateFormSwitch(form, 'startDate', 'endDate');

  useEffect(() => {
    if (!payload || !payload.measureID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentDetailsActions.fetchAppointmentDetailsFields({
        measureID: payload.measureID,
      })
    );
  }, [payload]);

  return (
    <div className={'flex-grow flex flex-col gap-2 p-5 overflow-hidden w-[600px]'}>
      <SchemaProvider schema={appointmentDetailsSchemaBuilder(appointmentDetailTranslations)}>
        <Typography type={'H3'}>{appointmentDetailTranslations('title.label')}</Typography>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className={'flex-grow flex flex-col justify-between gap-3  overflow-hidden'}
          >
            <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
              <div className={'flex flex-col gap-2 flex-1 overflow-y-auto'}>
                <div className={'flex flex-row gap-2'}>
                  <Checkbox name={'appointmentOnlyPlanned'} />
                  <Typography>
                    {appointmentDetailTranslations('fields.appointmentOnlyPlanned.label')}
                  </Typography>
                </div>
                <div className={'grid grid-cols-[max-content,_max-content] items-center gap-5'}>
                  <DatePicker
                    control={form.register('startDate')}
                    label={appointmentDetailTranslations('fields.startDate.label')}
                  />
                  {isStartEndSameDate ? (
                    <Input
                      control={form.register('startTime')}
                      handleChange={timeMask}
                      label={appointmentDetailTranslations('fields.startTime.label')}
                    />
                  ) : (
                    <div />
                  )}
                  <DatePicker
                    control={form.register('endDate')}
                    label={appointmentDetailTranslations('fields.endDate.label')}
                  />
                  {isStartEndSameDate ? (
                    <Input
                      control={form.register('endTime')}
                      handleChange={timeMask}
                      label={appointmentDetailTranslations('fields.endTime.label')}
                    />
                  ) : (
                    <Input
                      control={form.register('duration')}
                      type={'number'}
                      label={appointmentDetailTranslations('fields.hours.label')}
                    />
                  )}
                </div>
                <div className={'flex flex-col gap-2'}>
                  <SerialAppointmentButton form={form} context={'modalVersion'} />
                  <Input
                    control={form.register('measureTitle')}
                    label={appointmentDetailTranslations('fields.measureTitle.label')}
                  />
                  <AppointmentDetailsLocationSelect appointmentDetails={appointmentDetails} />
                  <ImplementationAidsSelect appointmentDetails={appointmentDetails} />
                  <AppointmentDetailsTypeSelect />
                  <Input
                    control={form.register('places')}
                    label={appointmentDetailTranslations('fields.places.label')}
                  />
                  <Input
                    control={form.register('responsible')}
                    readOnly={true}
                    label={appointmentDetailTranslations('fields.responsible.label')}
                  />
                  <TextArea
                    control={form.register('appointmentInfo')}
                    label={appointmentDetailTranslations('fields.appointmentInfo.label')}
                  />
                </div>
              </div>
            </Skeleton>
            <AppointmentDetailsFooter
              appointmentDetails={appointmentDetails}
              form={form}
              payload={payload}
            />
          </form>
        </FormProvider>
      </SchemaProvider>
    </div>
  );
};
