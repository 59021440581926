import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { ArrowIcon } from '@/components/Icons/ArrowIcon';
import { Typography } from '@/components/Typography/Typography';
import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import styles from '@/components/views/person/PersonPage/DepartmentView/styles.module.scss';
import { SortingBy } from '@/types/sortingBy';

export const DepartmentSorting: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.label' });
  const [sortedBy, setSortBy] = usePersonPageStoreSelector((store) => store.departmentsSortedBy);

  const handleSortBy = () => {
    setSortBy({
      departmentsSortedBy:
        sortedBy === SortingBy.nameAscend ? SortingBy.nameDescend : SortingBy.nameAscend,
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'enter') {
      handleSortBy();
    }
  };

  return (
    <div
      className='flex flex-row items-center cursor-pointer'
      onClick={handleSortBy}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      role={'button'}
    >
      <Typography type='16-R' color='Secondary'>
        {t('alphabetical')}
      </Typography>
      <motion.div
        className={clsx(styles.arrowButton, styles.filterButton, 'ms-2')}
        animate={{ rotate: sortedBy === SortingBy.nameAscend ? 0 : 180 }}
      >
        <ArrowIcon />
      </motion.div>
    </div>
  );
};
