import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.organizationalUnits;

const organizationalUnits = createSelector([selector], (state) => state.organizationalUnitsList);
const organizationalUnitsLock = createSelector(
  [selector],
  (state) => state.organizationalUnitsListLock
);

const additionalPersonRequirement = createSelector(
  [selector],
  (state) => state.additionalPersonRequirement
);
const additionalPersonRequirementLock = createSelector(
  [selector],
  (state) => state.additionalPersonRequirementLock
);

export const organizationalUnitsSelectors = {
  organizationalUnits,
  organizationalUnitsLock,

  additionalPersonRequirement,
  additionalPersonRequirementLock,
};
