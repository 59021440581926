import React from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionItem } from '@/components/forms/ParticipantExpectation/QuestionItem/QuestionItem';
import { Typography } from '@/components/Typography/Typography';

export const ExpectationsAndMotivation: React.FC = () => {
  const { t: expectationsAndMotivationTranslations } = useTranslation('participantExpectation', {
    keyPrefix: 'expectationsAndMotivation',
  });

  return (
    <div>
      <Typography type={'H3'}>{expectationsAndMotivationTranslations('title.label')}</Typography>
      <div className={'flex flex-col mt-4 gap-4'}>
        <QuestionItem
          title={expectationsAndMotivationTranslations('fields.wem1.label')}
          fieldName={'wem1'}
        />
        <QuestionItem
          title={expectationsAndMotivationTranslations('fields.wem2.label')}
          fieldName={'wem2'}
        />
        <QuestionItem
          title={expectationsAndMotivationTranslations('fields.wem3.label')}
          fieldName={'wem3'}
        />
      </div>
    </div>
  );
};
