import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionFooter } from '@/components/wrapper/FunctionWrapper/FunctionFooter/FunctionFooter';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsSelectors } from '@/core/redux/slices/functions/organizationalUnits/selectors';
import {
  IOrganizationalUnit,
  organizationalUnitsActions,
} from '@/core/redux/slices/functions/organizationalUnits/slice';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IOrganizationalUnitsForm {
  comment?: string | null;
}

export const OrganizationalUnits: React.FC = () => {
  const { t: organizationalUnitsTranslations } = useTranslation('organizationalUnits');

  const [selectedUnit, setSelectedUnit] = useState<IOrganizationalUnit | null>(null);

  const organizationalUnits = useAppSelector(organizationalUnitsSelectors.organizationalUnits);
  const loadingStatus = useAppSelector(organizationalUnitsSelectors.organizationalUnitsLock);

  const { selectedPersonID } = useSelectedPerson();

  const form = useForm<IOrganizationalUnitsForm>();

  const dispatch = useAppDispatch();

  const columns: ITableColumn[] = [
    {
      accessor: 'name',
      header: organizationalUnitsTranslations('table.header.name.label'),
    },
    {
      accessor: 'fromTimestamp',
      header: organizationalUnitsTranslations('table.header.from.label'),
    },
    { accessor: 'toTimestamp', header: organizationalUnitsTranslations('table.header.to.label') },
  ];

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      organizationalUnitsActions.fetchOrganizationalUnits({
        personID: selectedPersonID,
      })
    );

    return () => {
      dispatch(organizationalUnitsActions.setOrganizationalUnits([]));
    };
  }, [selectedPersonID]);

  useEffect(() => {
    if (!organizationalUnits || !selectedUnit) {
      return;
    }

    if (loadingStatus === LoadingStatus.LOADING) {
      setSelectedUnit(null);
    }

    form.reset({
      comment: selectedUnit.comment,
    });
  }, [organizationalUnits, selectedUnit, loadingStatus]);

  const handleSelect = (id: string) => {
    if (!organizationalUnits) {
      return;
    }

    if (!id) {
      setSelectedUnit(null);
      return;
    }

    const foundElement = organizationalUnits.find((unit) => unit.id === Number(id));

    if (!foundElement) {
      return;
    }

    setSelectedUnit(foundElement);
  };

  const handleCreate = () => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      organizationalUnitsModalsActions.openCreateOrganizationalUnitModal({
        personID: selectedPersonID,
      })
    );
  };
  const handleEdit = () => {
    if (!selectedPersonID || !selectedUnit) {
      return;
    }

    dispatch(
      organizationalUnitsModalsActions.openUpdateOrganizationalUnitModal({
        personID: selectedPersonID,
        organizationalUnit: selectedUnit,
        unitID: selectedUnit.unitID,
      })
    );
  };

  const handleDelete = () => {
    if (!selectedPersonID || !selectedUnit) {
      return;
    }

    dispatch(
      organizationalUnitsActions.deleteOrganizationalUnit({
        personID: selectedPersonID,
        organizationalUnit: selectedUnit,
      })
    );
  };

  return (
    <FunctionWrapper>
      <FunctionContent withFooter={true}>
        <div className={'flex flex-col mt-5 gap-3'}>
          <Typography type={'H3'} className={'px-7'}>
            {organizationalUnitsTranslations('title.label')}
          </Typography>
          <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
            <div className={'flex-grow'}>
              <Table
                columns={columns}
                data={organizationalUnits}
                initialSelectedState={[selectedUnit?.id]}
                singleSelect={true}
                onSingleSelectChange={handleSelect}
              />
            </div>
            <div className={'px-7'}>
              <FormProvider {...form}>
                <TextArea
                  control={form.register('comment')}
                  label={organizationalUnitsTranslations('fields.comment.label')}
                />
              </FormProvider>
            </div>
          </Skeleton>
        </div>
      </FunctionContent>
      <FunctionFooter>
        <Button onClick={handleCreate}>
          {organizationalUnitsTranslations('buttons.create.label')}
        </Button>
        {selectedUnit && (
          <>
            <Button onClick={handleEdit}>
              {organizationalUnitsTranslations('buttons.edit.label')}
            </Button>
            <Button onClick={handleDelete}>
              {organizationalUnitsTranslations('buttons.delete.label')}
            </Button>
          </>
        )}
      </FunctionFooter>
    </FunctionWrapper>
  );
};
