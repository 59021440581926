import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { Button } from '@/components/Button/Button';
import { EditTextIcon } from '@/components/Icons/EditTextIcon';
import { TrashIcon } from '@/components/Icons/TrashIcon';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { evaluateGoalsActions } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/evaluateGoalsSlice';
import { evaluateGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/selectors';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { evaluateGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/evaluateGoals/evaluateGoalsModalSlice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

export const GoalCheckSection: React.FC = () => {
  const { t: evaluateGoalsTranslations } = useTranslation('evaluateGoals');

  const dispatch = useAppDispatch();

  const data = useAppSelector(evaluateGoalsSelectors.goalChecks);
  const selectedSmartGoalID = useAppSelector(smartGoalsSelectors.selectedSmartGoal);

  const createGoalCheckLock = useAppSelector(evaluateGoalsSelectors.createGoalCheckLock);
  const updateGoalCheckLock = useAppSelector(evaluateGoalsSelectors.updateGoalCheckLock);

  const isGoalCheckUpdated =
    createGoalCheckLock === LoadingStatus.LOADED || updateGoalCheckLock === LoadingStatus.LOADED;

  const openDisplayGoalCheckModal = (id: number) => {
    if (!selectedSmartGoalID) {
      return;
    }

    dispatch(
      evaluateGoalsModalActions.openDisplayGoalCheckModal({
        smartGoalID: selectedSmartGoalID,
        goalCheckID: id,
      })
    );
  };

  const handleDeleteGoalCheck = (id: number) => {
    if (!selectedSmartGoalID) {
      return;
    }

    dispatch(
      evaluateGoalsActions.deleteGoalCheck({
        smartGoalID: selectedSmartGoalID,
        goalCheckID: id,
      })
    );
  };

  const columns: ITableColumn[] = [
    {
      header: evaluateGoalsTranslations('columns.checkedAtDate.label'),
      accessor: 'checkedAtDate',
    },
    {
      header: evaluateGoalsTranslations('columns.checkedBy.label'),
      accessor: 'checkedBy',
    },
    {
      header: evaluateGoalsTranslations('columns.achievementLevel.label'),
      accessor: 'achievementLevel',
    },
    {
      header: evaluateGoalsTranslations('columns.note.label'),
      accessor: 'note',
    },
    {
      header: evaluateGoalsTranslations('columns.nextAction.label'),
      accessor: 'nextAction',
    },
    {
      header: evaluateGoalsTranslations('columns.nextCheckDate.label'),
      accessor: 'nextCheckDate',
    },
    {
      header: '',
      accessor: '',
      isSortable: false,
      className: 'py-1 px-5 pr-6',

      template: (_, row) => {
        const isEditable = row.id === data[0]?.id;

        return (
          <div className={'flex flex-row items-center'}>
            {isEditable && (
              <>
                <Button
                  data-tooltip-id='editCheck'
                  data-tooltip-content={evaluateGoalsTranslations('buttons.editCheck.label')}
                  buttonVariant={'Icon'}
                  buttonStyle={'Icon'}
                  className={twMerge(
                    `text-${colorTypes.Grey}  p-2 hover:bg-stroke rounded-md cursor-pointer`
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    openDisplayGoalCheckModal(row.id);
                  }}
                >
                  <EditTextIcon className={'w-[24px] h-[24px]'} />
                </Button>
                <Tooltip id={'editCheck'} style={{ zIndex: 49 }} />
                <>
                  <Button
                    data-tooltip-id='deleteCheck'
                    data-tooltip-content={evaluateGoalsTranslations('buttons.deleteCheck.label')}
                    buttonVariant={'Icon'}
                    buttonStyle={'Icon'}
                    className={twMerge(
                      `text-${colorTypes.Grey}  p-2 hover:bg-stroke rounded-md cursor-pointer`
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteGoalCheck(row.id);
                    }}
                  >
                    <TrashIcon className={'w-[24px] h-[24px]'} />
                  </Button>
                  <Tooltip id={'deleteCheck'} style={{ zIndex: 49 }} />
                </>
              </>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (!selectedSmartGoalID) {
      return;
    }

    dispatch(evaluateGoalsActions.setSelectedGoalCheck(null));
    dispatch(
      evaluateGoalsActions.fetchGoalChecks({
        smartGoalID: selectedSmartGoalID,
      })
    );
  }, [selectedSmartGoalID]);

  useEffect(() => {
    if (!isGoalCheckUpdated || !selectedSmartGoalID) {
      return;
    }

    dispatch(
      evaluateGoalsActions.fetchGoalChecks({
        smartGoalID: selectedSmartGoalID,
      })
    );
  }, [isGoalCheckUpdated]);

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        selectable={false}
        onSingleSelectChange={(selected) =>
          dispatch(evaluateGoalsActions.setSelectedGoalCheck(selected))
        }
      />
    </div>
  );
};
