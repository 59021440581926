import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../types';

const selector = (state: IRootState) => state.specialists;
const specialists = createSelector([selector], (state) => state.specialists);
const selectedSpecialist = createSelector([selector], (state) => state.selectedSpecialist);


export const specialistsSelectors = {
    specialists,
    selectedSpecialist
};
