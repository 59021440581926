import React from 'react';

import { IIcon } from '@/types/icon';

export const ArrowRightIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M8 16L12.6942 10.8363C12.8676 10.6456 12.8676 10.3544 12.6942 10.1637L8 5'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);
