import React, { CSSProperties } from 'react';
import { Tooltip } from 'react-tooltip';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

interface ILeftSidebarMenuItemTooltip {
  id: string;
  tooltipMessage: string;
}

export const LeftSidebarMenuItemTooltip: React.FC<ILeftSidebarMenuItemTooltip> = ({
  id,
  tooltipMessage,
}) => {
  const tooltipStyles: CSSProperties = {
    backgroundColor: 'transparent',
    zIndex: 100,
    padding: 0,
  };

  return (
    <Tooltip
      anchorSelect={`#${id}`}
      place={'right'}
      positionStrategy={'fixed'}
      style={tooltipStyles}
      className={'bg-blue-300'}
      noArrow={true}
      opacity={1}
    >
      <div className={`bg-${colorTypes.HoverLight} rounded-default p-2`}>
        <Typography>{tooltipMessage}</Typography>
      </div>
    </Tooltip>
  );
};
