import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';

export const Comments: React.FC = () => {
  const { t: commentsTranslations } = useTranslation('participantExpectation', {
    keyPrefix: 'comments',
  });

  const { control } = useFormContext();

  return (
    <div className={'grid grid-cols-5'}>
      <Typography>{commentsTranslations('fields.notes.label')}</Typography>
      <TextArea control={control.register('notes')} containerClassName={'col-span-4'} />
    </div>
  );
};
