import React from 'react';

export const CheckedCheckboxIcon: React.FC = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='3' y='3' width='18' height='18' rx='2' fill='currentColor' />
    <path
      d='M7 11.7941L10.312 15.1256C10.5142 15.329 10.8457 15.321 11.0378 15.1081L17 8.5'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);

export const UnCheckedCheckboxIcon: React.FC = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z'
      fill='currentColor'
    />
  </svg>
);

export const IntermediateCheckboxIcon: React.FC = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='3' y='3' width='18' height='18' rx='2' fill='currentColor' />
    <path d='M7 12H17' stroke='white' strokeWidth='2' strokeLinecap='round' />
  </svg>
);
