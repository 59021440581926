import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { IClipboard } from '@/components/Table/CopyPopup/CopyPopup';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  addressesActions,
  IAddressInfo,
} from '@/core/redux/slices/functions/personGeneralData/addresses/addressesSlice';
import { addressesSelectors } from '@/core/redux/slices/functions/personGeneralData/addresses/selectors';
import { personGeneralDataModalsActions } from '@/core/redux/slices/modalsSlice/functions/personGeneralData/personGeneralDataModalsSlice';

interface IAddresses {
  personID?: number;
}

export const Addresses: React.FC<IAddresses> = ({ personID }) => {
  const { t: addressesTranslations } = useTranslation('addresses');

  const addressData = useAppSelector(addressesSelectors.addressData);

  const dispatch = useAppDispatch();

  const [selectedAddressID, setSelectedAddressID] = useState<number | null>(null);

  const checkIsDeletable = (): boolean => {
    if (!selectedAddressID) {
      return false;
    }

    const foundAddress = addressData.find((address) => address.id === selectedAddressID);

    if (!foundAddress) {
      return false;
    }

    return foundAddress.canDelete;
  };

  const columns: ITableColumn[] = [
    {
      header: addressesTranslations('columns.addresseeType.label'),
      accessor: 'addresseeType',
      className: 'w-1/4',
    },
    {
      header: addressesTranslations('columns.name.label'),
      accessor: 'name',
      className: 'w-1/4',
    },
    {
      header: addressesTranslations('columns.address.label'),
      accessor: 'address',
      className: 'w-1/4',
    },
    {
      header: addressesTranslations('columns.telephoneNumber.label'),
      accessor: 'telephoneNumber',
      className: 'w-1/4',
      template: (value: string | null, rowData: IAddressInfo) => {
        return (
          <>
            <div>
              {addressesTranslations('columns.telephoneNumber.label')}: {value ?? '--'}
            </div>
            <div>
              {addressesTranslations('columns.cellPhoneNumber.label')}:{' '}
              {rowData.cellPhoneNumber ?? '--'}
            </div>
            <div>
              {addressesTranslations('columns.email.label')}: {rowData.email ?? '--'}
            </div>
          </>
        );
      },
    },
    {
      header: 'Gb',
      accessor: 'isLegalGuardian',
      template: (value: boolean) => {
        return <CheckboxUI checked={Boolean(value)} readOnly />;
      },
    },
  ];

  const clipboard: IClipboard[] = [
    {
      label: addressesTranslations('columns.name.label'),
      copyTemplate: (rowData: IAddressInfo) => rowData.name,
    },
    {
      label: addressesTranslations('columns.address.label'),
      copyTemplate: (rowData: IAddressInfo) => rowData.address,
    },
    {
      label: `${addressesTranslations('columns.name.label')} + ${addressesTranslations(
        'columns.address.label'
      )}`,
      copyTemplate: (rowData: IAddressInfo) => {
        if (!rowData.name || !rowData.address) {
          return;
        }

        return `${rowData.name} + ${rowData.address}`;
      },
    },
    {
      label: addressesTranslations('columns.telephoneNumber.label'),
      copyTemplate: (rowData: IAddressInfo) => rowData.telephoneNumber,
    },
    {
      label: addressesTranslations('columns.cellPhoneNumber.label'),
      copyTemplate: (rowData: IAddressInfo) => rowData.cellPhoneNumber,
    },
    {
      label: addressesTranslations('columns.email.label'),
      copyTemplate: (rowData: IAddressInfo) => rowData.email,
    },
  ];

  useEffect(() => {
    if (!personID) {
      return;
    }

    dispatch(
      addressesActions.fetchAddressData({
        personID: personID,
      })
    );
  }, [personID]);

  const openDsiplayAddressModal = (addressID: number) => {
    if (!personID) {
      return;
    }

    dispatch(
      personGeneralDataModalsActions.openDisplayAddressModal({
        addressID: addressID,
        personID: personID,
      })
    );
  };

  const openDeleteAddressModal = (addressID: number) => {
    if (!personID) {
      return;
    }

    dispatch(
      personGeneralDataModalsActions.openDeleteAddressModal({
        addressID: addressID,
        personID: personID,
      })
    );
  };

  return (
    <>
      <div className='flex flex-col gap-6 w-full p-5 h-[calc(100vh_-_300px)] overflow-auto'>
        <Typography type='H3'>{addressesTranslations('title.label')}</Typography>
        <Table
          columns={columns}
          data={addressData}
          singleSelect
          onSingleSelectChange={(selected) => setSelectedAddressID(selected)}
          clipboard={clipboard}
        />
      </div>
      <div
        className={'flex flex-row px-7 py-default justify-start items-center gap-3 shadow-footer'}
      >
        <Button onClick={() => openDsiplayAddressModal(0)}>
          {addressesTranslations('buttons.create.label')}
        </Button>
        {selectedAddressID && (
          <>
            <Button onClick={() => openDsiplayAddressModal(selectedAddressID)}>
              {addressesTranslations('buttons.update.label')}
            </Button>
            {checkIsDeletable() && (
              <Button onClick={() => openDeleteAddressModal(selectedAddressID)}>
                {addressesTranslations('buttons.delete.label')}
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};
