import React from 'react';

import { WeekendButton } from '@/components/functions/Mobility/MobilityItem/WeekendButton';
import { WorkingDayButton } from '@/components/functions/Mobility/MobilityItem/WorkingDayButton';
import { Typography } from '@/components/Typography/Typography';
import { IMobilityItem } from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import { IMobilityDay } from '@/core/redux/slices/modalsSlice/functions/mobility/slice';

interface IMobilityItemProps extends IMobilityItem {
  personID?: number | null;
}

export const MobilityItem: React.FC<IMobilityItemProps> = ({
  name,
  arrival,
  departure,
  isWorkingDay,
  id,
  personID,
}) => {
  const day: IMobilityDay = {
    id: id,
    name: name,
    isWorkingDay: isWorkingDay,
  };

  return (
    <div className={'grid grid-cols-11 gap-4 items-center'}>
      <Typography type={'16-Sb'} className={'col-span-3'}>
        {name}
      </Typography>
      {isWorkingDay ? (
        <>
          <WorkingDayButton
            id={id}
            name={arrival}
            mobilityDay={day}
            context={'arrival'}
            className={'col-span-4'}
            personID={personID}
          />
          <WorkingDayButton
            id={id}
            name={departure}
            mobilityDay={day}
            context={'departure'}
            className={'col-span-4'}
            personID={personID}
          />
        </>
      ) : (
        <WeekendButton className={'col-span-4'} />
      )}
    </div>
  );
};
