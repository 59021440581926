import React from 'react';
import { useFormContext } from 'react-hook-form';

import { parseCompetenceAnalysisRating } from '@/business/validationRules/competenceAnalysis/competenceAnalysisRatingValidationRule';
import { Input } from '@/components/Input/Input';
import { TextArea } from '@/components/TextArea/TextArea';

export interface IOtherCompetenceCommentItem {
  fieldName: string;
}

export const OtherCompetenceCommentItem: React.FC<IOtherCompetenceCommentItem> = ({
  fieldName,
}) => {
  const { control } = useFormContext();

  const handleReviewChange = (previousValue: string, newValue: string) => {
    return parseCompetenceAnalysisRating(newValue) ? newValue : previousValue;
  };

  return (
    <div className={'flex flex-col gap-2 mt-4'}>
      <div className={'grid grid-cols-12 gap-4'}>
        <TextArea
          control={control.register(`${fieldName}Name`)}
          containerClassName={'col-span-4'}
        />
        <Input
          control={control.register(`${fieldName}Rating`)}
          containerClassName={'col-span-1 h-full'}
          wrapperClassName={'h-full'}
          className={'h-full text-center'}
          type={'number'}
          resetButton={false}
          handleChange={handleReviewChange}
        />
        <TextArea
          control={control.register(`${fieldName}Comment`)}
          containerClassName={'col-span-7'}
        />
      </div>
    </div>
  );
};
