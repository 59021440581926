import React from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { PrivateImage } from '@/components/PrivateImage/PrivateImage';
import { Typography } from '@/components/Typography/Typography';

import styles from './styles.module.scss';

const WidgetItem = ({ title, icon, name }: { title: string; icon?: string; name: string }) => {
  const formContext = useFormContext();
  const isChecked = formContext.watch(name);

  return (
    <div
      className={clsx(
        styles.container,
        'h-[100px] w-[250px] rounded-[10px] p-[16px] flex justify-between items-start',
        { [styles.checked]: isChecked }
      )}
    >
      <div className='flex items-center justify-center'>
        <PrivateImage id={icon || ''} alt='icon' className={'w-6 h-6'} />
        <label htmlFor={name} className={'cursor-pointer'}>
          <Typography type='H3' className={'ml-2'}>
            {title}
          </Typography>
        </label>
      </div>
      <div>
        <Checkbox name={name} className='ml-2' id={name} />
      </div>
    </div>
  );
};

export default WidgetItem;
