import { colorTypes, textTypes } from '@/styles/types';

export type InputVariants = keyof typeof inputVariants;

const inputVariants = {
  Default: {
    base: `bg-${colorTypes.Fields} text-${colorTypes.Primary} placeholder:text-${colorTypes.Disabled} ${textTypes['16-R']} border border-${colorTypes.Fields} rounded-default px-5 py-[10px]  outline-none w-full`,
    hovered: '',
    active: '',
    focused: `focus:border-${colorTypes.Blue}`,
    disabled: `disabled:text-${colorTypes.Disabled}`,
    readOnly: `read-only:text-${colorTypes.Disabled}`,
  },
  Search: {
    base: `text-${colorTypes.Primary} ${textTypes['16-R']} border border-${colorTypes.Border} rounded-default ps-[40px] pe-4 py-[10px] outline-none w-full`,
    hovered: '',
    active: '',
    focused: `focus:border-${colorTypes.Blue}`,
    disabled: `disabled:text-${colorTypes.Disabled}`,
    readOnly: ``,
  },
  WidgetSearch: {
    base: `bg-${colorTypes.Fields} text-${colorTypes.Primary} ${textTypes['16-R']} border border-${colorTypes.Fields} rounded-default ps-[52px] pe-4 py-[10px] outline-none w-full`,
    hovered: '',
    active: '',
    focused: `focus:border-${colorTypes.Blue}`,
    disabled: `disabled:text-${colorTypes.Disabled}`,
    readOnly: ``,
  },
  Date: {
    base: `bg-${colorTypes.Fields} text-${colorTypes.Primary} ${textTypes['16-R']} border border-${colorTypes.Fields} rounded-default px-5 py-[10px] outline-none w-full`,
    hovered: '',
    active: '',
    focused: `focus:border-${colorTypes.Blue}`,
    disabled: `disabled:text-${colorTypes.Disabled}`,
    readOnly: ``,
  },
  Password: {
    base: `bg-${colorTypes.Fields} text-${colorTypes.Primary} placeholder:text-${colorTypes.Disabled} ${textTypes['16-R']} border border-${colorTypes.Fields} rounded-default px-5 py-[10px]  outline-none w-full`,
    hovered: '',
    active: '',
    focused: `focus:border-${colorTypes.Blue}`,
    disabled: `disabled:text-${colorTypes.Disabled}`,
    readOnly: ``,
  },
  Outline: {
    base: `text-${colorTypes.Primary} placeholder:text-${colorTypes.Disabled} ${textTypes['16-R']} border-b border-${colorTypes.Tiffany} px-2 pt-[7px] outline-none w-full`,
    hovered: '',
    active: '',
    focused: `focus:border-${colorTypes.Orange}`,
    disabled: `disabled:text-${colorTypes.Disabled}`,
    readOnly: ``,
  },
};

export function geInputVariant(key: InputVariants): string {
  const { base, hovered, active, focused, disabled, readOnly } = inputVariants[key];

  return [base, hovered, active, focused, disabled, readOnly].join(' ');
}
