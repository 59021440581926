import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.diagnostics.typeOfDisability;

const typeOfDisability = createSelector([selector], (state) => state.typeOfDisability);
const typeOfDisabilityLock = createSelector([selector], (state) => state.typeOfDisabilityLock);
const typeOfDisabilityImportData = createSelector([selector], (state) => state.importData);
const typeOfDisabilityImportDataLock = createSelector([selector], (state) => state.importDataLock);
const updateLock = createSelector([selector], (state) => state.updateLock);

export const diagnosticsTypeOfDisabilitySelectors = {
  typeOfDisability,
  typeOfDisabilityLock,
  typeOfDisabilityImportData,
  typeOfDisabilityImportDataLock,
  updateLock,
};
