import { colorTypes, textTypes } from '@/styles/types';

export type SelectVariants = keyof typeof selectVariants;

const selectVariants = {
  Button: {
    base: `p-1 px-[10px] py-2`,
    hovered: `hover:bg-${colorTypes.Hover} hover:rounded-default`,
    active: '',
    focused: `focus:border-${colorTypes.Blue}`,
    disabled: `disabled:text-${colorTypes.Disabled}`,
    readOnly: ``,
  },
  Input: {
    base: `bg-${colorTypes.Fields} text-${colorTypes.Primary} placeholder:text-${colorTypes.Disabled} ${textTypes['16-R']} border border-${colorTypes.Fields} rounded-default px-5 py-[10px]  outline-none w-full`,
    hovered: '',
    active: '',
    focused: `focus:border-${colorTypes.Blue}`,
    disabled: `disabled:text-${colorTypes.Disabled}`,
    readOnly: ``,
  },
  QuestionnaireQuestionsMark: {
    base: `py-2 px-3 rounded-default border border-${colorTypes.Stroke} bg-white text-${colorTypes.Grey} gap-5 w-fit`,
    hovered: `hover:border-${colorTypes.Blue}`,
    active: '',
    focused: ``,
    disabled: ``,
    readOnly: ``,
  },
};

export function getSelectVariant(key: SelectVariants): string {
  const { base, hovered, active, focused, disabled, readOnly } = selectVariants[key];

  return [base, hovered, active, focused, disabled, readOnly].join(' ');
}
