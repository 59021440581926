import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { vocationalTrainingAreaActions } from '@/core/redux/slices/functions/vocationalTrainingArea/slice';

import { useVocationalTrainingAreaErrors } from '../../VocationalTrainingArea/hooks/useVocationalTrainingAreaErrors';
import { GoalsSection } from '../SmartGoals/GoalsSection/GoalsSection';
import { MeasuresSection } from '../SmartGoals/MeasuresSection/MeasuresSection';

export const MeasuresCarrying: React.FC = () => {
  const { t: measuresCarryingTranslations } = useTranslation('measuresCarrying');
  const { renderDialogContainer } = useVocationalTrainingAreaErrors();

  const dispatch = useAppDispatch();
  const selectedMeasures = useAppSelector(smartGoalsSelectors.selectedMeasures);
  const measures = useAppSelector(smartGoalsSelectors.measures);

  const handleCompleteMeasures = () => {
    const measuresToComplete = measures.filter((measure) => selectedMeasures.includes(measure.id));
    const measureToPersonIDs = measuresToComplete.map((item) => item.measureToPersonId);

    dispatch(vocationalTrainingAreaActions.completeMeasures(measureToPersonIDs));
  };

  const measureControls = selectedMeasures.length >= 2 ? (
    <div className={'flex gap-6 items-center'}>
      <div className='flex gap-1'>
        <Typography type='14-R'>{measuresCarryingTranslations('selected.label')}:</Typography>
        <Typography type='14-M'>
          {selectedMeasures.length} {measuresCarryingTranslations('measures.label')}
        </Typography>
      </div>
      <Button onClick={handleCompleteMeasures} className='text-sm h-9 px-5 py-2.5 leading-[140%]'>
        {measuresCarryingTranslations('buttons.completeMeasures.label')}
      </Button>
    </div>
  ) : null;

  return (
    <>
      <div className='h-full flex flex-col gap-4 py-5 px-8'>
        <GoalsSection className='basis-6/12 flex-grow' />
        <MeasuresSection controls={measureControls} className='basis-6/12 flex-grow' isControled />
      </div>
      {renderDialogContainer()}
    </>
  );
};
