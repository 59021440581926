import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { OtherCompetenceCommentItem } from '@/components/forms/CompetenceAnalysis/OtherCompetencies/OtherCompetenceCommentItem/OtherCompetenceCommentItem';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const OtherCompetencies: React.FC = () => {
  const { t: suitabilityTranslations } = useTranslation('competenceAnalysis', {
    keyPrefix: 'otherCompetencies',
  });

  return (
    <Accordion
      title={<Typography type={'H3'}>{suitabilityTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'flex flex-col gap-2 mt-4'}>
        <OtherCompetenceCommentItem fieldName={'otherCompetencies1'} />
        <OtherCompetenceCommentItem fieldName={'otherCompetencies2'} />
      </div>
    </Accordion>
  );
};
