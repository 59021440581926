import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';
import { testingResultModalsActions } from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';
import { LoadingStatus } from '@/types/loadingStatus';

export const DeleteAppliedTestProcedureModalContent: React.FC = () => {
  const { t: deleteAppliedTestProcedureTranslations } = useTranslation(
    'deleteAppliedTestProcedureModal'
  );
  const {
    isRender: isOpened,
    procedureID,
    deleteAppliedTestProcedureLock,
  } = useAppSelector(testingResultModalsSelectors.deleteAppliedTestingResultModal);

  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(testingResultModalsActions.closeDeleteAppliedTestProcedureModal());
  };

  const handleConfirm = () => {
    if (procedureID) {
      dispatch(testingResultModalsActions.deleteAppliedTestProcedure(procedureID));
    }
  };

  useEffect(() => {
    if (deleteAppliedTestProcedureLock === LoadingStatus.LOADED) {
      handleClose();
    }
  }, [deleteAppliedTestProcedureLock]);

  return (
    <Modal onExit={handleClose}>
      <div className={'flex flex-col gap-4'}>
        <Typography type={'H3'}>{deleteAppliedTestProcedureTranslations('title.label')}</Typography>
        <Typography>{deleteAppliedTestProcedureTranslations('warning.label')}</Typography>
        <div className={'flex flex-row justify-center gap-3 mt-4'}>
          <Button onClick={handleConfirm}>
            {deleteAppliedTestProcedureTranslations('buttons.yes.label')}
          </Button>
          <Button buttonVariant={'Secondary'} onClick={handleClose}>
            {deleteAppliedTestProcedureTranslations('buttons.no.label')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
