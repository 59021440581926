import React, { useEffect, useState } from 'react';

import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { simpleSelfAssesmentSelectors } from '@/core/redux/slices/functions/simpleSelfAssesment/selectors';
import { simpleSelfAssesmentActions } from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';

import { QuestionnaireAvailable } from './QuestionnaireAvailable/QuestionnaireAvailable';
import { QuestionnaireHistory } from './QuestionnaireHistory/QuestionnaireHistory';

export const SelfAssesment: React.FC = () => {
  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);

  const selectedPersonID = selectedPerson?.id;
  const availableQuestionnaires = useAppSelector(
    simpleSelfAssesmentSelectors.availableQuestionnaires
  );
  const personQuestionnaires = useAppSelector(simpleSelfAssesmentSelectors.personQuestionnaires);
  const personAnswers = useAppSelector(simpleSelfAssesmentSelectors.personAnswers);

  const [selectedM2sID, setSelectedM2sID] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }
    dispatch(
      simpleSelfAssesmentActions.fetchPersonAnswers({
        variables: {
          maID: selectedPersonID,
        },
      })
    );
  }, [selectedPersonID]);

  useEffect(() => {
    if (!selectedPersonID || !availableQuestionnaires || !personAnswers) {
      return;
    }
    dispatch(
      simpleSelfAssesmentActions.fetchPersonQuestionnaires({
        variables: {
          maID: selectedPersonID,
        },
        availableQuestionnaires,
        personAnswers,
      })
    );
  }, [selectedPersonID, availableQuestionnaires, personAnswers]);

  const handleSelect = (selected: any) => {
    setSelectedM2sID(selected);
  };

  return (
    <FunctionWrapper>
      <FunctionContent className={'flex flex-col'}>
        <QuestionnaireHistory
          personQuestionnaires={personQuestionnaires}
          handleSelect={handleSelect}
        />
        <QuestionnaireAvailable
          selectedPersonID={selectedPersonID}
          selectedM2sID={selectedM2sID}
          availableQuestionnaires={availableQuestionnaires}
        />
      </FunctionContent>
    </FunctionWrapper>
  );
};
