import { IIcon } from '@/types/icon';

export const AttentionIcon: React.FC<IIcon> = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='15' fill='#1AAEC0' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0484 9.09554H20.6913C20.9575 9.09554 21.1734 8.87966 21.1734 8.61339V8.26339C21.1734 7.99712 20.9575 7.78125 20.6913 7.78125H11.0484C10.7821 7.78125 10.5663 7.99712 10.5663 8.26339V8.61339C10.5663 8.87966 10.7821 9.09554 11.0484 9.09554ZM10.2422 14.9556H21.4922V20.9218H10.2422V14.9556ZM16.3493 18.4545H17.4743C17.7406 18.4545 17.9565 18.2386 17.9565 17.9724C17.9565 17.7061 17.7406 17.4902 17.4743 17.4902H16.3493V16.3652C16.3493 16.0989 16.1335 15.8831 15.8672 15.8831C15.6009 15.8831 15.385 16.0989 15.385 16.3652V17.4902H14.26C13.9938 17.4902 13.7779 17.7061 13.7779 17.9724C13.7779 18.2386 13.9938 18.4545 14.26 18.4545H15.385V19.5795C15.385 19.8458 15.6009 20.0616 15.8672 20.0616C16.1335 20.0616 16.3493 19.8458 16.3493 19.5795V18.4545ZM21.4922 13.9519V13.5457C21.4922 12.9185 21.1437 12.3547 20.5828 12.0743C20.3507 11.9582 20.2065 11.7249 20.2065 11.4653V10.0637H11.5279V11.4653C11.5279 11.7248 11.3837 11.9582 11.1516 12.0742C10.5906 12.3547 10.2422 12.9185 10.2422 13.5457V13.9519H21.4922ZM10.2422 22.467V21.9849H21.4922V22.467C21.4922 23.4418 20.6991 24.2349 19.7243 24.2349H12.01C11.0352 24.2349 10.2422 23.4418 10.2422 22.467Z'
        fill='white'
      />
    </svg>
  );
};
