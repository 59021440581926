import React from 'react';

import { PredefinedVariantsFooter } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/PredefinedVariantsContainer/PredefinedVariantsFooter';
import { PredefinedVariantsListContainer } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/PredefinedVariantsContainer/PredefinedVariantsListContainer';

export const PredefinedVariantsContainer: React.FC = () => {
  return (
    <div className={'flex-grow flex flex-col gap-3'}>
      <PredefinedVariantsListContainer />
      <PredefinedVariantsFooter />
    </div>
  );
};
