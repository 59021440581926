import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

export interface IQualificationObjectivesItem {
  id: string;
  name?: string | null;
  dateFrom?: string | null;
  dateTo?: string | null;
}

export const QualificationObjectivesItem: React.FC<IQualificationObjectivesItem> = ({
  id,
  name,
  dateFrom,
  dateTo,
}) => {
  const { watch } = useFormContext();

  const isChecked = watch(id);

  return (
    <div
      className={twMerge(
        'grid grid-cols-12 gap-4 items-center justify-items-center p-2 border-l-2  border-white px-4',
        isChecked && `border-${colorTypes.Blue} bg-${colorTypes.HoverLight}`
      )}
    >
      <Checkbox name={id} className={'col-span-1'} />
      <Typography className={'col-span-7 justify-self-start'}>{name}</Typography>
      <Typography className={'col-span-2'}>{dateFrom}</Typography>
      <Typography className={'col-span-2'}>{dateTo}</Typography>
    </div>
  );
};
