import React, { useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@/components/Button/Button';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { PerformanceProfileErrorsTypes } from '@/core/enums/functions/performanceProfile/performanceProfileErrorsTypesEnum';
import { useDialog } from '@/core/hooks/useDialog';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { performanceProfileModalsActions } from '@/core/redux/slices/modalsSlice/functions/performanceProfile/performancePrfoileModalSlice';
import { performanceProfileModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/performanceProfile/selector';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { yup } from '@/core/utils/commonUtils';

type FormValues = {
  targetCatalog: string;
};

const validationSchema = yup.object().shape({
  targetCatalog: yup.string().required(),
});

export const SelectTargetCatalogModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    performanceProfileModalsSelectors.selectTargerCatalogModal
  );

  if (isOpened) {
    return <ModalContent />;
  }
};

const ModalContent: React.FC = () => {
  const { selectedPersonID } = useSelectedPerson();
  const { t: startNewQuestionnaireModal } = useTranslation('startNewQuestionnaireModal');
  const targetCatalogTypes = useAppSelector(performanceProfileSelectors.targetCatalogTypes);

  const dialogText = useAppSelector(parametersSelectors.performanceProfileNoAllocatedCatalogs);
  const errorsState = useAppSelector(performanceProfileSelectors.errorsState);

  const dispatch = useAppDispatch();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const { renderDialogContainer, openDialog } = useDialog({
    description: dialogText,
    showCancelButton: false,
  });

  const handleExit = () => {
    dispatch(performanceProfileModalsActions.closeSelectTargetCatalogModal());
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (!selectedPersonID) {
      return;
    }
    
    dispatch(
      performanceProfileActions.startNewQuestionnaire({
        personID: selectedPersonID,
        targetCatalogID: Number(data.targetCatalog),
      })
    );
  };

  const options: IRadioButtonGroupItem[] = useMemo(
    () => targetCatalogTypes.map((item) => ({ value: String(item.id), element: item.name })),
    [targetCatalogTypes]
  );

  useEffect(() => {
    if (errorsState.NoAllocatedCatalogs) {
      openDialog();

      dispatch(
        performanceProfileActions.setPerformanceProfileErrors({
          [PerformanceProfileErrorsTypes.NoAllocatedCatalogs]: false,
        })
      );
    }
  }, [errorsState]);

  const renderFooter = (
    <div className='flex gap-2.5'>
      <Button
        type='submit'
        className='w-full'
        onClick={methods.handleSubmit(onSubmit)}
        disabled={!methods.formState.isValid}
      >
        {startNewQuestionnaireModal('buttons.create')}
      </Button>
      <Button type='button' className='w-full' buttonVariant='Secondary' onClick={handleExit}>
        {startNewQuestionnaireModal('buttons.cancel')}
      </Button>
    </div>
  );

  return (
    <Sidebar
      position={'right'}
      className='max-w-[700px]'
      onExit={handleExit}
      footer={renderFooter}
      title={startNewQuestionnaireModal('title')}
    >
      <FormProvider {...methods}>
        <form className='my-2.5'>
          <RadioButtonGroup options={options} name={'targetCatalog'} itemClassName='px-8 py-2.5' />
        </form>
      </FormProvider>
      {renderDialogContainer()}
    </Sidebar>
  );
};
