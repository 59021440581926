import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.modals.functions.activityPlaningModals.appointmentDetailsModals;

const completeAppointmentModal = createSelector(
  [selector],
  (state) => state.completeAppointmentModal
);

const appointmentDetailsModal = createSelector(
  [selector],
  (state) => state.appointmentDetailsModal
);

export const activityPlaningAppointmentDetailsModalsSelectors = {
  completeAppointmentModal,
  appointmentDetailsModal,
};
