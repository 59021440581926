import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { WorkingTimePartTimeReason } from '@/core/enums/functions/workingTimeMobility/partTimeReasonsEnum';
import { IWorkingTime } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { twMerge } from '@/core/utils/tailwindUtil';

interface IWorkingTimeContainerPartTimeReason {
  className?: string;
}

export const WorkingTimeContainerPartTimeReason: React.FC<IWorkingTimeContainerPartTimeReason> = ({
  className,
}) => {
  const { t: workingTimeTranslations } = useTranslation('workingTime');

  const { watch, register } = useFormContext<IWorkingTime>();

  const partTimeReasonOptions: IFormRadioButtonGroupItem[] = [
    {
      value: WorkingTimePartTimeReason.ByDisability,
      component: (
        <Typography className={'col-span-11'}>
          {workingTimeTranslations('fields.educationalMissionCompletion.label')}
        </Typography>
      ),
      className: 'grid grid-cols-12',
    },
    {
      value: WorkingTimePartTimeReason.ByRequest,
      component: (
        <Typography className={'col-span-11'}>
          {workingTimeTranslations('fields.partTimeAndFixedTermLawRequest.label')}
        </Typography>
      ),
      className: 'grid grid-cols-12',
    },
    {
      value: WorkingTimePartTimeReason.ByEducation,
      component: (
        <Typography className={'col-span-11'}>
          {workingTimeTranslations('fields.natureAndSeverityOfDisability.label')}
        </Typography>
      ),
      className: 'grid grid-cols-12',
    },
    {
      value: WorkingTimePartTimeReason.OTHER,
      component: (
        <Typography className={'col-span-11'}>
          {workingTimeTranslations('fields.otherStuff.label')}
        </Typography>
      ),
      className: 'grid grid-cols-12',
    },
  ];

  const selectedPartTimeReason = watch('partTimeReasonType');

  return (
    <div className={twMerge('flex flex-col gap-2', className)}>
      <Typography>{workingTimeTranslations('justification.label')}</Typography>
      <FormRadioButtonGroup
        control={register('partTimeReasonType')}
        options={partTimeReasonOptions}
      />
      {selectedPartTimeReason === WorkingTimePartTimeReason.OTHER && (
        <TextArea control={register('otherPartTimeReasonText')} />
      )}
    </div>
  );
};
