import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets/typings';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { integrationPlanActions } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import {
  ISummaryFA,
  ISummaryFAFetchPayload,
  ISummaryFAUpdatePayload,
  summaryFAActions,
} from '@/core/redux/slices/functions/integrationPlan/summaryFA/summaryFASlice';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type SummaryFAResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/summary_fa',
  'get',
  '200'
>;
type SummaryFARequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/integration_plan/summary_fa',
  'post'
>;

function* fetchSummaryFA(
  action: PayloadAction<ISummaryFAFetchPayload>
): Generator<any, void, SummaryFAResponse> {
  yield put(summaryFAActions.setSummaryFALock(LoadingStatus.LOADING));

  const { integrationPlanID } = action.payload;

  try {
    const response = yield call(restCall, '/integration_plan/summary_fa', 'get', {
      query: { integration_plan_id: integrationPlanID },
      ...authAdd(),
    });

    const { summary_fa } = response;

    const summaryFA: ISummaryFA = {
      isPersonnelRequired: summary_fa.is_personnel_required,
      isEndeEv1: summary_fa.is_ende_ev1,
      isEndeEv2: summary_fa.is_ende_ev2,
      isEndeBBB1: summary_fa.is_ende_bbb1,
      isEndeBBB21: summary_fa.is_ende_bbb21,
      isEndeBBB22: summary_fa.is_ende_bbb22,
      isEndeBBB23: summary_fa.is_ende_bbb23,
      isEndeBBB24: summary_fa.is_ende_bbb24,
      isEndeBBB25: summary_fa.is_ende_bbb25,
      isEndeBBB26: summary_fa.is_ende_bbb26,
      isUnsuitableWfbM: summary_fa.is_unsuitable_wfb_m,
      notes: summary_fa.notes,
    };

    yield put(summaryFAActions.setSummaryFA(summaryFA));

    yield put(summaryFAActions.setSummaryFALock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration plan summary FA fetching');
    yield put(summaryFAActions.setSummaryFALock(LoadingStatus.ERROR));
  }
}

function* updateSummaryFA(
  action: PayloadAction<ISummaryFAUpdatePayload>
): Generator<any, void, any> {
  const { integrationPlanID, personID, summaryFA } = action.payload;

  try {
    yield put(summaryFAActions.setUpdateSummaryFALock(LoadingStatus.LOADING));

    const request: SummaryFARequest = {
      query: {
        person_id: personID,
        integration_plan_id: integrationPlanID,
      },
      json: {
        notes: summaryFA.notes ?? null,
        is_ende_bbb1: summaryFA.isEndeBBB1,
        is_ende_ev2: summaryFA.isEndeEv2,
        is_ende_ev1: summaryFA.isEndeEv1,
        is_personnel_required: summaryFA.isPersonnelRequired,
        is_ende_bbb21: summaryFA.isEndeBBB21,
        is_ende_bbb22: summaryFA.isEndeBBB22,
        is_ende_bbb23: summaryFA.isEndeBBB23,
        is_ende_bbb24: summaryFA.isEndeBBB24,
        is_ende_bbb25: summaryFA.isEndeBBB25,
        is_ende_bbb26: summaryFA.isEndeBBB26,
        is_unsuitable_wfb_m: summaryFA.isUnsuitableWfbM,
      },
      ...authAdd(),
    };

    const updateResponse = yield call(restCall, '/integration_plan/summary_fa', 'post', {
      ...request,
    });

    yield put(
      integrationPlanActions.checkIntegrationPlanUpdate({
        integrationPlanID,
        updateResponse: updateResponse,
      })
    );

    yield put(summaryFAActions.setUpdateSummaryFALock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration plan summary FA data updating');
    yield put(summaryFAActions.setUpdateSummaryFALock(LoadingStatus.ERROR));
  }
}

export const summaryFASagas = [
  takeLatest(summaryFAActions.fetchSummaryFA, fetchSummaryFA),
  takeLatest(summaryFAActions.updateSummaryFA, updateSummaryFA),
];
