import { useEffect } from 'react';

import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { integrationPlanSelectors } from '@/core/redux/slices/functions/integrationPlan/selectors';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';

export function useIntegrationPlanLock() {
  const { integrationPlanVersionHistory: versionHistory } = useAppSelector(
    integrationPlanModalsSelectors.integrationPlanVersionHistoryModal
  );

  const versionDate = useAppSelector(integrationPlanSelectors.integrationPlanVersionDate);

  const hasCurrentPlanDate = Boolean(versionDate);
  const allHistoryHaveDates = versionHistory.every((version) => Boolean(version.printedTimestamp));

  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      integrationPlanModalsActions.fetchIntegrationPlanVersionHistory({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID, versionDate]);

  return hasCurrentPlanDate && !allHistoryHaveDates;
}
