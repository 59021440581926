import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import SpecialistsRoute from '../dashboard/SpecialistsRoute';

const SpecialistsRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route index element={<SpecialistsRoute />} />
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </Routes>
  );
};

export default SpecialistsRootRoute;
