import React from 'react';

import useAuthUserLoader from '@/route/hooks/useAuthUserLoader';
import PrivateRoutes from '@/route/Routes/v1/PrivateRoutes';
import PublicRoutes from '@/route/Routes/v1/PublicRoutes';

const RouteHolder: React.FC = () => {
  const { isAuthorized, profileEntity } = useAuthUserLoader();

  return <>{isAuthorized ? <PrivateRoutes user={profileEntity} /> : <PublicRoutes />}</>;
};

export default RouteHolder;
