import React, { useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { TextArea } from '@/components/TextArea/TextArea';
import { useAppDispatch } from '@/core/redux/hooks';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { colorTypes } from '@/styles/types';

interface IQuestionItemDescriptionEditPopup {
  parentID: number;
  id: number;
  initialValue?: string | null;
  closePopUp: () => void;
}

export const QuestionItemDescriptionEditPopup: React.FC<IQuestionItemDescriptionEditPopup> = ({
  parentID,
  id,
  closePopUp,
  initialValue,
}) => {
  const { t: questionnaireTranslations } = useTranslation('questionnaire', {
    keyPrefix: 'dialog.buttons',
  });

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const fieldName = `${parentID}.questions.${id}.description`;
  const formContext = useFormContext();

  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      description: initialValue,
    },
  });

  const handleSave = () => {
    const newValue = form.watch().description;

    const review: number | undefined = formContext.watch(`${parentID}.questions.${id}.review`);

    if (review !== undefined) {
      dispatch(
        performanceProfileActions.updateQuestionnaire({
          competence: {
            id: id,
            review: review,
            description: newValue ?? null,
          },
        })
      );
    }

    formContext.setValue(fieldName, newValue);
    closePopUp();
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className={`flex flex-col gap-3 bg-white p-3 rounded-default border border-${
        isFocused ? colorTypes.Blue : colorTypes.Stroke
      } shadow-default min-w-[300px]`}
    >
      <FormProvider {...form}>
        <TextArea
          control={form.control.register('description')}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          textAreaVariant={'EditTextPopUp'}
        />
      </FormProvider>
      <div className={'flex flex-row gap-3'}>
        <Button onClick={handleSave} disabled={!form.formState.isDirty}>
          {questionnaireTranslations('save.label')}
        </Button>
        <Button buttonVariant={'Secondary'} onClick={closePopUp}>
          {questionnaireTranslations('cancel.label')}
        </Button>
      </div>
    </div>
  );
};
