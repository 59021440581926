import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import fp from 'lodash/fp';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import { MobilityRefundVariant } from '@/core/enums/functions/workingTimeMobility/mobilityRefundVariantsEnum';
import { mobilityActions } from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import {
  IDeleteMobilityPayload,
  IEditMobilityModalPayload,
  IMobilityDay,
  IMobilityDetails,
  IMobilityFormType,
  IUpdateMobilityDetailsPayload,
  mobilityModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/mobility/slice';
import { getSelectedOption } from '@/core/utils/commonUtils';
import { parseTime, toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import { isEnumValue } from '@/core/utils/enumUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type ArrivalDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/mobility/arrival',
  'get',
  '200'
>;

type ArrivalDetailsRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/mobility/arrival', 'get'>;

type DepartureDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/mobility/departure',
  'get',
  '200'
>;

type DepartureDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/mobility/departure',
  'get'
>;

type UpdateArrivalDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/mobility/arrival',
  'put'
>;

type UpdateDepartureDetailsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/mobility/departure',
  'put'
>;

type DeleteDepartureMobilityRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/mobility/departure',
  'delete'
>;

type DeleteArrivalMobilityRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/mobility/arrival',
  'delete'
>;

const getMobilityTransportServiceContacts = (
  transportService?: ArrivalDetailsResponse['details']['transport_service'] | null
): string => {
  if (!transportService) {
    return '';
  }

  const contacts = fp.omit(['to_timestamp', 'type', 'pick_up_time'], transportService);

  const values = Object.values(contacts);

  return values.join('\n');
};

const mapMobilityResponse = (
  response: DepartureDetailsResponse | ArrivalDetailsResponse
): IMobilityDetails => {
  const { details } = response;

  const selfDriveOptions: Record<MobilityRefundVariant, boolean> = {
    [MobilityRefundVariant.TicketPrice]: Boolean(
      details.self_drive?.is_reimburse_expenses_jobticket_amount
    ),
    [MobilityRefundVariant.ByMileage]: Boolean(
      details.self_drive?.is_reimburse_expenses_kilometer_flat_rate
    ),
    [MobilityRefundVariant.NonRefundable]: Boolean(details.self_drive?.is_reimburse_expenses),
    [MobilityRefundVariant.Default]: false,
  };

  return {
    id: details.id,
    mobilityType: {
      id: details.mobility_type?.id,
      name: details.mobility_type?.name,
      type: isEnumValue(MobilityFormTypes, details.mobility_type?.type)
        ? details.mobility_type?.type
        : MobilityFormTypes.Default,
    },
    transportService: {
      contacts: getMobilityTransportServiceContacts(details.transport_service),
      pickupTime: details.transport_service?.pick_up_time?.slice(0, 5),
      date: toClientDateInput(details.transport_service?.to_timestamp),
      type: details.transport_service?.type,
    },
    selfDrive: {
      kilometer: details.self_drive?.kilometers_amount,
      refundVariant: isEnumValue(MobilityRefundVariant, getSelectedOption(selfDriveOptions))
        ? (getSelectedOption(selfDriveOptions) as MobilityRefundVariant)
        : MobilityRefundVariant.Default,
    },
    jobTicket: {
      validDate: toClientDateInput(details.jobticket?.jobticket_valid_date),
    },
    availableWorkingDays: response.available_working_days.map<IMobilityDay>((day) => ({
      id: day.id,
      name: day.name,
      isWorkingDay: day.is_working_day,
    })),
    availableMobilityTypes: response.available_mobility_types.map<IMobilityFormType>(
      (mobilityType) => ({
        id: mobilityType.id,
        name: mobilityType.name,
        type: isEnumValue(MobilityFormTypes, mobilityType.type)
          ? mobilityType.type
          : MobilityFormTypes.Default,
      })
    ),
    availableTransportServices: response.available_transport_services.map((service) => ({
      contacts: getMobilityTransportServiceContacts(service),
      date: toClientDateInput(service.to_timestamp),
      type: service.type,
      time: parseTime(service.pick_up_time),
    })),
  };
};

function* fetchMobilityDetails(action: PayloadAction<IEditMobilityModalPayload>) {
  const { mobilityID, context } = action.payload;

  try {
    yield put(mobilityModalsActions.setMobilityDetailsLock(LoadingStatus.LOADING));

    const request: ArrivalDetailsRequest | DepartureDetailsRequest = {
      query: {
        mobility_item_id: mobilityID,
      },
      ...authAdd(),
    };

    const response: ArrivalDetailsResponse | DepartureDetailsResponse = yield call(
      restCall,
      context === 'arrival' ? '/mobility/arrival' : '/mobility/departure',
      'get',
      request
    );

    const mobilityDetails: IMobilityDetails = mapMobilityResponse(response);

    yield put(mobilityModalsActions.setMobilityDetails(mobilityDetails));

    yield put(mobilityModalsActions.setMobilityDetailsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on mobility details fetching', error);
    yield put(mobilityModalsActions.setMobilityDetailsLock(LoadingStatus.ERROR));
  }
}

function* updateMobilityDetails(action: PayloadAction<IUpdateMobilityDetailsPayload>) {
  const { context, formValues, personID } = action.payload;

  try {
    yield put(mobilityModalsActions.setUpdateMobilityLock(LoadingStatus.LOADING));

    let mappedForm:
      | UpdateDepartureDetailsRequest['json']['departure']
      | UpdateArrivalDetailsRequest['json']['arrival'];

    switch (context) {
      case 'arrival': {
        mappedForm = {
          mobility_type_id: formValues?.mobilityType?.id || -1,
          to_timestamp: toBackendDate(formValues?.carService?.date),
          transport_service_type_id: formValues?.carService?.type?.id,
          is_reimburse_expenses:
            formValues?.selfDriver?.refundVariant === MobilityRefundVariant.NonRefundable,
          is_reimburse_expenses_jobticket_amount:
            formValues?.selfDriver?.refundVariant === MobilityRefundVariant.TicketPrice,
          is_reimburse_expenses_kilometer_flat_rate:
            formValues?.selfDriver?.refundVariant === MobilityRefundVariant.ByMileage,
          kilometers_amount: formValues?.selfDriver?.kilometer,
          pick_up_time: fp.isEmpty(formValues?.carService?.pickupTime)
            ? null
            : formValues?.carService?.pickupTime,
          jobticket_valid_date: toBackendDate(formValues?.jobTicket?.validDate),
        };
        break;
      }
      case 'departure': {
        mappedForm = {
          mobility_type_id: formValues?.mobilityType?.id || -1,
          to_timestamp: toBackendDate(formValues?.carService?.date),
          is_reimburse_expenses:
            formValues?.selfDriver?.refundVariant === MobilityRefundVariant.NonRefundable,
          is_reimburse_expenses_jobticket_amount:
            formValues?.selfDriver?.refundVariant === MobilityRefundVariant.TicketPrice,
          is_reimburse_expenses_kilometer_flat_rate:
            formValues?.selfDriver?.refundVariant === MobilityRefundVariant.ByMileage,
          kilometers_amount: formValues?.selfDriver?.kilometer,
          pick_up_time: formValues?.carService?.pickupTime,
          jobticket_valid_date: toBackendDate(formValues?.jobTicket?.validDate),
          transport_service_type_id: formValues?.carService?.type?.id,
        };
        break;
      }
    }

    const arrivalRequest: UpdateArrivalDetailsRequest = {
      json: {
        mobility_items_ids: formValues?.appliedDays ?? [],
        arrival: mappedForm,
      },
      ...authAdd(),
    };

    const departureRequest: UpdateDepartureDetailsRequest = {
      json: {
        mobility_items_ids: formValues?.appliedDays ?? [],
        departure: mappedForm,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      context === 'arrival' ? '/mobility/arrival' : '/mobility/departure',
      'put',
      context === 'arrival' ? arrivalRequest : departureRequest
    );
    yield put(mobilityModalsActions.setUpdateMobilityLock(LoadingStatus.LOADED));

    yield put(
      mobilityActions.fetchMobility({
        personID: personID,
      })
    );
  } catch (error) {
    console.log('Error on mobility details updating', error);
    yield put(mobilityModalsActions.setUpdateMobilityLock(LoadingStatus.ERROR));
  }
}

function* deleteMobility(action: PayloadAction<IDeleteMobilityPayload>) {
  const { mobilityID, context, personID } = action.payload;

  try {
  } catch (error) {
    console.log('Error on mobility delete', error);
  }

  const departureRequest: DeleteDepartureMobilityRequest = {
    query: {
      mobility_item_id: mobilityID,
    },
    ...authAdd(),
  };

  const arrivalRequest: DeleteArrivalMobilityRequest = {
    query: {
      mobility_item_id: mobilityID,
    },
    ...authAdd(),
  };

  yield call(
    restCall,
    context === 'arrival' ? '/mobility/arrival' : '/mobility/departure',
    'delete',
    context === 'arrival' ? arrivalRequest : departureRequest
  );

  yield put(
    mobilityActions.fetchMobility({
      personID: personID,
    })
  );
}

export const mobilityModalsSagas = [
  takeLatest(mobilityModalsActions.fetchMobilityDetails, fetchMobilityDetails),
  takeLatest(mobilityModalsActions.updateMobility, updateMobilityDetails),
  takeLatest(mobilityModalsActions.deleteMobility, deleteMobility),
];
