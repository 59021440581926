import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Accordion } from '@/components/Accordion/Accordion';
import {
  IQuestionFormValues,
  IQuestionnaireForm,
} from '@/components/functions/PerformanceProfile/Questionnaire/hooks/useQuestionnaireQuestionsListForm';
import { QuestionnaireQuestionItem } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionItem/QuestionnaireQuestionItem';
import { CircleFilledIcon } from '@/components/Icons/CircleFilledIcon';
import { CircleOutlineIcon } from '@/components/Icons/CircleOutlineIcon';
import { Typography } from '@/components/Typography/Typography';
import { QuestionnaireQuestionsGroupStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireQustionsGroupStatusEnum';
import { IQuestionnaireQuestion } from '@/core/redux/slices/functions/performanceProfile/slice';
import { colorTypes } from '@/styles/types';

export const statusIcons: Record<QuestionnaireQuestionsGroupStatus, React.ReactNode> = {
  [QuestionnaireQuestionsGroupStatus.Completed]: (
    <CircleFilledIcon className={`text-${colorTypes.Tiffany}`} />
  ),
  [QuestionnaireQuestionsGroupStatus.Intermediate]: (
    <CircleOutlineIcon className={`text-${colorTypes.Tiffany}`} />
  ),
  [QuestionnaireQuestionsGroupStatus.Uncompleted]: (
    <CircleOutlineIcon className={`text-${colorTypes.Orange}`} />
  ),
};

interface IQuestionnaireQuestionsGroup {
  id: number;
  name: string;
  index: number;
  expandedGroupID: number | null;
  setExpandedGroupID: (id: number | null) => void;
  questionsList: IQuestionnaireQuestion[];
  containerRef: React.RefObject<HTMLDivElement>;
}

export const QuestionnaireQuestionsGroup: React.FC<IQuestionnaireQuestionsGroup> = ({
  name,
  id,
  index,
  questionsList,
  expandedGroupID,
  setExpandedGroupID,
  containerRef,
}) => {
  const [, setIsExpanded] = useState<boolean>(false);
  const [status, setStatus] = useState<QuestionnaireQuestionsGroupStatus>(
    QuestionnaireQuestionsGroupStatus.Uncompleted
  );
  const groupRef = useRef<HTMLDivElement>(null);

  const formContext = useFormContext<IQuestionnaireForm>();
  const currentValues = formContext.watch();

  useEffect(() => {
    if (currentValues[id]) {
      const questionsReviews = Object.values<IQuestionFormValues>(currentValues[id].questions);

      if (questionsReviews.every((value) => value.review === null)) {
        setStatus(QuestionnaireQuestionsGroupStatus.Uncompleted);
        return;
      }

      if (questionsReviews.every((value) => value.review !== null)) {
        setStatus(QuestionnaireQuestionsGroupStatus.Completed);
        return;
      }

      setStatus(QuestionnaireQuestionsGroupStatus.Intermediate);
    }
  }, [currentValues]);

  const questionsGroupTitle = (
    <div className={`flex flex-row gap-3 items-center`}>
      {statusIcons[status]}
      <Typography type={'16-Sb'}>{`${index}. ${name}`}</Typography>
    </div>
  );

  const scrollToElement = () => {
    if (containerRef.current && groupRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const elementRect = groupRef.current.getBoundingClientRect();
      const offsetTop = elementRect.top - containerRect.top;

      containerRef.current.style.scrollBehavior = 'smooth';
      containerRef.current.scrollTop += offsetTop;
    }
  };

  const handleExpand = () => {
    setExpandedGroupID(expandedGroupID === id ? null : id);
  };

  const onAnimationComplete = () => {
    scrollToElement();
  };

  return (
    <div ref={groupRef}>
      <Accordion
        title={questionsGroupTitle}
        className={'w-full'}
        isExpanded={expandedGroupID === id}
        onClick={handleExpand}
        setIsExpanded={setIsExpanded}
        onAnimationComplete={onAnimationComplete}
        headerClassName={`bg-${colorTypes.Fields} px-[30px] py-3`}
      >
        {questionsList.map((question, childIndex) => (
          <QuestionnaireQuestionItem
            id={question.id}
            parentID={id}
            parentIndex={index}
            index={childIndex + 1}
            name={question.name}
            reviewsList={question.reviews}
            reviewsHistory={question.reviewsHistory}
            review={question.review}
            description={question.description}
            key={question.id}
          />
        ))}
      </Accordion>
    </div>
  );
};
