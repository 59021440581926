import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { motion } from 'framer-motion';

import { useAdministrationChecklistSelector } from '@/components/administartionFunctions/checklist/AdministrationChecklistContext/AdministrationChecklistContext';
import { Button } from '@/components/Button/Button';
import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { SmallDraggableIcon } from '@/components/Icons/SmallDraggableIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import {
  administrationChecklistActions,
  IAdministrationChecklist,
} from '@/core/redux/slices/administrationFunctions/checkList/slice';
import { colorTypes } from '@/styles/types';

export const AdministrationChecklistItem: React.FC<IAdministrationChecklist> = (checklist) => {
  const [isChecked, setIsChecked] = useState(checklist.isAdded);
  const { attributes, listeners, setNodeRef, isDragging, transform, transition } = useSortable({
    id: checklist.id,
  });

  const style = {
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleX: isDragging ? 0.97 : transform.scaleX,
        scaleY: isDragging ? 0.97 : transform.scaleY,
      }
    ),
    transition,
    transformOrigin: 'right center',
  };

  const [selectedChecklist, setState] = useAdministrationChecklistSelector(
    (state) => state.selectedChecklist
  );
  const isSelected = selectedChecklist?.id === checklist.id;

  const dispatch = useAppDispatch();

  const handleCheck = (isChecked: boolean) => {
    setIsChecked(isChecked);
    dispatch(
      administrationChecklistActions.updateChecklistItem({
        id: checklist.id,
        isSelected: isChecked,
      })
    );
  };

  const handleClick = () => {
    setState({ selectedChecklist: checklist });
    setState({ selectedCheckpoint: null });
  };

  const activeStyles = isDragging
    ? `text-${colorTypes.White}`
    : `text-${colorTypes.White} opacity-50 hover:opacity-100`;
  const defaultStyles = isDragging
    ? `text-${colorTypes.Blue}`
    : `text-${colorTypes.Secondary} hover:text-${colorTypes.Blue}`;

  return (
    <motion.div
      ref={setNodeRef}
      animate={{
        zIndex: isDragging ? 1 : 0,
      }}
      className={`relative grid grid-cols-12 gap-3 items-center h-[44px]
      bg-${isSelected ? colorTypes.Blue : colorTypes.HoverLight} 
      ${isDragging && 'rounded-default'}
      border-b border-b-${colorTypes.StrokeLight} p-2`}
      style={style}
      onClick={handleClick}
    >
      <div className={'col-span-2 self-center'}>
        {isDragging && (
          <div
            className={`absolute inset-0 rounded-default border-2 border-${colorTypes.Blue} border-dashed`}
            style={{ top: '-3px', bottom: '-3px', left: '-3px', right: '-3px' }}
          />
        )}
        <CheckboxUI checked={isChecked} onChange={handleCheck} />
      </div>
      <div className={'col-span-10 flex flex-row items-center'}>
        <Typography className={'flex-1'} color={isSelected ? 'White' : 'Primary'}>
          {checklist.name}
        </Typography>
        <Button buttonVariant={'Icon'} buttonStyle={'Icon'} {...attributes} {...listeners}>
          <SmallDraggableIcon className={isSelected ? activeStyles : defaultStyles} />
        </Button>
      </div>
    </motion.div>
  );
};
