import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.integrationPlanModals;

//Qualification objectives
const qualificationObjectivesModal = createSelector(
  [selector],
  (state) => state.qualificationObjectivesModal
);

//Internships
const internshipDocumentationModal = createSelector(
  [selector],
  (state) => state.internshipDocumentationModal.isRender
);

//Create integration plan documents
const createIntegrationPlanDocumentsModal = createSelector(
  [selector],
  (state) => state.createIntegrationPlanDocumentsModal.isRender
);
const createIntegrationPlanDocumentPrintVariants = createSelector(
  [selector],
  (state) => state.createIntegrationPlanDocumentsModal.printVariants
);
const createIntegrationPlanDocumentsPredefinedPrintVariants = createSelector(
  [selector],
  (state) => state.createIntegrationPlanDocumentsModal.predefinedVariants
);
const createIntegrationPlanDocumentsPrintVariantsLoadingStatus = createSelector(
  [selector],
  (state) => state.createIntegrationPlanDocumentsModal.printVariantsLoadingStatus
);
const createIntegrationPlanDocumentsGetDocumentsLoadingStatus = createSelector(
  [selector],
  (state) => state.createIntegrationPlanDocumentsModal.getDocumentsLoadingStatus
);

//Action course created date
const actionCourseCreatedDateModal = createSelector(
  [selector],
  (state) => state.actionCourseCreatedDateModal
);

//General data updated data
const generalDataUpdatedDataModal = createSelector(
  [selector],
  (state) => state.generalDataUpdatedDataModal
);

//Competence analysis import
const competenceAnalysisImportModal = createSelector(
  [selector],
  (state) => state.competenceAnalysisImportModal
);

//Integration plan version history
const integrationPlanVersionHistoryModal = createSelector(
  [selector],
  (state) => state.integrationPlanVersionHistory
);

export const integrationPlanModalsSelectors = {
  qualificationObjectivesModal,
  internshipDocumentationModal,
  actionCourseCreatedDateModal,
  generalDataUpdatedDataModal,
  createIntegrationPlanDocumentsModal,
  createIntegrationPlanDocumentPrintVariants,
  createIntegrationPlanDocumentsPredefinedPrintVariants,
  createIntegrationPlanDocumentsPrintVariantsLoadingStatus,
  createIntegrationPlanDocumentsGetDocumentsLoadingStatus,
  competenceAnalysisImportModal,
  integrationPlanVersionHistoryModal,
};
