import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  IPersonData as FormValues,
  personDataActions,
} from '@/core/redux/slices/functions/personGeneralData/personData/personDataSlice';
import { personDataSelectors } from '@/core/redux/slices/functions/personGeneralData/personData/selectors';

export const ServiceProvidersSelect: React.FC = () => {
  const [serviceProviderID, setServiceProviderID] = useState<string | undefined>(undefined);
  const form = useFormContext<FormValues>();
  const currentValues = form.watch();

  const serviceProviders = useAppSelector(personDataSelectors.serviceProviders);

  const dispatch = useAppDispatch();

  const sortedServiceProviders = [...serviceProviders].sort((a, b) => {
    const componentA = a.name?.toLowerCase() || '';
    const componentB = b.name?.toLowerCase() || '';

    return componentA?.localeCompare(componentB);
  });

  const serviceProvidersSelectOptions: ISelectItem[] = useMemo(
    () =>
      sortedServiceProviders.map((serviceProvider) => ({
        id: String(serviceProvider.id),
        component: (
          <div className={'max-w-[800px] w-full grid grid-cols-12 gap-3'}>
            <Typography className={'col-span-5'}>{serviceProvider.name}</Typography>
            <Typography className={'col-span-3'}>{serviceProvider.address}</Typography>
            <Typography className={'col-span-2'}>{serviceProvider.plz}</Typography>
            <Typography className={'col-span-2'}>{serviceProvider.location}</Typography>
          </div>
        ),
      })),
    [serviceProviders]
  );

  useEffect(() => {
    const formServiceProviderID = String(currentValues.serviceProvider);

    if (serviceProviderID === formServiceProviderID) {
      return;
    }

    setServiceProviderID(formServiceProviderID);
  }, [currentValues]);

  useEffect(() => {
    dispatch(personDataActions.fetchServiceProviders());
  }, []);

  const handleSelect = (id: string) => {
    form.setValue('serviceProvider', Number(id));
  };

  const renderSelectedItem = () => {
    const foundServiceProvider = serviceProviders.find(
      (serviceProvider) => serviceProvider.id === Number(serviceProviderID)
    );

    if (!foundServiceProvider) {
      return null;
    }

    return <Typography>{foundServiceProvider.name}</Typography>;
  };

  return (
    <Select
      setSelectedExternal={setServiceProviderID}
      selectedExternal={serviceProviderID}
      onChange={handleSelect}
      selectedComponent={renderSelectedItem()}
      values={serviceProvidersSelectOptions}
    />
  );
};
