import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CompleteMeasureAppointmentListGroup } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureAppointmentList/CompleteMeasureAppointmentListGroup';
import { useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';
import {
  IMeasuresGroup,
  IRootMeasureGroup,
} from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';

function findMeasureGroup(
  rootGroups: IRootMeasureGroup[] | null,
  groupId: number
): IMeasuresGroup | undefined {
  if (!rootGroups) {
    return;
  }

  for (const rootGroup of rootGroups) {
    const group = rootGroup.groups.find((_group) => _group.id === groupId);
    if (group) {
      return group;
    }
  }
  return undefined;
}

export const CompleteMeasureAppointmentList: React.FC = () => {
  const { measureDetails } = useAppSelector(
    vocationalTrainingAreaModalSelectors.completeMeasureModal
  );

  const { watch } = useFormContext();

  const selectedMeasure = watch('selectedMeasure');
  const matches = selectedMeasure?.match(/groupID=([^&]+)&measureID=([^&]+)/);
  const groupID = Number(matches?.[1]);
  const measureID = Number(matches?.[2]);

  const measureGroup = findMeasureGroup(measureDetails, groupID);

  return (
    <div>
      {measureGroup?.appointments &&
        measureGroup.appointments?.map((appointment) => (
          <CompleteMeasureAppointmentListGroup appointment={appointment} key={appointment.id} />
        ))}
    </div>
  );
};
