import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.specialistGeneralData.internships;

// internships
const internships = createSelector([selector], (state) => state.internships);

export const internshipsSelectors = {
  internships,
};
