import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import {
  IWorkingDay,
  IWorkingTimeDayItem,
  IWorkingTimeWeek,
} from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IModal {
  isRender: boolean;
}

export interface IMobilityTransportType {
  id: number;
  transportTypeName: string;
  transportFormType: IMobilityFromType;
}

export interface IMobilityFromType {
  id: number;
  formType: MobilityFormTypes;
}

export interface IMobilityTransportTypesUpdatePayload {
  id: number | null;
  transportTypeName: string;
  transportTypeFormID: number;
}

export interface IMobilityTransportTypesModal extends IModal {
  mobilityTransportTypes: IMobilityTransportType[];
  mobilityFormTypes: IMobilityFromType[];
  mobilityTransportTypesLock: LoadingStatus;
}

export interface IWorkingTimePredefinedVariant {
  id: number;
  name: string;
  workingWeek: IWorkingTimeWeek;
}

export interface ICreateWorkingTimePredefinedVariantPayload {
  name: string;
  days: Record<string, IWorkingDay | null>;
}

export interface IUpdateWorkingTimePredefinedVariantPayload
  extends ICreateWorkingTimePredefinedVariantPayload {
  id: number;
}

export interface IWorkingTimePredefinedVariantsModal extends IModal {
  workingTimePredefinedVariants: IWorkingTimePredefinedVariant[] | null;
  workingTimeDaysList: IWorkingTimeDayItem[] | null;
  workingTimePredefinedVariantsLock: LoadingStatus;
  updateWorkingTimePredefinedVariantsLock: LoadingStatus;
}

export interface IWorkingTimeMobilityModalsState {
  transportTypesModal: IMobilityTransportTypesModal;
  workingTimePredefinedVariantsModal: IWorkingTimePredefinedVariantsModal;
}

const initialState: IWorkingTimeMobilityModalsState = {
  transportTypesModal: {
    mobilityTransportTypes: [],
    mobilityFormTypes: [],
    mobilityTransportTypesLock: LoadingStatus.NEVER,
    isRender: false,
  },
  workingTimePredefinedVariantsModal: {
    isRender: false,
    workingTimePredefinedVariants: null,
    workingTimeDaysList: null,
    workingTimePredefinedVariantsLock: LoadingStatus.NEVER,
    updateWorkingTimePredefinedVariantsLock: LoadingStatus.NEVER,
  },
};

export const workingTimeMobilityModalsSlice = createSlice({
  name: 'workingTimeMobilityModals',
  initialState,
  reducers: {
    //Transport types
    openTransportTypesModal: (state) => ({
      ...state,
      transportTypesModal: {
        ...state.transportTypesModal,
        isRender: true,
      },
    }),
    closeTransportTypesModal: (state) => ({
      ...state,
      transportTypesModal: {
        isRender: false,
        mobilityTransportTypes: [],
        mobilityFormTypes: [],
        mobilityTransportTypesLock: LoadingStatus.NEVER,
      },
    }),
    fetchTransportTypes: (state, action: PayloadAction<number | null>) => ({
      ...state,
      payload: action.payload,
    }),
    setTransportTypes: (state, action: PayloadAction<IMobilityTransportType[]>) => ({
      ...state,
      transportTypesModal: {
        ...state.transportTypesModal,
        mobilityTransportTypes: action.payload,
      },
    }),
    setTransportFormTypes: (state, action: PayloadAction<IMobilityFromType[]>) => ({
      ...state,
      transportTypesModal: {
        ...state.transportTypesModal,
        mobilityFormTypes: action.payload,
      },
    }),
    setTransportTypesLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      transportTypesModal: {
        ...state.transportTypesModal,
        mobilityTransportTypesLock: action.payload,
      },
    }),
    updateTransportTypes: (state, action: PayloadAction<IMobilityTransportTypesUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    //Working time predefined variants
    openWorkingTimePredefinedVariantsModal: (state) => ({
      ...state,
      workingTimePredefinedVariantsModal: {
        isRender: true,
        workingTimePredefinedVariants: null,
        workingTimeDaysList: null,
        workingTimePredefinedVariantsLock: LoadingStatus.NEVER,
        updateWorkingTimePredefinedVariantsLock: LoadingStatus.NEVER,
      },
    }),
    closeWorkingTimePredefinedVariantsModal: (state) => ({
      ...state,
      workingTimePredefinedVariantsModal: {
        isRender: false,
        workingTimePredefinedVariants: null,
        workingTimeDaysList: null,
        workingTimePredefinedVariantsLock: LoadingStatus.NEVER,
        updateWorkingTimePredefinedVariantsLock: LoadingStatus.NEVER,
      },
    }),
    fetchWorkingTimePredefinedVariants: (state) => ({
      ...state,
    }),
    setWorkingTimePredefinedVariants: (
      state,
      action: PayloadAction<IWorkingTimePredefinedVariant[] | null>
    ) => ({
      ...state,
      workingTimePredefinedVariantsModal: {
        ...state.workingTimePredefinedVariantsModal,
        workingTimePredefinedVariants: action.payload,
      },
    }),
    setWorkingTimeDaysList: (state, action: PayloadAction<IWorkingTimeDayItem[] | null>) => ({
      ...state,
      workingTimePredefinedVariantsModal: {
        ...state.workingTimePredefinedVariantsModal,
        workingTimeDaysList: action.payload,
      },
    }),
    setWorkingTimePredefinedVariantsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      workingTimePredefinedVariantsModal: {
        ...state.workingTimePredefinedVariantsModal,
        workingTimePredefinedVariantsLock: action.payload,
      },
    }),
    updateWorkingTimePredefinedVariants: (
      state,
      action: PayloadAction<IUpdateWorkingTimePredefinedVariantPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    createWorkingTimePredefinedVariants: (
      state,
      action: PayloadAction<ICreateWorkingTimePredefinedVariantPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateWorkingTimePredefinedVariantsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      workingTimePredefinedVariantsModal: {
        ...state.workingTimePredefinedVariantsModal,
        updateWorkingTimePredefinedVariantsLock: action.payload,
      },
    }),
  },
});

export const workingTimeMobilityModalsReducer = workingTimeMobilityModalsSlice.reducer;
export const workingTimeMobilityModalsActions = workingTimeMobilityModalsSlice.actions;
