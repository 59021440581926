import { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { appActions } from '@/core/redux/slices/app/appSlice';
import { appSelectors } from '@/core/redux/slices/app/selectors';
import { useCookies } from '@/core/utils/cookiesUtil';

const useAppInitialization = () => {
  const dispatch = useAppDispatch();
  const cookies = useCookies();
  const cookiesAccessToken = cookies.get('accessToken');
  const isAppInitializationInProgress = useAppSelector(appSelectors.isAppInitializationInProgress);

  useEffect(() => {
    dispatch(appActions.setAppInitialization({ accessToken: cookiesAccessToken }));
  }, [dispatch, cookiesAccessToken]);

  return useMemo(() => isAppInitializationInProgress, [isAppInitializationInProgress]);
};

export default useAppInitialization;
