import { useEffect, useMemo } from 'react';
import fp from 'lodash/fp';

import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { useSelectedLocations } from '@/components/views/person/PersonPage/PersonView/PersonList/hooks/useSelectedLocations';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { personPageSelectors } from '@/core/redux/slices/personPage/personPageSelectors';
import { personPageActions } from '@/core/redux/slices/personPage/personPageSlice';
import { generateName } from '@/core/utils/commonUtils';
import { LoadingStatus } from '@/types/loadingStatus';
import { SortingBy } from '@/types/sortingBy';

import { useSelectedPersons } from './useSelectedPersons';

const PERSONS_LIMIT = 10000;

export function useFetchedPersons() {
  const dispatch = useAppDispatch();

  const selectedLocations = useSelectedLocations();
  const selectedPersons = useSelectedPersons();

  const selectedPersonsIds = useMemo(
    () => (selectedPersons ? selectedPersons?.map((item) => item?.id) : []),
    [selectedPersons]
  );

  const [sortBy] = usePersonPageStoreSelector((store) => store.personsSortedBy);
  const [searchValue] = usePersonPageStoreSelector((store) => store.personsSearchValue);
  const [fetchContext] = usePersonPageStoreSelector((store) => store.fetchContext);

  useEffect(() => {
    if (selectedLocations && !fp.isEmpty(selectedLocations)) {
      const filteredLocations: string[] = selectedLocations;

      dispatch(
        personPageActions.fetchPersons({
          departments: filteredLocations,
          limit: PERSONS_LIMIT,
          context: fetchContext,
        })
      );
    }
  }, [dispatch, selectedLocations, fetchContext]);

  const fetchedPersons = useAppSelector(personPageSelectors.persons);

  useEffect(() => {
    return () => {
      dispatch(personPageActions.setPersonsLock(LoadingStatus.NEVER));
    };
  }, []);

  return useMemo(() => {
    if (
      fp.isEmpty(fetchedPersons) ||
      fp.isEmpty(sortBy) ||
      searchValue === undefined ||
      fp.isEmpty(selectedLocations)
    ) {
      return { selected: null, nonSelected: null };
    }

    const fetchedPersonsArray = Object.values(fetchedPersons);

    const filteredPersons = fetchedPersonsArray.filter((person) =>
      generateName(person).toLowerCase().includes(searchValue.toLowerCase())
    );

    const sortedPersons = filteredPersons.sort((a, b) => {
      const aIsPrioritized = selectedPersonsIds.includes(a.id);
      const bIsPrioritized = selectedPersonsIds.includes(b.id);

      if (aIsPrioritized && !bIsPrioritized) {
        return -1;
      } else if (!aIsPrioritized && bIsPrioritized) {
        return 1;
      } else {
        // If both have the same priority or neither is prioritized, sort by name
        switch (sortBy) {
          case SortingBy.nameAscend:
            if (a.surname.localeCompare(b.surname) !== 0) {
              return a.surname.localeCompare(b.surname);
            } else {
              return a.name.localeCompare(b.name);
            }
          case SortingBy.nameDescend:
            if (a.surname.localeCompare(b.surname) !== 0) {
              return b.surname.localeCompare(a.surname);
            } else {
              return b.name.localeCompare(a.name);
            }
        }
      }
    });

    const selectedPersonList = sortedPersons.filter((person) =>
      selectedPersonsIds.includes(person.id)
    );
    const nonSelectedPersonList = sortedPersons.filter(
      (person) => !selectedPersonsIds.includes(person.id)
    );
    const personList = sortedPersons;

    return { selectedPersonList, nonSelectedPersonList, personList };
  }, [fetchedPersons, sortBy, searchValue, selectedLocations, selectedPersons]);
}
