import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Dropdown } from '@/components/Dropdown/Dropdown';
import { SettingsIcon } from '@/components/Icons/SettingsIcon';
import { Typography } from '@/components/Typography/Typography';
import { PersonDropDownContent } from '@/components/views/person/PersonPage/PersonView/PersonDropDown/PersonDropDown';
import { usePersonListForm } from '@/components/views/person/PersonPage/PersonView/PersonList/hooks/usePersonListForm';
import { PersonList } from '@/components/views/person/PersonPage/PersonView/PersonList/PersonList';
import { PersonSearch } from '@/components/views/person/PersonPage/PersonView/PersonSearch/PersonSearch';
import { PersonSelectAll } from '@/components/views/person/PersonPage/PersonView/PersonSelectAll/PersonSelectAll';
import { PersonSorting } from '@/components/views/person/PersonPage/PersonView/PersonSorting/PersonSorting';

import styles from './styles.module.scss';

export const PersonView: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.title' });
  const form = usePersonListForm();

  return (
    <div className={clsx(styles.container, 'flex flex-col items-start justify-start')}>
      <Typography type='H2' color='Primary' className={styles.title}>
        {t('persons')}
      </Typography>
      <PersonSearch />
      <div className={clsx(styles.personsContainer, 'flex flex-col flex-grow')}>
        <FormProvider {...form}>
          <div
            className={clsx(
              styles.filterContainer,
              'flex flex-row items-center justify-between w-full'
            )}
          >
            <PersonSelectAll />
            <div className='flex'>
              <PersonSorting />
              <div className='flex flex-row items-center ms-3'>
                <Dropdown content={<PersonDropDownContent />}>
                  <SettingsIcon />
                </Dropdown>
              </div>
            </div>
          </div>
          <PersonList />
        </FormProvider>
      </div>
    </div>
  );
};
