import { useMemo } from 'react';
import moment from 'moment';

import { ICompetenceReviewItem } from '@/core/redux/slices/functions/performanceProfile/slice';

export function useChartData(data: ICompetenceReviewItem[]) {
  const chartsData = useMemo(
    () =>
      data.reduce((accum: Record<string, string>[], chart) => {
        const reviewKeys = Object.keys(chart.reviews);

        reviewKeys.forEach((reviewKey) => {
          const foundElement = accum.find((element) => element.category === reviewKey);
          const newValue = chart.reviews[reviewKey].value.toString();

          const formattedDate = moment(chart.date).format('DD-MM-YYYY');

          if (!foundElement) {
            accum.push({
              category: reviewKey,
              index: chart.reviews[reviewKey].index,
              [formattedDate]: newValue,
            });

            return;
          }

          foundElement[formattedDate] = newValue;

          return;
        });

        return accum;
      }, []),
    [data]
  );

  let chartsKeys: string[] = [];

  if (chartsData.length > 0) {
    chartsKeys = Object.keys(chartsData[0]).filter(
      (chartKey) => chartKey !== 'category' && chartKey !== 'index'
    );
  }

  return { chartsData, chartsKeys };
}
