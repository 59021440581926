import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { functionPageActions } from '@/core/redux/slices/functionPage/functionPageSlice';
import { FunctionTypes, IFunctionTypeTag } from '@/types/functions';

import styles from './styles.module.scss';

export const FunctionTypeTag: React.FC<IFunctionTypeTag> = ({
  functionType = FunctionTypes.none,
  id,
  className,
}) => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.label' });
  const dispatch = useAppDispatch();
  const handleRemoveType = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(functionPageActions.updateFunctionType({ id, functionType: FunctionTypes.none }));
  };

  return (
    <div
      className={clsx(
        styles.container,
        'flex flex-row items-center px-2 py-1 rounded-full ms-2',
        { [styles.wizard]: functionType === FunctionTypes.wizard },
        { [styles.full]: functionType === FunctionTypes.full },
        className
      )}
    >
      <Typography type='12-R' color='White'>
        {functionType === FunctionTypes.wizard ? t('guidedProcess') : t('inputTemplate')}
      </Typography>
      <Button
        onClick={handleRemoveType}
        buttonVariant={'Icon'}
        buttonStyle={'Icon'}
        className={clsx(styles.button, 'flex items-center ms-2')}
      >
        <CrossIcon />
      </Button>
    </div>
  );
};
