import React from 'react';

import { Button } from '@/components/Button/Button';
import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { DEFAULT_LAYER_PADDING } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaGroupedList/VocationalTrainingAreaGroupedListGroup/VocationalTrainingAreaGroupedListGroup';
import { Typography } from '@/components/Typography/Typography';
import { useDoubleClick } from '@/core/hooks/useDoubleClick';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch } from '@/core/redux/hooks';
import { efficacyAssessmentActions } from '@/core/redux/slices/efficacyAssessment/efficacyAssessmentSlice';
import { IVocationalTrainingAreaItem } from '@/core/redux/slices/functions/vocationalTrainingArea/slice';
import { efficacyAssessmentModalActions } from '@/core/redux/slices/modalsSlice/efficacyAssessment/efficacyAssessmentModalSlice';
import { vocationalTrainingAreaModalActions } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { colorTypes } from '@/styles/types';

interface IListItem {
  id: number;
  type: string;
  text: string;
  layer: number;
  bgColor?: string;
  lastActivity: string;
  nearestActivity: string;
  isParticipated: boolean;
  isUnderDirection: boolean;
  isAlmostIndependent: boolean;
  isIndependent: boolean;
  groupID: number;
  onMeasureSelected: () => void;
}

export const ListItem: React.FC<IListItem> = ({
  text,
  id,
  layer,
  bgColor,
  groupID,
  onMeasureSelected,
}) => {
  const dispatch = useAppDispatch();

  const { selectedPersonID } = useSelectedPerson();

  const handleOpenRecordingEffictivenessModal = (selectedType: string) => {
    if (!selectedPersonID) {
      return;
    }

    onMeasureSelected();

    dispatch(
      efficacyAssessmentModalActions.openRecordingEffectivenessModal({
        personID: selectedPersonID,
        measureID: groupID,
        selectedType: selectedType,
        levelID: id,
      })
    );
  };

  return (
    <Button
      buttonStyle={'Text'}
      buttonVariant={'Transparent'}
      className={`col-span-12 gap-2 grid grid-cols-12 hover:bg-${colorTypes.HoverLight} py-1 px-[30px]`}
      style={{ backgroundColor: bgColor }}
    >
      <Typography
        className={'col-span-7 whitespace-normal'}
        style={{ paddingLeft: DEFAULT_LAYER_PADDING * (layer + 1) }}
        type={'16-M'}
        color={'Secondary'}
      >
        {text}
      </Typography>
      <div className='col-span-5 grid grid-cols-12'>
        <div className={'col-span-3'}>
          <CheckboxUI
            name='isParticipated'
            checked={false}
            onChange={() => handleOpenRecordingEffictivenessModal('isParticipated')}
          />
        </div>
        <div className={'col-span-3'}>
          <CheckboxUI
            name='isUnderDirection'
            checked={false}
            onChange={() => handleOpenRecordingEffictivenessModal('isUnderDirection')}
          />
        </div>
        <div className={'col-span-3'}>
          <CheckboxUI
            name='isAlmostIndependent'
            checked={false}
            onChange={() => handleOpenRecordingEffictivenessModal('isAlmostIndependent')}
          />
        </div>
        <div className={'col-span-3'}>
          <CheckboxUI
            name='isIndependent'
            checked={false}
            onChange={() => handleOpenRecordingEffictivenessModal('isIndependent')}
          />
        </div>
      </div>
    </Button>
  );
};
