import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AdministrationRoute from '@/pages/dashboard/AdministrationRoute';

const AdministrationRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route index element={<AdministrationRoute />} />
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </Routes>
  );
};

export default AdministrationRootRoute;
