import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { DepartmentView } from '@/components/views/person/PersonPage/DepartmentView/DepartmentView';
import { PersonView } from '@/components/views/person/PersonPage/PersonView/PersonView';
import { PERSONS_URL } from '@/core/constants/urls';

import styles from './styles.module.scss';

export const PersonPage: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.breadcrumbs' });
  const breadcrumbItems = [{ text: t('personsSelection'), link: PERSONS_URL }];

  return (
    <div className={clsx(styles.page, 'px-[40px] w-full flex flex-col')}>
      <div className='pt-[20px] pb-[20px]'>
        <Breadcrumbs items={breadcrumbItems} />
      </div>
      <div className={clsx(styles.container, 'grid grid-cols-2 gap-x-5 overflow-hidden w-full')}>
        <DepartmentView />
        <PersonView />
      </div>
    </div>
  );
};
