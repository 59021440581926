import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { participantExpectationsChangeTypes } from '@/core/enums/participantExpectationsChangeTypesEnum';
import { IStatusReport } from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { colorTypes } from '@/styles/types';

export const ParticipantsExpectations: React.FC = () => {
  const { t: participantsExpectationsTranslations } = useTranslation('statusReport', {
    keyPrefix: 'participantsExpectations',
  });

  const { control, setValue, watch } = useFormContext<IStatusReport>();

  const selectedOption = watch('isParticipantExpectationChanged');

  const options: IRadioButtonGroupItem[] = [
    {
      value: participantExpectationsChangeTypes.UnChanged,
      element: (
        <div className={'w-full grid grid-cols-[3fr,8fr] items-center gap-4'}>
          <Typography>
            {participantsExpectationsTranslations('fields.isParticipantExpectationChangedNo.label')}
          </Typography>
          <TextArea
            readOnly={selectedOption !== participantExpectationsChangeTypes.UnChanged}
            control={control.register('participantExpectationChangeMethod')}
            containerClassName={'w-full'}
          />
        </div>
      ),
    },
    {
      value: participantExpectationsChangeTypes.Changed,
      element: (
        <div className={'w-full grid grid-cols-[3fr,8fr] items-center gap-4'}>
          <Typography>
            {participantsExpectationsTranslations(
              'fields.isParticipantExpectationChangedYes.label'
            )}
          </Typography>
          <TextArea
            control={control.register('participantExpectationChangeText')}
            containerClassName={'w-full'}
            readOnly={selectedOption !== participantExpectationsChangeTypes.Changed}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (watch('isParticipantExpectationChanged') === participantExpectationsChangeTypes.UnChanged) {
      setValue('participantExpectationChangeText', '');
    }
  }, [watch('isParticipantExpectationChanged')]);

  return (
    <Accordion
      title={
        <Typography type={'H3'}>{participantsExpectationsTranslations('title.label')}</Typography>
      }
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'flex flex-col mt-4'}>
        <Typography>{participantsExpectationsTranslations('expectationsChanged.label')}</Typography>
        <RadioButtonGroup
          options={options}
          name={'isParticipantExpectationChanged'}
          className={'mt-4'}
          itemClassName={'w-full'}
        />
      </div>
    </Accordion>
  );
};
