import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.integrationPlan.summaryFA;

const summaryFA = createSelector([selector], (state) => state.summaryFA);
const summaryFALock = createSelector([selector], (state) => state.summaryFALock);
const updateSummaryFALock = createSelector([selector], (state) => state.updateSummaryFALock);

export const summaryFASelectors = {
  summaryFA,
  summaryFALock,
  updateSummaryFALock,
};
