import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  IItemICF,
  itemsICFActions,
} from '@/core/redux/slices/functions/diagnostics/itemsICF/itemsICFSlice';
import { itemsICFSelectors } from '@/core/redux/slices/functions/diagnostics/itemsICF/selectors';
import { diagnosticsModalsActions } from '@/core/redux/slices/modalsSlice/functions/diagnostics/diagnosticsModalsSlice';

export interface IItemsICF {
  personID?: number | null;
}

export const ItemsICF: React.FC<IItemsICF> = ({ personID }) => {
  const { t: itemsICFTranslations } = useTranslation('itemsICF');
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const itemsICF = useAppSelector(itemsICFSelectors.itemsICFData);

  const dispatch = useAppDispatch();

  const handleAdd = () => {
    personID &&
      dispatch(
        diagnosticsModalsActions.openAddICFItemsModal({
          personID: personID,
          selectedPage: selectedPage,
        })
      );
  };

  useEffect(() => {
    personID &&
      dispatch(
        itemsICFActions.fetchItemsICF({
          personID: personID,
          limit: 0,
          page: 0,
        })
      );
  }, [dispatch, personID, selectedPage]);

  return (
    <div className='h-full flex flex-col'>
      <div className='flex-1 overflow-y-auto'>
        {itemsICF.map((code: IItemICF) => (
          <div key={code.id} className='flex border-b'>
            <div className='w-1/3 py-3'>{code.code}</div>
            <div className='w-2/3 py-3'>{code.name}</div>
          </div>
        ))}
      </div>
      <div className='mt-5 flex justify-start w-full'>
        <Button onClick={handleAdd}>{itemsICFTranslations('buttons.add.label')}</Button>
      </div>
    </div>
  );
};
