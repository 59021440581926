import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { motion } from 'framer-motion';

import { useAdministrationChecklistSelector } from '@/components/administartionFunctions/checklist/AdministrationChecklistContext/AdministrationChecklistContext';
import { Button } from '@/components/Button/Button';
import { SmallDraggableIcon } from '@/components/Icons/SmallDraggableIcon';
import { Typography } from '@/components/Typography/Typography';
import { IAdministrationChecklistCheckpoint } from '@/core/redux/slices/administrationFunctions/checkList/slice';
import { colorTypes } from '@/styles/types';

export const AdministrationCheckpointItem: React.FC<IAdministrationChecklistCheckpoint> = (
  checkpoint
) => {
  const { attributes, listeners, setNodeRef, isDragging, transform, transition } = useSortable({
    id: checkpoint.id,
  });

  const style = {
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleX: isDragging ? 0.97 : transform.scaleX,
        scaleY: isDragging ? 0.97 : transform.scaleY,
      }
    ),
    transition,
    transformOrigin: 'right center',
  };

  const [selectedCheckpoint, setSelectedCheckpoint] = useAdministrationChecklistSelector(
    (state) => state.selectedCheckpoint
  );
  const isSelected = selectedCheckpoint?.id === checkpoint.id;

  const handleClick = () => {
    setSelectedCheckpoint({ selectedCheckpoint: checkpoint });
  };

  const activeStyles = isDragging
    ? `text-${colorTypes.White}`
    : `text-${colorTypes.White} opacity-50 hover:opacity-100`;
  const defaultStyles = isDragging
    ? `text-${colorTypes.Blue}`
    : `text-${colorTypes.Secondary} hover:text-${colorTypes.Blue}`;

  return (
    <motion.div
      ref={setNodeRef}
      animate={{
        zIndex: isDragging ? 1 : 0,
      }}
      className={`relative grid grid-cols-12 gap-3 items-center h-[44px]
      bg-${isSelected ? colorTypes.Blue : colorTypes.HoverLight} 
      ${isDragging && 'rounded-default'}
      border-b border-b-${colorTypes.StrokeLight} p-2`}
      style={style}
      onClick={handleClick}
    >
      {isDragging && (
        <div
          className={`absolute inset-0 rounded-default border-2 border-${colorTypes.Blue} border-dashed`}
          style={{ top: '-3px', bottom: '-3px', left: '-3px', right: '-3px' }}
        />
      )}
      <Typography className={'col-span-9'} color={isSelected ? 'White' : 'Primary'}>
        {checkpoint.name}
      </Typography>
      <Typography
        className={'col-span-2 justify-self-center'}
        color={isSelected ? 'White' : 'Primary'}
      >
        {checkpoint.days}
      </Typography>
      <Button buttonVariant={'Icon'} buttonStyle={'Icon'} {...attributes} {...listeners}>
        <SmallDraggableIcon className={isSelected ? activeStyles : defaultStyles} />
      </Button>
    </motion.div>
  );
};
