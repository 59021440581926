import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { PersonGeneralDataViews } from '@/core/enums/personGeneralDataViewsEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { colorTypes } from '@/styles/types';

import { Addresses } from './Addresses/Addresses';
import { ParticipantData } from './ParticipantData/ParticipantData';

interface View {
  type: PersonGeneralDataViews;
  view: JSX.Element;
  title: string;
}

export const PersonGeneralData: React.FC = () => {
  const { t: personGeneralDataTranslations } = useTranslation('personGeneralData');

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const personID = selectedPerson?.id;

  const [selectedView, setSelectedView] = useState<PersonGeneralDataViews>(
    PersonGeneralDataViews.ParticipantData
  );

  const pesontGeneralDataTabs: Record<PersonGeneralDataViews, View> = {
    [PersonGeneralDataViews.ParticipantData]: {
      type: PersonGeneralDataViews.ParticipantData,
      view: <ParticipantData personID={personID} />,
      title: personGeneralDataTranslations('tabs.participantData.label'),
    },
    [PersonGeneralDataViews.Addresses]: {
      type: PersonGeneralDataViews.Addresses,
      view: <Addresses personID={personID} />,
      title: personGeneralDataTranslations('tabs.addresses.label'),
    },
  };

  const handleTabClick = (view: PersonGeneralDataViews) => {
    setSelectedView(view);
  };

  return (
    <FunctionWrapper>
      <FunctionContent className={'flex flex-col'}>
        <div className={`flex flex-row gap-3 border-b border-b-${colorTypes.Stroke} px-7`}>
          {Object.values(pesontGeneralDataTabs).map((view) => (
            <Button
              onClick={() => handleTabClick(view.type)}
              buttonVariant={selectedView === view.type ? 'Default' : 'Secondary'}
              key={view.type}
              className={'rounded-b-none px-4 pt-2 pb-[7px]'}
            >
              {view.title}
            </Button>
          ))}
        </div>
        {pesontGeneralDataTabs[selectedView].view}
      </FunctionContent>
    </FunctionWrapper>
  );
};
