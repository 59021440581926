import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const CompleteAppointmentTableHeader: React.FC = () => {
  const { t: completeAppointmentModalTranslations } = useTranslation('completeAppointmentModal', {
    keyPrefix: 'table.header',
  });

  return (
    <div
      className={`grid grid-cols-12 items-center border-y border-${colorTypes.Stroke} px-[30px] py-3`}
    >
      <Typography color={'Secondary'} className={'col-span-1'}>
        W
      </Typography>
      <Typography color={'Secondary'} className={'col-span-3'}>
        {completeAppointmentModalTranslations('remark.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeAppointmentModalTranslations('fromTimestamp.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeAppointmentModalTranslations('toTimestamp.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeAppointmentModalTranslations('hours.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeAppointmentModalTranslations('isUnderDirection.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeAppointmentModalTranslations('isParticipated.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeAppointmentModalTranslations('isAlmostIndependent.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeAppointmentModalTranslations('isIndependent.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {completeAppointmentModalTranslations('comment.label')}
      </Typography>
    </div>
  );
};
