import React from 'react';

import { ImportInternalDifferentiationModalContent } from '@/components/Modals/Modals/functions/testingResultModals/ImportInternalDifferentiationModal/ImportInternalDifferentiationModalContent/ImportInternalDifferentiationModalContent';
import { useAppSelector } from '@/core/redux/hooks';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';

export const ImportInternalDifferentiationModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    testingResultModalsSelectors.importInternalDifferentiationModal
  );

  return isOpened ? <ImportInternalDifferentiationModalContent /> : null;
};
