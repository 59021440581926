import { TFunction } from 'i18next';
import { ObjectSchema, string } from 'yup';

import { participantExpectationFunctionsTypes } from '@/core/enums/participantExpectationFunctionsEnum';
import { IParticipantExpectation } from '@/core/redux/slices/functions/integrationPlan/participantExpectation/participantExpectationSlice';
import { yup } from '@/core/utils/commonUtils';

export const participantExpectationResolver = (
  translations: TFunction<'participantExpectation', undefined>
): ObjectSchema<IParticipantExpectation> =>
  yup.object().shape({
    creationReason: string().optional().nullable(),
    individualAppointmentReason: string()
      .optional()
      .when('creationReason', {
        is: (value: string) =>
          value === participantExpectationFunctionsTypes.isIndividualAppointment,
        then: () => string().required(translations('errors.required.label')),
        otherwise: () => string().optional().nullable(),
      }),
    inm1: string().optional().nullable(),
    inm1Method: string().optional().nullable(),
    inm2: string().optional().nullable(),
    inm2Method: string().optional().nullable(),
    inm3: string().optional().nullable(),
    inm3Method: string().optional().nullable(),
    inm4: string().optional().nullable(),
    inm4Method: string().optional().nullable(),
    wem1: string().optional().nullable(),
    wem1Method: string().optional().nullable(),
    wem2: string().optional().nullable(),
    wem2Method: string().optional().nullable(),
    wem3: string().optional().nullable(),
    wem3Method: string().optional().nullable(),
    notes: string().optional().nullable(),
  });
