import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import clsx from 'clsx';
import fp from 'lodash/fp';

import { WidgetContainer } from '@/components/views/common/Dashboard/WidgetContainer/WidgetContainer';
import { IDragBlock } from '@/types/dragBlock';
import { IDragColumn } from '@/types/dragContainer';

import styles from './styles.module.scss';

export const DragColumn: React.FC<IDragColumn> = ({ column, items, activeId }) => {
  const { setNodeRef } = useDroppable({
    id: column,
  });
  const filteredEmpty = fp.filter<IDragBlock>(fp.isObject, items);

  return (
    <SortableContext id={column} items={items} strategy={verticalListSortingStrategy}>
      <div ref={setNodeRef} className={clsx('flex flex-col', styles.column)}>
        {filteredEmpty.map((item) => (
          <WidgetContainer
            key={item.id}
            {...item}
            dragged={item.id === activeId}
            widgetName={item.widgetName}
          />
        ))}
      </div>
    </SortableContext>
  );
};
