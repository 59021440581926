import { GoalsSection } from './GoalsSection/GoalsSection';
import { MeasuresSection } from './MeasuresSection/MeasuresSection';

export const SmartGoals: React.FC = () => {

  return (
    <div className='h-full flex flex-col gap-4 py-5 px-8'>
      <GoalsSection isControled className='basis-6/12 flex-grow' />
      <MeasuresSection className='basis-6/12 flex-grow' />
    </div>
  );
};
