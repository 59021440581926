import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import {
  IHorizontalScrollableContainer,
  ScrollDirection,
} from '@/types/horizontalScrollableContainer';

import styles from './styles.module.scss';

const SCROLL_STEP = 250;

export const HorizontalScrollableContainer: React.FC<IHorizontalScrollableContainer> = ({
  children,
  className,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [showArrows, setShowArrows] = useState(false);

  const updateArrowVisibility = () => {
    if (contentRef.current) {
      const { scrollWidth, clientWidth } = contentRef.current;
      setShowArrows(scrollWidth > clientWidth);
    }
  };

  useEffect(() => {
    updateArrowVisibility();
    window.addEventListener('resize', updateArrowVisibility);
    return () => {
      window.removeEventListener('resize', updateArrowVisibility);
    };
  }, [children]);

  const handleScroll = (direction: ScrollDirection) => {
    if (contentRef.current) {
      const scrollStep = direction === ScrollDirection.right ? SCROLL_STEP : -SCROLL_STEP;
      contentRef.current.scrollBy({
        left: scrollStep,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={clsx('flex items-center relative z-10', className)}>
      {showArrows && (
        <Button
          className='absolute left-[14px] rotate-180 w-[16px] px-0 py-0'
          onClick={() => handleScroll(ScrollDirection.left)}
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
        >
          <ArrowRightIcon />
        </Button>
      )}
      <div ref={contentRef} className={clsx(styles.content, 'flex overflow-hidden mx-[34px]')}>
        {children}
      </div>
      {showArrows && (
        <Button
          className='absolute right-[14px] w-[16px] px-0 py-0'
          onClick={() => handleScroll(ScrollDirection.right)}
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
        >
          <ArrowRightIcon />
        </Button>
      )}
    </div>
  );
};
