import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  functionsPageModalsActions,
  IPersonInfo,
} from '@/core/redux/slices/modalsSlice/functionsPage/functionsPageModalsSlice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';
import { IPerson } from '@/types/person';

type PersonInfoResponse = OASOutput<NormalizeOAS<typeof oas>, '/person/main_info', 'get', '200'>;

function* fetchPersonInfo(
  action: PayloadAction<IPerson>
): Generator<any, void, PersonInfoResponse> {
  const { id } = action.payload;

  try {
    yield put(functionsPageModalsActions.setPersonInfoLock(LoadingStatus.LOADING));

    const { main_info } = yield call(restCall, '/person/main_info', 'get', {
      query: {
        person_id: id,
      },
      ...authAdd(),
    });

    const personInfo: IPersonInfo = {
      name: main_info.name,
      surname: main_info.surname,
      birthDate: main_info.birth_date ? toClientDateInput(main_info.birth_date) : null,
      groupManager: main_info.group_manager,
      socialServiceWorker: main_info.social_service_worker,
      inactiveSince: main_info.inactive_timestamp,
      educationMentor: main_info.educational_supporter,
    };

    yield put(functionsPageModalsActions.setPersonInfo(personInfo));
    yield put(functionsPageModalsActions.setPersonInfoLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on fetching person info');
    yield put(functionsPageModalsActions.setPersonInfoLock(LoadingStatus.ERROR));
  }
}

export const functionsPageModalsSagas = [
  takeLatest(functionsPageModalsActions.fetchPersonInfo, fetchPersonInfo),
];
