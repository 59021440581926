import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import { generateName } from '@/core/utils/commonUtils';
import { IPerson } from '@/types/activitiesWidget';

export const PersonItem: React.FC<IPerson> = ({ name, surname, id }) => {
  const formContext = useFormContext();

  const fieldName = `person_id=${id}`;

  useEffect(() => {
    formContext.setValue(fieldName, true);
  }, []);

  return (
    <div className={'flex flex-row gap-[10px]'}>
      <Checkbox name={fieldName} id={id} />
      <label className={'cursor-pointer'} htmlFor={id}>
        <Typography>{generateName({ name, surname })}</Typography>
      </label>
    </div>
  );
};
