import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { medicationActions } from '@/core/redux/slices/functions/medication/medicationSlice';
import { medicationModalsActions } from '@/core/redux/slices/modalsSlice/functions/medication/medicationModalsSlice';
import { medicationModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/medication/selector';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';

const ModalContent: React.FC = () => {
  const { payload } = useAppSelector(medicationModalsSelectors.stopAdministratingModal);
  const { t: stopAdministrationModalTranslations } = useTranslation('stopAdministrationModal');

  const form = useForm({
    defaultValues: {
      date: toClientDateInput(new Date().toISOString()) ?? '',
    },
  });

  const { control } = form;

  const medicationID = payload?.medicationID;
  const personID = payload?.personID;

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(medicationModalsActions.closeStopAdministratingModal());
  };

  const deleteAddress = (id: number | undefined, date: string) => {
    if (!personID || !date || id === undefined) {
      return;
    }

    dispatch(
      medicationActions.stopAdministrating({
        personID: personID,
        medicationID: id,
        date: date,
      })
    );

    handleExit();
  };

  const renderFooter = (
    <div className='flex gap-2.5 px-8 py-4 justify-end'>
      <Button type='button' onClick={() => deleteAddress(medicationID, form.getValues('date'))}>
        {stopAdministrationModalTranslations('buttons.confirm.label')}
      </Button>
      <Button type='button' onClick={handleExit} buttonVariant='Secondary'>
        {stopAdministrationModalTranslations('buttons.close.label')}
      </Button>
    </div>
  );

  return (
    <Modal onExit={handleExit} className='w-[600px]' footer={renderFooter}>
      <div className='flex flex-col gap-4'>
        <Typography>{stopAdministrationModalTranslations('title.label')}</Typography>
        <FormProvider {...form}>
          <DatePicker control={control.register('date')} />
        </FormProvider>
      </div>
    </Modal>
  );
};

export const StopAdministratingModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(medicationModalsSelectors.stopAdministratingModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
