import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { Button } from '@/components/Button/Button';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { Input } from '@/components/Input/Input';
import { ExternalSelectForm } from '@/components/Select/form/ExternalSelectForm';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { UploadFileFormField, UploadFileZone } from '@/components/UploadFileZone/UploadFileZone';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  IDosage,
  medicationActions,
} from '@/core/redux/slices/functions/medication/medicationSlice';
import { medicationSelectors } from '@/core/redux/slices/functions/medication/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IMedicationFormFields {
  id?: number | null;
  name: string;
  isNotDispensed: boolean;
  prescriptionFile: UploadFileFormField;
  isRemarkedOnGiving?: boolean | null;
  dosageFormId?: string | null;
  individualDosage?: string | null;
  maxDailyDosage?: string | null;
  indicationID?: string | null;
  orderedBy?: string | null;
  orderedFromTimestamp?: string | null;
  orderedToTimestamp?: string | null;
  dosages: {
    id: number;
    dosage?: string | null;
    inWorkshop?: boolean | null;
  }[];
  isDemanded?: boolean | null;
  note?: string | null;
}

export interface IMedicationForm {
  onSubmit: (data: IMedicationFormFields) => void;
  dosages?: IDosage[];
  className?: string;
}

export const MedicationForm: React.FC<IMedicationForm> = ({ onSubmit, dosages, className }) => {
  const form = useFormContext<IMedicationFormFields>();
  const { t: displayMedicationModalTranslations } = useTranslation('displayMedicationModal');

  const { control, watch } = form;

  const isNotDispensed = watch('isNotDispensed');

  const medicationID = form.watch('id');
  const isDemanded = form.watch('isDemanded');

  const downloadLoadingStatus = useAppSelector(medicationSelectors.downloadMedicationDocumentLock);
  const isLoading = downloadLoadingStatus === LoadingStatus.LOADING;

  const dispatch = useAppDispatch();

  const handleDownloadMedicationDocument = () => {
    if (!medicationID || medicationID === 0) {
      return;
    }

    dispatch(
      medicationActions.downloadMedicationDocument({
        medicationID,
      })
    );
  };

  const isDosageValid = form.formState.errors.dosages?.root || form.formState.errors.dosages;
  const isDemandedDisabled = medicationID !== 0 || form.watch('isNotDispensed');

  useEffect(() => {
    form.clearErrors();
    form.setValue('isRemarkedOnGiving', isDemanded);
  }, [isDemanded]);

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className={twMerge('flex flex-col gap-4', className)}
    >
      <div>
        <CheckboxItem
          fieldName={'isNotDispensed'}
          label={displayMedicationModalTranslations('fields.isNotDispensed.label')}
          labelPosition={'left'}
        />
      </div>
      <div className='max-w-full'>
        <div className='flex flex-col gap-3'>
          <Input
            control={control.register('name')}
            label={displayMedicationModalTranslations('fields.name.label')}
          />
        </div>
        <div className={twMerge('hidden', isNotDispensed ? 'hidden' : 'flex flex-col gap-4 ')}>
          <div className='flex flex-col gap-3'>
            <label></label>
            <div className='flex gap-4 mt-4'>
              <CheckboxItem
                fieldName={'isDemanded'}
                label={displayMedicationModalTranslations('fields.isDemanded.label')}
                disabled={isDemandedDisabled}
                labelPosition={'left'}
              />

              {!isDemanded && (
                <CheckboxItem
                  fieldName={'isRemarkedOnGiving'}
                  label={displayMedicationModalTranslations('fields.isRemarkedOnAwarding.label')}
                />
              )}
            </div>
          </div>
          <ExternalSelectForm
            tableName={DropdownItemsByFetch.DOSAGE_FORM}
            control={control.register('dosageFormId')}
            className='w-full'
            label={displayMedicationModalTranslations('fields.dosageFormId.label')}
            isFlexible
          />

          {isDemanded && (
            <>
              <Input
                control={control.register('individualDosage')}
                label={displayMedicationModalTranslations('fields.dosation.label')}
              />
              <Input
                control={control.register('maxDailyDosage')}
                label={displayMedicationModalTranslations('fields.maxdosation.label')}
              />
              <ExternalSelectForm
                control={control.register('indicationID')}
                className='w-full'
                label={<>{displayMedicationModalTranslations('fields.indicationID.label')}</>}
                tableName={DropdownItemsByFetch.DEMAND_MEDICATION_INDICATION}
                isFlexible
              />
            </>
          )}

          {!isDemanded && (
            <div className='flex flex-col gap-3'>
              <label>{displayMedicationModalTranslations('fields.dosage.label')} *</label>
              <div>
                {dosages && dosages.length && (
                  <div className='flex flex-col gap-4'>
                    {dosages.map((item: IDosage, index) => {
                      const { id, daytime } = item;

                      return (
                        <div key={id} className='grid grid-cols-10 gap-4 items-center'>
                          <div className='col-span-2'>
                            <Typography>{daytime}</Typography>
                          </div>
                          <div className='col-span-5'>
                            <Input
                              control={control.register(`dosages.${index}.dosage`)}
                              onAfterChange={() => {
                                if (form.formState.isSubmitted) {
                                  form.trigger('dosages');
                                }
                              }}
                            />
                          </div>
                          <div className='col-span-3'>
                            <CheckboxItem
                              fieldName={`dosages.${index}.inWorkshop`}
                              label={displayMedicationModalTranslations(
                                'fields.dosage.inWorkshop.label'
                              )}
                              labelPosition={'left'}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className='mt-4'>
                  {isDosageValid && (
                    <Typography type={'14-R'} color={'Error'} className={'mt-1'}>
                      {isDosageValid.message}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          )}

          <Input
            control={control.register('orderedBy')}
            label={displayMedicationModalTranslations('fields.orderedBy.label')}
          />

          <div className='flex flex-col gap-3'>
            <div>
              <div className='flex flex-col gap-4'>
                <div className='flex gap-4'>
                  <DatePicker
                    control={control.register('orderedFromTimestamp')}
                    label={displayMedicationModalTranslations('fields.orderedFromTimestamp.label')}
                  />
                  <DatePicker
                    control={control.register('orderedToTimestamp')}
                    label={displayMedicationModalTranslations('fields.orderedToTimestamp.label')}
                  />
                </div>
                <div className={'flex gap-10 items-center'}>
                  <UploadFileZone control={control.register('prescriptionFile')} />
                  {medicationID !== 0 && (
                    <Button
                      onClick={handleDownloadMedicationDocument}
                      disabled={isLoading}
                      className={'relative'}
                    >
                      {displayMedicationModalTranslations('buttons.download.label')}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <TextArea
            control={control.register('note')}
            label={displayMedicationModalTranslations('fields.note.label')}
          />
        </div>
      </div>
    </form>
  );
};
