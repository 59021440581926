import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface ICheckIntegrationPlanUpdatePayload {
  integrationPlanID: number;
  updateResponse: any;
}

export interface IIntegrationPlanState {
  integrationPlanID: number | null;
  integrationPlanVersionDate: string | null;
  integrationPlanLock: LoadingStatus;
}

const initialState: IIntegrationPlanState = {
  integrationPlanID: null,
  integrationPlanVersionDate: null,
  integrationPlanLock: LoadingStatus.NEVER,
};

export const integrationPlanSlice = createSlice({
  name: 'integrationPlan',
  initialState,
  reducers: {
    fetchIntegrationPlanID: (state, action: PayloadAction<number>) => ({
      ...state,
      payload: action.payload,
    }),
    setIntegrationPlanID: (state, action: PayloadAction<number | null>) => ({
      ...state,
      integrationPlanID: action.payload,
    }),
    setIntegrationPlanVersionDate: (state, action: PayloadAction<string | null>) => ({
      ...state,
      integrationPlanVersionDate: action.payload,
    }),
    setIntegrationPlanLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      integrationPlanLock: action.payload,
    }),
    setIntegrationPlanToInitial: () => {},
    checkIntegrationPlanUpdate: (
      state,
      action: PayloadAction<ICheckIntegrationPlanUpdatePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const integrationPlanReducers = integrationPlanSlice.reducer;
export const integrationPlanActions = integrationPlanSlice.actions;
