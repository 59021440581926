import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { ReviewItem } from '@/components/forms/CompetenceAnalysis/ReviewItem/ReviewItem';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const PersonalSkills: React.FC = () => {
  const { t: personalSkillsTranslations } = useTranslation('competenceAnalysis', {
    keyPrefix: 'personalSkills',
  });

  return (
    <Accordion
      title={<Typography type={'H3'}>{personalSkillsTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'flex flex-col gap-2'}>
        <ReviewItem
          title={personalSkillsTranslations('fields.flexibility.label')}
          fieldName={'flexibility'}
        />
        <ReviewItem
          title={personalSkillsTranslations('fields.selfAssessment.label')}
          fieldName={'selfAssessment'}
        />
        <ReviewItem
          title={personalSkillsTranslations('fields.selfRepresentation.label')}
          fieldName={'selfRepresentation'}
        />
      </div>
    </Accordion>
  );
};
