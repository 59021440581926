const mixinWithAugment = (next: Function) =>
  function* busyWrapper(args: any) {
    yield next();
  };

export const augmentSaga = (
  saga: (...args: any) => any,
  ...wrapperSagas: ((...args: any) => any)[]
) =>
  [mixinWithAugment, ...wrapperSagas].reduceRight(
    (saga, wrapperSaga) =>
      function* aurmenter(...args) {
        yield wrapperSaga(() => saga(...args))(...args);
      },
    saga
  );
