import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useImperativeHandle,
} from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { CommentItem } from '@/components/forms/GeneralData/CommentItem/CommentItem';
import { useGeneralDataForm } from '@/components/forms/GeneralData/hooks/useGeneralDataForm';
import { useUpdatedGeneralData } from '@/components/forms/GeneralData/hooks/useUpdatedGeneralData';
import { IntegrationPlanFormActions } from '@/components/functions/IntegrationPlan/IntegrationPlan';
import { IIntegrationPlanFormProps } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsContainer/IntegrationPlanFormsContainer';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { generalDataActions } from '@/core/redux/slices/functions/integrationPlan/generalData/generalDataSlice';

export const GeneralData: ForwardRefExoticComponent<
  Omit<IIntegrationPlanFormProps, 'ref'> & RefAttributes<IntegrationPlanFormActions>
> = forwardRef((props, ref) => {
  const { integrationPlanID, personID } = props;

  const { t: generalDataTranslations } = useTranslation('generalData');

  const { form, initialValues } = useGeneralDataForm({ integrationPlanID, personID });
  const dispatch = useAppDispatch();

  const onBeforeExitCheck = () => {
    const currentValues = form.watch();

    if (fp.isEqual(currentValues, initialValues)) {
      return true;
    }

    if (!personID || integrationPlanID === null) {
      return true;
    }

    dispatch(
      generalDataActions.updateGeneralData({
        personID: personID,
        integrationPlanID: integrationPlanID,
        generalData: currentValues,
      })
    );

    dispatch(generalDataActions.setGeneralData(null));

    return true;
  };

  useImperativeHandle(ref, () => ({
    onBeforeExitCheck: onBeforeExitCheck,
  }));

  useUpdatedGeneralData(integrationPlanID, personID);

  useEffect(() => {
    if (!integrationPlanID || !personID) {
      return;
    }

    dispatch(
      generalDataActions.fetchGeneralData({
        integrationPlanID: integrationPlanID,
        personID: personID,
      })
    );
  }, [integrationPlanID, personID]);

  return (
    <FormProvider {...form}>
      <form className={'flex flex-col gap-4'}>
        <Typography type={'H3'}>{generalDataTranslations('title.label')}</Typography>
        <CommentItem
          label={generalDataTranslations('fields.disabilityType.label')}
          fieldName={'disabilityType'}
          readOnly={true}
        />
        <CommentItem
          label={generalDataTranslations('fields.healthImpact.label')}
          fieldName={'healthImpact'}
        />
        <CommentItem
          label={generalDataTranslations('fields.schoolEducation.label')}
          fieldName={'schoolEducation'}
        />
        <CommentItem
          label={generalDataTranslations('fields.professionalMeasuresInformationBeforeWfbM.label')}
          fieldName={'professionalMeasuresInformationBeforeWfbM'}
        />
        <CommentItem
          label={generalDataTranslations('fields.publicTransportUsageNote.label')}
          fieldName={'publicTransportUsageNote'}
        />
        <CommentItem label={generalDataTranslations('fields.other.label')} fieldName={'other'} />
      </form>
    </FormProvider>
  );
});
