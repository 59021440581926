import { TFunction } from 'i18next';
import { boolean, mixed, ObjectSchema, string } from 'yup';

import { StatusReportCourseOfMeasuresQuestionTypes } from '@/core/enums/functions/integrationPlan/statusReport/StatusReportCourseOfMeasuresQuestionTypesEnum';
import { IStatusReport } from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { yup } from '@/core/utils/commonUtils';

const requiredByCheck = (
  checkFieldName: string,
  translations: TFunction<'statusReport', undefined>
) =>
  string()
    .optional()
    .when(checkFieldName, {
      is: true,
      then: () => string().required(translations('errors.required.label')),
      otherwise: () => string().optional().nullable(),
    });

export const statusReportResolver = (
  translations: TFunction<'statusReport', undefined>
): ObjectSchema<IStatusReport> =>
  yup.object().shape({
    documentNumber: string().optional(),
    actionCourseTimestamp: string().optional().nullable(),
    isFirstYearVocationalTraining: boolean().required(),
    isSecondYearVocationalTraining: boolean().required(),
    isFurtherIndividualAppointment: boolean().required(),
    furtherIndividualAppointmentReason: requiredByCheck(
      'isFurtherIndividualAppointment',
      translations
    ),
    reportingPeriodFromTimestamp: string().optional().nullable(),
    reportingPeriodToTimestamp: string().optional().nullable(),
    isCurrentlyNoQuestions: boolean().required(),
    isQuestionsNeedClarification: boolean().required(),
    questionsText: yup
      .string()
      .nullable()
      .when('selectedCourseOfMeasuresQuestionType', {
        is: StatusReportCourseOfMeasuresQuestionTypes.isQuestionsNeedClarification,
        then: () => yup.string().required(translations('errors.required.label')),
        otherwise: () => yup.string().nullable(),
      }),
    isCompetenceAnalysisUnchanged: boolean().required(),
    isNewClassification: boolean().required(),
    isActivityOriented: boolean().required(),
    isOccupationalFieldOriented: boolean().required(),
    isWorkplaceOriented: boolean().required(),
    isJobProfileOriented: boolean().required(),
    isParticipantExpectationChanged: string().required(),
    participantExpectationChangeText: string().optional().nullable(),
    participantExpectationChangeMethod: string().optional().nullable(),
    isAdditionalRequirements: boolean().required(),
    isContinued: boolean().required(),
    isVocationalTrainingPlaceRealistic: boolean().required(),
    isTransitionToGeneralLaborMarketRealistic: boolean().required(),
    isDiscussionRequired: boolean().required(),
    qualificationObjectivesDocumentations: mixed().required(),
    selectedOrientation: string().optional(),
    selectedCourseOfMeasuresQuestionType: string().optional(),
  });
