import { useFormContext } from 'react-hook-form';

import { useFecthedCategories } from '@/components/Modals/Modals/logbook/hooks/useFetchLogbookCategories';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { TextArea } from '@/components/TextArea/TextArea';

export const AddNewLogbook: React.FC = () => {
  const form = useFormContext();

  const {options: categoryOptions} = useFecthedCategories()

  return (
    <form className='flex flex-col gap-5'>
      <SelectForm
        control={form.control.register('categoryID')}
        values={categoryOptions}
        isFlexible
      />
      <TextArea control={form.control.register('note')} className='min-h-[260px]' />
    </form>
  );
};
