import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IReport {
  id: string;
  fileName: string;
  name: string;
  isVisible?: boolean | null | undefined;
}

export interface IReportsState {
  reports: IReport[];
}

const initialState: IReportsState = {
  reports: [],
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    fetchReports: (state, action: PayloadAction) => ({
      ...state,
      payload: action.payload,
    }),
    setReports: (state, action: PayloadAction<IReport[]>) => ({
      ...state,
      reports: action.payload,
    }),
  },
});

export const reportsReducer = reportsSlice.reducer;
export const reportsActions = reportsSlice.actions;
