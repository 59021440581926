import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { FunctionFooter } from '@/components/wrapper/FunctionWrapper/FunctionFooter/FunctionFooter';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { simpleSelfAssesmentSelectors } from '@/core/redux/slices/functions/simpleSelfAssesment/selectors';
import {
  ISelfAssessmentQuestionnaire,
  simpleSelfAssesmentActions,
} from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';
import { selfAssesmentModalsActions } from '@/core/redux/slices/modalsSlice/functions/selfAssesment/selfAssesmentModalsSlice';

interface IQuestionnaireAvailable {
  selectedPersonID?: number | null;
  selectedM2sID: string | null;
  availableQuestionnaires: ISelfAssessmentQuestionnaire[];
}

export const QuestionnaireAvailable: React.FC<IQuestionnaireAvailable> = ({
  selectedPersonID,
  selectedM2sID,
  availableQuestionnaires,
}) => {
  const { t: selfAssessmentTranslations } = useTranslation('selfAssesment');

  const selectedPersonQuestionnaire = useAppSelector(
    simpleSelfAssesmentSelectors.selectedPersonQuestionnaire
  );
  const selectedPersonSimpleVersion = useAppSelector(
    simpleSelfAssesmentSelectors.selectedPersonSimpleVersion
  );
  const updatedPersonQuestionnaireID = useAppSelector(
    simpleSelfAssesmentSelectors.updatedPersonQuestionnaireID
  );

  const [questionnaireID, setQuestionnaireID] = useState<string>();
  const [isSimpleVersion, setIsSimpleVersion] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }
    dispatch(simpleSelfAssesmentActions.fetchQuestionnaires());
  }, [selectedPersonID]);

  useEffect(() => {
    if (!selectedPersonID || !selectedPersonQuestionnaire) {
      return;
    }
    if (!(selectedPersonID in selectedPersonQuestionnaire)) {
      return;
    }
    setQuestionnaireID(selectedPersonQuestionnaire[selectedPersonID]);
  }, [selectedPersonID, selectedPersonQuestionnaire]);

  useEffect(() => {
    if (!selectedPersonID || !selectedPersonSimpleVersion) {
      return;
    }
    if (!(selectedPersonID in selectedPersonSimpleVersion)) {
      return;
    }
    setIsSimpleVersion(selectedPersonSimpleVersion[selectedPersonID]);
  }, [selectedPersonID, selectedPersonSimpleVersion]);

  useEffect(() => {
    if (!updatedPersonQuestionnaireID) {
      return;
    }
    updateSelectedQuestionnaire(updatedPersonQuestionnaireID);
    dispatch(simpleSelfAssesmentActions.setUpdatedPersonQuestionnaireID(null));
  }, [updatedPersonQuestionnaireID]);

  const questionnaireSelectOptions: ISelectItem[] = useMemo(
    () =>
      availableQuestionnaires?.map(
        (item): ISelectItem => ({
          id: item.sebID,
          component: item.name,
        })
      ),
    [availableQuestionnaires]
  );

  const createQuestionnaire = (questionnaireID: string) => {
    selectedPersonID &&
      questionnaireID &&
      dispatch(
        simpleSelfAssesmentActions.createSelfAssessmentForPerson({
          variables: {
            data: {
              ma_id: Number(selectedPersonID),
              seb_id: Number(questionnaireID),
              new_von: 3,
              selbst_beantwortet: true,
            },
          },
        })
      );
  };

  const updateSelectedQuestionnaire = (m2sID: string) => {
    if (!m2sID) {
      return;
    }
    isSimpleVersion
      ? dispatch(
          selfAssesmentModalsActions.openSimpleQuestionnaireModal({
            m2sID: m2sID,
          })
        )
      : dispatch(
          selfAssesmentModalsActions.openDefaultQuestionnaireModal({
            m2sID: m2sID,
          })
        );
  };

  const handleSelect = (questionnaireID: string) => {
    selectedPersonID &&
      dispatch(
        simpleSelfAssesmentActions.setSelectedPersonQuestionnaire({
          personID: selectedPersonID,
          questionnaireID: questionnaireID,
        })
      );
  };

  const handleSimpleVersion = (checked: boolean) => {
    selectedPersonID &&
      dispatch(
        simpleSelfAssesmentActions.setSelectedPersonIsSimpleVersion({
          personID: selectedPersonID,
          isSimpleVersion: checked,
        })
      );
  };

  return (
    <FunctionFooter>
      <div className={'flex gap-4 items-center'}>
        <Select
          values={questionnaireSelectOptions}
          onChange={(value) => {
            handleSelect(value);
          }}
          initialValueID={questionnaireID}
        />
        {questionnaireID && (
          <Button onClick={() => createQuestionnaire(questionnaireID)}>
            {selfAssessmentTranslations('buttons.create.label')}
          </Button>
        )}
        {selectedM2sID && (
          <Button onClick={() => updateSelectedQuestionnaire(selectedM2sID)}>
            {selfAssessmentTranslations('buttons.update.label')}
          </Button>
        )}
        {(questionnaireID || selectedM2sID) && (
          <label className='w-fit flex gap-4 cursor-pointer p-5'>
            <CheckboxUI
              checked={isSimpleVersion}
              className='pointer-events-none'
              onChange={(checked) => {
                handleSimpleVersion(checked);
              }}
            />
            <Typography>{selfAssessmentTranslations('filter.isSimpleVersion.label')}</Typography>
          </label>
        )}
      </div>
    </FunctionFooter>
  );
};
