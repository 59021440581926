import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.medication;

const medications = createSelector([selector], (state) => state.medications);
const medicationsLock = createSelector([selector], (state) => state.medicationsLock);

const medicationDetails = createSelector([selector], (state) => state.medicationDetails);
const medicationDetailsLock = createSelector([selector], (state) => state.medicationDetailsLock);

const createMedicationLock = createSelector([selector], (state) => state.createMedicationsLock);
const updateMedicationLock = createSelector([selector], (state) => state.updateMedicationsLock);

const downloadMedicationDocumentLock = createSelector(
  [selector],
  (state) => state.downloadMedicationDocumentLock
);

export const medicationSelectors = {
  medications,
  medicationsLock,

  medicationDetails,
  medicationDetailsLock,

  createMedicationLock,
  updateMedicationLock,

  downloadMedicationDocumentLock,
};
