import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';
import { IPerson } from '@/types/person';

export interface IModal {
  isRender: boolean;
}

export interface IPersonInfo {
  name?: string | null;
  surname?: string | null;
  educationMentor?: string | null;
  birthDate?: string | null;
  inactiveSince?: string | null;
  socialServiceWorker?: string | null;
  groupManager?: string | null;
}

interface IPersonInfoModal extends IModal {
  personInfo: IPersonInfo | null;
  personInfoLock: LoadingStatus;
  payload: IPerson | null;
}

export interface IFunctionsPageModalsState {
  personInfoModal: IPersonInfoModal;
}

const initialState: IFunctionsPageModalsState = {
  personInfoModal: {
    isRender: false,
    personInfo: null,
    personInfoLock: LoadingStatus.NEVER,
    payload: null,
  },
};

export const functionPageModalsSlice = createSlice({
  name: 'functionsPageModals',
  initialState,
  reducers: {
    openPersonInfoModal: (state, action: PayloadAction<IPerson>) => ({
      ...state,
      personInfoModal: {
        ...state.personInfoModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closePersonInfoModal: (state) => ({
      ...state,
      personInfoModal: {
        isRender: false,
        payload: null,
        personInfo: null,
        personInfoLock: LoadingStatus.NEVER,
      },
    }),
    fetchPersonInfo: (state, action: PayloadAction<IPerson>) => ({
      ...state,
      personInfoModal: {
        ...state.personInfoModal,
        payload: action.payload,
      },
    }),
    setPersonInfo: (state, action: PayloadAction<IPersonInfo>) => ({
      ...state,
      personInfoModal: {
        ...state.personInfoModal,
        personInfo: action.payload,
      },
    }),
    setPersonInfoLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      personInfoModal: {
        ...state.personInfoModal,
        personInfoLock: action.payload,
      },
    }),
  },
});

export const functionsPageModalsReducer = functionPageModalsSlice.reducer;
export const functionsPageModalsActions = functionPageModalsSlice.actions;
