import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DashboardRoute from '@/pages/dashboard/DashboardRoute';

const DashboardRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route index element={<DashboardRoute />} />
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </Routes>
  );
};

export default DashboardRootRoute;
