import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IQualificationObjectivesModalItem } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IQualificationDocumentationItem {
  qualification_objective_id: number;
  isQualificationObjectiveFixed: boolean;
  qualificationObjectiveName: string;
  status: string;
  agreementTimestamp?: string | null;
  qualificationObjectiveComment?: string | null;
  qualificationObjectiveExtension?: string | null;
}

export interface IInternship {
  orderNumber: number;
  internshipName?: string | null;
  internshipLocation?: string | null;
  internshipPeriod?: string | null;
}

export interface IQualificationDocumentation {
  qualificationsDocumentations: Record<string, IQualificationDocumentationItem>;
  internships: Record<string, IInternship>;
}

export interface IQualificationDocumentationFetchPayload {
  integrationPlanID: number;
}

export interface IQualificationDocumentationUpdatePayload
  extends IQualificationDocumentationFetchPayload {
  personID: number;
  qualificationDocumentation: IQualificationDocumentation;
}

export interface IQualificationObjectiveRemovePayload
  extends IQualificationDocumentationUpdatePayload {
  qualificationObjectives: number[];
}

export interface IQualificationDocumentationState {
  qualificationDocumentation: IQualificationDocumentation | null;
  qualificationDocumentationLock: LoadingStatus;
  updateQualificationDocumentationLock: LoadingStatus;
}

const initialState: IQualificationDocumentationState = {
  qualificationDocumentation: null,
  qualificationDocumentationLock: LoadingStatus.NEVER,
  updateQualificationDocumentationLock: LoadingStatus.NEVER,
};

export const qualificationDocumentationSlice = createSlice({
  name: 'qualificationDocumentation',
  initialState,
  reducers: {
    fetchQualificationDocumentation: (
      state,
      action: PayloadAction<IQualificationDocumentationFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    updateQualificationDocumentation: (
      state,
      action: PayloadAction<IQualificationDocumentationUpdatePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    removeQualificationObjective: (
      state,
      action: PayloadAction<IQualificationObjectiveRemovePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setQualificationObjectives: (
      state,
      action: PayloadAction<Record<string, IQualificationDocumentationItem>>
    ) => {
      if (state.qualificationDocumentation)
        return {
          ...state,
          qualificationDocumentation: {
            ...state.qualificationDocumentation,
            qualificationsDocumentations: action.payload,
          },
        };
    },
    updateQualificationObjectives: (
      state,
      action: PayloadAction<Record<string, IQualificationObjectivesModalItem>>
    ) => {
      return {
        ...state,
        payload: action.payload,
      };
    },
    setQualificationDocumentationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      qualificationDocumentationLock: action.payload,
    }),
    setUpdateQualificationDocumentationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateQualificationDocumentationLock: action.payload,
    }),
    setQualificationDocumentation: (
      state,
      action: PayloadAction<IQualificationDocumentation | null>
    ) => ({
      ...state,
      qualificationDocumentation: action.payload,
    }),
  },
});

export const qualificationDocumentationReducers = qualificationDocumentationSlice.reducer;

export const qualificationDocumentationActions = qualificationDocumentationSlice.actions;
