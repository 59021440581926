export interface IDevelopmentReportReview {
  lfdNr?: number | null;
  selectedReview?: string;
  comment?: string | null;
  goal?: string | null;
  smartGoalId?: number | null;
}

export interface IDevelopmentReportReviewSupportForm {
  reviews: IDevelopmentReportReview[];
}

export const reviewSupportDefaultValues: IDevelopmentReportReviewSupportForm = {
  reviews: [],
};
