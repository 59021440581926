import React from 'react';

export const EmailPlusIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1054_8727)'>
      <path
        d='M24 10V19C23.9984 20.3256 23.4711 21.5964 22.5338 22.5338C21.5964 23.4711 20.3256 23.9984 19 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19L0 8C0.00158786 6.67441 0.528882 5.40356 1.46622 4.46622C2.40356 3.52888 3.67441 3.00159 5 3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289C13.8946 3.48043 14 3.73478 14 4C14 4.26522 13.8946 4.51957 13.7071 4.70711C13.5196 4.89464 13.2652 5 13 5H5C4.45621 5.0015 3.92312 5.15128 3.45812 5.43321C2.99311 5.71514 2.6138 6.11854 2.361 6.6L9.878 14.118C10.4417 14.6791 11.2047 14.9941 12 14.9941C12.7953 14.9941 13.5583 14.6791 14.122 14.118L17.943 10.3C18.1316 10.1178 18.3842 10.017 18.6464 10.0193C18.9086 10.0216 19.1594 10.1268 19.3448 10.3122C19.5302 10.4976 19.6354 10.7484 19.6377 11.0106C19.64 11.2728 19.5392 11.5254 19.357 11.714L15.536 15.536C14.5972 16.472 13.3257 16.9976 12 16.9976C10.6743 16.9976 9.40276 16.472 8.464 15.536L2 9.071V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V10C22 9.73478 22.1054 9.48043 22.2929 9.29289C22.4804 9.10536 22.7348 9 23 9C23.2652 9 23.5196 9.10536 23.7071 9.29289C23.8946 9.48043 24 9.73478 24 10ZM17 5H19V7C19 7.26522 19.1054 7.51957 19.2929 7.70711C19.4804 7.89464 19.7348 8 20 8C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7V5H23C23.2652 5 23.5196 4.89464 23.7071 4.70711C23.8946 4.51957 24 4.26522 24 4C24 3.73478 23.8946 3.48043 23.7071 3.29289C23.5196 3.10536 23.2652 3 23 3H21V1C21 0.734784 20.8946 0.48043 20.7071 0.292893C20.5196 0.105357 20.2652 0 20 0C19.7348 0 19.4804 0.105357 19.2929 0.292893C19.1054 0.48043 19 0.734784 19 1V3H17C16.7348 3 16.4804 3.10536 16.2929 3.29289C16.1054 3.48043 16 3.73478 16 4C16 4.26522 16.1054 4.51957 16.2929 4.70711C16.4804 4.89464 16.7348 5 17 5Z'
        fill='#B7284D'
      />
    </g>
    <defs>
      <clipPath id='clip0_1054_8727'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
