import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { RadioButtonCheckedIcon } from '@/components/Icons/RadioButtonCheckedIcon';
import { RadioButtonUncheckedIcon } from '@/components/Icons/RadioButtonUncheckedIcon';

export interface IRadioButton
  extends DetailedHTMLProps<HTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  value: string;
  name: string;
  id: string;
  customClick?: (event: React.MouseEvent, value: string) => string;
  preventDefault?: boolean;
  readOnly?: boolean;
}

export const RadioButton: React.FC<IRadioButton> = ({
  value,
  name,
  id,
  customClick,
  preventDefault,
  readOnly,
}) => {
  const formContext = useFormContext();
  const register = formContext.register(name);

  const handleChange = (event: React.MouseEvent) => {
    if (readOnly) {
      return;
    }

    let fieldValue = value;

    if (customClick) {
      fieldValue = customClick(event, value);
    }

    if (preventDefault) {
      event.preventDefault();
      return;
    }

    formContext.setValue(name, fieldValue, { shouldDirty: true });
    formContext.trigger(name);
  };

  const selected = formContext.watch(name);

  return (
    <div
      className={'relative cursor-pointer w-[24px] h-[24px] flex items-center justify-center'}
      onClick={handleChange}
    >
      <input
        type={'radio'}
        value={value}
        className={'appearance-none'}
        id={id}
        readOnly={readOnly}
        {...register}
      />
      <div
        className={clsx(
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10',
          selected === value ? 'text-blue hover:text-grey' : 'text-grey hover:text-blue'
        )}
      >
        {selected === value ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
      </div>
    </div>
  );
};
