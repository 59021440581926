import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// payloads

export interface IAssignedSpecialistsModalPayload {
  departmentID: number;
}

export interface IOrganizationUnitsSpecialistsModalPayload {
  organizationUnitID: number;
}

// interfaces

export interface IDepartmentSpecialistsModal {
  isRender: boolean;
  payload: IAssignedSpecialistsModalPayload | null;
}

export interface IOrganizationUnitSpecialistsModal {
  isRender: boolean;
  payload: IOrganizationUnitsSpecialistsModalPayload | null;
}

// state

export interface ISpecialistGeneralDataModalsState {
  assignedSpecialistsModal: IDepartmentSpecialistsModal;
  organizationUnitSpecialistsModal: IOrganizationUnitSpecialistsModal;
}

const initialState: ISpecialistGeneralDataModalsState = {
  assignedSpecialistsModal: {
    isRender: false,
    payload: null,
  },
  organizationUnitSpecialistsModal: {
    isRender: false,
    payload: null,
  },
};

export const specialistGeneralDataModalsSlice = createSlice({
  name: 'specialistGeneralDataModalsSlice',
  initialState,
  reducers: {
    // Display department assigned specialists modal
    openAssignedSpecialistsModal: (
      state,
      action: PayloadAction<IAssignedSpecialistsModalPayload>
    ) => ({
      ...state,
      assignedSpecialistsModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAssignedSpecialistsModal: (state) => ({
      ...state,
      assignedSpecialistsModal: {
        isRender: false,
        payload: null,
      },
    }),
    // Display organization unit assigned specialists modal
    openOrganizationUnitSpecialistsModal: (
      state,
      action: PayloadAction<IOrganizationUnitsSpecialistsModalPayload>
    ) => ({
      ...state,
      organizationUnitSpecialistsModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeOrganizationUnitSpecialistsModal: (state) => ({
      ...state,
      organizationUnitSpecialistsModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const specialistGeneralDataModalsReducer = specialistGeneralDataModalsSlice.reducer;
export const specialistGeneralDataModalsActions = specialistGeneralDataModalsSlice.actions;
