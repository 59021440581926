import React from 'react';

import { WorkingTimePredefinedVariantsModalContent } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/WorkingTimePredefinedVariantsModalContent';
import { useAppSelector } from '@/core/redux/hooks';
import { workingTimeMobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/selectors';

export const WorkingTimePredefinedVariantsModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    workingTimeMobilityModalsSelectors.workingTypePredefinedVariantsModal
  );

  return isOpened ? <WorkingTimePredefinedVariantsModalContent /> : null;
};
