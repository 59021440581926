import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

// interfaces
export interface ILocation {
  name: string;
  isSelected: boolean;
  id: number;
}

export interface IInternship {
  name?: string | null;
  isSelected: boolean;
  id: number;
  locations: ILocation[];
}

//payloads
export interface IInternshipsFetchPayload {
  specialistID: number;
}

export interface IInternshipUpdatePayload {
  specialistID: number;
  internshipID: number;
  isSelected: boolean;
}

export interface ILocationUpdatePayload {
  specialistID: number;
  internshipID: number;
  locationID: number;
  isSelected: boolean;
}

//state
export interface IInternshipsState {
  internships: IInternship[];
  internshipsLock: LoadingStatus;
  updateInternshipLock: LoadingStatus;
  updateLocationLock: LoadingStatus;
}

const initialState: IInternshipsState = {
  internships: [],
  internshipsLock: LoadingStatus.NEVER,
  updateInternshipLock: LoadingStatus.NEVER,
  updateLocationLock: LoadingStatus.NEVER,
};

export const internshipsSlice = createSlice({
  name: 'internships',
  initialState,
  reducers: {
    //internships
    fecthInternships: (state, action: PayloadAction<IInternshipsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setInternshipsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      internshipsLock: action.payload,
    }),
    setInternships: (state, action: PayloadAction<IInternship[]>) => ({
      ...state,
      internships: action.payload ?? null,
    }),
    // update internship
    updateInternship: (state, action: PayloadAction<IInternshipUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateInternshipLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateGeneralDataLock: action.payload,
    }),
    // update location
    updateLocation: (state, action: PayloadAction<ILocationUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateLocationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateLocationLock: action.payload,
    }),
  },
});

export const internshipsReducer = internshipsSlice.reducer;
export const internshipsActions = internshipsSlice.actions;
