import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { RollUpIcon } from '@/components/Icons/RollUpIcon';
import { UserIcon } from '@/components/Icons/UserIcon';
import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { specialistsSelectors } from '@/core/redux/slices/specialists/selectors';
import { specialistsActions } from '@/core/redux/slices/specialists/specilistsSlice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { IPerson } from '@/types/person';

import { SelectedSpecialistItem } from './SelectedSpecialisttem';

const searchInput = 'search-specialists';

export const SelectedSpecialistsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t: specialistsTranslations } = useTranslation('specialists');

  const specialists = useAppSelector(specialistsSelectors.specialists);
  const selectedSpecialist = useAppSelector(specialistsSelectors.selectedSpecialist);

  const form = useForm();
  const searchValue = form.watch(searchInput);

  const [isExpanded, setIsExpanded] = useState(true);

  const handleSelectSpecialist = (item: IPerson) => {
    if (selectedSpecialist?.id === item.id) {
      return;
    }

    dispatch(specialistsActions.setSelectedSpecialist(item));
  };

  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    dispatch(
      specialistsActions.fetchSpecialists({
        search: searchValue,
      })
    );
  }, [searchValue]);

  return (
    <AnimatePresence initial={false}>
      <motion.div
        className={twMerge(
          'flex flex-col justify-between pt-[30px] bg-white rounded-t-[30px] h-[calc(100vh_-_138px)]'
        )}
        style={{ minWidth: isExpanded ? '85px' : '280px' }}
        animate={{
          minWidth: isExpanded ? '280px' : '85px',
          maxWidth: isExpanded ? '20%' : '5%',
        }}
        transition={{ duration: 0.2 }}
      >
        <div className='flex flex-col flex-grow'>
          <div
            className={twMerge(
              'flex flex-row gap-3 justify-between items-center',
              isExpanded ? 'px-[20px]' : 'px-3 pb-[15px]'
            )}
          >
            {isExpanded ? (
              <Typography type='H2'>{specialistsTranslations('list.title.label')}</Typography>
            ) : (
              <UserIcon className={'shrink-0 w-[24px] h-[24px]'} />
            )}
            <Button buttonVariant={'Icon'} buttonStyle={'Icon'} onClick={handleExpand}>
              <RollUpIcon isOpen={isExpanded} />
            </Button>
          </div>
          <div className={'flex flex-col grow h-full'}>
            <div
              className={twMerge('flex flex-col grow relative mt-[20px]', !isExpanded && 'gap-2')}
            >
              {isExpanded && (
                <FormProvider {...form}>
                  <div className='p-2'>
                    <Input
                      inputVariant='WidgetSearch'
                      placeholder={specialistsTranslations('list.search.label')}
                      control={form.control.register(searchInput)}
                    />
                  </div>
                </FormProvider>
              )}

              <div className='flex flex-col grow max-h-[calc(100vh_-_290px)] overflow-y-auto'>
                {specialists &&
                  specialists.map((item: IPerson) => (
                    <SelectedSpecialistItem
                      person={item}
                      isActive={selectedSpecialist?.id === item.id}
                      handleSelect={handleSelectSpecialist}
                      isListExpanded={isExpanded}
                      key={item.id}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
