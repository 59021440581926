import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { v4 } from 'uuid';

import { Button } from '@/components/Button/Button';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { PlusIcon } from '@/components/Icons/PlusIcon';
import { PrivateImage } from '@/components/PrivateImage/PrivateImage';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { administrationPageSelectors } from '@/core/redux/slices/administrationPage/selectors';
import { administrationPageActions } from '@/core/redux/slices/administrationPage/slice';

import styles from './styles.module.scss';

export const AdministrationFunctionTabs = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.button' });
  const activeTabID = useAppSelector(administrationPageSelectors.activeTabId);
  const tabs = useAppSelector(administrationPageSelectors.tabs);
  const [hasEmptyTab, setHasEmptyTab] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const addNewTab = () => {
    dispatch(
      administrationPageActions.addTab({
        id: v4(),
        title: t('newFunction'),
      })
    );
  };

  const handleClick = (id: string) => {
    dispatch(administrationPageActions.setActiveTabId(id));
  };

  const renderCrossIcon = (id: string) => {
    const handleClick = (e: React.MouseEvent<HTMLOrSVGElement>) => {
      e.stopPropagation();
      dispatch(administrationPageActions.removeTab(id));
      dispatch(administrationPageActions.setActiveTabId(null));
    };

    return (
      <div className={'shrink-0'}>
        <CrossIcon onClick={handleClick} />
      </div>
    );
  };

  useEffect(() => {
    dispatch(administrationPageActions.loadTabsFromLocale());
  }, [dispatch]);

  useEffect(() => {
    const hasEmpty = tabs.some((tab) => !tab.functionItem);

    setHasEmptyTab(hasEmpty);
  }, [tabs]);

  const renderIcon = (iconUrl: string, isActive: boolean) => (
    <PrivateImage
      className={clsx({ [styles.activeIcon]: isActive }, 'w-5 h-5 shrink-0')}
      id={iconUrl}
      alt='function_logo'
    />
  );

  return (
    <div className={clsx(styles.tabContainer, 'flex flex-nowrap gap-[4px]')}>
      {tabs.map((tab) => {
        const iconUrl = tab.functionItem?.iconUrl;
        return (
          <Button
            key={tab.id}
            buttonVariant={activeTabID === tab.id ? 'FunctionTab' : 'InActiveFunctionTab'}
            onClick={() => handleClick(tab.id)}
          >
            {iconUrl ? renderIcon(iconUrl, activeTabID === tab.id) : undefined}
            <div className={'max-w-[140px] truncate'}>
              {tab.functionItem?.name || t('newFunction')}
            </div>
            {renderCrossIcon(tab.id)}
          </Button>
        );
      })}
      <div className={styles.newTabButtonContainer}>
        <Button
          onClick={addNewTab}
          buttonStyle={'Icon'}
          buttonVariant={'AddNewTab'}
          disabled={hasEmptyTab}
        >
          <PlusIcon />
        </Button>
      </div>
    </div>
  );
};
