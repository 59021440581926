import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDevelopmentReportGoalsAndActivityForm } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/GoalsAndActivity/helper/goalsAndActivityForm';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IDevelopmentReportGoalsAndActivityFetchPayload {
  developmentReportID: number;
}

export interface IImportedDevelopmentReportGoalsAndActivityFetchPayload {
  personID: number;
}

export interface IDevelopmentReportGoalsAndActivityUpdatePayload {
  developmentReportID: number;
  personID: number;
  formValues: IDevelopmentReportGoalsAndActivityForm;
}

export interface IDevelopmentReportGoalsAndActivityState {
  developmentReportGoalsAndActivity: IDevelopmentReportGoalsAndActivityForm | null;
  developmentReportGoalsAndActivityLock: LoadingStatus;
  importedDevelopmentReportGoalsAndActivity: IDevelopmentReportGoalsAndActivityForm | null;
  importedDevelopmentReportGoalsAndActivityLock: LoadingStatus;
  fetchPayload: IDevelopmentReportGoalsAndActivityFetchPayload | null;
  importPayload: IImportedDevelopmentReportGoalsAndActivityFetchPayload | null;
  updatePayload: IDevelopmentReportGoalsAndActivityUpdatePayload | null;
}

const initialState: IDevelopmentReportGoalsAndActivityState = {
  developmentReportGoalsAndActivity: null,
  developmentReportGoalsAndActivityLock: LoadingStatus.NEVER,
  importedDevelopmentReportGoalsAndActivity: null,
  importedDevelopmentReportGoalsAndActivityLock: LoadingStatus.NEVER,
  fetchPayload: null,
  importPayload: null,
  updatePayload: null,
};

const developmentReportGoalsAndActivitySlice = createSlice({
  name: 'developmentReportGoalsAndActivity',
  initialState,
  reducers: {
    fetchDevelopmentReportGoalsAndActivity: (
      state,
      action: PayloadAction<IDevelopmentReportGoalsAndActivityFetchPayload>
    ) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setDevelopmentReportGoalsAndActivity: (
      state,
      action: PayloadAction<IDevelopmentReportGoalsAndActivityForm | null>
    ) => ({
      ...state,
      developmentReportGoalsAndActivity: action.payload,
    }),
    setDevelopmentReportGoalsAndActivityLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      developmentReportGoalsAndActivityLock: action.payload,
    }),
    updateDevelopmentReportGoalsAndActivity: (
      state,
      action: PayloadAction<IDevelopmentReportGoalsAndActivityUpdatePayload>
    ) => ({
      ...state,
      updatePayload: action.payload,
    }),
    fetchImportedDevelopmentReportGoalsAndActivity: (
      state,
      action: PayloadAction<IImportedDevelopmentReportGoalsAndActivityFetchPayload>
    ) => ({
      ...state,
      importPayload: action.payload,
    }),
    setImportedDevelopmentReportGoalsAndActivity: (
      state,
      action: PayloadAction<IDevelopmentReportGoalsAndActivityForm | null>
    ) => ({
      ...state,
      importedDevelopmentReportGoalsAndActivity: action.payload,
    }),
    setImportedDevelopmentReportGoalsAndActivityLock: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => ({
      ...state,
      importedDevelopmentReportGoalsAndActivityLock: action.payload,
    }),
  },
});

export const developmentReportGoalsAndActivityReducer =
  developmentReportGoalsAndActivitySlice.reducer;
export const developmentReportGoalsAndActivityActions =
  developmentReportGoalsAndActivitySlice.actions;
