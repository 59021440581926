import { useEffect } from 'react';

import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  efficacyAssessmentActions,
  IAppointment,
  IMeasureAppointment,
} from '@/core/redux/slices/efficacyAssessment/efficacyAssessmentSlice';
import { efficacyAssessmentSelectors } from '@/core/redux/slices/efficacyAssessment/selectors';

interface IUseFecthedAppointments {
  personID: number | undefined;
  measureID: number | undefined;
}

export function useFecthedMeasureAppointments({ personID, measureID }: IUseFecthedAppointments) {
  const dispatch = useAppDispatch();
  const measureAppointments = useAppSelector(efficacyAssessmentSelectors.measureAppointments);

  const options: ISelectItem[] = measureAppointments?.map(
    (measureAppointment: IMeasureAppointment) => ({
      id: String(measureAppointment.id),
      component: measureAppointment.fromTimestamp,
    })
  );

  useEffect(() => {
    if (!personID || !measureID) {
      return;
    }
    
    dispatch(
      efficacyAssessmentActions.fetchMeasureAppointments({
        personID: personID,
        measureID: measureID,
      })
    );
  }, [dispatch, personID, measureID]);

  return { measureAppointments, options };
}
