import React from 'react';

import { SubActivity } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/SubActivityItem/SubActivity/SubActivity';
import { SubActivityPersonsContainer } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/SubActivityItem/SubActivityPersonsContainer/SubActivityPersonContainer';
import { colorTypes } from '@/styles/types';
import { ISubActivity } from '@/types/activitiesWidget';

export const SubActivityItem: React.FC<ISubActivity> = (subActivityItem) => {
  return (
    <div
      className={`flex flex-row items-center w-auto ms-9 border-t border-${colorTypes.ActivityBorder} border-dotted hover:bg-${colorTypes.HoverLight} px-3`}
    >
      <SubActivity {...subActivityItem} />
      {subActivityItem.persons && (
        <SubActivityPersonsContainer
          personsList={subActivityItem.persons}
          activityID={subActivityItem.id}
        />
      )}
    </div>
  );
};
