import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActivityPlanningMeasureType } from '@/core/enums/functions/activityPlanning/measureTypesEnum';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IMeasuresListFetchPayload {
  personID: number;
}

export interface IPersonLocationFetchPayload {
  personID: number;
}

export interface IPersonLocation {
  id: number;
  name: string;
}

export interface IActivityPlanningMeasure {
  id: number;
  measureID: number;
  name?: string | null;
  measureType: ActivityPlanningMeasureType;
}

export interface IActivityPlanningMeasuresList {
  personLocation: IPersonLocation | null;
  personLocationLock: LoadingStatus;
  measuresList: IActivityPlanningMeasure[];
  measuresListLock: LoadingStatus;
}

const initialState: IActivityPlanningMeasuresList = {
  personLocation: null,
  personLocationLock: LoadingStatus.NEVER,
  measuresList: [],
  measuresListLock: LoadingStatus.NEVER,
};

export const activityPlanningMeasuresList = createSlice({
  name: 'measuresList',
  initialState,
  reducers: {
    //measures list
    fetchMeasuresList: (state, action: PayloadAction<IMeasuresListFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setMeasuresList: (state, action: PayloadAction<IActivityPlanningMeasure[]>) => ({
      ...state,
      measuresList: action.payload,
    }),
    setMeasuresListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      measuresListLock: action.payload,
    }),
    //person location
    fetchPersonLocation: (state, action: PayloadAction<IPersonLocationFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setPersonLocation: (state, action: PayloadAction<IPersonLocation | null>) => ({
      ...state,
      personLocation: action.payload,
    }),
    setPersonLocationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      personLocationLock: action.payload,
    }),
  },
});

export const activityPlaningMeasuresListReducers = activityPlanningMeasuresList.reducer;
export const activityPlaningMeasuresListActions = activityPlanningMeasuresList.actions;
