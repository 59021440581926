import { useForm } from 'react-hook-form';

interface IRadarChartForm {
  showLabel: boolean;
  showAxis: boolean;
}

export function useRadarChartForm() {
  const form = useForm<IRadarChartForm>({
    defaultValues: {
      showAxis: true,
      showLabel: true,
    },
  });

  const showLabel = form.watch().showLabel;
  const showAxis = form.watch().showAxis;

  return { form, showLabel, showAxis };
}
