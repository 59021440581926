import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';

import { activityPlaningMeasuresListSelectors } from '../redux/slices/functions/activityPlanning/measuresList/selectors';
import {
  activityPlaningMeasuresListActions,
  IPersonLocation,
} from '../redux/slices/functions/activityPlanning/measuresList/slice';

interface IUserSelectedLocation {
  selectedPersonLocation: IPersonLocation | null;
}

export function useSelectedPersonLocation(): IUserSelectedLocation {
  const dispatch = useAppDispatch();

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const selectedPersonID = selectedPerson?.id;

  const selectedPersonLocation = useAppSelector(
    activityPlaningMeasuresListSelectors.personLocation
  );

  useEffect(() => {
    if (!selectedPersonID || selectedPersonLocation) {
      return;
    }

    dispatch(
      activityPlaningMeasuresListActions.fetchPersonLocation({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID]);

  return { selectedPersonLocation };
}
