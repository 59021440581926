import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../types';

const selector = (state: IRootState) => state.notification;
const notifications = createSelector([selector], (state) => state.notifications);

export const notificationsSelectors = {
  notifications,
};
