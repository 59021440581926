import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { useAppSelector } from '@/core/redux/hooks';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';
import { LoadingStatus } from '@/types/loadingStatus';

interface IGetDocumentsButton {
  isRender: boolean;
  handleCreateDocuments: () => void;
}

export const GetDocumentsButton: React.FC<IGetDocumentsButton> = ({
  isRender,
  handleCreateDocuments,
}) => {
  const { t: createIntegrationPlanDocumentsTranslations } = useTranslation(
    'createIntegrationPlanDocumentsModal'
  );

  const loadingStatus = useAppSelector(
    integrationPlanModalsSelectors.createIntegrationPlanDocumentsGetDocumentsLoadingStatus
  );

  return (
    <Button onClick={handleCreateDocuments} disabled={!isRender}>
      {loadingStatus === LoadingStatus.LOADING ? (
        <SpinnerIcon className={'text-white w-[30px] h-[30px]'} />
      ) : (
        createIntegrationPlanDocumentsTranslations('buttons.createDocuments.label')
      )}
    </Button>
  );
};
