import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@/components/Input/Input';
import { PredefinedVariantsList } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/PredefinedVariantsContainer/PredefinedVariantsList';
import { PredefinedVariantsListHeader } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/PredefinedVariantsContainer/PredefinedVariantsListHeader';
import { colorTypes } from '@/styles/types';

export const PredefinedVariantsListContainer: React.FC = () => {
  const { register } = useFormContext();
  const { t: workingTimePredefinedVariantsModalTranslations } = useTranslation(
    'workingTimePredefinedVariantsModal'
  );

  return (
    <div
      className={`flex-grow flex flex-col gap-5 border border-${colorTypes.Orange} rounded-default p-4 max-h-[calc(100vh_-_300px)]`}
    >
      <PredefinedVariantsListHeader />
      <PredefinedVariantsList />
      <Input
        control={register('predefinedName')}
        label={workingTimePredefinedVariantsModalTranslations(
          'predefinedVariantsList.header.profileName.label'
        )}
      />
    </div>
  );
};
