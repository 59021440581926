import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { GridViewIcon } from '@/components/Icons/GridViewIcon';
import { ListViewIcon } from '@/components/Icons/ListViewIcon';
import { Input } from '@/components/Input/Input';
import { FunctionGridView } from '@/components/views/functions/FunctionContainer/FunctionGridView/FunctionGridView';
import { FunctionListView } from '@/components/views/functions/FunctionContainer/FunctionListView/FunctionListView';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageActions } from '@/core/redux/slices/functionPage/functionPageSlice';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { searchFunctionByName } from '@/core/utils/functionsUtils';
import { IFunctionItem, ViewMode } from '@/types/functions';

import styles from './styles.module.scss';

const search = 'search';

export const FunctionSelect: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui' });
  const [viewState, setViewState] = useState<ViewMode>(ViewMode.gridView);
  const [functionsState, setFunctionsState] = useState<IFunctionItem[]>([]);

  const functions = useAppSelector(functionPageSelectors.functions);

  const dispatch = useAppDispatch();

  const form = useForm();
  const searchValue = form.watch(search);

  const handleGridViewCLick = () => {
    setViewState(ViewMode.gridView);
  };

  const handleListViewClick = () => {
    setViewState(ViewMode.listView);
  };

  useEffect(() => {
    dispatch(functionPageActions.fetchFunctions());
  }, [dispatch, viewState]);

  useEffect(() => {
    const functionState = searchFunctionByName(functions, searchValue);
    setFunctionsState(functionState);

    if (functions.length < 1) {
      return;
    }

    if (functionState.every((func) => !func.isPinned)) {
      setViewState(ViewMode.listView);
    }
  }, [functions, searchValue]);

  return (
    <>
      <div className='flex flex-row justify-between my-[30px] items-center pr-7'>
        <FormProvider {...form}>
          <Input
            placeholder={t('label.searchInFunctions')}
            control={form.control.register(search)}
            inputVariant={'Search'}
            containerClassName={'flex-grow ms-5'}
          />
        </FormProvider>
        <div className='flex flex-row justify-between items-center ms-[30px]'>
          <Button
            buttonStyle={'Icon'}
            buttonVariant={'Icon'}
            className={clsx(styles.viewButton, {
              [styles.selected]: viewState === ViewMode.gridView,
            })}
            onClick={handleGridViewCLick}
          >
            <GridViewIcon />
          </Button>
          <Button
            buttonStyle='Icon'
            buttonVariant={'Icon'}
            className={clsx(styles.viewButton, {
              [styles.selected]: viewState === ViewMode.listView,
            })}
            onClick={handleListViewClick}
          >
            <ListViewIcon />
          </Button>
        </div>
      </div>
      <div className={clsx(styles.functionContent, 'w-full overflow-y-auto')}>
        {viewState === ViewMode.gridView ? (
          <FunctionGridView functions={functionsState} />
        ) : (
          <FunctionListView functions={functionsState} />
        )}
      </div>
    </>
  );
};
