import React, { PropsWithChildren, useRef, useState } from 'react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  OffsetOptions,
  Placement,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { Typography } from '@/components/Typography/Typography';

interface IDropdown {
  content?: React.ReactNode;
  position?: Placement;
  contentOffset?: OffsetOptions;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  preventClick?: boolean;
  className?: string;
  floatClassName?: string;
  isFlexible?: boolean;
  wrapperClassName?: string;
  label?: string | React.ReactNode;
  isRequired?: boolean;
}

export const Dropdown: React.FC<PropsWithChildren<IDropdown>> = ({
  children,
  content,
  open,
  setOpen,
  className,
  floatClassName,
  wrapperClassName,
  label,
  isRequired,
  preventClick = false,
  contentOffset = 20,
  position = 'bottom-start',
  isFlexible = false,
}) => {
  const [openInternal, setOpenInternal] = useState(false);

  const opened = open || openInternal;
  const setOpened = setOpen || setOpenInternal;

  const { refs, floatingStyles, context } = useFloating({
    open: opened,
    onOpenChange: setOpened,
    placement: position,
    whileElementsMounted: autoUpdate,
    middleware: [offset(contentOffset), flip()],
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (preventClick) {
      return;
    }

    setOpened(!opened);
  };

  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className={clsx(className)} ref={refs.setReference} {...getReferenceProps()}>
      <div
        onClick={handleClick}
        role='button'
        ref={contentRef}
        className={twMerge('flex flex-col gap-2', wrapperClassName)}
      >
        {label && (
          <>
            {typeof label === 'string' ? <Typography as='label'>{label}</Typography> : label}{' '}
            {isRequired && '*'}
          </>
        )}
        {children}
      </div>
      <FloatingPortal>
        {opened && (
          <FloatingFocusManager context={context}>
            <div
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                zIndex: 1000,
                minWidth: isFlexible ? contentRef?.current?.clientWidth : 'auto',
              }}
              className={twMerge('shadow-default', floatClassName)}
              {...getFloatingProps()}
            >
              {content}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </div>
  );
};
