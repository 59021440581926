import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { DunnoImage } from '@/components/Images/DunnoImage';
import { NoImage } from '@/components/Images/NoImage';
import { YesImage } from '@/components/Images/YesImage';
import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';
import { QuestionnaireAnswerOptions } from '@/core/enums/functions/selfAssessment/questionnaireAnswersEnum';
import {
  ISelfAssessmentAnswer,
  ISelfAssessmentQuestion,
} from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';
import { colorTypes } from '@/styles/types';

export interface ISimpleQuestionnaireForm {
  selectedIndex: number;
  questions: ISelfAssessmentQuestion[];
  question: ISelfAssessmentQuestion | null;
  answer: ISelfAssessmentAnswer | null;
  handleAnswer: (selectedAnswer: QuestionnaireAnswerOptions, note: string) => void;
}

export const SimpleQuestionnaireForm: React.FC<ISimpleQuestionnaireForm> = ({
  selectedIndex,
  questions,
  question,
  answer,
  handleAnswer,
}) => {
  const { t: selfAssessmentTranslations } = useTranslation('selfAssesment');

  const form = useForm();
  const { watch, register, setValue } = form;

  const watchNote = watch('note');

  useEffect(() => {
    if (!question) {
      return;
    }
    if (!answer) {
      setValue('note', '');
    } else {
      setValue('note', answer.note);
    }
  }, [question, answer]);

  return (
    <FormProvider {...form}>
      <form>
        <div>
          <br></br>
          <div className='text-center'>
            <Typography type={'20-R'} color='SelfAssessmentActive'>
              {selfAssessmentTranslations('fields.assessment.label')} {selectedIndex + 1}{' '}
              {selfAssessmentTranslations('fields.of.label')} {questions.length}:
            </Typography>
          </div>
          <div className='text-center'>
            <Typography type={'20-R'} color='SelfAssessmentActive'>
              {question?.name}
            </Typography>
          </div>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-wrap'>
              <div className='sm:w-1/3 px-4 container flex flex-col items-center'>
                <Button
                  buttonVariant={'SimpleQuestionnaire'}
                  buttonStyle={'WithIcon'}
                  className={`border ${
                    answer?.answer === QuestionnaireAnswerOptions.YES
                      ? `bg-${colorTypes.SelfAssessmentActive} border-${colorTypes.SelfAssessmentActiveBorder}`
                      : `hover:bg-${colorTypes.SelfAssessmentHover} hover:border-${colorTypes.SelfAssessmentHoverBorder}`
                  }`}
                  onClick={() => handleAnswer(QuestionnaireAnswerOptions.YES, watchNote)}
                >
                  <YesImage className='img-responsive'></YesImage>
                </Button>
                <Typography type={'20-R'} color='SelfAssessmentActive'>
                  {selfAssessmentTranslations('fields.yes.label')}
                </Typography>
              </div>
              <div className='sm:w-1/3 px-4 container flex flex-col items-center'>
                <Button
                  buttonVariant='SimpleQuestionnaire'
                  buttonStyle='WithIcon'
                  className={`border ${
                    answer?.answer === QuestionnaireAnswerOptions.NO
                      ? `bg-${colorTypes.SelfAssessmentActive} border-${colorTypes.SelfAssessmentActiveBorder}`
                      : `hover:bg-${colorTypes.SelfAssessmentHover} hover:border-${colorTypes.SelfAssessmentHoverBorder}`
                  }`}
                  onClick={() => handleAnswer(QuestionnaireAnswerOptions.NO, watchNote)}
                >
                  <NoImage className='img-responsive'></NoImage>
                </Button>
                <Typography type={'20-R'} color='SelfAssessmentActive'>
                  {selfAssessmentTranslations('fields.no.label')}
                </Typography>
              </div>
              <div className='sm:w-1/3 px-4 container flex flex-col items-center'>
                <Button
                  buttonVariant='SimpleQuestionnaire'
                  buttonStyle='WithIcon'
                  className={`border ${
                    answer?.answer === QuestionnaireAnswerOptions.DUNNO
                      ? `bg-${colorTypes.SelfAssessmentActive} border-${colorTypes.SelfAssessmentActiveBorder}`
                      : `hover:bg-${colorTypes.SelfAssessmentHover} hover:border-${colorTypes.SelfAssessmentHoverBorder}`
                  }`}
                  onClick={() => handleAnswer(QuestionnaireAnswerOptions.DUNNO, watchNote)}
                >
                  <DunnoImage className='img-responsive'></DunnoImage>
                </Button>
                <Typography type={'20-R'} color='SelfAssessmentActive'>
                  {selfAssessmentTranslations('fields.dunno.label')}
                </Typography>
              </div>
            </div>
            <Input
              placeholder={selfAssessmentTranslations('fields.notice.label')}
              control={register('note')}
              inputVariant={'Default'}
              containerClassName={'flex-grow mx-5'}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
