import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const ReviewSupportHeader: React.FC = () => {
  const { t: reviewSupportTranslations } = useTranslation('developmentReportReviewSupport', {
    keyPrefix: 'table.tableHeader',
  });

  return (
    <div className={'w-full grid grid-cols-[repeat(19,_1fr)] gap-3 items-center'}>
      <Typography className={'col-span-8'}>
        {reviewSupportTranslations('objective.label')}
      </Typography>
      <Typography>{reviewSupportTranslations('yes.label')}</Typography>
      <Typography>{reviewSupportTranslations('partially.label')}</Typography>
      <Typography>{reviewSupportTranslations('no.label')}</Typography>
      <Typography className={'col-span-8'}>{reviewSupportTranslations('comment.label')}</Typography>
    </div>
  );
};
