import { colorTypes, textTypes } from '@/styles/types';

export type ButtonVariants = keyof typeof buttonVariants;

const buttonVariants = {
  Default: {
    base: `bg-${colorTypes.Blue} ${textTypes['16-M']} text-white py-default px-[20px]`,
    hovered: `hover:bg-${colorTypes.BlueHover} hover:text-white`,
    active: `active:bg-${colorTypes.BlueHover}`,
    focused: '',
    disabled: 'disabled:opacity-30 disabled:pointer-events-none',
  },
  Secondary: {
    base: `bg-${colorTypes.Fields} text-${colorTypes.Grey} ${textTypes['16-M']} py-default px-[20px]`,
    hovered: `hover:bg-${colorTypes.Hover} hover:text-black`,
    active: ``,
    focused: ``,
    disabled: 'disabled:opacity-30 disabled:pointer-events-none',
  },
  Outlined: {
    base: `bg-none text-${colorTypes.Blue} border border-${colorTypes.Blue}  px-5 py-2.5`,
    hovered: `hover:bg-${colorTypes.Blue} hover:text-white`,
    active: ``,
    focused: ``,
    disabled: 'disabled:opacity-30 disabled:pointer-events-none',
  },
  FunctionTab: {
    base: `bg-${colorTypes.Dark} text-${colorTypes.White} ${textTypes['14-Sb']} gap-2 py-3 px-4`,
    hovered: `hover:bg-${colorTypes.Dark} hover:text-white`,
    active: ``,
    focused: ``,
    disabled: '',
  },
  InActiveFunctionTab: {
    base: `bg-${colorTypes.Fields} text-${colorTypes.Grey} ${textTypes['14-Sb']} gap-2 py-3 px-4`,
    hovered: `hover:bg-${colorTypes.Hover} hover:text-black`,
    active: ``,
    focused: ``,
    disabled: '',
  },
  AddNewTab: {
    base: `border border-${colorTypes.Dark} text--${colorTypes.Dark} w-[44px] aspect-square rounded-default`,
    hovered: `hover:bg-${colorTypes.Hover}`,
    active: '',
    focused: '',
    disabled: `disabled:bg-${colorTypes.HoverLight} disabled:text-${colorTypes.Disabled}`,
  },
  Icon: {
    base: 'bg-transparent',
    hovered: `hover:text-${colorTypes.Blue}`,
    active: '',
    focused: '',
    disabled: '',
  },
  UserSelect: {
    base: textTypes['16-R'],
    hovered: `hover:text-${colorTypes.Blue} hover:bg-${colorTypes.Hover}`,
    active: '',
    focused: `focus:text-${colorTypes.Blue}`,
    disabled: `disabled:bg-${colorTypes.Disabled}`,
  },
  AddUser: {
    base: `${textTypes['16-Sb']} bg-transparent text-${colorTypes.Blue} border border-${colorTypes.Blue} w-full py-default px-[20px] justify-center gap-[10px]`,
    hovered: `hover:bg-${colorTypes.Blue} hover:text-${colorTypes.White}`,
    active: '',
    focused: '',
    disabled: `disabled:border-${colorTypes.Disabled} disabled:text-${colorTypes.Disabled} disabled:pointer-events-none`,
  },
  SelectedPerson: {
    base: `${textTypes['16-M']} text-${colorTypes.Disabled} py-default px-[20px]`,
    hovered: `hover:text-${colorTypes.Blue} hover:bg-${colorTypes.Hover}`,
    active: '',
    focused: ``,
    disabled: `disabled:bg-${colorTypes.Disabled}`,
  },
  Sidebar: {
    base: `w-full ${textTypes['16-Sb']} text-${colorTypes.Primary}`,
    hovered: `hover:text-${colorTypes.Blue} hover:bg-${colorTypes.Hover}`,
    active: '',
    focused: `focus:text-${colorTypes.Blue}`,
    disabled: `disabled:bg-${colorTypes.Disabled}`,
  },
  TextBlue: {
    base: `w-auto ${textTypes['14-Sb']} text-${colorTypes.Blue} bg-transparent`,
    hovered: `hover:text-${colorTypes.BlueHover} hover:bg-transparent`,
    active: '',
    focused: `focus:text-${colorTypes.BluePressed}`,
    disabled: `disabled:text-${colorTypes.Disabled}`,
  },
  Transparent: {
    base: 'bg-transparent',
    hovered: `bg-transparent`,
    active: 'bg-transparent',
    focused: 'bg-transparent',
    disabled: '',
  },
  SimpleQuestionnaire: {
    base: `leading-[1.3333333] px-[20px] py-default`,
    hovered: '',
    active: `active:bg-${colorTypes.SelfAssessmentActive} active:border-${colorTypes.SelfAssessmentActiveBorder}`,
    focused: '',
    disabled: 'disabled:cursor-not-allowed disabled:opacity-[0.65] disabled:shadow-none',
  },
  PersonListSelect: {
    base: `px-4 py-3 text-disabled border-l-transparent border-l-2 border-solid`,
    hovered: 'hover:text-blue hover:bg-hover hover:border-l-blue',
    active: `active:bg-${colorTypes.SelfAssessmentActive} active:border-${colorTypes.SelfAssessmentActiveBorder}`,
    focused: '',
    disabled: 'disabled:cursor-not-allowed disabled:opacity-[0.65] disabled:shadow-none',
  },
  Disabled: {
    base: `opacity-30  bg-${colorTypes.Blue} ${textTypes['16-M']} text-white py-default px-[20px]`,
    hovered: `hover:bg-${colorTypes.BlueHover} hover:text-white`,
    active: `active:bg-${colorTypes.BlueHover}`,
    focused: '',
    disabled: 'disabled:pointer-events-none',
  },
  BreadCrumbs: {
    base: `${textTypes['16-M']} text-disabled`,
    hovered: 'hover:text-blue',
    active: ``,
    focused: '',
    disabled: 'disabled:cursor-not-allowed disabled:opacity-[0.65] disabled:shadow-none',
  },
  Tab: {
    base: `bg-${colorTypes.Fields} text-${colorTypes.Grey} ${textTypes['16-M']} py-default px-[20px] rounded-b-none px-4 pt-2 pb-[7px] justify-start text-sm overflow-hidden`,
    hovered: `hover:bg-${colorTypes.Hover} hover:text-black`,
    active: ``,
    focused: ``,
    disabled: 'disabled:opacity-30 disabled:pointer-events-none',
  },
  ActiveTab: {
    base: `bg-${colorTypes.Blue} ${textTypes['16-M']} text-white py-default px-[20px] rounded-b-none px-4 pt-2 pb-[7px] justify-start text-sm overflow-hidden`,
    hovered: `hover:bg-${colorTypes.BlueHover} hover:text-white`,
    active: `active:bg-${colorTypes.BlueHover}`,
    focused: '',
    disabled: 'disabled:opacity-30 disabled:pointer-events-none',
  },
};

export function getButtonVariant(key: ButtonVariants): string {
  const { base, hovered, active, focused, disabled } = buttonVariants[key];

  return `${base ?? ''} ${hovered ?? ''} ${active ?? ''} ${focused ?? ''} ${disabled ?? ''}`;
}
