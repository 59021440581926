import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@/components/Input/Input';
import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';

const SEARCH = 'search_department';
export const DepartmentSearch: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.label' });
  const [, setSearchValue] = usePersonPageStoreSelector((store) => store.departmentSearchValue);

  const form = useForm({ defaultValues: { [SEARCH]: '' } });
  const search = form.watch(SEARCH);

  useEffect(() => {
    setSearchValue({ departmentSearchValue: search });
  }, [search, setSearchValue]);

  return (
    <FormProvider {...form}>
      <Input
        control={form.control.register(SEARCH)}
        inputVariant='Search'
        placeholder={t('searchInDepartments')}
        containerClassName={'w-full mt-[40px] px-[30px]'}
      />
    </FormProvider>
  );
};
