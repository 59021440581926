import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.specialistGeneralData.costCenters;

// departments
const availableUserDepartments = createSelector(
  [selector],
  (state) => state.availableUserDepartments
);
const availableUserDepartmentsLock = createSelector(
  [selector],
  (state) => state.availableUserDepartmentsLock
);
const updateUserDepartmentsLock = createSelector(
  [selector],
  (state) => state.updateUserDepartmentsLock
);
// users
const departmentUsers = createSelector(
  [selector],
  (state) => state.departmentUsers
);

export const costCentersSelectors = {
  availableUserDepartments,
  availableUserDepartmentsLock,
  updateUserDepartmentsLock,

  departmentUsers
};
