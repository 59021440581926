import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const AdministrationChecklistListHeader: React.FC = () => {
  const { t: administrationChecklistTranslations } = useTranslation('administrationChecklist');
  return (
    <div className={'grid grid-cols-12 items-center gap-3 h-[44px] p-2'}>
      <Typography className={'col-span-2'}>
        {administrationChecklistTranslations('checklistList.header.view.label')}
      </Typography>
      <Typography className={'col-span-10'}>
        {administrationChecklistTranslations('checklistList.header.checklist.label')}
      </Typography>
    </div>
  );
};
