import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { ISerialAppointmentForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/hooks/useSerialAppointmentForm';
import { Typography } from '@/components/Typography/Typography';

export interface ISerialAppointmentDailyForm {
  isWorkingDaysOnly: boolean;
  daysInterval?: number | null;
}

export const SerialAppointmentDailyForm: React.FC = () => {
  const { t: serialAppointmentTranslations } = useTranslation('serialAppointmentModal', {
    keyPrefix: 'cycle.forms.daily',
  });

  const { watch } = useFormContext<ISerialAppointmentForm>();

  const isEveryWorkingDay = watch('daily.isWorkingDaysOnly');

  const serialAppointmentDailyFormOptions: IFormRadioButtonGroupItem[] = [
    {
      id: 'daily.isEveryWorkingDayTrue',
      value: true,
      component: (
        <label htmlFor={'daily.isEveryWorkingDayTrue'}>
          <Typography>{serialAppointmentTranslations('fields.everyWorkingDay.label')}</Typography>{' '}
        </label>
      ),
    },
    {
      id: 'daily.isEveryWorkingDayFalse',
      value: false,
      component: (
        <label htmlFor={'daily.isEveryWorkingDayFalse'}>
          <Typography>{serialAppointmentTranslations('fields.everyone.label')}</Typography>
        </label>
      ),
    },
  ];

  const serialAppointmentDailyForm: (
    | FormItem<ISerialAppointmentForm>
    | FormGroup<ISerialAppointmentForm>
  )[] = [
    {
      type: 'radio-button-group',
      name: 'daily.isWorkingDaysOnly',
      radioButtonGroupOptions: {
        options: serialAppointmentDailyFormOptions,
        containerClassName: 'flex flex-row gap-5',
      },
    },
    {
      condition: !isEveryWorkingDay,
      type: 'input',
      name: 'daily.daysInterval',
      label: serialAppointmentTranslations('fields.day.label'),
    },
  ];

  return <FormRender list={serialAppointmentDailyForm} />;
};
