import React from 'react';

export interface IHorizontalScrollableContainer {
  children: React.ReactNode;
  className?: string;
}

export enum ScrollDirection {
  left = 'left',
  right = 'right',
}
