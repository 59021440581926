import React from 'react';
import { useDraggable } from '@dnd-kit/core';

import { Button } from '@/components/Button/Button';
import { EditTextIcon } from '@/components/Icons/EditTextIcon';
import { FolderIcon } from '@/components/Icons/FolderIcon';
import { SmallDraggableIcon } from '@/components/Icons/SmallDraggableIcon';
import { TrashIcon } from '@/components/Icons/TrashIcon';
import { useAppDispatch } from '@/core/redux/hooks';
import { IAdministrationLocation } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { administrationLocationManagementModalsActions } from '@/core/redux/slices/modalsSlice/administrationFunctions/locationManagement/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

export interface IAdministrationLocationManagementLocationItemOverlay {
  isActive: boolean;
  isSelected: boolean;
  location: IAdministrationLocation;
  setIsHovered: (isHovered: boolean) => void;
  className?: string;
  openConfirmDeleteDialog?: () => void;
  openBlockDeleteDialog?: () => void;
  openRenameDialog?: () => void;
}

export const AdministrationLocationManagementLocationItemOverlay: React.FC<IAdministrationLocationManagementLocationItemOverlay> =
  ({
    isActive,
    isSelected,
    className,
    setIsHovered,
    location,
    openBlockDeleteDialog,
    openConfirmDeleteDialog,
    openRenameDialog,
  }) => {
    const { isDeletable, id } = location;
    const { attributes, listeners, setActivatorNodeRef } = useDraggable({ id: id });

    const dispatch = useAppDispatch();

    const handleDelete = (event: React.MouseEvent) => {
      event.stopPropagation();
      isDeletable ? openConfirmDeleteDialog?.() : openBlockDeleteDialog?.();
      setIsHovered(false);
    };

    const handleRename = (event: React.MouseEvent) => {
      event.stopPropagation();
      openRenameDialog?.();
      setIsHovered(false);
    };

    const handleMove = (event: React.MouseEvent) => {
      event.stopPropagation();
      dispatch(
        administrationLocationManagementModalsActions.openAdministrationLocationMoveModal({
          location: location,
        })
      );
    };

    return isActive ? (
      <div
        className={twMerge(
          'absolute flex flex-row right-[10px] top-1/2 -translate-y-1/2',
          className
        )}
      >
        <Button
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
          className={`p-2 text-${isSelected ? colorTypes.White : colorTypes.Secondary}`}
          onClick={handleRename}
        >
          <EditTextIcon />
        </Button>
        <Button
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
          className={`p-2 text-${isSelected ? colorTypes.White : colorTypes.Secondary}`}
          onClick={handleMove}
        >
          <FolderIcon />
        </Button>
        <Button
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
          className={`p-2 text-${isSelected ? colorTypes.White : colorTypes.Secondary} hover:text-${
            colorTypes.Error
          }`}
          onClick={handleDelete}
        >
          <TrashIcon />
        </Button>
        <Button
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
          className={`p-2 text-${isSelected ? colorTypes.White : colorTypes.Secondary}`}
          ref={setActivatorNodeRef}
          {...attributes}
          {...listeners}
        >
          <SmallDraggableIcon />
        </Button>
      </div>
    ) : null;
  };
