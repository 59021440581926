import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAdministrationCheckpointForm } from '@/components/administartionFunctions/checklist/AdministrationCheckpointList/hooks/useAdministrationCheckpointForm';
import { IDropdownItem } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import { LoadingStatus } from '@/types/loadingStatus';

//check list group
export interface IAdministrationChecklistGroup {
  name: string;
  id: number;
}

//check list group

export interface IAdministrationChecklist {
  id: number;
  name: string;
  isAdded: boolean;
  order: number;
}

export interface IAdministrationChecklistFetchPayload {
  checklistGroupID: number;
}

//checkpoint
export interface IAdministrationChecklistCheckpoint {
  id: number;
  name?: string | null;
  days: number;
  correspondingDocument?: {
    id: number;
    value?: string | null;
  } | null;
  infoDocument?: string | null;
  validFrom: string;
  order: number;
  disabilityTypeID?: number | null;
}

export interface IAdministrationChecklistCheckpointFetchPayload {
  checklistID: number;
}

export interface IAdministrationChecklistItemUpdatePayload {
  id: number;
  isSelected: boolean;
}

export interface IAdministrationChecklistCheckpointUpdatePayload {
  formValues: IAdministrationCheckpointForm;
  checkpointID: number;
}

export interface IAdministrationChecklistCheckpointCreatePayload {
  formValues: IAdministrationCheckpointForm;
  checklistID: number;
}

export interface IAdministrationChecklistState {
  checklistGroups: IAdministrationChecklistGroup[];
  checklistGroupsLock: LoadingStatus;
  checklist: IAdministrationChecklist[];
  checklistLock: LoadingStatus;
  checklistFetchPayload: IAdministrationChecklistFetchPayload | null;
  checklistCheckpoints: IAdministrationChecklistCheckpoint[];
  checklistCheckpointsLock: LoadingStatus;
  checklistCheckpointsFetchPayload: IAdministrationChecklistCheckpointFetchPayload | null;
  correspondingDocuments: IDropdownItem[];
  disabilityTypes: IDropdownItem[];
}

export const initialState: IAdministrationChecklistState = {
  checklistGroups: [],
  checklistGroupsLock: LoadingStatus.NEVER,
  checklist: [],
  checklistLock: LoadingStatus.NEVER,
  checklistFetchPayload: null,
  checklistCheckpoints: [],
  checklistCheckpointsLock: LoadingStatus.NEVER,
  checklistCheckpointsFetchPayload: null,
  correspondingDocuments: [],
  disabilityTypes: [],
};

const administrationChecklistSlice = createSlice({
  name: 'administrationChecklist',
  initialState,
  reducers: {
    //check list groups
    fetchChecklistGroups: () => {},
    setChecklistGroups: (state, action: PayloadAction<IAdministrationChecklistGroup[]>) => ({
      ...state,
      checklistGroups: action.payload,
    }),
    setChecklistGroupsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      checklistGroupsLock: action.payload,
    }),

    //check list
    fetchChecklist: (state, action: PayloadAction<IAdministrationChecklistFetchPayload>) => ({
      ...state,
      checklistFetchPayload: action.payload,
    }),
    setChecklist: (state, action: PayloadAction<IAdministrationChecklist[]>) => ({
      ...state,
      checklist: action.payload,
    }),
    setChecklistLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      checklistLock: action.payload,
    }),

    //checkpoints
    fetchChecklistCheckpoints: (
      state,
      action: PayloadAction<IAdministrationChecklistCheckpointFetchPayload>
    ) => ({
      ...state,
      checklistCheckpointsFetchPayload: action.payload,
    }),
    setChecklistCheckpoints: (
      state,
      action: PayloadAction<IAdministrationChecklistCheckpoint[]>
    ) => ({
      ...state,
      checklistCheckpoints: action.payload,
    }),
    setChecklistCheckpointsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      checklistCheckpointsLock: action.payload,
    }),

    //corresponding document
    fetchCorrespondingDocuments: (state) => state,
    setCorrespondingDocuments: (state, action: PayloadAction<IDropdownItem[]>) => ({
      ...state,
      correspondingDocuments: action.payload,
    }),

    //disability types
    fetchDisabilityTypes: (state) => state,
    setDisabilityTypes: (state, action: PayloadAction<IDropdownItem[]>) => ({
      ...state,
      disabilityTypes: action.payload,
    }),

    //actions
    reorderChecklist: (state, action: PayloadAction<IAdministrationChecklist[]>) => state,
    reorderCheckpoint: (state, action: PayloadAction<IAdministrationChecklistCheckpoint[]>) =>
      state,
    updateChecklistItem: (
      state,
      action: PayloadAction<IAdministrationChecklistItemUpdatePayload>
    ) => state,
    createCheckpoint: (
      state,
      action: PayloadAction<IAdministrationChecklistCheckpointCreatePayload>
    ) => state,
    updateCheckpoint: (
      state,
      action: PayloadAction<IAdministrationChecklistCheckpointUpdatePayload>
    ) => state,
  },
});

export const administrationChecklistReducer = administrationChecklistSlice.reducer;
export const administrationChecklistActions = administrationChecklistSlice.actions;
