import React from 'react';

import { CreateAppliedTestProcedureModalContent } from '@/components/Modals/Modals/functions/testingResultModals/CreateAppliedTestProcedureModal/CreateAppliedTestProcedureModalContent/CreateAppliedTestProcedureModalContent';
import { useAppSelector } from '@/core/redux/hooks';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';

export const CreateAppliedTestProcedureModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    testingResultModalsSelectors.createAppliedTestingResultModal
  );

  return isOpened ? <CreateAppliedTestProcedureModalContent /> : null;
};
