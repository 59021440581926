import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import type oas from '@/services/rest/base/openapi';

import {
  dropdownItemsActions,
  IDropdownItems,
  IDropdownItemsFetchPayload,
} from './dropdownItems.slice';

type DropdownItemsResponse = OASOutput<NormalizeOAS<typeof oas>, '/dropdown_items', 'get', '200'>;

function* fetchDropdownItems(
  action: PayloadAction<IDropdownItemsFetchPayload>
): Generator<any, void, DropdownItemsResponse> {
  const { dropdownName, dropdownTable } = action.payload;

  try {
    const response = yield call(restCall, '/dropdown_items', 'get', {
      query: {
        dropdown_table: dropdownTable,
      },
      ...authAdd(),
    });

    const dropdownItems = response.dropdown_items;

    yield put(
      dropdownItemsActions.setDropdownItems({
        tableName: dropdownName || dropdownTable,
        items: dropdownItems,
      })
    );
  } catch (error) {
    console.log('Error on dropdown items fetching');
  }
}

export const dropdownItemsSagas = [
  takeEvery(dropdownItemsActions.fetchDropdownItems, fetchDropdownItems),
];
