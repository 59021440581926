import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import { DatePickerVariant } from '@/core/enums/datePickerVariantsEnum';
import { DateRangeType } from '@/core/enums/dateRangeTypeEnum';
import { IActivity } from '@/types/activitiesWidget';
import {
  IUpdateWidgetPositionPayload,
  IUpdateWidgetVisibilityPayload,
} from '@/types/dashboardWidget';
import { LoadingStatus } from '@/types/loadingStatus';
import { IPerson, IShortcutFunctionsItem } from '@/types/person';

export interface IFavoritePerson {
  id: number;
  name?: string | null;
  surname?: string | null;
  lastModified?: string | null;
  isFavorite?: boolean;
  functions: IShortcutFunctionsItem[];
}

export interface IFavoritePersonsGroup {
  id: number;
  name: string;
  persons: IFavoritePerson[];
}

export interface IDashboardState {
  favoritePersons: {
    navigatedFavoritePersons: Record<string, IPerson> | null;
    favoritePersonsGroups: IFavoritePersonsGroup[];
    favoritePersonsGroupsLock: LoadingStatus;
  };
  activities: {
    status: LoadingStatus;
    totalCount: number;
    activitiesList: IActivity[];
    chosenDateRange: DateRangeType | null;
    datePickerVariant: DatePickerVariant | null;
    selectedActivityPersons: Record<string, IPerson> | null;
  };
  widgets: any[]; // TODO
}

export interface IFavoritePersonsFetchPayload {
  search: string;
}

export interface IDateRange {
  fromDate: string;
  toDate: string;
}

export interface IActivitiesFetchPayload {
  dateRangeType: DateRangeType;
  dateRange?: IDateRange;
}

const initialState: IDashboardState = {
  favoritePersons: {
    navigatedFavoritePersons: null,
    favoritePersonsGroups: [],
    favoritePersonsGroupsLock: LoadingStatus.NEVER,
  },
  activities: {
    status: LoadingStatus.NEVER,
    totalCount: 0,
    activitiesList: [],
    chosenDateRange: DateRangeType.THIS_WEEK,
    datePickerVariant: null,
    selectedActivityPersons: null,
  },
  widgets: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchWidgets: (state, action: PayloadAction) => ({
      ...state,
      payload: action.payload,
    }),
    setWidgets: (state: IDashboardState, action: PayloadAction<any>) =>
      fp.set('widgets', action.payload, state),
    updateWidgetSettings: (state, action: PayloadAction<IUpdateWidgetVisibilityPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateWidgetPosition: (state, action: PayloadAction<IUpdateWidgetPositionPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateWidgets: (state: IDashboardState, action: PayloadAction<any>) => {
      const { payload } = action;
      const newWidgets = state.widgets.map((oldItem) => {
        const newItem = payload.find((newItem: any) => newItem.id === oldItem.id);
        return newItem ? { ...oldItem, is_visible: newItem.is_visible } : oldItem;
      });
      return {
        ...state,
        widgets: newWidgets, // Use the updated newWidgets array here
      };
    },

    fetchFavoritePersons: (state, action: PayloadAction<IFavoritePersonsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setFavoritePersons: (
      state: IDashboardState,
      action: PayloadAction<IFavoritePersonsGroup[]>
    ) => ({
      ...state,
      favoritePersons: {
        ...state.favoritePersons,
        favoritePersonsGroups: action.payload,
      },
    }),
    setFavoritePersonsLock: (state: IDashboardState, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      favoritePersons: {
        ...state.favoritePersons,
        favoritePersonsGroupsLock: action.payload,
      },
    }),
    setNavigatedActivityPersons: (
      state: IDashboardState,
      action: PayloadAction<Record<string, IPerson>>
    ) => ({
      ...state,
      favoritePersons: {
        ...state.favoritePersons,
        navigatedFavoritePersons: action.payload,
      },
    }),

    fetchActivities: (state, action: PayloadAction<IActivitiesFetchPayload | null>) => ({
      ...state,
      payload: action.payload,
    }),
    setActivitiesList: (state: IDashboardState, action: PayloadAction<IActivity[]>) => ({
      ...state,
      activities: {
        ...state.activities,
        activitiesList: action.payload,
      },
    }),
    setActivitiesLock: (state: IDashboardState, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      activities: {
        ...state.activities,
        status: action.payload,
      },
    }),
    setActivitiesDateRange: (
      state: IDashboardState,
      action: PayloadAction<DateRangeType | null>
    ) => ({
      ...state,
      activities: {
        ...state.activities,
        chosenDateRange: action.payload,
      },
    }),
    setDatePickerVariant: (
      state: IDashboardState,
      action: PayloadAction<DatePickerVariant | null>
    ) => ({
      ...state,
      activities: {
        ...state.activities,
        datePickerVariant: action.payload,
      },
    }),
    setActivitiesTotalCount: (state: IDashboardState, action: PayloadAction<number>) => ({
      ...state,
      activities: {
        ...state.activities,
        totalCount: action.payload,
      },
    }),
    setSelectedActivityPersons: (
      state: IDashboardState,
      action: PayloadAction<Record<string, IPerson>>
    ) => ({
      ...state,
      activities: {
        ...state.activities,
        selectedActivityPersons: action.payload,
      },
    }),
  },
});

export const dashboardWidgetsReducer = dashboardSlice.reducer;
export const dashboardActions = dashboardSlice.actions;
