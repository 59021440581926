import React from 'react';
import { ProjectStatsView } from '@nw/wheel';

import { useAppSelector } from '@/core/redux/hooks';
import { userSettingsSelectors } from '@/core/redux/slices/userSettings/selectors';
import { internationalizationLocales } from '@/core/utils/commonUtils';

export const WheelView: React.FC = () => {
  const projectId = '313';
  const apiKey = process.env.REACT_APP_WHEEL_API_KEY;
  const projectSource = 'https://redmine.sustems.eu';
  const view = 'sunburst';
  const localeGlobal = useAppSelector(userSettingsSelectors.userSettingsLanguage);
  const locale = internationalizationLocales[localeGlobal];

  return (
    <div className='p-5 h-[calc(100vh_-_270px)] flex flex-col gap-4 items-start overflow-auto'>
      <div className='w-full'>
        {apiKey && (
          <ProjectStatsView
            projectId={projectId}
            apiKey={apiKey}
            projectSource={projectSource}
            locale={locale}
            view={view}
          />
        )}
      </div>
    </div>
  );
};
