import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { EntryIcon } from '@/components/Icons/EntryIcon';
import { Input } from '@/components/Input/Input';
import { IAuthFormValues } from '@/components/views/auth/LoginPage/helpers/authPageFromValues';
import { authResolvers } from '@/components/views/auth/LoginPage/helpers/authPageResolvers';
import { PasswordField } from '@/components/views/auth/LoginPage/PasswordField/PasswordField';
import { AuthWrapper } from '@/components/wrapper/AuthWrapper/AuthWrapper';
import { useAppDispatch } from '@/core/redux/hooks';
import { authActions } from '@/core/redux/slices/auth/authSlice';
import { useCookies } from '@/core/utils/cookiesUtil';
import { useAuthSystemMutation } from '@/services/graphql/system/graphql';

import styles from './styles.module.scss';

export const LoginPage: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui' });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const cookies = useCookies();

  const dispatch = useAppDispatch();

  const [sendAuthData, response] = useAuthSystemMutation({
    errorPolicy: 'none',
    onError: () => {},
    onCompleted: async (data) => {
      cookies.set('accessToken', data?.auth_login?.access_token, {
        maxAge: 60 * 60 * 71,
      });
      localStorage.setItem('refreshToken', data?.auth_login?.refresh_token as string);
      dispatch(authActions.setAToken(data.auth_login?.access_token ?? null));
      navigate(searchParams.get('redirect_path') ?? '/');
    },
  });

  const form = useForm<IAuthFormValues>({
    resolver: yupResolver(authResolvers),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleAuth = async (data: IAuthFormValues) => {
    await sendAuthData({ variables: data });
  };

  return (
    <AuthWrapper bottom={<Link to='/auth/password_request'>{t('label.resetPassword')}</Link>}>
      <div className='flex justify-center items-center flex-col'>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleAuth)}
            className={clsx(styles.formContainer, 'flex flex-col')}
          >
            <Input control={form.control.register('email')} placeholder={t('label.email')} />
            <PasswordField
              placeholder={t('label.password')}
              control={form.control.register('password')}
              className='my-2'
            />
            {fp.get('error.message', response)}
            <div className='text-center my-3 flex justify-center'>
              <Button buttonStyle={'WithIcon'} className={'gap-3'} type={'submit'}>
                {t('button.login')}
                <EntryIcon />
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </AuthWrapper>
  );
};
