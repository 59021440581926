import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { DEFAULT_LAYER_PADDING } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaGroupedList/VocationalTrainingAreaGroupedListGroup/VocationalTrainingAreaGroupedListGroup';
import {
  ICompleteMeasureFormGroup,
  mapFormItem,
} from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/hooks/useCompleteMeasureModalForm';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import {
  IAppointment,
  IMeasure,
} from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface ICompleteAppointmentGroupedListItem extends IMeasure {
  groupID: number;
  layer: number;
  bgColor?: string;
  formPath: string;
  appointments?: IAppointment[];
}

export const CompleteMeasureGroupedListItem: React.FC<ICompleteAppointmentGroupedListItem> = ({
  text,
  note,
  type,
  formPath,
  id,
  groupID,
  layer,
  bgColor,
  appointments,
}) => {
  const { watch, setValue, register } = useFormContext();

  const currentValues: ICompleteMeasureFormGroup = watch(formPath);
  const currentID = `groupID=${groupID}&measureID=${id}`;

  const isSelected = watch('selectedMeasure') === currentID;

  const foundIndex =
    currentValues && currentValues.items
      ? currentValues?.items?.findIndex((item) => item.id === id)
      : 0;

  const basePath = `${formPath}.items`;

  const isParticipatedPath = `${basePath}.${foundIndex}.isParticipated`;
  const isUnderDirectionPath = `${basePath}.${foundIndex}.isUnderDirection`;
  const isAlmostIndependentPath = `${basePath}.${foundIndex}.isAlmostIndependent`;
  const isIndependentPath = `${basePath}.${foundIndex}.isIndependent`;

  const handleCheck = (formPath: string) => {
    if (!currentValues || !currentValues.items) {
      return;
    }

    const isChecked = watch(formPath);

    const tempValues = [...currentValues.items];
    tempValues.forEach((item) => {
      item.isUnderDirection = false;
      item.isParticipated = false;
      item.isIndependent = false;
      item.isAlmostIndependent = false;
    });

    setValue(basePath, tempValues);

    if (isChecked) {
      return;
    }

    setValue(formPath, true, { shouldDirty: true });
  };

  const handleSelect = () => {
    setValue('selectedMeasure', currentID);

    if (!appointments) {
      return;
    }

    const newAppointments = mapFormItem(appointments);
    setValue('appointments', newAppointments);
  };

  return (
    <Button
      buttonStyle={'Text'}
      buttonVariant={'Transparent'}
      className={twMerge(
        `col-span-12 grid grid-cols-12 items-center justify-center hover:bg-${colorTypes.HoverLight} py-1 px-[30px] border-l`,
        isSelected ? `border-${colorTypes.Blue}` : 'border-transparent'
      )}
      style={{ backgroundColor: isSelected ? '#E8EBF2' : bgColor }}
      onClick={handleSelect}
    >
      <Typography
        className={'col-span-1 whitespace-normal'}
        style={{ paddingLeft: DEFAULT_LAYER_PADDING * (layer + 1) }}
        type={'16-M'}
        color={'Secondary'}
      >
        {type}
      </Typography>
      <Typography className={'col-span-4 whitespace-normal'} type={'16-M'} color={'Secondary'}>
        {text}
      </Typography>
      <Checkbox
        name={isParticipatedPath}
        onClick={() => handleCheck(isParticipatedPath)}
        preventDefault={true}
        className={'justify-self-center'}
      />
      <Checkbox
        name={isUnderDirectionPath}
        onClick={() => handleCheck(isUnderDirectionPath)}
        preventDefault={true}
        className={'justify-self-center'}
      />
      <Checkbox
        name={isAlmostIndependentPath}
        onClick={() => handleCheck(isAlmostIndependentPath)}
        preventDefault={true}
        className={'justify-self-center'}
      />
      <Checkbox
        name={isIndependentPath}
        onClick={() => handleCheck(isIndependentPath)}
        preventDefault={true}
        className={'justify-self-center'}
      />
      <TextArea
        control={register(`${formPath}.items.${foundIndex}.comment`)}
        containerClassName={'col-span-3 w-full'}
      >
        {note}
      </TextArea>
    </Button>
  );
};
