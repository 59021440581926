import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { internshipsActions } from '@/core/redux/slices/functions/specialistGeneralData/internships/internshipsSlice';
import { internshipsSelectors } from '@/core/redux/slices/functions/specialistGeneralData/internships/selectors';
import { colorTypes } from '@/styles/types';

import InternshipList from './InternshipList/InternshipList';

interface IInternships {
  userID: number;
}

export const Internships: React.FC<IInternships> = ({ userID }) => {
  const { t: specialistGeneralDataTranslations } = useTranslation('specialistGeneralData');
  const dispatch = useAppDispatch();

  const internships = useAppSelector(internshipsSelectors.internships);

  const handleInternshipChange = (internshipID: number, isSelected: boolean) => {
    dispatch(
      internshipsActions.updateInternship({
        specialistID: userID,
        internshipID: internshipID,
        isSelected: isSelected,
      })
    );
  };

  const handleLocationChange = (internshipID: number, locationID: number, isSelected: boolean) => {
    dispatch(
      internshipsActions.updateLocation({
        specialistID: userID,
        internshipID: internshipID,
        locationID: locationID,
        isSelected: isSelected,
      })
    );
  };

  useEffect(() => {
    dispatch(
      internshipsActions.fecthInternships({
        specialistID: userID,
      })
    );
  }, [userID]);

  return (
    <div className='w-full flex flex-col gap-4 h-full overflow-auto p-5'>
      <div className={`border-b border-b-${colorTypes.StrokeLight} pb-2.5 mb-5`}>
        <Typography type='H3'>{specialistGeneralDataTranslations('internships.label')}</Typography>
      </div>
      <InternshipList
        internships={internships}
        onInternshipChange={handleInternshipChange}
        onLocationChange={handleLocationChange}
      />
    </div>
  );
};
