import React from 'react';

import { CompleteAppointmentGroupedList } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/CompleteAppointmentGroupedList/CompleteAppointmentGroupedList';
import { CompleteAppointmentTableHeader } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/CompleteAppointmentTableHeader/CompleteAppointmentTableHeader';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

export const CompleteAppointmentTable: React.FC = () => {
  const { appointmentCompleteQuestionnaireLock } = useAppSelector(
    activityPlaningAppointmentDetailsModalsSelectors.completeAppointmentModal
  );

  return (
    <div className={'flex flex-col'}>
      <CompleteAppointmentTableHeader />
      <Skeleton trigger={appointmentCompleteQuestionnaireLock === LoadingStatus.LOADING}>
        <CompleteAppointmentGroupedList />
      </Skeleton>
    </div>
  );
};
