import React from 'react';

import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { FavoritePersonsGroup } from '@/components/widgets/PersonWidget/FavoritePersonsGroup/FavoritePersonsGroup';
import { ErrorPlaceholder } from '@/components/widgets/PersonWidget/FavoritePersonsGroupsList/ErrorPlaceholder/ErrorPlaceholder';
import { Placeholder } from '@/components/widgets/PersonWidget/FavoritePersonsGroupsList/Placeholder/Placeholder';
import { useAppSelector } from '@/core/redux/hooks';
import { dashboardSelectors } from '@/core/redux/slices/dashboard/selectors';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

export const FavoritePersonsGroupsList: React.FC = () => {
  const favoritePersonsGroups = useAppSelector(dashboardSelectors.favoritePersonsGroups);
  const favoritePersonsGroupsLock = useAppSelector(dashboardSelectors.favoritePersonsLock);

  const renderList = () => {
    switch (favoritePersonsGroupsLock) {
      case LoadingStatus.LOADED:
        return favoritePersonsGroups.map((personsGroup) => (
          <FavoritePersonsGroup {...personsGroup} key={personsGroup.id} />
        ));
      case LoadingStatus.LOADED_EMPTY:
        return <Placeholder />;
      case LoadingStatus.ERROR:
        return <ErrorPlaceholder />;
      case LoadingStatus.NEVER:
        return <Placeholder />;
      case LoadingStatus.LOADING:
        return (
          <div className={'flex-grow flex items-center justify-center'}>
            <SpinnerIcon className={`text-${colorTypes.Blue}`} />
          </div>
        );
    }
  };

  return renderList();
};
