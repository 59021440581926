import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { useQuestionnaireStoreSelector } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireContext/QuestionnaireContext';
import { Typography } from '@/components/Typography/Typography';

export const QuestionnaireHeader: React.FC = () => {
  const { t: questionnaireTranslations } = useTranslation('questionnaire', { keyPrefix: 'header' });

  const [collapseTrigger, triggerCollapse] = useQuestionnaireStoreSelector(
    (store) => store.collapseTrigger
  );

  const handleCollapseAll = () => {
    triggerCollapse({ collapseTrigger: !collapseTrigger });
  };

  return (
    <div className={'flex flex-row justify-between items-center px-[30px] mt-4'}>
      <Typography type={'H3'}>{questionnaireTranslations('title.label')}</Typography>
      <Button buttonStyle={'Text'} buttonVariant={'TextBlue'} onClick={handleCollapseAll}>
        {questionnaireTranslations('buttons.collapseAll.label')}
      </Button>
    </div>
  );
};
