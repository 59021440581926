import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.functions.medicalInformation.diagnosticsSpecialFeatures;

const diagnosticsSpecialFeatures = createSelector(
  [selector],
  (state) => state.diagnosticsSpecialFeatures
);
const diagnosticsSpecialFeaturesLock = createSelector(
  [selector],
  (state) => state.diagnosticsSpecialFeaturesLock
);
const updateLock = createSelector([selector], (state) => state.updateLock);

export const diagnosticsSpecialFeaturesSelectors = {
  diagnosticsSpecialFeatures,
  diagnosticsSpecialFeaturesLock,
  updateLock,
};
