import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useActivityPlaningWizardSelector } from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardContext/ActivityPlanningContext';
import { Typography } from '@/components/Typography/Typography';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningMeasuresListSelectors } from '@/core/redux/slices/functions/activityPlanning/measuresList/selectors';
import { activityPlaningMeasuresListActions } from '@/core/redux/slices/functions/activityPlanning/measuresList/slice';

export const MeasuresListHeader: React.FC = () => {
  const { t: measuresListTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'measuresList.title',
  });

  const [, setPersonLocation] = useActivityPlaningWizardSelector((store) => store.personLocation);

  const personLocation = useAppSelector(activityPlaningMeasuresListSelectors.personLocation);

  const { selectedPersonID, selectedPersonName } = useSelectedPerson();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      activityPlaningMeasuresListActions.fetchPersonLocation({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID]);

  useEffect(() => {
    if (!personLocation) {
      return;
    }

    setPersonLocation({ personLocation: personLocation });
  }, [personLocation]);

  return (
    <div className={'flex flex-row gap-2 justify-between px-2'}>
      {personLocation && (
        <Typography type={'16-Sb'} className={'text-wrap'}>
          {personLocation.name}
        </Typography>
      )}
      {selectedPersonName && (
        <Typography type={'16-Sb'} className={'shrink-0'}>
          {selectedPersonName}
        </Typography>
      )}
      {!selectedPersonName && (
        <Typography type={'16-Sb'}>{measuresListTranslations('label')}</Typography>
      )}
    </div>
  );
};
