import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { IPersonRequirementTimeListForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/hooks/usePersonRequirementTimeListForm';
import { Typography } from '@/components/Typography/Typography';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { getFirstFormError } from '@/core/utils/formUtils';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

interface IPersonRequirementTimeListFooter {
  form: UseFormReturn<IPersonRequirementTimeListForm, any, undefined>;
  additionalRequirementID?: number | null;
  assistanceName?: string | null;
  onExit: () => void;
}

export const PersonRequirementTimeListFooter: React.FC<IPersonRequirementTimeListFooter> = ({
  form,
  additionalRequirementID,
  assistanceName,
  onExit,
}) => {
  const { t: personRequirementTimeListTranslations } = useTranslation(
    'personRequirementTimeListModal'
  );

  const { updatePersonRequirementTimeListLock } = useAppSelector(
    organizationalUnitsModalsSelectors.additionalPersonRequirementTimeListModal
  );
  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  const {
    formState: { isDirty, errors },
  } = form;

  const errorMessage = getFirstFormError<IPersonRequirementTimeListForm>(errors);

  const handleOpenPrognosisModal = () => {
    if (!additionalRequirementID) {
      return;
    }

    dispatch(
      organizationalUnitsModalsActions.openAdditionalPersonRequirementAssessmentPrognosisModal({
        additionalPersonRequirementID: additionalRequirementID,
        assistanceName: assistanceName,
      })
    );
  };

  const handleSave = () => {
    if (!additionalRequirementID || !selectedPersonID) {
      return;
    }

    form.trigger().then((isValid) => {
      if (!isValid) {
        return;
      }

      const currentValues = fp.cloneDeep(form.watch());
      form.reset(currentValues);

      dispatch(
        organizationalUnitsModalsActions.updatePersonRequirementTimeList({
          additionalPersonRequirementID: additionalRequirementID,
          formValues: currentValues,
          personID: selectedPersonID,
        })
      );
    });
  };

  return (
    <div className={'flex gap-3 justify-start'}>
      <div className={'flex gap-3 items-center'}>
        <Button onClick={handleSave} disabled={!isDirty}>
          {personRequirementTimeListTranslations('buttons.save.label')}
        </Button>
        <Button onClick={onExit} buttonVariant={'Secondary'}>
          {personRequirementTimeListTranslations('buttons.cancel.label')}
        </Button>
        <Button onClick={handleOpenPrognosisModal}>
          {personRequirementTimeListTranslations('buttons.prediction.label')}
        </Button>
        {updatePersonRequirementTimeListLock === LoadingStatus.LOADING && (
          <SpinnerIcon className={`text-${colorTypes.Blue}`} />
        )}
        {errorMessage && <Typography color={'Error'}>{errorMessage}</Typography>}
      </div>
    </div>
  );
};
