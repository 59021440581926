import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IAdministrationDepartment,
  IAdministrationLocation,
} from '@/core/redux/slices/administrationFunctions/locationManagement/slice';

interface IModal {
  isRender: boolean;
}

export interface IAdministrationLocationMoveModalPayload {
  location: IAdministrationLocation;
}

export interface IAdministrationLocationMoveModal extends IModal {
  payload: IAdministrationLocationMoveModalPayload | null;
}

export interface IAdministrationDepartmentMoveModalPayload {
  departments: IAdministrationDepartment[];
}

export interface IAdministrationDepartmentMoveModal extends IModal {
  payload: IAdministrationDepartmentMoveModalPayload | null;
}

export interface IAdministrationLocationManagementModalsState {
  administrationLocationMoveModal: IAdministrationLocationMoveModal;
  administrationDepartmentMoveModal: IAdministrationDepartmentMoveModal;
}

const initialState: IAdministrationLocationManagementModalsState = {
  administrationLocationMoveModal: {
    isRender: false,
    payload: null,
  },
  administrationDepartmentMoveModal: {
    isRender: false,
    payload: null,
  },
};

const administrationLocationManagementModalsSlice = createSlice({
  name: 'administrationLocationManagementModals',
  initialState,
  reducers: {
    openAdministrationLocationMoveModal: (
      state,
      action: PayloadAction<IAdministrationLocationMoveModalPayload>
    ) => ({
      ...state,
      administrationLocationMoveModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAdministrationLocationMoveModal: (state) => ({
      ...state,
      administrationLocationMoveModal: {
        isRender: false,
        payload: null,
      },
    }),

    openAdministrationDepartmentMoveModal: (
      state,
      action: PayloadAction<IAdministrationDepartmentMoveModalPayload>
    ) => ({
      ...state,
      administrationDepartmentMoveModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeAdministrationDepartmentMoveModal: (state) => ({
      ...state,
      administrationDepartmentMoveModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const administrationLocationManagementModalsReducer =
  administrationLocationManagementModalsSlice.reducer;
export const administrationLocationManagementModalsActions =
  administrationLocationManagementModalsSlice.actions;
