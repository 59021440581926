import React from 'react';

import { DeleteAppliedTestProcedureModalContent } from '@/components/Modals/Modals/functions/testingResultModals/DeleteAppliedTestProcedureModal/DeleteAppliedTestProcedureModalContent/DeleteAppliedTestProcedureModalContent';
import { useAppSelector } from '@/core/redux/hooks';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';

export const DeleteAppliedTestProcedureModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    testingResultModalsSelectors.deleteAppliedTestingResultModal
  );

  return isOpened ? <DeleteAppliedTestProcedureModalContent /> : null;
};
