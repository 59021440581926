import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../types';

const selector = (state: IRootState) => state.personPage;
const departments = createSelector([selector], (state) => state.departments);
const departmentsStatus = createSelector([selector], (state) => state.departmentsStatus);
const persons = createSelector([selector], (state) => state.persons);
const personsStatus = createSelector([selector], (state) => state.personsStatus);
const selectedLocations = createSelector([selector], (state) => state.selectedLocations);
const locationsCheckState = createSelector([selector], (state) => state.locationsCheckState);
const selectedPersons = createSelector([selector], (state) => state.selectedPersons);

export const personPageSelectors = {
  departments,
  departmentsStatus,
  persons,
  personsStatus,
  selectedLocations,
  selectedPersons,
  locationsCheckState,
};
