import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import FormRender from '@/components/FormRender/FormRender';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { specialistGeneralDataSelectors } from '@/core/redux/slices/functions/specialistGeneralData/generalData/selectors';
import {
  IGeneralData,
  specialistGeneralDataActions,
} from '@/core/redux/slices/functions/specialistGeneralData/generalData/specialistGeneralDataSlice';
import { colorTypes } from '@/styles/types';

import { firstColumn, secondColumn } from './helpers/formTemplates';

interface IGeneralDataComponent {
  userID: number;
}

export const GeneralData: React.FC<IGeneralDataComponent> = ({ userID }) => {
  const { t: specialistGeneralDataTranslations } = useTranslation('specialistGeneralData');
  const form = useForm<IGeneralData>();
  const dispatch = useAppDispatch();

  const generalData = useAppSelector(specialistGeneralDataSelectors.generalData);

  const onSubmit = (data: IGeneralData) => {
    dispatch(
      specialistGeneralDataActions.updateGeneralData({
        userID: userID,
        generalData: data,
      })
    );
  };

  const onReset = () => {
    if (!generalData) {
      return;
    }
    form.reset(generalData);
  };

  useEffect(() => {
    if (!userID) {
      return;
    }

    dispatch(
      specialistGeneralDataActions.fetchGeneralData({
        userID: userID,
      })
    );
  }, [dispatch, userID]);

  useEffect(() => {
    if (!generalData) {
      return;
    }

    form.reset(generalData);
  }, [generalData]);

  return (
    <FormProvider {...form}>
      <div className='w-full flex flex-col gap-4 p-5 h-full overflow-auto'>
        <form>
          <div className={`mb-5 border-b border-b-${colorTypes.StrokeLight} pb-2.5`}>
            <Typography type='H3'>{specialistGeneralDataTranslations('title.label')}</Typography>
          </div>
          <div>
            <div className='w-full'>
              <div className='flex gap-4 w-full'>
                <div className='flex gap-10 grow'>
                  <FormRender
                    list={firstColumn}
                    translationContext={specialistGeneralDataTranslations}
                    className='flex flex-wrap w-[450px]'
                  />
                  <FormRender
                    list={secondColumn}
                    translationContext={specialistGeneralDataTranslations}
                    className='flex flex-wrap w-[450px]'
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        className={'flex flex-row px-7 py-default justify-start items-center gap-3 shadow-footer'}
      >
        {form.formState.isDirty && (
          <>
            <Button type='submit' onClick={form.handleSubmit(onSubmit)}>
              {specialistGeneralDataTranslations('buttons.submit.label')}
            </Button>
            <Button onClick={onReset}>
              {specialistGeneralDataTranslations('buttons.reset.label')}
            </Button>
          </>
        )}
      </div>
    </FormProvider>
  );
};
