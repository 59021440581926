import React from 'react';

import { Button } from '@/components/Button/Button';
import { FavoriteIcon } from '@/components/Icons/FavoriteIcon';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { userSettingsSelectors } from '@/core/redux/slices/userSettings/selectors';
import { userSettingsActions } from '@/core/redux/slices/userSettings/userSettingsSlice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IFavoriteButton {
  isHovered: boolean;
  personID: number;
  className?: string;
}

export const PersonFavoriteButton: React.FC<IFavoriteButton> = ({
  isHovered,
  personID,
  className,
}) => {
  const favoritePersons = useAppSelector(userSettingsSelectors.userFavoritePersons);
  const isFavorite = favoritePersons.includes(personID);

  const dispatch = useAppDispatch();

  const handleClick = () => {
    const tempArray = [...favoritePersons];
    const index = tempArray.indexOf(personID);

    if (index > -1) {
      tempArray.splice(index, 1);
    } else {
      tempArray.push(personID);
    }

    dispatch(
      userSettingsActions.updateFavoritePersons({
        favoritePersons: tempArray,
      })
    );
  };

  return isFavorite || isHovered ? (
    <Button
      buttonVariant={'Icon'}
      buttonStyle={'Icon'}
      className={twMerge('px-2', className)}
      onClick={handleClick}
    >
      <FavoriteIcon
        className={twMerge(
          'w-[16px] h-[16px]',
          isFavorite
            ? `text-${colorTypes.Orange} fill-${colorTypes.Orange} hover:text-${colorTypes.Blue} hover:fill-none`
            : `text-${colorTypes.Blue} hover:text-${colorTypes.Orange} hover:fill-${colorTypes.Orange} fill-none`
        )}
      />
    </Button>
  ) : (
    <div className={'w-[32px] h-[16px]'} />
  );
};
