import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { ReviewItem } from '@/components/forms/CompetenceAnalysis/ReviewItem/ReviewItem';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const SchoolCompetencies: React.FC = () => {
  const { t: schoolCompetenciesTranslations } = useTranslation('competenceAnalysis', {
    keyPrefix: 'schoolCompetencies',
  });

  return (
    <Accordion
      title={<Typography type={'H3'}>{schoolCompetenciesTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'grid grid-cols-[repeat(20,_minmax(0,_1fr))] items-center mt-4'}>
        <Typography color={'Secondary'} type={'14-M'} className={'col-span-6'}>
          {schoolCompetenciesTranslations('tableHeader.observedCompetencies.label')}
        </Typography>
        <Typography color={'Secondary'} type={'14-M'} className={'col-span-2 p-2'}>
          {schoolCompetenciesTranslations('tableHeader.review.label')}
        </Typography>
        <Typography color={'Secondary'} type={'14-M'} className={'col-span-12 p-2'}>
          {schoolCompetenciesTranslations('tableHeader.restrictions.label')}
        </Typography>
      </div>
      <div className={'flex flex-col gap-2'}>
        <ReviewItem
          title={schoolCompetenciesTranslations('fields.read.label')}
          fieldName={'read'}
        />
        <ReviewItem
          title={schoolCompetenciesTranslations('fields.writing.label')}
          fieldName={'writing'}
        />
        <ReviewItem
          title={schoolCompetenciesTranslations('fields.calculate.label')}
          fieldName={'calculate'}
        />
      </div>
    </Accordion>
  );
};
