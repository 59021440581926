import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useImperativeHandle,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import fp from 'lodash/fp';

import { Comments } from '@/components/forms/ParticipantExpectation/Comments/Comments';
import { ExpectationsAndMotivation } from '@/components/forms/ParticipantExpectation/ExpectationsAndMotivation/ExpectationsAndMotivation';
import { Header } from '@/components/forms/ParticipantExpectation/Header/Header';
import { participantExpectationResolver } from '@/components/forms/ParticipantExpectation/helpers/participantExpectationResolver';
import { InterestsAndInclinations } from '@/components/forms/ParticipantExpectation/InterestsAndInclinations/InterestsAndInclinations';
import { useSelectedPersonForm } from '@/components/functions/IntegrationPlan/hooks/useSelectedPersonForm';
import { IntegrationPlanFormActions } from '@/components/functions/IntegrationPlan/IntegrationPlan';
import { IIntegrationPlanFormProps } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsContainer/IntegrationPlanFormsContainer';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  IParticipantExpectation,
  participantExpectationActions,
} from '@/core/redux/slices/functions/integrationPlan/participantExpectation/participantExpectationSlice';
import { participantExpectationSelectors } from '@/core/redux/slices/functions/integrationPlan/participantExpectation/selectors';

export const ParticipantExpectation: ForwardRefExoticComponent<
  Omit<IIntegrationPlanFormProps, 'ref'> & RefAttributes<IntegrationPlanFormActions>
> = forwardRef((props, ref) => {
  const { t: participantExpectationTranslations } = useTranslation('participantExpectation');
  const { integrationPlanID, personID } = props;

  const initialValues = useAppSelector(participantExpectationSelectors.participantExpectation);

  const form = useForm<IParticipantExpectation>({
    resolver: yupResolver(participantExpectationResolver(participantExpectationTranslations)),
  });

  useSelectedPersonForm({ form, integrationPlanID, initialValues });

  const dispatch = useAppDispatch();

  const onBeforeExitCheck = () => {
    const currentValues = form.watch();

    if (fp.isEqual(currentValues, initialValues)) {
      return true;
    }

    if (!personID || integrationPlanID === null) {
      return true;
    }

    dispatch(
      participantExpectationActions.updateParticipantExpectation({
        personID: personID,
        integrationPlanID: integrationPlanID,
        participantExpectation: currentValues,
      })
    );

    dispatch(participantExpectationActions.setParticipantExpectation(null));

    return true;
  };

  useImperativeHandle(ref, () => ({
    onBeforeExitCheck: onBeforeExitCheck,
  }));

  useEffect(() => {
    if (!integrationPlanID || !personID) {
      return;
    }

    dispatch(
      participantExpectationActions.fetchParticipantExpectation({
        integrationPlanID: integrationPlanID,
      })
    );
  }, [integrationPlanID, personID]);

  useEffect(() => {
    form.trigger().then();
  }, [form.watch()]);

  return (
    <FormProvider {...form}>
      <form className={'flex flex-col gap-[30px] w-full'} onSubmit={form.handleSubmit(() => {})}>
        <Header />
        <InterestsAndInclinations />
        <ExpectationsAndMotivation />
        <Comments />
      </form>
    </FormProvider>
  );
});
