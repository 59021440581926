import React from 'react';

export const SettingsIcon: React.FC = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.8636 20.5C15.5909 20.5 16.2273 19.8918 16.2273 19.0833C16.2273 18.2749 15.5909 17.6667 14.8636 17.6667C14.1363 17.6667 13.5 18.2749 13.5 19.0833C13.5 19.8918 14.1363 20.5 14.8636 20.5ZM14.8636 22C16.1906 22 17.3068 21.0807 17.6317 19.8333L17.7273 19.8333L21 19.8333C21.4142 19.8333 21.75 19.4975 21.75 19.0833C21.75 18.6691 21.4142 18.3333 21 18.3333L17.7273 18.3333L17.6317 18.3333C17.3068 17.0859 16.1906 16.1667 14.8636 16.1667C13.5367 16.1667 12.4205 17.0859 12.0956 18.3333L3 18.3333C2.58579 18.3333 2.25 18.6691 2.25 19.0833C2.25 19.4975 2.58579 19.8333 3 19.8333L12.0956 19.8333C12.4205 21.0807 13.5367 22 14.8636 22ZM2.25 4.91667C2.25 4.50245 2.58579 4.16667 3 4.16667L12.0956 4.16667C12.4205 2.91927 13.5367 2 14.8636 2C16.1906 2 17.3068 2.91927 17.6317 4.16667L17.7273 4.16667L21 4.16667C21.4142 4.16667 21.75 4.50245 21.75 4.91667C21.75 5.33088 21.4142 5.66667 21 5.66667L17.7273 5.66667L17.6317 5.66667C17.3068 6.91406 16.1906 7.83333 14.8636 7.83333C13.5367 7.83333 12.4205 6.91406 12.0956 5.66667L3 5.66667C2.58579 5.66667 2.25 5.33088 2.25 4.91667ZM21 12.75C21.4142 12.75 21.75 12.4142 21.75 12C21.75 11.5858 21.4142 11.25 21 11.25L11.9044 11.25C11.5795 10.0026 10.4633 9.08333 9.13636 9.08333C7.8094 9.08333 6.69324 10.0026 6.36829 11.25L6.27273 11.25L3 11.25C2.58579 11.25 2.25 11.5858 2.25 12C2.25 12.4142 2.58579 12.75 3 12.75L6.27273 12.75L6.36829 12.75C6.69324 13.9974 7.8094 14.9167 9.13636 14.9167C10.4633 14.9167 11.5795 13.9974 11.9044 12.75L21 12.75ZM16.2273 4.91667C16.2273 5.72514 15.5909 6.33333 14.8636 6.33333C14.1363 6.33333 13.5 5.72514 13.5 4.91667C13.5 4.1082 14.1363 3.5 14.8636 3.5C15.5909 3.5 16.2273 4.1082 16.2273 4.91667ZM7.77273 12C7.77273 11.1915 8.40908 10.5833 9.13636 10.5833C9.86365 10.5833 10.5 11.1915 10.5 12C10.5 12.8085 9.86365 13.4167 9.13636 13.4167C8.40908 13.4167 7.77273 12.8085 7.77273 12Z'
      fill='#0D1627'
    />
  </svg>
);
