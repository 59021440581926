import React from 'react';

import { InternshipDocumentationContent } from '@/components/Modals/Modals/functions/integrationPlanModals/InternshipDocumentationModal/InternshipDocumentationContent/InternshipDocumentationContent';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';

export const InternshipDocumentationModal: React.FC = () => {
  const isOpen = useAppSelector(integrationPlanModalsSelectors.internshipDocumentationModal);

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(integrationPlanModalsActions.closeInternshipDocumentationModal());
  };

  if (isOpen) {
    return (
      <Modal onExit={handleExit} className={'min-w-[600px]'}>
        <InternshipDocumentationContent onExit={handleExit} />
      </Modal>
    );
  }

  return null;
};
