import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.modals.administrationFunctions.locationManagementModals;

const moveLocationModal = createSelector(
  [selector],
  (state) => state.administrationLocationMoveModal
);

const moveDepartmentModal = createSelector(
  [selector],
  (state) => state.administrationDepartmentMoveModal
);

export const administrationLocationManagementModalsSelectors = {
  moveLocationModal,
  moveDepartmentModal,
};
