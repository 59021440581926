import React from 'react';
import clsx from 'clsx';

import { FunctionGroupItem } from '@/components/views/functions/FunctionContainer/FunctionListView/FunctionGroupItem/FunctionGroupItem';
import { IFunctionListItem, IFunctionView } from '@/types/functions';

import styles from './styles.module.scss';

export const FunctionListView: React.FC<IFunctionView> = ({ functions }) => {
  const functionList: IFunctionListItem[] = [];

  functions.forEach((item) => {
    const firstChar = item.name.charAt(0);

    if (functionList.findIndex((item) => item.heading === firstChar) === -1) {
      functionList.push({ heading: firstChar, functions: [item] });
    } else {
      const foundIndex = functionList.findIndex((item) => item.heading === firstChar);
      functionList[foundIndex].functions.push(item);
    }
  });

  functionList.sort((a, b) => a.heading.toLowerCase().localeCompare(b.heading.toLowerCase()));

  functionList.forEach((item) => {
    item.functions.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  });

  return (
    <div className={clsx(styles.icon, 'flex flex-col items-start justify-start')}>
      {functionList &&
        functionList.map((item) => (
          <FunctionGroupItem heading={item.heading} functions={item.functions} key={item.heading} />
        ))}
    </div>
  );
};
