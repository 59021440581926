import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { VersionHistoryItem } from '@/components/Modals/Modals/functions/developmentReport/DevelopmentReportVersionHistoryModal/VersionHistoryItem/VersionHistoryItem';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { developmentReportActions } from '@/core/redux/slices/functions/developmentReport/slice';
import { developmentReportVersionHistoryModalSelectors } from '@/core/redux/slices/modalsSlice/functions/developmentReport/developmentReportVersionHistory/selectors';
import { developmentReportVersionHistoryModalActions } from '@/core/redux/slices/modalsSlice/functions/developmentReport/developmentReportVersionHistory/slice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import styles from './styles.module.scss';

export const DevelopmentReportVersionHistoryModal: React.FC = () => {
  const { isOpened } = useAppSelector(
    developmentReportVersionHistoryModalSelectors.developmentReportVersionHistoryModal
  );

  return isOpened ? <DevelopmentReportReportHistoryModalContent /> : null;
};

export const DevelopmentReportReportHistoryModalContent: React.FC = () => {
  const { t: developmentReportVersionHistoryModalTranslations } = useTranslation(
    'developmentReportVersionHistoryModal'
  );

  const {
    versionsLock: loadingStatus,
    versions,
    payload,
  } = useAppSelector(
    developmentReportVersionHistoryModalSelectors.developmentReportVersionHistoryModal
  );
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(developmentReportVersionHistoryModalActions.closeDevelopmentReportVersionHistory());
  };

  const handleVersionSelect = (id: number, printedTimestamp?: string | null) => {
    dispatch(
      developmentReportActions.setDevelopmentReport({
        id: id,
        printedTimestamp: printedTimestamp,
      })
    );
    handleClose();
  };

  useEffect(() => {
    if (!payload) {
      return;
    }

    dispatch(
      developmentReportVersionHistoryModalActions.fetchDevelopmentReportVersionHistory({
        personID: payload.personID,
      })
    );
  }, []);

  return (
    <Sidebar onExit={handleClose} position='right' className='p-0'>
      <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
        <div className={'flex flex-col min-w-[400px]'}>
          <Typography type={'H2'} className={'m-[30px]'}>
            {developmentReportVersionHistoryModalTranslations('title.label')}
          </Typography>
          <Typography
            type={'14-R'}
            color={'Secondary'}
            className={`border-t border-${colorTypes.Stroke} px-[30px] py-3`}
          >
            {developmentReportVersionHistoryModalTranslations('dateHeader.label')}
          </Typography>
          <div className={twMerge(styles.container, `flex flex-col`)}>
            {versions.length > 0 &&
              versions.map((version) => (
                <VersionHistoryItem
                  key={version.id}
                  timeStamp={version.printedTimestamp}
                  id={version.id}
                  handleClick={handleVersionSelect}
                />
              ))}
          </div>
        </div>
      </Skeleton>
    </Sidebar>
  );
};
