import { PayloadAction } from '@reduxjs/toolkit';
import { debounce, put, takeLatest } from 'redux-saga/effects';

import { initializeApollo } from '@/core/clients/apollo';
import { simpleSelfAssesmentActions } from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';
import {
  CreateSelfAssessmentAnswerDocument,
  CreateSelfAssessmentAnswerMutation,
  CreateSelfAssessmentAnswerMutationResult,
  CreateSelfAssessmentAnswerMutationVariables,
  UpdateSelfAssessmentAnswerDocument,
  UpdateSelfAssessmentAnswerMutation,
  UpdateSelfAssessmentAnswerMutationResult,
  UpdateSelfAssessmentAnswerMutationVariables,
} from '@/services/graphql/base/graphql';

import {
  ICreateSelfAssessmentAnswerPayload,
  IUpdateSelfAssessmentAnswerPayload,
  selfAssesmentModalsActions,
} from './selfAssesmentModalsSlice';

function* createSelfAssessmentAnswer(
  action: PayloadAction<ICreateSelfAssessmentAnswerPayload>
): Generator<any, void, CreateSelfAssessmentAnswerMutationResult> {
  const { payload } = action;
  try {
    const client = initializeApollo();

    const response = yield client.mutate<
      CreateSelfAssessmentAnswerMutation,
      CreateSelfAssessmentAnswerMutationVariables
    >({
      mutation: CreateSelfAssessmentAnswerDocument,
      variables: payload.variables,
    });

    const seaID = response.data?.create_t_se_antworten_item?.sea_id;
    if (!seaID) {
      return;
    }

    yield put(
      selfAssesmentModalsActions.updateSelfAssessmentAnswer({
        variables: {
          seaID: seaID,
          data: {
            m2s_id: {
              m2s_id: payload.m2sID,
            },
          },
        },
      })
    );
  } catch (error) {
    console.log('Error on trying to create answer', error);
  }
}

function* updateSelfAssessmentAnswer(
  action: PayloadAction<IUpdateSelfAssessmentAnswerPayload>
): Generator<any, void, UpdateSelfAssessmentAnswerMutationResult> {
  const { payload } = action;
  try {
    const client = initializeApollo();

    const response = yield client.mutate<
      UpdateSelfAssessmentAnswerMutation,
      UpdateSelfAssessmentAnswerMutationVariables
    >({
      mutation: UpdateSelfAssessmentAnswerDocument,
      variables: payload.variables,
    });

    const m2sID = response.data?.update_t_se_antworten_item?.m2s_id?.m2s_id;
    if (!m2sID) {
      return;
    }

    yield put(
      simpleSelfAssesmentActions.fetchPersonQuestionnaireAnswers({
        variables: {
          m2sID,
        },
      })
    );
  } catch (error) {
    console.log('Error on trying to update answer', error);
  }
}

export const selfAssessmentModalsSagas = [
  debounce(500, selfAssesmentModalsActions.createSelfAssessmentAnswer, createSelfAssessmentAnswer),
  debounce(500, selfAssesmentModalsActions.updateSelfAssessmentAnswer, updateSelfAssessmentAnswer),
];
