import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { FormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { StatusReportCourseOfMeasuresQuestionTypes } from '@/core/enums/functions/integrationPlan/statusReport/StatusReportCourseOfMeasuresQuestionTypesEnum';
import { IStatusReport } from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { colorTypes } from '@/styles/types';

export const CourseOfMeasures: React.FC = () => {
  const { t: courseOfMeasuresTranslations } = useTranslation('statusReport', {
    keyPrefix: 'courseOfMeasures',
  });

  const { control, watch } = useFormContext<IStatusReport>();

  const questionOptions: IFormRadioButtonGroupItem[] = [
    {
      id: StatusReportCourseOfMeasuresQuestionTypes.isCurrentlyNoQuestions,
      value: StatusReportCourseOfMeasuresQuestionTypes.isCurrentlyNoQuestions,
      component: (
        <label htmlFor={StatusReportCourseOfMeasuresQuestionTypes.isCurrentlyNoQuestions}>
          {courseOfMeasuresTranslations(`fields.isCurrentlyNoQuestions.label`)}
        </label>
      ),
    },
    {
      id: StatusReportCourseOfMeasuresQuestionTypes.isQuestionsNeedClarification,
      value: StatusReportCourseOfMeasuresQuestionTypes.isQuestionsNeedClarification,
      component: (
        <div className={'flex flex-row gap-3 w-full'}>
          <label htmlFor={StatusReportCourseOfMeasuresQuestionTypes.isQuestionsNeedClarification}>
            <Typography>
              {courseOfMeasuresTranslations(`fields.isQuestionsNeedClarification.label`)}
            </Typography>
          </label>
          <TextArea
            control={control.register('questionsText')}
            containerClassName={'w-full'}
            readOnly={
              watch('selectedCourseOfMeasuresQuestionType') !==
              StatusReportCourseOfMeasuresQuestionTypes.isQuestionsNeedClarification
            }
          />
        </div>
      ),
    },
  ];

  return (
    <Accordion
      title={<Typography type={'H3'}>{courseOfMeasuresTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'flex flex-col gap-2 mt-4'}>
        <div className={'flex flex-row gap-4 items-center'}>
          <Typography type={'H3'}>
            {courseOfMeasuresTranslations('fields.reasonForCreation.label')}
          </Typography>
          <div className={'flex flex-row gap-2 items-center ms-[50px]'}>
            <Typography>
              {courseOfMeasuresTranslations('fields.reasonForCreation.dateLabel.label')}
            </Typography>
            <DatePicker control={control.register('actionCourseTimestamp')} />
          </div>
        </div>
        <div className={'w-auto'}>
          <CheckboxItem
            label={courseOfMeasuresTranslations('fields.isFirstYearVocationalTraining.label')}
            fieldName={'isFirstYearVocationalTraining'}
            labelPosition={'left'}
          />
          <CheckboxItem
            label={courseOfMeasuresTranslations('fields.isSecondYearVocationalTraining.label')}
            fieldName={'isSecondYearVocationalTraining'}
            labelPosition={'left'}
          />
        </div>
        <div className={'grid grid-cols-12 gap-2'}>
          <CheckboxItem
            label={courseOfMeasuresTranslations('fields.isFurtherIndividualAppointment.label')}
            fieldName={'isFurtherIndividualAppointment'}
            labelPosition={'left'}
            className={'col-span-3'}
          />
          <div className={'col-span-2 '}>
            <Typography className={'flex-nowrap'}>
              {courseOfMeasuresTranslations('fields.furtherIndividualAppointmentReason.label')}
            </Typography>
          </div>
          <div className={'col-span-7'}>
            <TextArea
              control={control.register('furtherIndividualAppointmentReason')}
              containerClassName={'flex-grow'}
              readOnly={!watch('isFurtherIndividualAppointment')}
            />
          </div>
        </div>
        <div className={'flex flex-row gap-2 items-center'}>
          <Typography>{courseOfMeasuresTranslations('fields.reportingPeriod.label')}</Typography>
          <Typography>
            {courseOfMeasuresTranslations(
              'fields.reportingPeriod.reportingPeriodFromTimestamp.label'
            )}
          </Typography>

          <DatePicker control={control.register('reportingPeriodFromTimestamp')} />
          <Typography>
            {courseOfMeasuresTranslations(
              'fields.reportingPeriod.reportingPeriodToTimestamp.label'
            )}
          </Typography>
          <DatePicker control={control.register('reportingPeriodToTimestamp')} />
        </div>
        <div className={'grid grid-cols-12 grid-rows-[auto, auto] gap-x-2'}>
          <Typography className={'col-span-2 row-span-2'}>
            {courseOfMeasuresTranslations('fields.specialQuestions.label')}
          </Typography>
          <FormRadioButtonGroup
            control={control.register('selectedCourseOfMeasuresQuestionType')}
            options={questionOptions}
            className={'col-span-10'}
          />
        </div>
      </div>
    </Accordion>
  );
};
