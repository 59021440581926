import React from 'react';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { ActivityDate } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/ActivityDate/ActivityDate';
import { useDoubleClick } from '@/core/hooks/useDoubleClick';
import { useAppDispatch } from '@/core/redux/hooks';
import { widgetsModalsActions } from '@/core/redux/slices/modalsSlice/widgets/widgetsModalsSlice';
import { IActivity } from '@/types/activitiesWidget';

import 'moment/locale/de';

export const Activity: React.FC<IActivity> = ({ name, date, is_expired, persons }) => {
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    if (!persons) {
      return;
    }
    dispatch(widgetsModalsActions.openActivityPersonsListModal({ personList: persons }));
  };

  const handleDoubleClick = useDoubleClick(handleOpenModal);

  return (
    <div className={'flex flex-col justify-start items-start w-2/3 ms-1 pe-[50px]'}>
      <Button onClick={handleDoubleClick} buttonStyle={'Text'} buttonVariant={'Transparent'}>
        <Typography type={'16-M'}>{name}</Typography>
      </Button>
      <ActivityDate date={date} isExpired={is_expired} />
    </div>
  );
};
