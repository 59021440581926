import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const OrganizationUnitDefault: React.FC = () => {
  const { t: createOrganizationalUnitModalTranslations } = useTranslation(
    'createOrganizationalUnitModal'
  );

  return (
    <Typography>
      {createOrganizationalUnitModalTranslations('description.notImplemented.label')}
    </Typography>
  );
};
