import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

export interface IAdditionalPersonRequirementAssessmentPrognosisForm {
  assessment?: string | null;
  prognosis?: string | null;
}

export const AdditionalPersonRequirementAssessmentPrognosisModalContent: React.FC = () => {
  const { t: personRequirementAssessmentPrognosisModalTranslations } = useTranslation(
    'personRequirementAssessmentPrognosisModal'
  );

  const { payload, additionalPersonRequirementAssessmentPrognosis } = useAppSelector(
    organizationalUnitsModalsSelectors.additionalPersonRequirementAssessmentPrognosisModal
  );
  const form = useForm<IAdditionalPersonRequirementAssessmentPrognosisForm>();
  const currentValues = form.watch();

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(
      organizationalUnitsModalsActions.closeAdditionalPersonRequirementAssessmentPrognosisModal()
    );
  };

  useEffect(() => {
    if (!payload?.additionalPersonRequirementID) {
      return;
    }

    dispatch(
      organizationalUnitsModalsActions.fetchAdditionalPersonRequirementAssessmentPrognosis({
        additionalPersonRequirementID: payload.additionalPersonRequirementID,
        assistanceName: payload.assistanceName,
      })
    );
  }, []);

  useEffect(() => {
    if (!additionalPersonRequirementAssessmentPrognosis) {
      return;
    }

    form.reset(additionalPersonRequirementAssessmentPrognosis);
  }, [additionalPersonRequirementAssessmentPrognosis]);

  const handleSave = () => {
    if (!payload?.additionalPersonRequirementID) {
      return;
    }

    dispatch(
      organizationalUnitsModalsActions.updateAdditionalPersonRequirementAssessmentPrognosis({
        additionalPersonRequirementID: payload.additionalPersonRequirementID,
        assessment: currentValues.assessment,
        prognosis: currentValues.prognosis,
      })
    );

    handleExit();
  };

  const footer = (
    <div className={'p-3 flex gap-3 justify-end'}>
      {form.formState.isDirty && (
        <Button onClick={handleSave}>
          {personRequirementAssessmentPrognosisModalTranslations('buttons.save.label')}
        </Button>
      )}
      <Button onClick={handleExit} buttonVariant={'Secondary'}>
        {personRequirementAssessmentPrognosisModalTranslations('buttons.cancel.label')}
      </Button>
    </div>
  );

  return (
    <Modal
      onExit={handleExit}
      layer={2}
      className={'min-w-[50vw] min-h-[40vh]'}
      title={personRequirementAssessmentPrognosisModalTranslations('title.label', {
        assistanceName: payload?.assistanceName,
      })}
      footer={footer}
    >
      <div className={'flex flex-col flex-grow'}>
        <FormProvider {...form}>
          <Typography>
            {personRequirementAssessmentPrognosisModalTranslations('fields.assessment.label')}
          </Typography>
          <TextArea control={form.control.register('assessment')} className={'min-h-[150px]'} />
          <Typography>
            {personRequirementAssessmentPrognosisModalTranslations('fields.prognosis.label')}
          </Typography>
          <TextArea control={form.control.register('prognosis')} className={'min-h-[150px]'} />
        </FormProvider>
      </div>
    </Modal>
  );
};
