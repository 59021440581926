import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IDropdownItem {
  value?: string | null | undefined;
  id: number;
}

export interface IDropdownItems {
  [key: string]: IDropdownItem[];
}

export interface IDropdownItemsFetchPayload {
  dropdownTable: string;
  dropdownName?: string;
}

export interface IDropdownSetPayload {
  tableName: string;
  items: IDropdownItem[];
}

export interface IDropdownItemsState {
  dropdownItems: IDropdownItems | null;
}

const initialState: IDropdownItemsState = {
  dropdownItems: null,
};

export const dropdownItemsSlice = createSlice({
  name: 'dropdownItems',
  initialState,
  reducers: {
    //Dropdown items
    fetchDropdownItems: (state, action: PayloadAction<IDropdownItemsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setDropdownItems: (state, action: PayloadAction<IDropdownSetPayload>) => {
      const { tableName, items } = action.payload;

      return {
        ...state,
        dropdownItems: {
          ...state.dropdownItems,
          [tableName]: items,
        },
      };
    },
  },
});

export const dropdownItemsReducer = dropdownItemsSlice.reducer;
export const dropdownItemsActions = dropdownItemsSlice.actions;
