import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { ExclamationIcon } from '@/components/Icons/ExclamationIcon';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsActions } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { smartGoalsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/selectors';
import { smartGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/smartGoalsModalSlice';

const ModalContent: React.FC = () => {
  const { t: confirmDeleteSmartGoalModalTranslations } = useTranslation(
    'confirmDeleteSmartGoalModal'
  );
  const { payload } = useAppSelector(smartGoalsModalsSelectors.confirmDeleteSmartGoalModal);

  const smartGoalID = payload?.smartGoalID;
  const personID = payload?.personID;

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(smartGoalsModalActions.closeConfirmDeleteSmartGoalModal());
  };

  const deleteSmartGoal = (id: number | undefined) => {
    if (!id || !personID) {
      return;
    }

    dispatch(
      smartGoalsActions.deleteSmartGoal({
        personID: personID,
        smartGoalID: id,
      })
    );

    handleExit();
  };

  return (
    <Modal onExit={handleExit} className='w-[500px]'>
      <div className='flex flex-col gap-8 text-center'>
        <div className='flex flex-col gap-5'>
          <ExclamationIcon className='self-center' />
          <div className='flex flex-col gap-3'>
            <Typography type='H2'>
              {confirmDeleteSmartGoalModalTranslations('title.label')}
            </Typography>
            <Typography>{confirmDeleteSmartGoalModalTranslations('subtitle.label')}</Typography>
          </div>
        </div>

        <div className='flex gap-2.5'>
          <Button type='button' onClick={handleExit} buttonVariant='Secondary' className='w-full'>
            {confirmDeleteSmartGoalModalTranslations('buttons.close.label')}
          </Button>
          <Button type='button' onClick={() => deleteSmartGoal(smartGoalID)} className='w-full'>
            {confirmDeleteSmartGoalModalTranslations('buttons.confirm.label')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const ConfirmDeleteSmartGoalModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    smartGoalsModalsSelectors.confirmDeleteSmartGoalModal
  );

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
