import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import MedicationRoute from '../dashboard/MedicationRoute';

const MedicationRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route index path={'administration'} element={<MedicationRoute />} />
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </Routes>
  );
};

export default MedicationRootRoute;
