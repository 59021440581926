import React from 'react';
import { format } from 'date-fns';

import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';

interface ICalendarMonthSelect {
  setSelectedMonth: React.Dispatch<React.SetStateAction<number>>;
  selectedMonth: number;
  isFutureSelectionLocked?: boolean;
}

export const CalendarMonthSelect: React.FC<ICalendarMonthSelect> = ({
  selectedMonth,
  setSelectedMonth,
  isFutureSelectionLocked,
}) => {
  const currentMonth = new Date().getMonth();
  const monthsArray = Array.from(
    { length: isFutureSelectionLocked ? currentMonth + 1 : 12 },
    (_, i) => ({
      id: String(i),
      name: format(new Date(2000, i), 'LLLL'),
    })
  );

  const selectOptions: ISelectItem[] = monthsArray.map<ISelectItem>((month) => ({
    id: month.id,
    component: month.name,
    showCheckIcon: false,
  }));

  const handleChange = (id: string) => {
    setSelectedMonth(Number(id));
  };

  return (
    <Select
      selectedContainerClassName={'bg-transparent border-none p-1'}
      values={selectOptions}
      initialValueID={String(selectedMonth)}
      onChange={handleChange}
      initialScrollToValue={String(selectedMonth)}
      isSorted={false}
    />
  );
};
