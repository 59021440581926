import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

const useRequiredFieldsWatcher = <T extends yup.Maybe<yup.AnyObject>>(
  schema: yup.ObjectSchema<T>,
  values: T
): boolean => {
  const [allRequiredFieldsFilled, setAllRequiredFieldsFilled] = useState(false);

  const checkRequiredFields = useCallback(async (schema: yup.ObjectSchema<T>, values: T) => {
    try {
      await schema.validate(values, { abortEarly: false });
      setAllRequiredFieldsFilled(true);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const requiredErrors = error.inner.some((error) => {
          return error.type === 'required';
        });
        setAllRequiredFieldsFilled(!requiredErrors);
      } else {
        setAllRequiredFieldsFilled(false);
      }
    }
  }, []);

  useEffect(() => {
    checkRequiredFields(schema, values);
  }, [schema, values, checkRequiredFields]);

  return allRequiredFieldsFilled;
};

export default useRequiredFieldsWatcher;
