import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

import { IAuthWrapper } from '@/types/authWrapper';

import styles from './styles.module.scss';

export const AuthWrapper: React.FC<PropsWithChildren<IAuthWrapper>> = ({
  children,
  bottom = null,
}) => (
  <div className='flex flex-col h-full w-full justify-center items-center py-3'>
    <div className={clsx(styles.content, 'mb-5')}>{children}</div>
    <div />
    <div>{bottom}</div>
  </div>
);
