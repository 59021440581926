import React, { useState } from 'react';

import { EyeHider } from '@/components/Icons/EyeHider';
import { IInput, Input } from '@/components/Input/Input';

export const PasswordField: React.FC<IInput> = (props) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  return (
    <Input
      {...props}
      type={isShow ? 'text' : 'password'}
      inputVariant={'Password'}
      rightIcon={
        <EyeHider
          isShow={isShow}
          setIsShow={setIsShow}
          className={'absolute right-3 top-1/2 transform -translate-y-1/2'}
        />
      }
    />
  );
};
