import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { dropdownItemsActions } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import {
  disabilityCharacteristicsActions,
  IDisabilityCharacteristic,
  IDisabilityCharacteristicsFetchPayload,
  IDisabilityCharacteristicsUpdatePayload,
  IDisabilityCharacteristicValues,
} from '@/core/redux/slices/functions/medicalInformation/disabilityCharacteristics/slice';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type DisabilityCharacteristicsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/severely_disabled_person_pass',
  'get',
  '200'
>;
type DisabilityCharacteristicsFetchRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/severely_disabled_person_pass',
  'get'
>;

type DisabilityCharacteristicsUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/severely_disabled_person_pass',
  'post'
>;

function* fetchDisabilityCharacteristics(
  action: PayloadAction<IDisabilityCharacteristicsFetchPayload>
): Generator<any, void, DisabilityCharacteristicsResponse> {
  const { personID } = action.payload;

  try {
    yield put(
      disabilityCharacteristicsActions.setDisabilityCharacteristicsLock(LoadingStatus.LOADING)
    );

    const request: DisabilityCharacteristicsFetchRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/severely_disabled_person_pass', 'get', request);
    const mappedResponse: IDisabilityCharacteristicValues = {
      identityCardAuthoritiesID: response.disability_data?.identity_card_authorities?.id,
      isDisabledPass: response.disability_data.is_disabled_pass,
      isUnlimited: response.disability_data.is_unlimited,
      referenceNumber: response.disability_data.reference_number,
      validDate: toClientDateInput(response.disability_data.valid_date),
      characteristics: response.characteristics.map<IDisabilityCharacteristic>(
        (characteristic) => ({
          id: characteristic.id,
          name: characteristic.name,
          isSelected: characteristic.is_selected,
        })
      ),
    };

    yield put(disabilityCharacteristicsActions.setDisabilityCharacteristics(mappedResponse));

    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.IDENTITY_CARD_AUTHORITIES,
      })
    );

    yield put(
      disabilityCharacteristicsActions.setDisabilityCharacteristicsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on diagnostics disability characteristics fetch', error);
    yield put(
      disabilityCharacteristicsActions.setDisabilityCharacteristicsLock(LoadingStatus.ERROR)
    );
  }
}

function* updateDisabilityCharacteristics(
  action: PayloadAction<IDisabilityCharacteristicsUpdatePayload>
): Generator<any, void, any> {
  const { personID, formValues } = action.payload;

  try {
    yield put(
      disabilityCharacteristicsActions.setUpdateDisabilityCharacteristicsLock(LoadingStatus.LOADING)
    );

    const characteristicsKeys = Object.keys(formValues?.characteristics);

    const request: DisabilityCharacteristicsUpdateRequest = {
      json: {
        person_id: personID,
        disability_data: {
          is_disabled_pass: formValues?.isDisabledPass,
          identity_card_authorities_id: formValues?.identityCardAuthoritiesID,
          valid_date: toBackendDate(formValues?.validDate),
          is_unlimited: formValues?.isUnlimited,
          reference_number: formValues?.referenceNumber,
        },
        characteristics: characteristicsKeys.map<
          DisabilityCharacteristicsUpdateRequest['json']['characteristics'][0]
        >((key) => ({
          id: Number(key),
          is_selected: formValues?.characteristics[key],
          person_id: personID,
        })),
      },
      ...authAdd(),
    };

    yield call(restCall, '/severely_disabled_person_pass', 'post', request);

    yield put(
      disabilityCharacteristicsActions.setUpdateDisabilityCharacteristicsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on diagnostics disability characteristics update', error);
    yield put(
      disabilityCharacteristicsActions.setUpdateDisabilityCharacteristicsLock(LoadingStatus.ERROR)
    );
  }
}

export const disabilityCharacteristicsSagas = [
  takeLatest(
    disabilityCharacteristicsActions.fetchDisabilityCharacteristics,
    fetchDisabilityCharacteristics
  ),
  takeLatest(
    disabilityCharacteristicsActions.updateDisabilityCharacteristics,
    updateDisabilityCharacteristics
  ),
];
