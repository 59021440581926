import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import fp from 'lodash/fp';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  generalDataActions,
  IGeneralData,
  IGeneralDataFetchPayload,
  IGeneralDataUpdatePayload,
} from '@/core/redux/slices/functions/integrationPlan/generalData/generalDataSlice';
import { integrationPlanActions } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type GeneralDataResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/general_data',
  'get',
  '200'
>;
type GeneralDataRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/integration_plan/general_data',
  'post'
>;

function mapGeneralDataUpdatedData(updatedData?: { [x: string]: { new_value?: any } }) {
  if (!updatedData) {
    return null;
  }

  const updatedDataKeys = Object.keys(updatedData);

  if (updatedDataKeys.length <= 0) {
    return null;
  }

  return updatedDataKeys.reduce<Record<string, string>>((accum, updatedDataKey) => {
    const camelKey = fp.camelCase(updatedDataKey);

    return {
      ...accum,
      [camelKey]: updatedData[updatedDataKey].new_value,
    };
  }, {});
}

function* fetchGeneralData(
  action: PayloadAction<IGeneralDataFetchPayload>
): Generator<any, void, GeneralDataResponse> {
  yield put(generalDataActions.setGeneralDataLock(LoadingStatus.LOADING));

  const { integrationPlanID, personID } = action.payload;

  try {
    const response = yield call(restCall, '/integration_plan/general_data', 'get', {
      query: { integration_plan_id: integrationPlanID, person_id: personID },
      ...authAdd(),
    });

    const { general_data, updated_data } = response;

    const generalData: IGeneralData = {
      disabilityType: general_data.disability_type,
      healthImpact: general_data.health_impact,
      professionalMeasuresInformationBeforeWfbM:
        general_data.professional_measures_information_before_wfb_m,
      publicTransportUsageNote: general_data.public_transport_usage_note,
      schoolEducation: general_data.school_education,
      other: general_data.other,
    };

    const generalDataUpdatedData = mapGeneralDataUpdatedData(updated_data);

    yield put(generalDataActions.setGeneralData(generalData));
    yield put(generalDataActions.setUpdatedData(generalDataUpdatedData));

    yield put(generalDataActions.setGeneralDataLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration plan general data fetching');
    yield put(generalDataActions.setGeneralDataLock(LoadingStatus.ERROR));
  }
}

function* updateGeneralData(
  action: PayloadAction<IGeneralDataUpdatePayload>
): Generator<any, void, any> {
  const { integrationPlanID, personID, generalData } = action.payload;

  const request: GeneralDataRequest = {
    query: {
      person_id: personID,
      integration_plan_id: integrationPlanID,
    },
    json: {
      disability_type: generalData.disabilityType ?? null,
      health_impact: generalData.healthImpact ?? null,
      school_education: generalData.schoolEducation ?? null,
      professional_measures_information_before_wfb_m:
        generalData.professionalMeasuresInformationBeforeWfbM ?? null,
      public_transport_usage_note: generalData.publicTransportUsageNote ?? null,
      other: generalData.other ?? null,
    },
    ...authAdd(),
  };
  try {
    yield put(generalDataActions.setUpdateGeneralDataLock(LoadingStatus.LOADING));

    const updateResponse = yield call(restCall, '/integration_plan/general_data', 'post', {
      ...request,
    });

    yield put(
      integrationPlanActions.checkIntegrationPlanUpdate({
        integrationPlanID,
        updateResponse: updateResponse,
      })
    );

    yield put(generalDataActions.setUpdateGeneralDataLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration plan general data updating');
    yield put(generalDataActions.setUpdateGeneralDataLock(LoadingStatus.ERROR));
  }
}

function* updateWithFetchGeneralData(
  action: PayloadAction<IGeneralDataUpdatePayload>
): Generator<any, void, any> {
  yield call(updateGeneralData, action);

  yield put(
    generalDataActions.fetchGeneralData({
      integrationPlanID: action.payload.integrationPlanID,
      personID: action.payload.personID,
    })
  );
}

export const generalDataSagas = [
  takeLatest(generalDataActions.fetchGeneralData, fetchGeneralData),
  takeLatest(generalDataActions.updateGeneralData, updateGeneralData),
  takeLatest(generalDataActions.updateWithFetchGeneralData, updateWithFetchGeneralData),
];
