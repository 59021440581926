import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import WidgetItem from '@/components/Modals/Modals/widgets/WidgetControlModal/WidgetControlModalContent/WidgetItem/WidgetItem';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dashboardActions } from '@/core/redux/slices/dashboard/dashboardSlice';
import { dashboardSelectors } from '@/core/redux/slices/dashboard/selectors';
import { widgetsModalsActions } from '@/core/redux/slices/modalsSlice/widgets/widgetsModalsSlice';

export const WidgetControlModalContent: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui' });
  const widgets = useAppSelector(dashboardSelectors.widgets);

  const form = useForm();

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(widgetsModalsActions.closeWidgetControlModal());
  };

  const onSubmit = (data: any) => {
    const arr: any = [];
    Object.keys(data).map((key) => arr.push({ id: key, is_visible: data[key] }));
    dispatch(dashboardActions.updateWidgetSettings(arr));
    handleExit();
  };

  const reset = useCallback(() => {
    if (widgets) {
      widgets.forEach((widget: any) => {
        form.setValue(widget.id, widget.is_visible);
      });
    }
  }, [widgets, form]);

  const onCancel = () => {
    reset();
    handleExit();
  };

  useEffect(() => {
    reset();
  }, [reset, widgets]);

  return (
    <div className={clsx('flex flex-col justify-center')}>
      <Typography type='H2' color='Primary' className='pb-[30px]'>
        {t('title.editWidgets')}
      </Typography>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className='grid grid-cols-3 gap-4'>
            {widgets.map((item: any) => (
              <WidgetItem
                key={item.id}
                icon={item.widgets_id.icon.filename_disk}
                title={item.widgets_id.name || ''}
                name={item.id}
              />
            ))}
          </div>
          <div className='flex flex-row justify-between pt-[30px] gap-[10px]'>
            <Button
              className='w-full justify-center'
              buttonVariant={'Secondary'}
              onClick={onCancel}
              type='reset'
            >
              {t('button.cancel')}
            </Button>
            <Button className='w-full justify-center' type='submit'>
              {t('button.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
