import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { costCentersActions } from '@/core/redux/slices/functions/specialistGeneralData/costCenters/costCentersSlice';
import { costCentersSelectors } from '@/core/redux/slices/functions/specialistGeneralData/costCenters/selectors';
import { specialistGeneralDataModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/specialistGeneralData/selector';
import { specialistGeneralDataModalsActions } from '@/core/redux/slices/modalsSlice/functions/specialistGeneralData/specialistGeneralDataModalsSlice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IModalContent {
  selectedDepartmentID: number;
}

const ModalContent: React.FC<IModalContent> = ({ selectedDepartmentID }) => {
  const { t: assignedSpecialistsModalTranslations } = useTranslation('assignedSpecialistsModal');

  const dispatch = useAppDispatch();

  const [isShowUnauthorized, setIsShowUnauthorized] = useState(false);

  // selectors
  const departmentUsers = useAppSelector(costCentersSelectors.departmentUsers);
  const updateUserDepartmentsLock = useAppSelector(costCentersSelectors.updateUserDepartmentsLock);

  // handle exit
  const handleExit = () => {
    dispatch(specialistGeneralDataModalsActions.closeAssignedSpecialistsModal());
  };

  // actions
  const handleAddDepartmentToUser = (departmentID: number, userID: number) => {
    dispatch(
      costCentersActions.addUserDepartment({
        userID: userID,
        departmentID: departmentID,
      })
    );
  };

  const handleRemoveDepartmentFromUser = (departmentID: number, userID: number) => {
    dispatch(
      costCentersActions.deleteUserDepartment({
        userID: userID,
        departmentID: departmentID,
      })
    );
  };

  // effects
  useEffect(() => {
    if (!selectedDepartmentID) {
      return;
    }

    dispatch(
      costCentersActions.fetchDepartmentUsers({
        departmentID: selectedDepartmentID,
        isAdded: !isShowUnauthorized,
      })
    );
  }, [isShowUnauthorized]);

  useEffect(() => {
    if (updateUserDepartmentsLock === LoadingStatus.LOADED) {
      dispatch(
        costCentersActions.fetchDepartmentUsers({
          departmentID: selectedDepartmentID,
          isAdded: !isShowUnauthorized,
        })
      );
    }
  }, [updateUserDepartmentsLock]);

  const columns: ITableColumn[] = [
    {
      header: assignedSpecialistsModalTranslations('table.columns.isAdded.label'),
      accessor: '',
      isSortable: false,
      template: (_value: boolean, row) => (
        <CheckboxUI
          checked={!isShowUnauthorized}
          onChange={(value) => {
            value
              ? handleAddDepartmentToUser(selectedDepartmentID, row.id)
              : handleRemoveDepartmentFromUser(selectedDepartmentID, row.id);
          }}
        />
      ),
    },
    {
      header: assignedSpecialistsModalTranslations('table.columns.name.label'),
      accessor: 'name',
      className: 'w-3/4',
      template: (_value, row) => row.name + ' ' + row.surname,
    },
  ];


  return (
    <Sidebar
      onExit={handleExit}
      className='w-[1000px]'
      position={'right'}
      title={assignedSpecialistsModalTranslations('title.label')}
    >
      <div className='flex flex-col gap-4'>
        <label className='w-fit flex gap-2 cursor-pointer px-5'>
          <CheckboxUI
            className='pointer-events-none'
            checked={isShowUnauthorized}
            onChange={(value: boolean) => {
              setIsShowUnauthorized(value);
            }}
          />
          <Typography>{assignedSpecialistsModalTranslations('filters.isAdded.label')}</Typography>
        </label>
        <Table
          columns={columns}
          data={departmentUsers}
          selectable={false}
          className='max-h-[500px]'
        />
      </div>
    </Sidebar>
  );
};


export const AssignedSpecialistModal: React.FC = () => {
  const { isRender: isOpened, payload } = useAppSelector(
    specialistGeneralDataModalsSelectors.assignedSpecialistsModal
  );

  if (isOpened && payload) {
    return <ModalContent selectedDepartmentID={payload.departmentID} />;
  }

  return null;
};
