import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { RefreshIcon } from '@/components/Icons/RefreshIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { testingResultModalsActions } from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';
import { parseDateAndTime, toClientDateInput } from '@/core/utils/dateTimeUtil';

interface IInternalDifferentiationHeader {
  lastCompetenceAnalysisTimestamp?: string | null;
  lastInternalDifferentiationUpdateTimestamp?: string | null;
  isInternalDifferentiationObsolete?: boolean;
  personID?: number | null;
}

export const InternalDifferentiationHeader: React.FC<IInternalDifferentiationHeader> = ({
  lastInternalDifferentiationUpdateTimestamp,
  lastCompetenceAnalysisTimestamp,
  isInternalDifferentiationObsolete,
  personID,
}) => {
  const { t: internalDifferentiationTranslations } = useTranslation('testingResult', {
    keyPrefix: 'testProcedures.internalDifferentiation.header',
  });

  const parsedLastInternalDifferentiationUpdateDate = parseDateAndTime(
    lastInternalDifferentiationUpdateTimestamp
  );
  const parsedLastPerformanceProfileDate = parseDateAndTime(lastCompetenceAnalysisTimestamp);

  const dispatch = useAppDispatch();

  const handleUpdate = () => {
    if (!personID) {
      return;
    }

    dispatch(
      testingResultModalsActions.openImportInternalDifferentiationModal({
        personID: personID,
      })
    );
  };

  return (
    <div className={'flex flex-row justify-between gap-3 '}>
      <Typography color={isInternalDifferentiationObsolete ? 'Error' : 'Primary'}>
        {internalDifferentiationTranslations('lastPerformanceProfileDate.label')}
        {parsedLastPerformanceProfileDate
          ? internalDifferentiationTranslations('dateAndTime.label', {
              date: toClientDateInput(parsedLastPerformanceProfileDate.date),
              time: parsedLastPerformanceProfileDate.time,
            })
          : internalDifferentiationTranslations('emptyHolder.label')}
        {internalDifferentiationTranslations('divider.label')}
        {internalDifferentiationTranslations('lastInternalDifferentiationUpdateDate.label')}
        {parsedLastInternalDifferentiationUpdateDate
          ? internalDifferentiationTranslations('dateAndTime.label', {
              date: toClientDateInput(parsedLastInternalDifferentiationUpdateDate.date),
              time: parsedLastInternalDifferentiationUpdateDate.time,
            })
          : internalDifferentiationTranslations('emptyHolder.label')}
      </Typography>
      <Button onClick={handleUpdate} buttonVariant={'Icon'} buttonStyle={'Icon'}>
        <RefreshIcon />
      </Button>
    </div>
  );
};
