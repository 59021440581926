import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.specialistGeneralData.organizationUnits;

// organization units
const availableUnits = createSelector(
  [selector],
  (state) => state.availableUnits
);
const availableUnitsLock = createSelector(
  [selector],
  (state) => state.availableUnitsLock
);
const updateUserUnitsLock = createSelector(
  [selector],
  (state) => state.updateUnitsLock
);
// users
const organizationUnitUsers = createSelector(
  [selector],
  (state) => state.organizationUnitUsers
);

export const specialistOrganizationUnitsSelectors = {
  availableUnits,
  availableUnitsLock,
  updateUserUnitsLock,

  organizationUnitUsers
};
