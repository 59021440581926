import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ICheckedState } from '@/components/views/person/PersonPage/DepartmentView/DepartmentList/DepartmentsTreeSelect/DepartmentsTreeSelect';
import { initializeApollo } from '@/core/clients/apollo';
import { Language } from '@/core/enums/languageEnum';
import { authSelectors } from '@/core/redux/slices/auth/selectors';
import { IUpdateFavoritePersonsPayload, userSettingsActions } from '@/core/redux/slices/userSettings/userSettingsSlice';
import i18n from '@/i18n';
import {
  GetUserSettingsDocument,
  GetUserSettingsQuery,
  GetUserSettingsQueryHookResult,
  UpdateUserFavoritePersonsDocument,
  UpdateUserFavoritePersonsMutation,
  UpdateUserFavoritePersonsMutationResult,
  UpdateUserSelectedDepartmentsDocument,
  UpdateUserSelectedDepartmentsMutation,
  UpdateUserSettingsLanguageDocument,
  UpdateUserSettingsLanguageMutation,
} from '@/services/graphql/base/graphql';
import { IUser } from '@/types/user';

function* fetchUserSettings(): Generator<any, void, any> {
  try {
    const client = initializeApollo();

    const response: GetUserSettingsQueryHookResult = yield call(client.query<GetUserSettingsQuery>, {
      query: GetUserSettingsDocument,
      errorPolicy: 'ignore',
    });

    const user: IUser = yield select(authSelectors.profileEntity);

    const currentUserSettings =  response?.data?.settings.find((settings ) => settings.id === user.settingsID)

    if(currentUserSettings) {
      const userSettingsLanguage = currentUserSettings.language_code as Language ?? Language.DEUTSCH;
      const userSelectedDepartments = currentUserSettings.selected_departments;
      const userFavoritePersons = currentUserSettings.favorite_persons;
        
      const parsedUserFavoritePersons: number[] = userFavoritePersons ? JSON.parse(userFavoritePersons) : []
  
      yield put(userSettingsActions.setUserSettingsLanguage(userSettingsLanguage));
      yield put(userSettingsActions.setUserSelectedDepartments(userSelectedDepartments));
      yield put(userSettingsActions.setFavoritePersons(parsedUserFavoritePersons));
  
      yield i18n.changeLanguage(userSettingsLanguage);
    }

  } catch (error) {
    console.log('Error on user settings data fetching');
  }
}

function* updateUserSettingsLanguage(action: PayloadAction<Language>): Generator<any, void, any> {
  try {
    const client = initializeApollo();

    const user = yield select(authSelectors.profileEntity);

    yield call(client.mutate<UpdateUserSettingsLanguageMutation>, {
      mutation: UpdateUserSettingsLanguageDocument,
      variables: {
        id: user.settingsID,
        language_code: action.payload,
      },
    });

    yield put(userSettingsActions.setUserSettingsLanguage(action.payload));

  } catch (error) {
    console.log('Error on user settings language updating');
  }
}

function* updateUserSelectedDepartments(action: PayloadAction<ICheckedState>): Generator<any, void, any> {
  try {
    const client = initializeApollo();

    const user = yield select(authSelectors.profileEntity);

    yield call(client.mutate<UpdateUserSelectedDepartmentsMutation>, {
      mutation: UpdateUserSelectedDepartmentsDocument,
      variables: {
        id: user.settingsID,
        selected_departments: JSON.stringify(action.payload),
      },
    });


  } catch (error) {
    console.log('Error on user selected departments updating');
  }
}

function* updateFavoritePersons(action: PayloadAction<IUpdateFavoritePersonsPayload>): Generator<any, void, any> {
  const {favoritePersons} = action.payload

  try {
    const client = initializeApollo();
    const user = yield select(authSelectors.profileEntity);

   const response: UpdateUserFavoritePersonsMutationResult = yield call(client.mutate<UpdateUserFavoritePersonsMutation>, {
      mutation: UpdateUserFavoritePersonsDocument,
      variables: {
        id: user.settingsID,
        favorite_persons: JSON.stringify(favoritePersons),
      },
    });

   const updatedFavoritePersons: number[] = response?.data?.update_settings_item?.favorite_persons ? JSON.parse(response.data.update_settings_item.favorite_persons) : []

   yield put(userSettingsActions.setFavoritePersons(updatedFavoritePersons))
  } catch (error) {
    console.log('Error on user favorite persons updating', error);
  }
}

export const userSettingsSagas = [
  takeLatest(userSettingsActions.fetchUserSettings, fetchUserSettings),
  takeLatest(userSettingsActions.updateUserSettingsLanguage, updateUserSettingsLanguage),
  takeLatest(userSettingsActions.updateUserSelectedDepartments, updateUserSelectedDepartments),
  takeLatest(userSettingsActions.updateFavoritePersons, updateFavoritePersons),

];