import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { Input } from '@/components/Input/Input';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Pagination } from '@/components/Pagination/Pagination';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { itemsICFActions } from '@/core/redux/slices/functions/diagnostics/itemsICF/itemsICFSlice';
import { itemsICFSelectors } from '@/core/redux/slices/functions/diagnostics/itemsICF/selectors';
import { diagnosticsModalsActions } from '@/core/redux/slices/modalsSlice/functions/diagnostics/diagnosticsModalsSlice';
import { diagnosticsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/diagnostics/selector';

const paginationSize = 10;
const paginationChunkSize = 3;

const searchInput = 'search-icf-items';
const selectedCheckbox = 'check-selected-items';

const ModalContent: React.FC = () => {
  const { t: addICFItemsModalTranslations } = useTranslation('addICFItemsModal');

  const columns: ITableColumn[] = [
    {
      header: addICFItemsModalTranslations('columns.code.label'),
      accessor: 'code',
      template: (htmlString: string) => (
        <div className='[&>span]:text-blue' dangerouslySetInnerHTML={{ __html: htmlString }} />
      ),
    },
    {
      header: addICFItemsModalTranslations('columns.name.label'),
      accessor: 'name',
      className: 'max-w-[200px] min-w-[200px]',
      template: (htmlString: string) => (
        <div
          className='whitespace-normal [&>span]:text-blue'
          dangerouslySetInnerHTML={{ __html: htmlString }}
        />
      ),
    },
    {
      header: addICFItemsModalTranslations('columns.text.label'),
      accessor: 'text',
      className: 'w-full',
      template: (htmlString: string) => (
        <div
          className='whitespace-normal [&>span]:text-blue'
          dangerouslySetInnerHTML={{ __html: htmlString }}
        />
      ),
    },
  ];

  const { payload } = useAppSelector(diagnosticsModalsSelectors.addICFItemsModal);
  const availableItems = useAppSelector(itemsICFSelectors.availableItemsICFData);
  const totalCountAvailable = useAppSelector(itemsICFSelectors.availableIemsICFTotalCount);
  const personID = payload?.personID;

  const dispatch = useAppDispatch();

  const methods = useForm();
  const searchValue = methods.watch(searchInput);
  const checkValue = methods.watch(selectedCheckbox);

  const [selectedPage, setSelectedPage] = useState<number>(1);

  const defaultSelectedItems = useMemo(
    () => availableItems.filter((item) => item.isSelected).map((item) => item.id),
    [availableItems]
  );

  const TableHeader = (
    <div className='flex gap-4 items-center px-5'>
      <div className='grow'>
        <Input
          inputVariant='WidgetSearch'
          placeholder={addICFItemsModalTranslations('tableHeader.filter.placeholder')}
          control={methods.control.register(searchInput)}
        />
      </div>
      <CheckboxItem
        label={addICFItemsModalTranslations('tableHeader.showOnlySelected.label')}
        fieldName={selectedCheckbox}
        labelPosition={'left'}
      />
    </div>
  );

  const TableFooter = (
    <div>
      <Pagination
        total={totalCountAvailable}
        selectedPage={selectedPage}
        paginationSize={paginationSize}
        paginationChunkSize={paginationChunkSize}
        setSelectedPage={setSelectedPage}
        className={'px-5'}
      />
    </div>
  );

  const handleExit = () => {
    if (!personID) {
      return;
    }

    dispatch(
      itemsICFActions.fetchItemsICF({
        personID: personID,
        limit: 0,
        page: 0,
      })
    );
    dispatch(diagnosticsModalsActions.closeAddICFItemsModal());
  };

  useEffect(() => {
    if (!personID) {
      return;
    }

    dispatch(
      itemsICFActions.fetchAvailableICFItems({
        personID: personID,
        limit: paginationSize,
        page: selectedPage - 1,
        search: searchValue,
        isSelected: checkValue || undefined,
      })
    );
  }, [dispatch, selectedPage, personID, searchValue, checkValue]);

  useEffect(() => {
    setSelectedPage(1);
  }, [checkValue, searchValue]);

  const onSelectedItemsChange = (selectedItems: number[]) => {
    if (!personID) {
      return;
    }

    const updatedItems = availableItems.map((item) => ({
      id: item.id,
      isSelected: selectedItems.includes(item.id),
    }));

    dispatch(
      itemsICFActions.updateItemsICF({
        personID: personID,
        updatedItems: updatedItems,
      })
    );
  };

  return (
    <Sidebar
      position={'right'}
      title={addICFItemsModalTranslations('title.label')}
      onExit={handleExit}
    >
      <div className={'h-full flex flex-col'}>
        <FormProvider {...methods}>
          <Table
            columns={columns}
            data={availableItems}
            initialSelectedState={defaultSelectedItems}
            header={TableHeader}
            footer={TableFooter}
            onMultiSelectChange={onSelectedItemsChange}
            className='flex-1 w-[1000px] min-h-[300px] pl-2'
          />
        </FormProvider>
      </div>
    </Sidebar>
  );
};

export const AddICFItemsModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(diagnosticsModalsSelectors.addICFItemsModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
