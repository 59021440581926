import React from 'react';
import clsx from 'clsx';

import {
  IIntegrationPlanForm,
  IntegrationPlanFormActions,
} from '@/components/functions/IntegrationPlan/IntegrationPlan';
import styles from '@/components/functions/IntegrationPlan/styles.module.scss';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { useAppSelector } from '@/core/redux/hooks';
import { integrationPlanSelectors } from '@/core/redux/slices/functions/integrationPlan/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

export enum IntegrationPlanTabs {
  ActionCourse = 'actionCourse',
  GeneralData = 'generalData',
  StatusReport = 'statusReport',
  CompetenceAnalysis = 'competenceAnalysis',
  SummaryFA = 'summaryFA',
  ParticipantExpectation = 'participantExpectation',
  QualificationDocumentation = 'qualificationDocumentation',
}

export interface IIntegrationPlanFormProps {
  integrationPlanID: number | null;
  personID?: number | null;
  ref?: React.RefObject<IntegrationPlanFormActions>;
}

interface IIntegrationPlanFormsContainer {
  activeTab: IntegrationPlanTabs;
  integrationPlanForms: Record<IntegrationPlanTabs, IIntegrationPlanForm>;
}

export const IntegrationPlanFormsContainer: React.FC<IIntegrationPlanFormsContainer> = ({
  activeTab,
  integrationPlanForms,
}) => {
  const integrationPlanLock = useAppSelector(integrationPlanSelectors.integrationPlanLock);

  return (
    <Skeleton trigger={integrationPlanLock === LoadingStatus.LOADING}>
      <div className={clsx('overflow-y-auto w-full', styles.container)}>
        <div className={'p-4'}>{integrationPlanForms[activeTab].form}</div>
      </div>
    </Skeleton>
  );
};
