import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const TableHeader: React.FC = () => {
  const { t: qualificationObjectivesModalTranslations } = useTranslation(
    'qualificationObjectivesModal',
    { keyPrefix: 'tableHeader' }
  );

  return (
    <div className={'grid grid-cols-12 gap-4 items-center justify-items-center px-4'}>
      <Typography className={'col-span-1'} color={'Secondary'} type={'14-M'}>
        {qualificationObjectivesModalTranslations('adopt.label')}
      </Typography>
      <Typography className={'col-span-7 justify-self-start'} color={'Secondary'} type={'14-M'}>
        {qualificationObjectivesModalTranslations('qualificationObjective.label')}
      </Typography>
      <Typography className={'col-span-2'} color={'Secondary'} type={'14-M'}>
        {qualificationObjectivesModalTranslations('dateFrom.label')}
      </Typography>
      <Typography className={'col-span-2'} color={'Secondary'} type={'14-M'}>
        {qualificationObjectivesModalTranslations('dateTo.label')}
      </Typography>
    </div>
  );
};
