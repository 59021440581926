import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { ArrowIcon } from '@/components/Icons/ArrowIcon';
import { Typography } from '@/components/Typography/Typography';
import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import styles from '@/components/views/person/PersonPage/DepartmentView/styles.module.scss';

export const DepartmentListExpand: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.label' });
  const [isExpandedAll, setIsExpenedAll] = usePersonPageStoreSelector(
    (store) => store.allDepartmentsListExpanded
  );

  const handleExpandAll = () => {
    setIsExpenedAll({
      allDepartmentsListExpanded: !isExpandedAll,
    });
  };

  return (
    <div
      className='flex flex-row items-center cursor-pointer'
      onClick={handleExpandAll}
      role={'button'}
    >
      <Typography type='16-R' color='Secondary'>
        {isExpandedAll ? t('collapseAll') : t('expandAll')}
      </Typography>
      <motion.div
        className={clsx(styles.arrowButton, styles.filterButton, 'ms-2')}
        animate={{ rotate: isExpandedAll ? 180 : 0 }}
      >
        <ArrowIcon />
      </motion.div>
    </div>
  );
};
