import React, { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/Button/Button';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { FormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import { QualificationObjectiveNameExtension } from '@/components/forms/QualificationDocumentation/QualificationObjectives/QualificationObjectivesItem/QualificationObjectiveNameExtension/QualificationObjectiveNameExtension';
import { IRadioButtonGroupItem } from '@/components/RadioButtonGroup/RadioButtonGroup';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { useDoubleClick } from '@/core/hooks/useDoubleClick';
import { useOnClickOutside } from '@/core/hooks/useOnClickOutside';
import { twMerge } from '@/core/utils/tailwindUtil';

interface IQualificationObjectivesItem {
  objectiveName: string;
  objectiveID: string;
  onClick?: (objectiveID: string) => void;
  className?: string;
}

export const QualificationObjectivesItem: React.FC<IQualificationObjectivesItem> = ({
  objectiveID,
  objectiveName,
  onClick,
  className,
}) => {
  const [nameExtensionViewMode, setNameExtensionViewMode] = useState<'input' | 'text'>('text');

  const { control } = useFormContext();

  const options: IRadioButtonGroupItem[] = [
    { value: 'isStarted' },
    { value: 'isAchieved' },
    { value: 'isNotStarted' },
  ];

  const newOptions: IFormRadioButtonGroupItem[] = [
    {
      id: `${objectiveID}.isStarted`,
      component: <div className={'hidden'} />,
      value: 'isStarted',
    },
    {
      id: `${objectiveID}.isAchieved`,
      component: <div className={'hidden'} />,
      value: 'isAchieved',
    },
    {
      id: `${objectiveID}.isNotStarted`,
      component: <div className={'hidden'} />,
      value: 'isNotStarted',
    },
  ];

  const setViewModeToInput = () => {
    setNameExtensionViewMode('input');
  };

  const handleDoubleCLick = useDoubleClick(setViewModeToInput);

  const onDoubleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    handleDoubleCLick();
  };

  const setViewModeToText = () => {
    setNameExtensionViewMode('text');
  };

  const ref = useRef(null);

  useOnClickOutside(ref, setViewModeToText);

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setViewModeToText();
    }
  };

  const handleClick = () => {
    onClick?.(objectiveID);
  };

  return (
    <div
      className={twMerge('grid grid-cols-16 gap-4 items-start p-2', className)}
      onClick={handleClick}
    >
      <div className={'col-span-11 grid grid-cols-12 gap-3 items-center'}>
        <Button
          buttonStyle={'Text'}
          buttonVariant={'Transparent'}
          className={'col-span-6'}
          onClick={onDoubleClick}
          type={'button'}
          ref={ref}
        >
          <Typography className={'text-wrap'}>{objectiveName}</Typography>
          <QualificationObjectiveNameExtension
            objectiveID={objectiveID}
            viewMode={nameExtensionViewMode}
            onEnterPress={handleEnterPress}
          />
        </Button>
        <DatePicker
          wrapperClassName={'col-span-3 flex justify-center'}
          control={control.register(
            `qualificationsDocumentations.${objectiveID}.agreementTimestamp`
          )}
        />
        <FormRadioButtonGroup
          control={control.register(`qualificationsDocumentations.${objectiveID}.status`)}
          options={newOptions}
          className={'col-span-3  '}
          containerClassName={'grid grid-cols-3 items-center justify-items-center'}
          isResettable={true}
        />
      </div>
      <TextArea
        control={control.register(
          `qualificationsDocumentations.${objectiveID}.qualificationObjectiveComment`
        )}
        containerClassName={'col-span-5'}
      />
    </div>
  );
};
