import React from 'react';

import { IIcon } from '@/types/icon';

export const SortingAscendIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M2 5.28176C2 4.73351 2.44445 4.28906 2.9927 4.28906H8.24817C8.79643 4.28906 9.24088 4.73351 9.24088 5.28176C9.24088 5.83002 8.79643 6.27446 8.24817 6.27446H2.9927C2.44445 6.27446 2 5.83002 2 5.28176Z'
      fill='#3575EF'
    />
    <path
      d='M2 9.9533C2 9.40504 2.44445 8.9606 2.9927 8.9606H12.3358C12.884 8.9606 13.3285 9.40504 13.3285 9.9533C13.3285 10.5015 12.884 10.946 12.3358 10.946H2.9927C2.44445 10.946 2 10.5015 2 9.9533Z'
      fill='#3575EF'
    />
    <path
      d='M2.9927 13.6321C2.44445 13.6321 2 14.0766 2 14.6248C2 15.1731 2.44445 15.6175 2.9927 15.6175H17.0073C17.5556 15.6175 18 15.1731 18 14.6248C18 14.0766 17.5556 13.6321 17.0073 13.6321H2.9927Z'
      fill='#3575EF'
    />
  </svg>
);
