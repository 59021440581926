import { createSlice } from '@reduxjs/toolkit';

export interface IModal {
  isRender: boolean;
}

export interface ILogbookModalsState {
  logbookEditModal: IModal;
  logbookListModal: IModal;
  newLogbookEntryModal: IModal;
}

const initialState: ILogbookModalsState = {
  logbookEditModal: {
    isRender: false,
  },
  logbookListModal: {
    isRender: false,
  },
  newLogbookEntryModal: {
    isRender: false,
  }
};

export const logbookModalsSlice = createSlice({
  name: 'logbookModals',
  initialState,
  reducers: {
    //Logbook edit modal
    openLogbookEditModal: (state) => {
      return {
        ...state,
        logbookEditModal: {
          isRender: true,
        },
      };
    },
    closeLogbookEditModal: (state) => {
      return {
        ...state,
        logbookEditModal: {
          isRender: false,
        },
      };
    },
    //Logbook list modal
    openLogbookListModal: (state) => {
      return {
        ...state,
        logbookListModal: {
          isRender: true,
        },
      };
    },
    closeLogbookListModal: (state) => {
      return {
        ...state,
        logbookListModal: {
          isRender: false,
        },
      };
    },
  //New logbook entry modal
    openNewLogbookEntryModal: (state) => {
      return {
        ...state,
        newLogbookEntryModal: {
          isRender: true,
        },
      };
    },
    closeNewLogbookEntryModal: (state) => {
      return {
        ...state,
        newLogbookEntryModal: {
          isRender: false,
        },
      };
    },
  },
});

export const logbookModalsReducers = logbookModalsSlice.reducer;
export const logbookModalsActions = logbookModalsSlice.actions;
