import { ObjectSchema } from 'yup';

import { IGoalCheckFormFields } from '@/components/forms/GoalCheck/GoalCheck';
import { yup } from '@/core/utils/commonUtils';

export const GoalCheckFormResolver: ObjectSchema<IGoalCheckFormFields> = yup.object().shape({
  checkedAtDate: yup.string().required(),
  achievementLevel: yup.string().required(),
  note: yup.string().nullable(),
  nextAction: yup.string().optional().required(),
  nextCheckDate: yup
    .string()
    .optional()
    .when('nextAction', {
      is: (value: number) => value === 3,
      then: () => yup.string().required(),
      otherwise: () => yup.string().optional().nullable(),
    }),
});
