import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) =>
  state.functions.developmentReport.developmentReportReviewSupport;

const developmentReportReviewSupport = createSelector(
  [selector],
  (state) => state.developmentReportReviewSupport
);
const developmentReportReviewSupportLock = createSelector(
  [selector],
  (state) => state.developmentReportReviewSupportLock
);
const importedDevelopmentReportReviewSupport = createSelector(
  [selector],
  (state) => state.importedDevelopmentReportReviewSupport
);
const importedDevelopmentReportReviewSupportLock = createSelector(
  [selector],
  (state) => state.importedDevelopmentReportReviewSupportLock
);

export const developmentReportReviewSupportSelectors = {
  developmentReportReviewSupport,
  developmentReportReviewSupportLock,
  importedDevelopmentReportReviewSupport,
  importedDevelopmentReportReviewSupportLock,
};
