import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { Typography } from '@/components/Typography/Typography';

export const EndeEV: React.FC = () => {
  const { t: endeEVTranslations } = useTranslation('summaryFA', { keyPrefix: 'endeEV' });

  return (
    <div className={'flex flex-col gap-3'}>
      <Typography type={'H3'}>{endeEVTranslations('title.label')}</Typography>
      <CheckboxItem
        label={endeEVTranslations('fields.isEndeEv1.label')}
        fieldName={'isEndeEv1'}
        className={'justify-start'}
      />
      <div className={'ps-[50px] flex flex-col gap-3'}>
        <CheckboxItem
          label={endeEVTranslations('fields.isEndeEv2.label')}
          fieldName={'isEndeEv2'}
          className={'justify-start'}
        />
      </div>
    </div>
  );
};
