import React from 'react';
import { useFormContext } from 'react-hook-form';

import { IVocationalTrainingAreaForm } from '@/components/functions/VocationalTrainingArea/VocationalTrainingArea';
import { VocationalTrainingAreaGroupedList } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaGroupedList/VocationalTrainingAreaGroupedList';
import { VocationalTrainingAreaList } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaList/VocationalTrainingAreaList';
import { VocationalTrainingAreaTableHeader } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaTableHeader/VocationalTrainingAreaTableHeader';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaSelectors } from '@/core/redux/slices/functions/vocationalTrainingArea/selector';
import { LoadingStatus } from '@/types/loadingStatus';

export const VocationalTrainingAreaTable: React.FC = () => {
  const vocationalTrainingAreaGroupsLock = useAppSelector(
    vocationalTrainingAreaSelectors.vocationalTrainingAreaGroupsLock
  );

  const formContext = useFormContext<IVocationalTrainingAreaForm>();
  const isGroupView = formContext.watch().groupView;

  return (
    <div className={'flex flex-col h-full max-h-[calc(100vh_-_485px)]'}>
      <VocationalTrainingAreaTableHeader />
      <Skeleton trigger={vocationalTrainingAreaGroupsLock === LoadingStatus.LOADING}>
        <div className={'overflow-y-auto h-full'}>
          {isGroupView ? <VocationalTrainingAreaGroupedList /> : <VocationalTrainingAreaList />}
        </div>
      </Skeleton>
    </div>
  );
};
