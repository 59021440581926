import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextArea } from '@/components/TextArea/TextArea';

interface IInternshipsDocumentationItem {
  internshipsDocumentationItemID: string;
}

export const InternshipsDocumentationItem: React.FC<IInternshipsDocumentationItem> = ({
  internshipsDocumentationItemID,
}) => {
  const { control } = useFormContext();

  return (
    <div className={'grid grid-cols-3 gap-3 items-center'}>
      <TextArea
        control={control.register(`internships.${internshipsDocumentationItemID}.internshipType`)}
      />
      <TextArea
        control={control.register(
          `internships.${internshipsDocumentationItemID}.internshipLocation`
        )}
      />
      <TextArea
        control={control.register(`internships.${internshipsDocumentationItemID}.internshipPeriod`)}
      />
    </div>
  );
};
