import React from 'react';

import { UpdateAppliedTestProcedureModalContent } from '@/components/Modals/Modals/functions/testingResultModals/UpdateAppliedTestProcedureModal/UpdateAppliedTestProcedureModalContent/UpdateAppliedTestProcedureModalContent';
import { useAppSelector } from '@/core/redux/hooks';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';

export const UpdateAppliedTestProcedureModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    testingResultModalsSelectors.updateAppliedTestingResultModal
  );

  return isOpened ? <UpdateAppliedTestProcedureModalContent /> : null;
};
