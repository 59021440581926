import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

export default function ReduceModelStoreContext<State>() {
  type IAction = {
    type: string;
    payload: Partial<State>;
  };

  const MODEL_TYPE = 'MODEL_TYPE';

  function reducer(state: State, action: IAction) {
    switch (action.type) {
      case MODEL_TYPE: {
        return {
          ...state,
          ...action.payload,
        };
      }
      default:
        return state;
    }
  }

  function useStoreData(data: State) {
    const [store, dispatch] = useReducer(reducer, data);

    const get = useCallback((): State => store, [store]);

    const set = useCallback((value: Partial<State>) => {
      dispatch({
        type: MODEL_TYPE,
        payload: {
          ...value,
        },
      });
    }, []);

    return {
      get,
      set,
    };
  }

  const StoreContext = createContext<ReturnType<typeof useStoreData> | null>(null);

  function StoreProvider({ state, children }: { state: State; children: React.ReactNode }) {
    return <StoreContext.Provider value={useStoreData(state)}>{children}</StoreContext.Provider>;
  }

  function useStoreSelector<T>(
    selector: (state: State) => T
  ): [T, (value: Partial<State>) => void] {
    const store = useContext(StoreContext);

    if (!store) {
      throw new Error('Store not found');
    }

    const [state, setState] = useState(() => selector(store.get()));

    useEffect(() => {
      setState(selector(store.get()));
    }, [store, selector]);

    return [state, store.set];
  }

  return {
    StoreProvider,
    useStoreSelector,
  };
}
