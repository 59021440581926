import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { ReviewItem } from '@/components/forms/CompetenceAnalysis/ReviewItem/ReviewItem';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const MethodologicalSkills: React.FC = () => {
  const { t: methodologicalSkillsTranslations } = useTranslation('competenceAnalysis', {
    keyPrefix: 'methodologicalSkills',
  });

  return (
    <Accordion
      title={<Typography type={'H3'}>{methodologicalSkillsTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'flex flex-col gap-2 mt-4'}>
        <ReviewItem
          title={methodologicalSkillsTranslations('fields.concentration.label')}
          fieldName={'concentration'}
        />
        <ReviewItem
          title={methodologicalSkillsTranslations('fields.memorization.label')}
          fieldName={'memorization'}
        />
        <ReviewItem
          title={methodologicalSkillsTranslations('fields.workSpeed.label')}
          fieldName={'workSpeed'}
        />
      </div>
    </Accordion>
  );
};
