import React, { useEffect } from 'react';

import { CompleteAppointmentGroupedListGroup } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/CompleteAppointmentGroupedList/CompleteAppointmentGroupedListGroup';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/selectors';
import { activityPlaningAppointmentDetailsModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';

export const CompleteAppointmentGroupedList: React.FC = () => {
  const { appointmentCompleteQuestionnaire } = useAppSelector(
    activityPlaningAppointmentDetailsModalsSelectors.completeAppointmentModal
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        activityPlaningAppointmentDetailsModalsActions.setAppointmentCompleteQuestionnaire(null)
      );
    };
  }, []);

  return (
    <div>
      {appointmentCompleteQuestionnaire && (
        <CompleteAppointmentGroupedListGroup
          {...appointmentCompleteQuestionnaire}
          id={appointmentCompleteQuestionnaire.id}
          layer={0}
        />
      )}
    </div>
  );
};
