import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useActivityPlaningWizardSelector } from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardContext/ActivityPlanningContext';
import { MeasuresListHeader } from '@/components/functions/ActivityPlanning/WizardFunction/MeasuresList/MeasureListHeader/MeasuresListHeader';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningMeasuresListSelectors } from '@/core/redux/slices/functions/activityPlanning/measuresList/selectors';
import { activityPlaningMeasuresListActions } from '@/core/redux/slices/functions/activityPlanning/measuresList/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const MeasuresList: React.FC = () => {
  const { t: measuresListTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'measuresList',
  });

  const [selectedMeasureID, setSelectedMeasureID] = useActivityPlaningWizardSelector(
    (store) => store.selectedMeasureID
  );
  const [, setSelectedMeasureName] = useActivityPlaningWizardSelector(
    (store) => store.selectedMeasureName
  );

  const measuresList = useAppSelector(activityPlaningMeasuresListSelectors.measuresList);
  const measuresListLock = useAppSelector(activityPlaningMeasuresListSelectors.measuresListLock);

  const [selectedTableID, setSelectedTableID] = useState<number | null>(null);

  const { selectedPersonID } = useSelectedPerson();
  const dispatch = useAppDispatch();

  const columns: ITableColumn[] = [
    {
      accessor: 'name',
      header: measuresListTranslations('list.tableHeader.name.label'),
      className: 'whitespace-normal',
    },
    { accessor: 'measureType', header: measuresListTranslations('list.tableHeader.type.label') },
  ];

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      activityPlaningMeasuresListActions.fetchMeasuresList({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID]);

  useEffect(() => {
    if (!measuresList?.length) return;

    const defaultMeasure = measuresList[0];
    const foundMeasure =
      measuresList.find(({ measureID }) => measureID === selectedMeasureID) || defaultMeasure;

    if (!selectedMeasureID) {
      setSelectedMeasureID({ selectedMeasureID: defaultMeasure.measureID });
    }

    setSelectedTableID(foundMeasure.id);
    setSelectedMeasureName({ selectedMeasureName: foundMeasure.name });
  }, [measuresList]);

  useEffect(() => {
    return () => {
      dispatch(activityPlaningMeasuresListActions.setMeasuresList([]));
    };
  }, []);

  const handleSelect = (id: number) => {
    const foundElement = measuresList.find((measure) => measure.id === id);

    if (!foundElement) {
      return;
    }

    setSelectedTableID(id);
    setSelectedMeasureID({
      selectedMeasureID: foundElement.measureID,
    });
    setSelectedMeasureName({ selectedMeasureName: foundElement.name });
  };

  return (
    <div className={'flex flex-col gap-2 h-full'}>
      <MeasuresListHeader />
      <Skeleton trigger={measuresListLock === LoadingStatus.LOADING} rows={8}>
        <Table
          isDeselectAllowed={false}
          data={measuresList}
          columns={columns}
          singleSelect={true}
          initialSelectedState={[selectedTableID]}
          onSingleSelectChange={handleSelect}
        />
      </Skeleton>
    </div>
  );
};
