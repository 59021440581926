import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { ArrowIcon } from '@/components/Icons/ArrowIcon';
import { Typography } from '@/components/Typography/Typography';
import {
  IInternship,
  ILocation,
} from '@/core/redux/slices/functions/specialistGeneralData/internships/internshipsSlice';

interface IInternshipsList {
  internships: IInternship[];
  onInternshipChange: (internshipID: number, isSelected: boolean) => void;
  onLocationChange: (internshipID: number, locationID: number, isSelected: boolean) => void;
}

enum CheckboxState {
  Checked = 'checked',
  Intermediate = 'intermediate',
  Empty = 'empty',
}

const InternshipList: React.FC<IInternshipsList> = ({
  internships,
  onInternshipChange,
  onLocationChange,
}) => {
  const { t: internshipsTranslations } = useTranslation('internships');

  // state
  const [internshipState, setInternshipState] = useState<IInternship[]>(internships);
  const [collapsedState, setCollapsedState] = useState<Record<number, boolean>>({});

  useEffect(() => {
    setInternshipState(internships);
  }, [internships]);

  // handle individual location checkbox changes
  const handleLocationChange = (internshipID: number, locationID: number, isSelected: boolean) => {
    const updatedInternships = internshipState.map((internship) =>
      internship.id === internshipID
        ? {
            ...internship,
            locations: internship.locations.map((location) =>
              location.id === locationID ? { ...location, isSelected: isSelected } : location
            ),
          }
        : internship
    );

    // update state and notify the parent component.
    setInternshipState(updatedInternships);
    onLocationChange(internshipID, locationID, isSelected);
  };

  // handle internship checkbox changes
  const handleInternshipChange = (internshipID: number, isSelected: boolean) => {
    const updatedInternships = internshipState.map((internship) =>
      internship.id === internshipID
        ? {
            ...internship,
            isSelected: isSelected,
            locations: internship.locations.map((location) => ({
              ...location,
              isSelected: isSelected, // set all locations to match internship selection
            })),
          }
        : internship
    );

    // update state and notify the parent component
    setInternshipState(updatedInternships);
    onInternshipChange(internshipID, isSelected);
  };

  // handle collapse/expand for internships
  const toggleCollapse = (internshipID: number) => {
    setCollapsedState((prevState) => ({
      ...prevState,
      [internshipID]: !prevState[internshipID],
    }));
  };

  // determine the checkbox state (empty, colored, or tick) based on location selections
  const getCheckboxState = (locations: ILocation[]): CheckboxState => {
    const allSelected = locations.every((location) => location.isSelected);
    const noneSelected = locations.every((location) => !location.isSelected);
    if (allSelected) return CheckboxState.Checked;
    if (noneSelected) return CheckboxState.Empty;
    return CheckboxState.Intermediate;
  };

  return (
    <AnimatePresence initial={false}>
      <div>
        <div className='grid  grid-cols-12 py-1 border-y-[1px] border-border-light'>
          <div className='col-span-1'></div>
          <div className='col-span-10'>
            <div className='py-3'>
              <Typography type='14-M'>
                {internshipsTranslations('table.columns.name.label')}
              </Typography>
            </div>
          </div>
          <div className='col-span-1'>
            <div className='py-3'>
              <Typography type='14-M'>
                {internshipsTranslations('table.columns.isSelected.label')}
              </Typography>
            </div>
          </div>
        </div>
        {internshipState.map((internship) => {
          const internshipCheckboxState: CheckboxState = getCheckboxState(internship.locations);
          const isExpended = collapsedState[internship.id];
          const isActiveBg =
            internshipCheckboxState === CheckboxState.Intermediate ||
            internshipCheckboxState === CheckboxState.Checked;

          return (
            <div key={internship.id}>
              <div
                className={twMerge(
                  'flex items-center py-2 border-l-2 border-l-transparent gap-4',
                  isActiveBg && ' bg-hover  border-l-blue border-solid'
                )}
              >
                <div className='w-full items-center grid  grid-cols-12 '>
                  <div className='col-span-1 flex items-center justify-center'>
                    <button onClick={() => toggleCollapse(internship.id)}>
                      <motion.div
                        key={'arrow-' + internship.id}
                        animate={{ rotate: isExpended ? 180 : 0 }}
                      >
                        <ArrowIcon />
                      </motion.div>
                    </button>
                  </div>

                  <div className='col-span-10'>
                    <Typography type='H3'>{internship.name}</Typography>
                  </div>
                  <div className='col-span-1'>
                    <CheckboxUI
                      checked={internshipCheckboxState === CheckboxState.Checked}
                      intermediate={internshipCheckboxState === CheckboxState.Intermediate}
                      onChange={(value) => handleInternshipChange(internship.id, value)}
                    />
                  </div>
                </div>
              </div>

              <motion.div
                className={twMerge('flex flex-col w-full overflow-y-hidden')}
                initial={{ height: 0, opacity: 0 }}
                animate={isExpended ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
              >
                <ul>
                  {internship.locations.map((location) => (
                    <li
                      key={location.id}
                      className={twMerge(
                        'flex items-center py-2 border-l-2 border-transparent',
                        location.isSelected && 'bg-hover border-l-blue border-solid'
                      )}
                    >
                      <div className='grid  grid-cols-12 w-full items-center'>
                        <div className='col-span-1'></div>
                        <div className='col-span-10'>
                          <div className='pl-8'>
                            <Typography type='16-R'>{location.name}</Typography>
                          </div>
                        </div>
                        <div className='col-span-1'>
                          <CheckboxUI
                            checked={location.isSelected}
                            onChange={(value) => {
                              handleLocationChange(internship.id, location.id, value);
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </motion.div>
            </div>
          );
        })}
      </div>
    </AnimatePresence>
  );
};

export default InternshipList;
