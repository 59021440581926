import React, { createContext, ReactNode, useContext } from 'react';
import { AnyObject, ISchema, ObjectSchema, Reference } from 'yup';

interface SchemaContextType {
  schema: ObjectSchema<any>;
}

const SchemaContext = createContext<SchemaContextType | undefined>(undefined);

export const SchemaProvider: React.FC<{ schema: ObjectSchema<any>; children: ReactNode }> = ({
  schema,
  children,
}) => {
  return <SchemaContext.Provider value={{ schema }}>{children}</SchemaContext.Provider>;
};

export const useSchemaContext = (fieldName?: string) => {
  const context = useContext(SchemaContext);

  if (!context) {
    return {};
  }

  if (!fieldName) {
    return { schema: context.schema, isRequiredField: false };
  }

  const currenSchema: Reference<unknown> | ISchema<any, AnyObject, any, any> =
    context.schema?.fields[fieldName];

  //@ts-expect-error: yup returns wrong schema description
  const isRequiredField = !currenSchema?.spec.optional;

  return { schema: context.schema, isRequiredField: isRequiredField };
};
