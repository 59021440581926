import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { addressesActions } from '@/core/redux/slices/functions/personGeneralData/addresses/addressesSlice';
import { personGeneralDataModalsActions } from '@/core/redux/slices/modalsSlice/functions/personGeneralData/personGeneralDataModalsSlice';
import { personGeneralDataModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/personGeneralData/selector';

const ModalContent: React.FC = () => {
  const { t: deleteAddressModal } = useTranslation('deleteAddressModal');
  const { payload } = useAppSelector(personGeneralDataModalsSelectors.deleteAddressModal);

  const personID = payload?.personID;
  const addressID = payload?.addressID;

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(personGeneralDataModalsActions.closeDeleteAddressModal());
  };

  const deleteAddress = (id: number | undefined) => {
    if (!personID || id === undefined) {
      return;
    }

    dispatch(
      addressesActions.deleteAddress({
        personID: personID,
        addressID: id,
      })
    );

    handleExit();
  };

  return (
    <Modal onExit={handleExit} className='w-[600px]'>
      <div>
        <Typography>{deleteAddressModal('title.label')}</Typography>
        <div className='flex gap-4 justify-center mt-5'>
          <Button onClick={() => deleteAddress(addressID)}>
            {deleteAddressModal('buttons.confirm.label')}
          </Button>
          <Button onClick={handleExit}>{deleteAddressModal('buttons.close.label')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export const DeleteAddressModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    personGeneralDataModalsSelectors.deleteAddressModal
  );

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
