export function isEnumValue<T extends string | number>(
  enumType: Record<string | number, T>,
  value?: string | number | null
): value is T {
  if (value === null || value === undefined) {
    return false;
  }

  return Object.values(enumType).includes(value as T);
}

export function getNumberedEnumValues(enumType: any): number[] {
  return Object.keys(enumType)
    .map((key) => enumType[key])
    .filter((value) => typeof value === 'number');
}
