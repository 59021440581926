import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { AsterikIcon } from '@/components/Icons/AsterikIcon';
import { EmailPlusIcon } from '@/components/Icons/EmailPlusIcon';
import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';
import { IPasswordRequestFormValues } from '@/components/views/auth/PasswordRequestPage/helpers/passwordRequestFormValues';
import { PasswordRequestResolvers } from '@/components/views/auth/PasswordRequestPage/helpers/passwordRequestsResolvers';
import { useRequestRestoreMutation } from '@/services/graphql/system/graphql';
import { colorTypes } from '@/styles/types';

export const PasswordRequestPage = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui' });

  const [sent, setSent] = useState<boolean>(false);
  const [block, setBlock] = useState<boolean>(false);

  const [sendRequestData, response] = useRequestRestoreMutation({
    errorPolicy: 'none',
    onError: () => {},
    onCompleted: async () => {
      setSent(true);
      setBlock(true);
    },
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (sent) {
      timer = setTimeout(() => {
        setBlock(false);
      }, 7000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [sent]);

  const form = useForm<IPasswordRequestFormValues>({
    resolver: yupResolver(PasswordRequestResolvers),
    defaultValues: {
      email: '',
    },
  });

  const handleAuth = async (data: IPasswordRequestFormValues) => {
    await sendRequestData({
      variables: {
        ...data,
        link: `${window.location.origin}/auth/password_reset`,
      },
    });
  };

  return (
    <div className='h-screen flex justify-center items-center flex-col gap-5'>
      <div className='flex flex-col text-center items-center gap-3'>
        <Typography color='Primary' type='H1'>
          {t('title.recoverPassword')}
        </Typography>
        <div style={{ maxWidth: 438 }}>
          {sent || (
            <Typography className='my-3 pb-5' color='Secondary' type='16-M'>
              {t('placeholder.passwordRecover.enterEmail')}
            </Typography>
          )}
        </div>
        {sent && (
          <Typography
            type='14-M'
            className={`w-1/2 flex flex-col items-center border border-${colorTypes.Disabled} rounded shadow p-3 my-3 gap-3`}
          >
            <EmailPlusIcon />
            {t('placeholder.passwordRecover.enterEmail')}
          </Typography>
        )}
      </div>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleAuth)} className='flex w-1/2 flex-col mb-5'>
          <Input control={form.control.register('email')} containerClassName='my-2' errorMessage='Email' />
          {fp.get('error.message', response)}
          <div className='text-center my-3 flex justify-center'>
            <Button
              buttonStyle={'WithIcon'}
              disabled={response.loading || block}
              type={'submit'}
              className={'gap-3'}
            >
              {t('button.receiveEmail')}
              <AsterikIcon />
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
