import React from 'react';

import { AppointmentDetails } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/AppointmentDetails';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentDetailsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/selectors';
import { activityPlaningAppointmentDetailsModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';

export const AppointmentDetailsModal: React.FC = () => {
  const { isRender } = useAppSelector(
    activityPlaningAppointmentDetailsModalsSelectors.appointmentDetailsModal
  );

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(activityPlaningAppointmentDetailsModalsActions.closeAppointmentDetailsModal());
  };

  return isRender ? (
    <Sidebar onExit={handleExit} position={'right'} className={'max-w-[50vw]'}>
      <AppointmentDetails />
    </Sidebar>
  ) : null;
};
