import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { ActivityPlanningWizardNavigation } from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardContainer/ActivityPlanningWizardNavigation';
import { useActivityPlaningWizardSelector } from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardContext/ActivityPlanningContext';
import { MeasuresAppointmentsList } from '@/components/functions/ActivityPlanning/WizardFunction/MeasuresAppointmentsList/MeasuresAppointmentsList';
import { MeasuresList } from '@/components/functions/ActivityPlanning/WizardFunction/MeasuresList/MeasuresList';
import { ParticipantsList } from '@/components/functions/ActivityPlanning/WizardFunction/ParticipantsList/ParticipantsList';
import { colorTypes } from '@/styles/types';

export interface IActivityPlanningWizardView {
  title: string;
  view: React.ReactNode;
}

export const ActivityPlanningWizardContainer: React.FC = () => {
  const { t: activityPlanningTranslations } = useTranslation('activityPlanning');

  const [step, setStep] = useActivityPlaningWizardSelector((store) => store.wizardStep);

  const views: IActivityPlanningWizardView[] = useMemo(
    () => [
      {
        title: activityPlanningTranslations('wizard.header.measures.title.label'),
        view: <MeasuresList />,
      },
      {
        title: activityPlanningTranslations('wizard.header.measureAppointments.title.label'),
        view: <MeasuresAppointmentsList />,
      },
      {
        title: activityPlanningTranslations('wizard.header.participants.title.label'),
        view: <ParticipantsList />,
      },
    ],
    [activityPlanningTranslations]
  );

  const handleNextStep = () => {
    setStep({ wizardStep: step >= views.length ? step : step + 1 });
  };

  const handlePrevStep = () => {
    setStep({ wizardStep: step === 0 ? step : step - 1 });
  };

  return (
    <div className={'flex flex-col gap-3 h-full'}>
      <div
        className={`flex flex-row justify-between items-center gap-3 border-b border-b-${colorTypes.Stroke} pb-1`}
      >
        <Button onClick={handlePrevStep} buttonVariant={'Secondary'} disabled={step === 0}>
          {activityPlanningTranslations('wizard.buttons.back.label')}
        </Button>
        <ActivityPlanningWizardNavigation views={views} />
        <Button
          onClick={handleNextStep}
          buttonVariant={'Secondary'}
          disabled={step === views.length - 1}
        >
          {activityPlanningTranslations('wizard.buttons.next.label')}
        </Button>
      </div>
      <div className={'h-full overflow-y-auto'}>{views[step].view}</div>
    </div>
  );
};
