import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.integrationPlan.participantExpectation;

const participantExpectation = createSelector([selector], (state) => state.participantExpectation);
const participantExpectationLock = createSelector(
  [selector],
  (state) => state.participantExpectationLock
);
const updateParticipantExpectationLock = createSelector(
  [selector],
  (state) => state.updateParticipantExpectationLock
);

export const participantExpectationSelectors = {
  participantExpectation,
  participantExpectationLock,
  updateParticipantExpectationLock,
};
