import { useEffect } from 'react';

import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  efficacyAssessmentActions,
  IAppointment,
} from '@/core/redux/slices/efficacyAssessment/efficacyAssessmentSlice';
import { efficacyAssessmentSelectors } from '@/core/redux/slices/efficacyAssessment/selectors';

interface IUseFecthedAppointments {
  personID: number | undefined;
  fromDate: string;
  toDate: string;
}

export function useFecthedAppointments({ personID, fromDate, toDate }: IUseFecthedAppointments) {
  const dispatch = useAppDispatch();
  const appointments = useAppSelector(efficacyAssessmentSelectors.appointments);

  useEffect(() => {

    if(!personID || !fromDate || !toDate) {
      return
    }

    dispatch(
      efficacyAssessmentActions.fetchAppointments({
        personID: personID,
        fromDate: fromDate,
        toDate: toDate,
      })
    );
  }, [dispatch, personID, fromDate, toDate]);

  return { appointments };
}
