import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ITableColumn, Table } from '@/components/Table/Table';
import { ISelfAssessmentPersonQuestionnaire } from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';
import { toClientDateInput, toClientDateTimeInput } from '@/core/utils/dateTimeUtil';

interface IQuestionnaireHistory {
  personQuestionnaires: ISelfAssessmentPersonQuestionnaire[];
  handleSelect: (selected: any) => void;
}

interface IQuestionnaireHistoryData extends ISelfAssessmentPersonQuestionnaire {
  id: string;
}

export const QuestionnaireHistory: React.FC<IQuestionnaireHistory> = ({
  personQuestionnaires,
  handleSelect,
}) => {
  const { t: selfAssessmentTranslations } = useTranslation('selfAssesment');

  const [data, setData] = useState<IQuestionnaireHistoryData[]>([]);

  const columns: ITableColumn[] = [
    { header: selfAssessmentTranslations('columns.name.label'), accessor: 'questionnaireName' },
    {
      header: selfAssessmentTranslations('columns.numAnswered.label'),
      accessor: 'numAnswered',
    },
    {
      header: selfAssessmentTranslations('columns.numQuestions.label'),
      accessor: 'numQuestions',
    },
    {
      header: selfAssessmentTranslations('columns.dateCreated.label'),
      accessor: 'createdDate',
      template: (date: string) => toClientDateTimeInput(date),
    },
    {
      header: selfAssessmentTranslations('columns.lastChange.label'),
      accessor: 'lastChange',
      template: (date: string) => (date ? toClientDateTimeInput(date) : '--'),
    },
    {
      header: selfAssessmentTranslations('columns.answeredByPerson.label'),
      accessor: 'answeredByPerson',
      template: (value: boolean) =>
        value
          ? selfAssessmentTranslations('fields.yes.label')
          : selfAssessmentTranslations('fields.no.label'),
    },
    { header: selfAssessmentTranslations('columns.status.label'), accessor: 'statusName' },
  ];

  useEffect(() => {
    if (!personQuestionnaires) {
      return;
    }
    const questionnairesWithId = personQuestionnaires.map<IQuestionnaireHistoryData>(
      (questionnaire) => ({
        ...questionnaire,
        id: questionnaire.m2sID,
      })
    );
    setData(questionnairesWithId);
  }, [personQuestionnaires]);

  return (
    <div className='flex-grow mt-8'>
      <Table
        data={data}
        columns={columns}
        singleSelect
        onSingleSelectChange={(selected) => handleSelect(selected)}
        className='max-h-[calc(100vh_-_436px)]'
      />
    </div>
  );
};
