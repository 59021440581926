import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const VocationalTrainingAreaTableHeader: React.FC = () => {
  const { t: vocationalTrainingAreaTranslations } = useTranslation('vocationalTrainingArea', {
    keyPrefix: 'table.header',
  });

  return (
    <div
      className={`grid grid-cols-12 items-center border-y border-${colorTypes.Stroke} px-[30px] py-3`}
    >
      <Typography color={'Secondary'} className={'col-span-7'}>
        {vocationalTrainingAreaTranslations('measure.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-2'}>
        {vocationalTrainingAreaTranslations('nextActivity.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-1'}>
        {vocationalTrainingAreaTranslations('type.label')}
      </Typography>
      <Typography color={'Secondary'} className={'col-span-2'}>
        {vocationalTrainingAreaTranslations('latestActivity.label')}
      </Typography>
    </div>
  );
};
