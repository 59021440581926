import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  IAdditionalPersonRequirementField,
  responsibleTableName,
} from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/AdditionalPersonRequirementModalContent';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';

interface IPersonRequirementResponsibleSelect extends IAdditionalPersonRequirementField {
  isOpened: boolean;
}

export const PersonRequirementResponsibleSelect: React.FC<IPersonRequirementResponsibleSelect> = ({
  form,
  isOpened,
}) => {
  const { t: additionalPersonRequirementsModalTranslations } = useTranslation(
    'additionalPersonRequirementModal'
  );

  const responsibleDropdown = useAppSelector(dropdownItemsSelectors.dropdownItems);

  let responsibleList: ISelectItem[] = [];

  if (responsibleDropdown && responsibleDropdown[responsibleTableName]) {
    responsibleList = responsibleDropdown[responsibleTableName].map((responsible) => ({
      id: String(responsible.id),
      component: responsible.value,
    }));
  }

  const { watch, setValue } = form;
  const selectedResponsibleID = watch('responsibleID');

  const handleResponsibleSelect = (id: string) => {
    setValue('responsibleID', Number(id), { shouldDirty: true });
  };

  return (
    <div className={'flex flex-col gap-2'}>
      <Typography>
        {additionalPersonRequirementsModalTranslations('fields.responsible.label')}
      </Typography>
      <Select
        isOpen={isOpened}
        values={responsibleList}
        initialValueID={String(selectedResponsibleID)}
        onChange={handleResponsibleSelect}
        isFlexible={true}
      />
    </div>
  );
};
