import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { useActivityPlaningWizardSelector } from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardContext/ActivityPlanningContext';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentParticipantsListSelectors } from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/selectors';
import { activityPlanningAppointmentParticipantsListActions } from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/slice';
import { activityPlaningParticipantsModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningParticipants/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const ParticipantsList: React.FC = () => {
  const { t: appointmentParticipantsListTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentParticipantsList',
  });
  const { t: appointmentParticipantsWizardTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'wizard.header.participants',
  });

  const [selectedParticipant, setSelectedParticipant] = useState<string | null>(null);

  const [selectedAppointmentID] = useActivityPlaningWizardSelector(
    (store) => store.selectedAppointmentID
  );
  const [selectedMeasureID] = useActivityPlaningWizardSelector((store) => store.selectedMeasureID);
  const [selectedMeasureName] = useActivityPlaningWizardSelector(
    (store) => store.selectedMeasureName
  );

  const appointmentParticipants = useAppSelector(
    activityPlaningAppointmentParticipantsListSelectors.appointmentParticipantsList
  );
  useActivityPlaningWizardSelector;
  const loadingStatus = useAppSelector(
    activityPlaningAppointmentParticipantsListSelectors.appointmentParticipantsListLock
  );

  const dispatch = useAppDispatch();

  const columns: ITableColumn[] = [
    { accessor: 'w', header: appointmentParticipantsListTranslations('list.tableHeader.w.label') },
    {
      accessor: 'name',
      header: appointmentParticipantsListTranslations('list.tableHeader.participant.label'),
    },
    {
      accessor: 'department',
      header: appointmentParticipantsListTranslations('list.tableHeader.department.label'),
    },
  ];

  const handleOpenAddParticipantsModal = () => {
    if (!selectedMeasureID || !selectedAppointmentID) {
      return;
    }

    dispatch(
      activityPlaningParticipantsModalsActions.openAddParticipantsModal({
        measureID: selectedMeasureID,
        appointmentID: selectedAppointmentID,
        measureName: selectedMeasureName,
        placesCount: appointmentParticipants?.placesNum,
      })
    );
  };

  const handleParticipantChange = (id: string) => {
    setSelectedParticipant(id);
  };

  const handleDeleteParticipant = () => {
    if (!selectedMeasureID || !selectedAppointmentID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentParticipantsListActions.deleteParticipant({
        personID: Number(selectedParticipant),
        measureID: selectedMeasureID,
        appointmentID: selectedAppointmentID,
      })
    );

    setSelectedParticipant(null);
  };

  useEffect(() => {
    if (!selectedAppointmentID || !selectedMeasureID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentParticipantsListActions.fetchAppointmentParticipantsList({
        appointmentID: selectedAppointmentID,
        measureID: selectedMeasureID,
      })
    );
  }, [selectedAppointmentID]);

  useEffect(() => {
    return () => {
      dispatch(
        activityPlanningAppointmentParticipantsListActions.setAppointmentParticipantsList(null)
      );
    };
  }, []);

  return (
    <div className={'flex flex-col gap-2 p-2 h-full justify-between'}>
      <div className={'max-h-[calc(100vh_-_440px)] flex flex-col gap-2'}>
        <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
          <Table
            rowIDAccessor={'rowID'}
            columns={columns}
            data={
              appointmentParticipants?.list && selectedAppointmentID
                ? appointmentParticipants?.list
                : []
            }
            singleSelect={true}
            onSingleSelectChange={handleParticipantChange}
          />
        </Skeleton>
      </div>
      <div className={'flex flex-row gap-3 justify-start'}>
        {appointmentParticipants?.hasPlaces && (
          <Button
            onClick={handleOpenAddParticipantsModal}
            disabled={!selectedAppointmentID || !selectedMeasureID}
          >
            {appointmentParticipantsWizardTranslations(
              'footer.buttons.openAddParticipantsListModal.label'
            )}
          </Button>
        )}
        {selectedParticipant && (
          <Button
            onClick={handleDeleteParticipant}
            disabled={!selectedAppointmentID || !selectedMeasureID || !selectedParticipant}
          >
            {appointmentParticipantsWizardTranslations('footer.buttons.removeParticipant.label')}
          </Button>
        )}
      </div>
    </div>
  );
};
