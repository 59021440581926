import React from 'react';
import { FormProvider } from 'react-hook-form';

import { AdministrationLocationManagementDepartmentList } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementDepartmentList/AdministrationLocationManagementDepartmentList';
import { AdministrationLocationManagementFooter } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementFooter';
import { AdministrationLocationManagementLocationList } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagementLocationList/AdministrationLocationManagementLocationList';
import { useAdministrationLocationManagementForm } from '@/components/administartionFunctions/locationManagement/hooks/useAdministrationLocationManagementForm';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionFooter } from '@/components/wrapper/FunctionWrapper/FunctionFooter/FunctionFooter';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';

export const AdministrationLocationManagement: React.FC = () => {
  const { form } = useAdministrationLocationManagementForm();

  return (
    <FunctionWrapper>
      <FormProvider {...form}>
        <FunctionContent withFooter={true}>
          <div className={'grid grid-cols-2 h-full overflow-hidden'}>
            <AdministrationLocationManagementLocationList />
            <AdministrationLocationManagementDepartmentList />
          </div>
        </FunctionContent>
        <FunctionFooter>
          <AdministrationLocationManagementFooter />
        </FunctionFooter>
      </FormProvider>
    </FunctionWrapper>
  );
};
