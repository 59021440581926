import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';

import { Button } from '@/components/Button/Button';
import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';
import { useDialog } from '@/core/hooks/useDialog';
import { useAppDispatch } from '@/core/redux/hooks';
import {
  administrationLocationManagementActions,
  IAdministrationDepartment,
} from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { yup } from '@/core/utils/commonUtils';

interface IAdministrationDepartmentRenameForm {
  name: string;
}

export function useRenameAdministrationDepartmentDialog(department: IAdministrationDepartment) {
  const { t: administrationLocationManagementTranslations } = useTranslation(
    'administrationLocationManagement',
    { keyPrefix: 'departments.dialogs.renameDepartment' }
  );

  const dispatch = useAppDispatch();

  const validator: ObjectSchema<IAdministrationDepartmentRenameForm> = yup.object().shape({
    name: yup
      .string()
      .required(
        administrationLocationManagementTranslations('fields.department.errors.required.label')
      ),
  });

  const form = useForm<IAdministrationDepartmentRenameForm>({
    defaultValues: {
      name: department.name,
    },
    resolver: yupResolver(validator),
  });

  const handleRename = () => {
    form.trigger().then((isValid) => {
      if (!isValid) {
        return;
      }

      const currentName = form.watch('name');

      dispatch(
        administrationLocationManagementActions.updateDepartmentName({
          departmentID: department.id,
          name: currentName,
        })
      );

      form.reset({
        name: currentName,
      });
      closeDialog();
    });
  };

  const handleClose = () => {
    form.reset();
    closeDialog();
  };

  const { renderDialogContainer, openDialog, closeDialog } = useDialog({
    title: (
      <Typography type={'H2'}>
        {administrationLocationManagementTranslations('title.label')}
      </Typography>
    ),
    description: (
      <FormProvider {...form}>
        <Input
          control={form.control.register('name')}
          label={administrationLocationManagementTranslations('fields.department.label')}
        />
      </FormProvider>
    ),
    className: 'w-[500px] flex flex-col gap-4',
    confirmButton: (
      <Button onClick={handleRename} disabled={!form.formState.isDirty} className={'w-full'}>
        {administrationLocationManagementTranslations('buttons.confirm.label')}
      </Button>
    ),
    cancelButton: (
      <Button buttonVariant={'Secondary'} onClick={handleClose} className={'w-full'}>
        {administrationLocationManagementTranslations('buttons.cancel.label')}
      </Button>
    ),
    buttonsContainerClassName: 'flex-row-reverse w-full mt-4',
  });

  return { renderDialogContainer, openDialog };
}
