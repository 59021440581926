import React from 'react';

import { IIcon } from '@/types/icon';

export const EditTextIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='17.500000'
    height='17.500000'
    viewBox='0 0 17.5 17.5'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <defs />
    <path
      id='Vector'
      d='M13.5938 0.921875C13.8711 0.808594 14.168 0.75 14.4688 0.75C14.7656 0.75 15.0625 0.808594 15.3398 0.921875C15.6172 1.03906 15.8711 1.20703 16.082 1.41797C16.293 1.62891 16.4609 1.88281 16.5781 2.16016C16.6914 2.4375 16.75 2.73438 16.75 3.03125C16.75 3.33203 16.6914 3.62891 16.5781 3.90625C16.4609 4.18359 16.293 4.43359 16.082 4.64453L5.1875 15.5391L0.75 16.75L1.96094 12.3125L12.8555 1.41797C13.0664 1.20703 13.3164 1.03906 13.5938 0.921875Z'
      stroke='currentColor'
      strokeOpacity='1.000000'
      strokeWidth='1.500000'
      strokeLinejoin='round'
    />
  </svg>
);
