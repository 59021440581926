import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const PredefinedVariantsListHeader: React.FC = () => {
  const { t: workingTimePredefinedVariantsModalTranslations } = useTranslation(
    'workingTimePredefinedVariantsModal',
    { keyPrefix: 'predefinedVariantsList.header' }
  );

  return (
    <div className={'grid grid-cols-6 gap-3'}>
      <Typography type={'14-M'} className={'col-span-3'}>
        {workingTimePredefinedVariantsModalTranslations('name.label')}
      </Typography>
      <Typography type={'14-M'}>
        {workingTimePredefinedVariantsModalTranslations('withBreaks.label')}
      </Typography>
      <Typography type={'14-M'}>
        {workingTimePredefinedVariantsModalTranslations('breaks.label')}
      </Typography>
      <Typography type={'14-M'}>
        {workingTimePredefinedVariantsModalTranslations('withoutBreaks.label')}
      </Typography>
    </div>
  );
};
