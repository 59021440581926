import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Diagnostics } from '@/components/functions/MedicalInformation/Diagnostics/Diagnostics';
import { DisabilityCharacteristics } from '@/components/functions/MedicalInformation/DisabilityCharacteristics/DisabilityCharacteristics';
import { MedicalInformationTabs } from '@/components/functions/MedicalInformation/MedicalInformationTabs';
import { Medication } from '@/components/functions/MedicalInformation/Medication/Medication';
import { SpecialFeatures } from '@/components/functions/MedicalInformation/SpecialFeatures/SpecialFeatures';
import { Typography } from '@/components/Typography/Typography';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { MedicalInformationTypes } from '@/core/enums/functions/medicalInformation/medicalInformationTypesEnum';

export const MedicalInformation: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<MedicalInformationTypes>(
    MedicalInformationTypes.MedicalDiagnosis
  );
  const { t: medicalInformationTranslations } = useTranslation('medicalInformation');

  const medicalInformationViews: Record<MedicalInformationTypes, React.ReactNode> = {
    [MedicalInformationTypes.MedicalDiagnosis]: <Diagnostics />,
    [MedicalInformationTypes.DisabilityCharacteristics]: <DisabilityCharacteristics />,
    [MedicalInformationTypes.Medication]: <Medication />,
    [MedicalInformationTypes.SpecialFeatures]: <SpecialFeatures />,
  };

  return (
    <FunctionWrapper>
      <FunctionContent className={'flex flex-col gap-3 p-5'}>
        <Typography type={'H3'}>{medicalInformationTranslations('title.label')}</Typography>
        <MedicalInformationTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <div className={'flex-1 overflow-hidden'}>{medicalInformationViews[selectedTab]}</div>
      </FunctionContent>
    </FunctionWrapper>
  );
};
