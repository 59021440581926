import { TFunction } from 'i18next';
import { ObjectSchema } from 'yup';

import { IDisabilityCharacteristicsForm } from '@/components/functions/MedicalInformation/DisabilityCharacteristics/helpers/useDisabilityCharacteristicsForm';
import { yup } from '@/core/utils/commonUtils';

export function disabilityCharacteristicsValidatorBuilder(
  translations: TFunction<'disabilityCharacteristics', undefined>
): ObjectSchema<IDisabilityCharacteristicsForm> {
  return yup
    .object()
    .shape({
      characteristics: yup.object().required(),
      isDisabledPass: yup.boolean().required(),
      identityCardAuthoritiesID: yup.number().nullable(),
      validDate: yup.string().nullable(),
      referenceNumber: yup.string().nullable(),
      isUnlimited: yup.boolean().required(),
    })
    .test('valid-or-unlimited', translations('errors.requiredPeriod.label'), function (value) {
      if (value.isDisabledPass) {
        return !!value.validDate || value.isUnlimited;
      }
      return true;
    });
}
