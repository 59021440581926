import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.diagnostics.codesICD;

//ICD codes selectors
const codesICD = createSelector([selector], (state) => state.codesICD);
const codesICDData = createSelector([selector], (state) => state.codesICD.data);
const codesICDStatus = createSelector([selector], (state) => state.codesICD.status);
const codesICDTotalCount = createSelector([selector], (state) => state.codesICD.totalCount);

//ICD codes selectors
const availableCodesICD = createSelector([selector], (state) => state.availableCodesICD);
const availableCodesICDData = createSelector([selector], (state) => state.availableCodesICD.data);
const availableCodesICDStatus = createSelector(
  [selector],
  (state) => state.availableCodesICD.status
);
const availableCodesICDTotalCount = createSelector(
  [selector],
  (state) => state.availableCodesICD.totalCount
);

export const codesICDSelectors = {
  codesICD,
  codesICDData,
  codesICDStatus,
  codesICDTotalCount,

  availableCodesICD,
  availableCodesICDData,
  availableCodesICDStatus,
  availableCodesICDTotalCount,
};
