import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@/components/Button/Button';
import { IMedicationFormFields, MedicationForm } from '@/components/forms/Medication/Medication';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { SchemaProvider } from '@/components/SchemaContext/SchemaContext';
import { useDateFormSwitch } from '@/core/hooks/useDateFormSwitch';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  IDosage,
  IMedicationData,
  medicationActions,
} from '@/core/redux/slices/functions/medication/medicationSlice';
import { medicationSelectors } from '@/core/redux/slices/functions/medication/selectors';
import { medicationModalsActions } from '@/core/redux/slices/modalsSlice/functions/medication/medicationModalsSlice';
import { medicationModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/medication/selector';
import { fileToBase64 } from '@/core/utils/fileUtils';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import { MedicationFormResolver } from './helpers/resolver';

const prepareDosages = (dosages: IDosage[], data: IMedicationFormFields) => {
  return dosages.map((dosageItem, index) => ({
    id: dosageItem.id,
    dosage: data['dosages'][`${index}`]['dosage'] ?? '',
    inWorkshop: data['dosages'][`${index}`]['inWorkshop'] ?? false,
  }));
};

const prepareMedicationData = async (
  data: IMedicationFormFields,
  dosages: IDosage[]
): Promise<IMedicationData> => {
  if (!data.prescriptionFile.files) {
    return {
      ...data,
      dosages: prepareDosages(dosages, data),
      prescriptionFile: null,
    };
  }

  return {
    ...data,
    dosages: prepareDosages(dosages, data),
    prescriptionFile: {
      fileName: data.prescriptionFile.fileName ?? 'not-provided',
      fileContent: await fileToBase64(data.prescriptionFile.files[0]),
    },
  };
};

const ModalContent: React.FC = () => {
  const form = useForm<IMedicationFormFields>({
    resolver: yupResolver(MedicationFormResolver),
    defaultValues: {
      name: '',
      isNotDispensed: true,
    },
  });
  const { t: displayMedicationModalTranslations } = useTranslation('displayMedicationModal');

  const { payload } = useAppSelector(medicationModalsSelectors.displayMedicationModal);
  const medicationDetails = useAppSelector(medicationSelectors.medicationDetails);
  const medicationDetailsLock = useAppSelector(medicationSelectors.medicationDetailsLock);
  const dosages = medicationDetails?.dosages;

  const createMedicationLock = useAppSelector(medicationSelectors.createMedicationLock);
  const updateMedicationLock = useAppSelector(medicationSelectors.updateMedicationLock);

  const isLoading =
    createMedicationLock === LoadingStatus.LOADING ||
    updateMedicationLock === LoadingStatus.LOADING;
  const isLoaded =
    createMedicationLock === LoadingStatus.LOADED || updateMedicationLock === LoadingStatus.LOADED;

  const personID = payload?.personID;
  const medicationID = payload?.medicationID;

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(medicationModalsActions.closeDisplayMedicationModal());
  };

  const onSubmit = (data: IMedicationFormFields) => {
    if (!personID || medicationID === undefined || !dosages || isLoading) {
      return;
    }

    prepareMedicationData(data, dosages).then((medicationData) => {
      medicationID !== 0
        ? dispatch(
            medicationActions.updateMedication({
              personID: personID,
              medicationID: medicationID,
              medicationData: medicationData,
            })
          )
        : dispatch(
            medicationActions.createMedication({
              personID: personID,
              medicationData: medicationData,
            })
          );
    });
  };

  useEffect(() => {
    if (!personID || medicationID === undefined) {
      return;
    }

    dispatch(
      medicationActions.fetchMedicationDetails({
        personID: personID,
        medicationID: medicationID,
      })
    );
  }, [personID, medicationID]);

  useEffect(() => {
    if (!medicationDetails || medicationDetailsLock !== LoadingStatus.LOADED) {
      return;
    }

    form.reset({
      ...medicationDetails,
      name: medicationDetails.name ?? '',
      orderedFromTimestamp: medicationDetails.orderedFromTimestamp ?? '',
      orderedBy: medicationDetails.orderedBy ?? '',
      prescriptionFile: {
        fileName: medicationDetails.prescriptionFile?.fileName,
      },
      isNotDispensed: Boolean(medicationDetails.isNotDispensed),
      dosageFormId: medicationDetails.dosageFormID,
    });
  }, [medicationDetails, medicationDetailsLock]);

  useEffect(() => {
    return () => {
      dispatch(medicationActions.setMedicationDetails(null));
      dispatch(medicationActions.setMedicationDetailsLock(LoadingStatus.NEVER));
      dispatch(medicationActions.setCreateMedicationLock(LoadingStatus.NEVER));
      dispatch(medicationActions.setUpdateMedicationLock(LoadingStatus.NEVER));
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      handleExit();
    }
  }, [isLoaded]);

  useDateFormSwitch(form, 'orderedFromTimestamp', 'orderedToTimestamp');

  const renderFooter = (
    <div className='flex gap-4'>
      <Button
        type='submit'
        onClick={form.handleSubmit(onSubmit)}
        buttonVariant={form.formState.isValid ? 'Default' : 'Disabled'}
      >
        {displayMedicationModalTranslations('buttons.submit.label')}
      </Button>
      <Button type='button' buttonVariant='Secondary' onClick={handleExit}>
        {displayMedicationModalTranslations('buttons.close.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar onExit={handleExit} className='w-[800px]' footer={renderFooter} position={'right'}>
      <SchemaProvider schema={MedicationFormResolver}>
        <FormProvider {...form}>
          <div className={'relative p-7'}>
            <MedicationForm
              onSubmit={onSubmit}
              dosages={dosages}
              className={isLoading ? 'opacity-50' : ''}
            />
            {isLoading && (
              <div className={'absolute inset-0 flex items-center justify-center'}>
                <SpinnerIcon className={`text-${colorTypes.Blue} w-fit`} />
              </div>
            )}
          </div>
        </FormProvider>
      </SchemaProvider>
    </Sidebar>
  );
};

export const DisplayMedicationModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(medicationModalsSelectors.displayMedicationModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
