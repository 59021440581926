import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { useAppDispatch } from '@/core/redux/hooks';
import { smartGoalsActions } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { colorTypes } from '@/styles/types';

import { EvaluateGoals } from './EvaluateGoals/EvaluateGoals';
import { MeasuresCarrying } from './MeasuresCarrying/MeasuresCarrying';
import { ParticipationPlan } from './ParticipationPlan/ParticipationPlan';
import { PerformanceProfileView } from './PerformanceProfile/PerformanceProfile';
import { SmartGoals } from './SmartGoals/SmartGoals';

enum FundingCycleViews {
  ParticipationPlan = 'participationPlan',
  SmartGoals = 'smartGoals',
  PerformanceProfile = 'performanceProfile',
  MeasuresCarrying = 'measuresCarrying',
  EvaluateGoals = 'evaluateGoals',
}
interface View {
  type: FundingCycleViews;
  view: JSX.Element;
  title: string;
}

export const FundingCycle: React.FC = () => {
  const { t: fundingCycleTranslations } = useTranslation('fundingCycle');
  const dispatch = useAppDispatch()

  const [selectedView, setSelectedView] = useState<FundingCycleViews>(
    FundingCycleViews.ParticipationPlan
  );

  const fundingCycleTabs: Record<FundingCycleViews, View> = {
    [FundingCycleViews.ParticipationPlan]: {
      type: FundingCycleViews.ParticipationPlan,
      view: <ParticipationPlan />,
      title: fundingCycleTranslations('tabs.participationPlan.label'),
    },
    [FundingCycleViews.PerformanceProfile]: {
      type: FundingCycleViews.PerformanceProfile,
      view: <PerformanceProfileView />,
      title: fundingCycleTranslations('tabs.performanceProfile.label'),
    },
    [FundingCycleViews.SmartGoals]: {
      type: FundingCycleViews.SmartGoals,
      view: <SmartGoals />,
      title: fundingCycleTranslations('tabs.smartGoals.label'),
    },
    [FundingCycleViews.MeasuresCarrying]: {
      type: FundingCycleViews.MeasuresCarrying,
      view: <MeasuresCarrying />,
      title: fundingCycleTranslations('tabs.measuresCarrying.label'),
    },
    [FundingCycleViews.EvaluateGoals]: {
      type: FundingCycleViews.EvaluateGoals,
      view: <EvaluateGoals />,
      title: fundingCycleTranslations('tabs.agreementEvaluating.label'),
    },
  };

  const handleTabClick = (view: FundingCycleViews) => {
    setSelectedView(view);
  };

  useEffect(() => {
    dispatch(smartGoalsActions.setSelectedSmartGoal(undefined));
  }, []);

  return (
    <FunctionWrapper className='h-full flex flex-col'>
      <FunctionContent className='flex flex-col flex-1'>
        <div className={`flex flex-row gap-3 border-b border-b-${colorTypes.Stroke} px-7`}>
          {Object.values(fundingCycleTabs).map((view) => (
            <Button
              onClick={() => handleTabClick(view.type)}
              buttonVariant={selectedView === view.type ? 'Default' : 'Secondary'}
              key={view.type}
              className='rounded-b-none px-4 pt-2 pb-[7px]'
            >
              {view.title}
            </Button>
          ))}
        </div>
        <div className='flex-1 overflow-y-auto'>{fundingCycleTabs[selectedView].view}</div>
      </FunctionContent>
    </FunctionWrapper>
  );
};
