import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IModal {
  isRender: boolean;
}

export interface IVocationalTrainingAreaDocumentsModalFetchPayload {
  measureID: number;
}

export interface IVocationalTrainingAreaDocumentDownloadPayload {
  documentID: number;
}

export interface IVocationalTrainingAreaDocumentDownloadLockPayload {
  documentID: number;
  status: LoadingStatus;
}

export interface IVocationalTrainingAreaDocument {
  id: number;
  name: string;
}

export interface IVocationalTrainingAreaDocumentsModal extends IModal {
  payload: IVocationalTrainingAreaDocumentsModalFetchPayload | null;
  documentDownloadPayload: IVocationalTrainingAreaDocumentDownloadPayload | null;
  documentDownloadLock: Record<number, LoadingStatus>;
  vocationalTrainingAreaDocuments: IVocationalTrainingAreaDocument[] | null;
  vocationalTrainingAreaDocumentsLock: LoadingStatus;
}

export interface IAppointment {
  id: number;
  type: string;
  isParticipated: boolean;
  isUnderDirection: boolean;
  isAlmostIndependent: boolean;
  isIndependent: boolean;
  explanation?: string | null;
  text?: string | null;
  note?: string | null;
  appointmentDate?: string | null;
}

export interface IMeasure {
  id: number;
  type: string;
  isParticipated?: boolean;
  isUnderDirection?: boolean;
  isAlmostIndependent?: boolean;
  isIndependent?: boolean;
  text?: string | null;
  explanation?: string | null;
  note?: string | null;
}

export interface IMeasuresGroup {
  id: number;
  items: IMeasure[];
  appointments: IAppointment[];
  name?: string | null;
}

export interface IRootMeasureGroup {
  id: number;
  groups: IMeasuresGroup[];
  name?: string | null;
  surname?: string | null;
}

export interface ICloseMeasureItem {
  id: number;
  level: number;
  isParticipated: boolean;
  isUnderDirection: boolean;
  isAlmostIndependent: boolean;
  isIndependent: boolean;
  note?: string | null;
}

export interface ICloseMeasurePayload {
  measureItems: ICloseMeasureItem[];
}

export interface ICompleteMeasureModal {
  isRender: boolean;
  payload: number[] | null;
  measureDetails: IRootMeasureGroup[] | null;
  measureDetailLock: LoadingStatus;
  closeMeasurePayload: ICloseMeasurePayload | null;
  actionTrigger: boolean;
}

export interface IVocationalTrainingAreaModalState {
  completeMeasureModal: ICompleteMeasureModal;
  vocationalTrainingAreaDocumentsModal: IVocationalTrainingAreaDocumentsModal;
}

const initialState: IVocationalTrainingAreaModalState = {
  vocationalTrainingAreaDocumentsModal: {
    isRender: false,
    payload: null,
    documentDownloadPayload: null,
    documentDownloadLock: {},
    vocationalTrainingAreaDocuments: null,
    vocationalTrainingAreaDocumentsLock: LoadingStatus.NEVER,
  },
  completeMeasureModal: {
    actionTrigger: false,
    isRender: false,
    payload: null,
    measureDetails: null,
    measureDetailLock: LoadingStatus.NEVER,
    closeMeasurePayload: null,
  },
};

const vocationalTrainingAreaModalSlice = createSlice({
  name: 'vocationalTrainingAreaModals',
  initialState,
  reducers: {
    openVocationalTrainingAreaDocuments: (
      state,
      action: PayloadAction<IVocationalTrainingAreaDocumentsModalFetchPayload>
    ) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        ...state.vocationalTrainingAreaDocumentsModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeVocationalTrainingAreaDocuments: (state) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        isRender: false,
        payload: null,
        documentDownloadPayload: null,
        documentDownloadLock: {},
        vocationalTrainingAreaDocuments: null,
        vocationalTrainingAreaDocumentsLock: LoadingStatus.NEVER,
      },
    }),
    fetchVocationalTrainingAreaDocuments: (
      state,
      action: PayloadAction<IVocationalTrainingAreaDocumentsModalFetchPayload>
    ) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        ...state.vocationalTrainingAreaDocumentsModal,
        payload: action.payload,
      },
    }),
    setVocationalTrainingAreaDocuments: (
      state,
      action: PayloadAction<IVocationalTrainingAreaDocument[] | null>
    ) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        ...state.vocationalTrainingAreaDocumentsModal,
        vocationalTrainingAreaDocuments: action.payload,
      },
    }),
    setVocationalTrainingAreaDocumentsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        ...state.vocationalTrainingAreaDocumentsModal,
        vocationalTrainingAreaDocumentsLock: action.payload,
      },
    }),
    downloadVocationalTrainingAreaDocument: (
      state,
      action: PayloadAction<IVocationalTrainingAreaDocumentDownloadPayload>
    ) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        ...state.vocationalTrainingAreaDocumentsModal,
        documentDownloadPayload: action.payload,
      },
    }),
    setDownloadVocationalTrainingAreaDocumentLock: (
      state,
      action: PayloadAction<IVocationalTrainingAreaDocumentDownloadLockPayload>
    ) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        ...state.vocationalTrainingAreaDocumentsModal,
        documentDownloadLock: {
          ...state.vocationalTrainingAreaDocumentsModal.documentDownloadLock,
          [`${action.payload.documentID}`]: action.payload.status,
        },
      },
    }),

    //Complete Measure Modal
    openCompleteMeasureModal: (state, action: PayloadAction<number[]>) => ({
      ...state,
      completeMeasureModal: {
        ...state.completeMeasureModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeCompleteMeasureModal: (state) => ({
      ...state,
      completeMeasureModal: {
        isRender: false,
        payload: null,
        measureDetails: null,
        measureDetailLock: LoadingStatus.NEVER,
        closeMeasurePayload: null,
        actionTrigger: state.completeMeasureModal.actionTrigger,
      },
    }),
    fetchCompleteMeasureModalDetails: (state, action: PayloadAction<number[]>) => ({
      ...state,
      completeMeasureModal: {
        ...state.completeMeasureModal,
        payload: action.payload,
      },
    }),
    setCompleteMeasureModalDetails: (state, action: PayloadAction<IRootMeasureGroup[]>) => ({
      ...state,
      completeMeasureModal: {
        ...state.completeMeasureModal,
        measureDetails: action.payload,
      },
    }),
    setCompleteMeasureModalDetailsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      completeMeasureModal: {
        ...state.completeMeasureModal,
        measureDetailsLock: action.payload,
      },
    }),
    removeMeasureFromPerson: (state, action: PayloadAction<number[]>) => ({
      ...state,
      payload: action.payload,
    }),
    closeMeasure: (state, action: PayloadAction<ICloseMeasurePayload>) => ({
      ...state,
      closeMeasurePayload: action.payload,
    }),
    setActionTrigger: (state) => ({
      ...state,
      completeMeasureModal: {
        ...state.completeMeasureModal,
        actionTrigger: !state.completeMeasureModal.actionTrigger,
      },
    }),
  },
});

export const vocationalTrainingAreaModalReducer = vocationalTrainingAreaModalSlice.reducer;
export const vocationalTrainingAreaModalActions = vocationalTrainingAreaModalSlice.actions;
