import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';

interface IMobilityTransportTypesSelect {
  control: Control<any>;
  name: string;
  options: ISelectItem[];
  className?: string;
  defaultValue?: string;
}

export const MobilityTransportTypesSelect: React.FC<IMobilityTransportTypesSelect> = ({
  control,
  name,
  options,
  defaultValue,
  className,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          className={className}
          values={options}
          showSelected={true}
          initialValueID={defaultValue}
          onChange={(id) => field.onChange(parseInt(id))}
        />
      )}
    />
  );
};
