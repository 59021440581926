import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.integrationPlan.integrationPlan;

const integrationPlanID = createSelector([selector], (state) => state.integrationPlanID);
const integrationPlanVersionDate = createSelector(
  [selector],
  (state) => state.integrationPlanVersionDate
);
const integrationPlanLock = createSelector([selector], (state) => state.integrationPlanLock);

export const integrationPlanSelectors = {
  integrationPlanID,
  integrationPlanLock,
  integrationPlanVersionDate,
};
