import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.specialistGeneralData.generalData;

const generalData = createSelector([selector], (state) => state.generalData);
const generalDataLock = createSelector([selector], (state) => state.generalDataLock);
const updateGeneralDataLock = createSelector([selector], (state) => state.updateGeneralDataLock);

const administers = createSelector([selector], (state) => state.administers);
const administersLock = createSelector([selector], (state) => state.administersLock);

export const specialistGeneralDataSelectors = {
  generalData,
  generalDataLock,
  updateGeneralDataLock,

  administers,
  administersLock,
};
