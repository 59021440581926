import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import {
  FormRadioButtonGroup,
  IRadioButtonGroupChangeEvent,
} from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import { Typography } from '@/components/Typography/Typography';
import { competenceAnalysisRecommendationsTypes } from '@/core/enums/competenceAnalysisRecommendationsTypesEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { ICompetenceAnalysis } from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/competenceAnalysisSlice';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { colorTypes } from '@/styles/types';

export const Recommendations: React.FC = () => {
  const { t: competenceAnalysisTranslations } = useTranslation('competenceAnalysis');

  const isAllowChangeBinnDiffInEP = useAppSelector(parametersSelectors.isAllowChangeBinnDiffInEP);

  const dispatch = useAppDispatch();

  const { control } = useFormContext<ICompetenceAnalysis>();

  const options: IFormRadioButtonGroupItem[] = [
    {
      value: competenceAnalysisRecommendationsTypes.ActivityOriented,
      id: competenceAnalysisRecommendationsTypes.ActivityOriented,
      component: (
        <label htmlFor={competenceAnalysisRecommendationsTypes.ActivityOriented}>
          <Typography>
            {competenceAnalysisTranslations('recommendations.fields.isActivityOriented.label')}
          </Typography>
        </label>
      ),
    },
    {
      value: competenceAnalysisRecommendationsTypes.OccupationalFieldOriented,
      id: competenceAnalysisRecommendationsTypes.OccupationalFieldOriented,
      component: (
        <label htmlFor={competenceAnalysisRecommendationsTypes.OccupationalFieldOriented}>
          <Typography>
            {competenceAnalysisTranslations(
              'recommendations.fields.isOccupationalFieldOriented.label'
            )}
          </Typography>
        </label>
      ),
    },
    {
      value: competenceAnalysisRecommendationsTypes.WorkplaceOriented,
      id: competenceAnalysisRecommendationsTypes.WorkplaceOriented,
      component: (
        <label htmlFor={competenceAnalysisRecommendationsTypes.WorkplaceOriented}>
          <Typography>
            {competenceAnalysisTranslations('recommendations.fields.isWorkplaceOriented.label')}
          </Typography>
        </label>
      ),
    },
    {
      value: competenceAnalysisRecommendationsTypes.JobProfileOriented,
      id: competenceAnalysisRecommendationsTypes.JobProfileOriented,
      component: (
        <label htmlFor={competenceAnalysisRecommendationsTypes.JobProfileOriented}>
          <Typography>
            {competenceAnalysisTranslations('recommendations.fields.isJobProfileOriented.label')}
          </Typography>
        </label>
      ),
    },
  ];

  const handleChange = (_?: any, event?: IRadioButtonGroupChangeEvent) => {
    if (!isAllowChangeBinnDiffInEP) {
      event?.preventDefault();

      dispatch(
        notificationsActions.showNotification({
          notification: {
            description: competenceAnalysisTranslations(
              'notifications.notAllowed.description.label'
            ),
            title: competenceAnalysisTranslations('notifications.notAllowed.title.label'),
          },
        })
      );
    }
  };

  return (
    <Accordion
      title={
        <Typography type={'H3'}>
          {competenceAnalysisTranslations('recommendations.title.label')}
        </Typography>
      }
      className={'flex flex-col gap-3'}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <Typography className={'ms-[30px]'} type={'16-M'}>
        {competenceAnalysisTranslations('recommendations.subText.label')}
      </Typography>
      <FormRadioButtonGroup
        options={options}
        control={control.register('recommendations')}
        containerClassName={'grid grid-cols-[max-content,max-content] grid-rows-2 gap-3 ms-[30px]'}
        onChange={handleChange}
      />
    </Accordion>
  );
};
