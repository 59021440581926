import React from 'react';

import { VocationalTrainingAreaDocumentsModalContent } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/VocationalTrainingAreaDocumentsModal/VocationalTrainingAreaDocumentsModalContent/VocationalTrainingAreaDocumentsModalContent';
import { useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';

export const VocationalTrainingAreaDocumentsModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    vocationalTrainingAreaModalSelectors.vocationalTrainingAreaDocumentsModal
  );
  return isOpened ? <VocationalTrainingAreaDocumentsModalContent /> : null;
};
