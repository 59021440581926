import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.app;

const isAppInitializationInProgress = createSelector(
  [selector],
  (state) => state.isAppInitializationInProgress
);

export const appSelectors = { isAppInitializationInProgress };
