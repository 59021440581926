import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IAdministrationLocationManagementForm } from '@/components/administartionFunctions/locationManagement/hooks/useAdministrationLocationManagementForm';
import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { administrationLocationManagementSelectors } from '@/core/redux/slices/administrationFunctions/locationManagement/selectors';
import { administrationLocationManagementActions } from '@/core/redux/slices/administrationFunctions/locationManagement/slice';
import { isIntermediateStatus } from '@/core/utils/commonUtils';
import { colorTypes } from '@/styles/types';

export const AdministrationLocationManagementDepartmentHeader: React.FC = () => {
  const { t: administrationLocationManagementTranslations } = useTranslation(
    'administrationLocationManagement'
  );
  const unattachedDepartments = useAppSelector(
    administrationLocationManagementSelectors.unattachedDepartments
  );
  const unattachedDepartmentsLock = useAppSelector(
    administrationLocationManagementSelectors.unattachedDepartmentsLock
  );

  const departmentList = useAppSelector(administrationLocationManagementSelectors.departments);

  const dispatch = useAppDispatch();

  const { watch } = useFormContext<IAdministrationLocationManagementForm>();
  const departments = watch('departments');

  const isIntermediate = isIntermediateStatus(departments);
  const selectedLocation = watch('selectedLocation');

  useEffect(() => {
    dispatch(administrationLocationManagementActions.fetchUnattachedDepartments());
  }, []);

  return (
    <div
      className={`flex flex-row items-center gap-[10px] px-[30px] py-[10px] bg-${colorTypes.HoverLight} border-y border-y-${colorTypes.StrokeLight}`}
    >
      <Checkbox
        name={'departmentsSelectAll'}
        intermediate={isIntermediate}
        disabled={departmentList.length < 1 && !selectedLocation}
      />
      <Typography className={'flex-1'}>
        {administrationLocationManagementTranslations('departments.title.label')}
      </Typography>
      <Button
        buttonVariant={'TextBlue'}
        buttonStyle={'Text'}
        disabled={unattachedDepartments.length < 1}
      >
        {administrationLocationManagementTranslations(
          'departments.buttons.unattachedDepartments.label'
        )}
      </Button>
    </div>
  );
};
