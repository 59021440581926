import React from 'react';

import { Modal } from '@/components/Overlays/Modal/Modal';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { widgetModalsSelectors } from '@/core/redux/slices/modalsSlice/widgets/selector';
import { widgetsModalsActions } from '@/core/redux/slices/modalsSlice/widgets/widgetsModalsSlice';

export const PersonWidgetModal: React.FC = () => {
  const isOpened = useAppSelector(widgetModalsSelectors.personWidgetModal);

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(widgetsModalsActions.closePersonWidgetModal());
  };

  if (isOpened) {
    return (
      <Modal onExit={handleExit}>
        <div>
          <Typography type='16-R' color='Primary'>
            Ziel muss definiert werden
          </Typography>
        </div>
      </Modal>
    );
  }

  return null;
};
