import React, { useEffect } from 'react';

import {
  AdministrationChecklistProvider,
  IAdministrationChecklistContext,
} from '@/components/administartionFunctions/checklist/AdministrationChecklistContext/AdministrationChecklistContext';
import { AdministrationChecklistList } from '@/components/administartionFunctions/checklist/AdministrationChecklistList/AdministrationChecklistList';
import { AdministrationChecklistNavigation } from '@/components/administartionFunctions/checklist/AdministrationChecklistNavigation/AdministrationChecklistNavigation';
import { AdministrationCheckpointForm } from '@/components/administartionFunctions/checklist/AdministrationCheckpointList/AdministrationCheckpointForm';
import { AdministrationCheckpointList } from '@/components/administartionFunctions/checklist/AdministrationCheckpointList/AdministrationCheckpointList';
import { AdministrationChecklistSelectChecklistPlaceholder } from '@/components/administartionFunctions/checklist/placeholders/AdministrationChecklistSelectChecklistPlaceholder';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { useAppDispatch } from '@/core/redux/hooks';
import { administrationChecklistActions } from '@/core/redux/slices/administrationFunctions/checkList/slice';

export const AdministrationChecklist: React.FC = () => {
  const contextInitialState: IAdministrationChecklistContext = {
    selectedChecklistGroup: null,
    selectedChecklist: null,
    selectedCheckpoint: null,
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(administrationChecklistActions.fetchCorrespondingDocuments());
    dispatch(administrationChecklistActions.fetchDisabilityTypes());
  }, []);

  return (
    <AdministrationChecklistProvider state={contextInitialState}>
      <FunctionWrapper>
        <FunctionContent>
          <div className={'h-full flex flex-col p-5 pt-0 overflow-hidden'}>
            <AdministrationChecklistNavigation />
            <div className={'flex-1 grid grid-cols-2 gap-3 overflow-hidden'}>
              <AdministrationChecklistList />
              <div className={'flex flex-col gap-3 overflow-hidden'}>
                <AdministrationCheckpointList />
                <AdministrationChecklistSelectChecklistPlaceholder />
                <AdministrationCheckpointForm />
              </div>
            </div>
          </div>
        </FunctionContent>
      </FunctionWrapper>
    </AdministrationChecklistProvider>
  );
};
