import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes, textTypes } from '@/styles/types';

interface IVersionHistoryItem {
  timeStamp?: string | null;
  id: number;
  handleClick: (id: number, versionDate?: string | null) => void;
}

export const VersionHistoryItem: React.FC<IVersionHistoryItem> = ({
  timeStamp,
  id,
  handleClick,
}) => {
  const { t: developmentReportVersionHistoryModalTranslations } = useTranslation(
    'developmentReportVersionHistoryModal'
  );

  const formattedTimestamp =
    timeStamp === null
      ? developmentReportVersionHistoryModalTranslations('fields.inProgress.label')
      : moment(timeStamp).format('DD.MM.YYYY, HH:mm');

  return (
    <div
      className={`flex flex-row items-center w-full justify-between font-normal border-t border-$\{colorTypes.Stroke} px-[30px] py-3 odd:bg-${colorTypes.Fields}`}
    >
      <Typography type={'14-R'}>{formattedTimestamp}</Typography>
      <Button
        onClick={() => handleClick(id, timeStamp)}
        buttonStyle={'Icon'}
        className={`${textTypes['14-R']} bg-transparent text-${colorTypes.Blue} py-0 
      hover:bg-transparent hover:text-${colorTypes.BlueHover} 
      focus:bg-transparent 
      active:bg-transparent`}
      >
        {developmentReportVersionHistoryModalTranslations('buttons.open.label')}
      </Button>
    </div>
  );
};
