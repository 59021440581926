import React from 'react';

import { CompetenceAnalysisImportModalContent } from '@/components/Modals/Modals/functions/integrationPlanModals/CompetenceAnalysisImportModal/CompetenceAnalysisImportModalContent/CompetenceAnalysisImportModalContent';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';

export const CompetenceAnalysisImportModal: React.FC = () => {
  const { isRender: isOpened, payload } = useAppSelector(
    integrationPlanModalsSelectors.competenceAnalysisImportModal
  );

  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(integrationPlanModalsActions.closeCompetenceAnalysisImportModal());
  };

  if (isOpened) {
    return (
      <Modal onExit={handleCloseModal}>
        <CompetenceAnalysisImportModalContent
          handleCloseModal={handleCloseModal}
          personID={payload?.personID}
        />
      </Modal>
    );
  }

  return null;
};
