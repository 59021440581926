import React from 'react';

import { Button } from '@/components/Button/Button';
import { UserIcon } from '@/components/Icons/UserIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { functionsPageModalsActions } from '@/core/redux/slices/modalsSlice/functionsPage/functionsPageModalsSlice';
import { generateName } from '@/core/utils/commonUtils';
import { colorTypes } from '@/styles/types';
import { IPerson } from '@/types/person';

interface ISelectedPerson {
  selectedPerson: IPerson | null;
}

export const FunctionSelectedPerson: React.FC<ISelectedPerson> = ({ selectedPerson }) => {
  const dispatch = useAppDispatch();

  const handleOpenPersonInfo = () => {
    if (!selectedPerson) {
      return;
    }

    dispatch(functionsPageModalsActions.openPersonInfoModal(selectedPerson));
  };

  return (
    <Button
      onClick={handleOpenPersonInfo}
      buttonVariant={'Transparent'}
      className={`flex flex-row items-center gap-3 text-${colorTypes.Blue}`}
    >
      <UserIcon />
      <Typography type='H3' color={'Blue'}>
        {generateName(selectedPerson)}
      </Typography>
    </Button>
  );
};
