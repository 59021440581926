import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { ExpandIcon } from '@/components/Icons/ExpandIcon';
import { WorkingTimePlannerHeaderItem } from '@/components/WorkingTimePlanner/WorkingTimePlannerHeader/WorkingTimePlannerHeaderItem/WorkingTimePlannerHeaderItem';
import { useAppDispatch } from '@/core/redux/hooks';
import { IWorkingTime } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { workingTimeMobilityModalsActions } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/workingTimeMobilitModalsSlice';
import { colorTypes } from '@/styles/types';

interface IWorkingTimePlannerHeader {
  context: 'function' | 'modal';
}

export const WorkingTimePlannerHeader: React.FC<IWorkingTimePlannerHeader> = ({ context }) => {
  const { t: workingTimePlannerTranslations } = useTranslation('workingTimePlanner', {
    keyPrefix: 'header',
  });

  const { watch } = useFormContext<IWorkingTime>();

  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(workingTimeMobilityModalsActions.openWorkingTimePredefinedVariantsModal());
  };

  return (
    <div className={'flex flex-row justify-end gap-3 w-full'}>
      <WorkingTimePlannerHeaderItem
        name={workingTimePlannerTranslations('workingHoursNet.label')}
        value={watch('workingWeek.sumNet')}
      />
      <WorkingTimePlannerHeaderItem
        name={workingTimePlannerTranslations('workingHoursGross.label')}
        value={watch('workingWeek.sumGross')}
      />
      <WorkingTimePlannerHeaderItem
        name={workingTimePlannerTranslations('workingDays.label')}
        value={watch('workingWeek.workingDaysCount')}
      />
      {context === 'function' && (
        <Button buttonVariant={'Transparent'} buttonStyle={'Icon'} onClick={handleOpenModal}>
          {
            <ExpandIcon
              className={`text-${colorTypes.Blue} hover:text-${colorTypes.BlueHover} ms-5`}
            />
          }
        </Button>
      )}
    </div>
  );
};
