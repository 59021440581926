import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

interface IQualificationObjectivesItem {
  name: string;
  dateFrom: string;
  dateTo: string;
  fieldName: string;
  place: string;
}

export const InternshipDocumentationItem: React.FC<IQualificationObjectivesItem> = ({
  name,
  dateFrom,
  dateTo,
  fieldName,
  place,
}) => {
  const formContext = useFormContext();
  const isSelected = formContext.watch(fieldName);

  return (
    <div
      className={twMerge(
        'grid grid-cols-12 gap-4 items-center justify-items-center p-2  border-l-2  border-white px-4',
        isSelected && `bg-${colorTypes.Hover} border-l-2 border-${colorTypes.BlueHover}`
      )}
    >
      <Checkbox name={fieldName} />
      <Typography className={'col-span-5 justify-self-start'}>{name}</Typography>
      <Typography className={'col-span-2 justify-self-start'}>{place}</Typography>
      <Typography className={'col-span-2'}>{dateFrom}</Typography>
      <Typography className={'col-span-2'}>{dateTo}</Typography>
    </div>
  );
};
