import React, { PropsWithChildren, useEffect, useState } from 'react';
import clsx from 'clsx';
import { AnimatePresence } from 'framer-motion';

import { NotificationItem } from '@/components/NotificationsContainer/Notification/NotificationItem';
import { useAppSelector } from '@/core/redux/hooks';
import { notificationsSelectors } from '@/core/redux/slices/notifications/notificatuinsSelectors';

import styles from './styles.module.scss';

export const NotificationsContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const notifications = useAppSelector(notificationsSelectors.notifications);
  const [notificationList, setNotificationList] = useState<string[]>([]);

  useEffect(() => {
    if (notifications) {
      setNotificationList(Object.keys(notifications));
    }
  }, [notifications]);

  return (
    <div className='relative'>
      <div className='h-screen overflow-auto overflow-x-hidden'>{children}</div>
      <div className='absolute bottom-0 start-0'>
        <div className={clsx(styles.list, 'flex flex-col mb-4 ms-4')}>
          <AnimatePresence mode='popLayout' initial={false}>
            {notificationList &&
              notificationList.map((key) => (
                <NotificationItem {...notifications[key]} id={key} key={key} />
              ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
