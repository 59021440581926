import React from 'react';
import { parseInt } from 'lodash';
import { LabelProps } from 'recharts';

interface ICustomPointLabel {
  isShowLabel?: boolean;
  chartLayout: 'vertical' | 'horizontal';
  labelProps: LabelProps;
}

export const BarCustomPointLabel: React.FC<ICustomPointLabel> = (props) => {
  const {
    labelProps: { x, y, value, width, height },
    isShowLabel = false,
    chartLayout,
  } = props;

  if (!x || !y) {
    return null;
  }

  const positionX = typeof x === 'string' ? parseInt(x) : x;
  const positionY = typeof y === 'string' ? parseInt(y) : y;

  const barWidth = typeof width === 'string' ? parseInt(width) : width ?? 0;
  const barHeight = typeof height === 'string' ? parseInt(height) : height ?? 0;

  const containerWidth = 30;
  const containerHeight = 25;

  const svgPositionX = positionX + barWidth / 2 - containerWidth / 2;
  const svgPositionY =
    chartLayout === 'horizontal'
      ? positionY - containerHeight / 2
      : positionY - containerHeight / 2 + barHeight / 2;

  return (
    <svg x={svgPositionX} y={svgPositionY} width={containerWidth} height={containerHeight}>
      {isShowLabel && (
        <>
          <g>
            <rect
              x={0}
              y={0}
              width={containerWidth}
              height={containerHeight}
              opacity={1}
              rx={5}
              ry={5}
              fill={'#3575EF'}
            />
            <text
              x={containerWidth / 2}
              y={containerHeight / 2}
              fill={'white'}
              dominantBaseline={'middle'}
              textAnchor={'middle'}
            >
              {value}
            </text>
          </g>
        </>
      )}
    </svg>
  );
};
