import { useEffect } from 'react';

import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { ICategory, logbookActions } from '@/core/redux/slices/logbook/logbookSlice';
import { logbookSelectors } from '@/core/redux/slices/logbook/selectors';

export function useFecthedCategories() {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(logbookSelectors.categories);

  const options: ISelectItem[] = categories.map((category: ICategory) => ({
    id: String(category.id),
    component: category.name,
  }));

  useEffect(() => {
    dispatch(logbookActions.fetchCategories());
  }, [dispatch]);

  return { categories, options };
}
