import React, { useState } from 'react';

import { IIcon } from '@/types/icon';

export const ExpandIcon: React.FC<IIcon> = ({ className }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <rect
        x='0.5'
        y='23.5'
        width='23'
        height='23'
        rx='5.5'
        transform='rotate(-90 0.5 23.5)'
        stroke={isHovered ? '#3575EF' : '#B5C5E4'}
        fill={isHovered ? '#3575EF' : 'none'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.19186 15.86C7.95233 15.6599 7.93468 15.3194 8.15244 15.0993L10.7247 12.5L8.15244 9.90067C7.93468 9.68063 7.95233 9.34008 8.19186 9.14004C8.4314 8.93999 8.8021 8.95621 9.01986 9.17626L11.7714 11.9567C12.0762 12.2648 12.0762 12.7352 11.7714 13.0433L9.01986 15.8237C8.8021 16.0438 8.4314 16.06 8.19186 15.86Z'
        fill={isHovered ? '#fff' : '#3575EF'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.1919 15.86C12.9523 15.6599 12.9347 15.3194 13.1524 15.0993L15.7247 12.5L13.1524 9.90067C12.9347 9.68063 12.9523 9.34008 13.1919 9.14004C13.4314 8.93999 13.8021 8.95621 14.0199 9.17626L16.7714 11.9567C17.0762 12.2648 17.0762 12.7352 16.7714 13.0433L14.0199 15.8237C13.8021 16.0438 13.4314 16.06 13.1919 15.86Z'
        fill={isHovered ? '#fff' : '#3575EF'}
      />
    </svg>
  );
};
