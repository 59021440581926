import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { AttentionIcon } from '@/components/Icons/AttentionIcon';
import { TableFolderIcon } from '@/components/Icons/TableFolderIcon';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  IMedication,
  medicationAdministrationActions,
} from '@/core/redux/slices/medicationAdministration/medicationAdministrationSlice';
import { medicationAdministrationSelectors } from '@/core/redux/slices/medicationAdministration/selectors';
import { medicationAdministrationModalsActions } from '@/core/redux/slices/modalsSlice/medicationAdministration/medicationAdministrationModalSlice';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { twMerge } from '@/core/utils/tailwindUtil';
import { LoadingStatus } from '@/types/loadingStatus';

export const MedicationUnadministratedList: React.FC = () => {
  const { t: medicationAdministrationTranslations } = useTranslation('medicationAdministration');

  const medications = useAppSelector(medicationAdministrationSelectors.unadministratedMedications);
  const markAdministratedLock = useAppSelector(
    medicationAdministrationSelectors.markAdministratedLock
  );
  const isOnlyOnDemand = useAppSelector(medicationAdministrationSelectors.isOnlyOnDemand);
  const notificationDelay = useAppSelector(
    parametersSelectors.medikamentenverabreichungUpdateDataNotificationDelay
  );

  const dispatch = useAppDispatch();

  const handleOpenHistory = (medication: IMedication) => {
    dispatch(
      medicationAdministrationModalsActions.openHistorynModal({
        medication: medication,
      })
    );
  };

  const handleMarkAdministrated = (
    medicationId: number,
    isGiven: boolean,
    isDemanded?: boolean | null,
    administrationRemark?: string | null,
    isRemarkedOnGiving?: boolean | null
  ) => {
    if (isGiven && !isRemarkedOnGiving) {
      if (isDemanded) {
        dispatch(
          medicationAdministrationModalsActions.openConfirmMarknModal({
            medicationID: medicationId,
            isGiven: isGiven,
            isOnlyOnDemand: isOnlyOnDemand,
          })
        );
      } else {
        dispatch(
          medicationAdministrationActions.markAdministrated({
            medicationID: medicationId,
            isGiven: isGiven,
            administrationRemark: administrationRemark ?? '',
          })
        );
      }
    } else {
      dispatch(
        medicationAdministrationModalsActions.openConfirmMarknModal({
          medicationID: medicationId,
          isGiven: isGiven,
          isOnlyOnDemand: isOnlyOnDemand,
        })
      );
    }
  };

  const columns: ITableColumn[] = [
    {
      header: medicationAdministrationTranslations('columns.personName.label'),
      accessor: 'personName',
    },
    {
      header: '',
      accessor: 'administrationNote',
      template: (value: string, data: IMedication) => {
        const { id, administrationNote } = data;

        return (
          <>
            {administrationNote && (
              <>
                <div data-tooltip-id={String(id)} data-tooltip-content={administrationNote}>
                  <AttentionIcon />
                </div>
              </>
            )}
            <Tooltip id={String(id)} style={{ zIndex: 100000 }} />
          </>
        );
      },
    },
    {
      header: medicationAdministrationTranslations('columns.medicationName.label'),
      className: 'max-w-[300px] whitespace-normal',
      accessor: 'medicationName',
      template: (value: string) => {
        return <div className='flex gap-5 items-center'>{value}</div>;
      },
    },
    {
      header: medicationAdministrationTranslations('columns.dosageForm.label'),
      accessor: 'dosageForm',
    },
    {
      header: medicationAdministrationTranslations('columns.daytime.label'),
      accessor: 'daytime',
    },
    {
      header: medicationAdministrationTranslations('columns.dosage.label'),
      accessor: 'dosage',
    },
    {
      header: medicationAdministrationTranslations('columns.isNotGiven.label'),
      headerClassName: 'bg-orange text-white',
      accessor: 'isNotGiven',
      className: 'bg-orange bg-opacity-20 group',
      isHidden: isOnlyOnDemand,
      template: (value: boolean, data: IMedication) => {
        const { id, administrationNote, isDemanded, isRemarkedOnGiving } = data;

        return (
          <div
            onClick={() => {
              handleMarkAdministrated(
                id,
                false,
                isDemanded,
                administrationNote,
                isRemarkedOnGiving
              );
            }}
            className={twMerge(
              'flex gap-2.5',
              isDemanded && 'pointer-events-none cursor-not-allowed'
            )}
          >
            <CheckboxUI checked={Boolean(value)} readOnly />
            <Typography className='invisible group-hover:visible' type='14-M' color='Secondary'>
              {medicationAdministrationTranslations('options.notGiven.label')}
            </Typography>
          </div>
        );
      },
      isSortable: false,
    },
    {
      header: medicationAdministrationTranslations('columns.isGiven.label'),
      headerClassName: 'bg-blue text-white',
      accessor: 'isGiven',
      className: 'group bg-blue bg-opacity-20',
      template: (value: boolean, data: IMedication) => {
        const { id, administrationRemark, isDemanded, isRemarkedOnGiving } = data;

        return (
          <div
            onClick={() =>
              handleMarkAdministrated(
                id,
                true,
                isDemanded,
                administrationRemark,
                isRemarkedOnGiving
              )
            }
            className='flex gap-2.5'
          >
            <CheckboxUI checked={Boolean(value)} readOnly />
            <Typography className='invisible group-hover:visible' type='14-M' color='Secondary'>
              {medicationAdministrationTranslations('options.given.label')}
            </Typography>
          </div>
        );
      },
      isSortable: false,
    },
    {
      header: '',
      accessor: '',
      isSortable: false,
      template: (value, data: IMedication) => {
        return (
          <div className='flex gap-2.5'>
            <div
              className='p-2 hover:bg-hover rounded-default'
              onClick={() => handleOpenHistory(data)}
            >
              <TableFolderIcon />
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (markAdministratedLock === LoadingStatus.LOADED) {
      dispatch(
        notificationsActions.showNotification({
          notification: {
            type: 'success',
            title: medicationAdministrationTranslations(
              'notification.markAdministrated.title.label'
            ),
            description: medicationAdministrationTranslations(
              'notification.markAdministrated.description.label'
            ),
            delay: notificationDelay,
          },
        })
      );
    }
  }, [markAdministratedLock]);

  useEffect(() => {
    return () => {
      dispatch(medicationAdministrationActions.setUnadministratedMedications([]));
      dispatch(
        medicationAdministrationActions.setUnadministratedMedicationsLock(LoadingStatus.NEVER)
      );
      dispatch(medicationAdministrationActions.setMarkAdministratedLock(LoadingStatus.NEVER));
    };
  }, []);

  return (
    <Table
      data={medications}
      columns={columns}
      singleSelect
      defaultSortConfig={{
        key: 'personName',
        direction: 'asc',
      }}
    />
  );
};
