import React from 'react';
import { motion } from 'framer-motion';

import { IIcon } from '@/types/icon';

export const SpinnerIcon: React.FC<IIcon> = ({ className }) => (
  <motion.div
    className={className}
    animate={{ rotate: 360 }}
    transition={{ ease: 'linear', duration: 2, repeat: Infinity }}
  >
    <motion.svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <defs>
        <linearGradient id='grad' gradientTransform='rotate(45)'>
          <stop offset='0%' stopColor='currentColor' stopOpacity='0.12' />
          <stop offset='100%' stopColor='currentColor' />
        </linearGradient>
      </defs>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.0013 38.3334C30.1265 38.3334 38.3346 30.1253 38.3346 20.0001C38.3346 9.87486 30.1265 1.66675 20.0013 1.66675C9.87608 1.66675 1.66797 9.87486 1.66797 20.0001C1.66797 30.1253 9.87608 38.3334 20.0013 38.3334ZM20.0013 29.1667C25.0639 29.1667 29.168 25.0627 29.168 20.0001C29.168 14.9375 25.0639 10.8334 20.0013 10.8334C14.9387 10.8334 10.8346 14.9375 10.8346 20.0001C10.8346 25.0627 14.9387 29.1667 20.0013 29.1667Z'
        fill='url(#grad)'
      />
    </motion.svg>
  </motion.div>
);
