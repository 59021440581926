import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { DatePickerVariant } from '@/core/enums/datePickerVariantsEnum';
import { DateRangeType } from '@/core/enums/dateRangeTypeEnum';
import { useAppDispatch } from '@/core/redux/hooks';
import { dashboardActions } from '@/core/redux/slices/dashboard/dashboardSlice';
import { colorTypes } from '@/styles/types';

const CHOOSE_DAY_ID = 'choose_day';
const CHOOSE_PERIOD_ID = 'choose_period';

export const ActivitiesWidgetHeader: React.FC = () => {
  const { t: activitiesWidgetHeaderTranslations } = useTranslation('activitiesWidget', {
    keyPrefix: 'activitiesHeader.dateRanges',
  });
  const dispatch = useAppDispatch();

  const dateSelectValues: ISelectItem[] = [
    {
      component: <Typography>{activitiesWidgetHeaderTranslations('thisWeek.label')}</Typography>,
      componentContainerClassName: `px-3 py-[6px] rounded-0 `,
      id: DateRangeType.THIS_WEEK,
    },
    {
      component: <Typography>{activitiesWidgetHeaderTranslations('thisMonth.label')}</Typography>,
      componentContainerClassName: `px-3 py-[6px] rounded-0 hover:bg-${colorTypes.Fields}`,
      id: DateRangeType.THIS_MONTH,
    },
    {
      component: <Typography>{activitiesWidgetHeaderTranslations('thisQuarter.label')}</Typography>,
      componentContainerClassName: `px-3 py-[6px] rounded-none border-b border-scroll border-solid hover:rounded-default`,
      id: DateRangeType.THIS_QUARTER,
    },
    {
      component: <Typography>{activitiesWidgetHeaderTranslations('nextWeek.label')}</Typography>,
      componentContainerClassName: `px-3 py-[6px] hover:bg-${colorTypes.Fields}`,
      id: DateRangeType.NEXT_WEEK,
    },
    {
      component: <Typography>{activitiesWidgetHeaderTranslations('nextMonth.label')}</Typography>,
      componentContainerClassName: `px-3 py-[6px] hover:bg-${colorTypes.Fields}`,
      id: DateRangeType.NEXT_MONTH,
    },
    {
      component: <Typography>{activitiesWidgetHeaderTranslations('nextQuarter.label')}</Typography>,
      componentContainerClassName: `px-3 py-[6px] rounded-none border-b border-scroll border-solid hover:rounded-default`,
      id: DateRangeType.NEXT_QUARTER,
    },
    {
      component: (
        <Typography color={'Blue'}>
          {activitiesWidgetHeaderTranslations('chooseDay.label')}
        </Typography>
      ),
      componentContainerClassName: `px-3 py-[6px] text-${colorTypes.Blue} hover:bg-${colorTypes.Fields}`,
      id: CHOOSE_DAY_ID,
    },
    {
      component: (
        <Typography color={'Blue'}>
          {activitiesWidgetHeaderTranslations('choosePeriod.label')}
        </Typography>
      ),
      componentContainerClassName: `px-3 py-[6px] text-${colorTypes.Blue} hover:bg-${colorTypes.Fields}`,
      id: CHOOSE_PERIOD_ID,
    },
  ];

  const handleChange = (id: string) => {
    if (id === CHOOSE_DAY_ID) {
      dispatch(dashboardActions.setDatePickerVariant(DatePickerVariant.SINGLE));
      return;
    }

    if (id === CHOOSE_PERIOD_ID) {
      dispatch(dashboardActions.setDatePickerVariant(DatePickerVariant.RANGE));
      return;
    }

    dispatch(dashboardActions.setDatePickerVariant(null));
    dispatch(dashboardActions.setActivitiesDateRange(id as DateRangeType));
  };

  return (
    <div className={'flex flex-row justify-end flex-1 pe-[10px]'}>
      <Select
        values={dateSelectValues}
        initialValueID={dateSelectValues[0].id}
        className={clsx(`p-1 px-[10px] py-2 hover:bg-${colorTypes.Hover} hover:rounded-default`)}
        contentPosition={'bottom-end'}
        contentClassName={'py-2 px-1 bg-white'}
        variant='Button'
        onChange={handleChange}
      />
    </div>
  );
};
