import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';
import { IPerson } from '@/types/person';

export interface ISpecialistsState {
  specialists: IPerson[];
  selectedSpecialist: IPerson | null;
  specialistsLock: LoadingStatus;
}

export interface ISpecialistsFecthPayload {
  search: string;
}

//state
const initialState: ISpecialistsState = {
  specialists: [],
  selectedSpecialist: null,
  specialistsLock: LoadingStatus.NEVER,
};

const specialistsSlice = createSlice({
  name: 'specialists',
  initialState,
  reducers: {
    // specialists
    fetchSpecialists: (state, action: PayloadAction<ISpecialistsFecthPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setSpecialistsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      specialistsLock: action.payload,
    }),
    setSpecialists: (state, action: PayloadAction<IPerson[]>) => {
      return {
        ...state,
        specialists: action.payload,
      };
    },
    setSelectedSpecialist: (state, action: PayloadAction<IPerson | null>) => {
      return {
        ...state,
        selectedSpecialist: action.payload,
      };
    },
  },
});

export const specialistsReducer = specialistsSlice.reducer;
export const specialistsActions = specialistsSlice.actions;
