import Cookie from 'universal-cookie';

const getOptions = (host?: string) => (options?: object) => ({
  path: '/',
  maxAge: 60 * 60 * 24,
  domain: host,
  ...options,
});

class Cookies {
  cookies: Cookie;

  getOpts: (options?: object) => object;

  constructor(ctx?: object | string, host?: string) {
    this.cookies = new Cookie(ctx);
    this.getOpts = getOptions(host);
  }

  get = (name: string, options = {}) => this.cookies.get(name, this.getOpts(options));

  set = (name: string, value?: string | number | null, options = {}) =>
    this.cookies.set(name, value, this.getOpts(options));

  remove = (name: string, options?: object) => this.cookies.remove(name, this.getOpts(options));
}

export const useCookies = (cookie?: string, host?: string) => new Cookies(cookie, host);

export const fromRawCookies = (cookie?: string) => new Cookies(cookie);
