import { useTranslation } from 'react-i18next';

import FormRender from '@/components/FormRender/FormRender';

import { addressForm } from './helpers/formTemplates';

export interface IAddressForm {
  onExit: () => void;
}

export const AddressForm: React.FC<IAddressForm> = () => {
  const { t: displayAddressModalTranslations } = useTranslation('displayAddressModal');

  return (
    <div className='w-full flex flex-col gap-4 pr-3'>
      <div className='grow'>
        <FormRender
          list={addressForm}
          translationContext={displayAddressModalTranslations}
          className={'grid grid-cols-2 gap-x-10'}
          title={displayAddressModalTranslations('information.title.label')}
        />
      </div>
    </div>
  );
};
