import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AdministrationChecklist } from '@/components/administartionFunctions/checklist/AdministrationChecklist';
import { AdministrationLocationManagement } from '@/components/administartionFunctions/locationManagement/AdministrationLocationManagement';
import { HorizontalScrollableContainer } from '@/components/HorizontalScrollableContainer/HorizontalScrollableContainer';
import { Typography } from '@/components/Typography/Typography';
import { AdministrationFunctionSelect } from '@/components/views/administration/AdministrationFunctionContainer/AdministrationFunctionSelect/AdministrationFunctionSelect';
import { AdministrationFunctionTabs } from '@/components/views/administration/AdministrationFunctionContainer/AdministrationFunctionTabs/AdministrationFunctionTabs';
import { AdministrationFunctionViews } from '@/core/enums/common/administrationFunctionViewsEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { administrationPageSelectors } from '@/core/redux/slices/administrationPage/selectors';
import { FunctionTypes } from '@/types/functions';

interface IFunctionView {
  id: string;
  type: AdministrationFunctionViews;
  fullView: JSX.Element;
  wizardView?: JSX.Element;
}

export const administrationFunctionViews: Record<string, IFunctionView> = {
  5: {
    id: '5',
    fullView: <AdministrationChecklist />,
    type: AdministrationFunctionViews.Checklist,
  },
  6: {
    id: '6',
    fullView: <AdministrationLocationManagement />,
    type: AdministrationFunctionViews.LocationManagement,
  },
};

export const AdministrationFunctionContainer: React.FC = () => {
  const { t: functionsPageTranslations } = useTranslation('functionsPage');

  const activeTabId = useAppSelector(administrationPageSelectors.activeTabId);
  const tabs = useAppSelector(administrationPageSelectors.tabs);

  const activeTab = activeTabId ? tabs.find((tab) => tab.id === activeTabId) : null;
  const functionType = activeTab?.functionItem?.functionType;

  const isFunctionSelected = Boolean(activeTab?.functionItem);

  const selectedView: IFunctionView | undefined = useMemo(
    () => administrationFunctionViews[activeTab?.functionItem?.id ?? ''],
    [activeTabId, tabs]
  );

  const renderComponent = () => {
    const renderError = () => (
      <div className='p-5 flex justify-center'>
        <Typography>
          {functionsPageTranslations('placeholders.functionNotImplemented.label')}
        </Typography>
      </div>
    );

    if (functionType === FunctionTypes.wizard) {
      return selectedView?.wizardView ?? selectedView?.fullView;
    }

    if (
      selectedView &&
      (!functionType || functionType === FunctionTypes.full || functionType === FunctionTypes.none)
    ) {
      return selectedView.fullView || renderError();
    }

    return renderError();
  };

  return (
    <div className={'flex flex-col'}>
      <HorizontalScrollableContainer className='mx-[10px] mt-[30px] h-[47px]'>
        <AdministrationFunctionTabs />
      </HorizontalScrollableContainer>
      <div>
        {isFunctionSelected && activeTabId && renderComponent()}
        {!isFunctionSelected && activeTabId && <AdministrationFunctionSelect />}
        {!isFunctionSelected && !activeTabId && (
          <div className='p-5 flex justify-center'>
            <Typography>
              {functionsPageTranslations('placeholders.selectFunction.label')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
