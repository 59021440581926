import React from 'react';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

interface IPersonInfoItem {
  label?: string;
  value?: string | null;
}

export const PersonInfoItem: React.FC<IPersonInfoItem> = ({ label, value }) => {
  return (
    <div className={'flex flex-col items-start gap-2'}>
      <Typography>{label}</Typography>
      <Typography
        className={`w-full p-2 pl-4 bg-${colorTypes.HoverLight} rounded-default min-h-[40px]`}
      >
        {value}
      </Typography>
    </div>
  );
};
