import { RefObject } from 'react';
import fp from 'lodash/fp';
import * as y from 'yup';

import { Coords } from '@/types/dropDown';
import { LoadingStatus } from '@/types/loadingStatus';

export const isBrowser = () => typeof window !== 'undefined';

export const merge = fp.mergeWith((obj, src) => (fp.isArray(obj) ? [...obj, ...src] : undefined));

export const mergeModuleParts = (parts: any[]): any => parts.reduce(merge, {});

export const mergeEndArray = fp.mergeWith((obj, src) =>
  fp.isArray(obj) ? (src?.length > 0 ? [...src] : obj?.length > 0 ? [...obj] : []) : undefined
);

export const mergeModulePartsEndArray = (parts: any[]): any => parts.reduce(mergeEndArray, {});

export const yup = y;

export const arrayFromSelect = (checkboxData: any, strictArray: boolean = false) => {
  if (checkboxData.all === true) {
    return strictArray
      ? null
      : [...fp.pipe(fp.keys, fp.map(fp.replace('id-', '')))(checkboxData.list)];
  }

  const list = fp.pipe(
    fp.pickBy(fp.isEqual(true)),
    fp.keys,
    fp.map(fp.replace('id-', ''))
  )(checkboxData.list);

  return list.length > 0 ? list : [];
};

export const createArrayWithLength = (size: number) => {
  const arr = [];

  for (let i = 1; i <= size; i++) {
    arr.push(i);
  }

  return arr;
};

export type personNameFormat = 'name surname' | 'surname, name';

export const generateName = (user: any, format?: personNameFormat): string => {
  if (fp.isEmpty(user)) {
    return '';
  }

  let name = '';
  let surname = '';

  if (fp.has('name') && !fp.has('surname')) {
    name = fp.isEmpty('name') ? '' : user.name;
  }

  if (fp.has('surname') && !fp.has('name')) {
    surname = fp.isEmpty('surname') ? '' : user.surname;
  }

  if (fp.has('name') && fp.has('surname')) {
    name = fp.isEmpty('name') ? '' : user.name;
    surname = fp.isEmpty('surname') ? '' : user.surname;
  }

  switch (format) {
    case 'surname, name':
      return `${surname}, ${name}`;
    default:
      return `${name} ${surname}`;
  }
};

export const getPersonInitials = (user: any): string => {
  if (fp.isEmpty(user)) {
    return '';
  }

  let name = '';
  let surname = '';

  if (fp.has('name') && !fp.has('surname')) {
    name = fp.isEmpty('name') ? '' : user.name[0].toUpperCase();
  }

  if (fp.has('surname') && !fp.has('name')) {
    surname = fp.isEmpty('surname') ? '' : user.surname[0].toUpperCase();
  }

  if (fp.has('name') && fp.has('surname')) {
    name = fp.isEmpty('name') ? '' : user.name[0].toUpperCase();
    surname = fp.isEmpty('surname') ? '' : user.surname[0].toUpperCase();
  }

  return `${name}${surname}`;
};

export const resolveProgress = (status: LoadingStatus): boolean => {
  return status === LoadingStatus.LOADING;
};

export const getSelectedOption = (options: Record<string, boolean>): string => {
  let option;
  Object.keys(options).forEach((dateKey) => {
    if (options[dateKey]) {
      option = dateKey;
    }
  });
  return option ?? '';
};

export const getCoords = (ref: RefObject<HTMLElement>): Coords | null => {
  const box = ref.current?.getBoundingClientRect();

  if (box) {
    return {
      left: box.left,
      top: box.top + box.height,
      width: box.width,
    };
  }

  return null;
};

export function getLetter(index: number): string {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const base = alphabet.length;

  if (index < base) {
    return alphabet[index];
  } else {
    const letterIndex = Math.floor(index / base) - 1;
    const digit = index % base;
    return `${alphabet[letterIndex]}${alphabet[digit]}`;
  }
}

export const formatOptionsToSchema = (
  options:
    | {
        id: number;
        name: string;
        isSelected: boolean;
      }[]
    | undefined
): { [key: number]: boolean } => {
  const optionsSchema: { [key: number]: boolean } = {};

  if (!options) {
    return {};
  }

  options.forEach((option) => {
    optionsSchema[option.id] = option.isSelected;
  });

  return optionsSchema;
};

export const formatSchemaToOptions = (input: {
  [key: string]: boolean;
}): { id: number; is_selected: boolean }[] => {
  return Object.entries(input).map(([key, value]) => ({
    id: Number(key),
    is_selected: value,
  }));
};

export const internationalizationLocales = {
  en: 'en-US',
  de: 'de-DE',
  ru: 'ru-RU',
};

export const toCamelCase = (obj: any): any => {
  if (fp.isObject(obj)) {
    if (fp.isArray(obj)) {
      return obj.map((item) => toCamelCase(item));
    } else {
      const newObject: any = {};
      for (const key of Object.keys(obj) as (keyof typeof obj)[]) {
        if (obj.hasOwnProperty(key)) {
          const newKey = fp.camelCase(key);
          newObject[newKey] = toCamelCase(obj[key]);
        }
      }
      return newObject;
    }
  } else {
    return obj;
  }
};

export function extractIntegerFromString(input: string | undefined | null): string | undefined {
  if (input && !isNaN(parseInt(input))) {
    const number = parseInt(input);

    return number.toString();
  }

  return undefined;
}

export const intToColor = (num: number): string => {
  num >>>= 0;
  const b = num & 0xff;
  const g = (num & 0xff00) >>> 8;
  const r = (num & 0xff0000) >>> 16;
  const a = 1;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export function isIntermediateStatus(arr?: (boolean | undefined)[]): boolean {
  if (!arr) {
    return false;
  }

  const hasTrue = arr.includes(true);
  const hasFalse = arr.includes(false) || arr.includes(undefined);
  return hasTrue && hasFalse;
}

export const extractQueryParam = (param: string): string | null => {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get(param);
};
