import React from 'react';

import { IIcon } from '@/types/icon';

export const CrossIcon: React.FC<IIcon> = ({ onClick, className }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      d='M14.125 5.87402L5.87542 14.1236'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.87695 5.875L14.1265 14.1246'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
