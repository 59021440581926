import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApprovedBeforeApplicationDateDialog } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/Dialogs/ApprovedBeforeApplicationDateDialog';
import { ApprovedToBeforeApprovedFromDateDialog } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/Dialogs/ApprovedToBeforeApprovedFromDateDialog';
import { ApprovedToWithoutApprovedFromDateDialog } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/Dialogs/ApprovedToWithoutApprovedFromDateDialog';
import { ConfirmCreatingDialog } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/Dialogs/ConfirmCreatingDialog';
import { RequiredResponsibleNotSelected } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/Dialogs/RequiredResponsibleNotSelected';
import {
  IAdditionalPersonRequirementForm,
  useAdditionalPersonRequirementForm,
} from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/hooks/useAdditionalPersonRequirementForm';
import { PersonRequirementAssistanceSelect } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/PersonRequirementAssistanceSelect';
import { PersonRequirementComment } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/PersonRequirementComment';
import { PersonRequirementDateBlock } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/PersonRequirementDateBlock';
import { PersonRequirementFooter } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/PersonRequirementFooter';
import { PersonRequirementResponsibleSelect } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/PersonRequirementResponsibleSelect';
import { PersonRequirementTimeSpent } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/PersonRequirementTimeSpent';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { AdditionalPersonRequirementValidationStatus } from '@/core/enums/functions/organizationalUnits/additionalPersonRequirementValidationStatus';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsActions } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IAdditionalPersonRequirementField {
  form: UseFormReturn<IAdditionalPersonRequirementForm>;
}

export const responsibleTableName = DropdownItemsByFetch.GROUP_MANAGER;

export const AdditionalPersonRequirementModalContent: React.FC = () => {
  const { t: additionalPersonRequirementsModalTranslations } = useTranslation(
    'additionalPersonRequirementModal'
  );

  const [approvedFromDatePickerOpened, setApprovedFromDatePickerOpened] = useState<boolean>(false);
  const [approvedToDatePickerOpened, setApprovedToDatePickerOpened] = useState<boolean>(false);
  const [responsibleSelectedOpened, setResponsibleSelectedOpened] = useState<boolean>(false);

  const { additionalPersonRequirementID, additionalPersonRequirementLock: loadingStatus } =
    useAppSelector(organizationalUnitsModalsSelectors.additionalPersonRequirementModal);

  const { form, validationStatus, setValidationStatus, validate } =
    useAdditionalPersonRequirementForm();
  const currentValues = form.watch();

  const dispatch = useAppDispatch();

  const { selectedPersonID } = useSelectedPerson();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      organizationalUnitsModalsActions.fetchAvailableAssistanceTypes({
        personID: selectedPersonID,
      })
    );

    dispatch(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: responsibleTableName,
      })
    );

    if (!additionalPersonRequirementID) {
      return;
    }

    dispatch(
      organizationalUnitsModalsActions.fetchAdditionalPersonRequirementDetails({
        additionalPersonRequirementID: additionalPersonRequirementID,
      })
    );
  }, [additionalPersonRequirementID]);

  const handleSubmit = () => {
    if (!selectedPersonID) {
      return;
    }

    const additionalPersonRequirementID = currentValues.id;

    if (additionalPersonRequirementID) {
      dispatch(
        organizationalUnitsModalsActions.updateAdditionalPersonRequirement({
          personID: selectedPersonID,
          additionalPersonRequirementID: additionalPersonRequirementID,
          formValues: currentValues,
        })
      );

      return;
    }

    dispatch(
      organizationalUnitsModalsActions.createAdditionalPersonRequirement({
        personID: selectedPersonID,
        formValues: currentValues,
      })
    );
  };

  const handleCloseDialog = () => {
    setValidationStatus(AdditionalPersonRequirementValidationStatus.Default);
  };

  const handleConfirmCreating = () => {
    handleSubmit();
    handleCloseDialog();
    handleExit();
  };

  const handleFocusOnApprovedFromDatePicker = () => {
    setApprovedFromDatePickerOpened(true);
    handleCloseDialog();
  };

  const handleFocusOnApprovedToDatePicker = () => {
    setApprovedToDatePickerOpened(true);
    handleCloseDialog();
  };

  const handleFocusOnResponsibleSelect = () => {
    setResponsibleSelectedOpened(true);
    handleCloseDialog();
  };

  const handleExit = () => {
    dispatch(organizationalUnitsModalsActions.closeAdditionalPersonRequirementModal());
  };

  const renderDialog = useCallback(() => {
    switch (validationStatus) {
      case AdditionalPersonRequirementValidationStatus.Default:
        return null;
      case AdditionalPersonRequirementValidationStatus.Valid:
        return (
          <ConfirmCreatingDialog onCancel={handleCloseDialog} onConfirm={handleConfirmCreating} />
        );
      case AdditionalPersonRequirementValidationStatus.ApprovedBeforeApplicationDate:
        return (
          <ApprovedBeforeApplicationDateDialog
            onCancel={handleCloseDialog}
            onConfirm={handleConfirmCreating}
          />
        );
      case AdditionalPersonRequirementValidationStatus.ApprovedToWithoutApprovedFromDate:
        return (
          <ApprovedToWithoutApprovedFromDateDialog
            onConfirm={handleFocusOnApprovedFromDatePicker}
          />
        );
      case AdditionalPersonRequirementValidationStatus.ApprovedToBeforeApprovedFromDate:
        return (
          <ApprovedToBeforeApprovedFromDateDialog onConfirm={handleFocusOnApprovedToDatePicker} />
        );
      case AdditionalPersonRequirementValidationStatus.RequiredResponsibleNotSelected:
        return <RequiredResponsibleNotSelected onConfirm={handleFocusOnResponsibleSelect} />;
    }
  }, [validationStatus]);

  return (
    <Sidebar
      position={'right'}
      onExit={handleExit}
      footer={<PersonRequirementFooter handleExit={handleExit} handleSave={validate} form={form} />}
    >
      <div className={'flex flex-col gap-5 p-5'}>
        <Typography type={'H3'}>
          {additionalPersonRequirementsModalTranslations('title.label')}
        </Typography>
        <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
          <FormProvider {...form}>
            <div className={'flex flex-col gap-5'}>
              <PersonRequirementAssistanceSelect form={form} />
              <PersonRequirementDateBlock
                form={form}
                approvedFromIsOpened={approvedFromDatePickerOpened}
                approvedToIsOpened={approvedToDatePickerOpened}
              />
              <PersonRequirementResponsibleSelect
                form={form}
                isOpened={responsibleSelectedOpened}
              />
              <PersonRequirementTimeSpent form={form} />
              <PersonRequirementComment form={form} />
            </div>
          </FormProvider>
        </Skeleton>
        {renderDialog()}
      </div>
    </Sidebar>
  );
};
