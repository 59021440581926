import { useEffect, useMemo } from 'react';
import fp from 'lodash/fp';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { authActions } from '@/core/redux/slices/auth/authSlice';
import { authSelectors } from '@/core/redux/slices/auth/selectors';

const useAuthUserLoader = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(authSelectors.accessToken);
  const profileEntity = useAppSelector(authSelectors.profileEntity);

  useEffect(() => {
    if (!fp.isEmpty(accessToken) && fp.isEmpty(profileEntity)) {
      dispatch(authActions.initializeProfile());
    }
  }, [dispatch, accessToken, profileEntity]);

  const isAuthorized = useMemo(() => !fp.isEmpty(profileEntity), [profileEntity]);

  return { isAuthorized, profileEntity };
};

export default useAuthUserLoader;
