import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
interface ICheckboxListUI {
  options: IOption[];
  onChange?: (selected: string, value: boolean) => void;
  defaultSelected?: string;
}

interface IOption {
  label: string;
  value: string;
}

interface ICheckboxList {
  options: IOption[];
  defaultValues: any;
}

export const CheckboxListUI: React.FC<ICheckboxListUI> = ({
  options,
  onChange,
  defaultSelected,
}) => {
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const handleSelect = (value: string) => {
    if (selected === value) {
      setSelected(undefined);
      onChange?.(value, false);
    } else {
      setSelected(value);
      onChange?.(value, true);
    }
  };

  return (
    <div className='flex gap-4'>
      {options.map((option: IOption) => {
        const {value, label} = option;
        return (
          <div className='flex gap-2 cursor-pointer' onClick={() => handleSelect(value)} key={value} >
            <CheckboxUI name={value} checked={selected === value} readOnly/>
            <label htmlFor={value}>{label}</label>
          </div>
        );
      })}
    </div>
  );
};

export const TypesCheckboxList: React.FC<ICheckboxList> = ({ options, defaultValues }) => {
  const form = useFormContext();

  const defaultSelected = useMemo(
    () => Object.keys(defaultValues).find((k) => defaultValues[k]),
    [defaultValues]
  );

  const handleSelect = (selected: string, value: boolean) => {
    options.forEach((option: IOption) => form.setValue(`types.${option.value}`, false));
    form.setValue(`types.${selected}`, value);
  };

  return (
    <CheckboxListUI
      options={options}
      onChange={(selected, value) => handleSelect(selected, value)}
      defaultSelected={defaultSelected}
    />
  );
};
