import React from 'react';

import { IIcon } from '@/types/icon';

export const CheckedIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M7 11.7941L10.312 15.1256C10.5142 15.329 10.8457 15.321 11.0378 15.1081L17 8.5'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);
