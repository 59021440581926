import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { PersonWidgetIcon } from '@/components/Icons/PersonWidgetIcon';
import { Typography } from '@/components/Typography/Typography';

import styles from './styles.module.scss';

export const Placeholder = () => {
  const { t: favoritePersonsWidgetTranslations } = useTranslation('favoritePersonWidget');

  return (
    <div className={clsx(styles.container, 'flex flex-col items-center justify-center')}>
      <PersonWidgetIcon />
      <Typography type='16-R' color='Secondary' className={clsx(styles.status)}>
        {favoritePersonsWidgetTranslations('placeholders.empty.label')}
      </Typography>
    </div>
  );
};
