import React, { useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import fp from 'lodash/fp';

import { useAdministrationChecklistSelector } from '@/components/administartionFunctions/checklist/AdministrationChecklistContext/AdministrationChecklistContext';
import { AdministrationChecklistItem } from '@/components/administartionFunctions/checklist/AdministrationChecklistList/AdministrationChecklistItem';
import { AdministrationChecklistListHeader } from '@/components/administartionFunctions/checklist/AdministrationChecklistList/AdministrationChecklistListHeader';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { administrationCheckListSelectors } from '@/core/redux/slices/administrationFunctions/checkList/selectors';
import {
  administrationChecklistActions,
  IAdministrationChecklist,
} from '@/core/redux/slices/administrationFunctions/checkList/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const AdministrationChecklistList: React.FC = () => {
  const [viewState, setViewState] = useState<IAdministrationChecklist[]>([]);

  const checklist = useAppSelector(administrationCheckListSelectors.checklist);
  const checklistLock = useAppSelector(administrationCheckListSelectors.checklistLock);

  const dispatch = useAppDispatch();

  const [selectedChecklistGroup] = useAdministrationChecklistSelector(
    (state) => state.selectedChecklistGroup
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setViewState((checklist) => {
        const oldIndex = fp.findIndex(['id', active?.id], checklist);
        const newIndex = fp.findIndex(['id', over?.id], checklist);

        const newState = arrayMove(checklist, oldIndex, newIndex);

        dispatch(administrationChecklistActions.reorderChecklist(newState));

        return newState;
      });
    }
  };

  useEffect(() => {
    if (!selectedChecklistGroup) {
      return;
    }

    dispatch(
      administrationChecklistActions.fetchChecklist({
        checklistGroupID: selectedChecklistGroup.id,
      })
    );
  }, [selectedChecklistGroup]);

  useEffect(() => {
    if (checklist.length < 1) {
      return;
    }

    setViewState([...checklist].sort((a, b) => a.order - b.order));
  }, [checklist]);

  return (
    <Skeleton trigger={checklistLock === LoadingStatus.LOADING}>
      {viewState && selectedChecklistGroup && (
        <div className={'h-full overflow-hidden px-2'}>
          <AdministrationChecklistListHeader />
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={viewState}>
              <div className={'h-full flex flex-col gap-1 overflow-y-auto'}>
                {viewState.map((checklist) => (
                  <AdministrationChecklistItem {...checklist} key={checklist.id} />
                ))}
              </div>
            </SortableContext>
          </DndContext>
        </div>
      )}
    </Skeleton>
  );
};
