import React from 'react';

import { IIcon } from '@/types/icon';

export const EntryIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g clipPath='url(#clip0_511_712)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.24991 1.12491C7.66412 1.12491 7.99991 1.46069 7.99991 1.87491C7.99991 2.28912 7.66412 2.62491 7.24991 2.62491C6.0425 2.62491 5.57644 2.63057 5.21022 2.72231C4.04696 3.01369 3.13869 3.92197 2.8473 5.08522C2.75557 5.45144 2.74991 5.9175 2.74991 7.12491L2.74991 10.8749C2.74991 12.0823 2.75557 12.5484 2.8473 12.9146C3.13868 14.0778 4.04696 14.9861 5.21022 15.2775C5.57644 15.3692 6.04249 15.3749 7.24991 15.3749V16.8749L7.13078 16.8749C6.08281 16.8751 5.41538 16.8752 4.84575 16.7326C3.14561 16.3067 1.81812 14.9792 1.39226 13.2791C1.24957 12.7094 1.2497 12.042 1.24989 10.994L1.24991 10.8749L1.24991 7.12491L1.24989 7.00578C1.2497 5.95781 1.24957 5.29038 1.39226 4.72075C1.81812 3.02061 3.14561 1.69312 4.84575 1.26726C5.41537 1.12457 6.08281 1.1247 7.13077 1.12489L7.13078 1.12489L7.24991 1.12491ZM12.2803 14.0303C12.5732 13.7374 12.5732 13.2625 12.2803 12.9697L9.06061 9.74998H17.7499C18.1642 9.74998 18.4999 9.41419 18.4999 8.99998C18.4999 8.58577 18.1642 8.24998 17.7499 8.24998H9.06061L12.2803 5.03031C12.5732 4.73742 12.5732 4.26254 12.2803 3.96965C11.9874 3.67676 11.5125 3.67676 11.2196 3.96965L6.71962 8.46965L6.18929 8.99998L6.71962 9.53031L11.2196 14.0303C11.5125 14.3232 11.9874 14.3232 12.2803 14.0303Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_511_712'>
        <rect width='18' height='18' fill='currentColor' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);
