import fp from 'lodash/fp';

import { FunctionTypes, IFunctionItem } from '@/types/functions';

export function getFunctionType(type: string): FunctionTypes {
  switch (type) {
    case 'wizard':
      return FunctionTypes.wizard;
    case 'full':
      return FunctionTypes.full;
    default:
      return FunctionTypes.none;
  }
}

export function searchFunctionByName(
  functions: IFunctionItem[],
  searchValue: string
): IFunctionItem[] {
  if (fp.isEmpty(searchValue)) {
    return functions;
  }
  const search = searchValue.toLowerCase();
  return [...functions].filter((func) => func.name.toLowerCase().includes(search));
}
