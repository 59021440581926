import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useImperativeHandle,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import fp from 'lodash/fp';

import { ActivitySkills } from '@/components/forms/CompetenceAnalysis/ActivitySkills/ActivitySkills';
import { Header } from '@/components/forms/CompetenceAnalysis/Header/Header';
import { competenceAnalysisResolver } from '@/components/forms/CompetenceAnalysis/helpers/competenceAnalysisResolver';
import { MethodologicalSkills } from '@/components/forms/CompetenceAnalysis/MethodologicalSkills/MethodologicalSkills';
import { OtherCompetencies } from '@/components/forms/CompetenceAnalysis/OtherCompetencies/OtherCompetencies';
import { PersonalSkills } from '@/components/forms/CompetenceAnalysis/PersonalSkills/PersonalSkills';
import { Recommendations } from '@/components/forms/CompetenceAnalysis/Recommendations/Recommendations';
import { SchoolCompetencies } from '@/components/forms/CompetenceAnalysis/SchoolCompetencies/SchoolCompetencies';
import { SocialCommunicativeSkills } from '@/components/forms/CompetenceAnalysis/SocialCommunicativeSkills/SocialCommunicativeSkills';
import { SuitabilityAndInclination } from '@/components/forms/CompetenceAnalysis/SuitabilityAndInclination/SuitabilityAndInclination';
import { useSelectedPersonForm } from '@/components/functions/IntegrationPlan/hooks/useSelectedPersonForm';
import { IntegrationPlanFormActions } from '@/components/functions/IntegrationPlan/IntegrationPlan';
import { IIntegrationPlanFormProps } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsContainer/IntegrationPlanFormsContainer';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  competenceAnalysisActions,
  ICompetenceAnalysis,
} from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/competenceAnalysisSlice';
import { competenceAnalysisSelectors } from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/selectors';

export const CompetenceAnalysis: ForwardRefExoticComponent<
  Omit<IIntegrationPlanFormProps, 'ref'> & RefAttributes<IntegrationPlanFormActions>
> = forwardRef((props, ref) => {
  const { t: competenceAnalysisTranslations } = useTranslation('competenceAnalysis');
  const { integrationPlanID, personID } = props;

  const initialValues = useAppSelector(competenceAnalysisSelectors.competenceAnalysis);
  const isCompetenceAnalysisImported = useAppSelector(
    competenceAnalysisSelectors.competenceAnalysisIsImported
  );

  const form = useForm<ICompetenceAnalysis>({
    resolver: yupResolver(competenceAnalysisResolver(competenceAnalysisTranslations)),
  });

  useSelectedPersonForm({ form, integrationPlanID, initialValues });

  const dispatch = useAppDispatch();

  const onBeforeExitCheck = () => {
    const currentValues = form.watch();

    if (
      fp.isEqual(JSON.stringify(currentValues), JSON.stringify(initialValues)) &&
      !isCompetenceAnalysisImported
    ) {
      return true;
    }

    if (!personID || integrationPlanID === null) {
      return true;
    }

    dispatch(
      competenceAnalysisActions.updateCompetenceAnalysis({
        personID: personID,
        integrationPlanID: integrationPlanID,
        competenceAnalysis: currentValues,
      })
    );

    dispatch(competenceAnalysisActions.setCompetenceAnalysis(null));

    return true;
  };

  useImperativeHandle(ref, () => ({
    onBeforeExitCheck: onBeforeExitCheck,
  }));

  useEffect(() => {
    if (!integrationPlanID || !personID) {
      return;
    }

    dispatch(
      competenceAnalysisActions.fetchCompetenceAnalysis({ integrationPlanID: integrationPlanID })
    );
  }, [integrationPlanID, personID]);

  useEffect(() => {
    form.trigger().then();
  }, [form.watch()]);

  return (
    <FormProvider {...form}>
      <form className={'flex flex-col gap-1'} onSubmit={form.handleSubmit(() => {})}>
        <Header personID={personID} importDate={initialValues?.importDate} />
        <SocialCommunicativeSkills />
        <MethodologicalSkills />
        <ActivitySkills />
        <PersonalSkills />
        <SuitabilityAndInclination />
        <SchoolCompetencies />
        <OtherCompetencies />
        <Recommendations />
      </form>
    </FormProvider>
  );
});
