import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InternalDifferentiationPosition } from '@/core/enums/testingResult/internalDifferentiationPositionEnum';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IAppliedTestProcedure {
  id: number;
  procedure?: {
    id: number;
    value?: string | null;
  } | null;
  performedTimestamp?: string | null;
  testResult?: string | null;
  performedBy?: {
    id: number;
    value?: string | null;
  } | null;
}

export interface ITestingResultFetchPayload {
  personID: number;
}

export interface IInternalDifferentiationItem {
  id: number;
  description: string;
  name: string;
  isChecked: boolean;
  position: InternalDifferentiationPosition;
  pointsFrom?: number | null;
  pointsTo?: number | null;
  checkValues: number[];
}

export interface IInternalDifferentiation {
  id: number;
  lastCompetenceAnalysisTimestamp: string | null;
  lastInternalDifferentiationUpdateTimestamp: string | null;
  isObsolete: boolean;
  internalDifferentiationList: IInternalDifferentiationItem[];
}

export interface IInternalDifferentiationUpdatePayload {
  personID: number;
  internalDifferentiation: Record<string, boolean>;
  internalDifferentiationItems: IInternalDifferentiationItem[];
}

export interface IInternalDifferentiationItem {
  id: number;
  description: string;
  name: string;
  isChecked: boolean;
  position: InternalDifferentiationPosition;
  pointsFrom?: number | null;
  pointsTo?: number | null;
  checkValues: number[];
}

export interface IInternalDifferentiation {
  id: number;
  lastCompetenceAnalysisTimestamp: string | null;
  lastInternalDifferentiationUpdateTimestamp: string | null;
  isObsolete: boolean;
  internalDifferentiationList: IInternalDifferentiationItem[];
}

export interface ITestingResultState {
  appliedTestProcedures: IAppliedTestProcedure[];
  appliedTestProceduresLock: LoadingStatus;
  internalDifferentiation: IInternalDifferentiation | null;
  isShouldUpdate: boolean;
  internalDifferentiationLock: LoadingStatus;
  updateInternalDifferentiationLock: LoadingStatus;
}

const initialState: ITestingResultState = {
  appliedTestProcedures: [],
  appliedTestProceduresLock: LoadingStatus.NEVER,
  internalDifferentiation: null,
  isShouldUpdate: false,
  internalDifferentiationLock: LoadingStatus.NEVER,
  updateInternalDifferentiationLock: LoadingStatus.NEVER,
};

export const testingResultSlice = createSlice({
  name: 'testingResult',
  initialState,
  reducers: {
    fetchAppliedTestProcedures: (state, action: PayloadAction<ITestingResultFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAppliedTestProcedure: (state, action: PayloadAction<IAppliedTestProcedure[]>) => ({
      ...state,
      appliedTestProcedures: action.payload,
    }),
    setAppliedTestProcedureLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      appliedTestProceduresLock: action.payload,
    }),
    fetchInternalDifferentiation: (state, action: PayloadAction<ITestingResultFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setInternalDifferentiation: (
      state,
      action: PayloadAction<IInternalDifferentiation | null>
    ) => ({
      ...state,
      internalDifferentiation: action.payload,
    }),
    setIsShouldUpdate: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isShouldUpdate: action.payload,
    }),
    setInternalDifferentiationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      internalDifferentiationLock: action.payload,
    }),
    updateInternalDifferentiation: (
      state,
      action: PayloadAction<IInternalDifferentiationUpdatePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateInternalDifferentiationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateInternalDifferentiationLock: action.payload,
    }),

    //Fetch applied test procedure on create/delete/update
    reFetchAppliedTestProcedureList: () => {},
  },
});

export const testingResultReducer = testingResultSlice.reducer;
export const testingResultActions = testingResultSlice.actions;
