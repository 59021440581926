import React from 'react';

export const MoveIconDragged = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='40' height='40' rx='10' fill='#F1F3F8' />
    <rect x='12' y='12' width='6' height='6' rx='1' fill='#3575EF' />
    <rect x='22' y='12' width='6' height='6' rx='1' fill='#3575EF' />
    <rect x='22' y='22' width='6' height='6' rx='1' fill='#3575EF' />
    <rect x='12' y='22' width='6' height='6' rx='1' fill='#3575EF' />
  </svg>
);
