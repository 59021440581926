import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.performanceProfileModals;

//Select Target Catalogs Modal
const selectTargerCatalogModal = createSelector(
  [selector],
  (state) => state.selectTargerCatalogModal
);
const selectColleagueModal = createSelector([selector], (state) => state.selectColleagueModal);

export const performanceProfileModalsSelectors = {
  selectTargerCatalogModal,
  selectColleagueModal,
};
