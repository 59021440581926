import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.diagnostics.itemsICF;

//ICF items selectors
const itemsICF = createSelector([selector], (state) => state.itemsICF);
const itemsICFData = createSelector([selector], (state) => state.itemsICF.data);
const itemsICFStatus = createSelector([selector], (state) => state.itemsICF.status);
const itemsICFTotalCount = createSelector([selector], (state) => state.itemsICF.totalCount);

// Available ICF items selectors
const availableItemsICF = createSelector([selector], (state) => state.availableItemsICF);
const availableItemsICFData = createSelector([selector], (state) => state.availableItemsICF.data);
const availableItemsICFStatus = createSelector(
  [selector],
  (state) => state.availableItemsICF.status
);
const availableIemsICFTotalCount = createSelector(
  [selector],
  (state) => state.availableItemsICF.totalCount
);

export const itemsICFSelectors = {
  itemsICF,
  itemsICFData,
  itemsICFStatus,
  itemsICFTotalCount,

  availableItemsICF,
  availableItemsICFData,
  availableItemsICFStatus,
  availableIemsICFTotalCount,
};
