import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import {
  administrationChecklistActions,
  IAdministrationChecklist,
  IAdministrationChecklistCheckpoint,
  IAdministrationChecklistCheckpointCreatePayload,
  IAdministrationChecklistCheckpointFetchPayload,
  IAdministrationChecklistCheckpointUpdatePayload,
  IAdministrationChecklistFetchPayload,
  IAdministrationChecklistGroup,
  IAdministrationChecklistItemUpdatePayload,
} from '@/core/redux/slices/administrationFunctions/checkList/slice';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import { fileToBase64 } from '@/core/utils/fileUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type ChecklistCheckpointsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/checklist/administration/checkpoint',
  'get',
  '200'
>;

type ChecklistCheckpointsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/checklist/administration/checkpoint',
  'get'
>;

type ChecklistGroupsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/checklist/group',
  'get',
  '200'
>;

type ChecklistResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/checklist/administration',
  'get',
  '200'
>;

type ChecklistRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/checklist/administration',
  'get'
>;

type UpdateChecklistItemRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/checklist/administration/add_or_remove',
  'post'
>;

type ReorderChecklistRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/checklist/administration/reorder',
  'post'
>;

type ReorderCheckpointRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/checklist/administration/checkpoint/reorder',
  'post'
>;

type DropDownRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/dropdown_items', 'get'>;

type DropDownResponse = OASOutput<NormalizeOAS<typeof oas>, '/dropdown_items', 'get', '200'>;

type CreateCheckpointRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/checklist/administration/checkpoint',
  'post'
>;

type UpdateCheckpointRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/checklist/administration/checkpoint',
  'put'
>;

function* fetchChecklistCheckpoints(
  action: PayloadAction<IAdministrationChecklistCheckpointFetchPayload>
): Generator<any, void, ChecklistCheckpointsResponse> {
  const { checklistID } = action.payload;

  try {
    yield put(administrationChecklistActions.setChecklistCheckpointsLock(LoadingStatus.LOADING));

    const request: ChecklistCheckpointsRequest = {
      query: {
        checklist_id: checklistID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/checklist/administration/checkpoint', 'get', request);

    const mappedResponse: IAdministrationChecklistCheckpoint[] =
      response.checkpoints.map<IAdministrationChecklistCheckpoint>((checkpoint) => ({
        id: checkpoint.id,
        name: checkpoint.name,
        days: checkpoint.days,
        correspondingDocument: checkpoint.corresponding_document
          ? {
              id: checkpoint.corresponding_document.id,
              value: checkpoint.corresponding_document.value,
            }
          : null,
        infoDocument: checkpoint.info_document,
        validFrom: toClientDateInput(checkpoint.valid_from) ?? '',
        order: checkpoint.order,
        disabilityTypeID: checkpoint.disability_type?.id,
      }));

    yield put(administrationChecklistActions.setChecklistCheckpoints(mappedResponse));
    yield put(administrationChecklistActions.setChecklistCheckpointsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on administration checklist checkpoints fetching', error);
    yield put(administrationChecklistActions.setChecklistCheckpointsLock(LoadingStatus.NEVER));
  }
}

function* fetchChecklistGroups(): Generator<any, void, ChecklistGroupsResponse> {
  try {
    yield put(administrationChecklistActions.setChecklistGroupsLock(LoadingStatus.LOADING));

    const response = yield call(restCall, '/checklist/group', 'get', authAdd());

    const mappedResponse: IAdministrationChecklistGroup[] =
      response.groups.map<IAdministrationChecklistGroup>((group) => ({
        id: group.id,
        name: group.name,
      }));

    yield put(administrationChecklistActions.setChecklistGroups(mappedResponse));
    yield put(administrationChecklistActions.setChecklistGroupsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on administration checklist groups fetching', error);
    yield put(administrationChecklistActions.setChecklistGroupsLock(LoadingStatus.NEVER));
  }
}

function* fetchChecklist(
  action: PayloadAction<IAdministrationChecklistFetchPayload>
): Generator<any, void, ChecklistResponse> {
  const { checklistGroupID } = action.payload;

  try {
    yield put(administrationChecklistActions.setChecklistLock(LoadingStatus.LOADING));

    const request: ChecklistRequest = {
      query: {
        checklist_group_id: checklistGroupID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/checklist/administration', 'get', request);

    const mappedResponse: IAdministrationChecklist[] =
      response.checklists.map<IAdministrationChecklist>((checklist) => ({
        id: checklist.id,
        name: checklist.name,
        isAdded: checklist.is_added,
        order: checklist.order,
      }));

    yield put(administrationChecklistActions.setChecklist(mappedResponse));
    yield put(administrationChecklistActions.setChecklistLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on administration checklist fetching', error);
    yield put(administrationChecklistActions.setChecklistLock(LoadingStatus.NEVER));
  }
}

function* updateChecklistItem(action: PayloadAction<IAdministrationChecklistItemUpdatePayload>) {
  const { id, isSelected } = action.payload;

  try {
    const request: UpdateChecklistItemRequest = {
      json: {
        id: id,
        is_selected: isSelected,
      },
      ...authAdd(),
    };

    yield call(restCall, '/checklist/administration/add_or_remove', 'post', request);
  } catch (error) {
    console.log('Error on checklist item updating', error);
  }
}

function* reorderChecklist(action: PayloadAction<IAdministrationChecklist[]>) {
  const checklistList = action.payload;

  try {
    const request: ReorderChecklistRequest = {
      json: checklistList.map((checklist, index) => ({
        id: checklist.id,
        order: index,
      })),
      ...authAdd(),
    };

    yield call(restCall, '/checklist/administration/checkpoint/reorder', 'post', request);
  } catch (error) {
    console.log('Error on checklist order updating', error);
  }
}

function* reorderCheckpoint(action: PayloadAction<IAdministrationChecklistCheckpoint[]>) {
  const checkpointList = action.payload;

  try {
    const request: ReorderCheckpointRequest = {
      json: checkpointList.map((checkpoint, index) => ({
        id: checkpoint.id,
        order: index,
      })),
      ...authAdd(),
    };

    yield call(restCall, '/checklist/administration/checkpoint/reorder', 'post', request);
  } catch (error) {
    console.log('Error on checklist order updating', error);
  }
}

function* fetchCorrespondingDocuments(): Generator<any, void, DropDownResponse> {
  try {
    const request: DropDownRequest = {
      query: {
        dropdown_table: DropdownItemsByFetch.FORM_DOCUMENT,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/dropdown_items', 'get', request);
    yield put(administrationChecklistActions.setCorrespondingDocuments(response.dropdown_items));
  } catch (error) {
    console.log('Error on corresponding documents fetch', error);
  }
}

function* fetchDisabilityTypes(): Generator<any, void, DropDownResponse> {
  try {
    const request: DropDownRequest = {
      query: {
        dropdown_table: DropdownItemsByFetch.DISABILITY_TYPE,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/dropdown_items', 'get', request);
    yield put(administrationChecklistActions.setDisabilityTypes(response.dropdown_items));
  } catch (error) {
    console.log('Error on disability types fetch', error);
  }
}

function* createCheckpoint(
  action: PayloadAction<IAdministrationChecklistCheckpointCreatePayload>
): Generator<any, void, any> {
  const { checklistID, formValues } = action.payload;

  const file: string = formValues?.infoDocumentName?.files
    ? yield call(fileToBase64, formValues?.infoDocumentName.files[0])
    : '';

  try {
    const request: CreateCheckpointRequest = {
      json: {
        checklist_id: checklistID,
        valid_from: toBackendDate(formValues?.validFrom) ?? '',
        info_document: formValues?.infoDocumentName?.fileName
          ? {
              name: formValues?.infoDocumentName.fileName,
              content: file,
            }
          : null,
        name: formValues?.name,
        days: formValues?.days,
        corresponding_document_id: formValues?.correspondingDocumentID,
        disability_type_id: formValues?.disabilityTypeID,
      },
      ...authAdd(),
    };

    yield call(restCall, '/checklist/administration/checkpoint', 'post', request);
  } catch (error) {
    console.log('Error on checkpoint creating', error);
  }
}

function* updateCheckpoint(
  action: PayloadAction<IAdministrationChecklistCheckpointUpdatePayload>
): Generator<any, void, any> {
  const { checkpointID, formValues } = action.payload;

  const file: string = formValues?.infoDocumentName?.files
    ? yield call(fileToBase64, formValues?.infoDocumentName.files[0])
    : '';

  try {
    const request: UpdateCheckpointRequest = {
      json: {
        id: checkpointID,
        valid_from: toBackendDate(formValues?.validFrom) ?? '',
        info_document: formValues?.infoDocumentName?.fileName
          ? {
              name: formValues?.infoDocumentName.fileName,
              content: file,
            }
          : null,
        name: formValues?.name,
        days: formValues?.days,
        corresponding_document_id: formValues?.correspondingDocumentID,
        disability_type_id: formValues?.disabilityTypeID,
      },
      ...authAdd(),
    };

    yield call(restCall, '/checklist/administration/checkpoint', 'put', request);
  } catch (error) {
    console.log('Error on disability types fetch', error);
  }
}

export const administrationCheckListSagas = [
  takeLatest(administrationChecklistActions.fetchChecklistCheckpoints, fetchChecklistCheckpoints),
  takeLatest(administrationChecklistActions.fetchChecklistGroups, fetchChecklistGroups),
  takeLatest(administrationChecklistActions.fetchChecklist, fetchChecklist),
  takeLatest(administrationChecklistActions.updateChecklistItem, updateChecklistItem),
  takeLatest(administrationChecklistActions.reorderChecklist, reorderChecklist),
  takeLatest(administrationChecklistActions.reorderCheckpoint, reorderCheckpoint),
  takeLatest(
    administrationChecklistActions.fetchCorrespondingDocuments,
    fetchCorrespondingDocuments
  ),
  takeLatest(administrationChecklistActions.fetchDisabilityTypes, fetchDisabilityTypes),
  takeLatest(administrationChecklistActions.createCheckpoint, createCheckpoint),
  takeLatest(administrationChecklistActions.updateCheckpoint, updateCheckpoint),
];
