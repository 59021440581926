import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IMeasuresAppointmentsListFetchPayload {
  personID: number;
  measureID: number;
}

export interface IActivityPlanningMeasureAppointment {
  id: number;
  appointmentID: number;
  freePlaces: string;
  day: string;
  appointmentStartDate?: string | null;
  appointmentStartTime?: string | null;
  appointmentEndTime?: string | null;
  appointmentEndDate?: string | null;
  hours?: string | null;
  location?: string | null;
  responsibleUser: string;
  implementationAid?: string | null;
  isCompleted: boolean;
}

export interface IActivityPlanningMeasuresAppointmentsList {
  measuresAppointmentsList: IActivityPlanningMeasureAppointment[];
  measuresAppointmentsListLock: LoadingStatus;
}

const initialState: IActivityPlanningMeasuresAppointmentsList = {
  measuresAppointmentsList: [],
  measuresAppointmentsListLock: LoadingStatus.NEVER,
};

export const activityPlanningAppointmentsMeasuresList = createSlice({
  name: 'measuresAppointmentsList',
  initialState,
  reducers: {
    fetchMeasuresAppointmentsList: (
      state,
      action: PayloadAction<IMeasuresAppointmentsListFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setMeasuresAppointmentsList: (
      state,
      action: PayloadAction<IActivityPlanningMeasureAppointment[]>
    ) => ({
      ...state,
      measuresAppointmentsList: action.payload,
    }),
    setMeasuresAppointmentsListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      measuresAppointmentsListLock: action.payload,
    }),
  },
});

export const activityPlanningAppointmentsMeasuresListReducers =
  activityPlanningAppointmentsMeasuresList.reducer;
export const activityPlanningAppointmentsMeasuresListActions =
  activityPlanningAppointmentsMeasuresList.actions;
