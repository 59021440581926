import { PayloadAction } from '@reduxjs/toolkit';
import { put, take, takeLeading } from 'redux-saga/effects';

import { appActions, IAppState } from '@/core/redux/slices/app/appSlice';
import { authActions } from '@/core/redux/slices/auth/authSlice';
import { parametrsList } from '@/core/utils/parametrsList';

import { parametersActions } from '../parameters/parametersSlice';

function* setInitialization(action: PayloadAction<IAppState | null>): any {
  const accessToken = action.payload?.accessToken;
  if (accessToken) {
    yield put(authActions.setAToken(accessToken));
    yield put(authActions.fetchSelfProfile());
    yield put(
      parametersActions.fetchParameters(parametrsList)
    );
    yield take(authActions.setUser);
  }
  yield put(appActions.setAppInitializationSuccess({ isAppInitializationInProgress: false }));
}

export const appSagas = [takeLeading(appActions.setAppInitialization, setInitialization)];
