import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InternalDifferentiationHeader } from '@/components/functions/TestingResult/InternalDifferentiation/InternalDifferentiationHeader/InternalDifferentiationHeader';
import { InternalDifferentiationList } from '@/components/functions/TestingResult/InternalDifferentiation/InternalDifferentiationList/InternalDifferentiationList';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { testingResultSelectors } from '@/core/redux/slices/functions/testingResult/selectors';
import { testingResultActions } from '@/core/redux/slices/functions/testingResult/testingResultSlice';
import { LoadingStatus } from '@/types/loadingStatus';

export const InternalDifferentiation: React.FC = () => {
  const { t: internalDifferentiationTranslations } = useTranslation('testingResult', {
    keyPrefix: 'testProcedures.internalDifferentiation',
  });

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const selectedPersonID = selectedPerson?.id;

  const internalDifferentiation = useAppSelector(testingResultSelectors.internalDifferentiation);
  const internalDifferentiationLock = useAppSelector(
    testingResultSelectors.internalDifferentiationLock
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(testingResultActions.setInternalDifferentiation(null));
    dispatch(testingResultActions.fetchInternalDifferentiation({ personID: selectedPersonID }));
  }, [selectedPersonID]);
  
  return (
    <div className={'p-2 flex flex-col gap-3'}>
      <Skeleton trigger={internalDifferentiationLock === LoadingStatus.LOADING}>
        <Typography type={'H3'}>{internalDifferentiationTranslations('title.label')}</Typography>
        <InternalDifferentiationHeader
          personID={selectedPersonID}
          isInternalDifferentiationObsolete={internalDifferentiation?.isObsolete}
          lastInternalDifferentiationUpdateTimestamp={
            internalDifferentiation?.lastInternalDifferentiationUpdateTimestamp
          }
          lastCompetenceAnalysisTimestamp={internalDifferentiation?.lastCompetenceAnalysisTimestamp}
        />
        <InternalDifferentiationList
          internalDifferentiationItems={internalDifferentiation?.internalDifferentiationList}
          personID={selectedPersonID}
        />
      </Skeleton>
    </div>
  );
};
