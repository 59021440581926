import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { AsterikIcon } from '@/components/Icons/AsterikIcon';
import { Typography } from '@/components/Typography/Typography';
import { PasswordField } from '@/components/views/auth/LoginPage/PasswordField/PasswordField';
import { PasswordResetResolvers } from '@/components/views/auth/PasswordResetPage/helpers/passwordResetFormResolvers';
import { IPasswordResetFormValues } from '@/components/views/auth/PasswordResetPage/helpers/passwordResetFormValues';
import { AUTH_URL } from '@/core/constants/urls';
import { useResetPasswordMutation } from '@/services/graphql/system/graphql';

export const PasswordResetPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [sendResetData, response] = useResetPasswordMutation({
    errorPolicy: 'none',
    onError: () => {},
    onCompleted: async () => {
      navigate(AUTH_URL);
    },
  });

  const form = useForm<IPasswordResetFormValues>({
    resolver: yupResolver(PasswordResetResolvers),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const handleAuth = async (data: IPasswordResetFormValues) => {
    await sendResetData({ variables: { ...data, token: searchParams.get('token') as string } });
  };

  return (
    <div className='h-screen flex justify-center items-center flex-col'>
      <div className='flex flex-col pb-5 mb-5 text-center'>
        <Typography color='Primary' type='H1'>
          Geben Sie ein neues Passwort ein
        </Typography>
      </div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleAuth)} className='flex w-3/4 flex-col mb-5'>
          <PasswordField control={form.control.register('password')} errorMessage='Passwort' />
          <PasswordField
            control={form.control.register('confirmPassword')}
            errorMessage='Passwort wiederholen'
          />
          {fp.get('error.message', response)}
          <div className='text-center my-3 flex justify-center'>
            <Button buttonStyle={'WithIcon'} className={'gap-3'} type={'submit'}>
              <Typography>Geben Sie ein neues Passwort ein</Typography>
              <AsterikIcon />
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
