import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { DevelopmentReportActionsBar } from '@/components/functions/DevelopmentReport/DevelopmentReportActionsBar/DevelopmentReportActionsBar';
import { reviewSupportDefaultValues } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/ReviewSupport/helper/reviewSupportForm';
import { ReviewSupportHeader } from '@/components/functions/DevelopmentReport/DevelopmentReportForms/ReviewSupport/ReviewSupportHeader/ReviewSupportHeader';
import { useDevelopmentReport } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReport';
import { IDevelopmentReportForm } from '@/components/functions/DevelopmentReport/hooks/useDevelopmentReportForm';
import { PlusIcon } from '@/components/Icons/PlusIcon';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { DevelopmentReportForms } from '@/core/enums/functions/developmentReport/developmentReportFormsEnum';
import { DevelopmentReportReviewTypes } from '@/core/enums/functions/developmentReport/developmentReportReviewTypesEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { developmentReportReviewSupportSelectors } from '@/core/redux/slices/functions/developmentReport/developmentReportReviewSupportSlice/selectors';
import { developmentReportReviewSupportActions } from '@/core/redux/slices/functions/developmentReport/developmentReportReviewSupportSlice/slice';
import { getFirstFormError } from '@/core/utils/formUtils';
import { LoadingStatus } from '@/types/loadingStatus';

function mapReviewToFormItems(
  index: number,
  reviewOptions: IFormRadioButtonGroupItem[]
): FormItem<IDevelopmentReportForm> | FormGroup<IDevelopmentReportForm> {
  return {
    fieldsContainerClassName: 'grid grid-cols-[repeat(19,_1fr)] gap-3 items-center',
    className: 'w-full',
    fields: [
      {
        name: `${DevelopmentReportForms.Review}.reviews.${index}.goal`,
        type: 'input',
        className: 'col-span-8',
      },
      {
        name: `${DevelopmentReportForms.Review}.reviews.${index}.selectedReview`,
        type: 'radio-button-group',
        className: 'col-span-3',
        radioButtonGroupOptions: {
          options: reviewOptions,
          className: 'flex flex-row gap-2 w-full',
          containerClassName: 'grid grid-cols-3 gap-3 w-full',
          errorClassName: 'hidden',
        },
      },
      {
        name: `${DevelopmentReportForms.Review}.reviews.${index}.comment`,
        type: 'input',
        className: 'col-span-8',
      },
    ],
  };
}

export const DevelopmentReportReviewSupport: React.FC = () => {
  const [currentFormState, setCurrentFormState] = useState<
    (FormItem<IDevelopmentReportForm> | FormGroup<IDevelopmentReportForm>)[]
  >([]);

  const { developmentReportID } = useDevelopmentReport();
  const { selectedPersonID } = useSelectedPerson();

  const {
    reset,
    formState: { errors, isDirty },
    watch,
    setValue,
  } = useFormContext<IDevelopmentReportForm>();
  const dispatch = useAppDispatch();

  const developmentReportReviewSupport = useAppSelector(
    developmentReportReviewSupportSelectors.developmentReportReviewSupport
  );
  const developmentReportReviewSupportLock = useAppSelector(
    developmentReportReviewSupportSelectors.developmentReportReviewSupportLock
  );
  const importedDevelopmentReportReviewSupport = useAppSelector(
    developmentReportReviewSupportSelectors.importedDevelopmentReportReviewSupport
  );
  const importedDevelopmentReportReviewSupportLock = useAppSelector(
    developmentReportReviewSupportSelectors.importedDevelopmentReportReviewSupportLock
  );

  useEffect(() => {
    if (!developmentReportID) {
      return;
    }

    dispatch(
      developmentReportReviewSupportActions.fetchDevelopmentReportReviewSupport({
        developmentReportID: developmentReportID,
      })
    );

    return () => {
      dispatch(
        developmentReportReviewSupportActions.setDevelopmentReportReviewSupport({ reviews: [] })
      );

      const values = fp.cloneDeep(watch());
      reset({
        ...values,
        [DevelopmentReportForms.Review]: reviewSupportDefaultValues,
      });
    };
  }, [developmentReportID]);

  const reviewOptions: IFormRadioButtonGroupItem[] = [
    {
      value: DevelopmentReportReviewTypes.Yes,
      component: <div />,
    },
    {
      value: DevelopmentReportReviewTypes.Partially,
      component: <div />,
    },
    {
      value: DevelopmentReportReviewTypes.No,
      component: <div />,
    },
  ];

  useEffect(() => {
    if (!developmentReportReviewSupport) {
      return;
    }

    const newFormState = developmentReportReviewSupport.reviews.map((_, index) =>
      mapReviewToFormItems(index, reviewOptions)
    );
    setCurrentFormState(newFormState);
  }, [developmentReportReviewSupport]);

  useEffect(() => {
    if (!importedDevelopmentReportReviewSupport) {
      return;
    }

    setValue(DevelopmentReportForms.Review, importedDevelopmentReportReviewSupport);

    const newFormState = importedDevelopmentReportReviewSupport.reviews.map((_, index) =>
      mapReviewToFormItems(index, reviewOptions)
    );
    setCurrentFormState(newFormState);

    dispatch(developmentReportReviewSupportActions.setImportedDevelopmentReportReviewSupport(null));
  }, [importedDevelopmentReportReviewSupport]);

  const handleAddField = () => {
    const newField: FormItem<IDevelopmentReportForm> | FormGroup<IDevelopmentReportForm> = {
      fieldsContainerClassName: 'grid grid-cols-[repeat(19,_1fr)] gap-3 items-center',
      className: 'w-full',
      fields: [
        {
          name: `${DevelopmentReportForms.Review}.reviews.${currentFormState.length}.goal`,
          type: 'input',
          className: 'col-span-8',
        },
        {
          name: `${DevelopmentReportForms.Review}.reviews.${currentFormState.length}.selectedReview`,
          type: 'radio-button-group',
          className: 'col-span-3',
          radioButtonGroupOptions: {
            options: reviewOptions,
            className: 'flex flex-row gap-2 w-full',
            containerClassName: 'grid grid-cols-3 gap-3 w-full',
            errorClassName: 'hidden',
          },
        },
        {
          name: `${DevelopmentReportForms.Review}.reviews.${currentFormState.length}.comment`,
          type: 'input',
          className: 'col-span-8',
        },
      ],
    };

    setCurrentFormState((prev) => [...prev, newField]);
  };

  const errorMessage = getFirstFormError<IDevelopmentReportForm>(errors);

  const handleImport = () => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      developmentReportReviewSupportActions.fetchImportedDevelopmentReportReviewSupport({
        personID: selectedPersonID,
      })
    );
  };

  return (
    <Skeleton trigger={developmentReportReviewSupportLock === LoadingStatus.LOADING}>
      <div className={'flex flex-col gap-3 p-3 h-full flex-1'}>
        <ReviewSupportHeader />
        <FormRender list={currentFormState} className={'py-4 w-full gap-2'} />

        <div className={'flex-1'} />
        {errorMessage && <Typography color={'Error'}>{errorMessage}</Typography>}
        <DevelopmentReportActionsBar
          importOptions={{
            handleImport,
            loadingStatus: importedDevelopmentReportReviewSupportLock,
          }}
          errorsOptions={{
            errors: errors,
            formKey: DevelopmentReportForms.Review,
          }}
          resetOptions={{
            onReset: reset,
            isDirty: isDirty,
          }}
          customOptions={{
            component: (
              <Button
                onClick={handleAddField}
                buttonVariant={'Default'}
                buttonStyle={'Circle'}
                className={'opacity-50 hover:opacity-100'}
              >
                <PlusIcon />
              </Button>
            ),
          }}
        />
      </div>
    </Skeleton>
  );
};
