import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import { InternalDifferentiationPosition } from '@/core/enums/testingResult/internalDifferentiationPositionEnum';
import { IInternalDifferentiationItem } from '@/core/redux/slices/functions/testingResult/testingResultSlice';

interface IInternalDifferentiationListItem extends IInternalDifferentiationItem {
  onChange: (id: string[]) => void;
  bgColor?: string;
}

export const InternalDifferentiationListItem: React.FC<IInternalDifferentiationListItem> = ({
  id,
  name,
  description,
  bgColor,
  position,
  checkValues,
  onChange,
  pointsFrom,
  pointsTo,
}) => {
  const { t: internalDifferentiationTranslations } = useTranslation('testingResult', {
    keyPrefix: 'testProcedures.internalDifferentiation.table.internalDifferentiationItem',
  });

  const handleChange = () => {
    const selectedIDs = checkValues.map((value) => value.toString());
    onChange(selectedIDs);
  };

  return (
    <div
      className={'grid grid-cols-12 gap-3 items-center py-1'}
      style={{
        backgroundColor: bgColor,
      }}
    >
      {position === InternalDifferentiationPosition.Zero ? (
        <>
          <div className={'col-span-8 flex flex-col'}>
            <div className={'flex flex-row gap-2'}>
              <Typography type={'16-M'}>{name}</Typography>
              <Typography>
                {internalDifferentiationTranslations('scale.label', {
                  from: pointsFrom,
                  to: pointsTo,
                })}
              </Typography>
            </div>
            <Typography>{description}</Typography>
          </div>
          <Checkbox
            name={id.toString()}
            onClick={handleChange}
            preventDefault={true}
            className={'justify-self-center'}
          />
          <div />
          <Typography className={'col-span-2'}>{name}</Typography>
        </>
      ) : (
        <>
          <div className={'col-span-8'} />
          <div />
          <Checkbox
            name={id.toString()}
            onClick={handleChange}
            preventDefault={true}
            className={'justify-self-center'}
          />
          <Typography className={'col-span-2'}>{name}</Typography>
        </>
      )}
    </div>
  );
};
