import React, { useEffect, useMemo, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import fp from 'lodash/fp';

import { useFetchedWidgets } from '@/components/views/common/Dashboard/DashboardDnD/hooks/useFetchedWidgets';
import { DragColumn } from '@/components/views/common/Dashboard/DragColumn/DragColumn';
import { WidgetContainer } from '@/components/views/common/Dashboard/WidgetContainer/WidgetContainer';

const leftColumn = '7901DDEB-D5E2-482B-A77F-9FAAB018EA38';
const rightColumn = '03E38766-6A91-450F-9F2D-CE03CB0CB425';

const columns = [leftColumn, rightColumn];

export const DashboardDnD: React.FC = () => {
  const { widgets, onWidgetPositionUpdate } = useFetchedWidgets();

  const [items, setItems] = useState<any>([]);
  const [activeId, setActiveId] = useState<string | number | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const overlayWidget = useMemo(() => {
    if (!activeId || fp.isEmpty(widgets)) {
      return null;
    }
    return fp.find(['id', activeId], widgets);
  }, [activeId, widgets]);

  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => fp.find(['id', id], items[key]));
  };

  const groupWidgets = (ogWidgets: any) =>
    fp.groupBy(
      'column',
      ogWidgets
        .map((item: any) => ({
          title: item.widgets_id.name,
          id: item.id,
          widgetName: item.widgets_id.name,
          icon: item.widgets_id.icon.filename_disk,
          column: item.column_id ?? rightColumn,
          order: item.order_number ?? 0,
        }))
        .sort((a: any, b: any) => a.order - b.order)
    );

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over || { id: '' };

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setItems((prev: any) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      const activeIndex = fp.findIndex(['id', id], activeItems);
      const overIndex = fp.findIndex(['id', overId], overItems);

      let newIndex;

      if (overId in prev) {
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          fp.get('rect.current.translated.top', active) > over.rect.top + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [...prev[activeContainer].filter((item: any) => item?.id !== active.id)],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  };
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over || { id: '' };

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = fp.findIndex(['id', id], items[activeContainer]);
    const overIndex = fp.findIndex(['id', overId], items[overContainer]);

    let newItems = { ...items };

    if (activeIndex !== overIndex) {
      newItems = {
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      };
      setItems(newItems);
    }
    onWidgetPositionUpdate(newItems);

    setActiveId(null);
  };
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  };

  useEffect(() => {
    const groupedItems = groupWidgets(widgets.filter((item: any) => item.is_visible));
    if (!groupedItems[leftColumn]) {
      groupedItems[leftColumn] = [];
    }
    if (!groupedItems[rightColumn]) {
      groupedItems[rightColumn] = [];
    }
    setItems(groupedItems);
  }, [widgets]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      {columns.map((column) => (
        <DragColumn activeId={activeId} key={column} column={column} items={items[column] ?? []} />
      ))}
      <DragOverlay>
        {overlayWidget && (
          <WidgetContainer
            icon={overlayWidget.widgets_id.icon?.filename_disk}
            title={overlayWidget.widgets_id.name}
            widgetName={overlayWidget.widgets_id.name}
            {...overlayWidget}
            onOverlay
          />
        )}
      </DragOverlay>
    </DndContext>
  );
};
