import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { FundingCycleSection } from '@/components/functions/FundingCycle/FundingCycleSection/FundingCycleSection';
import { FundingCycleSectionHeader } from '@/components/functions/FundingCycle/FundingCycleSectionHeader/FundingCycleSectionHeader';
import { GoalsWishes } from '@/components/functions/FundingCycle/GoalsWishes/GoalsWishes';
import { EditTextIcon } from '@/components/Icons/EditTextIcon';
import { TrashIcon } from '@/components/Icons/TrashIcon';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { participationPlanActions } from '@/core/redux/slices/functions/fundingCycle/participationPlan/participationPlanSlice';
import { participationPlanSelectors } from '@/core/redux/slices/functions/fundingCycle/participationPlan/selectors';
import { participationPlanModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/participationPlan/participationPlanModalSlice';
import { colorTypes } from '@/styles/types';

export const ParticipationPlan: React.FC = () => {
  const { t: participationPlanTranslations } = useTranslation('participationPlan');
  const dispatch = useAppDispatch();

  const participantPlans = useAppSelector(participationPlanSelectors.participantPlans);
  const selectedParticipantPlanID = useAppSelector(
    participationPlanSelectors.selectedParticipantPlan
  );
  const { selectedPersonID } = useSelectedPerson();

  const isGoalsWishesEditable = selectedParticipantPlanID === participantPlans[0]?.id

  const handleOpenDisplayParticipationPlan = (participationPlanID: number) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      participationPlanModalActions.openDisplayParticipationPlannModal({
        personID: selectedPersonID,
        participationPlanID: participationPlanID,
      })
    );
  };

  const handleDeleteParticipationPlan = (participationPlanID: number) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      participationPlanActions.deleteParticipationPlan({
        personID: selectedPersonID,
        participationPlanID: participationPlanID,
      })
    );
  };

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      participationPlanActions.fetchParticipantPlans({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID]);

  useEffect(() => {
    return () => {
      dispatch(participationPlanActions.setSelectedParticipanPlan(null));
    };
  }, []);

  const columns: ITableColumn[] = [
    {
      header: participationPlanTranslations('columns.name.label'),
      accessor: 'name',
      className: 'truncate',
    },
    {
      header: participationPlanTranslations('columns.caretaker.label'),
      accessor: 'caretaker',
    },
    {
      header: participationPlanTranslations('columns.socialServiceWorker.label'),
      accessor: 'socialServiceWorker',
    },
    {
      header: participationPlanTranslations('columns.startDate.label'),
      accessor: 'startDate',
    },
    {
      header: '',
      accessor: '',
      isSortable: false,
      template: (_, row) => {
        return (
          <div className={'flex flex-row gap-[20px] items-center'}>
            {participantPlans[0].id === row.id && (
              <>
                <Button
                  buttonVariant={'Icon'}
                  buttonStyle={'Icon'}
                  className={`text-${colorTypes.Grey}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenDisplayParticipationPlan(row.id);
                  }}
                >
                  <EditTextIcon className={'w-[24px] h-[24px]'} />
                </Button>
                <Button
                  buttonVariant={'Icon'}
                  buttonStyle={'Icon'}
                  className={`text-${colorTypes.Grey}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteParticipationPlan(row.id);
                  }}
                >
                  <TrashIcon className={'w-[24px] h-[24px]'} />
                </Button>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const ParticipationPlanControls = (
    <div>
      <Button
        onClick={() => handleOpenDisplayParticipationPlan(0)}
        className='text-sm h-9 px-5 py-2.5 leading-[140%]'
      >
        {participationPlanTranslations('buttons.add.label')}
      </Button>
    </div>
  );

  return (
    <div className='h-full flex flex-col gap-4 py-5 px-8'>
      <FundingCycleSection
        header={
          <FundingCycleSectionHeader
            controls={ParticipationPlanControls}
            title={participationPlanTranslations('title.label')}
          />
        }
        className={'basis-6/12 flex-grow'}
      >
        <Table
          data={participantPlans}
          columns={columns}
          singleSelect
          initialSelectedState={[selectedParticipantPlanID]}
          onSingleSelectChange={(selected) =>
            dispatch(participationPlanActions.setSelectedParticipanPlan(selected))
          }
        />
      </FundingCycleSection>
      <GoalsWishes
        isEditable={isGoalsWishesEditable}
        participationPlanID={selectedParticipantPlanID}
        className='basis-6/12 flex-grow'
      />
    </div>
  );
};
