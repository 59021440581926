import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IVocationalTrainingAreaForm } from '@/components/functions/VocationalTrainingArea/VocationalTrainingArea';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { VocationalTrainingAreaType } from '@/core/enums/functions/vocationalTrainingArea/vocationalTrainingAreaTypesEnum';
import { isEnumValue } from '@/core/utils/enumUtils';

export const VocationalTrainingAreaSelectType: React.FC = () => {
  const { t: vocationalTrainingAreaTranslations } = useTranslation('vocationalTrainingArea', {
    keyPrefix: 'filters.type',
  });

  const formContext = useFormContext<IVocationalTrainingAreaForm>();

  const selectValues: ISelectItem[] = [
    {
      id: String(VocationalTrainingAreaType.Default),
      component: (
        <Typography>{vocationalTrainingAreaTranslations('selectOptions.all.label')}</Typography>
      ),
      showCheckIcon: false,
    },
    {
      id: String(VocationalTrainingAreaType.G),
      component: (
        <Typography>{vocationalTrainingAreaTranslations('selectOptions.g.label')}</Typography>
      ),
      showCheckIcon: false,
    },
    {
      id: String(VocationalTrainingAreaType.P),
      component: (
        <Typography>{vocationalTrainingAreaTranslations('selectOptions.p.label')}</Typography>
      ),
      showCheckIcon: false,
    },
    {
      id: String(VocationalTrainingAreaType.S),
      component: (
        <Typography>{vocationalTrainingAreaTranslations('selectOptions.s.label')}</Typography>
      ),
      showCheckIcon: false,
    },
  ];

  const handleChange = (id: string) => {
    if (isEnumValue(VocationalTrainingAreaType, id)) {
      formContext.setValue('selectedType', id);
    }
  };

  return (
    <div className={'flex flex-row gap-3 items-center'}>
      <Typography>{vocationalTrainingAreaTranslations('label')}</Typography>
      <Select
        values={selectValues}
        initialValueID={VocationalTrainingAreaType.Default}
        onChange={handleChange}
      />
    </div>
  );
};
