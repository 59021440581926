import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { dashboardSelectors } from '@/core/redux/slices/dashboard/selectors';

export const TotalActivitiesCount: React.FC = () => {
  const { t: activitiesTotalCountTranslations } = useTranslation('activitiesWidget', {
    keyPrefix: 'activitiesContentTable.activitiesTotalCount',
  });

  const activitiesTotalCount = useAppSelector(dashboardSelectors.activitiesTotalCount);

  return (
    <div className={'flex flex-row items-center justify-center gap-2 self-start mt-3'}>
      <Typography type={'16-R'}>{activitiesTotalCountTranslations('label')}</Typography>
      <Typography type={'16-M'}>{activitiesTotalCount}</Typography>
    </div>
  );
};
