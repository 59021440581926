import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface ICodeICD {
  id: string;
  code: string | null | undefined;
  description: string | null | undefined;
}

export interface IUpdateCodeICD {
  code: string;
  isSelected: boolean;
}

export interface IAvailableCodesICD extends ICodeICD {
  isSelected: boolean;
}

export interface ICodesICDState {
  codesICD: {
    status: LoadingStatus;
    data: ICodeICD[];
    totalCount: number;
  };
  availableCodesICD: {
    status: LoadingStatus;
    data: IAvailableCodesICD[];
    totalCount: number;
  };
}

export interface ICodesICDFetchPayload {
  personID: number;
  limit?: number;
  offset?: number;
  page?: number;
}

export interface IAvailableCodesICDFetchPayload extends ICodesICDFetchPayload {
  search?: string;
  isSelected?: boolean;
}

export interface ICodesICDUpdatePayload {
  personID: number;
  updatedCodes: IUpdateCodeICD[];
}

const initialState: ICodesICDState = {
  codesICD: {
    status: LoadingStatus.NEVER,
    data: [],
    totalCount: 0,
  },
  availableCodesICD: {
    status: LoadingStatus.NEVER,
    data: [],
    totalCount: 0,
  },
};

export const codesICDSlice = createSlice({
  name: 'codesICD',
  initialState,
  reducers: {
    //ICD codes
    fetchCodesICD: (state, action: PayloadAction<ICodesICDFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setCodesICDData: (state, action: PayloadAction<ICodeICD[]>) => ({
      ...state,
      codesICD: {
        ...state.codesICD,
        data: action.payload,
      },
    }),
    setCodesICDStatus: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      codesICD: {
        ...state.codesICD,
        status: action.payload,
      },
    }),
    setCodesICDToatalCount: (state, action: PayloadAction<number>) => ({
      ...state,
      codesICD: {
        ...state.codesICD,
        totalCount: action.payload,
      },
    }),
    //Available ICD Codes
    fetchAvailableICDCodes: (state, action: PayloadAction<IAvailableCodesICDFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAvailableICDCodesData: (state, action: PayloadAction<IAvailableCodesICD[]>) => ({
      ...state,
      availableCodesICD: {
        ...state.availableCodesICD,
        data: action.payload,
      },
    }),
    setAvailableICDCodesStatus: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      availableCodesICD: {
        ...state.availableCodesICD,
        status: action.payload,
      },
    }),
    setAvailableICDCodesTotalCount: (state, action: PayloadAction<number>) => ({
      ...state,
      availableCodesICD: {
        ...state.availableCodesICD,
        totalCount: action.payload,
      },
    }),
    //Update ICD Codes
    updateCodesICD: (state, action: PayloadAction<ICodesICDUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const codesICDReducer = codesICDSlice.reducer;
export const codesICDActions = codesICDSlice.actions;
