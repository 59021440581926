import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';

import { administrationCheckpointFormValidator } from '@/components/administartionFunctions/checklist/AdministrationCheckpointList/hooks/administrationCheckpointFormValidator';
import { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { Typography } from '@/components/Typography/Typography';
import { UploadFileFormField } from '@/components/UploadFileZone/UploadFileZone';
import { useAppSelector } from '@/core/redux/hooks';
import { administrationCheckListSelectors } from '@/core/redux/slices/administrationFunctions/checkList/selectors';

export interface IAdministrationCheckpointForm {
  name: string;
  days: number;
  correspondingDocumentID?: number | null;
  infoDocumentName?: UploadFileFormField | null;
  validFrom: string;
  disabilityTypeID?: number | null;
}

const ADMINISTRATION_CHECKPOINT_FORM_DEFAULT_VALUES: IAdministrationCheckpointForm = {
  name: '',
  days: 0,
  correspondingDocumentID: null,
  infoDocumentName: {},
  validFrom: '',
  disabilityTypeID: null,
};

const buildAdministrationCheckpointForm = (
  correspondingDocuments: any[],
  disabilityTypes: any[],
  isDisabilityExist: boolean,
  translations: TFunction<'administrationChecklist', undefined>
): (FormItem<IAdministrationCheckpointForm> | FormGroup<IAdministrationCheckpointForm>)[] => [
  {
    className: 'w-full',
    fieldsContainerClassName: 'w-full grid grid-cols-12 gap-2',
    fields: [
      {
        type: 'input',
        name: 'name',
        className: 'col-span-10 grid grid-cols-[30%,_65%] gap-3 items-center w-full',
        label: translations('checkpointList.fields.name.label'),
      },
      {
        type: 'input',
        name: 'days',
        className: 'col-span-2',
      },
    ],
  },
  {
    className: 'grid grid-cols-[25%,_75%] gap-3 items-center w-full',
    type: 'select',
    name: 'correspondingDocumentID',
    options: correspondingDocuments,
    label: translations('checkpointList.fields.correspondingDocument.label'),
  },
  {
    className: 'grid grid-cols-[25%,_75%] gap-3 items-center',
    type: 'upload-file',
    name: 'infoDocumentName',
    label: translations('checkpointList.fields.infoDocument.label'),
  },
  {
    className: 'w-full',
    fieldsContainerClassName: 'flex flex-row gap-3',
    fields: [
      {
        className: 'flex flex-row gap-3 items-center',
        type: 'datepicker',
        name: 'validFrom',
        label: translations('checkpointList.fields.validFrom.label'),
      },
      {
        className: 'flex flex-row gap-3 items-center',
        condition: isDisabilityExist,
        type: 'select',
        name: 'disabilityTypeID',
        options: disabilityTypes,
        label: translations('checkpointList.fields.relevantFor.label'),
      },
      {
        className: 'flex flex-row items-center w-fit',
        condition: isDisabilityExist,
        type: 'custom',
        customComponent: (
          <Typography>{translations('checkpointList.fields.disabilityType.label')}</Typography>
        ),
        name: undefined,
      },
    ],
  },
];

export function useAdministrationCheckpointForm() {
  const { t: administrationChecklistTranslations } = useTranslation('administrationChecklist');

  const form = useForm<IAdministrationCheckpointForm>({
    resolver: yupResolver(
      administrationCheckpointFormValidator(administrationChecklistTranslations)
    ),
  });

  const correspondingDocuments = useAppSelector(
    administrationCheckListSelectors.correspondingDocuments
  );
  const disabilityTypes = useAppSelector(administrationCheckListSelectors.disabilityTypes);

  const mappedCorrespondingDocuments = correspondingDocuments?.map((item) => ({
    id: String(item.id),
    name: item.value,
  }));

  const mappedDisabilityTypes = disabilityTypes?.map((item) => ({
    id: String(item.id),
    name: item.value,
  }));

  const isDisabilityExist = Boolean(form.watch('disabilityTypeID'));

  return {
    form,
    formFields: buildAdministrationCheckpointForm(
      mappedCorrespondingDocuments,
      mappedDisabilityTypes,
      isDisabilityExist,
      administrationChecklistTranslations
    ),
    defaultValues: ADMINISTRATION_CHECKPOINT_FORM_DEFAULT_VALUES,
  };
}
