import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IAddressData {
  address?: string | null;
  addresseeType?: string | null;
  cellPhoneNumber?: string | null;
  email?: string | null;
  isLegalGuardian?: boolean | null;
  name?: string | null;
  telephoneNumber?: string | null;
  location?: string | null;
  id: number;
  canDelete: boolean;
}

export interface IAddressInfo {
  address?: string | null;
  cellPhoneNumber?: string | null;
  email?: string | null;
  isLegalGuardian?: boolean | null;
  name?: string | null;
  telephoneNumber?: string | null;
  location?: string | null;
  surname?: string | null;
  notes?: string | null;
  addresseeTypeId?: string | null;
  plz?: string | null;
  fax?: string | null;
  salutationId?: number;
}

export interface IAddressType {
  name?: string | null;
  is_checked?: boolean;
  id: number;
}

export interface IAddressDataFetchPayload {
  personID: number;
}

export interface IAddressInfoFetchPayload {
  addressID: number;
  personID: number;
}

export interface IAddressUpdatePayload {
  personID: number;
  addressID: number;
  addressInfo: IAddressInfo;
}

export interface IAddressCreatePayload {
  personID: number;
  addressInfo: IAddressInfo;
}

export interface IAddressDeletePayload {
  personID: number;
  addressID: number;
}

export interface IAddressesState {
  addressData: IAddressData[];
  addressDataLock: LoadingStatus;
  addressInfo: IAddressInfo | null;
  addressTypes: IAddressType[];
  addressInfoLock: LoadingStatus;
  updateAddressLock: LoadingStatus;
  createAddressLock: LoadingStatus;
  deleteAddressLock: LoadingStatus;
}

const initialState: IAddressesState = {
  //addresses
  addressData: [],
  addressDataLock: LoadingStatus.NEVER,
  //info
  addressInfo: null,
  addressTypes: [],
  addressInfoLock: LoadingStatus.NEVER,
  //update
  updateAddressLock: LoadingStatus.NEVER,
  //create
  createAddressLock: LoadingStatus.NEVER,
  //delete
  deleteAddressLock: LoadingStatus.NEVER,
};

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    //addresses
    fetchAddressData: (state, action: PayloadAction<IAddressDataFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAddressDataLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      addressDataLock: action.payload,
    }),
    setAddressData: (state, action: PayloadAction<IAddressData[]>) => ({
      ...state,
      addressData: action.payload ?? [],
    }),
    //info
    fetchAddressInfo: (state, action: PayloadAction<IAddressInfoFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAddressInfo: (state, action: PayloadAction<IAddressInfo | null>) => ({
      ...state,
      addressInfo: action.payload ?? null,
    }),
    setAddressInfoLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      addressInfoLock: action.payload,
    }),
    setAddressTypes: (state, action: PayloadAction<IAddressType[]>) => ({
      ...state,
      addressTypes: action.payload,
    }),
    //update
    updateAddress: (state, action: PayloadAction<IAddressUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateAddressLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateAddressLock: action.payload,
    }),
    //create
    createAddress: (state, action: PayloadAction<IAddressCreatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setCreateAddressLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      createAddressLock: action.payload,
    }),
    //delete
    deleteAddress: (state, action: PayloadAction<IAddressDeletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setDeleteAddressLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      deleteAddressLock: action.payload,
    }),
  },
});

export const addressesReducers = addressesSlice.reducer;
export const addressesActions = addressesSlice.actions;
