import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Input } from '@/components/Input/Input';
import { MobilityTransportTypesSelect } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/MobilityTransportTypesModal/MobilityTransportTypesModalContent/MobilityTransportTypesSelect/MobilityTransportTypesSelect';
import { MobilityTransportTypesTable } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/MobilityTransportTypesModal/MobilityTransportTypesModalContent/MobilityTransportTypesTable/MobilityTransportTypesTable';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { workingTimeMobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/selectors';
import {
  IMobilityTransportTypesUpdatePayload,
  workingTimeMobilityModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/workingTimeMobilitModalsSlice';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IMobilityTransportTypesForm {
  transportTypeName?: string | null;
  id: number | null;
  transportFormTypeID?: number | null;
}

export const MobilityTransportTypesModalContent: React.FC = () => {
  const { t: transportTypesModalTranslations } = useTranslation('mobilityTransportTypesModal');

  const {
    mobilityTransportTypes,
    mobilityTransportTypesLock: loadingStatus,
    mobilityFormTypes,
  } = useAppSelector(workingTimeMobilityModalsSelectors.transportTypesModal);

  const dispatch = useAppDispatch();

  const defaultValues: IMobilityTransportTypesForm = {
    transportFormTypeID: null,
    id: null,
    transportTypeName: '',
  };

  const form = useForm<IMobilityTransportTypesForm>({ defaultValues });
  const { id, transportFormTypeID, transportTypeName } = form.watch();
  const { isDirty } = form.formState;

  const handleClose = () => {
    dispatch(workingTimeMobilityModalsActions.closeTransportTypesModal());
  };

  const handleFormReset = () => {
    form.reset();
  };

  const handleUpdate = () => {
    if (!transportFormTypeID || !transportTypeName) {
      return;
    }

    const updatedTransportType: IMobilityTransportTypesUpdatePayload = {
      id: id,
      transportTypeFormID: transportFormTypeID,
      transportTypeName: transportTypeName,
    };

    dispatch(workingTimeMobilityModalsActions.updateTransportTypes(updatedTransportType));
  };

  const selectOptions = mobilityFormTypes.reduce<ISelectItem[]>((accum, formType) => {
    const selectOption: ISelectItem = {
      id: formType.id.toString(),
      component: formType.formType,
    };

    accum.push(selectOption);

    return accum;
  }, []);

  return (
    <div className={'bg-white rounded-default flex flex-col gap-4 min-w-[800px]'}>
      <Typography>{transportTypesModalTranslations('title.label')}</Typography>
      <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
        {mobilityTransportTypes.length > 0 && (
          <MobilityTransportTypesTable
            form={form}
            formDefaultValues={defaultValues}
            transportTypes={mobilityTransportTypes}
          />
        )}
      </Skeleton>
      <div className={'flex flex-row gap-4 w-full'}>
        <FormProvider {...form}>
          <Input control={form.register('transportTypeName')} containerClassName={'w-full'} />
          <MobilityTransportTypesSelect
            control={form.control}
            name='transportFormTypeID'
            options={selectOptions}
            className={'shrink-0'}
            defaultValue={transportFormTypeID ? transportFormTypeID.toString() : ''}
          />
        </FormProvider>
      </div>
      <div className={'flex flex-row gap-3'}>
        {isDirty ? (
          <>
            <Button onClick={handleUpdate}>
              {id ? (
                <Typography>
                  {transportTypesModalTranslations('buttons.updateTransportType.label')}
                </Typography>
              ) : (
                <Typography>
                  {transportTypesModalTranslations('buttons.createTransportType.label')}
                </Typography>
              )}
            </Button>
            <Button buttonVariant={'Secondary'} onClick={handleFormReset}>
              {transportTypesModalTranslations('buttons.resetForm.label')}
            </Button>
          </>
        ) : (
          <Button buttonVariant={'Secondary'} onClick={handleClose} className={'ml-auto'}>
            {transportTypesModalTranslations('buttons.cancel.label')}
          </Button>
        )}
      </div>
    </div>
  );
};
