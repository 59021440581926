import React from 'react';

import { VocationalTrainingAreaFinishedMeasures } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaFilters/VocationalTrainingAreaFinishedMeasures/VocationalTrainingAreaFinishedMeasures';
import { VocationalTrainingAreaGroupView } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaFilters/VocationalTrainingAreaGroupView/VocationalTrainingAreaGroupView';
import { VocationalTrainingAreaSearchByName } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaFilters/VocationalTrainingAreaSearchByName/VocationalTrainingAreaSearchByName';
import { VocationalTrainingAreaSelectType } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaFilters/VocationalTrainingAreaSelectType/VocationalTrainingAreaSelectType';

export const VocationalTrainingAreaFilters: React.FC = () => {
  return (
    <div className={'flex flex-row gap-10 items-center'}>
      <VocationalTrainingAreaFinishedMeasures />
      <VocationalTrainingAreaGroupView />
      <VocationalTrainingAreaSearchByName />
      <VocationalTrainingAreaSelectType />
    </div>
  );
};
