import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';

import { Button } from '@/components/Button/Button';
import { useQuestionnaireStoreSelector } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireContext/QuestionnaireContext';
import { QuestionItemDescriptionEditPopup } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionItem/QuestionItemDescription/QuestionItemDescriptionEditPopup/QuestionItemDescriptionEditPopup';
import { EditTextIcon } from '@/components/Icons/EditTextIcon';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

interface IQuestionItemDescription {
  parentID: number;
  id: number;
}

export const QuestionItemDescription: React.FC<IQuestionItemDescription> = ({ parentID, id }) => {
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const [isFormEditable] = useQuestionnaireStoreSelector((store) => store.isFormEditable);

  const fieldName = `${parentID}.questions.${id}.description`;

  const formContext = useFormContext();
  const currentValue = formContext.watch(fieldName);

  const { refs, floatingStyles, context } = useFloating({
    open: isEditable,
    onOpenChange: setIsEditable,
    placement: 'bottom',
    middleware: [flip()],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const handleOpenPopUp = () => {
    setIsEditable(true);
  };

  const handleClosePopUp = () => {
    setIsEditable(false);
  };

  return (
    <div ref={refs.setReference} {...getReferenceProps()}>
      {isEditable ? (
        <FloatingFocusManager context={context} closeOnFocusOut={true}>
          <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <QuestionItemDescriptionEditPopup
              parentID={parentID}
              id={id}
              closePopUp={handleClosePopUp}
              initialValue={currentValue}
            />
          </div>
        </FloatingFocusManager>
      ) : (
        <div className={'flex flex-row items-center justify-between gap-3'}>
          <Typography className={'pt-2'}>{currentValue}</Typography>
          {isFormEditable && (
            <Button buttonVariant={'Icon'} buttonStyle={'Icon'} onClick={handleOpenPopUp}>
              <EditTextIcon className={`text-${colorTypes.Grey} hover:text-${colorTypes.Blue}`} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
