import { PayloadAction } from '@reduxjs/toolkit';
import { type NormalizeOAS, type OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  IDevelopmentReportVisionAndGoalsForm,
} from '@/components/functions/DevelopmentReport/DevelopmentReportForms/VisionAndGoals/helper/visionAndGoalsForm';
import { authAdd, restCall } from '@/core/clients/rest';
import {
  DevelopmentReportVisionAndGoalsTypes,
} from '@/core/enums/functions/developmentReport/developmentReportVisionAndGoalsTypesEnum';
import {
  developmentReportVisionAndGoalsActions,
  IDevelopmentReportVisionAndGoalsFetchPayload,
  IDevelopmentReportVisionAndGoalsUpdatePayload,
} from '@/core/redux/slices/functions/developmentReport/developmentReportVisionAndGoalsSlice/slice';
import { getSelectedOption } from '@/core/utils/commonUtils';
import { isEnumValue } from '@/core/utils/enumUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type FetchDevelopmentReportVisionAndGoalsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/vision_goals',
  'get'
>;


type DevelopmentReportVisionAndGoalsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/development_report/vision_goals',
  'get',
  '200'
>;

type UpdateDevelopmentReportVisionAndGoalsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/development_report/vision_goals',
  'post'
>;

const mapDevelopmentReportVisionAndGoalsResponse = (response: DevelopmentReportVisionAndGoalsResponse): IDevelopmentReportVisionAndGoalsForm => {

  return {
    visionAndGoals: response.items.map((item) => {

      const viewOptions: Record<DevelopmentReportVisionAndGoalsTypes, boolean> = {
        [DevelopmentReportVisionAndGoalsTypes.OwnExclusion]: item.is_own,
        [DevelopmentReportVisionAndGoalsTypes.DeputyExclusion]: item.is_representative,
        [DevelopmentReportVisionAndGoalsTypes.Default]: false
      }

      const selectedOption = getSelectedOption(viewOptions)

    return {
      viewType: isEnumValue(DevelopmentReportVisionAndGoalsTypes, selectedOption) ? selectedOption :  DevelopmentReportVisionAndGoalsTypes.Default,
      lfdNr: item.lfd_nr,
      ownView: item.own_view,
      representativeView: item.representative_view,
    }
    }),
  }
}

function* fetchDevelopmentReportVisionAndGoals(action: PayloadAction<IDevelopmentReportVisionAndGoalsFetchPayload>): Generator<any, void, DevelopmentReportVisionAndGoalsResponse> {
  const { developmentReportID } = action.payload;

  try {
    yield put(developmentReportVisionAndGoalsActions.setDevelopmentReportVisionAndGoalsLock(LoadingStatus.LOADING))

    const request: FetchDevelopmentReportVisionAndGoalsRequest = {
      query: {
        development_report_id: developmentReportID
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/development_report/vision_goals', 'get', request);
    const mappedResponse: IDevelopmentReportVisionAndGoalsForm = mapDevelopmentReportVisionAndGoalsResponse(response)

    yield put(developmentReportVisionAndGoalsActions.setDevelopmentReportVisionAndGoals(mappedResponse));
    yield put(developmentReportVisionAndGoalsActions.setDevelopmentReportVisionAndGoalsLock(LoadingStatus.LOADED))
  } catch (error) {
    console.log('Error on development report vision and goals fetching', error);
    yield put(developmentReportVisionAndGoalsActions.setDevelopmentReportVisionAndGoalsLock(LoadingStatus.ERROR))
  }
}

function* updateDevelopmentReportVisionAndGoals(action: PayloadAction<IDevelopmentReportVisionAndGoalsUpdatePayload>): Generator<any, void, any> {
  const { developmentReportID, personID, formValues } = action.payload;

  try {
    const request: UpdateDevelopmentReportVisionAndGoalsRequest = {
      json: {
        person_id: personID,
        development_report_id: developmentReportID,
        items: formValues?.visionAndGoals.map<UpdateDevelopmentReportVisionAndGoalsRequest['json']['items'][0]>((visionAndGoal) => ({
          representative_view: visionAndGoal.representativeView,
          own_view: visionAndGoal.ownView,
          lfd_nr: visionAndGoal.lfdNr,
          is_representative: visionAndGoal.viewType === DevelopmentReportVisionAndGoalsTypes.DeputyExclusion,
          is_own: visionAndGoal.viewType === DevelopmentReportVisionAndGoalsTypes.OwnExclusion
        }))
      },
      ...authAdd(),
    };

   yield call(restCall, '/development_report/vision_goals', 'post', request);
  } catch (error) {
    console.log('Error on development report vision and goals updating', error);
  }
}

export const developmentReportVisionAndGoalsSagas = [
  takeLatest(developmentReportVisionAndGoalsActions.fetchDevelopmentReportVisionAndGoals, fetchDevelopmentReportVisionAndGoals),
  takeLatest(developmentReportVisionAndGoalsActions.updateDevelopmentReportVisionAndGoals, updateDevelopmentReportVisionAndGoals)
];
