import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { initializeApollo } from '@/core/clients/apollo';
import { mixinWithErrorHandler, mixinWithLoading } from '@/core/redux/sagas';
import { administrationPageSelectors } from '@/core/redux/slices/administrationPage/selectors';
import {
  administrationPageActions,
  IUpdatePinnedPayload,
} from '@/core/redux/slices/administrationPage/slice';
import {
  IUpdateFunctionOrderNumberPayload,
  IUpdateFunctionType,
} from '@/core/redux/slices/functionPage/functionPageSlice';
import { augmentSaga } from '@/core/redux/utils';
import { getFunctionType } from '@/core/utils/functionsUtils';
import {
  GetAdministrationSettingsDocument,
  GetAdministrationSettingsQuery,
  GetAdministrationSettingsQueryHookResult,
  UpdateAdministrationFunctionOrderNumberDocument,
  UpdateAdministrationFunctionOrderNumberMutation,
  UpdateAdministrationFunctionTypeDocument,
  UpdateAdministrationFunctionTypeMutation,
  UpdateAdministrationPinnedDocument,
} from '@/services/graphql/base/graphql';
import { FunctionTypes, IFunctionItem } from '@/types/functions';
import { LoadingStatus } from '@/types/loadingStatus';

function updateFunctionOrder(functions: IFunctionItem[], targetID: string): IFunctionItem[] {
  return functions
    .map((func) => {
      if (func.id === targetID) {
        return { ...func, orderNumber: -1 };
      }
      return func;
    })
    .sort((a, b) => a.orderNumber - b.orderNumber)
    .map((func, index) => ({ ...func, orderNumber: index + 1 }));
}

function* fetchFunctions(): Generator<any, void, GetAdministrationSettingsQueryHookResult> {
  const client = initializeApollo();

  yield put(administrationPageActions.setFunctionsLock(LoadingStatus.LOADING));

  try {
    const response = yield client.query<GetAdministrationSettingsQuery>({
      query: GetAdministrationSettingsDocument,
    });

    const filteredResponse = response.data?.settings?.filter(
      (element) => element.administration_functions && element.administration_functions.length > 0
    );

    const functions: IFunctionItem[] = [];

    if (filteredResponse && filteredResponse[0].administration_functions) {
      filteredResponse[0].administration_functions.forEach((item) => {
        if (item) {
          functions.push({
            id: item.id,
            functionID: item.administration_functions_id?.id || '',
            isPinned: item.is_pinned || false,
            name: item.administration_functions_id?.name || '',
            iconUrl: item.administration_functions_id?.icon?.filename_disk || '',
            functionType: item.function_type_id
              ? getFunctionType(item.function_type_id.type)
              : FunctionTypes.none,
            orderNumber: item.order_number ?? 0,
          });
        }
      });
    }

    yield put(administrationPageActions.setFunctions(functions));
    yield put(administrationPageActions.setFunctionsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on functions list fetching', error);
    yield put(administrationPageActions.setFunctionsLock(LoadingStatus.LOADED));
  }
}

function* updatePinned(action: PayloadAction<IUpdatePinnedPayload>): Generator<any, void, any> {
  const { payload } = action;
  const client = initializeApollo();

  try {
    yield put(administrationPageActions.setUpdatePinLock(LoadingStatus.LOADING));

    const functions = yield select(administrationPageSelectors.functions);
    const sortedFunctions = updateFunctionOrder(functions, payload.id);

    yield call([client, client.mutate], {
      mutation: UpdateAdministrationPinnedDocument,
      variables: {
        id: payload.id,
        is_pinned: payload.isPinned,
      },
    });

    yield put(administrationPageActions.updateFunctionOrderNumber({ functions: sortedFunctions }));

    yield call(fetchFunctions);
    yield put(administrationPageActions.setUpdatePinLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on administration function pin updating', error);
    yield put(administrationPageActions.setUpdatePinLock(LoadingStatus.ERROR));
  }
}

function* updateFunctionType(
  action: PayloadAction<IUpdateFunctionType>
): Generator<any, void, any> {
  const { payload } = action;

  const client = initializeApollo();

  yield client.mutate<UpdateAdministrationFunctionTypeMutation>({
    mutation: UpdateAdministrationFunctionTypeDocument,
    variables: {
      id: payload.id,
      function_type: payload.functionType === 'none' ? null : { id: payload.functionType },
    },
  });

  yield call(fetchFunctions);
}

function* updateFunctionOrderNumber(
  action: PayloadAction<IUpdateFunctionOrderNumberPayload>
): Generator<any, void, any> {
  const { payload } = action;

  const client = initializeApollo();

  for (const [index, func] of Object.entries(payload.functions || [])) {
    yield client.mutate<UpdateAdministrationFunctionOrderNumberMutation>({
      mutation: UpdateAdministrationFunctionOrderNumberDocument,
      variables: {
        id: func.id,
        order_number: Number(index),
      },
    });
  }

  yield call(fetchFunctions);
}

export const administrationPageSagas = [
  takeLatest(
    administrationPageActions.fetchFunctions,
    augmentSaga(
      fetchFunctions,
      mixinWithErrorHandler('', administrationPageActions.setFunctionsLock),
      mixinWithLoading(administrationPageActions.setFunctionsLock)
    )
  ),
  takeEvery(administrationPageActions.updatePinned, updatePinned),
  takeEvery(administrationPageActions.updateFunctionType, updateFunctionType),
  takeEvery(administrationPageActions.updateFunctionOrderNumber, updateFunctionOrderNumber),
];
