import React from 'react';
import { useFormContext } from 'react-hook-form';

import { PredefinedVariantsListItem } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/PredefinedVariantsContainer/PredefinedVariantsListItem';
import {
  IWorkingTimePredefinedVariantsForm,
  PREDEFINED_VARIANTS_EMPTY_FORM_STATE,
} from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/WorkingTimePredefinedVariantsModalContent';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { useAppSelector } from '@/core/redux/hooks';
import { workingTimeMobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/selectors';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

export const PredefinedVariantsList: React.FC = () => {
  const { workingTimePredefinedVariants, workingTimePredefinedVariantsLock } = useAppSelector(
    workingTimeMobilityModalsSelectors.workingTypePredefinedVariantsModal
  );

  const isLoading = workingTimePredefinedVariantsLock === LoadingStatus.LOADING;

  const { reset, watch } = useFormContext<IWorkingTimePredefinedVariantsForm>();
  const selectedID = watch('selectedID');

  const oddColor = useAppSelector(parametersSelectors.vocationalTrainingAreaItemOddColor);
  const evenColor = useAppSelector(parametersSelectors.vocationalTrainingAreaItemEvenColor);

  const handleClick = (id: number) => {
    if (!workingTimePredefinedVariants) {
      return;
    }

    if (id === selectedID) {
      reset(PREDEFINED_VARIANTS_EMPTY_FORM_STATE);
      return;
    }

    const foundElement = workingTimePredefinedVariants.find(
      (predefinedVariant) => predefinedVariant.id === id
    );

    if (!foundElement) {
      return;
    }

    reset({
      selectedID: foundElement.id,
      predefinedName: foundElement.name,
      workingWeek: foundElement.workingWeek,
    });
  };

  return (
    <div className={'flex-grow flex flex-col overflow-y-auto'}>
      <Skeleton trigger={isLoading}>
        {workingTimePredefinedVariants &&
          workingTimePredefinedVariants.map((predefinedVariant, index) => (
            <PredefinedVariantsListItem
              onClick={handleClick}
              bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
              key={predefinedVariant.id}
              {...predefinedVariant}
            />
          ))}
      </Skeleton>
    </div>
  );
};
