import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const CompetenceAnalysisReviewTooltip: React.FC = () => {
  const { t: competenceAnalysisTranslations } = useTranslation('competenceAnalysis');

  return (
    <div
      className={`flex flex-col items-center justify-start p-[10px] bg-${colorTypes.TooltipBG} w-max shadow-default rounded-default`}
    >
      <Typography color={'White'} type={'12-R'}>
        {competenceAnalysisTranslations('tooltip.0.label')}
      </Typography>
      <Typography color={'White'} type={'12-R'}>
        {competenceAnalysisTranslations('tooltip.1.label')}
      </Typography>
      <Typography color={'White'} type={'12-R'}>
        {competenceAnalysisTranslations('tooltip.2.label')}
      </Typography>
      <Typography color={'White'} type={'12-R'}>
        {competenceAnalysisTranslations('tooltip.3.label')}
      </Typography>
      <Typography color={'White'} type={'12-R'}>
        {competenceAnalysisTranslations('tooltip.4.label')}
      </Typography>
    </div>
  );
};
