import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INotification } from '@/types/notifications';

export interface IShowNotificationPayload {
  notification: INotification;
}

export interface INotificationPayload {
  id: string;
  notification: INotification;
}

export interface INotificationsState {
  notifications: Record<string, INotification>;
}

const initialState: INotificationsState = {
  notifications: {},
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<IShowNotificationPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    addNotification: (state, action: PayloadAction<INotificationPayload>) => {
      const newNotifications = { ...state.notifications };
      newNotifications[action.payload.id] = action.payload.notification;
      return {
        ...state,
        notifications: newNotifications,
      };
    },
    hideNotification: (state, action: PayloadAction<string>) => {
      const newNotifications = { ...state.notifications };
      delete newNotifications[action.payload];
      return { ...state, notifications: newNotifications };
    },
  },
});

export const notificationsReducer = notificationSlice.reducer;
export const notificationsActions = notificationSlice.actions;
