import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.vocationalTrainingAreaModals;

const vocationalTrainingAreaDocumentsModal = createSelector(
  [selector],
  (state) => state.vocationalTrainingAreaDocumentsModal
);
const completeMeasureModal = createSelector([selector], (state) => state.completeMeasureModal);
const completeMeasureModalActionTrigger = createSelector(
  [selector],
  (state) => state.completeMeasureModal.actionTrigger
);

export const vocationalTrainingAreaModalSelectors = {
  vocationalTrainingAreaDocumentsModal,
  completeMeasureModal,
  completeMeasureModalActionTrigger,
};
