import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import styles from './styles.module.scss';

export interface ISidebar {
  onExit: () => void;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  position: 'left' | 'right';
  title?: string;
  isNested?: boolean;
  onBackTo?: () => void;
  layer?: number;
}

export const Sidebar: React.FC<ISidebar> = ({
  onExit,
  children,
  footer,
  className,
  position,
  title,
  isNested = false,
  layer = 1,
  onBackTo,
}) => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui' });

  return (
    <AnimatePresence>
      <motion.div
        className={clsx(!isNested && styles.modalContainer, `fixed inset-0 z-${layer * 50}`)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <motion.div
          className={twMerge(
            'w-screen h-screen flex flex-row',
            position === 'right' && 'justify-end',
            position === 'left' && 'justify-start'
          )}
        >
          <div className={twMerge(`relative`)}>
            <motion.div
              className={twMerge(`flex flex-col bg-${colorTypes.White} h-full`, className)}
              initial={{ x: position === 'right' ? '100%' : '-100%' }}
              animate={{ x: 0 }}
              transition={{ type: 'tween' }}
            >
              {(title || isNested) && (
                <div className='p-8 pb-5 flex flex-col gap-8'>
                  {isNested && (
                    <div className='flex gap-2 items-center cursor-pointer' onClick={onBackTo}>
                      <ArrowRightIcon className='rotate-180' />
                      <Typography>{t('label.backTo')}</Typography>
                    </div>
                  )}
                  {title && <Typography type='H2'>{title}</Typography>}
                </div>
              )}

              <div className='flex flex-col h-full overflow-auto'>{children}</div>
              {footer && (
                <div className='shadow-[0px_2px_10px_0px_rgba(110,123,148,0.20)] px-[30px] py-4'>
                  {footer}
                </div>
              )}
            </motion.div>
            <div
              className={clsx(
                styles.closeButtonContainer,
                'absolute w-[40px] h-[40px] flex items-center justify-center',
                position === 'left' && '-right-[50px] top-[10px] ',
                position === 'right' && '-left-[50px] top-[10px] ',
                isNested && 'opacity-0'
              )}
            >
              <Button
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={clsx(styles.closeButton, 'flex items-center')}
                onClick={onExit}
              >
                <CrossIcon />
              </Button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
