import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { GetDocumentsButton } from '@/components/Modals/Modals/functions/integrationPlanModals/CreateIntegrationPlanDocumentsModal/GetDocumentsButton/GetDocumentsButton';
import { PredefinedPrintVariantsSelect } from '@/components/Modals/Modals/functions/integrationPlanModals/CreateIntegrationPlanDocumentsModal/PredefinedPrintVariantsSelect/PredefinedPrintVariantsSelect';
import { PrintVariantsList } from '@/components/Modals/Modals/functions/integrationPlanModals/CreateIntegrationPlanDocumentsModal/PrintVariantsList/PrintVariantsList';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { integrationPlanSelectors } from '@/core/redux/slices/functions/integrationPlan/selectors';
import {
  ICreateIntegrationPlanDocumentsPayload,
  integrationPlanModalsActions,
} from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';
import { twMerge } from '@/core/utils/tailwindUtil';

export interface ICreateIntegrationPlanDocumentsForm {
  forms: Record<string, boolean>;
  previewOnly: boolean;
}

export const CreateIntegrationPlanDocumentsModal: React.FC = () => {
  const { t: createIntegrationPlanDocumentsTranslations } = useTranslation(
    'createIntegrationPlanDocumentsModal'
  );

  const [anySelected, setAnySelected] = useState<boolean>(false);

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const personID = selectedPerson?.id;

  const isOpened = useAppSelector(
    integrationPlanModalsSelectors.createIntegrationPlanDocumentsModal
  );
  const printVariants = useAppSelector(
    integrationPlanModalsSelectors.createIntegrationPlanDocumentPrintVariants
  );
  const printVariantsPredefinedVariants = useAppSelector(
    integrationPlanModalsSelectors.createIntegrationPlanDocumentsPredefinedPrintVariants
  );

  const integrationPlanID = useAppSelector(integrationPlanSelectors.integrationPlanID);

  const form = useForm<ICreateIntegrationPlanDocumentsForm>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpened) {
      dispatch(integrationPlanModalsActions.fetchIntegrationPlanPrintVariants());
    }
  }, [isOpened]);

  useEffect(() => {
    if (form.watch().forms) {
      const formsValues = Object.values(form.watch().forms);
      const anySelected = formsValues.includes(true);

      setAnySelected(anySelected);
    }
  }, [form.watch()]);

  if (isOpened) {
    const handleExit = () => {
      dispatch(integrationPlanModalsActions.closeCreateIntegrationPlanDocumentsModal());
    };

    const handleCreateDocuments = () => {
      if (!integrationPlanID || !personID) {
        return;
      }

      const formsState = form.watch().forms;
      const formsKeys = Object.keys(formsState);
      const selectedIds = formsKeys.reduce((accum: number[], formsKey) => {
        if (formsState[formsKey]) {
          accum.push(parseInt(formsKey));
        }
        return accum;
      }, []);

      const payload: ICreateIntegrationPlanDocumentsPayload = {
        personID: personID,
        integrationPlanID: integrationPlanID,
        finalizeIntegrationPlan: !form.watch('previewOnly'),
        selectedIds: selectedIds,
      };

      if (form.getValues('previewOnly')) {
        dispatch(integrationPlanModalsActions.getPreviewIntegrationPlanDocuments(payload));
        return;
      }

      dispatch(integrationPlanModalsActions.getIntegrationPlanDocuments(payload));
    };

    const footer = (
      <div className={twMerge('flex flex-row justify-start gap-3')}>
        <GetDocumentsButton handleCreateDocuments={handleCreateDocuments} isRender={anySelected} />
        <Button buttonVariant={'Secondary'} onClick={handleExit}>
          {createIntegrationPlanDocumentsTranslations('buttons.cancel.label')}
        </Button>
      </div>
    );

    return (
      <Sidebar position={'right'} onExit={handleExit} footer={footer}>
        <div className={'flex flex-col min-w-[500px] max-w-[740px] gap-3 p-5'}>
          <Typography type={'H3'}>
            {createIntegrationPlanDocumentsTranslations('title.label')}
          </Typography>
          <PredefinedPrintVariantsSelect
            form={form}
            predefinedPrintVariants={printVariantsPredefinedVariants}
          />
          <FormProvider {...form}>
            <PrintVariantsList printVariants={printVariants} />
            <CheckboxItem
              label={createIntegrationPlanDocumentsTranslations(
                'fields.finalizeIntegrationPlan.label'
              )}
              fieldName={'previewOnly'}
              className={'mt-4'}
              labelPosition={'left'}
            />
          </FormProvider>
        </div>
      </Sidebar>
    );
  }

  return null;
};
