import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ADMINISTRATION_TABS_PATH } from '@/core/constants/localStorage';
import { FunctionTypes, IFunctionItem } from '@/types/functions';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IUpdatePinnedPayload {
  id: string;
  isPinned: boolean;
}

export interface IUpdateFunctionOrderNumberPayload {
  functions: IFunctionItem[];
}

export interface IUpdateFunctionType {
  id: string;
  functionType: string | undefined;
}

export interface IAdministrationPageState {
  status: LoadingStatus;
  functions: IFunctionItem[];
  activeTabId: null | string;
  tabs: Tab[];
  updatePinLock: LoadingStatus;
}

export interface Tab {
  id: string;
  title: string;
  functionItem?: IAddTabFunctionItem;
}

export interface IAddTabFunctionItem {
  name: string;
  id: string;
  iconUrl: string;
  functionType?: FunctionTypes;
}

interface IAddTabFunctionPayload {
  tabId: string;
  functionItem: IAddTabFunctionItem;
}

const initialState: IAdministrationPageState = {
  status: LoadingStatus.NEVER,
  functions: [],
  activeTabId: null,
  tabs: [],
  updatePinLock: LoadingStatus.NEVER,
};

const administrationPageSlice = createSlice({
  name: 'administrationPage',
  initialState,
  reducers: {
    fetchFunctions: () => {},
    updatePinned: (state, action: PayloadAction<IUpdatePinnedPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateFunctionOrderNumber: (
      state,
      action: PayloadAction<IUpdateFunctionOrderNumberPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    updateFunctionType: (state, action: PayloadAction<IUpdateFunctionType>) => ({
      ...state,
      payload: action.payload,
    }),
    setFunctions: (state, action: PayloadAction<IFunctionItem[]>) => ({
      ...state,
      functions: action.payload,
    }),
    setFunctionsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      status: action.payload,
    }),
    setActiveTabId: (state, action: PayloadAction<string | null>) => ({
      ...state,
      activeTabId: action.payload,
    }),
    loadTabsFromLocale: (state) => {
      const storedTabs = localStorage.getItem(ADMINISTRATION_TABS_PATH);
      if (!storedTabs) {
        return state;
      }

      return {
        ...state,
        tabs: JSON.parse(storedTabs),
      };
    },
    addTab: (state, action: PayloadAction<Tab>) => {
      const tempTabs = [...state.tabs];
      const tabExists = tempTabs.some((tab) => tab.id === action.payload.id);

      if (!tabExists) {
        tempTabs.push(action.payload);
        localStorage.setItem(ADMINISTRATION_TABS_PATH, JSON.stringify(tempTabs));
      }

      return {
        ...state,
        tabs: tempTabs,
      };
    },
    setTabFunction: (state, action: PayloadAction<IAddTabFunctionPayload>) => {
      const { tabId, functionItem } = action.payload;

      const existingTab = state.tabs.find((tab) => tab.functionItem?.id === functionItem.id);

      if (existingTab) {
        return {
          ...state,
          activeTabId: existingTab.id,
        };
      }

      const updatedTabs = state.tabs.map((tab) =>
        tab.id === tabId ? { ...tab, functionItem } : tab
      );

      localStorage.setItem(ADMINISTRATION_TABS_PATH, JSON.stringify(updatedTabs));

      return {
        ...state,
        tabs: updatedTabs,
      };
    },
    removeTab: (state, action: PayloadAction<string>) => {
      const tempTabs = [...state.tabs];

      const foundIndex = tempTabs.findIndex((tab) => tab.id === action.payload);

      if (foundIndex === -1) {
        return state;
      }

      tempTabs.splice(foundIndex, 1);

      localStorage.setItem(ADMINISTRATION_TABS_PATH, JSON.stringify(tempTabs));

      return {
        ...state,
        tabs: tempTabs,
      };
    },
    setUpdatePinLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updatePinLock: action.payload,
    }),
  },
});

export const administrationPageReducer = administrationPageSlice.reducer;
export const administrationPageActions = administrationPageSlice.actions;
