import React from 'react';

import { IIcon } from '@/types/icon';

export const CircleOutlineIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='16.000000'
    height='16.000000'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <defs />
    <circle
      id='Ellipse 52'
      cx='8.000000'
      cy='8.000000'
      r='7.500000'
      stroke='currentColor'
      strokeOpacity='1.000000'
      strokeWidth='1.000000'
    />
  </svg>
);
