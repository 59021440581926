import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { workingTimeResolverBuilder } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/helpers/workingTimeResolver';
import { PredefinedVariantsContainer } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/PredefinedVariantsContainer/PredefinedVariantsContainer';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { Typography } from '@/components/Typography/Typography';
import { WorkingTimePlanner } from '@/components/WorkingTimePlanner/WorkingTimePlanner';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { IWorkingTimeWeek } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { workingTimeMobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/selectors';
import { workingTimeMobilityModalsActions } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/workingTimeMobilitModalsSlice';

export interface IWorkingTimePredefinedVariantsForm {
  selectedID?: number | null;
  predefinedName?: string | null;
  workingWeek: IWorkingTimeWeek;
}

export const PREDEFINED_VARIANTS_EMPTY_FORM_STATE: IWorkingTimePredefinedVariantsForm = {
  selectedID: null,
  predefinedName: '',
  workingWeek: {
    sumNet: null,
    workingDaysCount: null,
    sumGross: null,
    sumPauseDuration: null,
    workingDays: {},
  },
};

export const WorkingTimePredefinedVariantsModalContent: React.FC = () => {
  const { t: workingTimePredefinedVariantsModalTranslations } = useTranslation(
    'workingTimePredefinedVariantsModal'
  );

  const { workingTimeDaysList, workingTimePredefinedVariants } = useAppSelector(
    workingTimeMobilityModalsSelectors.workingTypePredefinedVariantsModal
  );

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(workingTimeMobilityModalsActions.closeWorkingTimePredefinedVariantsModal());
  };

  const form = useForm<IWorkingTimePredefinedVariantsForm>({
    defaultValues: PREDEFINED_VARIANTS_EMPTY_FORM_STATE,
    resolver: yupResolver(
      workingTimeResolverBuilder(workingTimePredefinedVariantsModalTranslations)
    ),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    dispatch(workingTimeMobilityModalsActions.fetchWorkingTimePredefinedVariants());
  }, []);

  useEffect(() => {
    if (!workingTimePredefinedVariants) {
      return;
    }

    const selectedID = form.getValues('selectedID');

    if (!selectedID) {
      return;
    }

    const foundVariant = workingTimePredefinedVariants.find((variant) => variant.id === selectedID);

    form.reset({
      selectedID: selectedID,
      predefinedName: foundVariant?.name,
      workingWeek: foundVariant?.workingWeek,
    });
  }, [workingTimePredefinedVariants]);

  return (
    <Modal onExit={handleExit} className={'max-w-[80vw]'}>
      <FormProvider {...form}>
        <div className={'flex flex-col gap-5'}>
          <Typography type={'H3'}>
            {workingTimePredefinedVariantsModalTranslations('title.label')}
          </Typography>
          <div className={'grid grid-cols-2 gap-5 justify-stretch'}>
            <WorkingTimePlanner context={'modal'} workingTimeDaysList={workingTimeDaysList} />
            <PredefinedVariantsContainer />
          </div>
        </div>
      </FormProvider>
    </Modal>
  );
};
