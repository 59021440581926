import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface ICompetenceAnalysisCompetencies {
  sociabilityRating?: string | null;
  sociabilityComment?: string | null;
  cooperationRating?: string | null;
  cooperationComment?: string | null;
  teamRating?: string | null;
  teamComment?: string | null;
  takeCriticismRating?: string | null;
  takeCriticismComment?: string | null;
  concentrationRating?: string | null;
  concentrationComment?: string | null;
  memorizationRating?: string | null;
  memorizationComment?: string | null;
  workSpeedRating?: string | null;
  workSpeedComment?: string | null;
  independentWorkRating?: string | null;
  independentWorkComment?: string | null;
  enduranceWorkRating?: string | null;
  enduranceWorkComment?: string | null;
  flexibilityRating?: string | null;
  flexibilityComment?: string | null;
  selfAssessmentRating?: string | null;
  selfAssessmentComment?: string | null;
  selfRepresentationRating?: string | null;
  selfRepresentationComment?: string | null;
  grossFineMotorSkillsWithoutToolsRating?: string | null;
  grossFineMotorSkillsWithoutToolsComment?: string | null;
  grossFineMotorSkillsWithToolsRating?: string | null;
  grossFineMotorSkillsWithToolsComment?: string | null;
  physicalCapacityRating?: string | null;
  physicalCapacityComment?: string | null;
  spacialOrientationRating?: string | null;
  spacialOrientationComment?: string | null;
  timeOrientationRating?: string | null;
  timeOrientationComment?: string | null;
  mobilityTrafficRating?: string | null;
  mobilityTrafficComment?: string | null;
  readRating?: string | null;
  readComment?: string | null;
  writingRating?: string | null;
  writingComment?: string | null;
  calculateRating?: string | null;
  calculateComment?: string | null;
}

export interface ICompetenceAnalysis extends ICompetenceAnalysisCompetencies {
  melba: boolean;
  melbaSl: boolean;
  mast: boolean;
  pac: boolean;
  detmolderLearningPathModel: boolean;
  isOwnTestProcedure: boolean;
  ida: boolean;
  hamet: boolean;
  imba: boolean;
  isOtherTestProcedureV1: boolean;
  isOtherTestProcedureV2: boolean;
  recommendations: string;
  designationOtherTestProcedureV1?: string | null;
  designationOtherTestProcedureV2?: string | null;
  otherCompetencies1Name?: string | null;
  otherCompetencies1Rating?: string | null;
  otherCompetencies1Comment?: string | null;
  otherCompetencies2Name?: string | null;
  otherCompetencies2Rating?: string | null;
  otherCompetencies2Comment?: string | null;
  importDate?: string | null;
  lfdNr?: string | null;
}

export interface ICompetenceAnalysisFetchPayload {
  integrationPlanID: number;
}

export interface ICompetenceAnalysisImportFetchPayload {
  personID: number;
  includesComments: boolean;
}

export interface ICompetenceAnalysisUpdatePayload {
  competenceAnalysis: ICompetenceAnalysis;
  integrationPlanID: number;
  personID: number;
}

export interface ICompetenceAnalysisState {
  competenceAnalysis: ICompetenceAnalysis | null;
  isCompetenceAnalysisImported: boolean;
  competenceAnalysisLock: LoadingStatus;
  updateCompetenceAnalysisLock: LoadingStatus;
}

const initialState: ICompetenceAnalysisState = {
  competenceAnalysis: null,
  isCompetenceAnalysisImported: false,
  competenceAnalysisLock: LoadingStatus.NEVER,
  updateCompetenceAnalysisLock: LoadingStatus.NEVER,
};

export const competenceAnalysisSlice = createSlice({
  name: 'competenceAnalysis',
  initialState,
  reducers: {
    fetchCompetenceAnalysis: (state, action: PayloadAction<ICompetenceAnalysisFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateCompetenceAnalysis: (state, action: PayloadAction<ICompetenceAnalysisUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setCompetenceAnalysisLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      competenceAnalysisLock: action.payload,
    }),
    setUpdateCompetenceAnalysisLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateCompetenceAnalysisLock: action.payload,
    }),
    setCompetenceAnalysis: (state, action: PayloadAction<ICompetenceAnalysis | null>) => ({
      ...state,
      competenceAnalysis: action.payload ?? null,
    }),
    setCompetenceAnalysisIsImported: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isCompetenceAnalysisImported: action.payload,
    }),
    fetchCompetenceAnalysisImport: (
      state,
      action: PayloadAction<ICompetenceAnalysisImportFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const competenceAnalysisReducers = competenceAnalysisSlice.reducer;

export const competenceAnalysisActions = competenceAnalysisSlice.actions;
