import React, { useCallback } from 'react';
import clsx from 'clsx';

import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ActivityItem } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/ActivityItem/ActivityItem';
import { EmptyLoadedPlaceholder } from '@/components/widgets/ActivitiesWidget/ActivitiesContainer/Placeholder/EmptyLoadedPlaceholder/EmptyLoadedPlaceholder';
import { useAppSelector } from '@/core/redux/hooks';
import { dashboardSelectors } from '@/core/redux/slices/dashboard/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

import styles from '../styles.module.scss';

export const ActivitiesContainer: React.FC = () => {
  const activitiesList = useAppSelector(dashboardSelectors.activitiesList);
  const loadingStatus = useAppSelector(dashboardSelectors.activitiesStatus);

  const renderActivitiesContainer = useCallback(() => {
    switch (loadingStatus) {
      case LoadingStatus.LOADED_EMPTY:
        return <EmptyLoadedPlaceholder />;
      case LoadingStatus.ERROR:
        return <EmptyLoadedPlaceholder />;
      default:
        return (
          <Skeleton
            trigger={
              loadingStatus === LoadingStatus.NEVER || loadingStatus === LoadingStatus.LOADING
            }
            rows={10}
          >
            <div
              className={clsx('flex flex-row w-full max-h-[600px] overflow-auto', styles.scrollbar)}
            >
              <div className={'flex flex-col justify-start items-center w-full'}>
                {activitiesList.map((activity) => (
                  <ActivityItem activity={activity} key={activity.id} />
                ))}
              </div>
            </div>
          </Skeleton>
        );
    }
  }, [loadingStatus, activitiesList]);

  return renderActivitiesContainer();
};
