export type TextTypes = keyof typeof textTypes;

export const textTypes = {
  H1: 'font-inter text-[32px] font-semibold leading-[140%]',
  H2: 'font-inter text-[28px] font-semibold leading-[140%]',
  H3: 'font-inter text-[20px] font-semibold leading-[140%]',
  '20-R': 'font-inter text-[20px] font-bold leading-[150%]',
  '16-R': 'font-inter text-[16px] leading-[150%]',
  '16-M': 'font-inter font-medium leading-[150%]',
  '16-Sb': 'font-inter font-semibold leading-[150%]',
  '14-R': 'font-inter text-sm leading-[150%]',
  '14-M': 'font-inter text-sm font-medium leading-[150%]',
  '14-Sb': 'font-inter text-sm font-semibold leading-[150%]',
  '13-R': 'font-inter text-[13px] leading-[140%]',
  '12-R': 'font-inter text-xs leading-[130%',
};

export type ColorTypes = keyof typeof colorTypes;

export const colorTypes = {
  Primary: 'primary',
  Secondary: 'secondary',
  Disabled: 'disabled',
  Blue: 'blue',
  BlueHover: 'blue-hover',
  BluePressed: 'blue-pressed',
  Dark: 'dark',
  White: 'white',
  Black: 'black',
  Error: 'error',
  Hover: 'hover',
  HoverLight: 'hover-light',
  Pressed: 'pressed',
  Grey: 'grey',
  Fields: 'fields',
  Border: 'border',
  Scroll: 'scroll',
  ActivityBorder: 'activity-border',
  TooltipBG: 'tooltip-bg',
  Stroke: 'stroke',
  Orange: 'orange',
  Tiffany: 'tiffany',
  StrokeLight: 'stroke-light',
  SelfAssessmentBase: 'self-assessment-base',
  SelfAssessmentBaseBorder: 'self-assessment-base-border',
  SelfAssessmentActive: 'self-assessment-active',
  SelfAssessmentActiveBorder: 'self-assessment-active-border',
  SelfAssessmentHover: 'self-assessment-hover',
  SelfAssessmentHoverBorder: 'self-assessment-hover-border',
  Success: 'success',
  Overlay: 'overlay',
};

export const colors = Object.values(colorTypes).join('|');
