import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.workingTimeMobilityModals;

const transportTypesModal = createSelector([selector], (state) => state.transportTypesModal);
const workingTypePredefinedVariantsModal = createSelector(
  [selector],
  (state) => state.workingTimePredefinedVariantsModal
);
const workingTimeDaysListModal = createSelector(
  [selector],
  (state) => state.workingTimePredefinedVariantsModal.workingTimeDaysList
);

export const workingTimeMobilityModalsSelectors = {
  transportTypesModal,
  workingTypePredefinedVariantsModal,
  workingTimeDaysListModal,
};
