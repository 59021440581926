import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISerialAppointmentForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/hooks/useSerialAppointmentForm';
import { IDropdownItem } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IModal {
  isRender: boolean;
}

export interface ISerialAppointmentModalPayload {
  personID: number;
  appointmentSeriesID?: number | null;
  firstAppointmentDate: string;
  appointmentStartTime: string;
  appointmentEndTime: string;
  context: 'fullVersion' | 'modalVersion';
}

export interface ISerialAppointmentDetailsFetchPayload {
  appointmentSeriesID?: number | null;
}

export interface ISerialAppointmentModal extends IModal {
  serialAppointmentDetails: ISerialAppointmentForm | null;
  payload: ISerialAppointmentModalPayload | null;
  fetchPayload: ISerialAppointmentDetailsFetchPayload | null;
  lockStatus: LoadingStatus;
  weekNumbers: IDropdownItem[];
}

export interface ISerialAppointmentModalsState {
  serialAppointmentModal: ISerialAppointmentModal;
}

const initialSerialAppointmentState: ISerialAppointmentModalsState = {
  serialAppointmentModal: {
    isRender: false,
    serialAppointmentDetails: null,
    payload: null,
    fetchPayload: null,
    lockStatus: LoadingStatus.NEVER,
    weekNumbers: [],
  },
};

const serialAppointmentModalsSlice = createSlice({
  name: 'serialAppointmentModals',
  initialState: initialSerialAppointmentState,
  reducers: {
    openSerialAppointmentModal: (state, action: PayloadAction<ISerialAppointmentModalPayload>) => ({
      ...state,
      serialAppointmentModal: {
        ...state.serialAppointmentModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeSerialAppointmentModal: (state) => ({
      ...state,
      serialAppointmentModal: {
        ...state.serialAppointmentModal,
        weekNumbers: [],
        isRender: false,
        payload: null,
        fetchPayload: null,
        serialAppointmentDetails: null,
        calculatePayload: null,
      },
    }),
    fetchSerialAppointmentDetails: (
      state,
      action: PayloadAction<ISerialAppointmentDetailsFetchPayload>
    ) => ({
      ...state,
      serialAppointmentModal: {
        ...state.serialAppointmentModal,
        fetchPayload: action.payload,
      },
    }),
    setSerialAppointmentDetails: (state, action: PayloadAction<ISerialAppointmentForm | null>) => ({
      ...state,
      serialAppointmentModal: {
        ...state.serialAppointmentModal,
        serialAppointmentDetails: action.payload,
      },
    }),
    setSerialAppointmentDetailsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      serialAppointmentModal: {
        ...state.serialAppointmentModal,
        lockStatus: action.payload,
      },
    }),
    setSerialAppointmentWeekNumbers: (state, action: PayloadAction<IDropdownItem[]>) => ({
      ...state,
      serialAppointmentModal: {
        ...state.serialAppointmentModal,
        weekNumbers: action.payload,
      },
    }),
  },
});

export const serialAppointmentModalsReducer = serialAppointmentModalsSlice.reducer;
export const serialAppointmentModalsActions = serialAppointmentModalsSlice.actions;
