import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { VersionHistoryItem } from '@/components/Modals/Modals/functions/integrationPlanModals/IntegrationPlanVersionHistoryModal/VersionHistoryItem/VersionHistoryItem';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { integrationPlanActions } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import { integrationPlanModalsActions } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import styles from './styles.module.scss';

export const IntegrationPlanVersionHistoryModal: React.FC = () => {
  const {
    isRender: isOpened,
    payload,
    integrationPlanVersionHistoryLock: loadingStatus,
    integrationPlanVersionHistory: versionHistory,
  } = useAppSelector(integrationPlanModalsSelectors.integrationPlanVersionHistoryModal);

  const { t: versionHistoryTranslations } = useTranslation('integrationPlanVersionHistoryModal');

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(integrationPlanModalsActions.closeIntegrationPlanVersionHistoryModal());
  };

  useEffect(() => {
    if (isOpened && payload) {
      dispatch(
        integrationPlanModalsActions.fetchIntegrationPlanVersionHistory({
          personID: payload.personID,
        })
      );
    }
  }, [isOpened]);

  if (isOpened) {
    const handleVersionSelect = (id: number, dateVersion?: string | null) => {
      dispatch(integrationPlanActions.setIntegrationPlanToInitial());
      dispatch(integrationPlanActions.setIntegrationPlanVersionDate(dateVersion ?? null));
      dispatch(integrationPlanActions.setIntegrationPlanID(id));
      handleClose();
    };

    return (
      <Sidebar onExit={handleClose} position='right' className='p-0'>
        <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
          <div className={'flex flex-col min-w-[400px]'}>
            <Typography type={'H2'} className={'m-[30px]'}>
              {versionHistoryTranslations('title.label')}
            </Typography>
            <Typography
              type={'14-R'}
              color={'Secondary'}
              className={`border-t border-${colorTypes.Stroke} px-[30px] py-3`}
            >
              {versionHistoryTranslations('dateHeader.label')}
            </Typography>
            <div className={twMerge(styles.container, `flex flex-col`)}>
              {versionHistory.length > 0 &&
                versionHistory.map((version) => (
                  <VersionHistoryItem
                    key={version.id}
                    timeStamp={version.printedTimestamp}
                    id={version.id}
                    handleClick={handleVersionSelect}
                  />
                ))}
            </div>
          </div>
        </Skeleton>
      </Sidebar>
    );
  }

  return null;
};
